import { put, call, take, fork } from 'redux-saga/effects'
import generalSaga from '../general'
import { MEDIA_CARD_IMAGE_CREATION } from '../../reducers/media-image/type'
import { mediaCardImageCreationService } from '../../api/media-image'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* mediaCardImageCreationFlow(payload) {
  try {
    const { prompt, cardId, callback } = payload

    const response = yield call(mediaCardImageCreationService, prompt, cardId)

    yield put(operationSuccess())

    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* mediaCardImageCreationWatcher() {
  while (true) {
    const action = yield take(MEDIA_CARD_IMAGE_CREATION)
    const { payload } = action

    yield fork(generalSaga, mediaCardImageCreationFlow, payload)
  }
}
