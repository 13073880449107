import React from 'react'
import Inform from './inform'
import { AnswerItemType } from 'src/model/question'
import Question from './question'
import Freeform from './free-form'
import Input from './input'
import Numeric from './numeric'
import Stars from './stars'
import NotSupported from './not-supported'
import { marked } from 'marked'

/* 
import Signup from './signup'
import Stripe from './stripe-payment'
import ResultCard from './result-card'
import ScoreCard from './score'
import SertifierCard from './sertifier'
import ProtectionCard from './protection'
import ResultStandart from './result-standart'
import QuestionAi from './ai' */

/**
 *
 * @param {*} props
 */
const CardTypeFactory = props => {
  const { type, id, card } = props

  const renderer = new marked.Renderer()
  renderer.code = (code, language) => {
    const formattedCode = code.replace(/\n/g, '<br>')
    return `<pre><code classname="${language}">${formattedCode}</code></pre>`
  }
  marked.use({ renderer })

  const markdownToHtml = cardText => {
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g

    // Replace each Markdown link with its HTML <a> tag equivalent
    //delete all <p> and </p> tags

    let rawMarkup = cardText.replace(markdownLinkRegex, '<a href="$2">$1</a>')

    rawMarkup = rawMarkup.replaceAll(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    rawMarkup = rawMarkup.replaceAll(/\*(.*?)\*/g, '<em>$1</em>')

    rawMarkup = marked(rawMarkup)
    rawMarkup = rawMarkup.replaceAll('\n', '<br>')
    rawMarkup = rawMarkup.replaceAll('</p><br>', '</p>')
    rawMarkup = rawMarkup.replaceAll('<br><ol><br>', '<ol>')
    rawMarkup = rawMarkup.replaceAll('<br><ol>', '<ol>')
    rawMarkup = rawMarkup.replaceAll('<ol><br>', '<ol>')
    rawMarkup = rawMarkup.replaceAll('<br></ol><br>', '</ol>')
    rawMarkup = rawMarkup.replaceAll('<br><ul><br>', '<ul>')
    rawMarkup = rawMarkup.replaceAll('<br></ul><br>', '</ul>')
    rawMarkup = rawMarkup.replaceAll('<br><br>', '<br>')
    rawMarkup = rawMarkup.replaceAll('<br><li>', '<li>')
    rawMarkup = rawMarkup.replaceAll('<br><li><br>', '<li>')
    rawMarkup = rawMarkup.replaceAll('<br></li><br>', '</li>')
    rawMarkup = rawMarkup.replaceAll('<ol><br><li>', '<ol><li>')
    rawMarkup = rawMarkup.replaceAll('<ul><br><li>', '<ul><li>')
    rawMarkup = rawMarkup.replaceAll('<br></li></ol>', '</li></ol>')
    rawMarkup = rawMarkup.replaceAll('<br></li></ul>', '</li></ul>')
    rawMarkup = rawMarkup.replaceAll('<br></li><br>', '</li>')
    rawMarkup = rawMarkup.replaceAll('<br></li>', '</li>')
    rawMarkup = rawMarkup.replaceAll('<br></ol>', '</ol>')

    //replace <ol ...> tags with <ul> tags
    //replace </ol> tags with </ul> tags

    rawMarkup = rawMarkup.replaceAll('<ol', '<ul')
    rawMarkup = rawMarkup.replaceAll('</ol', '</ul')
    rawMarkup = rawMarkup.replaceAll('<img src=""', '<p')
    return rawMarkup
  }
  props = { ...props, key: id }
  const editorSetting = card.settingDtos.find(x => x.key === 'editorSetting')
  if (editorSetting && editorSetting.value == 'markdownEditor') {
    const newCardText = markdownToHtml(card.text)
    props = {
      ...props,
      card: {
        ...card,
        text: newCardText,
      },
    }
  }

  switch (type) {
    case AnswerItemType.INFORM:
      return <Inform {...props} />
    case AnswerItemType.TESTINGSINGLECHOICE:
      return <Question {...props} />
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYSINGLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYFREEFORM:
      return <Inform {...props} />
    case AnswerItemType.SURVEYINPUT:
      return <Inform {...props} />
    case AnswerItemType.SURVEYNUMERICENTRY:
      return <Inform {...props} />
    case AnswerItemType.SURVEYSTARS:
      return <Stars {...props} />

    case AnswerItemType.SIGNUP:
      return <NotSupported {...props} />
    case AnswerItemType.STRIPEPAYMENT:
      return <NotSupported {...props} />
    case AnswerItemType.RESULT:
      return <NotSupported {...props} />
    case AnswerItemType.SCORECARD:
      return <NotSupported {...props} />
    case AnswerItemType.SERTIFIERCARD:
      return <NotSupported {...props} />
    case AnswerItemType.PROTECTIONCARD:
      return <NotSupported {...props} />
    case AnswerItemType.RESULTDEFAULT:
      return <NotSupported {...props} />
    case AnswerItemType.AI:
      return <Inform {...props} />
    default:
      return <Inform {...props} />
  }
}

export default CardTypeFactory
