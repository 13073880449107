import {
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS,
  AUTHENTICATION_REFRESH_BUSY,
  AUTHENTICATION_REFRESH_ERROR,
  AUTHENTICATION_REFRESH_SUCCESS,
  AUTHENTICATION_CURRENT_USER_INFO_SUCCESS,
} from './type'

import { set as setCookie, remove as removeCookie } from 'es-cookie'
export default function (
  state = { me: null, refreshTokenBusy: false },
  action,
) {
  const { payload } = action

  switch (action.type) {
    case AUTHENTICATION_LOGIN_USER_SUCCESS:
      const { user } = payload

      setCookie('token', user.accessToken, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })
      setCookie('refreshToken', user.refreshToken, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })
      setCookie('userId', user.userId, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })

      window.location.href = '/'
      return { ...state, user }
    case AUTHENTICATION_LOGOUT_SUCCESS:
      return { ...state }
    case AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS:
      return { ...state }
    case AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return { ...state }
    case AUTHENTICATION_REFRESH_BUSY:
      return { ...state, refreshTokenBusy: true }
    case AUTHENTICATION_REFRESH_SUCCESS:
      return { ...state, refreshTokenBusy: false }
    case AUTHENTICATION_REFRESH_ERROR:
      return { ...state, refreshTokenBusy: false }
    case AUTHENTICATION_CURRENT_USER_INFO_SUCCESS:
      return { ...state, me: payload.me }
    default:
      return state
  }
}
