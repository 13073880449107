import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ChangePasswordForm from '../../container/form-change-password'
import { authenticationVerifyChangePasswordAction } from '../../reducers/authentication/action'
// material
import { withStyles, Grid, Alert, CircularProgress } from '@material-ui/core'
import styles from './styles'
import translator from 'src/translate'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function ChangePassword(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const [linkCode, setLinkCode] = useState(props.match.params.linkCode)
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(200)
  const [email, setEmail] = useState('')

  useEffect(() => {
    setVerified(300)
    dispatch(
      authenticationVerifyChangePasswordAction(linkCode, response => {
        response && setVerified(response.statusCode)
        setEmail(response.data)
        setLoading(false)
      }),
    )
    setTimeout(() => setLoading(false), 2000)
  }, [])
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={6} className={classes.holder}>
        {loading == true && (
          <div className={classes.circularProgress}>
            <CircularProgress />
          </div>
        )}

        {loading == false && verified === 200 && (
          <ChangePasswordForm
            dispatch={dispatch}
            hasHeader={true}
            email={email}
          />
        )}

        {verified !== 200 && loading == false && (
          <div className={classes.alert}>
            <Alert severity="error" style={{ width: '100%' }}>
              This link is invalid! Click here to go <a href="/">login</a> page!
            </Alert>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ChangePassword)
