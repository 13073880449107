import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from '../styles'

import UserType from 'src/model/user-types'

const Badge = props => {
  const { type, classes } = props

  const premiumControl = () => {
    const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]

    if (
      (userControl == 'Regular Admin Account' ||
        userControl == 'Starter' ||
        userControl == 'StarterStarter - Legacy' ||
        userControl == 'Starter - A') &&
      type == 1
    ) {
      return {
        text: 'Premium',
        textColor: '#015FFB',
        backgroundColor: '#015ffb14',
        width: '60px',
      }
    }
    if (
      !(
        userControl == 'Premium Plus' ||
        userControl == 'Premium Plus v2' ||
        userControl == 'Premium - A' ||
        userControl == 'Appsumo (Tier 3)' ||
        userControl == 'Appsumo (Tier 3A)' ||
        userControl == 'Appsumo (Tier 4)' ||
        userControl == 'Appsumo (Tier 5)'
      ) &&
      type == 2
    ) {
      return {
        text: 'Pro',
        textColor: '#9747FF',
        backgroundColor: '#9747ff14',
        width: '30px',
      }
    }
    if (type == 3) {
      return {
        text: 'Enterprise',
        textColor: '#d5a831',
        backgroundColor: '#ffb74714',
        width: '70px',
      }
    }
    if (
      !(
        userControl == 'Premium Plus' ||
        userControl == 'Premium Plus v2' ||
        userControl == 'Premium - A' ||
        userControl == 'Premium' ||
        userControl == 'Premium v2' ||
        userControl == 'Appsumo (Tier 2)' ||
        userControl == 'Appsumo (Tier 2A)' ||
        userControl == 'Appsumo (Tier 3)' ||
        userControl == 'Appsumo (Tier 3A)' ||
        userControl == 'Appsumo (Tier 4)' ||
        userControl == 'Appsumo (Tier 5)'
      ) &&
      type == 4
    ) {
      return {
        text: 'Premium',
        textColor: '#015FFB',
        backgroundColor: '#015ffb14',
        width: '60px',
      }
    }
  }
  const viewContents = premiumControl()

  return viewContents ? (
    <div
      className={classes.badge}
      style={{
        backgroundColor: viewContents.backgroundColor,
        width: viewContents.width,
      }}
    >
      <Typography
        fontSize={'11px'}
        fontWeight={500}
        style={{ color: viewContents.textColor }}
      >
        {viewContents.text}
      </Typography>
    </div>
  ) : null
}

export default withStyles(styles)(Badge)
