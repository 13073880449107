import React, { useCallback, useState, useEffect } from 'react'
import CreateButtonView from 'src/components/create-button-view'
import { useDispatch, useSelector } from 'react-redux'
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { LimitTypeEnum } from 'src/model/limit'
import { getUserLimits } from 'src/api/limit'

import { KanbanCourseCard } from './components'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { editCourseCollectionIndexAction } from 'src/reducers/course-collection/action'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
const CourseView = props => {
  const {
    classes,
    containers,
    setContainers,
    draggedCourseId,
    setDraggedCourseId,
    hoveredCollectionId,
    setHoveredCollectionId,
    open,
    setOpen,
  } = props

  const dispatch = useDispatch()

  const selectedCollectionId = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const [selectedCollection, setSelectedCollection] = useState(
    containers.find(container => container.id === selectedCollectionId),
  )

  const [courses, setCourses] = useState(
    selectedCollection &&
      containers.length > 0 &&
      containers.filter(x => x.id == selectedCollection.id)[0].courses,
  )

  function findValueOfItems(id, type) {
    if (type === 'container') {
      // Directly return the container if it's found
      return containers.find(container => container.id === id)
    } else if (type === 'item') {
      // Loop through each container to find the item
      for (const container of containers) {
        const item = container.courses.find(x => x.id == id)

        if (item) {
          // Return the found item, not the container

          return container
        }
      }
    }
    return null // Return null if nothing is found
  }
  function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
    return JSON.stringify(arr)
  }

  useEffect(() => {
    setSelectedCollection(
      containers.find(container => container.id === selectedCollectionId),
    )
    setCourses(
      containers.find(container => container.id === selectedCollectionId) &&
        containers.length > 0 &&
        containers.filter(
          x =>
            x.id ==
            containers.find(container => container.id === selectedCollectionId)
              .id,
        )[0].courses,
    )
  }, [selectedCollectionId, containers, selectedCollection])

  const [changedCourses, setChangedCourses] = React.useState([])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragMove = useCallback(
    event => {
      const { active, over } = event

      // Handle Items Sorting
      if (draggedCourseId !== active.id) return
      /*   if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('container') &&
        open == false
      ) {
        setOpen(true)
      } */

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('item') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        // Find the active container and over container
        const activeContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
        )
        const overContainer = findValueOfItems(
          over.id.split('-item')[0],
          'item',
        )
        // If the active or over container is not found, return
        if (!activeContainer || !overContainer) return
        // Find the index of the active and over container
        const activeContainerIndex = containers.findIndex(
          container => container.id === activeContainer.id,
        )
        const overContainerIndex = containers.findIndex(
          container => container.id === overContainer.id,
        )
        // Find the index of the active and over item
        const activeitemIndex = activeContainer.courses.findIndex(
          item => item.id.toString() === active.id.split('-item')[0],
        )
        const overitemIndex = overContainer.courses.findIndex(
          item => item.id.toString() === over.id.split('-item')[0],
        )
        // In the same container
        if (activeContainerIndex === overContainerIndex) {
          let newItems = containers
          newItems[activeContainerIndex].courses = JSON.parse(
            arrayMove(
              newItems[activeContainerIndex].courses,
              activeitemIndex,
              overitemIndex,
            ),
          )
          newItems = newItems.map((container, containerIndex) => {
            if (containerIndex === activeContainerIndex) {
              return {
                ...container,
                courses: container.courses.map((course, index) => ({
                  ...course,
                  courseCollectionOrder: index,
                })),
              }
            }
            return container
          })

          //update newItems courses courseColelctionOrder

          setChangedCourses(newItems[activeContainerIndex].courses)
          setContainers(newItems)
        }
        return
      }

      //Handle Moving course into another container

      //cannot make open false again because it does not update.
    },
    [draggedCourseId],
  )
  const handleDragStart = useCallback(
    event => {
      const { active } = event
      const { id } = active
      draggedCourseId == null && setDraggedCourseId(id)
    },
    [draggedCourseId],
  )

  const handleDragEnd = () => {
    if (changedCourses.length !== 0) {
      dispatch(
        editCourseCollectionIndexAction({
          courseDtos: changedCourses,
        }),
      )
      setDraggedCourseId(null)
      setChangedCourses([])
    }
  }
  const [isUserReachedCourseLimit, setIsUserReachedCourseLimit] = useState(
    'Initial',
  )
  useEffect(() => {
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).limit.upperLimit

      count >= limit && limit != -1
        ? setIsUserReachedCourseLimit(true)
        : setIsUserReachedCourseLimit(false)
    })
  }, [])

  return (
    <div className={classes.container}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragStart={handleDragStart}
        strategy={verticalListSortingStrategy}
      >
       {selectedCollectionId != DEFAULT_COLLECTION_GUID && <CreateButtonView
          dispatch={dispatch}
          id={selectedCollection && selectedCollection.id}
          isUserReachedCourseLimit={isUserReachedCourseLimit}
        />
}
        {courses && courses.length > 0 && (
          <SortableContext items={courses.map(course => `${course.id}-item`)}>
            {courses.map((course, index) => (
              <KanbanCourseCard
                key={course.id}
                course={course}
                index={index}
                setHoveredCollectionId={setHoveredCollectionId}
                hoveredCollectionId={hoveredCollectionId}
                draggedCourseId={draggedCourseId}
                containers={containers}
                setContainers={setContainers}
                open={open}
                setOpen={setOpen}
              />
            ))}
          </SortableContext>
        )}
      </DndContext>
    </div>
  )
}

export default withStyles(styles)(CourseView)
