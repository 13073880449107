import { put, call, take, fork } from 'redux-saga/effects'
import { excelExportAutoSenderReportService } from '../../api/excel-report'
import FileDownload from 'js-file-download'
import { ASSIGNMENT_REPORT } from '../../reducers/assignment/type'
import { assignmentReportSuccess } from '../../reducers/assignment/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentReportFlow(payload) {
  try {
    const { id } = payload

    const response = yield call(excelExportAutoSenderReportService, id)

    yield put(assignmentReportSuccess())

    FileDownload(response, 'report.xlsx')

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentListWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_REPORT)
    const { payload } = action

    yield fork(generalSaga, assignmentReportFlow, payload)
  }
}
