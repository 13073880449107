import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core'
import { ProviderContent, StepZero, StepOne, StepTwo } from '..'
import styles from '../../styles'
import { useDispatch } from 'react-redux'
function getSteps() {
  return ['Create Payment Link ', 'Add Success URL', 'Add Webhook']
}
function getStepContent(
  index,
  content,
  handleNext,
  handleBack,
  entity,
  paymentLink,
  setPaymentLink,
  paymentSettingsConfigured,
  setPaymentSettingsConfigured,
  paymentProvider,
) {
  switch (index) {
    case 0:
      return (
        <StepZero
          entity={entity}
          videoUrl={content[index]}
          handleNext={handleNext}
          paymentLink={paymentLink}
          setPaymentLink={setPaymentLink}
        />
      )
    case 1:
      return (
        <StepOne
          videoUrl={content[index]}
          handleNext={handleNext}
          handleBack={handleBack}
          entity={entity}
        />
      )
    case 2:
      return (
        <StepTwo
          handleBack={handleBack}
          videoUrl={content[index]}
          entity={entity}
          handleNext={handleNext}
          paymentSettingsConfigured={paymentSettingsConfigured}
          setPaymentSettingsProvider={setPaymentSettingsConfigured}
          paymentProvider={paymentProvider}
        />
      )
    default:
      return 'Unknown step'
  }
}

function StepperContent(props) {
  const {
    entity,
    paymentProvider,
    paymentLink,
    setPaymentLink,
    paymentSettingsConfigured,
    setPaymentSettingsConfigured,
    onClickDone,
    classes,
  } = props
  const dispatch = useDispatch()
  const content = ProviderContent(paymentProvider)
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)

    if (activeStep + 1 === steps.length) {
      onClickDone(true)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  const setStep = step => {
    setActiveStep(step)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              '& .MuiStepLabel-root .MuiStepIcon-completed': {
                color: '#29D25D', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                color: '#29D25D', // Just text label (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#fff', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                color: '#29D25D', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#002E47', // circle's number (ACTIVE)
              },
              '& .MuiSvgIcon-root-MuiStepIcon-root.MuiStepIcon-completed': {
                color: '#29D25D',
              },
            }}
          >
            <StepLabel onClick={() => setStep(index)}>
              <Typography color={'#002E47'} fontWeight={500} fontSize={'15px'}>
                {label}
              </Typography>
            </StepLabel>
            <StepContent>
              {getStepContent(
                index,
                content,
                handleNext,
                handleBack,
                entity,
                paymentLink,
                setPaymentLink,
                paymentSettingsConfigured,
                setPaymentSettingsConfigured,
                paymentProvider,
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <div
          style={{
            width: '440px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={classes.stepTwo_main_completedDiv}>
            <img
              src="/assets/done-payment-steps.png"
              alt="done"
              width={'90px'}
              height={'90px'}
            />
            <Typography fontSize={'15px'} fontWeight={600}>
              Congratulations! 🎉
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color="#637381"
              textAlign={'center'}
            >
              You have successfully completed all the steps. <br />
              You're ready to share your link and get paid!
            </Typography>
            <Button
              variant="contained"
              className={classes.okayButton}
              onClick={() => onClickDone()}
            >
              Okay
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(StepperContent)
