const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  root_content: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    gap: 16,
    borderRadius: 8,
    padding: 12,
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1px solid #015FFB !important',
      cursor: 'pointer',
    },
  },
  root_content_text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  root_content_text_title: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#002E47',
  },
  root_content_text_description: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#637381',
  },
  switch:{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
  },
  certicationLock: {
    backgroundColor:"white", position:"absolute", height:1500, width:'100%', zIndex:1000, opacity:.5, marginTop:93
  }
})

export default styles
