import React from 'react'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  Button,
  Alert,
  AlertTitle,
  withStyles,
} from '@material-ui/core'
import translator from 'src/translate'
import styles from '../style'
const CaseOne = props => {
  const {
    courses,
    collections,
    loading,
    error,
    verified,
    domain,
    setDomain,
    type,
    courseId,
    collectionId,
    courseName,
    collectionName,
    handleType,
    handleCourse,
    handleCollection,
    handleAddDomain,
    classes,
  } = props
  return (
    <Box autoComplete="off">
      <Box m={2}></Box>
      <TextField
        label="Domain"
        placeholder="Exp: app.yourdomain.com"
        className={classes.textField}
        onChange={e => {
          setDomain(e.target.value)
        }}
        value={domain}
      />
      <Box m={2}></Box>
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel id="demo-simple-select-helper-label">
          Choose default data
        </InputLabel>
        <Select
          classes={{ select: classes.combo }}
          inputProps={{
            className: classes.input,
          }}
          fullWidth
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Choose your course"
          value={type}
          disabled={false}
          input={<Input id="select-input" name="select-input" />}
          onChange={handleType}
        >
          <MenuItem value={'Course'}>Course</MenuItem>
          <MenuItem value={'Collection'}>Collection</MenuItem>
        </Select>
      </FormControl>
      <Box m={2}></Box>

      {type && type == 'Course' && (
        <FormControl fullWidth={true} variant="outlined">
          <InputLabel id="demo-simple-select-helper-label">
            {translator._('customDomain.chooseCourse')}
          </InputLabel>
          <Select
            classes={{ select: classes.combo }}
            inputProps={{
              className: classes.input,
            }}
            fullWidth
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Choose your course"
            key={courseId}
            value={courseName}
            disabled={false}
            input={<Input id="select-input" name="select-input" />}
            onChange={handleCourse}
          >
            {courses &&
              courses.map(value => {
                return (
                  <MenuItem value={value.id} key={value.name}>
                    {value.name}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      )}
      {type && type == 'Collection' && (
        <FormControl fullWidth={true} variant="outlined">
          <InputLabel id="demo-simple-select-helper-label">
            Choose your default collection
          </InputLabel>
          <Select
            classes={{ select: classes.combo }}
            inputProps={{
              className: classes.input,
            }}
            fullWidth
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Choose your course"
            key={collectionId}
            value={collectionName}
            disabled={false}
            input={<Input id="select-input" name="select-input" />}
            onChange={handleCollection}
          >
            {collections &&
              collections.map(value => {
                return (
                  <MenuItem value={value.id} key={value.collectionName}>
                    {value.collectionName}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      )}

      {!verified && (
        <Alert severity="error">
          <AlertTitle>{translator._('customDomain.addCname')}</AlertTitle>
          {translator._('customDomain.cName')}
        </Alert>
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>{translator._('customDomain.alertTitle')}</AlertTitle>
          {translator._('customDomain.alertMessage')}
        </Alert>
      )}
      {loading && <CircularProgress />}
      {!loading && (
        <Button
          variant="contained"
          color="primary"
          className={classes.next}
          onClick={() => handleAddDomain()}
          disabled={!(domain && (courseId || collectionId))}
        >
          {translator._('customDomain.addDomain')}
        </Button>
      )}
    </Box>
  )
}

export default withStyles(styles)(CaseOne)
