import React from 'react'
import Html from 'react-pdf-html'
import URL from 'src/api/url'

import ReactPDF, { View, Image } from '@react-pdf/renderer'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card } = props
  let srcUrl = ""
  if (
    page !== 0 &&
    card.mediaDtos &&
    card.mediaDtos.length > 0 &&
    card.mediaDtos[0].mediaType == 1 &&
    !card.mediaDtos[0].mediaUrl.includes('gif')
  ) {
        srcUrl = card.mediaDtos[0].mediaUrl.includes('https') ?  card.mediaDtos[0].mediaUrl :  URL.CDN + card.mediaDtos[0].mediaUrl +  '?not-from-cache-please2'

  } else {
    srcUrl = ''
  }

  return (
    <View style={stylesheet.informView}>
      {page !== 0 &&
        card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('gif') && (
          <Image style={stylesheet.contentImage} src={srcUrl} cache={false} />
        )}
      <Html stylesheet={stylesheet} style={style}>
        {editor && editor.getContents && page !== 0
          ? editor.getContents().replace('figure', 'p')
          : card.text.replace('figure', 'p')}
      </Html>
    </View>
  )
}

export default Inform
