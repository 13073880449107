import React, { useState } from 'react'
import {
  Typography,
  TextField,
  withStyles,
  Modal,
  Button,
} from '@material-ui/core'
import styles from './styles'
import { useDispatch } from 'react-redux'
import { ModalContent, BadgeGrid } from './components'
import { useBadgeSettings } from './logic/use-badge-settings'
import UserType from 'src/model/user-types'

const BadgeImage = props => {
  const { classes, badges, badgeSettings, settings } = props
  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]

  const {
    ribbonText,
    content,
    selectedBadge,
    modalOpen,
    setModalOpen,
    handleSelectedBadge,
    handleRibbonText,
    handleContent,
    handlePopupOpen,
    handleContentText,
    handleRibbon,
    handleDrawerClose,
  } = useBadgeSettings(badgeSettings, settings)

  return (
    <div className={classes.container}>
      <Typography className={classes.heading}>Customize Badge Image</Typography>
      <div className={classes.inputRow}>
        <TextFieldWithCharCount
          label="Content"
          value={content}
          handleChange={handleContentText}
          handleBlur={handleContent}
          maxLength={28}
          classes={classes}
        />
        <TextFieldWithCharCount
          label="Ribbon Text"
          value={ribbonText}
          handleChange={handleRibbon}
          handleBlur={handleRibbonText}
          maxLength={12}
          classes={classes}
        />
      </div>
      <Typography className={classes.badgeStyleHeading}>Badge Style</Typography>
      <BadgeGrid
        badges={badges}
        userControl={userControl}
        selectedBadge={selectedBadge}
        handleSelectedBadge={handleSelectedBadge}
        handlePopupOpen={handlePopupOpen}
        ribbonText={ribbonText}
        content={content}
        classes={classes}
      />
      {userControl === 'Regular Admin Account' ? (
        <UpgradeMessage classes={classes} />
      ) : (
        <NoLogoMessage
          classes={classes}
          handleDrawerClose={handleDrawerClose}
        />
      )}
      <Modal
        open={modalOpen}
        onClose={handlePopupOpen}
        className={classes.modal}
      >
        <ModalContent setModalOpen={setModalOpen} />
      </Modal>
    </div>
  )
}

const TextFieldWithCharCount = ({
  label,
  value,
  handleChange,
  handleBlur,
  maxLength,
  classes,
}) => (
  <div className={classes.inputField}>
    <TextField
      label={label}
      value={value}
      onChange={e => handleChange(e.target.value)}
      onBlur={e => handleBlur(e.target.value)}
      className={classes.textField}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
    />
    <div className={classes.charCount}>
      <Typography className={classes.charCountText}>
        {value.length} / {maxLength}
      </Typography>
    </div>
  </div>
)

const UpgradeMessage = ({ classes }) => (
  <Typography className={classes.tipMessage}>
    To select badge styles and add your logo, you can upgrade to paid plans.
    <span
      onClick={() => {
        window.open('/pricing', '_self')
      }}
      className={classes.settingDrawerOpenSpan}
    >
      {' '}
      Click to upgrade.
    </span>
  </Typography>
)

const NoLogoMessage = ({ classes, handleDrawerClose }) => (
  <Typography className={classes.tipMessage}>
    If you do not have a logo, it will be left blank. You can add a logo in
    <span
      onClick={() => {
        handleDrawerClose()
      }}
      className={classes.settingDrawerOpenSpan}
    >
      {' '}
      the mini-course settings.
    </span>
  </Typography>
)

export default withStyles(styles)(BadgeImage)
