import { put, call, take, fork } from 'redux-saga/effects'
import { meService } from '../../api/authentication'
import { authenticationCurrentUserInfoSuccess } from '../../reducers/authentication/action'
import { AUTHENTICATION_CURRENT_USER_INFO } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

import * as amplitude from '@amplitude/analytics-browser'
import HMACObj from 'hmac-obj'
import { getLocalizationStringOfTypeService } from 'src/api/common'

/**
 *
 * @param {*} payload
 */
function* currentUserInfoSagaFlow() {
  try {
    const resMe = yield call(meService)
    const res = yield call(getLocalizationStringOfTypeService, 'course-ui')

    var groupBy = function (xs, keyFunc) {
      return xs.reduce(function (rv, x) {
        var key = keyFunc(x)
        ;(rv[key] = rv[key] || []).push(x)
        return rv
      }, {})
    }

    // Usage
    var localizationStringsGroupedByKeys = groupBy(
      res.data.localizationStrings,
      function (item) {
        return item.countryCode
      },
    )
    window.CONSTANTS = {
      TIME_ZONE: resMe.data.timeZone,
      DATE_FORMAT: resMe.data.dateFormat,
      USER_NAME: resMe.data.userName,
      NAME: resMe.data.name,
      SUR_NAME: resMe.data.surname == null ? '' : resMe.data.surname,
      COMPANY_NAME: resMe.data.companyName,
      COMPANY_LOGO: resMe.data.companyLogo,
      EMAIL: resMe.data.email,
      USER_ROLE_ID: resMe.data.userRoleId,
      CUSTOM_DOMAIN: resMe.data.lastCustomDomain,
      LANGUAGES: res.data.languages,
      LOCALIZATION_STRINGS: localizationStringsGroupedByKeys,
    }

    amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, resMe.data.userName)
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('Plan_Type', resMe.data.userRoleString)
    amplitude.identify(identifyEvent)

    const hmacSHA256 = yield HMACObj.new(
      '1kX6aGrFBj9ln90QhCDmNqV9Yt7bGLKcwnHA1kMK',
      resMe.data.userName,
      'SHA-256',
    )

    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'a1mmx6vo',
      name: resMe.data.name + ' ' + resMe.data.surname, // Full name
      email: resMe.data.userName, // Email address
      userRole: resMe.data.userRoleString,
      user_hash: hmacSHA256.hexdigest(),
    })

    yield put(authenticationCurrentUserInfoSuccess(resMe.data))
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* currentUserInfoSagaWatcher() {
  while (true) {
    const action = yield take(AUTHENTICATION_CURRENT_USER_INFO)
    const { payload } = action

    yield fork(generalSaga, currentUserInfoSagaFlow, payload)
  }
}
