const AUTHENTICATION_LOGIN_USER = 'AUTHENTICATION_LOGIN_USER'
const AUTHENTICATION_LOGIN_USER_SUCCESS = 'AUTHENTICATION_LOGIN_USER_SUCCESS'
const AUTHENTICATION_LOGIN_USER_ERROR = 'AUTHENTICATION_LOGIN_USER_ERROR'
const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT'
const AUTHENTICATION_LOGOUT_SUCCESS = 'AUTHENTICATION_LOGOUT_SUCCESS'
const AUTHENTICATION_LOGOUT_ERROR = 'AUTHENTICATION_LOGOUT_ERROR'
const AUTHENTICATION_DELETE = 'AUTHENTICATION_DELETE'
const AUTHENTICATION_DELETE_SUCCESS = 'AUTHENTICATION_DELETE_SUCCESS'
const AUTHENTICATION_DELETE_ERROR = 'AUTHENTICATION_DELETE_ERROR'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS'
const AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR =
  'AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE = 'VERIFY_CHANGE_PASSWORD_CODE'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS =
  'VERIFY_CHANGE_PASSWORD_CODE_SUCCESS'
const AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR =
  'VERIFY_CHANGE_PASSWORD_CODE_ERROR'
  const AUTHENTICATION_REFRESH = 'AUTHENTICATION_REFRESH'
  const AUTHENTICATION_REFRESH_SUCCESS = 'AUTHENTICATION_REFRESH_SUCCESS'
  const AUTHENTICATION_REFRESH_ERROR = 'AUTHENTICATION_REFRESH_ERROR'
  const AUTHENTICATION_REFRESH_BUSY = 'AUTHENTICATION_REFRESH_BUSY'
  const AUTHENTICATION_CURRENT_USER_INFO = 'AUTHENTICATION_CURRENT_USER_INFO'
  const AUTHENTICATION_CURRENT_USER_INFO_SUCCESS = 'AUTHENTICATION_CURRENT_USER_INFO_SUCCESS'
  const AUTHENTICATION_CURRENT_USER_INFO_ERROR = 'AUTHENTICATION_CURRENT_USER_INFO_ERROR'

export {
  AUTHENTICATION_LOGIN_USER,
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGIN_USER_ERROR,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT_ERROR,
  AUTHENTICATION_DELETE,
  AUTHENTICATION_DELETE_SUCCESS,
  AUTHENTICATION_DELETE_ERROR,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_SUCCESS,
  AUTHENTICATION_FORGOT_PASSWORD_EMAIL_ERROR,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_SUCCESS,
  AUTHENTICATION_VERIFY_CHANGE_PASSWORD_CODE_ERROR,
  AUTHENTICATION_REFRESH,
  AUTHENTICATION_REFRESH_SUCCESS,
  AUTHENTICATION_REFRESH_ERROR,
  AUTHENTICATION_REFRESH_BUSY,
  AUTHENTICATION_CURRENT_USER_INFO,
  AUTHENTICATION_CURRENT_USER_INFO_SUCCESS,
  AUTHENTICATION_CURRENT_USER_INFO_ERROR

}
