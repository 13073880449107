const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '-2rem',
    },
  },
  questionViewContainer: {
    background: '#f4f6f8',
    borderRadius: 20,
    marginTop: 16,
    paddingRight: 16,
    justifyContent: 'start',
    alignItems: 'start',
  },
  buttonBarGrid: {
    width: '100%',
    height: '54px',
  },
  questionListRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export default styles
