import { put, call, take, fork, select } from 'redux-saga/effects'
import { teamsExportService } from 'src/api/teams'
//import { questionPatchSuccess } from '../../reducers/question/action'
//import { getCourseDocumentByIdSuccess } from '../../reducers/assignment/action';
import FileDownload from 'js-file-download'

import { TEAMS_MEMBER_EXPORT } from 'src/reducers/teams/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* teamsMemberExportFlow(payload) {
  try {
    const { teamsId, callback } = payload
    const response = yield call(teamsExportService, teamsId)
    const teamName = yield select(state => state.teamsList.memberList.teamName)
    const teamNamesFolderName = teamName.replaceAll(' ', '_')
    yield put(operationSuccess())

    FileDownload(response, `${teamNamesFolderName}_learners.csv`)

    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* teamsMemberExportWatcher() {
  while (true) {
    const action = yield take(TEAMS_MEMBER_EXPORT)
    const { payload } = action

    yield fork(generalSaga, teamsMemberExportFlow, payload)
  }
}
