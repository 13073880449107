import {
  CREATE_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_COLLECTION_SUCCESS,
  LIST_COURSE_COLLECTION,
  LIST_COURSE_COLLECTION_SUCCESS,
  LIST_COURSE_COLLECTION_ERROR,
  UPDATE_COURSE_COLLECTION_SUCCESS,
  EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS,
  UPDATE_COURSE_COLLECTION_ERROR,
  ASSIGNMENT_CLONE_SUCCESS,
  ASSIGNMENT_DELETE_SUCCESS,
  COLLECTION_COURSE_PATCH,
  PATCH_COURSE_COLLECTION_SUCCESS,
  GET_COLLECTION_SETTINGS_SUCCESS,
  PATCH_COLLECTION_SETTINGS_SUCCESS,
  DRAWER_OPEN,
  SET_SELECTED_COLLECTION,
  SET_SELECTED_PAGE_VIEW,
  COURSE_TRANSPORT_CLICKED,
  TRANSPORTING_COURSE_SELECT,
} from './type'

export default function (
  state = {
    list: { courseDtos: [], courseCollectionDtos: [] },
    reportDataTeamData: { results: null, totalCount: 0 },
    reportDataTeamPerformance: { results: null, totalCount: 0 },
    reportDataUser: { results: null, totalCount: 0 },
    reportDataRelatedSubjectList: null,
    entity: null,
    query: null,
    collectionSettings: {},
    reportDataCardValues: [],
    drawerOpen: false,
    selectedCollection: null,
    selectedPageView: 'Mini Courses',
    courseTransportClicked: false,
  },
  action,
) {
  const { payload, type } = action
  const recursive = (row, id, response) => {
    if (row.id === id) {
      row.rowChildList = response
    }

    if (row.rowChildList == null) {
      return null
    }

    row.rowChildList.results.forEach(childRow => {
      recursive(childRow, id, response)
    })
  }
  switch (type) {
    case CREATE_COURSE_COLLECTION_SUCCESS:
      let newFolderDto = payload.response.data
      let collectionList = [...state.list.courseCollectionDtos]
      if (
        collectionList.length > 0 &&
        collectionList[0].collectionName == 'Templates'
      ) {
        collectionList.splice(1, 0, newFolderDto)
        state = {
          ...state,
          list: {
            ...state.list,
            courseCollectionDtos: [...collectionList],
          },
        }
      } else {
        state = {
          ...state,
          list: {
            ...state.list,
            courseCollectionDtos: [newFolderDto, ...collectionList],
          },
        }
      }
      return state

    case DELETE_COURSE_COLLECTION_SUCCESS:
      let collectionId = payload.response
      state = {
        ...state,
        list: {
          ...state.list,
          courseCollectionDtos: state.list.courseCollectionDtos.filter(
            collection => {
              return collection.id !== collectionId
            },
          ),
        },
      }

      return state
    case LIST_COURSE_COLLECTION:
      return { ...state, query: payload.query }
    case LIST_COURSE_COLLECTION_SUCCESS:
      return { ...state, list: payload.response }
    case UPDATE_COURSE_COLLECTION_SUCCESS:
      return { ...state }
    case LIST_COURSE_COLLECTION_ERROR:
      return { ...state, response: null }
    case UPDATE_COURSE_COLLECTION_ERROR:
      return { ...state, entity: null }
    case EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX_SUCCESS:
      return state
    case ASSIGNMENT_CLONE_SUCCESS:
      state = {
        ...state,
        list: {
          ...state.list,
          courseDtos: [payload.entity].concat(state.list.courseDtos),
        },
      }
      state.list.courseDtos.sort((a, b) => {
        return a.courseCollectionId - b.courseCollectionId
      })

      return state
    case ASSIGNMENT_DELETE_SUCCESS:
      const deletedCourseId = payload.response
      state = {
        ...state,
        list: {
          ...state.list,
          courseDtos: state.list.courseDtos.filter(course => {
            return course.id !== deletedCourseId
          }),
        },
      }

      return state
    case COLLECTION_COURSE_PATCH:
      let patchedCourse = state.list.courseDtos.filter(
        x => x.id == payload.content._id,
      )[0]

      payload.content._contents.map(value => {
        patchedCourse[value._path] = value.value
      })
      state = {
        ...state,
        list: {
          ...state.list,
          courseDtos: state.list.courseDtos.filter(course => {
            return course.id == payload.content._id
              ? (course = patchedCourse)
              : course
          }),
        },
      }
      return state
    case PATCH_COURSE_COLLECTION_SUCCESS:
      let patchedCollection = state.list.courseCollectionDtos.filter(
        x => x.id == payload.content._id,
      )[0]

      payload.content._contents.map(value => {
        patchedCollection[value._path] = value.value
      })
      state = {
        ...state,
        list: {
          ...state.list,
          courseCollectionDtos: state.list.courseCollectionDtos.filter(
            course => {
              return course.id == payload.content._id
                ? (course = patchedCollection)
                : course
            },
          ),
        },
      }
      return state
    case GET_COLLECTION_SETTINGS_SUCCESS:
      state = {
        ...state,
        collectionSettings: payload.response,
      }
      return state

    case PATCH_COLLECTION_SETTINGS_SUCCESS:
      var newCollectionSettings = state.collectionSettings
      payload.content._contents.map(value => {
        newCollectionSettings[value._path] = value._value
      })

      state = {
        ...state,
        collectionSettings: newCollectionSettings,
      }

      return state
    case DRAWER_OPEN:
      state = {
        ...state,
        drawerOpen: !state.drawerOpen,
      }
      return state
    case SET_SELECTED_COLLECTION:
      state = {
        ...state,
        selectedCollection: payload.collectionId,
      }
      return state
    case SET_SELECTED_PAGE_VIEW:
      state = {
        ...state,
        selectedPageView: payload.pageView,
      }
      return state
    case COURSE_TRANSPORT_CLICKED:
      state = {
        ...state,
        courseTransportClicked: !state.courseTransportClicked,
      }
      return state
    case TRANSPORTING_COURSE_SELECT:
      state = {
        ...state,
        selectedCourse: payload.courseId,
      }
      return state
    default:
      return state
  }
}
