const styles = theme => ({
  badgeGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '8px',
    width: '100%',
    boxSizing: 'border-box',
  },
  badgeItem: {
    borderRadius: 8,
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#fff !important',
      outline: '1px solid #015FFB !important',
    },
  },
  innerHtmlContainer: {
    width: '120px',
    height: '120px',
  },
})

export default styles
