import React, { useEffect } from 'react'
import styles from './styles'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

function LinearDeterminate(props) {
  const classes = useStyles()
  const { progressedCardCount, outlineOptionsLength } = props
  const progress = (progressedCardCount / outlineOptionsLength) * 100
  const buffer = (progressedCardCount + 1 / outlineOptionsLength) * 100
  return (
    <div className={classes.root}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </div>
  )
}

const AiLoading = props => {
  const { progressedCardCount, outlineOptionsLength } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          maxWidth: '300px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src="/assets/new_mascot_noline_1.gif"
          height={200}
          style={{ margin: '0 auto' }}
        ></img>
        <Box m={0.5}></Box>
        <LinearDeterminate
          progressedCardCount={progressedCardCount}
          outlineOptionsLength={outlineOptionsLength + 1}
        ></LinearDeterminate>
        <Box m={0.5}></Box>
        <Typography variant="h4" color="primary">
          AI Assistant is in action!
        </Typography>
        <Typography variant="subtitle" style={{ paddingBottom: '10px' }}>
          Please wait, AI can take up to 1 minute.
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(AiLoading)
