const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F4F6F8',
    width: '60%',
    borderRadius: '16px',
    padding: '40px',
    gap: 16,
    alignItems: 'center',
  },
  root_img: {
    width: '80%',
  },
  root_text: {
    width: '100%',
  },
})

export default styles
