const PaymentOptions = [
  {
    type: 'Stripe',
    value: 'stripe',
    icon: '/assets/provider-icons/stripe.png',
  },
  {
    type: 'Lemon Squeezy',
    value: 'lemon_squeeze',
    icon: '/assets/provider-icons/lemon.png',
  },
  {
    type: 'Pabbly',
    value: 'pabbly',
    icon: '/assets/provider-icons/pabbly.png',
  },
  /*{
    type: 'Paystack',
    value: 'pay_stack',
    icon: '/assets/provider-icons/paystack.png',
  },*/
]

export default PaymentOptions
