import React from 'react'
import styles from '../style'
import { Grid, Typography, withStyles } from '@material-ui/core'
import translator from 'src/translate'
import { AnswerItemType } from 'src/model/question'
const BasicOptions = [
  {
    name: 'star-rating',
    icon: '/assets/icons/task/star-rating.png',
    title: 'Star-rating',
    description: translator._('tasks.starRating.description'),
    description2: translator._('tasks.starRating.description2'),
    exampleImage: '/assets/icons/task/star-rating-example.png',
    dataType: AnswerItemType.SURVEYSTARS,
  },
  {
    name: 'free-form',
    icon: '/assets/icons/task/free-form.png',
    title: 'Open-ended (long text)',
    description: translator._('tasks.freeForm.description'),
    description2: translator._('tasks.freeForm.description2'),
    exampleImage: '/assets/icons/task/free-form-example.png',
    dataType: AnswerItemType.SURVEYFREEFORM,
  },
  {
    name: 'input',
    icon: '/assets/icons/task/input.png',
    title: 'Basic input (short text)',
    description: translator._('tasks.input.description'),
    description2: translator._('tasks.input.description2'),
    exampleImage: '/assets/icons/task/free-form-example.png',
    dataType: AnswerItemType.SURVEYINPUT,
  },
  {
    name: 'numeric-input',
    icon: '/assets/icons/task/numeric-input.png',
    title: 'Numeric answer',
    description: translator._('tasks.numericInput.description'),
    description2: translator._('tasks.numericInput.description2'),
    exampleImage: '/assets/icons/task/numeric-input-example.png',
    dataType: AnswerItemType.SURVEYNUMERICENTRY,
  },
]
const Feedback = props => {
  const { classes, saveType, entity } = props

  return (
    <div container className={classes.list}>
      <Typography className={classes.listTitle}>FEEDBACK</Typography>

      {BasicOptions.map((option, index) => {
        return (
          <div
            className={classes.listItems}
            onClick={() => saveType(option.dataType)}
          >
            <img
              alt="cardIcon"
              src={option.icon}
              className={classes.listImage}
            />
            <Typography className={classes.listText}>{option.title}</Typography>
          </div>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(Feedback)
