import React from 'react'
import { Icon } from '@iconify/react'
import { Button, Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const NoTeamsView = props => {
  const { handleEditTeam, classes } = props
  return (
    <div className={classes.noTeamsView_root}>
      <div className={classes.noTeamsView_root_content}>
        <img width={'180px'} src="/assets/empty-teamlist-icon.png " />
        <Typography
          fontSize="16px"
          fontWeight={500}
          color="#002E47"
          textAlign={'center'}
        >
          Your groups will appear here once created.{' '}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          color="#637381"
          textAlign={'center'}
        >
          Create your first group to begin adding learners.
        </Typography>
        <Button
          variant="contained"
          className={classes.page__header__button}
          onClick={() => handleEditTeam()}
          startIcon={
            <Icon
              icon={'eva:plus-fill'}
              width={24}
              height={24}
              color={'#fff'}
            />
          }
        >
          New Group
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(NoTeamsView)
