import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
} from '@material-ui/core'

const Examples = [
  'Cryptocurrency for beginners \n' +
    '\nCryptocurrency, or crypto, is a digital payment platform that eliminates the need to carry physical money. It exists only in digital form, and although people mainly use it for online transactions, you can make some physical purchases. Unlike traditional money printed only by the government, several companies sell cryptocurrency.' +
    '\n\nCryptocurrencies are fungible, meaning the value remains the same when bought, sold, or traded. Cryptocurrency isn’t the same as non-fungible tokens (NFTs) with variable values. For example, one dollar in crypto will always be one dollar, whereas the value of one NFT dollar depends on the digital asset it’s attached to.' +
    '\n\nAlthough government regulations are absent from the cryptocurrency market, they are taxable assets. You’ll need to file any profit or loss with the Internal Revenue Service.' +
    '\n\nWhen a cryptocurrency is minted, or created prior to issuance, or issued by a single issuer, it is generally considered centralized. When implemented with decentralized control, each cryptocurrency works through distributed ledger technology, typically a blockchain, that serves as a public financial transaction database.' +
    '\n\nThe first cryptocurrency was Bitcoin, which was first released as open-source software in 2009. As of March 2022, there were more than 9,000 other cryptocurrencies in the marketplace, of which more than 70 had a market capitalization exceeding $1 billion.',

  'History of blockchain ' +
    '\n\nCryptographer David Chaum first proposed a blockchain-like protocol in his 1982 dissertation Computer Systems Established, Maintained, and Trusted by Mutually Suspicious Groups. Further work on a cryptographically secured chain of blocks was described in 1991 by Stuart Haber and W. Scott Stornetta. They wanted to implement a system wherein document timestamps could not be tampered with. In 1992, Haber, Stornetta, and Dave Bayer  incorporated Merkle trees  into the design, which improved its efficiency by allowing several document certificates to be collected into one block. Under their company Surety, their document certificate hashes have been published in The New York Times every week since 1995. ' +
    '\n\nThe first decentralized blockchain was conceptualized by a person (or group of people) known as in 2008. Nakamoto improved the design in an important way using a Hashcash-like method to timestamp blocks without requiring them to be signed by a trusted party and introducing a difficulty parameter to stabilize the rate at which blocks are added to the chain. The design was implemented the following year by Nakamoto as a core component of the cryptocurrency bitcoin, where it serves as the public ledger for all transactions on the network. In August 2014, the bitcoin blockchain file size, containing records of all transactions that have occurred on the network, reached 20 GB (gigabytes).I n January 2015, the size had grown to almost 30 GB, and from January 2016 to January 2017, the bitcoin blockchain grew from 50 GB to 100 GB in size. The ledger size had exceeded 200 GB by early 2020.' +
    "\n\nThe words block and chain were used separately in Satoshi Nakamoto's original paper, but were eventually popularized as a single word, blockchain, by 2016.",
  'What is role-playing game? ' +
    '\n\nA role-playing game (sometimes spelled roleplaying game, RPG) is a game in which players assume the roles of characters in a fictional setting. Players take responsibility for acting out these roles within a narrative, either through literal acting or through a process of structured decision-making regarding character development. Actions taken within many games succeed or fail according to a formal system of rules and guidelines.' +
    "\n\nThere are several forms of role-playing games. The original form, sometimes called the tabletop role-playing game (TRPG or TTRPG), is conducted through discussion, whereas in live action role-playing (LARP), players physically perform their characters' actions. In both of these forms, an arranger called a game master (GM) usually decides on the rules and setting to be used, while acting as the referee; each of the other players takes on the role of a single character." +
    '\n\nSeveral varieties of RPG also exist in electronic media, such as multiplayer text-based Multi-User Dungeons (MUDs) and their graphics-based successors, massively multiplayer online role-playing games (MMORPGs). Role-playing games also include single-player role-playing video games in which players control a character, or team of characters, who undertake(s) quests and may include player capabilities that advance using statistical mechanics. These electronic games sometimes share settings and rules with tabletop RPGs, but emphasize character advancement more than collaborative storytelling.' +
    '\n\nThis type of game is well-established, so some RPG-related game forms, such as trading/collectible card games (CCGs) and wargames, may not be included under the definition.',
]

function UploadText(props) {
  const {
    documentText,
    setDocumentText,
    roleType,
    handleAddSource,
    cardText,
    classes,
  } = props

  const [textFieldElement, setTextFieldElement] = useState()
  const [charCount, setCharCount] = useState(0)

  const handleDocumentText = e => {
    let textValidation =
      (roleType == 1 && e.length >= 2000) || e.length >= 30000

    !textValidation && setTextFieldElement(e)
    !textValidation && setCharCount(e.length)
  }

  const handleTextField = e => {
    handleDocumentText(e)
  }

  const handleTextFieldOnBlur = e => {
    setDocumentText(e)
  }

  useEffect(() => {
    ;(roleType == 1 && charCount >= 2000) ||
    charCount >= 30000 ||
    charCount == 0
      ? handleAddSource(false)
      : handleAddSource(true)
  }, [roleType, textFieldElement, charCount])
  useEffect(() => {
    handleDocumentText(cardText && cardText.replace(/(<([^>]+)>)/gi, ''))
  }, [])
  const onClickTemplate = type => {
    switch (type) {
      case 'Cryptocurrency for beginners':
        handleTextField(Examples[0])
        handleTextFieldOnBlur(Examples[0])

        break
      case 'History of blockchain':
        handleTextField(Examples[1])
        handleTextFieldOnBlur(Examples[1])
        break
      case 'What is role-playing game?':
        handleTextField(Examples[2])
        handleTextFieldOnBlur(Examples[2])
        break
      default:
    }
  }
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}></div>
        <span
          style={{
            marginRight: 10,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {charCount}
        </span>
      </div>
      <TextField
        multiline
        placeholder="Generate questions from text. Type or copy and paste your notes. For best results, use several paragraphs. Maximum 2000 characters. Paid accounts can use up to 25,000 characters."
        minRows={6}
        maxRows={6}
        fontSize="12px"
        value={textFieldElement}
        onChange={e => handleTextField(e.target.value)}
        onBlur={e => handleTextFieldOnBlur(e.target.value)}
        style={{
          width: '100%',
          color: '#fff',
          fontSize: 12,
          marginBottom: '16px',
        }}
      ></TextField>
      <Typography
        color="#637381"
        style={{
          fontSize: '12px',
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        Want to see a quick demo? Try these:{' '}
        <span
          className={classes.addUrlDiv}
          style={{ color: '#5865F2' }}
          onClick={() => {
            onClickTemplate('Cryptocurrency for beginners')
          }}
        >
          Cryptocurrency for beginners
        </span>
        ,{' '}
        <span
          className={classes.addUrlDiv}
          style={{ color: '#5865F2' }}
          onClick={() => {
            onClickTemplate('History of blockchain')
          }}
        >
          History of blockchain
        </span>
        , and{' '}
        <span
          className={classes.addUrlDiv}
          style={{ color: '#5865F2' }}
          onClick={() => {
            onClickTemplate('What is role-playing game?')
          }}
        >
          What is role-playing game?
        </span>{' '}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(UploadText)
