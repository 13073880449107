const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '964px',
    padding: '24px 0px 0px 0px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '4px',
  },
  icon: {
    fontSize: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'space-between',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemTitle: {
    color: '#637381',
    fontWeight: 400,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '113px',
  },
  itemInfo: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: '#002E47',
    backgroundColor: '#fff',
    border: '1px solid #919EAB',
    width: '66px',
  },
})

export default styles
