const styles = theme => ({
  inputField: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  listGroup: {
    marginTop: 0,
    width: '440px',
  },
  select: {
    marginTop: '0!important',
  },
  copy: {
    color: '#015FFB',
  },
  continueButton: {
    width: '91px',
    height: '36px',
    boxShadow: 'none',
    backgroundColor: '#29D25D',
    color: '#fff',
  },
  formControlPayment: {
    width: '100%',
    minWidth: 120,
  },
  selectEmptyPayment: {
    marginTop: theme.spacing(2),
  },
  backButton: {
    outline: '1px solid #919EAB52',
    border: 'none',
    width: '32px',
    height:'36px',
    '&:hover': {
      outline: '1px solid #919EAB52',
      border: 'none',
    },
  },
  stepsStyle: {},
  iframeStyle: {
    borderRadius: '12px',
  },

  stepZero_main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  stepZero_main_title: {
    display: 'flex',
    alignItems: 'center',
  },
  stepOne_main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  stepOne_main_title: {
    display: 'flex',
    alignItems: 'center',
  },
  stepTwo_main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  stepTwo_main_title: {
    display: 'flex',
    alignItems: 'center',
  },
  stepTwo_main_buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  stepOne_main_buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  stepTwo_main_completedDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    width: '100%',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    padding: '8px',
    paddingBottom: 16,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  okayButton: {
    width: '100%',
    height: '36px',
    boxShadow: 'none',
    backgroundColor: '#015FFB',
    color: '#fff',
  },
  paymentLinkTextfield: { marginTop: '8px', width: '440px' },
  shareViewCopyButton: {
    backgroundColor: '#fff',
    border: '1px solid #015FFB',
    color: '#015FFB',
    boxShadow: 'none',
    width: '440px',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #015FFB',
      color: '#015FFB',
    },
  },
})

export default styles
