import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { EndlessPossibilities, BuildAutomations } from './first-step-comp'
const FirstStepView = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <EndlessPossibilities />
      <BuildAutomations />
    </div>
  )
}

export default withStyles(styles)(FirstStepView)
