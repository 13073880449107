import React from 'react'
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from '@material-ui/core'
import { styled } from '@material-ui/core'
import styles from '../styles'
import { AccordionSummaryContent } from '..'
import { StripeStepper, Provider } from './components'
import UserType from 'src/model/user-types'

import { PatchData, PatchContent } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
import { useDispatch } from 'react-redux'
import PaymentGateway from 'src/model/payment-providers'
import { Icon } from '@iconify/react'
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  '&.Mui-expanded': {
    boxShadow: 'none',
  },
}))

const PaymentWall = props => {
  const {
    accessRule,
    handleAccessRule,
    classes,
    entity,
    setPaymentLink,
    setPaymentProvider,
    paymentProvider,
    paymentSettingsConfigured,
    setPaymentSettingsConfigured,
    paymentLink,
  } = props
  const dispatch = useDispatch()

  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]

  const accessSpecificUsersControl = () => {
    if (
      userControl == 'Premium Plus' ||
      userControl == 'Premium Plus v2' ||
      userControl == 'Premium - A' ||
      userControl == 'Premium' ||
      userControl == 'Premium v2' ||
      userControl == 'Appsumo (Tier 2)' ||
      userControl == 'Appsumo (Tier 2A)' ||
      userControl == 'Appsumo (Tier 3)' ||
      userControl == 'Appsumo (Tier 3A)' ||
      userControl == 'Appsumo (Tier 4)' ||
      userControl == 'Appsumo (Tier 5)'
    ) {
      return false
    }
    return true
  }

  const handleChange = event => {
    setPaymentProvider(event.target.value)
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        PaymentGateway[event.target.value],
        'paymentProvider',
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(null, 'paymentLink', PatchData.OPERATION_REPLACE),
    )
    patchSource.addContent(
      new PatchContent(
        false,
        'isPaymentConfigured',
        PatchData.OPERATION_REPLACE,
      ),
    )

    setPaymentLink('')
    dispatch(
      patchCollectionSettingsAction(patchSource, response => {
        setPaymentSettingsConfigured(false)
      }),
    )
  }
  const onClickDone = clickedBeforeFinish => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        true,
        'isPaymentConfigured',
        PatchData.OPERATION_REPLACE,
      ),
    )
    dispatch(
      patchCollectionSettingsAction(patchSource, response => {
        !clickedBeforeFinish && setPaymentSettingsConfigured(true)
      }),
    )
  }
  const onClickEdit = () => {
    setPaymentSettingsConfigured(false)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClickSwitch = event => {
    accessSpecificUsersControl() ? handleClick(event) : handleAccessRule(4)
  }
  return (
    <div className={classes.root}>
      <StyledAccordion expanded={accessRule == 4}>
        <AccordionSummary
          className={classes.accordion__summary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryContent
            accessRule={accessRule}
            handleClickSwitch={handleClickSwitch}
            contentHeader={'Enable access behind a pay-wall'}
            contentDescription={
              'Learners can unlock the course once they have made the payment using your payment processor.'
            }
            id={id}
            badgeType={4}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            popupHeader={'Upgrade to Premium'}
            popupContent={
              'Upgrade to Premium account to access advanced features.'
            }
            accessRuleEqual={4}
          />
        </AccordionSummary>

        <AccordionDetails className={classes.accordion__details}>
          <div
            style={{
              padding: '8px',
              width: '100%',
              backgroundColor: '#F4F6F8',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Provider
              paymentProvider={paymentProvider}
              handleChange={handleChange}
            />
          </div>

          {paymentSettingsConfigured == false && paymentProvider != null && (
            <StripeStepper
              entity={entity}
              paymentProvider={paymentProvider}
              paymentLink={paymentLink}
              setPaymentLink={setPaymentLink}
              paymentSettingsConfigured={paymentSettingsConfigured}
              setPaymentSettingsConfigured={setPaymentSettingsConfigured}
              onClickDone={onClickDone}
            />
          )}
          {paymentSettingsConfigured && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: '#F4F6F8',
                borderRadius: '8px',
                height: '50px',
                padding: '12px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <Icon
                  icon="icon-park-solid:check-one"
                  width="20px"
                  color="#35CE64"
                />
                <Typography fontSize="14px" fontWeight={400}>
                  The payment processor is connected.
                </Typography>
              </div>
              <Button
                variant="contained"
                className={classes.continueButton}
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            </div>
          )}
        </AccordionDetails>
      </StyledAccordion>
    </div>
  )
}

export default withStyles(styles)(PaymentWall)
