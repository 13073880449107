import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  ListItem,
  Typography,
  ClickAwayListener,
  Tooltip,
} from '@material-ui/core'
import styles from './styles'
import { DrawerOpenButton, AddNewCollection } from './components'
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import URLs from 'src/api/url'
import { setSelectedCollectionAction } from 'src/reducers/course-collection/action'
import { Icon } from '@iconify/react'
import {
  getCollectionSettingsAction,
  courseTransportClickedAction,
  transportingCourseSelectAction,
} from 'src/reducers/course-collection/action'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { PatchContent, PatchData } from 'src/model/patch'

function CollectionListItem(props) {
  const { collection, open, classes, containers, setContainers, index } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: `${collection.id}-container` })
  const dispatch = useDispatch()
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const coverImageUrl =
    collection?.coverImageUrl != null
      ? collection?.coverImageUrl.includes('http://') ||
        collection?.coverImageUrl.includes('https://')
        ? collection?.coverImageUrl
        : `${URLs.CDN}` + collection?.coverImageUrl
      : `https://cdn.minicoursegenerator.com/assets/gateway/collection-cover.png`

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const selectedCollectionEntity = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )

  const selectedCollectionEntityId = selectedCollectionEntity[0]
    ? selectedCollectionEntity[0].id
    : DEFAULT_COLLECTION_GUID

  const courseTransportClicked = useSelector(
    state => state.courseCollectionList.courseTransportClicked,
  )
  const selectedCourse = useSelector(
    state => state.courseCollectionList.selectedCourse,
  )
  const value = useSelector(state => state.courseCollectionList.list)
  const { courseCollectionDtos: collections = [], courseDtos = [] } = value

  const onClick = () => {
    if (courseTransportClicked) {
      const course = courseDtos.find(course => course.id == selectedCourse)
      console.log(course, 'course')
      const newCollections = containers.map(container => {
        if (container.id === selectedCollection) {
          container.courses = container.courses.filter(
            course => course.id !== selectedCourse,
          )
        }
        if (container.id === collection.id) {
          container.courses.push(course)
        }
        return container
      })

      const patchSource = new PatchData(null, selectedCourse)
      patchSource.addContent(
        new PatchContent(
          collection.id,
          'courseCollectionId',
          PatchData.OPERATION_REPLACE,
        ),
      )
      patchSource.addContent(
        new PatchContent(
          collection.courses.length - 1,
          'courseCollectionOrder',
          PatchData.OPERATION_REPLACE,
        ),
      )

      dispatch(assignmentPatchAction(patchSource))
      setContainers(newCollections)
      dispatch(courseTransportClickedAction())
    } else if (collection.id != DEFAULT_COLLECTION_GUID) {
      dispatch(getCollectionSettingsAction(collection.id))
      dispatch(setSelectedCollectionAction(collection.id))
      window.history.pushState(null, '', `/${collection.id}`)
    }
  }

  return (
    <Tooltip
      title={
        <Typography fontSize="14px" style={{ marginBottom: '3px' }}>
          Select the target collection
        </Typography>
      }
      arrow
      placement="right"
      open={courseTransportClicked && index === 0}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '5px',
          },
        },
      }}
    >
      <ListItem
        button
        key={collection.id}
        className={clsx(classes.chatListItem, {
          [classes.chatListItemHover]: open,
          [classes.chatListItemSelected]:
            collection.id === selectedCollectionEntityId,
        })}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={onClick}
        //onMouseEnter={e => onMouseOver(e)}
        //onPointerLeave={e => onMouseLeave(e)}
      >
        <img
          src={coverImageUrl}
          style={{
            height: '40px',
            width: '60px',
            borderRadius: '6px',
          }}
          alt="cover"
        />
        <Typography
          fontSize="14px"
          fontWeight={400}
          className={clsx(classes.drawer, {
            [classes.collectionNameOpen]: open,
            [classes.collectionNameClose]: !open,
          })}
          data-text={collection.collectionName}
        >
          {collection.collectionName}
        </Typography>
        {open && !courseTransportClicked && (
          <Icon
            icon="nimbus:drag-dots"
            className={classes.dragIcon}
            {...listeners}
          />
        )}
      </ListItem>
    </Tooltip>
  )
}

function CollectionListDrawer(props) {
  const {
    containers,
    open,
    setOpen,
    classes,
    draggedCourseId,
    setHoveredCollectionId,
    setContainers,
  } = props
  const dispatch = useDispatch()
  const courseTransportClicked = useSelector(
    state => state.courseCollectionList.courseTransportClicked,
  )

  const onClickOutside = () => {
    if (courseTransportClicked !== true) return
    dispatch(courseTransportClickedAction())
    dispatch(transportingCourseSelectAction(null))
  }

  const [clickAwayActive, setClickAwayActive] = useState(false)

  useEffect(() => {
    if (courseTransportClicked) {
      const timer = setTimeout(() => {
        setClickAwayActive(true)
      }, 300) // Adjust delay as needed

      return () => clearTimeout(timer)
    } else {
      setClickAwayActive(false)
    }
  }, [courseTransportClicked])

  return (
    <ClickAwayListener onClickAway={() => clickAwayActive && onClickOutside()}>
      <div
        className={clsx({
          [classes.root]: !isMobile,
          [classes.root_mobile]: isMobile,
        })}
      >
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open && !isMobile,
            [classes.drawerClose]: !open && !isMobile,
            [classes.drawerOpenMobile]: open && isMobile,
            [classes.drawerCloseMobile]: !open && isMobile,
            [classes.courseTransportClicked]: open && courseTransportClicked,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpenPaper]: open && !isMobile,
              [classes.drawerClosePaper]: !open && !isMobile,
              [classes.drawerOpenPaperMobile]: open && isMobile,
              [classes.drawerClosePaperMobile]: !open && isMobile,
            }),
          }}
        >
          <DrawerOpenButton open={open} setOpen={setOpen} />
          <AddNewCollection open={open} />

          <div
            style={{
              overflow: 'auto',
              scrollbarWidth: 'thin',
            }}
          >
            <List className={classes.listOpen}>
              {containers.map((collection, index) => (
                <CollectionListItem
                  key={'collectionList' + index}
                  collection={collection}
                  open={open}
                  setOpen={setOpen}
                  classes={classes}
                  draggedCourseId={draggedCourseId}
                  setHoveredCollectionId={setHoveredCollectionId}
                  containers={containers}
                  setContainers={setContainers}
                  index={index}
                />
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    </ClickAwayListener>
  )
}

export default withStyles(styles)(CollectionListDrawer)
