import { put, call, take, fork } from 'redux-saga/effects'
import { getCollectionAnalyticsService } from '../../api/course-collection'
import { GET_COLLECTION_ANALYTICS } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getCollectionAnalyticsFlow(payload) {
  try {
    const { collectionId, courseId, callback } = payload

    const response = yield call(
      getCollectionAnalyticsService,
      collectionId,
      courseId,
    )

    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getCollectionAnalyticsWatcher() {
  while (true) {
    const action = yield take(GET_COLLECTION_ANALYTICS)
    const { payload } = action

    yield fork(generalSaga, getCollectionAnalyticsFlow, payload)
  }
}
