export const parseFontSize = (size) => {
  let dupSize = 0
  switch (size) {
    case 'XS':
      dupSize = -4
      break
    case 'S':
      dupSize = -2
      break
    case 'M':
      dupSize = 0
      break
    case 'L':
      dupSize = 2
      break
    case 'XL':
      dupSize = 4
      break
    default:
      dupSize = 0
      break
  }
  return dupSize
}

export const parseLineHeight = (size) => {
  let dupSize = 0
  switch (size) {
    case 'XS':
      dupSize = 1
      break
    case 'S':
      dupSize = 1.5
      break
    case 'M':
      dupSize = 2
      break
    case 'L':
      dupSize = 2.5
      break
    case 'XL':
      dupSize = 3
      break
    default:
      dupSize = 2
      break
  }
  return dupSize
}

