import {
  put,
  call,
  take,
  fork,
  select,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects'
import { refreshTokenService } from '../../api/authentication'
import {
  authenticationRefreshSuccess,
  authenticationRefreshError,
  authenticationRefreshBusy,
} from '../../reducers/authentication/action'
import { AUTHENTICATION_REFRESH } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import {
  set as setCookie,
  get as getCookie,
  remove as removeCookie,
} from 'es-cookie'

export const checkFetchingData = state => {
  return state.authentication.refreshTokenBusy
}
/**
 *
 * @param {*} payload
 */
function* refreshSagaFlow(payload) {
  try {
    let refreshToken = getCookie('refreshToken')
    const response = yield call(refreshTokenService, { refreshToken })

    removeCookie('refreshToken')

    setCookie('token', response.data.accessToken, {
      sameSite: 'none',
      secure: true,
      expires: 365,
    })
    setCookie('refreshToken', response.data.refreshToken, {
      sameSite: 'none',
      secure: true,
      expires: 365,
    })
    setCookie('userId', response.data.userId, {
      sameSite: 'none',
      secure: true,
      expires: 365,
    })

    yield put(authenticationRefreshSuccess())

    yield put(operationSuccess())
  } catch (error) {
    yield put(authenticationRefreshError())
    yield put(operationSuccess())
  }
}

/**
 *
 */
export default function* refreshSagaWatcher() {
  const action = yield take(AUTHENTICATION_REFRESH)

  const { payload } = action

  yield takeLeading(AUTHENTICATION_REFRESH, refreshSagaFlow, payload)
  /*   
  yield fork(generalSaga, refreshSagaFlow, payload) */
}
