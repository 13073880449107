import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useDispatch } from 'react-redux'
import {
  AccessTeams,
  OptIn,
  AuthenticatedLinks,
  MagicViewContent,
  EveryoneAccess,
  PaymentWall,
} from './components'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
import { useSelector } from 'react-redux'
import { PatchContent, PatchData } from 'src/model/patch'
import UserType from 'src/model/user-types'
import { track } from '@amplitude/analytics-browser'
import PaymentGateway from 'src/model/payment-providers'
import AutomationBanner from 'src/components/automations-banner'

const names = {
  0: 'Everyone',
  1: 'Opt-in',
  2: 'Specific Teams',
  3: 'Authenticated Links',
  4: 'Payment wall',
}
const GeneralSettings = props => {
  const { classes } = props
  const [magicLink, setMagicLink] = useState(false)

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const collection = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )

  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )
  const entity = collection[0]
  const providerText = getPaymentGatewayText(collectionSettings.paymentProvider)
  const paymentConfigured = collectionSettings.isPaymentConfigured
  const [paymentProvider, setPaymentProvider] = React.useState(providerText)
  const [paymentLink, setPaymentLink] = React.useState(
    collectionSettings.paymentLink || '',
  )
  useEffect(() => {
    setAccessRule(collectionSettings.accessRule)
    setPaymentLink(collectionSettings.paymentLink)
  }, [collectionSettings])

  const [
    paymentSettingsConfigured,
    setPaymentSettingsConfigured,
  ] = React.useState(paymentConfigured)

  function getPaymentGatewayText(value) {
    for (const gateway in PaymentGateway) {
      if (PaymentGateway[gateway] === value) {
        return gateway
      }
    }
    return null // Or any other default value if the input doesn't match any gateway
  }

  const [accessRule, setAccessRule] = useState(collectionSettings.accessRule)

  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]

  const dispatch = useDispatch()

  const handleSaveAccessSelection = value => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(value, 'accessRule', PatchData.OPERATION_REPLACE),
    )

    if (value != 4 && paymentProvider != '') {
      patchSource.addContent(
        new PatchContent(null, 'paymentProvider', PatchData.OPERATION_REPLACE),
      )
      setPaymentProvider()
      patchSource.addContent(
        new PatchContent(null, 'paymentLink', PatchData.OPERATION_REPLACE),
      )
      setPaymentLink('')
      patchSource.addContent(
        new PatchContent(
          false,
          'isPaymentConfigured',
          PatchData.OPERATION_REPLACE,
        ),
      )
      setPaymentSettingsConfigured(false)
    }
    dispatch(
      patchCollectionSettingsAction(patchSource, response => {
        track('Access Rule Changed', {
          from: names[value],
        })
      }),
    )
  }

  /*const handleSave = () => {
    setCollectionSettingDrawerOpen(false)
  }*/

  const handleBack = () => {
    setMagicLink(false)
  }

  const appInAuthUsersControl = () => {
    if (
      userControl == 'Premium Plus' ||
      userControl == 'Premium Plus v2' ||
      userControl == 'Premium - A' ||
      userControl == 'Appsumo (Tier 4)' ||
      userControl == 'Appsumo (Tier 5)'
    ) {
      return false
    }
    return true
  }

  const handleAccessRule = value => {
    let userAccessControl = false

    if (value == 3) {
      userAccessControl = appInAuthUsersControl()
      if (userAccessControl) {
        //window.location.href = '/pricing'
      }
    }

    if (value != accessRule) {
      setAccessRule(value)
      handleSaveAccessSelection(value)
    }
  }

  const handleMagicLink = () => {
    setMagicLink(!magicLink)
  }

  const closeDrawer = () => {
    //setCollectionSettingDrawerOpen(false)
  }

  const components = [
    {
      component: (
        <EveryoneAccess
          accessRule={accessRule}
          handleAccessRule={handleAccessRule}
        />
      ),
      onView: !magicLink,
      mainPage: true,
    },
    {
      component: (
        <OptIn
          entity={entity}
          accessRule={accessRule}
          handleAccessRule={handleAccessRule}
        />
      ),
      onView: !magicLink,
      mainPage: true,
    },
    {
      component: (
        <PaymentWall
          accessRule={accessRule}
          handleAccessRule={handleAccessRule}
          entity={entity}
          paymentProvider={paymentProvider}
          setPaymentProvider={setPaymentProvider}
          paymentSettingsConfigured={paymentSettingsConfigured}
          setPaymentSettingsConfigured={setPaymentSettingsConfigured}
          paymentLink={paymentLink}
          setPaymentLink={setPaymentLink}
        />
      ),
      onView: !magicLink,
      mainPage: true,
    },
    {
      component: (
        <AccessTeams
          entity={entity}
          accessRule={accessRule}
          handleAccessRule={handleAccessRule}
          dispatch={dispatch}
          handleMagicLink={handleMagicLink}
        />
      ),
      onView: !magicLink,
      mainPage: true,
    },

    {
      component: (
        <AuthenticatedLinks
          accessRule={accessRule}
          handleAccessRule={handleAccessRule}
          entity={entity}
        />
      ),
      onView: !magicLink,
      mainPage: true,
    },
    {
      component: (
        <MagicViewContent
          entity={entity}
          accessRule={accessRule}
          handleBack={handleBack}
          closeDrawer={closeDrawer}
          collectionSettings={collectionSettings}
        />
      ),
      onView: magicLink,
      mainPage: false,
    },
  ]
  return (
    <div className={classes.content}>
      <div className={classes.content__components}>
        {components.map(
          (component, index) =>
            component.onView && <div key={index}>{component.component}</div>,
        )}
      </div>

      {!magicLink && <AutomationBanner />}

    </div>
  )
}

export default withStyles(styles)(GeneralSettings)
