import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import styles from './styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { assignmentAddAction } from 'src/reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from 'src/model/assignment'
import { track } from '@amplitude/analytics-browser'
import { isMobile } from 'react-device-detect'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import BackButton from './back-button'
import { userGetSelfAction } from 'src/reducers/user/action'
import NamingModal from 'src/components/naming-modal'

const Options = [
  {
    cardName: 'Create from Scratch',
    icon: '/assets/create-from-scratch.png',
    text: 'Start to build mini-course or quiz immediately with your content.',
  },
  {
    cardName: 'AI Course Creator',
    icon: '/assets/ai-course-creator.png',
    text: 'Make AI create a mini-course with your guidance.',
  },
]
const CreateFirstCourse = props => {
  const [collection, setCollection] = useState(
    window.location.pathname.split('create-course/')[1] || '',
  )
  const [createFromScratch, setCreateFromScratch] = useState(false)
  const [createQuiz, setCreateQuiz] = useState(false)
  const dispatch = useDispatch()
  const { classes, history } = props
  const [courseTitle, setCourseTitle] = useState('My first mini-course')
  const [showNamingModal, setShowNamingModal] = useState(false)

  useEffect(() => {
    dispatch(userGetSelfAction())
  }, [])

  const handleCreateCourse = () => {
    const courseCollectionOrder = 0
    let collectionId =
      collection && collection != '00000000-0000-0000-0000-000000000000'
        ? collection
        : '00000000-0000-0000-0000-000000000000'
    dispatch(
      assignmentAddAction(
        {
          ...AssignmentSchema(),
          [AssignmentFieldNames.NAME]: courseTitle,
          [AssignmentFieldNames.IS_PERIODIC]: AssignmentTypes.ONETIME,
          CourseCollectionId:
            collection && collection != '00000000-0000-0000-0000-000000000000'
              ? collection
              : '00000000-0000-0000-0000-000000000000',
          CourseCollectionOrder: courseCollectionOrder,
        },
        history,
        entity => {
          createFromScratch &&
            track('Course Created First Time', {
              courseId: entity.id,
            })
          createFromScratch && history.push('/mini-course/' + entity.id)
          createQuiz && history.push('/create-quiz-with-ai/' + entity.id)
        },
      ),
    )
  }

  const handleClickCard = index => {
    if (index == 1) {
      history.push('/create-course-with-ai/' + collection + '/course')
      track('create-course-with-ai', {
        boxOrder: index,
      })
      return true
    }

    if (index == 0) {
      setShowNamingModal(true)
      //track('from_scratch', {
      //boxOrder: index,
      //})
      return
    }
  }
  const handleBack = () => {
    setCreateFromScratch(false)
    setCreateQuiz(false)
  }
  const handleBackToHome = () => {
    history.push('/')
  }

  return (
    <div className={classes.page}>
      {showNamingModal && (
        <NamingModal
          classes={classes}
          dispatch={dispatch}
          id={collection}
          history={history}
          handleCloseModal={() => setShowNamingModal(false)}
          isModalOpen={showNamingModal}
          selectedAssignmentType={AssignmentTypes.ONETIME}
        />
      )}
      <div className={classes.content}>
        {!createFromScratch && (
          <BackButton handleBackToHome={handleBackToHome} />
        )}
        {createFromScratch && (
          <>
            <div className={classes.backButton} onClick={() => handleBack()}>
              <Button className={classes.button}>
                <ChevronLeftIcon style={{ width: '0.8em', marginTop: '3px' }} />
                <Typography>Back</Typography>
              </Button>
            </div>
            <Box m={6}></Box>
            <Typography variant="h4">Let's name your mini-course.</Typography>
            <Box m={2}></Box>
            <TextField
              style={{
                width: '300px',
                borderColor: '#015FFB',
              }}
              variant="outlined"
              onChange={e => {
                setCourseTitle(e.currentTarget.value)
              }}
              label="Name your mini-course"
            />
            <Box m={1}></Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#015FFB',
                borderRadius: '20px',
                marginTop: 10,
                minWidth: '150px',
              }}
              onClick={() => handleCreateCourse()}
            >
              Next
            </Button>
          </>
        )}

        {!createFromScratch && (
          <>
            {!isMobile && <Box m={2.5}></Box>}
            <Typography variant="h4">How do you want to proceed?</Typography>
            {!isMobile && <Box m={2.5}></Box>}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              style={{ marginBottom: '40px' }}
            >
              {Options.map((option, index) => (
                <Grid item>
                  <Paper
                    key={option.index}
                    className={classes.paper}
                    sx={{
                      p: 2,
                      width: 1,
                      bgcolor: '#fffff',
                    }}
                    onClick={() => handleClickCard(index)}
                  >
                    <img style={{ width: 300 }} src={`${option.icon}`}></img>
                    <Box m={1}></Box>
                    <Typography
                      variant="h4"
                      textAlign={'center'}
                      style={{
                        fontSize: '18px',
                      }}
                    >
                      {option.cardName}
                    </Typography>
                    <Box m={1}></Box>
                    <Typography
                      style={{
                        textAlign: 'center',
                        fontSize: '13.5px',
                        fontWeight: 500,
                        marginBottom: '0px !important',
                      }}
                    >
                      {option.text}
                    </Typography>
                    <Box m={1}></Box>
                    <Typography
                      color="#015FFB"
                      variant="subtitle"
                      style={{
                        display: 'flex',
                        fontWeight: 700,
                      }}
                    >
                      Select <NavigateNextIcon />
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(CreateFirstCourse)
