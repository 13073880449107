import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@material-ui/core'
import styles from './styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
const Options = [
  {
    name: (
      <>
        On social media, sequential emails, etc., <strong>by link</strong>
      </>
    ),
    value: 'Link',
  },
  {
    name: (
      <>
        On webpages, membership platforms, etc. <strong>by embedding</strong>
      </>
    ),
    value: 'Embed',
  },
  {
    name: (
      <>
        On a LinkedIn post in <strong>LinkedIn carousel format</strong>
      </>
    ),
    value: 'Carousel',
  },
]

const PurposeSelection = props => {
  const { purpose, setPurpose } = props
  const handlePurpose = event => {
    setPurpose(event.target.value)
  }
  return (
    <>
      <Box m={2}></Box>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Where will you share this mini-course? </Typography>
        <Tooltip
          title="We customize the content length depending on your selection below."
          placement="right"
        >
          <InfoOutlinedIcon
            size="small"
            color="primary"
            style={{ marginLeft: 5, height: 16, marginTop: 3 }}
          ></InfoOutlinedIcon>
        </Tooltip>
      </div>
      <Box m={1}></Box>
      <FormGroup column>
        {Options.map((option, index) => (
          <FormControlLabel
            control={
              <Radio
                checked={purpose == option.value}
                onChange={handlePurpose}
                value={option.value}
              />
            }
            label={
              <Typography style={{ fontSize: '0.775rem' }}>
                {option.name}
              </Typography>
            }
          />
        ))}
      </FormGroup>
      <Box m={2}></Box>
    </>
  )
}

export default withStyles(styles)(PurposeSelection)
