import React from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../../translate'
import { Grid, withStyles, Button, Alert, Typography } from '@material-ui/core'
import Selection from './selection'
import { questionSelectionAddAction } from '../../../../reducers/question/action'
import {
  QuestionFieldNames,
  SelectionFieldNames,
  AnswerItemType,
} from '../../../../model/question'
import BulkImportContainer from './bulk-import-option'

import { componentBoxShowAction } from '../../../../reducers/component-box/action'
import ComponentBoxContent from '../../../../model/component-box'
import AddIcon from '@material-ui/icons/Add'
import { Icon } from '@iconify/react'
const QuestionTestingSingleChoice = props => {
  const { classes, entity, dispatch } = props

  /**
   *
   */
  const addButtonClickHandler = () => {
    let object = {}
    object[SelectionFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
    object[SelectionFieldNames.STEXT] = ''
    object[SelectionFieldNames.ISCORRECT] = false
    dispatch(questionSelectionAddAction(object, () => {}))
  }

  /**
   *
   */
  const importOptionButtonClickHandler = () => {
    const { dispatch } = props

    let defaultOptions = ''
    if (!entity[QuestionFieldNames.IS_ANSWERED]) {
      entity[QuestionFieldNames.SELECTIONS].forEach((element, i) => {
        if (i != 0) {
          defaultOptions =
            defaultOptions + '\n' + element[SelectionFieldNames.STEXT]
        } else {
          defaultOptions = element[SelectionFieldNames.STEXT]
        }
      })
    }

    const component = (
      <BulkImportContainer
        entity={entity}
        dispatch={dispatch}
        defaultOptions={defaultOptions}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
  }

  /**
   *
   */
  const options = () => {
    let item = entity[QuestionFieldNames.SELECTIONS].map((selection, index) => {
      return (
        <Selection
          selection={selection}
          key={selection.id}
          entity={entity}
          index={index}
          dispatch={dispatch}
        />
      )
    })

    return item
  }
  return (
    <React.Fragment>
      <div className={classes.main}>
        {entity[QuestionFieldNames.QUESTION_TYPE] ==
          AnswerItemType.TESTINGSINGLECHOICE &&
          entity[QuestionFieldNames.SELECTIONS].find(
            x => x.isCorrect == true,
          ) === undefined && (
            <Alert severity="info" className={classes.alert}>
              {translator._('taskItem.addQuestion.PleaseSelectCorrectAnswer')}
            </Alert>
          )}

        {entity[QuestionFieldNames.QUESTION_TYPE] ==
          AnswerItemType.TESTINGMULTIPLECHOICE &&
          entity[QuestionFieldNames.SELECTIONS].find(
            x => x.isCorrect == true,
          ) === undefined && (
            <Alert severity="info" className={classes.alert}>
              {translator._('taskItem.addQuestion.PleaseSelectCorrectAnswers')}
            </Alert>
          )}
        {options()}
      </div>

      <Grid container alignItems="center">
        <Grid item className={classes.grid}>
          <Button
            //variant="outlined"
            color="info"
            onClick={addButtonClickHandler}
            className={classes.button}
            size="small"
            fullWidth
            startIcon={
              <Icon icon="eva:plus-fill" className={classes.addIcon}></Icon>
            }
          >
            <Typography className={classes.addText}>
              {translator._('taskItem.addQuestion.answerType.addOption')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

QuestionTestingSingleChoice.propTypes = propTypes

export default withStyles(styles)(QuestionTestingSingleChoice)
