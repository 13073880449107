import { sortByKey } from '../../utils/array'
import URL from 'src/api/url/api'
import {
  ASSIGNMENT_UPDATE_ENTITY,
  ASSIGNMENT_ADD_SUCCESS,
  ASSIGNMENT_ADD_ERROR,
  ASSIGNMENT_LIST,
  ASSIGNMENT_LIST_SUCCESS,
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_GET_SUCCESS,
  ASSIGNMENT_GET_ERROR,
  ASSIGNMENT_EDIT_ERROR,
  ASSIGNMENT_UPDATE_ATTRIBUTE,
  ASSIGNMENT_REMOVE_USER,
  QUESTION_PATCH_SUCCESS,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_DELETE_SUCCESS,
  QUESTION_LINK_DELETE_SUCCESS,
  QUESTION_SELECTION_PATCH_SUCCESS,
  ASSIGNMENT_REMOVE_QUESTION_SUCCESS,
  ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS,
  QUESTION_PAYMENT_DATA_UPDATE,
  QUESTION_PAYMENT_DISCONNECT,
  QUESTION_SETTING_PATCH_SUCCESS,
  QUESTION_SETTING_CREATE_SUCCESS,
  QUESTION_SETTING_REMOVE,
  ASSIGNMENT_CARD_LINK_PATCH_SUCCESS,
  COURSE_SETTING_REMOVE,
  COURSE_SETTING_PATCH_SUCCESS,
  COURSE_SETTING_CREATE_SUCCESS,
  QUESTION_SELECTIONS_ADD_SUCCESS,
  QUESTION_SELECTIONS_ADD_ERROR,
  ASSIGNMENT_PATCH_SUCCESS,
  QUESTION_CHANGE_IMAGE,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_QUIZZES_BY_COURSE_ID_SUCCESS,
  SETTINGS_DRAWER_OPEN,
} from './type'

import {
  QuestionLinkSchema,
  QuestionFieldNames,
  QuestionMediaFieldNames,
  QuestionMediaSchema,
  SelectionSchema,
  QuestionLinkFieldNames,
  QuestionPaymentFieldNames,
  SelectionFieldNames,
} from '../../model/question'

import { AssignmentFieldNames } from 'src/model/assignment'

export default function (
  state = {
    list: { results: null, totalCount: null },
    reportDataTeamData: { results: null, totalCount: 0 },
    reportDataTeamPerformance: { results: null, totalCount: 0 },
    reportDataUser: { results: null, totalCount: 0 },
    reportDataRelatedSubjectList: null,
    entity: null,
    query: null,
    reportDataCardValues: [],
    settingsDrawerOpen: false,
  },
  action,
) {
  const { payload, type } = action
  const recursive = (row, id, response) => {
    if (row.id === id) {
      row.rowChildList = response
    }

    if (row.rowChildList == null) {
      return null
    }

    row.rowChildList.results.forEach(childRow => {
      recursive(childRow, id, response)
    })
  }
  switch (type) {
    case ASSIGNMENT_UPDATE_ENTITY:
      const { entity } = payload

      if (entity == null) {
        return {
          ...state,
          entity: null,
        }
      }

      if (state.entity == null) {
        state.entity = {}
      }

      return {
        ...state,
        entity: {
          ...state.entity,
          ...entity,
        },
      }

    case ASSIGNMENT_LIST:
      return { ...state, query: payload.query }
    case ASSIGNMENT_LIST_SUCCESS:
      return { ...state, list: payload.response }
    case ASSIGNMENT_LIST_ERROR:
      return { ...state, response: null }
    case ASSIGNMENT_GET_SUCCESS:
      return { ...state, entity: payload.entity }
    case ASSIGNMENT_GET_ERROR:
      return { ...state, entity: null }
    case ASSIGNMENT_ADD_SUCCESS:
      return {
        ...state,
        entity: payload.entity,
        list: {
          results:
            state.list.results == null
              ? [payload.entity]
              : [...state.list.results, payload.entity],
          totalCount: state.list.totalCount + 1,
        },
      }
    case ASSIGNMENT_ADD_ERROR:
      return { ...state, entity: null }
    case ASSIGNMENT_EDIT_ERROR:
      return { ...state, entity: null }
    case ASSIGNMENT_UPDATE_ATTRIBUTE:
      payload.content.forEach(content => {
        state = {
          ...state,
          entity: {
            ...state.entity,
            [content.path]: content.value,
          },
        }
      })
      return state
    case ASSIGNMENT_PATCH_SUCCESS:
      payload.content._contents.map(value => {
        state.entity = {
          ...state.entity,
          [value.path]: value.value,
        }
      })
      return state
    case ASSIGNMENT_REMOVE_USER:
      const { userId } = payload
      state = {
        ...state,
        entity: {
          ...state.entity,
          userDTOs: state.entity.userDTOs.filter(user => {
            return user.id !== userId
          }),
          autoSenderUserRelationDTOs: state.entity.userDTOs.filter(user => {
            return user.id !== userId
          }),
        },
      }
    case ASSIGNMENT_REMOVE_QUESTION_SUCCESS:
      const { questionId } = payload

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: state.entity.cardDtos.filter(question => {
            return question.id !== questionId
          }),
          courseCardDtos: state.entity.cardDtos.filter(question => {
            return question.id !== questionId
          }),
        },
      }
      state.entity.cardDtos.map((value, index) => {
        state.entity.cardDtos[index].orderOfCard = index
      })
      return state
    case QUESTION_PATCH_SUCCESS:
      let questionIdForPatch = payload.content.id
      let newPaths = {}
      payload.content.contents.forEach(patchContent => {
        newPaths = { ...newPaths, [patchContent.path]: patchContent.value }
      })
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch) {
                return {
                  ...q,
                  ...newPaths,
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case QUESTION_MEDIA_ADD_SUCCESS:
      const mediaSchema = { ...QuestionMediaSchema(), ...payload.entity }
      let questionIdForPatch1 =
        payload.entity[QuestionMediaFieldNames.QUESTION_ID]
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch1) {
                return {
                  ...q,
                  [QuestionFieldNames.QUESTION_MEDIAS]: [{ ...mediaSchema }],
                }
              }
              return q
            }),
          ],
        },
      }

      return state
    case QUESTION_MEDIA_DELETE_SUCCESS:
      let questionIdForPatch2 = payload.questionId

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch2) {
                return {
                  ...q,
                  [QuestionFieldNames.QUESTION_MEDIAS]: q[
                    QuestionFieldNames.QUESTION_MEDIAS
                  ].filter(function (item) {
                    return item.id !== payload.id
                  }),
                }
              }
              return q
            }),
          ],
        },
      }

      return state
    case QUESTION_LINK_ADD_SUCCESS:
      let questionIdForPatch3 =
        payload.entity[QuestionLinkFieldNames.QUESTION_ID]

      const linkSchema = { ...QuestionLinkSchema(), ...payload.entity }

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch3) {
                return {
                  ...q,
                  [QuestionFieldNames.QUESTION_LINKS]: [
                    ...sortByKey(
                      [
                        ...q[QuestionFieldNames.QUESTION_LINKS],
                        { ...linkSchema },
                      ],
                      'title',
                    ),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }

      return state
    case QUESTION_SELECTION_ADD_SUCCESS:
      if (state.entity == null) {
        return state
      }
      const selectionData = { ...SelectionSchema(), ...payload.response }

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == payload.response[SelectionFieldNames.QUESTION_ID]) {
                return {
                  ...q,
                  [QuestionFieldNames.SELECTIONS]: [
                    ...sortByKey(
                      [
                        ...q[QuestionFieldNames.SELECTIONS],
                        { ...selectionData },
                      ],
                      'id',
                    ),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case QUESTION_SELECTION_DELETE_SUCCESS:
      let questionIdForPatch4 = payload.questionId

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch4) {
                return {
                  ...q,
                  [QuestionFieldNames.SELECTIONS]: q[
                    QuestionFieldNames.SELECTIONS
                  ].filter(function (item) {
                    return item.id !== payload.id
                  }),
                }
              }
              return q
            }),
          ],
        },
      }

      return state
    case QUESTION_LINK_DELETE_SUCCESS:
      let questionIdForPatch5 = payload.questionId
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch5) {
                return {
                  ...q,
                  [QuestionFieldNames.QUESTION_LINKS]: q[
                    QuestionFieldNames.QUESTION_LINKS
                  ].filter(function (item) {
                    return item.id !== payload.id
                  }),
                }
              }
              return q
            }),
          ],
        },
      }

      return state
    case QUESTION_SELECTION_PATCH_SUCCESS:
      let questionIdForPatch6 = payload.questionId
      let selectionIdForPatch = payload.content.id
      let newPaths2 = {}

      payload.content.contents.forEach(patchContent => {
        newPaths2 = { ...newPaths2, [patchContent.path]: patchContent.value }
      })

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == questionIdForPatch6) {
                return {
                  ...q,
                  [QuestionFieldNames.SELECTIONS]: [
                    ...q[QuestionFieldNames.SELECTIONS].map(s => {
                      if (s.id == selectionIdForPatch) {
                        return {
                          ...s,
                          ...newPaths2,
                        }
                      }
                      return s
                    }),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS:
      return { ...state, entity: payload.entity }

    /*  case OPENAI_SEND_REQUEST_SUCCESS:
      return { entity: payload.entity } */
    case QUESTION_PAYMENT_DATA_UPDATE:
      payload.content.contents.forEach(patchContent => {
        state = {
          ...state,
          entity: {
            ...state.entity,
            cardDtos: [
              ...state.entity.cardDtos.map((q, i) => {
                if (q.id == payload.content.id) {
                  return {
                    ...q,
                    [QuestionFieldNames.PAYMENT]: [
                      {
                        ...state.entity.cardDtos[i][
                          QuestionFieldNames.PAYMENT
                        ][0],
                        [QuestionPaymentFieldNames[
                          patchContent._path.toUpperCase()
                        ]]: patchContent._value,
                      },
                    ],
                  }
                }
                return q
              }),
            ],
          },
        }
      })

      return state
    case QUESTION_PAYMENT_DISCONNECT:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map((q, i) => {
              if (q.id === payload.questionId) {
                return {
                  ...q,
                  [QuestionFieldNames.PAYMENT]: [
                    {
                      ...state.entity.cardDtos[i][
                        QuestionFieldNames.PAYMENT
                      ][0],
                      [QuestionPaymentFieldNames.ACCOUNT_ID]: undefined,
                    },
                  ],
                }
              }
              return q
            }),
          ],
        },
      }

      return state

    case QUESTION_SETTING_PATCH_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map((q, i) => {
              if (q.id === payload.questionId) {
                return {
                  ...q,
                  [QuestionFieldNames.SETTING_DTOS]: [
                    ...state.entity.cardDtos[i].settingDtos.map(
                      (value, index) => {
                        if (value.id === payload.settingId) {
                          return {
                            ...value,
                            //[payload.key]: payload.content.value,
                            value: payload.content.value,
                          }
                        }
                        return value
                      },
                    ),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case QUESTION_SETTING_CREATE_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map((q, i) => {
              if (q.id === payload.questionId) {
                return {
                  ...q,
                  [QuestionFieldNames.SETTING_DTOS]: [
                    ...q[QuestionFieldNames.SETTING_DTOS],
                    payload.content,
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case QUESTION_SETTING_REMOVE:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map((q, i) => {
              if (q.id === payload.questionId) {
                return {
                  ...q,
                  [QuestionFieldNames.SETTING_DTOS]: [
                    ...state.entity.cardDtos[i][
                      QuestionFieldNames.SETTING_DTOS
                    ].filter(v => v.id != payload.id),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case ASSIGNMENT_CARD_LINK_PATCH_SUCCESS:
      let cardIdForPatch7 = payload.cardId
      let linkIdForPatch = payload.content.id
      let newPaths3 = {}

      payload.content.contents.forEach(patchContent => {
        newPaths3 = { ...newPaths3, [patchContent.path]: patchContent.value }
      })

      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id == cardIdForPatch7) {
                return {
                  ...q,
                  [QuestionFieldNames.QUESTION_LINKS]: [
                    ...q[QuestionFieldNames.QUESTION_LINKS].map(s => {
                      if (s.id == linkIdForPatch) {
                        return {
                          ...s,
                          ...newPaths3,
                        }
                      }
                      return s
                    }),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case COURSE_SETTING_PATCH_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          settingDtos: [
            ...state.entity.settingDtos.map((q, i) => {
              if (q.id === payload.settingId) {
                return {
                  ...q,
                  value: payload.content.value,
                }
              }

              return q
            }),
          ],
        },
      }
      return state
    case COURSE_SETTING_CREATE_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          [AssignmentFieldNames.SETTING_DTOS]: [
            ...state.entity[AssignmentFieldNames.SETTING_DTOS],
            payload.content,
          ],
        },
      }
      return state
    case COURSE_SETTING_REMOVE:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map((q, i) => {
              if (q.id === payload.questionId) {
                return {
                  ...q,
                  [QuestionFieldNames.SETTING_DTOS]: [
                    ...state.entity.cardDtos[i][
                      QuestionFieldNames.SETTING_DTOS
                    ].filter(v => v.id != payload.id),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state

    case QUESTION_SELECTIONS_ADD_SUCCESS:
      const cardIdForAdd = payload.response[0].cardId
      const selectionsForAdd = [...payload.response]
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id === cardIdForAdd) {
                return {
                  ...q,
                  [QuestionFieldNames.SELECTIONS]: [...selectionsForAdd],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case QUESTION_SELECTIONS_ADD_ERROR:
      return { ...state, entity: null }

    case QUESTION_CHANGE_IMAGE:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [
            ...state.entity.cardDtos.map(q => {
              if (q.id === payload.cardId) {
                return {
                  ...q,
                  mediaDtos: [
                    ...q.mediaDtos.map(m => {
                      return {
                        ...m,
                        mediaUrl: payload.imageUrl,
                      }
                    }),
                  ],
                }
              }
              return q
            }),
          ],
        },
      }
      return state
    case GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [...payload.response.cardDtos],
        },
      }
      return state
    case GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS:
      let newCard = { ...payload.response }
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [...state.entity.cardDtos, newCard],
        },
      }
      return state
    case GENERATE_QUIZZES_BY_COURSE_ID_SUCCESS:
      state = {
        ...state,
        entity: {
          ...state.entity,
          cardDtos: [...state.entity.cardDtos, ...payload.response],
        },
      }

      return state

    case SETTINGS_DRAWER_OPEN:
      state = {
        ...state,
        settingsDrawerOpen: !state.settingsDrawerOpen,
      }
    default:
      return state
  }
}
