import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import styles from './styles'
import { Paper, Grid, CircularProgress } from '@material-ui/core'

import { track } from '@amplitude/analytics-browser'

import { userGetSelfAction } from 'src/reducers/user/action'
import {
  addCourseDocumentAction,
  getCourseDocumentByIdAction,
  deleteCourseDocumentAction,
  generateQuizWithCourseDocumentAction,
  generateInformationWithCourseDocumentAction,
  generateSuggestionWithCourseDocumentAction,
} from 'src/reducers/assignment/action'
import { getUserLimits } from 'src/api/limit'
import { LimitTypeEnum } from 'src/model/limit'
import CardHeader from './card-header'
import AddDocument from './add-document'
import GenerateContent from './generate-content'
import { useHistory } from 'react-router-dom'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { startConnection } from 'src/utils/signalR-connection/signalR-connection'
import { addCourseCreationMessageReceivedListener } from 'src/utils/signalR-connection/signalR-connection'

const CreateQuizWithAi = props => {
  const { entity, type, classes } = props
  const dispatch = useDispatch()
  const [courseId, setCourseId] = useState(
    type == 'end'
      ? window.location.pathname.split('mini-course/')[1]
      : window.location.pathname.split('/create-quiz-with-ai/')[1],
  )
  const [limitLoading, setLimitLoading] = useState(true)
  const [cardText, setCardText] = useState('')
  const [documentUrl, setDocumentUrl] = useState([''])
  const [file, setFile] = useState(null)
  const [documentPdf, setDocumentPdf] = useState()
  const [count, setAiCount] = useState()
  const [selectedMenu, setSelectedMenu] = useState('Text')
  const [documentText, setDocumentText] = useState()
  const [roleType, setRoleType] = useState()
  const [currentCredit, setCurrentCredit] = useState()
  const [limit, setLimit] = useState()
  const [refreshesAt, setRefreshesAt] = useState()
  const [produceType, setProduceType] = useState('Quiz')
  const [questionType, setQuestionType] = useState('Multiple Choice')
  const [maxQuestions, setMaxQuestions] = useState(3)
  const [documentsUploaded, setDocumentsUploaded] = useState(false)
  const [courseDocuments, setCourseDocuments] = useState([])
  const [titleList, setTitleList] = useState()
  const [title, setTitle] = useState()
  let history = useHistory()
  useEffect(() => {
    if (produceType == 'Quiz') return
    let formDataSuggestion = new FormData()
    formDataSuggestion.append('collectionName', courseId)
    formDataSuggestion.append('number', '3')

    dispatch(
      generateSuggestionWithCourseDocumentAction(
        formDataSuggestion,
        response => {
          setTitleList(response.message)
        },
      ),
    )
  }, [produceType])

  useEffect(() => {
    if (entity && entity.cardDtos.length > 0) {
      let textArray = ''
      entity.cardDtos.map(
        entity =>
          entity.type == 1 && (textArray = textArray + ' ' + entity.text),
      )

      textArray = textArray.replaceAll('</h3>', '</h3><br>')
      textArray = textArray.replaceAll('</h2>', '</h2><br>')
      textArray = textArray.replaceAll('</h1>', '</h1><br>')
      textArray = textArray.replaceAll('</h4>', '</h4><br>')
      textArray = textArray.replaceAll('</p>', '</p><br>')
      //remove code brakes and tabs
      textArray = textArray.replace(/\n/g, '')
      textArray = textArray.replace(/\t/g, '')

      //keep html brakes and tabs
      textArray = textArray.replace(/<\/td>/g, '\t')
      textArray = textArray.replace(/<\/table>/g, '\n')
      textArray = textArray.replace(/<\/tr>/g, '\n')
      textArray = textArray.replace(/<\/p>/g, '\n')
      textArray = textArray.replace(/<\/div>/g, '\n')
      textArray = textArray.replace(/<\/h>/g, '\n')
      textArray = textArray.replace(/<br>/g, '\n')
      textArray = textArray.replace(/<br( )*\/>/g, '\n')
      textArray.replace(/(<([^>]+)>)/gi, '')
      setCardText(textArray)
    }
  }, [entity])

  useEffect(() => {
    //This useEffect hook is used for getting user's limit count for course creation.
    setLimitLoading(true)

    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).limit.upperLimit
      if (limit == -1) {
        limit = 100
      }
      let refreshedAt = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).refreshedAt

      setRoleType(response.data[0].limit.roleType)
      setRefreshesAt(refreshedAt)
      setLimit(limit)
      setAiCount(count)
      setCurrentCredit(limit - count)
      setLimitLoading(false)
    })
  }, [])

  useEffect(() => {
    dispatch(userGetSelfAction())
  }, [])

  useEffect(() => {
    getCourseDocuments()
  }, [])

  const getCourseDocuments = () => {
    dispatch(
      getCourseDocumentByIdAction(courseId, response => {
        if (response == null) return
        let documents = [...response]
        setCourseDocuments(documents)
        documents.length == 0
          ? setDocumentsUploaded(false)
          : setDocumentsUploaded(true)
      }),
    )
  }

  const handleSocketMessage = data => {
    addCourseCreationMessageReceivedListener(async message => {
      if (type == 'end') {
        if (message[0] == entity.id.toString()) {
          setLimitLoading(false)
          getCourseDocuments()
        }
      } else {
        if (message[0] == courseId) {
          setLimitLoading(false)
          getCourseDocuments()
        }
      }
    })
  }
  const handleUploadDocuments = () => {
    setLimitLoading(true)
    let formData = new FormData()
    formData.append('courseId', courseId)
    startConnection()
    switch (selectedMenu) {
      case 'Text':
        formData.append('text', documentText ? documentText : null)
        track('ai-wiz-add-text')
        break
      case 'Upload':
        formData.append('file', file)
        track('ai-wiz-add-pdf')

        break
      case 'Url':
        formData.append('urls', documentUrl ? documentUrl : null)
        track('ai-wiz-add-url')
        break
      default:
    }
    dispatch(
      addCourseDocumentAction(formData, response => {
        handleSocketMessage(response.data)
      }),
    )
  }
  const handleGenerate = () => {
    switch (produceType) {
      case 'Quiz':
        let formDataQuiz = new FormData()
        formDataQuiz.append('collectionName', courseId)
        formDataQuiz.append('number', maxQuestions)
        formDataQuiz.append(
          'quizType',
          (questionType == 'Multiple Choice' && 'single choice') ||
            (questionType == 'True/False' && 'True or False') ||
            (questionType == 'Short Answer' && 'short answer') ||
            (questionType == 'Fill in the Blank' && 'fill in the blanks'),
        )

        formDataQuiz.append('goal', 'quiz')
        dispatch(
          generateQuizWithCourseDocumentAction(formDataQuiz, () => {
            track('ai-ai-wiz-generate-click', {
              type: produceType,
              questionType: questionType,
            })
            if (type == 'end') {
              dispatch(componentBoxHideAction())
            } else {
              history.push('/mini-course/' + courseId)
            }
          }),
        )
        break
      case 'Information':
        let formDataInfo = new FormData()
        formDataInfo.append('collectionName', courseId)
        formDataInfo.append('goal', 'info')
        formDataInfo.append('title', title)
        dispatch(
          generateInformationWithCourseDocumentAction(formDataInfo, () => {
            if (type == 'end') {
              dispatch(componentBoxHideAction())
            } else {
              history.push('/mini-course/' + courseId)
            }
          }),
        )
        break
      default:
        break
    }
  }
  const handleDelete = source => {
    let formDataDelete = new FormData()
    formDataDelete.append('collectionName', courseId)
    formDataDelete.append('source', source)

    dispatch(
      deleteCourseDocumentAction(formDataDelete, response => {
        setCourseDocuments(
          courseDocuments.filter(item => item.fileName != response.fileName),
        )
        let req =
          courseDocuments.filter(item => item.fileName != response.fileName)
            .length == 0

        if (req == true) {
          setDocumentsUploaded(false)
          setDocumentText('')
          setDocumentUrl([''])
          setFile(null)
          setCardText('')
        }
      }),
    )
  }
  const onHandleBack = () => {
    setDocumentsUploaded(true)
  }
  return (
    <Grid container className={classes.page}>
      <Grid item className={classes.stepperGrid}>
        <Paper className={classes.stepperHolder}>
          {limitLoading ? (
            <div className={classes.page}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <CardHeader
                documentsUploaded={documentsUploaded}
                courseDocuments={courseDocuments}
                onHandleBack={onHandleBack}
              />
              {documentsUploaded ? (
                <GenerateContent
                  documentsUploaded={documentsUploaded}
                  setDocumentsUploaded={setDocumentsUploaded}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  courseDocuments={courseDocuments}
                  produceType={produceType}
                  setProduceType={setProduceType}
                  questionType={questionType}
                  setQuestionType={setQuestionType}
                  maxQuestions={maxQuestions}
                  setMaxQuestions={setMaxQuestions}
                  handleGenerate={handleGenerate}
                  handleDelete={handleDelete}
                  titleList={titleList}
                  setTitleList={setTitleList}
                  setTitle={setTitle}
                  title={title}
                  setDocumentUrl={setDocumentUrl}
                  setDocumentText={setDocumentText}
                  setFile={setFile}
                  roleType={roleType}
                />
              ) : (
                <AddDocument
                  documentUrl={documentUrl}
                  setDocumentUrl={setDocumentUrl}
                  file={file}
                  setFile={setFile}
                  documentPdf={documentPdf}
                  setDocumentPdf={setDocumentPdf}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  documentText={documentText}
                  setDocumentText={setDocumentText}
                  roleType={roleType}
                  handleUploadDocuments={handleUploadDocuments}
                  cardText={cardText}
                />
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CreateQuizWithAi)
