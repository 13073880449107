const URL = {
  LOGIN: 'Auth/login',
  ME: 'Auth/CurrentUserInfo',
  LOGOUT: 'Auth/Logout',
  VALIDATE_SUPER_LOGIN: 'Auth/ValidateSuperTokenId',
  DELETE: 'Auth/DeleteUser',
  FORGOT_PASSWORD_EMAIL: 'Auth/SendForgotPasswordEmail',
  AUTH_CHANGE_PASSWORD_USER: 'Auth/ChangePassword',
  VERIFY_CHANGE_PASSWORD_CODE: 'Auth/VerifyChangePasswordCode',
  GET_USER_REPORT_BY_FILTER: 'Auth/GetUserReportById',
  REFRESH_TOKEN: 'Auth/refresh',

}

export default URL
