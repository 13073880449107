export default class CardTypes {
  static 1 = 'Information'
  static 2 = 'Single Choice Testing'
  static 3 = 'Multiple Choice Testing'
  static 4 = 'Single Choice Survey'
  static 5 = 'Multiple Choice Survey'
  static 6 = 'Survey Free Form'
  static 7 = 'Survey Free Input'
  static 8 = 'Survey Starts'
  static 9 = 'Survey Numeric'
  static 10 = 'Sign Up'
  static 11 = 'Payment'
  static 12 = 'Score'
  static 13 = 'Certificate'
  static 14 = 'Result'
  static 15 = 'Password Protected'
  static 16 = 'Result Default'
  static 17 = 'AI'
  static 18 = 'Badge'
}
