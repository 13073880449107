const styles = theme => ({
  buttonOnSelectedPage: {
    backgroundColor: '#F4F6F8',
    color: '#002E47',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: '#F4F6F8 !important',
      color: '#002E47 !important',
    },
  },
  buttonOnNonSelectedPage: {
    backgroundColor: 'transparent',
    color: '#637381',
    padding: '6px 12px',

    '&:hover': {
      backgroundColor: '#F4F6F8 !important',
      color: '#002E47 !important',
    },
  },
})

export default styles
