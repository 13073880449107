import React from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography, Box, Tooltip, Button } from '@material-ui/core'
import Iconify from 'src/components/Iconify'
import LimitReachedModal from '../limit-reached-modal'
import { useDispatch } from 'react-redux'
import { AssignmentTypes } from 'src/model/assignment'
import styles from './styles'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
function CreateButtonView(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { classes, isUserReachedCourseLimit } = props
  const [onHover, setOnHover] = React.useState(false)

  const history = useHistory()

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const collectionDto = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )
  const selectedCollectionData = collectionDto[0]

  const [modalOpen, setModalOpen] = useState(false)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const dispatch = useDispatch()
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div>
        <Paper
          onMouseEnter={() => {
            setOnHover(true)
          }}
          onMouseLeave={() => {
            setOnHover(false)
          }}
          onClick={() =>
            !isUserReachedCourseLimit
              ? history.push('/create-course/' + selectedCollectionData.id)
              : setModalOpen(true)
          }
          className={classes.courseGalleryView}
        >
          <div
            style={{
              backgroundImage: `url(/assets/create-card-bg.png)`,
              backgroundSize: onHover ? 400 : 350,
              transition: 'background-size 0.5s',
            }}
            className={`${classes.coverImage} ${
              onHover ? classes.breathAnimation : ''
            }`}
            onClick={event => {
              handleClick(event)
            }}
          >
            <div className={classes.createIcon}>
              <Iconify icon={'eva:plus-fill'} width={26} height={26} />
            </div>
          </div>

          <Box
            sx={{
              cursor: 'pointer',
              padding: 1,
              paddingRight: 2,
              paddingLeft: 2,
            }}
          >
            <Typography fontWeight={600} fontSize="14px">
              Create a Mini-Course
            </Typography>
          </Box>
          <div
            style={{
              flexGrow: 1,
            }}
          ></div>
          <Box
            sx={{
              cursor: 'pointer',
              padding: 1,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom: '18px',
            }}
          >
            <Typography
              fontWeight={400}
              fontSize="14px"
              style={{
                color: '#637381',
              }}
            >
              Start building the mini-course using AI or from scratch.
            </Typography>
          </Box>
        </Paper>

        {modalOpen && (
          <LimitReachedModal
            dispatch={dispatch}
            id={selectedCollectionData.id}
            entity={selectedCollectionData}
            history={history}
            handleCloseModal={() => setModalOpen(false)}
            isModalOpen={modalOpen}
            collectionName={selectedCollectionData.collectionName}
            selectedAssignmentType={AssignmentTypes.ONETIME}
          />
        )}
      </div>
    </>
  )
}

export default withStyles(styles)(CreateButtonView)
