import React from 'react'
import { useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import EyeFill from '@material-ui/icons/Visibility'
import EyeOffFill from '@material-ui/icons/VisibilityOff'
// material
import {
  Grid,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box,
  Link,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// routes
//import { PATH_AUTH } from '../../../routes/paths'
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef'
import { authenticationLoginUserAction } from '../../../reducers/authentication/action'
import AuthLayout from 'src/layouts/AuthLayout'
import translator from 'src/translate'

//

// ----------------------------------------------------------------------

const LoginForm = props => {
  //const { login } = useAuth()
  const method = 'firebase'
  const isMountedRef = useIsMountedRef()
  const [showPassword, setShowPassword] = useState(false)

  /*  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  }) */

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    //validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { dispatch } = props

        const data = {
          UserName: values.email,
          Password: values.password,
        }
        dispatch(authenticationLoginUserAction(data))

        if (isMountedRef.current) {
          setSubmitting(false)
        }
      } catch (error) {
        console.error(error)
        resetForm()
        if (isMountedRef.current) {
          setSubmitting(false)
          setErrors({ afterSubmit: error.message })
        }
      }
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  const handleShowPassword = () => {
    setShowPassword(show => !show)
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Box m={2}></Box>

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? (
                      <EyeFill></EyeFill>
                    ) : (
                      <EyeOffFill></EyeOffFill>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Grid>

        <Grid
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('remember')}
                  checked={values.remember}
                  sx={{
                    '&.Mui-checked': {
                      color: '#015ffb',
                    },
                  }}
                />
              }
              label="Remember me"
            />
            <div style={{ flexGrow: 1 }}> </div>
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={'/forgot-password'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: '#015ffb',
              }}
            >
              Forgot your password?
            </Link>
          </div>

          {/*  <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            Forgot password?
          </Link> */}
        </Grid>

        <LoadingButton
          sx={{
            backgroundColor: '#015ffb',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#015ffb',
              boxShadow: 'none',
            },
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '36px',
          }}
        >
          {translator._('signUp.dontHaveAnAccount')} &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            href="/register"
            sx={{
              color: '#015ffb',
            }}
          >
            {translator._('signUp.getStarted')}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default LoginForm
