import { withStyles, styled } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import styles from '../styles'
import {
  useCourseSettings,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'

import { useDispatch, useSelector } from 'react-redux'

import {
  AssignmentFieldNames,
  AssignmentInitialCourseSettings,
} from 'src/model/assignment'

import { initialThemeSettings } from 'src/model/theme'
import themePresets from 'src/model/theme/presets'
import { ChromePicker } from 'react-color'
import URLs from 'src/api/url'

import {
  Alert,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  IconButton,
  Divider,
  Chip,
  Stack,
  Autocomplete,
  TextField,
  CircularProgress,
  Tooltip,
  Popover,
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import AutoFixHighIcon from '@material-ui/icons/AutoFixHigh'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'

import SettingStretch from './components/stretch'
import SettingDirection from './components/direction'
import SettingLayout from './components/layout'
import SettingArrow from './components/arrow'

import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import translator from 'src/translate'
import FontPicker from 'font-picker-react'
import { showModal } from 'src/components/upgrade-popup'

import URL from 'src/api/url/api'
import rgbaParser from 'src/utils/rgbaParser'
import { track } from '@amplitude/analytics-browser'

const availableSizes = [
  { label: 'XS' },
  { label: 'S' },
  { label: 'M' },
  { label: 'L' },
  { label: 'XL' },
]
const SETTING_KEY = 'theme'
const ThemeSettings = props => {
  const { classes, entity, setThemeDrawerOpen } = props
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const [page, setPage] = useState('settings') // settings, preset-settings
  const [colors, setColors] = useState({})
  const [pageReady, setPageReady] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const [pickerVisibles, setPickerVisibles] = useState({
    fontColor: false,
    backgroundColor: false,
    cardBackgroundColor: false,
    buttonColor: false,
  })
  const dispatch = useDispatch()

  const courseEntity = useSelector(state => state.assignment.entity)

  const isUserPremium = true // Number(window.CONSTANTS.USER_ROLE_ID) !== 1

  const [themeSettings, setThemeSettings] = useState(
    exists && value !== false ? value : initialThemeSettings,
  )

  const onUploadBackgroundImage = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setThemeSettings({
          ...themeSettings,
          bodyBackgroundImage: URL.STORAGE + result.url,
        })
      }),
    )
  }

  const handleOpenPremiumDetails = () => {
    showModal(dispatch) // premium details
  }

  const saveSettings = () => {
    if (!pageReady) return
    if (!isUserPremium) return

    setPageReady(false)

    const callback = () => {
      setPageReady(true)

      if (!showPreview) {
        setShowPreview(true)
        setTimeout(() => setShowPreview(false), 7500)
      }
    }

    if (!exists) {
      execute(themeSettings, callback)
    } else {
      update(themeSettings, callback)
    }
  }
  const handleLayout = n => {
    let clearSettingObject = {}
    if (n == 0) {
      clearSettingObject = {
        ...themeSettings,
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        bodyBackgroundImage: '',
        cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
        fontColor: { r: 0, g: 0, b: 0, a: 1 },
        cardAlignment: 'top',
        stretch: 2,
      }
    }
    setThemeSettings({ ...themeSettings, ...clearSettingObject, layout: n })
  }

  return (
    <>
      <form
        className={`${classes.content} ${!isUserPremium && classes.locked}`}
      >
        {page == 'settings' && (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                //setThemeSettings(value)
                setPage('preset-settings')
                track('edit_current_theme_settings')
              }}
            >
              Edit Current Settings
            </Button>
            <Divider className={classes.divider}>
              <Chip className={classes.chip} label="OR" />
            </Divider>
            <Typography variant="h5">Discover Presets</Typography>
            <div className={classes.presets}>
              {themePresets.map((value, index) => {
                const backgroundImage =
                  value.theme.bodyBackgroundImage &&
                  value.theme.bodyBackgroundImage != ''
                    ? colors[value.name]
                      ? value.colors[colors[value.name]].bodyBackgroundImage
                        ? value.colors[colors[value.name]].bodyBackgroundImage
                        : `${URL.CDN}assets/theme/${value.name}/${
                            colors[value.name]
                          }.svg` || ''
                      : `${URL.CDN}assets/theme/${value.name}/${value.theme.bodyBackgroundImage}`
                    : ''

                return (
                  <Card className={classes.cardRoot}>
                    <CardActionArea
                      onClick={() => {
                        setThemeSettings({
                          ...value.theme,
                          bodyBackgroundImage: backgroundImage,
                          ...value.colors[
                            colors[value.name] || value.defaultColor
                          ],
                        })
                        setPage('preset-settings')
                      }}
                    >
                      {backgroundImage != '' ? (
                        <CardMedia
                          className={classes.media}
                          image={backgroundImage}
                          title=""
                        >
                          <h3
                            className={classes.previewText}
                            style={{
                              color: rgbaParser(
                                colors[value.name]
                                  ? value.colors[colors[value.name]].fontColor
                                  : value.colors[value.defaultColor].fontColor,
                              ),
                            }}
                          >
                            {value.previewText || ''}
                          </h3>
                        </CardMedia>
                      ) : (
                        <CardMedia className={classes.media} title="">
                          <div
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              backgroundColor: rgbaParser(
                                colors[value.name]
                                  ? value.colors[colors[value.name]]
                                      .backgroundColor
                                  : value.colors[value.defaultColor]
                                      .backgroundColor,
                              ),
                            }}
                          ></div>
                          <h3
                            className={classes.previewText}
                            style={{
                              color: rgbaParser(
                                colors[value.name]
                                  ? value.colors[colors[value.name]].fontColor
                                  : value.colors[value.defaultColor].fontColor,
                              ),
                            }}
                          >
                            {value.previewText || ''}
                          </h3>
                        </CardMedia>
                      )}
                    </CardActionArea>
                    <CardActions className={classes.cardActions}>
                      <div className={classes.colours}>
                        {Object.keys(value.colors).map((colorValue, index) => {
                          return (
                            <div
                              key={index}
                              className={classes.colorButton}
                              style={{ backgroundColor: colorValue }}
                              onClick={() => {
                                setColors({
                                  ...colors,
                                  [value.name]: colorValue,
                                })
                              }}
                            ></div>
                          )
                        })}
                      </div>
                      <div>
                        <IconButton
                          aria-label="settings"
                          size="small"
                          onClick={() => {
                            window.open(
                              colors[value.name]
                                ? value.colors[colors[value.name]].sampleUrl
                                : value.colors[value.defaultColor].sampleUrl,
                              '_blank',
                              'noopener,noreferrer',
                            )
                          }}
                        >
                          <Tooltip title="Preview Sample" placement="top">
                            <RemoveRedEyeIcon />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </CardActions>
                  </Card>
                )
              })}
            </div>
          </>
        )}
        {page == 'preset-settings' && (
          <div>
            <div className={classes.editPresetHeader}>
              <IconButton aria-label="back" onClick={() => setPage('settings')}>
                <ArrowBackIcon />
              </IconButton>
              <h2 className={classes.presetHeader}>Edit Your Theme</h2>

              <div></div>
            </div>
            <div className={classes.padding}></div>
            <Typography variant="h7" gutterBottom component="div">
              Font
            </Typography>
            <FontPicker
              apiKey="AIzaSyCrUWg_5VMW7DzqXAmN8iuB503bLwYfuxQ"
              activeFontFamily={themeSettings?.font || 'Inter'}
              onChange={nextFont =>
                setThemeSettings({
                  ...themeSettings,
                  font: nextFont.family,
                })
              }
            />
            <div className={classes.padding}></div>
            <div className={classes.inputGroup}>
              <div className={classes.inputItem}>
                <Typography variant="h7" gutterBottom component="div">
                  Font Color
                </Typography>
                <div className={classes.pickerBg}>
                  <span
                    className={classes.pickerSpan}
                    style={{
                      backgroundColor: rgbaParser(themeSettings?.fontColor),
                    }}
                    onClick={event => {
                      setPickerVisibles({
                        ...pickerVisibles,
                        fontColor: !pickerVisibles.fontColor,
                      })
                      setAnchorEl(event.currentTarget)
                    }}
                  ></span>
                  <Popover
                    id={pickerVisibles.fontColor ? 'fontColor' : undefined}
                    open={pickerVisibles.fontColor}
                    anchorEl={anchorEl}
                    onClose={() => {
                      setPickerVisibles({ ...pickerVisibles, fontColor: false })
                      setAnchorEl(null)
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <ChromePicker
                      disabled={!pageReady}
                      color={themeSettings?.fontColor || '#000000'}
                      onChange={value => {
                        setThemeSettings({
                          ...themeSettings,
                          fontColor: value.rgb,
                        })
                      }}
                    />
                  </Popover>
                </div>
              </div>
              <div className={classes.inputItem}>
                <Typography variant="h7" gutterBottom component="div">
                  Font Size
                </Typography>
                <Autocomplete
                  disablePortal
                  disableClearable
                  size="small"
                  disabled={!pageReady}
                  options={availableSizes}
                  defaultValue={themeSettings?.fontSize ?? availableSizes[2]}
                  onChange={(e, values) => {
                    setThemeSettings({
                      ...themeSettings,
                      fontSize: values.label,
                    })
                  }}
                  fullWidth
                  renderInput={params => <TextField {...params} label="" />}
                />
              </div>
              <div className={classes.inputItem}>
                <Typography variant="h7" gutterBottom component="div">
                  Line Height
                </Typography>
                <Autocomplete
                  disablePortal
                  disableClearable
                  size="small"
                  disabled={!pageReady}
                  options={availableSizes}
                  defaultValue={themeSettings?.lineHeight ?? availableSizes[0]}
                  onChange={(e, values) => {
                    setThemeSettings({
                      ...themeSettings,
                      lineHeight: values.label,
                    })
                  }}
                  fullWidth
                  renderInput={params => <TextField {...params} label="" />}
                />
              </div>
            </div>
            <div className={classes.padding}></div>
            <Typography variant="h7" gutterBottom component="div">
              Button Color
            </Typography>
            <div className={classes.pickerBg}>
              <span
                className={classes.pickerSpan}
                style={{
                  backgroundColor:
                    rgbaParser(themeSettings?.buttonColor) || '#ffffff',
                }}
                onClick={event => {
                  setPickerVisibles({
                    ...pickerVisibles,
                    buttonColor: !pickerVisibles.buttonColor,
                  })
                  setAnchorEl(event.currentTarget)
                }}
              ></span>

              <Popover
                id={pickerVisibles.buttonColor ? 'buttonColor' : undefined}
                open={pickerVisibles.buttonColor}
                anchorEl={anchorEl}
                onClose={() => {
                  setPickerVisibles({
                    ...pickerVisibles,
                    buttonColor: false,
                  })
                  setAnchorEl(null)
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ChromePicker
                  disabled={!pageReady}
                  color={themeSettings?.buttonColor || '#000000'}
                  onChange={value =>
                    setThemeSettings({
                      ...themeSettings,
                      buttonColor: value.rgb,
                    })
                  }
                />
              </Popover>
            </div>

            <div className={classes.padding}></div>
            <Typography variant="h7" gutterBottom component="div">
              Card Layout
            </Typography>
            <SettingLayout
              classes={classes}
              themeLayout={themeSettings?.layout}
              onChangeLayout={n => handleLayout(n)}
            />
            <div className={classes.padding}></div>
            <div
              style={{
                opacity: themeSettings?.layout == 0 ? 0 : 1,
                transition: '.3s linear all ',
              }}
            >
              <Typography variant="h7" gutterBottom component="div">
                Background Color
              </Typography>
              <div className={classes.pickerBg}>
                <span
                  className={classes.pickerSpan}
                  style={{
                    backgroundColor:
                      rgbaParser(themeSettings?.backgroundColor) || '#ffffff',
                  }}
                  onClick={event => {
                    setPickerVisibles({
                      ...pickerVisibles,
                      backgroundColor: !pickerVisibles.backgroundColor,
                    })
                    setAnchorEl(event.currentTarget)
                  }}
                ></span>

                <Popover
                  id={
                    pickerVisibles.backgroundColor
                      ? 'backgroundColor'
                      : undefined
                  }
                  open={pickerVisibles.backgroundColor}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setPickerVisibles({
                      ...pickerVisibles,
                      backgroundColor: false,
                    })
                    setAnchorEl(null)
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    disabled={!pageReady}
                    color={themeSettings?.backgroundColor || '#ffffff'}
                    onChange={value =>
                      setThemeSettings({
                        ...themeSettings,
                        backgroundColor: value.rgb,
                      })
                    }
                  />
                </Popover>
              </div>
              <div className={classes.padding}></div>
              <Typography variant="h7" gutterBottom component="div">
                Background Image
              </Typography>
              {themeSettings?.bodyBackgroundImage &&
              themeSettings?.bodyBackgroundImage != '' ? (
                <>
                  <div className={classes.bgContainer}>
                    <img
                      className={classes.backgroundImage}
                      src={themeSettings?.bodyBackgroundImage}
                      alt=""
                    />
                    <div
                      className={classes.bgTrash}
                      onClick={() => {
                        setThemeSettings({
                          ...themeSettings,
                          bodyBackgroundImage: '',
                        })
                      }}
                    >
                      <IconButton disabled={!pageReady} aria-label="delete">
                        <DeleteIcon color="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <TextField
                    type="file"
                    fullWidth={true}
                    disabled={!pageReady}
                    onChange={onUploadBackgroundImage}
                  />
                </>
              )}
              <div className={classes.padding}></div>
              <Typography variant="h7" gutterBottom component="div">
                Card Background Color
              </Typography>
              <div className={classes.pickerBg}>
                <span
                  className={classes.pickerSpan}
                  style={{
                    backgroundColor:
                      rgbaParser(themeSettings?.cardBackgroundColor) ||
                      '#ffffff',
                  }}
                  onClick={event => {
                    setPickerVisibles({
                      ...pickerVisibles,
                      cardBackgroundColor: !pickerVisibles.cardBackgroundColor,
                    })
                    setAnchorEl(event.currentTarget)
                  }}
                ></span>

                <Popover
                  id={
                    pickerVisibles.cardBackgroundColor
                      ? 'cardBackgroundColor'
                      : undefined
                  }
                  open={pickerVisibles.cardBackgroundColor}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setPickerVisibles({
                      ...pickerVisibles,
                      cardBackgroundColor: false,
                    })
                    setAnchorEl(null)
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    disabled={!pageReady}
                    color={themeSettings?.cardBackgroundColor || '#ffffff'}
                    onChange={value =>
                      setThemeSettings({
                        ...themeSettings,
                        cardBackgroundColor: value.rgb,
                      })
                    }
                  />
                </Popover>
              </div>{' '}
              <div className={classes.padding}></div>
              <Typography variant="h7" gutterBottom component="div">
                Card Vertical Alignment
              </Typography>
              <SettingDirection
                classes={classes}
                cardAlignment={themeSettings?.cardAlignment}
                onChangeCardAlignment={state =>
                  setThemeSettings({
                    ...themeSettings,
                    cardAlignment: state,
                  })
                }
              />
              <div className={classes.padding}></div>
              <Typography variant="h7" gutterBottom component="div">
                Card Stretch
              </Typography>
              <SettingStretch
                themeStretch={themeSettings?.stretch}
                onToggleStretch={state => {
                  let newStretch = themeSettings?.stretch - 1
                  if (newStretch <= 0) newStretch = 3
                  setThemeSettings({
                    ...themeSettings,
                    stretch: newStretch,
                  })
                }}
              />
            </div>
            <div className={classes.padding}></div>
            <div className={classes.padding}></div>
            <div className={classes.padding}></div>
            <div className={classes.padding}></div>
            <div className={classes.padding}></div>
            {!pageReady ? (
              <div className={classes.bottomButton}>
                <CircularProgress></CircularProgress>
              </div>
            ) : (
              <div className={classes.bottomButton} style={{ gap: 5 }}>
                {showPreview && (
                  <Button
                    style={{
                      background: 'white',
                    }}
                    variant="outlined"
                    size="small"
                    href={
                      entity
                        ? URLs.APP_URL + courseEntity[AssignmentFieldNames.CODE]
                        : ''
                    }
                    target="_blank"
                  >
                    Preview
                  </Button>
                )}
                <Button
                  fullWidth={true}
                  disabled={!pageReady}
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={saveSettings}
                >
                  {pageReady ? 'Apply Your Theme' : 'Saving...'}
                </Button>
              </div>
            )}
          </div>
        )}
      </form>
      {!isUserPremium && (
        <>
          <div className={classes.premiumContainer}>
            <img src="/assets/premium.gif" alt="premium" width="128" />
            <Alert severity="warning">
              You can customize with Premium Plan.
            </Alert>
            <Button
              variant="contained"
              style={{ marginTop: '1rem' }}
              onClick={handleOpenPremiumDetails}
            >
              Upgrade your Plan
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default withStyles(styles)(ThemeSettings)
