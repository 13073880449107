const content = [
  {
    header: '',
    explanation:
      ' You have exceptional flexibility in controlling how learners can access your mini-courses and collections (courses).',
    buttonText: 'Let’s check',
    skip: false,
  },
  {
    header: 'Grant exclusive access',
    explanation:
      'For instance, you can grant access to specific individuals through invitation-only access.',
    buttonText: 'Continue',
    skip: true,
  },
  {
    header: 'Add pay-wall',
    explanation:
      'Or, you can directly get paid for your know-how by enabling access to your courses behind a payment wall.',
    buttonText: 'Continue',
    skip: true,
  },
  {
    header: 'Add sign-up wall',
    explanation:
      'Another option is to enable access after a sign-up wall, through which you can collect emails to generate leads and start building engagement.',
    buttonText: 'Continue',
    skip: true,
  },
  {
    header: 'Build automations',
    explanation:
      'You can also build automations for delivering mini-courses triggered by specific actions through Zapier and Make integrations.',
    buttonText: 'Done 🎉',
    skip: false,
  },
]
export default content
