import React from 'react'
import {
  Typography,
  withStyles,
  Divider,
  TextField,
  Button,
} from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import URLs from 'src/api/url'
const EmbedViewHeader = props => {
  const { classes, handleView, entity } = props
  const [copiedEmbedCode, setCopiedEmbedCode] = React.useState(false)
  const [copiedEmbedCode2, setCopiedEmbedCode2] = React.useState(false)

  const onClickEmbedCode = () => {
    setCopiedEmbedCode(true)
    setTimeout(() => {
      setCopiedEmbedCode(false)
    }, 500)
  }
  const onClickEmbedCode2 = () => {
    setCopiedEmbedCode2(true)
    setTimeout(() => {
      setCopiedEmbedCode2(false)
    }, 500)
  }
  return (
    <div className={classes.customDomain__root}>
      <div
        className={classes.customDomain__headerWithIcon}
        onClick={() => handleView('share')}
      >
        <Icon icon="ic:round-chevron-left" color="#002E47" fontSize={24} />

        <Typography
          fontSize={'15px'}
          fontWeight={500}
          style={{
            marginBottom: '4px',
          }}
        >
          Embed on a website
        </Typography>
      </div>
      <Typography color={'#637381'} fontSize={'14px'} fontWeight={400}>
        You can embed your site with JavaScript code.
      </Typography>
      <Typography color={'#002E47'} fontSize={'14px'} fontWeight={400}>
        Add the following codes to the{' '}
        <span
          style={{
            color: '#015ffb',
          }}
        >
          {'<head>'}
        </span>{' '}
        tag of your site.
      </Typography>
      <TextField
        fullWidth
        disabled={true}
        multiline
        maxRows={3}
        sx={{ input: { color: '#637381', backgroundColor: '#F4F6F8' } }}
        value={`<meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
<script src="{1}viewer.js"></script>
<script>
const app = new MiniCourse();
document.addEventListener("DOMContentLoaded", () => {
  app.init({
    categoryId: "{2}"
  })
})
</script>`
          .replace('{0}', URLs.APP_URL)
          .replace('{1}', URLs.APP_URL)
          .replace('{2}', entity.id)}
        className={classes.textField}
      />

      <CopyToClipboard
        text={`<meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
                    <script src="{1}viewer.js"></script>
                    <script>
                      const app = new MiniCourse();

                      document.addEventListener("DOMContentLoaded", () => {
                        app.init({
                          categoryId: "{2}"
                        })
                      })
                    </script>`
          .replace('{0}', URLs.APP_URL)
          .replace('{1}', URLs.APP_URL)
          .replace('{2}', entity.id)}
        className={classes.shareViewCopyButton}
      >
        <Button
          onClick={onClickEmbedCode}
          variant="outlined"
          className={classes.shareViewCopyButton}
        >
          {copiedEmbedCode ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>

      <Typography color={'#002E47'} fontSize={'14px'} fontWeight={400}>
        Add the following codes to the{' '}
        <span
          style={{
            color: '#015ffb',
          }}
        >
          {'<body>'}
        </span>{' '}
        tag of your site.
      </Typography>
      <TextField
        fullWidth
        value={'<div id="mcg-category-' + entity.id + '"></div>'}
        className={classes.textField}
        sx={{ input: { color: '#637381' } }}
        disabled={true}
      />

      <CopyToClipboard
        text={'<div id="mcg-category-' + entity.id + '"></div>'}
        className={classes.shareViewCopyButton}
      >
        <Button
          onClick={onClickEmbedCode2}
          variant="outlined"
          textTransform="none"
          className={classes.shareViewCopyButton}
        >
          {copiedEmbedCode2 ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>
    </div>
  )
}

export default withStyles(styles)(EmbedViewHeader)
