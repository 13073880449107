import React from 'react'
import Stars from './stars'
import Freeform from './freeform'
import NumericEntry from './numeric-entry'
import PhotoAnswer from './photo-answer'
import Inform from './inform'
import { AnswerItemType } from '../../../../model/question'
import Option from './option'
import Payment from './stripe-payment'
import Sertifier from './sertifier'
import Score from './score'
import { guid } from 'src/utils/guid'
import Ai from './ai'

/**
 *
 * @param {*} props
 */
const BottomBarFactory = props => {
  const { type, id } = props
  props = { ...props, key: id }

  switch (type) {
    case AnswerItemType.INFORM:
      return <Inform {...props} />
    case AnswerItemType.TESTINGSINGLECHOICE:
      return <Option {...props} />
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYSINGLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      return <Option {...props} />
    case AnswerItemType.SURVEYFREEFORM:
      return <Freeform {...props} />
    case AnswerItemType.SURVEYINPUT:
      return <PhotoAnswer {...props} />
    case AnswerItemType.SURVEYSTARS:
      return <Stars {...props} />
    case AnswerItemType.SURVEYNUMERICENTRY:
      return <NumericEntry {...props} />
    case AnswerItemType.STRIPEPAYMENT:
      return <Payment {...props} key={guid.uid} />
    case AnswerItemType.SERTIFIERCARD:
      return <Sertifier {...props} key={guid.uid} />
    case AnswerItemType.SCORECARD:
      return <Score {...props} key={guid.uid} />
    case AnswerItemType.RESULTDEFAULT:
      return <Inform {...props} />
    case AnswerItemType.AI:
      return <Ai {...props} />
    default:
      return ''
  }
}

export default BottomBarFactory
