import React from 'react'
import styles from '../style'
import { Grid, Typography, withStyles } from '@material-ui/core'
import translator from 'src/translate'
import { AnswerItemType } from 'src/model/question'
import { AssignmentFieldNames } from 'src/model/assignment'
import { QuestionFieldNames } from 'src/model/question'
const BasicTypes = props => {
  const { classes, saveType, entity } = props
  const BasicOptions = [
    {
      name: 'bite-sized-information',
      icon: '/assets/icons/task/viewing-content.png',
      title: 'Information/Knowledge',
      description: translator._('tasks.biteSized.description'),
      description2: translator._('tasks.biteSized.description2'),
      exampleImage: '/assets/icons/task/bite-size-example.png',
      dataType: AnswerItemType.INFORM,
    },
    {
      name: 'bite-sized-information',
      icon: '/assets/icons/task/new-ai-icon-card.png',
      title: 'Let AI assist you',
      exampleImage: '/assets/icons/task/bite-size-example.png',
      dataType: AnswerItemType.AI,
    },
    {
      name: 'password',
      icon: '/assets/icons/task/password.png',
      title: 'Password protection',
      description: translator._('tasks.password.description'),
      description2: translator._('tasks.password.description2'),
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.PROTECTIONCARD,
      lockedMessage: 'Password card was used.',
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(
            x =>
              x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.PROTECTIONCARD,
          ) != null
        : false,
      userRoleControl: false,
    },
  ]
  return (
    <div container className={classes.list}>
      <Typography className={classes.listTitle}>BASICS</Typography>

      {BasicOptions.map((option, index) => {
        return (
          <div
            className={classes.listItems}
            onClick={() => saveType(option.dataType)}
          >
            <img
              alt="cardIcon"
              src={option.icon}
              className={classes.listImage}
            />
            <div>
              <Typography className={classes.listText}>
                {option.title}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(BasicTypes)
