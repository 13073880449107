import React from 'react'
import { withStyles, useMediaQuery } from '@material-ui/core'
import styles from '../styles'
import { AssignmentFieldNames } from '../../../model/assignment'
import QuestionCardList from 'src/components/card-list'

const TasksStep = props => {
  const {
    dispatch,
    onAdd,
    entity,
    classes,
    submitEntity,
    onDrag,
    page,
    setPage,
    setThemeDrawerOpen,
    listingType,
    sortCard,
    onDelete,
  } = props

  return (
    <QuestionCardList
      content={{}}
      entity={entity}
      list={
        entity
          ? entity[AssignmentFieldNames.QUESTION_DTOS]
            ? entity[AssignmentFieldNames.QUESTION_DTOS]
            : []
          : []
      }
      courseId={
        entity
          ? entity[AssignmentFieldNames.ID]
            ? entity[AssignmentFieldNames.ID]
            : 0
          : 0
      }
      baseEntity={entity}
      dispatch={dispatch}
      onAddCard={onAdd}
      sortCard={sortCard}
      onAdd={() => {}}
      onMove={() => {}}
      onRemove={() => {}}
      showSortOrder={true}
      onDragEnd={onDrag}
      page={page}
      setPage={setPage}
      courseCode={
        entity
          ? entity[AssignmentFieldNames.CODE]
            ? entity[AssignmentFieldNames.CODE]
            : 0
          : 0
      }
      setThemeDrawerOpen={setThemeDrawerOpen}
      listingType={listingType}
      onDelete={onDelete}
    />
  )
  // }
}

export default withStyles(styles)(TasksStep)
