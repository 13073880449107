import { put, take, fork, call } from 'redux-saga/effects'
import { assignmentRemoveQuestionService } from '../../api/assignment'

import { ASSIGNMENT_REMOVE_QUESTION } from '../../reducers/assignment/type'
import { assignmentRemoveQuestionSuccess } from '../../reducers/assignment/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentRemoveQuestionFlow(payload) {
  try {
    const { courseId, questionId, callback } = payload

    const response = yield call(
      assignmentRemoveQuestionService,
      questionId,
      courseId,
    )
    yield put(assignmentRemoveQuestionSuccess(questionId))

    callback()

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail())
  }
}

/**
 *
 */
export default function* assignmentRemoveQuestionWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_REMOVE_QUESTION)
    const { payload } = action

    yield fork(generalSaga, assignmentRemoveQuestionFlow, payload, false)
  }
}
