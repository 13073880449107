const pageView = {
  MiniCourses: 'Mini Courses',
  Overview: 'Overview',
  LearnersAccess: "Learners' Access",
  CustomDomain: 'Custom Domain',
  Certification: 'Certification',
  Analytics: 'Analytics',
  //Advanced: 'Advanced',
}

export default pageView
