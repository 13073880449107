import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentCardLinkPatchService } from '../../api/assignment'
import { assignmentCardLinkPatchSuccess } from '../../reducers/assignment/action'
import { ASSIGNMENT_CARD_LINK_PATCH } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentCardLinkPatchFlow(payload) {
  try {
    const { content, cardId, callback } = payload
    const response = yield call(assignmentCardLinkPatchService, content)
    yield put(assignmentCardLinkPatchSuccess(content, cardId))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentCardLinkPatchWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_CARD_LINK_PATCH)
    const { payload } = action

    yield fork(generalSaga, assignmentCardLinkPatchFlow, payload)
  }
}
