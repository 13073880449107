const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root: {
    height: '100vh',
  },
  video: {
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },

  image: {
    backgroundImage: 'url(/assets/signup_screen_newbg4.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative',
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  /*     paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3,
        )}px`,
    }, */
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  combo: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    //borderWidth: 1,
    //borderStyle: 'solid',
    boxSizing: 'border-box',
    padding: theme.spacing(2),

    '&:hover': {
      borderColor: '#80bdff',
    },
  },
  overlayText: {
    maxWidth: '90%',
    padding: 20,
    margin: '5%',
    background: '#f0ffff14',
    bottom: 0,
    position: 'absolute',
    color: 'whitesmoke',
  },
})

export default styles
