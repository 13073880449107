import { put, call, take, fork } from 'redux-saga/effects'
import { excelExportCourseCollectionAnalyticsService } from 'src/api/excel-report'
import FileDownload from 'js-file-download'
import { EXPORT_COLLECTION_ANALYTICS } from 'src/reducers/course-collection/type'
import { exportCollectionAnalyticsSuccess } from 'src/reducers/course-collection/action'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseCollectionAnalyticsDownloadFlow(payload) {
  try {
    const { collectionId } = payload

    const response = yield call(
      excelExportCourseCollectionAnalyticsService,
      collectionId,
    )

    yield put(exportCollectionAnalyticsSuccess())

    FileDownload(response, 'report.xlsx')

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseCollectionAnalyticsDownloadWatcher() {
  while (true) {
    const action = yield take(EXPORT_COLLECTION_ANALYTICS)
    const { payload } = action

    yield fork(generalSaga, courseCollectionAnalyticsDownloadFlow, payload)
  }
}
