import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import CustomizedSwitch from 'src/components/customized-switch'
import { Icon } from '@iconify/react'

const CustomDomainHeader = props => {
  const { classes } = props
  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__headerWithIcon}>
        <Typography fontSize={'15px'} fontWeight={500}>
          Custom Domain
        </Typography>
      </div>
      <Typography color={'#637381'} fontSize={'13px'} fontWeight={400}>
        You can find detailed information about "Add CNAME to your DNS Records"
        to{' '}
        <a
          href="https://share.minicoursegenerator.com/7tX3umAUDUeH4lM9S2Maew/1"
          target="_blank"
        >
          {' '}
          this link.
        </a>
      </Typography>
    </div>
  )
}

export default withStyles(styles)(CustomDomainHeader)
