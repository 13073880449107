import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { getSubHeaderItemStyle } from 'src/utils/dnd/getCardTitleItemStyle'
import { getAnswerListStyle } from 'src/utils/dnd/getCardTitleListStyle'
import { withStyles, styled } from '@material-ui/core/styles'

import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { Typography, TextField, Box, Button } from '@material-ui/core'
import styles from './styles'
import Iconify from 'src/components/Iconify'

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: '0px !important',
  },

  '& .MuiInput-underline:after': {
    borderBottom: '0px',
  },
  '& .MuiInputBase-input': {
    fontSize: '13px',
  },
})

const SubHeaders = props => {
  const {
    header,
    outlineNum,
    handleSubHeaderItemSave,
    handleSubHeaderClick,
    handleSubHeaderItemOnChange,
    deleteSubheader,
    addOwnSubheader,
    page,
    classes,
  } = props
  const inputFocus = id => {
    document.getElementById(id).focus()
  }

  const lastSubheader = header.subHeaders[header.subHeaders.length - 1]
  const sortOrderOfLastSubheader = lastSubheader.index
  const sectionOfLastSubheader = {
    name: lastSubheader.sectionName,
    id: lastSubheader.sectionId,
  }

  return (
    <Droppable droppableId={`droppable${header.id}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getAnswerListStyle(snapshot.isDraggingOver)}
        >
          {header.subHeaders.map((subHeader, index) => {
            return (
              <Draggable
                key={`${outlineNum}${index}`}
                draggableId={`${outlineNum}${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getSubHeaderItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      page == subHeader.id,
                    )}
                    onClick={() => handleSubHeaderClick(subHeader.id)}
                  >
                    <Box className={classes.header}>
                      <span {...provided.dragHandleProps} className="dragIcon">
                        <Iconify icon="icon-park-outline:drag"></Iconify>
                      </span>
                      <CssTextField
                        id={subHeader.id + '_' + index}
                        key={subHeader.id + '_' + index}
                        defaultValue={subHeader.name}
                        value={subHeader.name}
                        variant="standard"
                        style={{ borderBottom: 0, minWidth: '65%' }}
                        onBlur={e =>
                          handleSubHeaderItemSave(
                            e,
                            outlineNum,
                            index,
                            subHeader.id,
                            subHeader.name,
                          )
                        }
                        onChange={e => {
                          handleSubHeaderItemOnChange(
                            e,
                            outlineNum,
                            index,
                            subHeader.id,
                            subHeader.name,
                          )
                        }}
                        onFocus={() => handleSubHeaderClick(subHeader.id)}
                        placeholder="Card Title"
                        multiline
                      />
                      <div style={{ flexGrow: 1 }}></div>
                      <span className="buttons">
                        <EditIcon
                          onClick={() => {
                            inputFocus(header.id + '_' + index)
                          }}
                          color="inherit"
                          fontSize="small"
                          className={classes.button}
                        />
                        <DeleteIcon
                          onClick={() => {
                            deleteSubheader(subHeader.id)
                          }}
                          color="inherit"
                          fontSize="small"
                          className={classes.button}
                        />
                      </span>
                    </Box>
                  </div>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
          <div style={{}}>
            <Button
              variant="text"
              size="small"
              className={classes.addCardButton}
              onClick={() =>
                addOwnSubheader(
                  outlineNum,
                  sortOrderOfLastSubheader,
                  sectionOfLastSubheader,
                )
              }
            >
              <Iconify icon={'iconoir:plus'} width={20} height={20} />
              <Typography className={classes.addItem}>Add Card</Typography>
            </Button>
          </div>
        </div>
      )}
    </Droppable>
  )
}

export default withStyles(styles)(SubHeaders)
