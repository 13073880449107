const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    padding: '40px',
    marginBottom: '40px',
  },
})

export default styles
