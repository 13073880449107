import React from 'react'
import { Button, withStyles, Typography } from '@material-ui/core'
import styles from '../../styles'

const BackButton = props => {
  const { handleBack, classes } = props
  return (
    <Button
      variant="outlined"
      onClick={handleBack}
      className={classes.backButton}
    >
      <Typography color={'#002E47'}>Back</Typography>
    </Button>
  )
}

export default withStyles(styles)(BackButton)
