import React, { useState, useRef, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../translate'
import Media from '../media'
import AnswerTypeFactory from '../answer-type/factory'
import {
  QuestionFieldNames,
  AnswerItemType,
  QuestionEditorHeights,
  QuestionPlaceholders,
  QuestionMediaFieldNames,
} from '../../../model/question'
import { questionPatchAction } from '../../../reducers/question/action'
import { PatchContent, PatchData } from '../../../model/patch'
import {
  Grid,
  withStyles,
  Paper,
  Card,
  Box,
  IconButton,
  Divider,
  Tooltip,
  Button,
} from '@material-ui/core'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import MDEditor, {
  commands,
  ICommand,
  EditorContext,
} from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'
import {
  blockquote,
  align,
  font,
  fontSize,
  fontColor,
  hiliteColor,
  horizontalRule,
  list,
  table,
  formatBlock,
  lineHeight,
  template,
  paragraphStyle,
  textStyle,
  link,
  image,
  audio,
  math,
  imageGallery,
} from 'suneditor/src/plugins'

import { isMobile } from 'react-device-detect'
import CircularProgress from '@material-ui/core/CircularProgress'
import { convert, Rule, use } from 'html-to-markdown'

import BottomBar from '../bottom-bar/'

import { questionMediaAddAction } from '../../../reducers/question/action'
import { questionMediaDeleteAction } from '../../../reducers/question/action'

import AddIcon from '@material-ui/icons/Add'
import URL from 'src/api/url'

import { AssignmentFieldNames } from 'src/model/assignment'

import { mediaImageUploadAction } from 'src/reducers/media-image/action'

import CardSelectionPopup from 'src/components/card-list/card-selection-popup/index'

import { useCourse } from 'src/hooks/useCourseSettings'
import {
  componentBoxShowAction,
  componentBoxHideAction,
} from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import OpenAiPopup from './openai-card-complete/index'
import options from '../../select-type-widget/options'
import CardTitle from '../card-title/index'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import { Icon } from '@iconify/react'

import {
  questionSettingPatch,
  questionSettingCreate,
  questionSettingRemove,
} from 'src/reducers/assignment/action'
import { isAnswerItemTypeResultCard } from 'src/model/question'

// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css')
require('medium-editor/dist/css/themes/default.css')

const QuestionMain = props => {
  const {
    classes,
    back,
    index,
    entity,
    dispatch,
    onDrag,
    onClick,
    cardCount,
    onAddCard,
    setPage,
    lastCardFinish,
    page,
    sortCard,
    onDelete,
  } = props

  const baseEntity = useCourse()
  const courseId = baseEntity[AssignmentFieldNames.ID]

  const [updatedQText, setQText] = useState(entity[QuestionFieldNames.QTEXT])
  const [updatedMarkdownText, setMarkdownText] = useState(
    entity[QuestionFieldNames.QTEXT],
  )
  const [saving, setSaving] = useState(false)

  const fileInputRef = useRef(null)
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const editorRef = useRef()

  const handleFileChange = event => {
    const file = event.target.files[0]

    if (file) {
      dispatch(
        mediaImageUploadAction(event.target.files[0], '', result => {
          const imageUrl = `![${file.name}](${URL.STORAGE}${result.url})`
          const fullText = insertTextAtCursor(updatedMarkdownText, imageUrl)
          setMarkdownText(fullText)
          onBlur(fullText)
        }),
      )
    }
  }
  const insertTextAtCursor = (text, textToInsert) => {
    const textarea = editorRef.current?.textarea
    if (textarea) {
      const startPos = textarea.selectionStart
      const endPos = textarea.selectionEnd
      return (
        text.substring(0, startPos) +
        textToInsert +
        text.substring(startPos, text.length)
      )
    }
    return text + textToInsert
  }

  const AddImage = () => {
    return (
      <button title="Image" onClick={handleButtonClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="currentColor"
            d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3M6 5h12a1 1 0 0 1 1 1v8.36l-3.2-2.73a2.77 2.77 0 0 0-3.52 0L5 17.7V6a1 1 0 0 1 1-1m12 14H6.56l7-5.84a.78.78 0 0 1 .93 0L19 17v1a1 1 0 0 1-1 1"
          ></path>
          <circle cx={8} cy={8.5} r={1.5} fill="currentColor"></circle>
        </svg>
      </button>
    )
  }
  const addImage = {
    name: '',
    keyCommand: 'Add Image',
    value: 'Add Image',
    icon: <AddImage />,
  }

  const editorSetting = entity[QuestionFieldNames.SETTING_DTOS].find(
    x => x.key === 'editorSetting',
  )
  const [loading, setLoading] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const [imageURL, setImageURL] = useState(
    entity[QuestionFieldNames.QUESTION_MEDIAS].length > 0
      ? entity[QuestionFieldNames.QUESTION_MEDIAS][0][
          QuestionMediaFieldNames.MEDIA_URL
        ]
      : null,
  )
  const [editorType, setEditorType] = useState(
    editorSetting == undefined ? 'htmlEditor' : editorSetting.value,
  )

  const widgetParent = options({ entity: baseEntity }).find(o =>
    o.contents.find(
      oc => oc.dataType === entity[QuestionFieldNames.QUESTION_TYPE],
    ),
  )
  const open = Boolean(anchorEl)

  const patchAction = (id, key, value) => {
    setLoading(true)
    const entityId = entity[QuestionFieldNames.ID]
    setEditorType(value)

    editorSetting != undefined
      ? dispatch(
          questionSettingPatch(
            {
              path: 'Value',
              value: value,
            },
            id,
            entityId,
            key,
            () => {
              setLoading(false)
            },
          ),
        )
      : dispatch(
          questionSettingCreate(
            {
              cardId: entityId,
              key: key,
              value: value,
            },
            entityId,
            () => {
              setLoading(false)
            },
          ),
        )
  }
  const spanRef = React.useRef()
  const editor = useRef()

  function preprocessHtml(html) {
    const preCodeRegex = /<pre>\s*<code classname="([^"]*)">\s*([\s\S]*?)\s*<\/code>\s*<\/pre>/g
    return html.replace(preCodeRegex, (match, lang, code) => {
      // Ensure code is properly escaped for Markdown
      const escapedCode = code.replace(/`/g, '\\`')
      return `\`\`\`${lang}\n${escapedCode}\n\`\`\``
    })
  }
  // ES module
  var plugin_command = {
    // @Required @Unique
    // plugin name
    name: 'customCommand',
    // @Required
    // data display
    display: 'command',

    // @Options
    title: 'Markdown Editor',
    buttonClass: '',
    innerHTML:
      '<svg xmlns="http://www.w3.org/2000/svg" class="external-icon-suneditor" width="24" height="24" viewBox="0 0 16 16"><g fill="black"><path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"/><path fill-rule="evenodd" d="M9.146 8.146a.5.5 0 0 1 .708 0L11.5 9.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708"/><path fill-rule="evenodd" d="M11.5 5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5"/><path d="M3.56 11V7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11z"/></g></svg>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context
      const rangeTag = core.util.createElement('div')
      core.util.addClass(rangeTag, '__se__format__range_custom')

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customCommand = {
        targetButton: targetElement,
        tag: rangeTag,
      }
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active: function (element) {
      if (!element) {
        this.util.removeClass(this.context.customCommand.targetButton, 'active')
      } else if (this.util.hasClass(element, '__se__format__range_custom')) {
        this.util.addClass(this.context.customCommand.targetButton, 'active')
        return true
      }

      return false
    },

    onClick: function (element) {},
    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function (element) {
      setEditorType('markdownEditor')

      // will add a white space after the <h1> <h2> and <p> and <pre> and <code>tags
      //replace all br tags with \n
      //replace bold tag  <strong> with "<strong> "
      //replace <em> tag with "<em> "
      //replace <em> tag with "<em> \n"
      //replace <strong> tag with "<strong> \n"

      !loading &&
        patchAction(editorSetting?.id, 'editorSetting', 'markdownEditor')

      const pTagRegex = /<\/?p>/g
      const htmlImageRegex = /<div class="se-component se-image-container __se__float-none">.*?<img src="([^"]+)" alt="([^"]*)" .*?<\/figure><\/div>/g

      let rawText = editor.current.getContents()

      rawText = rawText.replaceAll(/<h1>/g, '\n<h1> ')
      rawText = rawText.replaceAll(/<\/p>/g, '</p>\n')
      rawText = rawText.replaceAll(pTagRegex, '')
      rawText = rawText.replaceAll(/<ol>/g, '\n<ol>')
      rawText = rawText.replaceAll(/<ul>/g, '\n<ul>')
      rawText = rawText.replaceAll(/<li>/g, '\n<li>')
      rawText = rawText.replaceAll(/<pre>/g, '\n<pre>')
      rawText = rawText.replaceAll(/<strong>/g, '<strong> ')
      rawText = rawText.replaceAll(/<h2>/g, '\n<h2> ')
      rawText = rawText.replaceAll(/<h3>/g, '\n<h3> ')
      rawText = rawText.replaceAll(/<h4>/g, '\n<h4> ')
      rawText = rawText.replaceAll(/<u>/g, '<u> ')
      rawText = rawText.replaceAll(/<br>/g, '\n')
      rawText = rawText.replaceAll(/<em>/g, '<em> ')
      // will add &nbsp; after the </h1> </h2> and </p> tags
      rawText = rawText.replaceAll(/<\/h1>/g, '</h1> \n')
      rawText = rawText.replaceAll(/<\/h2>/g, '</h2> \n')
      rawText = rawText.replaceAll(/<\/h3>/g, '</h3> \n')
      rawText = rawText.replaceAll(/<\/h4>/g, '</h4> \n')
      rawText = rawText.replaceAll(/<\/pre>/g, '</pre> \n')
      rawText = rawText.replaceAll(/<\/em>/g, '</em> ')
      rawText = rawText.replaceAll(/<\/strong>/g, '</strong> ')
      //ul il ol closing tags with \n
      rawText = rawText.replaceAll(/<\/ol>/g, '\n</ol>\n')

      const anchorTagRegex = /<a href="([^"]+)">([^<]+)<\/a>/g
      // Replace each <a> tag with its Markdown equivalent
      rawText = rawText.replaceAll(anchorTagRegex, '\n[$2]($1)\n')

      // Replace each HTML image structure with Markdown image syntax
      rawText = rawText.replace(htmlImageRegex, '![$2]($1)')

      const preProcessedHtml = preprocessHtml(rawText)

      //
      //<iframe width="560" height="315" src="https://www.youtube.com/embed/ryqFVcip_hI?si=GrpTo8jBivYxZKa0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      //i have this iframe code
      //i want to replace the iframe tags and add them after convert as it was inside the text
      //<iframe/iframe>

      //replace whitespace after "``` " with "```"
      let markdownOutput = convert(preProcessedHtml)
        .replace(/``` /g, '```')
        .replaceAll('** ', '**')
        .replaceAll('* ', '*')
        .replaceAll('&nbsp', '\n')

      setMarkdownText(markdownOutput)
      onBlur(markdownOutput)
    },
  }
  var plugin_command2 = {
    // @Required @Unique
    // plugin name
    name: 'customCommand2',
    // @Required
    // data display
    display: 'command2',
    title: 'HTML Editor',
    // @Options
    buttonClass: 'html-button-class',
    innerHTML:
      '<svg xmlns="http://www.w3.org/2000/svg" width="24"  height="24"viewBox="0 0 256 256" class="external-icon-suneditor" ><path  fill="currentColor" d="M128 152a8 8 0 0 1-8 8h-8v48a8 8 0 0 1-16 0v-48h-8a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8m-64-8a8 8 0 0 0-8 8v20H40v-20a8 8 0 0 0-16 0v56a8 8 0 0 0 16 0v-20h16v20a8 8 0 0 0 16 0v-56a8 8 0 0 0-8-8m176 56h-12v-48a8 8 0 0 0-16 0v56a8 8 0 0 0 8 8h20a8 8 0 0 0 0-16m-45.86-55.71a8 8 0 0 0-9 3.59L168 176.45l-17.14-28.57A8 8 0 0 0 136 152v56a8 8 0 0 0 16 0v-27.12l9.14 15.24a8 8 0 0 0 13.72 0l9.14-15.24V208a8 8 0 0 0 16 0v-56a8 8 0 0 0-5.86-7.71M208 120a8 8 0 0 1-8-8V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88v24a8 8 0 0 1-8 8m-19.31-40L160 51.31V80Z" /> </svg>',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      const context = core.context
      const rangeTag = core.util.createElement('div')
      core.util.addClass(rangeTag, '__se__format__range_custom')

      // @Required
      // Registering a namespace for caching as a plugin name in the context object
      context.customCommand = {
        targetButton: targetElement,
        tag: rangeTag,
      }
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active: false,
  }
  const handleClick = event => {
    const component = new ComponentBoxContent(
      (
        <CardSelectionPopup
          dispatch={dispatch}
          onAddCard={list => {
            onAddCard(list, sortCard, index + 1)
          }}
          type={index + 1 == cardCount ? 'end' : 'middle'}
          section={entity[QuestionFieldNames.SECTION]}
        />
      ),
      {
        hideCloseButton: false,
      },
    )

    dispatch(componentBoxShowAction(component))
  }

  const getSunEditorInstance = sunEditor => {
    editor.current = sunEditor
  }
  const addTextToTextArea = text => {
    if (editorType == 'markdownEditor') {
      onChangeMarkDown(text)
      onBlur(text)
      return
    }
    onBlur(text)
    editor.current.setContents(text)
    onChange(text)
  }

  /* useEffect(() => {
    setQText(entity[QuestionFieldNames.QTEXT])
  }, [entity])
 */
  const openEmptyPopup = value => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <OpenAiPopup
              entity={entity}
              dispatch={dispatch}
              value={value}
              addTextToTextArea={addTextToTextArea}
              editor={editor.current}
              updatedMarkdownText={updatedMarkdownText}
              editorType={editorType}
              onChangeMarkDown={onChangeMarkDown}
            />
          ),
          {
            hideCloseButton: false,
            callback: e => {},
          },
        ),
      ),
    )
  }

  /**
   *
   * @param {*} e
   */
  const onChange = async value => {
    if (editorType != 'htmlEditor') return

    if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] == ''
    ) {
      openEmptyPopup('')
    } else if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] !== ''
    ) {
      setAnchorEl(spanRef.current)
    }
    setQText(value.replace('+++', ''))
  }
  /**
   *
   * @param {*} e
   */
  const onChangeMarkDown = async value => {
    if (editorType != 'markdownEditor') return

    if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] == ''
    ) {
      openEmptyPopup('')
    } else if (
      value.includes('+++') &&
      value.replace(/(<([^>]+)>)/gi, '').split('+++')[0] !== ''
    ) {
      setAnchorEl(spanRef.current)
    }
    setMarkdownText(value.replace('+++', ''))
  }

  /**
   *
   */
  const onBlur = async value => {
    if (entity[QuestionFieldNames.QTEXT] !== value) {
      setSaving(true)
      await dispatch(
        questionPatchAction(
          new PatchData(
            new PatchContent(
              value,
              QuestionFieldNames.QTEXT,
              PatchContent.OPERATION_REPLACE,
            ),
            entity[QuestionFieldNames.ID],
          ),
          () => {
            setSaving(false)
          },
        ),
      )
    }
  }

  const toggleCodeView = isCodeView => {
    let content = editor.current.getContents()

    onBlur(content)
  }
  const conditionAddCard = !(index == cardCount - 1)

  const onOk = (items, close) => {
    const item = items[0]
    let object = {}
    let url = item.url
    if (item.type == 2) {
      url = item.link
    } else {
      url = item.url
      if (url && url.includes('Content/storage/sorbe')) {
        setImageURL(`${URL.STORAGE}` + url)
      } else if (url && url.includes('production/images/')) {
        setImageURL(`${URL.STORAGE_QUESTION_IMAGE}` + url)
      }
    }
    object[QuestionMediaFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
    object[QuestionMediaFieldNames.MEDIA_URL] = url
    object[QuestionMediaFieldNames.MEDIA_TYPE_ID] = item.type

    dispatch(
      questionMediaAddAction(object, () => {
        dispatch(componentBoxHideAction())
        if (close) {
          dispatch(componentBoxHideAction())
        }
      }),
    )
  }

  const onRemove = assignment => {
    const card = assignment['cardDtos'].find(
      x => x[QuestionFieldNames.ID] == entity[QuestionFieldNames.ID],
    )
    const id =
      card[QuestionFieldNames.QUESTION_MEDIAS][0][QuestionMediaFieldNames.ID]

    dispatch(questionMediaDeleteAction(id, entity[QuestionFieldNames.ID]))
  }

  const renderer = new marked.Renderer()
  renderer.code = (code, language) => {
    const formattedCode = code.replace(/\n/g, '<br>')
    return `<pre><code classname="${language}">${formattedCode}</code></pre>`
  }
  marked.use({ renderer })

  const ChangeEditor = () => {
    const click = () => {
      // add \n after the <pre> and </pre> tags

      !loading && patchAction(editorSetting?.id, 'editorSetting', 'htmlEditor')
      const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g
      const markdownImageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g

      let rawMarkup = updatedMarkdownText.replace(
        markdownImageRegex,
        '<div class="se-component se-image-container __se__float-none">' +
          '<figure>' +
          '<img src="$2" alt="$1" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="," data-align="none" data-percentage="auto,auto" data-index="0" data-file-name="$1" data-file-size="100" data-origin="," style="">' +
          '</figure>' +
          '</div>',
      )
      // Replace each Markdown link with its HTML <a> tag equivalent
      rawMarkup = rawMarkup.replace(markdownLinkRegex, '<a href="$2">$1</a>')

      // Replace each Markdown image with the desired HTML structure

      rawMarkup = rawMarkup.replaceAll(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      rawMarkup = rawMarkup.replaceAll(/\*(.*?)\*/g, '<em>$1</em>')

      rawMarkup = marked(rawMarkup)

      rawMarkup = rawMarkup.replaceAll('\n', '<br>')

      //const sanitizedMarkup = DOMPurify.sanitize(rawMarkup)

      //editor.current.setContents(sanitizedMarkup)
      setEditorType('htmlEditor')

      editor.current.setContents(rawMarkup)
      setQText(rawMarkup)
      onBlur(rawMarkup)

      return { __html: rawMarkup }
    }
    return (
      <button onMouseDown={click} title="HTML Editor">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 256 256"
          class="external-icon-md"
        >
          <path
            fill="currentColor"
            d="M128 152a8 8 0 0 1-8 8h-8v48a8 8 0 0 1-16 0v-48h-8a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8m-64-8a8 8 0 0 0-8 8v20H40v-20a8 8 0 0 0-16 0v56a8 8 0 0 0 16 0v-20h16v20a8 8 0 0 0 16 0v-56a8 8 0 0 0-8-8m176 56h-12v-48a8 8 0 0 0-16 0v56a8 8 0 0 0 8 8h20a8 8 0 0 0 0-16m-45.86-55.71a8 8 0 0 0-9 3.59L168 176.45l-17.14-28.57A8 8 0 0 0 136 152v56a8 8 0 0 0 16 0v-27.12l9.14 15.24a8 8 0 0 0 13.72 0l9.14-15.24V208a8 8 0 0 0 16 0v-56a8 8 0 0 0-5.86-7.71M208 120a8 8 0 0 1-8-8V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88v24a8 8 0 0 1-8 8m-19.31-40L160 51.31V80Z"
          />
        </svg>
      </button>
    )
  }
  const EditorViewerComp = () => {
    return (
      <button title="Markdown Editor" class="editor-viewer-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 16 16"
          class="external-icon-md"
        >
          <g fill="black">
            <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
            <path
              fill-rule="evenodd"
              d="M9.146 8.146a.5.5 0 0 1 .708 0L11.5 9.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708"
            />
            <path
              fill-rule="evenodd"
              d="M11.5 5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5"
            />
            <path d="M3.56 11V7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11z" />
          </g>
        </svg>
      </button>
    )
  }

  const editorChanger = {
    name: 'Html Editor',
    keyCommand: 'Html Editor',
    value: 'Html Editor',

    icon: <ChangeEditor />,
  }
  const editorViewer = {
    name: '',
    keyCommand: 'Html Editor',
    value: 'Html Editor',
    icon: <EditorViewerComp />,
  }

  const isCompletion = isAnswerItemTypeResultCard(
    entity[QuestionFieldNames.QUESTION_TYPE],
  )

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item className={classes.grid}>
        <Card className={classes.childGrid}>
          <Paper
            id={'main_' + `${entity[QuestionFieldNames.ID]}`}
            className={classes.paper}
            onClick={() => onClick(editor.current)}
          >
            <div style={{ width: '100%' }}>
              <BottomBar
                editor={editor.current}
                entity={entity}
                dispatch={dispatch}
                courseId={courseId}
                onDrag={onDrag}
                cardCount={cardCount}
                baseEntity={baseEntity}
                setPage={setPage}
                index={index}
                lastCardFinish={lastCardFinish}
                addTextToTextArea={addTextToTextArea}
                page={page}
                onBlur={onBlur}
                onChange={onChange}
                imageURL={imageURL}
                setImageURL={setImageURL}
                onOk={onOk}
                onRemove={onRemove}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                spanRef={spanRef}
                widgetParent={widgetParent}
                onDelete={onDelete}
                updatedMarkdownText={updatedMarkdownText}
                editorType={editorType}
                onChangeMarkDown={onChangeMarkDown}
              />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <div className={classes.contentDiv}>
              {!isCompletion && (
                <CardTitle entity={entity} dispatch={dispatch} />
              )}
              {entity[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.BADGE ? (
                <></>
              ) : entity[QuestionFieldNames.QUESTION_TYPE] !=
                AnswerItemType.RESULT ? (
                <>
                  <Media
                    dispatch={dispatch}
                    entity={entity}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    onOk={onOk}
                    onRemove={onRemove}
                  />
                  <Box m={1.5} />
                  {editorType === 'markdownEditor' && (
                    <div
                      style={{
                        border: '1px solid #dadada',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <MDEditor
                        id={'markdownEditor' + entity[QuestionFieldNames.ID]}
                        key={'editor' + entity[QuestionFieldNames.ID]}
                        value={updatedMarkdownText}
                        ref={editorRef}
                        onChange={onChangeMarkDown}
                        onBlur={event =>
                          editorType == 'markdownEditor' &&
                          onBlur(event.target.value)
                        }
                        preview={'edit'}
                        height={'unset'}
                        style={{
                          borderRadius: 8,
                          fontSize: '16px',
                        }}
                        extraCommands={[editorViewer, editorChanger]}
                        data-color-mode="light"
                        commands={[
                          commands.group([commands.title1, commands.title2], {
                            name: 'title',
                            groupName: 'title',
                            title: 'Title',
                            buttonProps: {
                              'aria-label': 'Insert title',
                              title: 'Insert title',
                            },
                            icon: (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill="black"
                                  d="M.75 2a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 1.5 0V3.5h2.75v9h-.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-.5v-9H8.5v.75a.75.75 0 0 0 1.5 0v-1.5A.75.75 0 0 0 9.25 2zM8 7.75A.75.75 0 0 1 8.75 7h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 8 7.75m0 3.5a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1-.75-.75"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ),
                          }),
                          commands.bold,
                          commands.italic,
                          commands.strikethrough,
                          addImage,
                          commands.link,
                        ]}
                        previewOptions={{
                          rehypePlugins: [[rehypeSanitize]],
                        }}
                        minHeight={
                          QuestionEditorHeights[
                            entity[QuestionFieldNames.QUESTION_TYPE]
                          ]
                            ? isMobile
                              ? QuestionEditorHeights[
                                  entity[QuestionFieldNames.QUESTION_TYPE]
                                ].mobile
                              : QuestionEditorHeights[
                                  entity[QuestionFieldNames.QUESTION_TYPE]
                                ].desktop
                            : 80
                        }
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: editorType == 'htmlEditor' ? 'block' : 'none',
                    }}
                  >
                    <SunEditor
                      getSunEditorInstance={getSunEditorInstance}
                      key={'editor' + entity[QuestionFieldNames.ID]}
                      defaultValue={entity[QuestionFieldNames.QTEXT]}
                      onChange={onChange}
                      setContents={updatedQText}
                      onBlur={(event, editorContents) => {
                        onBlur(editorContents)
                      }}
                      toggleCodeView={toggleCodeView}
                      className={classes.textArea}
                      setDefaultStyle={'text-align: left'}
                      onImageUploadBefore={(files, info, uploadHandler) => {
                        dispatch(
                          mediaImageUploadAction(files[0], '', result => {
                            const response = {
                              result: [
                                {
                                  url: `${URL.MEDIA}${result.url}`,
                                  name: result.name,
                                  size: 100,
                                },
                              ],
                            }

                            uploadHandler(response)
                          }),
                        )
                      }}
                      setOptions={{
                        key: 'editorOptions' + entity[QuestionFieldNames.ID],
                        mode: 'classic',
                        formats: ['h1', 'h2', 'p'],
                        placeholder:
                          QuestionPlaceholders[
                            entity[QuestionFieldNames.QUESTION_TYPE]
                          ] ||
                          translator._(
                            'taskItem.addQuestion.main.textFieldPlaceholder',
                          ),
                        height: 'unset',
                        marginTop: '12px',
                        minHeight: QuestionEditorHeights[
                          entity[QuestionFieldNames.QUESTION_TYPE]
                        ]
                          ? isMobile
                            ? QuestionEditorHeights[
                                entity[QuestionFieldNames.QUESTION_TYPE]
                              ].mobile
                            : QuestionEditorHeights[
                                entity[QuestionFieldNames.QUESTION_TYPE]
                              ].desktop
                          : 80,

                        addTagsWhitelist: 'file-embed|google-maps|div',
                        resizingBar: false,
                        buttonList: [
                          [
                            ':t-Format Text-default.more_text',
                            'formatBlock',
                            'bold',
                            'italic',
                            'underline',
                            'fontColor',
                            'hiliteColor',
                          ],
                          ['align'],
                          ['list'],
                          ['image'],
                          ['link'],
                          ['removeFormat'],
                          ['codeView'],
                          ['-right', 'customCommand2'],
                          ['-right', 'customCommand'],

                          /*  [
                            ':i-More Misc-default.more_vertical',
                            'link',
                            'removeFormat',
                          ], */
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        // plugins: [font] set plugins, all plugins are set by default
                        // Other option
                        pasteTagsWhitelist: 'p|h1|h2|h3',
                        popupDisplay: 'local',
                        plugins: [
                          blockquote,
                          align,
                          font,
                          fontSize,
                          fontColor,
                          hiliteColor,
                          horizontalRule,
                          list,
                          table,
                          formatBlock,
                          lineHeight,
                          template,
                          paragraphStyle,
                          textStyle,
                          link,
                          image,
                          audio,
                          math,
                          imageGallery,
                          plugin_command2,
                          plugin_command,
                        ],
                        attributesWhitelist: {
                          all:
                            'dir|class|width|height|title|allow|allowfullscreen|aspectratio|id|frameborder|iframe|className|data-.+',
                        },
                        stickyToolbar:"65px"
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Media
                    dispatch={dispatch}
                    entity={entity}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    onOk={onOk}
                    onRemove={onRemove}
                  />
                </>
              )}

              <Box m={1} />
              {AnswerTypeFactory({
                type: entity[QuestionFieldNames.QUESTION_TYPE],
                dispatch,
                entity,
              })}
              {saving && (
                <div className={classes.saving}>
                  <CircularProgress color="inherit" size={16} />
                  <span>Saving content...</span>
                </div>
              )}
            </div>
          </Paper>
        </Card>

        <Divider>
          <div className={classes.addCard}>
            <Tooltip title="Add Card">
              <IconButton
                className={classes.iconButtonAdd}
                aria-label="add card"
                onClick={handleClick}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Divider>
      </Grid>
    </Grid>
  )
}

QuestionMain.propTypes = propTypes

export default withStyles(styles)(QuestionMain)
