import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { processStartEnd } from 'src/reducers/process/action'
import TasksStep from './tasks-step/TasksStep'
import styles from './styles'
import {
  AssignmentFieldNames,
  AssignmentQuestionSendTypes,
} from '../../model/assignment'
import { QuestionFieldNames, defaultQTexts } from '../../model/question'
import {
  assignmentUpdateAttribute,
  assignmentGetAction,
  assignmentEditAction,
  assignmentRemoveQuestion,
} from '../../reducers/assignment/action'
import { withStyles, Grid } from '@material-ui/core'
import CourseLeftSide from './course-left-side'
import ButtonBar from './button-bar'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import translator from 'src/translate'

const CreateMiniCourse = props => {
  const { classes, dispatch, entity, list, match, history } = props

  const { params } = match

  const [pageURL, setURL] = useState(window.location.href)
  const [shareLinkModalOpen, setShareLinkModalOpen] = React.useState(false)
  const [shareEmbedModalOpen, setShareEmbedModalOpen] = React.useState(false)

  const [settingsDrawerOpen, setSettingDrawerOpen] = React.useState(false)

  const [themeDrawerOpen, setThemeDrawerOpen] = React.useState(false)
  const [analyticsDrawerOpen, setAnalyticsDrawerOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [listingType, setListingType] = useState('')

  const [page, setPageState] = React.useState(null)
  const [questionSendOrder, setQuestionSendOrder] = useState(
    AssignmentQuestionSendTypes.ORDERED,
  )

  //const prevEntity = useRef()
  useEffect(() => {
    if (params && params.id) {
      dispatch(assignmentGetAction(params.id, data => {}))
    } else {
      dispatch(processStartEnd())
    }
  }, [])

  useEffect(() => {}, [page])

  const setPage = (newPage, scroll) => {
    if (newPage == null) {
      return
    }

    if (scroll != false) {
      const element = document.getElementById('card-' + newPage)

      if (element) {
        let position = element.getBoundingClientRect()
        // scrolls to 20px above element
        window.scrollTo({
          left: position.left,
          top: position.top + window.scrollY - 90,
          behavior: 'smooth',
        })
      }
    }

    setPageState(newPage)
  }

  const submitEntity = newEntity => {
    dispatch(assignmentEditAction(newEntity, () => {}))
  }

  const onDrag = async (oldIndex, newIndex, destinationSectionId) => {
    const _entity = entity

    const list = _entity
      ? _entity[AssignmentFieldNames.QUESTION_DTOS]
        ? _entity[AssignmentFieldNames.QUESTION_DTOS]
        : []
      : []

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      startIndex.forEach((element, index) => {
        const [removed] = result.splice(element, 1)

        if (
          destinationSectionId[index] != null &&
          destinationSectionId[index] != 0
        ) {
          removed.section = { id: destinationSectionId[index] }
        }
        result.splice(endIndex[index], 0, removed)
      })

      return result
    }

    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })

      let newEntity = {
        ..._entity,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }

      submitEntity(newEntity)

      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }

  const questionAdd = (list, callback, sortOrder) => {
    setListingType('question_add')
    const entityDtos = entity[AssignmentFieldNames.QUESTION_DTOS]
    const hasFinishCard =
      entityDtos[entityDtos.length - 1][QuestionFieldNames.QUESTION_TYPE] >=
        12 &&
      entityDtos[entityDtos.length - 1][QuestionFieldNames.QUESTION_TYPE] <= 14

    let letNewRelationList = list.map((x, index) => {
      return {
        courseId: entity[AssignmentFieldNames.ID],
        cardId: x.id,
        orderOfCard: entity[AssignmentFieldNames.QUESTION_DTOS].length + 1,
      }
    })

    let relationList = [
      ...entity[AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS],
      ...letNewRelationList,
    ]

    const type = list[0][QuestionFieldNames.QUESTION_TYPE]
    if (defaultQTexts[type]) {
      list[0][QuestionFieldNames.QTEXT] = defaultQTexts[type]
    }

    let questionList = [...entity[AssignmentFieldNames.QUESTION_DTOS], ...list]

    let newEntity = {
      ...entity,
      [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: relationList,
      [AssignmentFieldNames.QUESTION_DTOS]: questionList,
    }

    //submitEntity(newEntity)

    dispatch(
      assignmentUpdateAttribute(
        [
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: relationList,
          },
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: questionList,
          },
        ],
        () => {
          setTimeout(function () {
            if (sortOrder == undefined) {
              sortOrder = questionList.length - 1
            }

            setPage(
              questionList.find(
                x => x[QuestionFieldNames.SORT_ORDER] == sortOrder,
              ).id,
            )
          }, 2000)

          if (callback) {
            callback(newEntity, sortOrder)
          } else if (hasFinishCard) {
            onDrag(
              newEntity[AssignmentFieldNames.QUESTION_DTOS].length - 1,
              newEntity[AssignmentFieldNames.QUESTION_DTOS].length - 2,
              newEntity,
            )
          }
        },
      ),
    )
  }

  const sortCard = (newEntity, sortOrder) => {
    const cardCount = entity[AssignmentFieldNames.QUESTION_DTOS].length
    reOrderToNewAdded(cardCount, sortOrder, newEntity)
  }

  const reOrderToNewAdded = (oldIndex, newIndex, courseEntity) => {
    const list = courseEntity[AssignmentFieldNames.QUESTION_DTOS]

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    if (newIndex !== null) {
      let items = reorder(list, oldIndex, newIndex)

      // TODO: autosenderId için daha temiz ve garanti bir yol olmalı
      // TODO: backendde de autosenderId check edilmeli
      let letNewRelationList = items.map((x, index) => {
        return {
          courseId: window.location.pathname.split('/')[2],
          cardId: x.id,
          orderOfCard: index,
        }
      })

      items.forEach((value, index) => {
        items[index].orderOfCard = index
      })
      let lastEntity = {
        ...courseEntity,
        [AssignmentFieldNames.QUESTION_DTOS]: items,
        [AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS]: letNewRelationList,
      }

      dispatch(assignmentEditAction(lastEntity, () => {}))

      dispatch(
        assignmentUpdateAttribute([
          {
            path: AssignmentFieldNames.QUESTION_DTOS,
            value: items,
          },
          {
            path: AssignmentFieldNames.AUTO_SENDER_QUESTION_RELATION_DTOS,
            value: letNewRelationList,
          },
        ]),
      )
    }
  }

  const onDeleteClick = (id, index) => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          'Confirmation',
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.remove'),
              ButtonActionType.DELETE,
              () => {
                dispatch(
                  assignmentRemoveQuestion(
                    id,
                    window.location.pathname.split('/')[2],
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                )
              },
              'error',
            ),
          ],
          translator._('assignment.areYouSureQuestion'),
        ),
      ),
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className={classes.container}>
      <Grid container spacing={2} style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          {entity && (
            <CourseLeftSide
              onAdd={questionAdd}
              onDrag={onDrag}
              page={page}
              setPage={setPage}
              dispatch={dispatch}
              entity={entity}
              onDelete={onDeleteClick}
              sortCard={sortCard}
            />
          )}
        </Grid>

        <Grid
          item
          container
          spacing={0}
          direction="column"
          className={classes.questionViewContainer}
        >
          <Grid item alignSelf="end" className={classes.buttonBarGrid}>
            <ButtonBar
              entity={entity}
              settingsDrawerOpen={settingsDrawerOpen}
              setThemeDrawerOpen={setThemeDrawerOpen}
              themeDrawerOpen={themeDrawerOpen}
              analyticsDrawerOpen={analyticsDrawerOpen}
              shareLinkModalOpen={shareLinkModalOpen}
              shareEmbedModalOpen={shareEmbedModalOpen}
              setSettingDrawerOpen={setSettingDrawerOpen}
              setAnalyticsDrawerOpen={setAnalyticsDrawerOpen}
              setShareLinkModalOpen={setShareLinkModalOpen}
              setShareEmbedModalOpen={setShareEmbedModalOpen}
              dispatch={dispatch}
              history={history}
            />
          </Grid>
          <Grid item className={classes.questionListRoot} alignSelf="center">
            <TasksStep
              dispatch={dispatch}
              entity={entity}
              classes={classes}
              onAdd={questionAdd}
              onDrag={onDrag}
              sortCard={sortCard}
              radioValue={questionSendOrder}
              setRadioValue={setQuestionSendOrder}
              submitEntity={submitEntity}
              page={page}
              setPage={setPage}
              setThemeDrawerOpen={setThemeDrawerOpen}
              listingType={listingType}
              onDelete={onDeleteClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = response => ({
  entity: response.assignment.entity,
  query: response.assignment.query,
  list: response.assignment.list,
})

export default connect(mapStateToProps)(withStyles(styles)(CreateMiniCourse))
