import {
  SEND_CONFIRMATION_EMAIL_SUCCESS,
  CONFIRM_CODE_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_ROOTIE_SUCCESS,
  APPSUMO_SIGN_UP_SUCCESS,
} from './type'
import { set as setCookie } from 'es-cookie'

export default function (state = {}, action) {
  const { payload } = action

  switch (action.type) {
    case SEND_CONFIRMATION_EMAIL_SUCCESS:
      const { email } = payload
      setCookie('email', email.email, { sameSite: 'none', secure: true })

      window.open('/checkEmail', '_top')
      return { ...state, email }
    case CONFIRM_CODE_SUCCESS:
      const { data } = payload
      setCookie('confirmCode', data.data, { sameSite: 'none', secure: true })

      window.location.href = '/signup/' + data.data

      return { ...state, data }
    case SIGN_UP_SUCCESS:
      const { entity } = payload
      setCookie('token', entity.accessToken, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })
      setCookie('refreshToken', entity.refreshToken, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })
      setCookie('userId', entity.userId, {
        sameSite: 'none',
        secure: true,
        expires: 365,
      })

      window.location.href = '/purpose'

      return { ...state, entity }
    case SIGN_UP_ROOTIE_SUCCESS:
      setCookie('token', payload.entity.token, {
        sameSite: 'none',
        secure: true,
      })

      window.location.href = '/'

      return { ...state, entity }
    default:
      return state

    case APPSUMO_SIGN_UP_SUCCESS:
      setCookie('token', payload.entity.accessToken, {
        sameSite: 'none',
        secure: true,
      })

      setCookie('refreshToken', payload.entity.refreshToken, {
        sameSite: 'none',
        secure: true,
      })
      setCookie('userId', payload.entity.userId, {
        sameSite: 'none',
        secure: true,
      })
      window.location.href = '/'

      return { ...state, entity }
  }
}
