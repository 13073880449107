import React from 'react'

import { withStyles, Button, Tooltip } from '@material-ui/core'
import PlagiarismIcon from '@material-ui/icons/Plagiarism'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpIcon from '@material-ui/icons/HelpOutline'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { Icon } from '@iconify/react'

import styles from './style'

import propTypes from './prop'

import translator from 'src/translate'

import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { HELPER_URL, STRIPE_DASHBOARD } from 'src/model/payment'

import ModalContent from 'src/components/card-item/bottom-bar/button-actions/stripe-payment'
import { guid } from 'src/utils/guid'

const IconsInform = props => {
  const { classes, entity, dispatch, location } = props

  const settingsModalClickHandler = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <ModalContent {...props} entityId={entity.id} key={guid.uid} />,
          {
            hideCloseButton: false,
            //header: 'Stripe Integration',
            callback: e => {},
          },
        ),
      ),
    )
  }

  return (
    <>
      <Tooltip
        title={translator._('taskItem.payment.settings')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => settingsModalClickHandler()}
        >
          <Icon
            icon="ion:settings-outline"
            width="18"
            height="19px"
            className={classes.icon}
          />
        </Button>
      </Tooltip>
      <Tooltip title={translator._('action.help')} placement="bottom">
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => {
            const newWindow = window.open(
              HELPER_URL,
              '_blank',
              'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
          }}
        >
          <HelpIcon className={classes.icon} fontSize="small" />
        </Button>
      </Tooltip>
      <Tooltip
        title={translator._('taskItem.payment.report')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => {
            const newWindow = window.open(
              STRIPE_DASHBOARD,
              '_blank',
              'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
          }}
        >
          <Icon
            icon="material-symbols:receipt-outline"
            width="256"
            height="256"
            className={classes.icon}
          />
        </Button>
      </Tooltip>
      {/*  <Tooltip title={translator._('taskItem.payment.report')} placement="bottom">
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => { }}
        >
          <PlagiarismIcon className={classes.icon} fontSize="small" />
        </Button>
      </Tooltip> */}
    </>
  )
}

IconsInform.propTypes = propTypes

export default withStyles(styles)(IconsInform)
