const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '50%',
    padding: '30px',
    borderRadius: '16px',
    justifyContent: 'space-between',
  },
  root__img: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zapierButton: {
    width: '100%',
    height: 40,
    backgroundColor: '#FF4F00',
    boxShadow: 'none',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#FF4F00',
    },
  },
  makeButton: {
    boxShadow: 'none',
    width: '100%',
    height: 40,
    background:
      'linear-gradient(90deg, rgba(247,6,253,1) 0%, rgba(158,36,223,1) 50%, rgba(113,6,206,1) 100%)',
    borderRadius: 12,
    '&:hover': {
      backgroundColor:
        'linear-gradient(90deg, rgba(247,6,253,1) 0%, rgba(158,36,223,1) 50%, rgba(113,6,206,1) 100%)',
    },
  },
  view__root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F4F6F8',
    width: '60%',
    borderRadius: '16px',
    padding: '40px',
    gap: 16,
  },
  view__root_img: {
    width: '120px',
  },
})

export default styles
