import React from 'react'

import { Typography } from '@material-ui/core'
const UserOwnEmptyList = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      <img src="assets/Certificate_Templates.png" width="160px" />
      <Typography fontSize={'16px'} fontWeight={400}>
        You don't have any certificate designs saved yet. <br />
        Let's create a certificate.
      </Typography>
    </div>
  )
}

export default UserOwnEmptyList
