import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import styles from './styles'
import LogoView from './out-login'
import data from './data'
const BuildAutomations = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#F4F6F8',
        width: '40%',
        borderRadius: '16px',
        padding: '40px',
        gap: 16,
      }}
    >
      <Typography fontSize={'15px'} fontWeight={500}>
        Start building automations in your Zapier and Make accounts.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 16,
          height: '100%',
        }}
      >
        {data.map((item, index) => (
          <LogoView
            key={index}
            logo={item.logo}
            productName={item.productName}
            buttonClass={item.buttonClass}
            buttonUrl={item.buttonUrl}
            logoHeight={item.logoHeight}
          />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(BuildAutomations)
