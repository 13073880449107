import React, { useState, useEffect } from 'react'
import translator from 'src/translate'
import { MButton } from 'src/theme'
import styles from './styles'
import StarIcon from '@material-ui/icons/Star'
import CodeIcon from '@material-ui/icons/Code'
import PhonelinkIcon from '@material-ui/icons/Phonelink'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { domainListAction } from 'src/reducers/domain/action'
import { AssignmentFieldNames } from 'src/model/assignment'
import { Icon } from '@iconify/react'
import {
  Card,
  Typography,
  TextField,
  withStyles,
  Grid,
  Button,
  Tab,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
  Divider,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import URL from 'src/api/url'
import { Link } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'

const ShareLinkStep = props => {
  const { handleBack, dispatch, classes, entity } = props
  const [showShareButtons, setShowShareButtons] = useState(0)
  const [domains, setDomains] = useState()
  const [domainName, setDomainName] = useState()
  useEffect(() => {
    const query = {
      CurrentPage: 0,
      PageSize: 5,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }
    track('Course_Share', {
      type: 'Link',
    })
    dispatch(
      domainListAction({ ...query, SortingColumn: 'LastModifiedAt' }, data => {
        setDomains(data.results)
        data.results.filter(x => x.verificationStatus == 1)[0].domain
          ? setDomainName(
              data.results.filter(x => x.verificationStatus == 1)[0].domain,
            )
          : setDomainName('share.minicoursegenerator.com')
      }),
    )
  }, [])

  /**
   *
   * @param {*} e
   */
  const handleDomain = e => {
    setDomainName(e.target.value)
  }

  const domain = entity.customDomainDtos
    ? entity.customDomainDtos.length == 0 ||
      entity.customDomainDtos[0].verificationStatus != 1
      ? 'https://share.minicoursegenerator.com/'
      : 'https://' + entity.customDomainDtos[0].domain + '/'
    : 'https://share.minicoursegenerator.com/'

  let shareCode = showShareButtons ? '?shr=1' : ''

  const hasVerifiedCourse =
    domains && domains.find(x => x.verificationStatus == 1)
  return entity ? (
    <Card className={classes.settingsContainer}>
      <div className={classes.headerLine}>
        <div className={classes.header}>
          <Typography variant="h6">Share Link</Typography>
          <Typography variant="body2">Get link to share</Typography>
        </div>
        {handleBack && (
          <MButton
            variant="outlined"
            color="inherit"
            className={classes.prevButton}
            onClick={() => handleBack()}
          >
            {translator._('general.back')}
          </MButton>
        )}
      </div>

      <div>
        <TabContext>
          <TabPanel>
            <div className={classes.settings}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showShareButtons}
                    onChange={(e, checked) => {
                      setShowShareButtons(checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translator._('assignment.showShareButtonsLabel')}
              />
            </div>
            {hasVerifiedCourse && (
              <div>
                <FormControl
                  style={{ width: '300px' }}
                  variant="outlined"
                  className={classes.listGroup}
                >
                  <Select
                    className={classes.select}
                    inputProps={{
                      className: classes.inputField,
                    }}
                    fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Choose your domain"
                    value={domainName}
                    key={domainName}
                    disabled={false}
                    input={<Input id="select-input" name="select-input" />}
                    onChange={handleDomain}
                  >
                    <MenuItem value={'share.minicoursegenerator.com'}>
                      share.minicoursegenerator.com
                    </MenuItem>
                    {domains &&
                      domains
                        .filter(x => x.verificationStatus == 1)
                        .map(value => {
                          return (
                            <MenuItem value={value.domain} key={value.domain}>
                              {value.domain}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className={classes.textFields}>
              <Grid container spacing={2}>
                <Grid
                  style={{ display: 'flex', flexDirection: 'column' }}
                  item
                  xs={12}
                  md={12}
                >
                  <TextField
                    fullWidth
                    value={
                      hasVerifiedCourse
                        ? 'https://' +
                          domainName +
                          '/' +
                          entity[AssignmentFieldNames.CODE] +
                          shareCode
                        : domain + entity[AssignmentFieldNames.CODE] + shareCode
                    }
                    className={classes.textField}
                  />

                  <CopyToClipboard
                    text={
                      hasVerifiedCourse
                        ? 'https://' +
                          domainName +
                          '/' +
                          entity[AssignmentFieldNames.CODE] +
                          shareCode
                        : domain + entity[AssignmentFieldNames.CODE] + shareCode
                    }
                    className={classes.copy}
                  >
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '015FFB', width: '140px' }}
                    >
                      {translator._('assignment.copy')}
                    </Button>
                  </CopyToClipboard>
                  <div style={{ flexGrow: 1 }}></div>
                  <Divider />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '15px',
                    }}
                  >
                    <Typography>
                      Want to customize the domain of the links?{' '}
                    </Typography>
                    <a href="/custom-domain" style={{ color: '#015FFB' }}>
                      <Icon icon="ph:link-bold" color="#015FFB" />
                      Add custom domain
                    </a>
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </Card>
  ) : null
}

export default withStyles(styles)(ShareLinkStep)
