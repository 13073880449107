const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
    width: '260px',
  },
  root_close: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
    width: '260px',
  },
  menuButton: {
    width: '40px',
    height: '40px',
    margin: 0,
  },
  collectionsHeader: {
    fontSize: '16px',
    color: '#002E47',
    fontWeight: 400,
    paddingRight: '12px',
  },

  menuHeaderOpen: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
  },
  menuHeader: {
    display: 'none',
  },
  menuButtonMobile: {
    display: 'none',
  },
})

export default styles
