import React from 'react'
import {
  CourseView,
  LearnerAccess,
  CustomDomainCollectionView,
  OverView,
  Certification,
  Analytics,
} from './components'
import { useSelector } from 'react-redux'
const PageFactory = ({
  containers,
  setContainers,
  draggedCourseId,
  setDraggedCourseId,
  hoveredCollectionId,
  setHoveredCollectionId,
  open,
  setOpen,
}) => {
  const selectedPage = useSelector(
    state => state.courseCollectionList.selectedPageView,
  )
  switch (selectedPage) {
    case 'Mini Courses':
      return (
        <CourseView
          containers={containers}
          setContainers={setContainers}
          draggedCourseId={draggedCourseId}
          setDraggedCourseId={setDraggedCourseId}
          setHoveredCollectionId={setHoveredCollectionId}
          hoveredCollectionId={hoveredCollectionId}
          open={open}
          setOpen={setOpen}
        />
      )
    case "Learners' Access":
      return <LearnerAccess />
    case 'Custom Domain':
      return <CustomDomainCollectionView />
    case 'Overview':
      return <OverView />
    case 'Certification':
      return <Certification />
    case 'Analytics':
      return <Analytics containers={containers} />
    default:
      return (
        <CourseView
          containers={containers}
          setContainers={setContainers}
          draggedCourseId={draggedCourseId}
          setDraggedCourseId={setDraggedCourseId}
          setHoveredCollectionId={setHoveredCollectionId}
          hoveredCollectionId={hoveredCollectionId}
        />
      )
  }
}

export default PageFactory
