const data = [
  {
    title: 'Collection Name',
    info: '{collection.name}',
    buttonText: 'Copy',
  },
  {
    title: 'Recipient Name',
    info: '{recipient.name}',
    buttonText: 'Copy',
  },
  {
    title: 'Certificate ID',
    info: '{certificate.id}',
    buttonText: 'Copy',
  },
  {
    title: 'Issue Date',
    info: '{certificate.issued_on}',
    buttonText: 'Copy',
  },
]

export default data
