import React, { useState, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
} from '@material-ui/core'
import translator from 'src/translate'

import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import Media from '../../../media'

import URL from 'src/api/url'

import DeleteIcon from '@material-ui/icons/Delete'

import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import { usePatchSettings } from 'src/hooks/useCardSettings'

const CONDITION_TYPES = ['Correct Answer', 'Wrong Answer', 'Total']
const CONDITIONS = ['>=', '<=', '>', '<', '==']

const ConditionMessages = {
  '>=': 'The value must be greater than or equal to',
  '<=': 'The value must be less than or equal to',
  '>': 'The value must be greater than',
  '<': 'The value must be less than',
  '==': 'The value must be equal to',
}

const ConditionTypeMessages = {
  'Correct Answer': '',
  'Wrong Answer': '',
  Total: 'Correct_Answer + Survey_Answer + Info_View',
}

const has = numeric => {
  if (numeric == 0) return true

  return numeric ? true : false
}

const ModalContent = props => {
  const {
    classes,
    conditions,
    conditionId,
    actionType,
    saveCondition,
    dispatch,
  } = props

  const [conditionType, setConditionType] = useState(
    has(conditionId) ? conditions[conditionId].type : CONDITION_TYPES[0],
  )
  const [condition, setCondition] = useState(
    has(conditionId) ? conditions[conditionId].condition : CONDITIONS[0],
  )
  const [conditionValue, setConditionValue] = useState(
    has(conditionId) ? conditions[conditionId].value : '',
  )

  const [htmlContent, setHtmlContent] = useState(
    has(conditionId) ? conditions[conditionId].htmlContent : '',
  )

  const prepareSaveCondition = () => {
    //if (typeof conditionValue != "number") return;

    if (actionType != 'add') {
      conditions[conditionId] = {
        ...conditions[conditionId],
        type: conditionType,
        condition: condition,
        value: conditionValue,

        htmlContent: htmlContent,
      }
    } else {
      conditions.push({
        type: conditionType,
        condition: condition,
        value: conditionValue,

        htmlContent: htmlContent,
      })
    }
    return saveCondition(conditions)
  }

  const prepareDeleteCondition = () => {
    conditions.splice(conditionId, 1)
    return saveCondition(conditions)
  }

  return (
    <FormControl className={classes.form}>
      <div className={classes.box}>
        <Select
          label="Condition"
          defaultValue={
            has(conditionId) ? conditions[conditionId].type : CONDITION_TYPES[0]
          }
          onChange={e => setConditionType(e.target.value)}
        >
          {CONDITION_TYPES.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        <Select
          label=""
          className={classes.conditionSelect}
          defaultValue={
            has(conditionId) ? conditions[conditionId].condition : CONDITIONS[0]
          }
          onChange={e => setCondition(e.target.value)}
        >
          {CONDITIONS.map(condition => (
            <MenuItem value={condition}>{condition}</MenuItem>
          ))}
        </Select>
        <TextField
          label="Value"
          type="number"
          defaultValue={has(conditionId) ? conditions[conditionId].value : ''}
          onChange={e => setConditionValue(e.target.value)}
        />
        <div className={classes.conditionMessage}>
          {ConditionTypeMessages[conditionType]}
        </div>
      </div>

      <div>
        <span>Customize your message:</span>

        <div className={classes.editForm}>
          <SunEditor
            key={'editor'}
            defaultValue={
              has(conditionId) ? conditions[conditionId].htmlContent : ''
            }
            onBlur={(event, editorContents) => setHtmlContent(editorContents)}
            className={classes.textArea}
            setDefaultStyle="text-align: left;"
            onImageUploadBefore={(files, info, uploadHandler) => {
              dispatch(
                mediaImageUploadAction(files[0], '', result => {
                  const response = {
                    result: [
                      {
                        url: `${URL.MEDIA}${result.url}`,
                        name: result.name,
                        size: 100,
                      },
                    ],
                  }

                  uploadHandler(response)
                }),
              )
            }}
            setOptions={{
              mode: 'inline',
              formats: ['h1', 'h2', 'p'],
              placeholder:
                'This content will appear under the condition you define.',
              height: 200,
              buttonList: [
                [],
                ['formatBlock'],
                [],
                ['bold'],
                [],
                ['image'],
                [],
                ['codeView'],
                [],
                [
                  ':i-More Misc-default.more_vertical',
                  'fontColor',
                  'hiliteColor',
                  'align',
                  'link',
                  'italic',
                  'underline',
                  'removeFormat',
                ],
              ],
              pasteTagsWhitelist: 'p|h1|h2|h3',
              imageMultipleFile: false,
              imageUrlInput: false,
              imageAlignShow: false,
              imageHeightShow: false,
              imageResizing: false,
              popupDisplay: 'local',
            }}
          />
        </div>
      </div>

      <div className={classes.buttonGroup}>
        <Button fullWidth variant="contained" onClick={prepareSaveCondition}>
          {actionType == 'add' ? 'Create Condition' : 'Save'}
        </Button>
        {actionType != 'add' && (
          <IconButton aria-label="delete" onClick={prepareDeleteCondition}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </FormControl>
  )
}

ModalContent.propTypes = propTypes

export default withStyles(styles)(ModalContent)
