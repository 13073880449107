const modalContentStyles = theme => ({
  modalContent: {
    height: '437px',
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
    borderRadius: '16px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    '&:focus': {
      outline: 'none!important',
    },
  },
  premiumBadges: {
    display: 'flex',
    height: '20px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notNowButton: {
    color: '#637381',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '4px',
    width: '90px',
    height: '40px',
    '&:hover': {
      textTransform: 'underline',
    },
  },
  upgradeButton: {
    width: '100%',
    backgroundColor: '#015FFB',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '4px',
    height: '40px',
  },
  iconSmall: {
    width: '16px',
    height: '16px',
  },
  typographyMain: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#002E47',
    width: '100%',
  },
  typographySub: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
  },
  typographyNote: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#002E47',
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    width: '400px',
    gap: '16px',
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: '100%',
  },
  badgeRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    alignItems: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  iconWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '6px',
  },
})

export default modalContentStyles
