const styles = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    backgroundColor: '#F8FAFC',
    padding: '20px',
    justifyContent: 'space-between',
    height: '150px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardName: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#637381',
  },
  cardNameCourse: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#637381',
  },
  icon: {
    marginTop: '5px',
    color: '#919EAB',
    fontSize: '16px',
    cursor: 'pointer', // Added cursor pointer on hover
    minWidth: '16px',
  },
  iconCourse: {
    marginTop: '5px',
    color: '#919EAB',
    fontSize: '13px',
    cursor: 'pointer', // Added cursor pointer on hover
    minWidth: '13px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    fontSize: '32px',
    fontWeight: '500',
    color: '#002E47',
  },
  valueCourse: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#002E47',
  },
  image: {
    width: '32px',
    height: '32px',
  },
  imageCourse: {
    width: '24px',
    height: '24px',
  },
})

export default styles
