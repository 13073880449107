import React from 'react'
import translator from '../translate'

import Shop from '../pages/shop'
import Profile from '../pages/profile'
import Assignment from 'src/pages/assignment'
import Pricing from 'src/pages/pricing'
import CreateMiniCourse from 'src/pages/create-mini-course'
import CustomDomain from 'src/pages/custom-domain'
import Roadmap from 'src/pages/roadmap'

import SendIcon from '@material-ui/icons/Dashboard'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Tune'
import ShopSettingIcon from '@material-ui/icons/Settings'

import CourseStatistics from 'src/pages/cockpit/course-statistics'
import CourseTimeSeries from 'src/pages/cockpit/course-time-series'
import Cockpit from 'src/pages/cockpit'
import UseCoupon from 'src/pages/use-coupon'
import SubscriptionSuccess from 'src/pages/subscription_success'
import SubscriptionFailed from 'src/pages/subscription_failed'
import Openai from 'src/pages/openai'
import Suggestions from 'src/pages/suggestions'
import HelpCenter from 'src/pages/help-center'
import RefundCoupon from 'src/pages/cockpit/appsumo-refund-coupon'
import UserDetailedList from 'src/pages/cockpit/user-detailed-list'
import Kanban from 'src/pages/list-courses'
import CreateFirstCourse from 'src/pages/create-first-course'
import CreateCourseWithAi from 'src/pages/create-course-with-ai'
import SelectTemplate from 'src/pages/select-template'
import MobileUI from 'src/pages/mobile-ui'
import CreateQuizWithAi from 'src/pages/create-quiz-with-ai'
import OtherProjects from 'src/pages/other-projects'
import Teams from 'src/pages/teams'
import TeamsMemberList from 'src/pages/teams-member-list'
import Automations from 'src/pages/automations'
import UseTemplate from 'src/pages/use-template'
import { isMobileOnly } from 'react-device-detect'
import MainPage from 'src/pages/main-page'
const menus = [
  {
    label: translator._('menu.settings'),
    icon: () => {
      return <SettingsIcon color="secondary"></SettingsIcon>
    },
    path: '*6',
    link: 'settings',
    pages: Shop,
    hidden: false,
    default: false,
    subMenus: [
      {
        label: translator._('menu.branding'),
        icon: () => {
          return <ShopSettingIcon color="secondary"></ShopSettingIcon>
        },
        path: 'settings/shop',
        link: '/settings/shop',
        pages: Shop,
        hidden: false,
        default: false,
      },

      {
        label: translator._('menu.profile'),
        icon: () => {
          return <SendIcon></SendIcon>
        },
        path: 'settings/profile',
        link: '/settings/profile',
        pages: Profile,
        hidden: true,
        default: false,
      },
    ],
  },

  {
    label: translator._('menu.createassignment'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/mini-course',
    link: '/mini-course/:id',
    pages: CreateMiniCourse,
    hidden: false,
    default: false,
  },

  {
    label: 'kanban',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/kanban',
    link: '/kanban',
    pages: isMobileOnly ? MobileUI : Assignment,
    hidden: false,
    default: true,
  },
  {
    label: 'Create Course',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/create-course',
    link: '/create-course',
    pages: isMobileOnly ? MobileUI : CreateFirstCourse,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.assignments'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/courses',
    link: '/courses',
    pages: isMobileOnly ? MobileUI : Assignment,
    hidden: false,
    default: false,
  },

  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/pricing',
    link: '/pricing',
    pages: Pricing,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.customdomain'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/custom-domain',
    link: '/custom-domain',
    pages: CustomDomain,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.roadmap'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/roadmap',
    link: '/roadmap',
    pages: Roadmap,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.courseStatistics'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/coursestatistics',
    link: '/coursestatistics',
    pages: CourseStatistics,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.courseTimeSeries'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/coursetimeseries',
    link: '/coursetimeseries',
    pages: CourseTimeSeries,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.cockpit'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/cockpit',
    link: '/cockpit',
    pages: Cockpit,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/use-coupon',
    link: '/use-coupon',
    pages: UseCoupon,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.openai'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/ai-assistant',
    link: '/ai-assistant',
    pages: Openai,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.subscriptionsucces'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/subscription-successful',
    link: '/subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/starter-subscription-success-old',
    link: '/starter-subscription-success-old',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/quarterly-subscription-successful',
    link: '/quarterly-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },

  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/annual-starter-subscription-successful',
    link: '/annual-starter-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/starter-subscription-successful',
    link: '/starter-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/annual-premium-subscription-successful',
    link: '/annual-premium-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/premium-subscription-successful',
    link: '/premium-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/annual-premium-plus-subscription-successful',
    link: '/annual-premium-plus-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/premium-plus-subscription-successful',
    link: '/premium-plus-subscription-successful',
    pages: SubscriptionSuccess,
    hidden: true,
    default: false,
  },

  {
    label: translator._('menu.pricing'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/subscription-failed',
    link: '/subscription-failed',
    pages: SubscriptionFailed,
    hidden: true,
    default: false,
  },
  {
    label: translator._('menu.suggestions'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/suggestions',
    link: '/suggestions',
    pages: Suggestions,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.helpCenter'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/help-center',
    link: '/help-center',
    pages: HelpCenter,
    hidden: false,
    default: false,
  },
  {
    label: translator._('menu.refundPage'),
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/code-refund',
    link: '/code-refund',
    pages: RefundCoupon,
    hidden: false,
    default: false,
  },
  {
    label: 'User Roles',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/user-roles',
    link: '/user-roles',
    pages: UserDetailedList,
    hidden: false,
    default: false,
  },
  {
    label: 'Create Course With AI',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/create-course-with-ai',
    link: '/create-course-with-ai',
    pages: CreateCourseWithAi,
    hidden: false,
    default: false,
  },
  {
    label: 'Select Template',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/select-template',
    link: '/select-template',
    pages: SelectTemplate,
    hidden: false,
    default: false,
  },
  {
    label: 'Mobile UI',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/mobile-ui',
    link: '/mobile-ui',
    pages: MobileUI,
    hidden: false,
    default: false,
  },
  {
    label: 'Create Quiz With AI',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/create-quiz-with-ai',
    link: '/create-quiz-with-ai',
    pages: CreateQuizWithAi,
    hidden: false,
    default: false,
  },
  {
    label: 'Other Products',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/other-products',
    link: '/other-products',
    pages: OtherProjects,
    hidden: false,
    default: false,
  },
  {
    label: 'Learners',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/learners',
    link: '/learners',
    pages: Teams,
    hidden: false,
    default: false,
  },
  {
    label: 'Learners Member List',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/learners-member-list',
    link: '/learners-member-list/:id',
    pages: TeamsMemberList,
    hidden: false,
    default: false,
  },
  {
    label: 'Automations',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/automations',
    link: '/automations',
    pages: Automations,
    hidden: false,
    default: false,
  },
  {
    label: 'Use Template',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/use-template/:courseCode*',
    link: '/use-template/:courseCode*',
    pages: UseTemplate,
    hidden: false,
    default: false,
  },

  {
    label: 'Mini Course Generator',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/old-collection-page',
    link: '/old-collection-page',
    pages: isMobileOnly ? MobileUI : Kanban,
    hidden: false,
    default: false,
  },
  {
    label: 'Mini Course Generator',
    icon: () => {
      return <DashboardIcon color="secondary"></DashboardIcon>
    },
    path: '/',
    link: '/',
    pages: isMobileOnly ? MobileUI : MainPage,
    hidden: false,
    default: false,
  },
]

export default menus
