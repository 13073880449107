const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 40,
  },
  root__boxView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    backgroundColor: '#F4F6F8',
    width: '100%',
    borderRadius: '16px',
    height: '100%',
  },
})

export default styles
