import React from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { isMobile } from 'react-device-detect'
import { drawerOpenAction } from 'src/reducers/course-collection/action'
import { useDispatch, useSelector } from 'react-redux'

const DrawerOpenButton = props => {
  const dispatch = useDispatch()
  const { classes, open, setOpen } = props

  const handleDrawer = () => {
    setOpen(!open)
  }
  return (
    <div
      className={clsx({
        [classes.root_close]: !open,
        [classes.root]: open,
      })}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '72px',
          justifyContent: 'center',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          className={clsx({
            [classes.menuButton]: !isMobile,
            [classes.menuButtonMobile]: isMobile,
          })}
        >
          <MenuIcon
            style={{
              color: '#637381',
            }}
          />
        </IconButton>
      </div>

      <div
        className={clsx({
          [classes.menuHeader]: !open,
          [classes.menuHeaderOpen]: open,
        })}
      >
        <Typography fontSize="16px" className={classes.collectionsHeader}>
          Collections
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(DrawerOpenButton)
