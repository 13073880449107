const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  input: {
    backgroundColor: '#fff',
  },
})

export default styles
