import { put, call, take, fork } from 'redux-saga/effects'
import { getBadgesService } from '../../api/badge'
//import { questionGetListSuccess } from '../../reducers/question/action'
import { GET_BADGES } from '../../reducers/question/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getBadgesFlow(payload) {
  const { callback } = payload
  try {
    const response = yield call(getBadgesService)

    yield put(operationSuccess())
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getBadgesWatcher() {
  while (true) {
    const action = yield take(GET_BADGES)
    const { payload } = action

    yield fork(generalSaga, getBadgesFlow, payload)
  }
}
