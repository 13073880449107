const styles = theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
  },
  centerWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '7px'
  }
})

export default styles
