const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typography: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '4px',
  },
  icon: {
    fontSize: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
