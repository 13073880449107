const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    padding: '16px 24px',
  },
  highlightItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    height: '52px',
    alignItems: 'center',
    padding: '12px 0px',
  },
  infoIcon: {
    fontSize: 24,
    color: '#919EAB',
  },
  infoText: {
    width: '600px',
    color: '#919EAB',
  },
  flexGrow: {
    flexGrow: 1,
  },
  button: {
    color: '#002E47',
    backgroundColor: '#fff',
  },
  fileInput: {
    display: 'none',
  },
})

export default styles
