const CREATE_CERTIFICATE = 'CREATE_CERTIFICATE'
const CREATE_CERTIFICATE_SUCCESS = 'CREATE_CERTIFICATE_SUCCESS'
const CREATE_CERTIFICATE_ERROR = 'CREATE_CERTIFICATE_ERROR'

const GET_CERTIFICATE = 'GET_CERTIFICATE'
const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS'
const GET_CERTIFICATE_ERROR = 'GET_CERTIFICATE_ERROR'

const PATCH_CERTIFICATE = 'PATCH_CERTIFICATE'
const PATCH_CERTIFICATE_SUCCESS = 'PATCH_CERTIFICATE_SUCCESS'
const PATCH_CERTIFICATE_ERROR = 'PATCH_CERTIFICATE_ERROR'

const GET_CERTIFICATE_LIST = 'GET_CERTIFICATE_LIST'
const GET_CERTIFICATE_LIST_SUCCESS = 'GET_CERTIFICATE_LIST_SUCCESS'
const GET_CERTIFICATE_LIST_ERROR = 'GET_CERTIFICATE_LIST_ERROR'

export {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_ERROR,
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_ERROR,
  PATCH_CERTIFICATE,
  PATCH_CERTIFICATE_SUCCESS,
  PATCH_CERTIFICATE_ERROR,
  GET_CERTIFICATE_LIST,
  GET_CERTIFICATE_LIST_SUCCESS,
  GET_CERTIFICATE_LIST_ERROR,
}
