import React from 'react'
import CustomDomain from 'src/components/custom-domain'
const CustomDomainCollectionView = () => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <CustomDomain />
    </div>
  )
}

export default CustomDomainCollectionView
