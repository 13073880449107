const styles = theme => ({

  switch: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
  },
  certicationLock: {
    backgroundColor: 'white',
    position: 'absolute',
    height: 1500,
    width: '100%',
    zIndex: 1000,
    opacity: 0.5,
    marginTop: 73,
  },
})

export default styles
