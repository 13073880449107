const style = theme => ({
  logo: {
    margin: '0 auto',
    height: 40,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
})

export default style
