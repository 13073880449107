import React from 'react'
import Html from 'react-pdf-html'
import URL from 'src/api/url'

import ReactPDF, { View, Image, Text } from '@react-pdf/renderer'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card } = props
  let srcUrl = ''

  if (
    card.mediaDtos &&
    card.mediaDtos.length > 0 &&
    card.mediaDtos[0].mediaType == 1 &&
    !card.mediaDtos[0].mediaUrl.includes('.gif')
  ) {
    
    if (
      card.mediaDtos[0].mediaUrl.includes('https') ||
      card.mediaDtos[0].mediaUrl.includes('data:image/png')
    ) {
      srcUrl = card.mediaDtos[0].mediaUrl
    } else {
      srcUrl = URL.CDN + card.mediaDtos[0].mediaUrl + '?not-from-cache-please2'
    }

  } else {
    srcUrl = ''
  }

  return (
    <View style={stylesheet.informView}>
      {card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('.gif') && (
          <Image style={stylesheet.contentImage} src={srcUrl}  />
        )}

      <Text style={stylesheet.informTitle}>{card.title}</Text>
      <Html stylesheet={stylesheet} style={style}>
        {editor && editor.getContents
          ? editor.getContents().replace('figure', 'p')
          : card.text.replace('figure', 'p')}
      </Html>
    </View>
  )
}

export default Inform
