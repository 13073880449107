/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, Modal, Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import { teamsMemberDeleteAction } from 'src/reducers/teams/action'
import { componentBoxHideAction } from 'src/reducers/component-box/action'

const DeleteMember = props => {
  const { entity, dispatch, classes } = props
  const teamsId = window.location.pathname.split('learners-member-list/')[1]

  const handleDeleteMember = () => {
    dispatch(
      teamsMemberDeleteAction(teamsId, entity.id, () => {
        dispatch(componentBoxHideAction())
      }),
    )
  }

  const handleCloseModal = () => {
    dispatch(componentBoxHideAction())
  }

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <div className={classes.modalContent__header}>
          <img style={{ marginRight: '10px' }} src="/assets/delete-icon.svg" />
          <Typography className={classes.modalContent__header__title}>
            Delete Learner
          </Typography>
        </div>

        <Typography className={classes.modalContent__header__text}>
          Are you sure you want to delete the learner?{' '}
        </Typography>

        <div className={classes.modalContent__buttons}>
          <Button
            variant="outlined"
            className={classes.modalContent__cancelButton}
            onClick={() => handleCloseModal()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.modalContent__deleteButton}
            onClick={() => handleDeleteMember()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Delete
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  )
}
export default withStyles(styles)(DeleteMember)
