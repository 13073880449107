import React, { useEffect } from 'react'
import styles from '../styles'
import {
  withStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Icon } from '@iconify/react' // Import Sun Editor's CSS File
import { TeamListItem } from '..'
import { useSelector } from 'react-redux'
import CustomizedSwitch from 'src/components/customized-switch'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
const MailPreparation = props => {
  const {
    entity,
    collectionSettings,
    dispatch,
    handleSendLinkToNewMembersOnly,
    sendLinkToNewMembersOnly,
    emailText,
    setEmailText,
    emailTitle,
    setEmailTitle,
    classes,
  } = props

  const [hasTeamsToView, setHasTeamsToView] = React.useState(false)

  const [teamListLength, setTeamListLength] = React.useState(0)
  const [memberListLength, setMemberListLength] = React.useState(0)
  const [selectedDomain, setSelectedDomain] = React.useState({
    id: '',
    domain: '',
  })
  const [domainList, setDomainList] = React.useState([])
  const onBlur = value => {
    if (collectionSettings.inviteEmailBody !== value) {
      const patchSource = new PatchData(null, entity.id)
      patchSource.addContent(
        new PatchContent(value, 'inviteEmailBody', PatchData.OPERATION_REPLACE),
      )

      dispatch(patchCollectionSettingsAction(patchSource, response => {}))
    }
  }

  const domainRawList = useSelector(state => state.domain.list.results)

  const domainListCreator = () => {
    const list = []

    domainRawList.map(domain => {
      if (
        domain.defaultCollectionDto &&
        domain.defaultCollectionDto.id === entity.id &&
        domain.verificationStatus === 1
      ) {
        list.push(domain)
      }
    })
    return list
  }
  useEffect(() => {
    const result = domainRawList && domainListCreator()
    setDomainList(result)
  }, [])

  const handleSelect = event => {
    setSelectedDomain({
      id: event.target.value,
      domain: event.target.name,
    })

    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        event.target.value,
        'customDomainId',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }
  useEffect(() => {
    if (collectionSettings.teams.length !== 0) {
      setHasTeamsToView(true)
      setTeamListLength(collectionSettings.teams.length)
      collectionSettings.teams.map(team => {
        setMemberListLength(prev => prev + team.memberCount)
      })
    }
  }, [collectionSettings.teams])

  const onBlurEmailTitle = value => {
    if (collectionSettings.invite_member_subject !== value) {
      const patchSource = new PatchData(null, entity.id)
      patchSource.addContent(
        new PatchContent(
          value,
          'inviteEmailSubject',
          PatchData.OPERATION_REPLACE,
        ),
      )

      dispatch(patchCollectionSettingsAction(patchSource, response => {}))
    }
  }
  return (
    <>
      <TextField
        label="Email Subject"
        variant="outlined"
        multiline
        value={emailTitle}
        onChange={e => setEmailTitle(e.target.value)}
        onBlur={e => onBlurEmailTitle(e.target.value)}
        style={{ backgroundColor: '#ffffff', borderRadius:'6px', width:"50%" }}
      />
      <Typography color="#637381" fontWeight={500} fontSize={'14px'}>
        You can customize the invitation email below. A personalized, unique
        link for each user will be automatically included.
      </Typography>
      <TextField
        label="Email Body"
        variant="outlined"
        multiline
        rows={10}
        value={emailText}
        onChange={e => setEmailText(e.target.value)}
        onBlur={e => onBlur(e.target.value)}
        style={{ backgroundColor: '#ffffff', borderRadius:'6px' }}
      />

      <div className={classes.borderBottom}></div>

      <Typography color="#637381" fontWeight={500} fontSize={'14px'}>
        You have assigned{' '}
        <strong>
          {teamListLength} {teamListLength > 1 ? 'teams' : 'team'}{' '}
        </strong>{' '}
        to this collection.
      </Typography>

      <div className={classes.sendLinkToNewMembersOnlyContent}>
        <CustomizedSwitch
          id="sendLinkToNewMembersOnly"
          checked={sendLinkToNewMembersOnly}
          onChange={() =>
            handleSendLinkToNewMembersOnly(!sendLinkToNewMembersOnly)
          }
        />
        <Typography fontSize={'15px'} fontWeight={500}>
          Send link to new members only
        </Typography>
      </div>
    </>
  )
}

export default withStyles(styles)(MailPreparation)
