import React from 'react'

import { withStyles, Button, Tooltip } from '@material-ui/core'

import styles from './style'

import propTypes from './prop'

import translator from 'src/translate'

const Freeform = props => {
  const { classes, entity, dispatch, location } = props
  
  return (
    <>
      {/*<Tooltip title='' placement="bottom">
          
      </Tooltip>*/}
    </>
  )
}

Freeform.propTypes = propTypes

export default withStyles(styles)(Freeform)
