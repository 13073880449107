import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} query
 */
export const mediaImageGetByFilterService = query =>
  new Request(URL.API, URL.MEDIA_IMAGE_GET_BY_FILTER).post(query)

/**
 *
 * @param {*} id
 */
export const mediaImageGetByIdService = id =>
  new Request(URL.API, URL.MEDIA_IMAGE_GET_BY_ID).get({ id })

/**
 *
 * @param {*} files
 */
export const mediaImageUploadManyService = files =>
  new Request(URL.API, URL.MEDIA_IMAGE_UPLOAD_MANY).post(files)

/**
 *
 * @param {*} formData
 */
export const mediaImageUploadService = formData => {
  return new Request(URL.API, URL.MEDIA_IMAGE_UPLOAD).post(formData)
}

/**
 *
 * @param {*} content
 */
export const mediaImagePatchService = content =>
  new Request(URL.API, URL.MEDIA_IMAGE_PATCH).patch(content)

/**
 *
 * @param {*} id
 */
export const mediaImageDeleteService = id =>
  new Request(URL.API, URL.MEDIA_IMAGE_DELETE).delete(id)

/**
 *
 * @param {*} ids
 */
export const mediaImageDeleteListService = ids =>
  new Request(URL.API, URL.MEDIA_IMAGE_DELETE_LIST).post(ids)

/**
 *
 * @param {*} description
 */
export const openaiGenerateImageService = description =>
  new Request(URL.API, URL.OPENAI_GENERATE_IMAGE).post({ description })

/**
 *
 * @param {*} prompt
 * @param {*} cardId
 */
export const mediaCardImageCreationService = (prompt, cardId) =>
  new Request(URL.API, URL.MEDIA_CARD_IMAGE_CREATION).post({
    prompt,
    cardId,
  })
