import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Reorder } from 'src/utils/dnd/reOrder'
import SubHeader from './sub-header'
import { withStyles, styled } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DragIcon from '@material-ui/icons/DragIndicatorOutlined'
import { getItemStyle } from '../../utils/dnd/getItemStyle'
import { Typography, TextField, Box, Button } from '@material-ui/core'
import styles from './styles'
import { track } from '@amplitude/analytics-browser'
import StepAiSelection from './step-ai-image'

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: '0px !important',
  },

  '& .MuiInput-underline:after': {
    borderBottom: '0px',
  },
})

class Outline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      outline: props.outline,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd(result) {
    const { saveOutline } = this.props
    // dropped outside the list
    if (!result.destination) {
      //console.log("no-change");
      return
    }

    if (result.type === 'HEADERS') {
      const outline = Reorder(
        this.state.outline,
        result.source.index,
        result.destination.index,
      )

      this.setState({
        outline,
      })
      saveOutline(outline)
    } else {
      const subHeaders = Reorder(
        this.state.outline[parseInt(result.type, 10)].subHeaders,
        result.source.index,
        result.destination.index,
      )

      const newOutline = [...this.state.outline]

      newOutline[result.type].subHeaders = subHeaders

      this.setState({
        outline: newOutline,
      })

      saveOutline(newOutline)
    }
  }
  inputFocus = id => {
    document.getElementById(id).focus()
  }

  handleHeaderItemChange = (e, index) => {
    const { saveOutline } = this.props

    let newHeaders = [...this.state.outline]
    newHeaders[index].header = e.target.value

    this.setState({
      outline: newHeaders,
    })

    saveOutline(newHeaders)
  }

  handleSubHeaderItemChange = (e, outlineNum, index) => {
    const { saveOutline } = this.props

    let newHeaders = [...this.state.outline]
    newHeaders[outlineNum].subHeaders[index] = e.target.value
    this.setState({
      outline: newHeaders,
    })
    saveOutline(newHeaders)
  }

  addOwnHeader = () => {
    const { saveOutline } = this.props

    let newHeaders = [...this.state.outline]

    let newHeaderObject = {
      id: 'header_' + newHeaders.length + 1,
      header: '',
      subHeaders: [''],
    }

    newHeaders = [...newHeaders, newHeaderObject]

    this.setState({
      outline: newHeaders,
    })

    saveOutline(newHeaders)
  }
  addOwnSubheader = headerIndex => {
    const { saveOutline } = this.props

    let newHeaders = [...this.state.outline]

    newHeaders[headerIndex].subHeaders = [
      ...newHeaders[headerIndex].subHeaders,
      '',
    ]

    this.setState({
      outline: newHeaders,
    })

    saveOutline(newHeaders)
  }

  deleteHeader = id => {
    const { saveOutline } = this.props

    let newHeaders = this.state.outline.filter(x => x.id != id)

    this.setState({
      outline: newHeaders,
    })

    saveOutline(newHeaders)
  }

  deleteSubheader = (headerIndex, value) => {
    const { saveOutline } = this.props

    let newHeaders = this.state.outline
    newHeaders[headerIndex].subHeaders = newHeaders[
      headerIndex
    ].subHeaders.filter(x => x != value)

    this.setState({
      outline: newHeaders,
    })

    saveOutline(newHeaders)
  }
  render() {
    const { handleNext, classes } = this.props
    return (
      <>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragUpdate={this.onDragUpdate}
        >
          <Droppable droppableId="droppable" type="HEADERS">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                //style={getQuestionListStyle(snapshot.isDraggingOver)}
              >
                {this.state.outline.map((header, index) => (
                  <Draggable
                    key={header.id}
                    draggableId={header.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        {' '}
                        <Box className={classes.header}>
                          <span {...provided.dragHandleProps}>
                            <DragIcon
                              color="inherit"
                              fontSize="small"
                              style={{ marginTop: 6 }}
                            />
                          </span>
                          <CssTextField
                            id={header.id}
                            key={header.id}
                            defaultValue={header.header}
                            variant="standard"
                            multiline
                            style={{ borderBottom: 0, minWidth: '80%' }}
                            onBlur={e => this.handleHeaderItemChange(e, index)}
                            placeholder="Edit Your Outline Header"
                          />
                          <span className="buttons">
                            <EditIcon
                              onClick={() => {
                                this.inputFocus(header.id)
                              }}
                              color="inherit"
                              fontSize="small"
                              className={classes.button}
                            />
                            <DeleteIcon
                              onClick={() => {
                                this.deleteHeader(header.id)
                              }}
                              color="inherit"
                              fontSize="small"
                              className={classes.button}
                            />
                          </span>
                        </Box>
                        <Box m={1}></Box>
                        <SubHeader
                          outlineNum={index}
                          header={header}
                          handleSubHeaderItemChange={
                            this.handleSubHeaderItemChange
                          }
                          handleSubHeaderItemChangeOnBlur={
                            this.handleSubHeaderItemChangeOnBlur
                          }
                          deleteSubheader={this.deleteSubheader}
                          addOwnSubheader={this.addOwnSubheader}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/*  <div className={classes.quizDiv}>
            <Typography className={classes.quizText}>Quiz</Typography>
          </div> */}
          <Button
            variant="text"
            size="small"
            onClick={() => this.addOwnHeader()}
          >
            <Typography fontSize={12} fontStyle="normal">
              Add a Header
            </Typography>
          </Button>
        </DragDropContext>
        <Box m={2}></Box>
        <StepAiSelection
          handleCreateCourse={this.props.handleCreateCourse}
          selectedPreset={this.props.selectedPreset}
          setPreset={this.props.setPreset}
          outlineOptionsLength={this.props.outlineOptionsLength}
          count={this.props.count}
          limit={this.props.limit}
          roleType={this.props.roleType}
          imageWillBeGenerated={this.props.imageWillBeGenerated}
          setImageWillBeGenerated={this.props.setImageWillBeGenerated}
        />
        <Box m={2}></Box>
      </>
    )
  }
}

export default withStyles(styles)(Outline)
