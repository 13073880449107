import { useState } from 'react'
import { patchSetting } from 'src/hooks/useCardSettings'
import { useDispatch } from 'react-redux'
import { settingsDrawerOpenSuccess } from 'src/reducers/assignment/action'

export const useBadgeSettings = (badgeSettings, settings) => {
  const dispatch = useDispatch()
  const [ribbonText, setRibbonText] = useState(badgeSettings.badgeRibbonText)
  const [content, setContent] = useState(badgeSettings.badgeTitle)
  const [selectedBadge, setSelectedBadge] = useState(badgeSettings.badgeId)
  const [modalOpen, setModalOpen] = useState(false)

  const handleSelectedBadge = e => {
    setSelectedBadge(e)
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({ ...badgeSettings, badgeId: e }),
    )
  }

  const handleRibbonText = e => {
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({ ...badgeSettings, badgeRibbonText: e }),
    )
  }

  const handleContent = e => {
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({ ...badgeSettings, badgeTitle: e }),
    )
  }

  const handlePopupOpen = () => {
    setModalOpen(modalOpen => !modalOpen)
  }

  const handleContentText = e => {
    if (e.length <= 32) setContent(e)
  }

  const handleRibbon = e => {
    if (e.length <= 12) setRibbonText(e)
  }

  const handleDrawerClose = () => {
    dispatch(settingsDrawerOpenSuccess())
  }

  return {
    ribbonText,
    setRibbonText,
    content,
    setContent,
    selectedBadge,
    setSelectedBadge,
    modalOpen,
    setModalOpen,
    handleSelectedBadge,
    handleRibbonText,
    handleContent,
    handlePopupOpen,
    handleContentText,
    handleRibbon,
    handleDrawerClose,
  }
}
