import React, { useEffect, useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'
import { Typography, Box, Divider, Button } from '@material-ui/core'

import moment from 'moment-timezone'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
const CardHeader = props => {
  const { documentsUploaded, courseDocuments, onHandleBack, classes } = props
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState(true)

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom:
              !documentsUploaded && courseDocuments.length > 0
                ? '12px'
                : '16px',
          }}
        >
          {!documentsUploaded && courseDocuments.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="outlined"
                startIcon={
                  <ArrowBackIosIcon
                    style={{ color: '#015FFB', fontSize: 14 }}
                  />
                }
                onClick={() => onHandleBack()}
              >
                <Typography color={'#015FFB'}>Back</Typography>
              </Button>
            </div>
          ) : (
            <>
              <img
                src="/assets/ai_button_bar.png"
                className={classes.headerIcon}
              />
              <Typography
                variant="h5"
                color="primary"
                className={classes.headerText}
              >
                AI Quiz Creator
              </Typography>
            </>
          )}
        </div>
      </div>

      <Divider className={classes.dividerLine}></Divider>
    </>
  )
}

export default withStyles(styles)(CardHeader)
