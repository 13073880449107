import React from 'react'
import styles from './styles'
import { withStyles, Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  AutomationsHeader,
  FirstStepView,
  SecondStep,
  ThirdStep,
  ZapierEmbed,
} from './components'
const Automations = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <AutomationsHeader />
      <FirstStepView />
      <SecondStep />
      <ThirdStep />
      <ZapierEmbed />

      <Box m={4}></Box>
    </div>
  )
}

export default withStyles(styles)(Automations)
