import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const UpgradeCertificateBanner = ({ classes }) => {
  return (
    <div className={classes.certificationContainer}>
      <Typography className={classes.title}>Certification</Typography>
      <Typography className={classes.description}>
        Award learners a digital certificate when they complete a collection or
        pass the success threshold.
      </Typography>

      <div className={classes.content}>
        <div className={classes.bannerContainer}>
          <div className={classes.bannerContent}>
            <Typography className={classes.bannerText}>
              Do you wanna to create a Certificate?
            </Typography>
            <Typography className={classes.bannerSubText}>
              With a higher plan, you can define a Certificate for your
              Collections based on personalized completion and success rate you
              determine.
            </Typography>
            <Button
              className={classes.bannerButton}
              variant="contained"
              onClick={() => {
                window.location.href = '/pricing'
              }}
            >
              See Plans & Upgrade
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(UpgradeCertificateBanner)
