import React, { useEffect, useState } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  Button,
  Autocomplete,
  TextField,
  Card,
  CardContent,
  Box,
} from '@material-ui/core'
import LinkOffIcon from '@material-ui/icons/LinkOff'

import heartOutline from '@iconify-icons/eva/heart-outline'
import { Icon } from '@iconify/react'

import { Link as RouterLink } from 'react-router-dom'
import { currencyModes, currencies } from 'src/model/payment'
import translator from 'src/translate'

import {
  QuestionFieldNames,
  QuestionPaymentFieldNames,
  QuestionEditorHeights,
} from 'src/model/question'

import { useDispatch, useSelector } from 'react-redux'

import {
  questionPaymentDataChangeAction,
  questionPaymentDisconnectAction,
} from 'src/reducers/assignment/action'
import { PatchContent, PatchData } from 'src/model/patch'

import { handlePaymentFetch } from 'src/api/question/payment/stripe-payment'
import { AssignmentFieldNames } from 'src/model/assignment'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

const PaymentModal = props => {
  const dispatch = useDispatch()
  const { classes, courseId, courseEntity, entity } = props
  const entityId = entity.id
  const baseEntity = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )

  const paymentData = entity[QuestionFieldNames.PAYMENT]
    ? entity[QuestionFieldNames.PAYMENT][0]
    : []
  const shopName = window.CONSTANTS.COMPANY_NAME
  const userRole = window.CONSTANTS.USER_ROLE_ID
  const [isStripeConnected, setStripeConnected] = useState(
    paymentData &&
      paymentData[QuestionPaymentFieldNames.ACCOUNT_ID] &&
      paymentData[QuestionPaymentFieldNames.ACCOUNT_ID] != undefined,
  )

  const [connectionMode, setConnectionMode] = useState(
    paymentData ? paymentData[QuestionPaymentFieldNames.MODE] : 0,
  )

  //let entity = courseEntity[AssignmentFieldNames.QUESTION_DTOS][question.index]

  const connectButtonClickHandler = () => {
    let mode = connectionMode

    handlePaymentFetch({
      courseId: courseId,
      cardId: entity[QuestionFieldNames.ID],
      mode,
    })
    //setStripeConnected(true)
  }

  const disconnectButtonEventHandler = async () => {
    await dispatch(
      questionPaymentDisconnectAction(entity[QuestionFieldNames.ID]),
    )
    setStripeConnected(false)
  }

  const handleChangePaymentValue = async (type, value) => {
    await dispatch(
      questionPaymentDataChangeAction(
        new PatchData(
          new PatchContent(value, type, PatchData.OPERATION_REPLACE),
          entity[QuestionFieldNames.ID],
        ),
      ),
    )

    if (type == QuestionPaymentFieldNames.MODE) {
      setConnectionMode(value)
    }
  }
  return (
    <>
      {window.CONSTANTS.USER_ROLE_ID === 4 ||
      window.CONSTANTS.USER_ROLE_ID === 8 ||
      window.CONSTANTS.USER_ROLE_ID === 1 ||
      window.CONSTANTS.USER_ROLE_ID === 12 ? (
        <React.Fragment>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.limitModalImage}>
                <img src="/assets/popup_girl.png" width="160px" />
              </div>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                marginBottom="20px"
              >
                Want to get paid with your mini-course?
              </Typography>
              <Typography align="center" gutterBottom>
                By upgrading to Premium, you will be able to connect with your{' '}
                <br /> Stripe account and monetize your mini-courses!
                <Box m={3}></Box>
                <Button
                  to="/pricing"
                  sx={{
                    mr: 1,
                    color: 'text.primary',
                  }}
                  variant="outlined"
                  startIcon={<Icon icon={heartOutline} />}
                  component={RouterLink}
                >
                  See Plans and Upgrade
                </Button>
              </Typography>
            </CardContent>
          </Card>
        </React.Fragment>
      ) : (
        <FormControl className={classes.form}>
          <Box m={1}></Box>
          <Typography variant="h6">Stripe Integration</Typography>
          <Box m={1}></Box>
          <Autocomplete
            className={classes.marginTop}
            options={currencyModes}
            defaultValue={
              paymentData &&
              paymentData[QuestionPaymentFieldNames.MODE] &&
              paymentData[QuestionPaymentFieldNames.MODE] == 1
                ? 'Live'
                : 'Test'
            }
            onChange={(event, value) => {
              handleChangePaymentValue(
                QuestionPaymentFieldNames.MODE,
                value === 'Live' ? 1 : 0,
              )
              if (isStripeConnected) {
                disconnectButtonEventHandler()
              }
            }}
            disableClearable
            renderInput={params => (
              <TextField {...params} label={translator._('action.mode')} />
            )}
          />

          {isStripeConnected && (
            <>
              <Button
                variant="text"
                className={classes.button}
                onClick={disconnectButtonEventHandler}
                startIcon={<LinkOffIcon />}
                color="inherit"
                fullWidth="false"
                size="small"
              >
                {translator._('action.disconnect')}
              </Button>

              <Autocomplete
                className={classes.marginTop}
                options={currencies}
                getOptionLabel={currency =>
                  `${currency.description} - ${currency.code}`
                }
                defaultValue={currencies.find(
                  currency =>
                    currency.code.toLowerCase() ==
                    (paymentData[
                      QuestionPaymentFieldNames.CURRENCY
                    ].toLowerCase() || 'usd'),
                )}
                onChange={(event, value) =>
                  handleChangePaymentValue(
                    QuestionPaymentFieldNames.CURRENCY,
                    value.code,
                  )
                }
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label={translator._('taskItem.payment.currency')}
                  />
                )}
              />

              <TextField
                className={classes.marginTop}
                label={translator._('taskItem.payment.amount')}
                type="number"
                defaultValue={
                  paymentData[QuestionPaymentFieldNames.AMOUNT] > 0 &&
                  paymentData[QuestionPaymentFieldNames.AMOUNT]
                }
                onBlur={event => {
                  handleChangePaymentValue(
                    QuestionPaymentFieldNames.AMOUNT,
                    event.target.value,
                  )
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <p className={classes.amountInfo}>
                {translator._('taskItem.payment.amountInfo')}
              </p>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {translator._('action.advantageSettings')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.accordionDetails}>
                    <TextField
                      className={classes.marginBottom + ' ' + classes.fullWidth}
                      disabled={true}
                      label="From Field"
                      defaultValue={shopName}
                    ></TextField>
                    <Typography>
                      {/*<TextField
                      className={classes.marginBottom + ' ' + classes.fullWidth}
                      label={translator._('taskItem.payment.successEmail')}
                      defaultValue={
                        paymentData[QuestionPaymentFieldNames.SUCCESSEMAIL]
                      }
                      multiline
                      rows={9}
                      onBlur={event => {
                        handleChangePaymentValue(
                          QuestionPaymentFieldNames.SUCCESSEMAIL,
                          event.target.value,
                        )
                      }}
                    />*/}
                      <SunEditor
                        key={'editor' + entity[QuestionFieldNames.ID]}
                        defaultValue={
                          paymentData[QuestionPaymentFieldNames.SUCCESSEMAIL]
                        }
                        onBlur={event => {
                          handleChangePaymentValue(
                            QuestionPaymentFieldNames.SUCCESSEMAIL,
                            event.target.innerHTML,
                          )
                        }}
                        className={classes.textArea}
                        setDefaultStyle="text-align: left;"
                        setOptions={{
                          height: '100px',
                          buttonList: [
                            [],
                            ['formatBlock'],
                            [],
                            ['bold'],
                            [],
                            ['image'],
                            [],
                            ['codeView'],
                            [],
                            [
                              ':i-More Misc-default.more_vertical',
                              'fontColor',
                              'hiliteColor',
                              'align',
                              'link',
                              'italic',
                              'underline',
                              'removeFormat',
                            ],
                          ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                          // plugins: [font] set plugins, all plugins are set by default
                          // Other option
                          pasteTagsWhitelist: 'p|h1|h2|h3',
                          popupDisplay: 'local',
                        }}
                      />
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {!isStripeConnected ? (
            <Button
              variant="contained"
              className={classes.marginTop + ' ' + classes.marginBottom}
              onClick={connectButtonClickHandler}
            >
              {translator._('action.connectToStripe')}
            </Button>
          ) : (
            ''
          )}
        </FormControl>
      )}
    </>
  )
}

PaymentModal.propTypes = propTypes

export default withStyles(styles)(PaymentModal)
