const styles = theme => ({
  bannerContainer: {
    width: '100%',
    height: '200px',
    borderRadius: '16px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    flexDirection: 'row',
    backgroundImage: `url('/assets/automation/automations_banner_noborder.png')`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    gap: '16px',
    textAlign: 'center',
    marginLeft: '50px',
  },
  bannerText: {
    color: '#002E47',
    fontWeight: 500,
    fontSize: '18px',
  },
  bannerButton: {
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    width: '100%',
    color: '#002E47',
    '&:hover': {
      backgroundColor: '#D4DADF',
    },
  },
})

export default styles
