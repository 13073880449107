import { all } from 'redux-saga/effects'
import {
  loginSagaWatcher,
  logoutSagaWatcher,
  deleteSagaWatcher,
  verifyChangePasswordWatcher,
  sendForgotEmailSagaWatcher,
  refreshSagaWatcher,
  currentUserInfoSagaWatcher,
} from './authentication'

import {
  userChangePasswordPutWatcher,
  userPasswordSetWatcher,
  userRenewPasswordWatcher,
} from './user-password'
import { shopGetWatcher, shopEditWatcher, shopPatchWatcher } from './shop'
import {
  mediaImageUploadWatcher,
  mediaImageDeleteWatcher,
  openaiGenerateImageWatcher,
  mediaCardImageCreationWatcher,
} from './media-image'
import { mediaVideoUploadWatcher, mediaVideoDeleteWatcher } from './media-video'
import {
  mediaFolderAddAsyncWatcher,
  mediaFolderPatchWatcher,
  mediaFolderDeleteWatcher,
} from './media-folder'

import {
  userAddWatcher,
  userPatchWatcher,
  adminPatchWatcher,
  userGetWatcher,
  userValidateShortLinkWatcher,
  userGetSelfWatcher,
  userListWatcher,
  userIdListWatcher,
} from './user'

import {
  questionListWatcher,
  questionAddWatcher,
  questionPatchWatcher,
  questionGetWatcher,
  questionDeleteWatcher,
  questionDeleteListWatcher,
  questionLinkAddWatcher,
  questionLinkDeleteWatcher,
  questionMediaAddWatcher,
  questionMediaDeleteWatcher,
  questionSelectionAddWatcher,
  questionSelectionDeleteWatcher,
  questionTagAddWatcher,
  questionTagDeleteWatcher,
  questionGetListWatcher,
  questionListMakeActiveWatcher,
  questionListMakePassiveWatcher,
  questionReportCompareTeamsWatcher,
  questionReportRelatedTeamsWatcher,
  questionReportChoiceTendencyWatcher,
  questionReportShowUserAnswersWatcher,
  questionCloneWatcher,
  questionReportImprovementWatcher,
  questionSelectionPatchWatcher,
  questionIdListWatcher,
  questionUpdateEntityWatcher,
  paymentPatchWatcher,
  paymentDisconnectWatcher,
  questionSettingsPatchWatcher,
  questionSettingsCreateWatcher,
  questionSettingsRemoveWatcher,
  questionSelectionsAddWatcher,
  getBadgesWatcher,
} from './question'

import {
  sendConfirmEmailSagaWatcher,
  confirmCodeSagaWatcher,
  signUpSagaWatcher,
  signUpRootieSagaWatcher,
  appSumosignUpSagaWatcher,
} from './sign-up'
import {
  mediaDirectoryGetRootWatcher,
  mediaDirectoryGetWatcher,
} from './media-directory'

import {
  assignmentListWatcher,
  assignmentGetWatcher,
  assignmentAddWatcher,
  assignmentEditWatcher,
  assignmentUpdateWatcher,
  assignmentRemoveUserWatcher,
  assignmentPatchWatcher,
  assignmentRemoveQuestionWatcher,
  assignmentReportWatcher,
  assignmentAddBulkQuestionWatcher,
  openAiRequestWatcher,
  assignmentCardLinkPatchWatcher,
  courseSettingsCreateWatcher,
  courseSettingsRemoveWatcher,
  courseSettingsPatchWatcher,
  openAiCardRequestWatcher,
  openAiCourseCreationGetTitleRequestWatcher,
  openAiCourseCreationGetOutlineRequestWatcher,
  openaiSendCourseCreationRequestWatcher,
  templateCourseCloneWatcher,
  sendCreatePromptFromCardContentRequestWatcher,
  getCourseAnalyticsByIdWatcher,
  addCourseDocumentWatcher,
  getCourseDocumentByIdWatcher,
  generateQuizWithCourseDocumentWatcher,
  deleteCourseDocumentWatcher,
  generateSuggestionWithCourseDocumentWatcher,
  generateInformationWithCourseDocumentWatcher,
  generateQuizzesByCourseIdWatcher,
  exportCourseToScormWatcher,
} from './assignment'

import {
  domainListWatcher,
  domainDeleteWatcher,
  domainRetryWatcher,
  domainPatchWatcher,
  domainAddWatcher,
} from './domain'

import {
  courseTimeSeriesListWatcher,
  courseTimeSeriesReportWatcher,
} from './course-time-series'

import {
  courseStatisticsListWatcher,
  courseStatisticsReportWatcher,
} from './course-statistics'

import { appsumoCodeVerifierSagaWatcher } from './appsumo-code'

import { appsumoCodeRefundSagaWatcher } from './appsumo-refund-code'

import {
  userRoleDetailedListWatcher,
  setUserRoleWatcher,
} from './user-role-detailed-list'

import {
  createCourseCollectionSagaWatcher,
  deleteCourseCollectionWatcher,
  patchCourseCollectionWatcher,
  courseCollectionListWatcher,
  courseCollectionUpdateWatcher,
  editCourseCollectionIndexWatcher,
  assignmentCloneWatcher,
  assignmentDeleteWatcher,
  courseCollectionPatchWatcher,
  getCollectionSettingsWatcher,
  patchCollectionSettingWatcher,
  getCollectionAnalyticsWatcher,
  courseCollectionAnalyticsDownloadWatcher,
} from './course-collection'

import {
  teamsListWatcher,
  teamAddWatcher,
  patchTeamWatcher,
  deleteTeamWatcher,
  teamsMemberListWatcher,
  teamsMemberDeleteWatcher,
  patchTeamMemberWatcher,
  addTeamsMemberWatcher,
  teamsMemberExportWatcher,
  teamsImportMemberWatcher,
  sendMailToLearnerWatcher,
} from './teams'

import {
  createCertificateSagaWatcher,
  getCertificateSagaWatcher,
  getCertificateListSagaWatcher,
  patchCertificateWatcher,
} from './certificate'

/**
 *
 */
export default function* rootSaga() {
  yield all([
    loginSagaWatcher(),
    logoutSagaWatcher(),
    deleteSagaWatcher(),
    verifyChangePasswordWatcher(),
    sendForgotEmailSagaWatcher(),
    refreshSagaWatcher(),
    currentUserInfoSagaWatcher(),

    userChangePasswordPutWatcher(),
    userPasswordSetWatcher(),
    userRenewPasswordWatcher(),

    userAddWatcher(),
    userPatchWatcher(),
    adminPatchWatcher(),
    userGetWatcher(),
    userValidateShortLinkWatcher(),
    userGetSelfWatcher(),
    userListWatcher(),
    userIdListWatcher(),

    questionAddWatcher(),
    questionGetWatcher(),
    questionListWatcher(),
    questionPatchWatcher(),
    questionDeleteWatcher(),
    questionDeleteListWatcher(),
    questionLinkAddWatcher(),
    questionLinkDeleteWatcher(),
    questionSelectionAddWatcher(),
    questionSelectionDeleteWatcher(),
    questionMediaAddWatcher(),
    questionMediaDeleteWatcher(),
    questionTagAddWatcher(),
    questionTagDeleteWatcher(),
    questionGetListWatcher(),
    questionListMakeActiveWatcher(),
    questionListMakePassiveWatcher(),
    questionReportCompareTeamsWatcher(),
    questionReportRelatedTeamsWatcher(),
    questionReportChoiceTendencyWatcher(),
    questionReportShowUserAnswersWatcher(),
    questionCloneWatcher(),
    questionReportImprovementWatcher(),
    questionSelectionPatchWatcher(),
    questionIdListWatcher(),
    questionUpdateEntityWatcher(),
    paymentPatchWatcher(),
    paymentDisconnectWatcher(),
    questionSettingsPatchWatcher(),
    questionSettingsCreateWatcher(),
    questionSettingsRemoveWatcher(),
    questionSelectionsAddWatcher(),
    getBadgesWatcher(),

    sendConfirmEmailSagaWatcher(),
    confirmCodeSagaWatcher(),
    signUpSagaWatcher(),
    signUpRootieSagaWatcher(),
    appSumosignUpSagaWatcher(),

    mediaDirectoryGetRootWatcher(),
    mediaDirectoryGetWatcher(),

    mediaImageUploadWatcher(),
    mediaImageDeleteWatcher(),
    mediaVideoUploadWatcher(),
    mediaVideoDeleteWatcher(),
    mediaFolderAddAsyncWatcher(),
    mediaFolderPatchWatcher(),
    mediaFolderDeleteWatcher(),
    openaiGenerateImageWatcher(),
    mediaCardImageCreationWatcher(),

    shopGetWatcher(),
    shopEditWatcher(),
    shopPatchWatcher(),

    assignmentListWatcher(),
    assignmentGetWatcher(),
    assignmentAddWatcher(),
    assignmentEditWatcher(),
    assignmentUpdateWatcher(),
    assignmentRemoveUserWatcher(),
    assignmentDeleteWatcher(),
    assignmentPatchWatcher(),
    assignmentRemoveQuestionWatcher(),
    assignmentReportWatcher(),
    assignmentAddBulkQuestionWatcher(),
    openAiRequestWatcher(),
    assignmentCardLinkPatchWatcher(),
    courseSettingsCreateWatcher(),
    courseSettingsRemoveWatcher(),
    courseSettingsPatchWatcher(),
    openAiCardRequestWatcher(),
    openAiCourseCreationGetTitleRequestWatcher(),
    openAiCourseCreationGetOutlineRequestWatcher(),
    openaiSendCourseCreationRequestWatcher(),
    templateCourseCloneWatcher(),
    sendCreatePromptFromCardContentRequestWatcher(),
    getCourseAnalyticsByIdWatcher(),
    addCourseDocumentWatcher(),
    getCourseDocumentByIdWatcher(),
    generateQuizWithCourseDocumentWatcher(),
    deleteCourseDocumentWatcher(),
    generateSuggestionWithCourseDocumentWatcher(),
    generateInformationWithCourseDocumentWatcher(),
    generateQuizzesByCourseIdWatcher(),
    exportCourseToScormWatcher(),

    domainListWatcher(),
    domainDeleteWatcher(),
    domainRetryWatcher(),
    domainPatchWatcher(),
    domainAddWatcher(),

    courseTimeSeriesListWatcher(),
    courseStatisticsListWatcher(),
    courseTimeSeriesReportWatcher(),
    courseStatisticsReportWatcher(),

    appsumoCodeVerifierSagaWatcher(),

    appsumoCodeRefundSagaWatcher(),

    userRoleDetailedListWatcher(),
    setUserRoleWatcher(),

    createCourseCollectionSagaWatcher(),
    deleteCourseCollectionWatcher(),
    patchCourseCollectionWatcher(),
    courseCollectionListWatcher(),
    courseCollectionUpdateWatcher(),
    editCourseCollectionIndexWatcher(),
    assignmentCloneWatcher(),
    courseCollectionPatchWatcher(),
    getCollectionSettingsWatcher(),
    patchCollectionSettingWatcher(),
    getCollectionAnalyticsWatcher(),
    courseCollectionAnalyticsDownloadWatcher(),

    teamsListWatcher(),
    teamAddWatcher(),
    patchTeamWatcher(),
    deleteTeamWatcher(),
    teamsMemberListWatcher(),
    teamsMemberDeleteWatcher(),
    patchTeamMemberWatcher(),
    addTeamsMemberWatcher(),
    teamsMemberExportWatcher(),
    teamsImportMemberWatcher(),
    sendMailToLearnerWatcher(),

    createCertificateSagaWatcher(),
    getCertificateSagaWatcher(),
    getCertificateListSagaWatcher(),
    patchCertificateWatcher(),
  ])
}
