import React, { useEffect, useState, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const UplaodPdf = props => {
  const {
    documentPdf,
    setDocumentPdf,
    file,
    setFile,
    handleAddSource,
    classes,
  } = props

  const inputRef = useRef(null)
  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click()
  }
  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    const file = event.target.files[0]
    setFile(file)
    handleAddSource(true)
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid  #015FFB',
          borderRadius: 8,
          height: '48px',
          marginBottom: '16px',
        }}
      >
        {documentPdf ? (
          <Typography>{file.name}</Typography>
        ) : (
          <>
            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <Button
              onClick={handleClick}
              startIcon={<CloudUploadIcon />}
              variant="contained"
              style={{
                color: '#fff',
                backgroundColor: '#015FFB',
                height: '100%',
                borderRadius: 6,
              }}
            >
              Choose File
            </Button>
            <Typography
              color="#637381"
              style={{
                marginLeft: 16,
                fontSize: '14px',
                fontWeight: 500,
                color: '#000',
                maxWidth: '430px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {file ? file.name : 'No file chosen'}
            </Typography>
          </>
        )}
      </div>
      <Typography
        color="#637381"
        style={{ fontSize: '12px', fontWeight: 500, margin: 5 }}
      >
        Upload a file containing text. Processing files can take 1-2 minutes.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(UplaodPdf)
