import React, { useState, useEffect } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import PurposeSelectionField from './purpose-selection-field'
import OnboardingInformation from './onboarding-information'
import OnboardingInformationMobile from './onboarding-information-mobile'
import PurposeSelectionFieldMobile from './purpose-selection-field-mobile'
import { isMobile } from 'react-device-detect'
import { userGetSelfAction } from 'src/reducers/user/action'
import { useDispatch } from 'react-redux'
import { meService } from 'src/api/authentication'

const Purpose = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [pageView, setPageView] = useState(1)
  const [name, setName] = useState('')
  const handlePageView = () => {
    setPageView(prev => prev + 1)
  }
  useEffect(() => {
    meService().then(res => {
      setName(res.data.name)
    })
  }, [])

  return (
    <div
      component="main"
      className={isMobile ? classes.rootMobile : classes.root}
    >
      {pageView == 6 ? (
        isMobile ? (
          <PurposeSelectionFieldMobile />
        ) : (
          <PurposeSelectionField />
        )
      ) : isMobile ? (
        <OnboardingInformationMobile
          pageView={pageView}
          setPageView={setPageView}
          handlePageView={handlePageView}
          name={name}
        />
      ) : (
        <OnboardingInformation
          pageView={pageView}
          setPageView={setPageView}
          handlePageView={handlePageView}
          name={name}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(Purpose)
