import React from 'react'

import { withStyles, Button, Tooltip } from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';

import styles from './style'

import propTypes from './prop'

import translator from 'src/translate'

import BulkImportContainer from '../../../answer-type/option/bulk-import-option'

import { componentBoxShowAction } from '../../../../../reducers/component-box/action'
import ComponentBoxContent from '../../../../../model/component-box'

import {
  QuestionFieldNames,
  SelectionFieldNames,
  AnswerItemType,
} from '../../../../../model/question'

import { questionSelectionAddAction } from '../../../../../reducers/question/action'

const Option = props => {
  const { classes, entity, dispatch, location } = props
  
  const addButtonClickHandler = () => {
    let object = {}
    object[SelectionFieldNames.QUESTION_ID] = entity[QuestionFieldNames.ID]
    object[SelectionFieldNames.STEXT] = ''
    object[SelectionFieldNames.ISCORRECT] = false
    dispatch(questionSelectionAddAction(object, () => {}))
  }

  const importOptionButtonClickHandler = () => {
    const { dispatch } = props

    let defaultOptions = ''
    if (!entity[QuestionFieldNames.IS_ANSWERED]) {
      entity[QuestionFieldNames.SELECTIONS].forEach((element, i) => {
        if (i != 0) {
          defaultOptions =
            defaultOptions + '\n' + element[SelectionFieldNames.STEXT]
        } else {
          defaultOptions = element[SelectionFieldNames.STEXT]
        }
      })
    }

    const component = (
      <BulkImportContainer
        entity={entity}
        dispatch={dispatch}
        defaultOptions={defaultOptions}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
  }

  return (
    <>
    {/*
      <Tooltip title={translator._('taskItem.addQuestion.answerType.addOption')} placement="bottom">
          <Button
              className={classes.iconButton}
              size="small"
              onClick={addButtonClickHandler}
          >
              <AddCircleOutlinedIcon className={classes.icon} fontSize="small" />
          </Button>
      </Tooltip>
      <Tooltip title={translator._('taskItem.addQuestion.answerType.importAllOptions')} placement="bottom">
          <Button
              className={classes.iconButton}
              size="small"
              onClick={importOptionButtonClickHandler}
          >
              <ImportExportOutlinedIcon className={classes.icon} fontSize="small" />
          </Button>
      </Tooltip>
      */}
    </>
  )
}

Option.propTypes = propTypes

export default withStyles(styles)(Option)
