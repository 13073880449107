import React from 'react'
import { useSelector } from 'react-redux'

import {
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  InputBase,
  Box,
} from '@material-ui/core'
import { Icon } from '@iconify/react'

import { styled } from '@material-ui/core'
import {
  TeamListItem,
  MagicLink,
  UpgradePopover,
  NoGroupView,
  AccordionSummaryContent,
} from '../index'
import styles from '../styles'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
import UserType from 'src/model/user-types'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  '&.Mui-expanded': {
    boxShadow: 'none',
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '.MuiSvgIcon-root': {
      display: 'none',
    },
  },
  input: {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const BootstrapInputLabel = withStyles(theme => ({
  root: {
    zIndex: 400,
    top: 0,
    left: 10,

    '&.Mui-focused': {
      display: 'none',
    },
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
}))(InputLabel)

const BootstrapSelect = withStyles(theme => ({
  icon: {
    right: 8,
  },
}))(Select)

const AccessTeams = props => {
  const {
    entity,
    accessRule,
    handleAccessRule,
    dispatch,
    handleMagicLink,
    classes,
  } = props

  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )
  const allTeamList = useSelector(state => state.teamsList.teamList)

  const [selectedTeamList, setSelectedTeamList] = React.useState(
    collectionSettings && [...collectionSettings.teams],
  )

  const handleSaveAccessTeams = value => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(value, 'teams', PatchData.OPERATION_REPLACE),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }

  const handleChange = event => {
    let length = event.target.value.length

    let newItemId = event.target.value[length - 1]
    let item = allTeamList.find(item => item.id == newItemId)
    if (selectedTeamList.map(item => item.id).includes(newItemId)) {
      setSelectedTeamList(selectedTeamList.filter(item => item.id != newItemId))
      handleSaveAccessTeams(
        selectedTeamList.filter(item => item.id != newItemId),
      )
    } else {
      setSelectedTeamList([...selectedTeamList, item])
      handleSaveAccessTeams([...selectedTeamList, item])
    }
  }

  const removeTeam = teamId => {
    let newItems = selectedTeamList.filter(item => item.id != teamId)
    setSelectedTeamList(newItems)
    handleSaveAccessTeams(newItems)
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }

  const CustomizedCheckBox = withStyles({
    root: {
      color: '#637381',
      '&$checked': {
        color: '#37D768',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />)

  const handleTeamsButtonClick = () => {
    window.location.href = '/learners'
  }

  const handleAccessRuleChange = value => {
    if (value != accessRule) {
      handleAccessRule(value)
      setSelectedTeamList([])
    }
  }
  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const accessSpecificUsersControl = () => {
    if (
      userControl == 'Premium Plus' ||
      userControl == 'Premium Plus v2' ||
      userControl == 'Premium - A' ||
      userControl == 'Appsumo (Tier 3)' ||
      userControl == 'Appsumo (Tier 3A)' ||
      userControl == 'Appsumo (Tier 4)' ||
      userControl == 'Appsumo (Tier 5)'
    ) {
      return false
    }
    return true
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClickSwitch = event => {
    accessSpecificUsersControl()
      ? handleClick(event)
      : handleAccessRuleChange(2)
  }

  return (
    <div className={classes.root}>
      <StyledAccordion expanded={accessRule == 2}>
        <AccordionSummary
          className={classes.accordion__summary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryContent
            accessRule={accessRule}
            handleClickSwitch={handleClickSwitch}
            contentHeader={' Enable access to specific learners'}
            contentDescription={
              'Send invitation emails that include a magic-link, through which invited learners can instantly access your course without a password.'
            }
            id={id}
            badgeType={2}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            popupHeader={'Upgrade to Pro'}
            popupContent={
              'Unlock exclusive features by upgrading to the Pro plan.'
            }
            accessRuleEqual={2}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordion__details}>
          <div className={classes.accordion__details__content}>
            {allTeamList && allTeamList[0] != undefined ? (
              <FormControl variant="standart" className={classes.formControl}>
                <BootstrapInputLabel htmlFor="mutiple-chip">
                  Select Group
                </BootstrapInputLabel>
                <BootstrapSelect
                  multiple
                  id="mutiple-chip"
                  value={selectedTeamList}
                  onChange={handleChange}
                  input={<BootstrapInput id="select-multiple-chip" />}
                  className={classes.select}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      <Typography>
                        {selectedTeamList.length} Groups Selected
                      </Typography>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {allTeamList.map((item, index) => {
                    return (
                      <MenuItem
                        key={item.name}
                        name={item.id}
                        value={item.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <CustomizedCheckBox
                            checked={selectedTeamList
                              .map(listItem => listItem.id)
                              .includes(item.id)}
                          />
                          {item.name}
                        </div>
                        <div className={classes.displayFAI}>
                          <Icon icon={'mdi:people'} color="#637381" />
                          <Typography
                            fontSize={'12px'}
                            fontWeight={400}
                            color={'#637381'}
                          >
                            {item.memberCount}
                          </Typography>
                        </div>
                      </MenuItem>
                    )
                  })}
                </BootstrapSelect>
              </FormControl>
            ) : (
              <NoGroupView handleTeamsButtonClick={handleTeamsButtonClick} />
            )}
            <div className={classes.infoTextForGroup}>
              <Typography fontSize={13}>Groups that can be access:</Typography>
            </div>

            {selectedTeamList &&
              selectedTeamList[0] != undefined &&
              selectedTeamList.map((item, index) => {
                return (
                  <TeamListItem
                    name={item.name}
                    number1={item.memberCount}
                    teamId={item.id}
                    removeTeam={removeTeam}
                    type="green"
                  />
                )
              })}
            <MagicLink
              handleMagicLink={handleMagicLink}
              selectedTeamList={selectedTeamList}
            />
          </div>
        </AccordionDetails>
      </StyledAccordion>
      <UpgradePopover
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        header={'Upgrade to Pro'}
        content={'Unlock exclusive features by upgrading to the Pro plan.'}
        accessRuleEqual={2}
      />
    </div>
  )
}

export default withStyles(styles)(AccessTeams)
