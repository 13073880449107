const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  stepperGrid: {
    width: '100%',
    height: '100vh',
  },
  stepperHolder: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: '1px solid lightgrey',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 240,
    },
  },
  stepperLabel: {
    fontSize: 15,
    display: 'flex',
    flexDirection: 'center',
    alignItems: 'center',
  },
  stepContent: {
    [theme.breakpoints.down('md')]: {
      borderLeft: 0,
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  descriptionText: {
    fontSize: 12,
    margin: '3px',
    marginTop: '8px',
    //paddingVertical: 60,
  },
  button: {
    marginTop: '16px',
  },
  actionsContainer: {
    display: 'flex',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    padding: 32,
    '&:hover': {
      outline: '1px solid #0d53b7',
      cursor: 'pointer',
    },
  },
  paperType: {
    borderRadius: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '200px',
    width: '150px',
    //padding: 32,
    alignItems: 'center',
    //outline: '1px solid #e7ecf3',
    [theme.breakpoints.down('sm')]: {
      zoom: 0.8,
    },
    '&:hover': {
      cursor: 'pointer',
      outline: '1px solid #015FFB',
    },
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    background: '#f2f2f2',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem',
    marginTop: '0.5rem',
    '&:hover': {
      cursor: 'default',
    },
  },
  list: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginBottom: 16,
  },
  listItem: {
    paddingLeft: 0,
    borderBottom: '1px solid #efefef',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '.buttons': {
    display: 'none',
  },
  gatewayList: {
    backgroundColor: 'white',
    borderRadius: 8,
    paddingBottom: 0,
    paddingTop: 0,
    //marginBottom: 4,
  },
  gatewayListItem: {
    paddingLeft: 0,
  },
  gatewayListItemPointers: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  header: {
    '& .buttons': {
      display: 'none',
    },
    '&:hover .buttons': {
      display: 'inline-block',
      float: 'right',
    },
  },
  button: {
    cursor: 'pointer',
    marginLeft: 6,
  },
  editButton: {
    marginLeft: 6,
    width: '250px',
    backgroundColor: '#015FFB',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    boxShadow: 'none',
  },
  carouselEditButton: {
    marginLeft: 6,
    width: '250px',
    backgroundColor: '#015FFB',
    borderRadius: 24,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  viewButton: {
    marginLeft: 6,
    backgroundColor: '#015FFB',
    borderRadius: 24,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  carouselViewButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 6,
    //backgroundColor: '#015FFB',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  item: {
    width: '100%',
    marginBottom: '5px',
  },

  imagePlaceholder: {
    marginBottom: theme.spacing(1.5),
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
  },
  imageUpload: {
    display: 'grid',
  },
  favIcon: {
    display: 'flex',
    flexDirection: 'row',
  },

  card: {
    maxWidth: 460,
    [theme.breakpoints.down('md')]: {
      maxWidth: '80vw',
    },
    width: 'fill-available',
    borderRadius: 5,
  },
  previewCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  thumbNailImage: {
    maxHeight: 120,
  },
  presets: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
    marginTop: '20px',
    alignItems: 'center',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },

  cardRoot: {
    boxShadow: 'none',
  },

  presetHeader: {
    marginTop: '7px',
  },
  padding: {
    paddingTop: '.6rem',
    paddingBottom: '.6rem',
  },

  media: {
    width: '100%',
    height: '100px',
  },
  bgContainer: {
    position: 'relative',
    width: '100%',
  },
  previewText: {
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: 10,
    marginTop: 10,
  },

  stepper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    marginBottom: '10px',
    display: 'inline-block',
    width: '100%',
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '36px',
    float: 'left',
  },
  dividerLine: {
    marginBottom: '10px',
    borderStyle: 'outset',
    width: '100%',
  },
  headerIcon: {
    height: '15px',
    marginRight: '5px',
  },
  headerText: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#015FFB',
    [theme.breakpoints.down('sm')]: {
      fontStyle: 'bold',
      fontWeight: 700,
    },
  },
  creditHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    float: 'right',
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      float: 'left',
      alignItems: 'center',
      color: '#637381',
    },
  },
  limitText: {
    lineHeight: 0.5,
    textAlign: 'end',
    fontWeight: 300,
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: 1,
    },
  },
  limitTextSpan: { fontWeight: 600 },
  limitTextRenewal: {
    fontWeight: 200,
    fontSize: '10px',
    marginTop: 5,
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: 1,
      marginTop: 0,
      marginLeft: 3,
    },
  },
  mobileViewForFinishScreen: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  nonMobileViewForFinishScreen: {
    display: 'block',
    marginTop: '32px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textAlign: {
    textAlign: 'center',
  },
  lastText: {
    textAlign: 'center',
    paddingBottom: '100px',
  },
  redirectCards: {
    borderRadius: 16,
    marginBottom: 16,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lastCard: {
    borderRadius: 0,
    padding: '10px',
  },
  gatewayTooltipStyle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  gatewayTooltipStylePc: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  aiImageIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: 10,
    marginTop: 10,
    color: '#00a350',
  },

  tipDiv: {
    marginTop: '-8px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '32px',
    },
  },
  quizDiv: {
    border: '1px solid #5ff05a',
    padding: 12,
    borderRadius: 10,
    maxWidth: 500,
    marginBottom: '6px',
  },
  quizText: {
    marginBottom: 2,
    fontWeight: 600,
  },
})

export default styles
