const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '-2rem',
    },
  },
  questionViewContainer: {
    background: '#f4f6f8',
    borderRadius: 20,
    marginTop: 16,
    paddingRight: 16,
    justifyContent: 'start',
    alignItems: 'start',
  },
  buttonBarGrid: {
    width: '100%',
    height: '54px',
  },
  questionListRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  badge: {
    display: 'flex',
    height: '25px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#9747ff14',
    width: '38px',
  },
  scormExportUpgradePopupContainer: {
    width: '622px',
    height: '300px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderRadius: '16px',
    overflow: 'hidden',
  },
  scormExportUpgradePopupContainer__right: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  scormExportUpgradePopupContainer__right__header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  text_span: {
    fontSize: '15px',
    fontWeight: 500,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  upgradeButton: {
    width: '210px',
    height: '38px',
    boxShadow: 'none',
    color: '#fff',
    backgroundColor: '#015FFB',
  },
})

export default styles
