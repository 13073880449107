import React, { useEffect } from 'react'

import { withStyles, Button, Tooltip } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { Icon } from '@iconify/react';
import styles from './style'

import propTypes from './prop'

import translator from 'src/translate'

import QuestionCreateLink from '../../../create-link'
import { componentBoxShowAction } from '../../../../../reducers/component-box/action'
import ComponentBoxContent from '../../../../../model/component-box'
import { QuestionFieldNames } from 'src/model/question'

const IconsInform = props => {
  const { classes, entity, dispatch, location } = props

  const element = document.getElementById('main_' + `${entity[QuestionFieldNames.ID]}`)
  const scrollToBottom = () => {
    element.scrollTo(0, element.scrollHeight);
  }
  const addButtonClickHandler = type => {
    const { dispatch } = props
    const component = (
      <QuestionCreateLink
        cardId={entity[QuestionFieldNames.ID]}
        location={location}
        selectedItem={type}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => { },
        }),
      ),
    )
    scrollToBottom()
  }

  return (
    <>
      <Tooltip
        title={translator._('taskItem.addQuestion.link.addLink')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => addButtonClickHandler('url')}
        >
          <Icon icon="ph:link-light" width="256" height="256" className={classes.icon} />
        </Button>
      </Tooltip>
      <Tooltip
        title={translator._('taskItem.addQuestion.link.addPDF')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => addButtonClickHandler('pdf')}
        >
          <Icon icon="material-symbols:picture-as-pdf-outline" width="256" height="256" className={classes.icon} />
        </Button>
      </Tooltip>
    </>
  )
}

IconsInform.propTypes = propTypes

export default withStyles(styles)(IconsInform)
