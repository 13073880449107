const styles = theme => ({
  image__content: {
    width: '100%',
    height: '200px',
    display: 'flex',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '16px 16px 0px 0px',
    position: 'relative',
  },
})

export default styles
