const grid = 8

export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    textAlign: 'left',

    // change background colour if dragging
    background: isDragging ? '#f4f4f4' : 'none',
    //border: "1px solid #e5e5e5",
    borderRadius: 10,
    maxWidth: 500,

    // styles we need to apply on draggables
    ...draggableStyle,
  }
}

export const getSubHeaderItemStyle = (
  isDragging,
  draggableStyle,
  isSelected,
) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 0,
    paddingLeft: 12,
    paddingBottom: 3,
    paddingTop: 3,
    margin: `0 0 ${grid}px 0`,
    textAlign: 'left',

    // change background colour if dragging
    background: isSelected ? 'rgba(145, 158, 171, 0.12)' : 'none',
    //borderRadius:10,

    // styles we need to apply on draggables
    ...draggableStyle,
  }
}
