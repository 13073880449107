import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import SwiperContent from './second-step-comp'
const SecondStep = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <Typography
        fontSize="22px"
        fontWeight={600}
        style={{
          marginTop: '40px',
        }}
      >
        Popular automation scenarios for inspiration
      </Typography>
      <SwiperContent />
    </div>
  )
}

export default withStyles(styles)(SecondStep)
