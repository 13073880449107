import React, { useState } from 'react'

import axios from 'axios'

import {
  TextField,
  withStyles,
  Button,
  Box,
  ImageList,
  Grid,
  ImageListItem,
  Alert,
  CircularProgress,
  Typography,
} from '@material-ui/core'

import styles from './style'
import translator from '../../../translate'
const Unsplash = props => {
  const { classes, onPhotoClick } = props
  const [urlText, setUrlText] = useState('')
  const [images, setImages] = useState([])
  const [loading, dispatchLoading] = useState(0) // 0: nothing, 1: loading, 2: not found
  const unsplashCode = `4I16r0PnR9RHTjdXadlv59qrCNXjYc0kE970b-7WWcQ`

  const getPhotos = () => {
    if (loading == 1) return false
    dispatchLoading(1)
    setImages([])
    axios
      .get('https://api.unsplash.com/search/photos', {
        params: {
          query: urlText,
          per_page: 24,
        },
        headers: {
          Authorization: 'Client-ID ' + unsplashCode,
        },
      })
      .then(output => {
        listPhotos(output?.data?.results)
      })
      .catch(err => {
        console.log(err)
        dispatchLoading(2)
      })
  }

  const listPhotos = e => {
    let newValue = []
    e.forEach(element => {
      newValue.push({
        regular: element['urls']['regular'],
        thumb: element['urls']['thumb'],
        small: element['urls']['small'],
      })
    })

    setImages(newValue)
    dispatchLoading(newValue.length > 0 ? 0 : 2)
    return true
  }
  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      getPhotos()
    }
  }
  return (
    <div className={classes.unsplashPanel}>
      <div className={classes.searchBar}>
        <TextField
          fullWidth={true}
          label="Search Photos"
          className={classes.textField}
          value={urlText}
          onChange={e => setUrlText(e.target.value)}
          onKeyPress={handleKeypress}
        />
        <Box m={0.5} />
        <Button
          variant="contained"
          className={classes.button}
          onClick={getPhotos}
        >
          {translator._('general.search')}
        </Button>
      </div>
      <Box m={0.5} />
      <Grid container className={classes.content}>
        {images.length > 0 ? (
          <Box sx={{ width: 650 }}>
            <ImageList variant="masonry" cols={4} gap={8}>
              {images.map((value, index) => {
                return (
                  <img
                    src={`${value.thumb}`}
                    width={248}
                    className={classes.imageItem}
                    onClick={() => onPhotoClick(value.small)}
                  />
                )
              })}
            </ImageList>
          </Box>
        ) : loading == 2 ? (
          <Alert severity="error">{translator._('mediaGallery.noPhoto')}</Alert>
        ) : (
          loading == 1 && <CircularProgress />
        )}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Unsplash)
