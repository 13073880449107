import React, { useEffect, useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'

import MyDoc from 'src/pages/create-mini-course/carousel-view/full-pdf-download'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { assignmentGetAction } from 'src/reducers/assignment/action'
import { CircularProgress, Typography } from '@material-ui/core'
import { track } from '@amplitude/analytics-browser'

const AiCarouselReadyPopupView = props => {
  const { courseData, dispatch, classes } = props
  const [loading, setLoading] = useState(true)
  const [logo, setLogo] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    dispatch(
      assignmentGetAction(courseData?.id, data => {
        setData(data)
        setLoading(false)
      }),
    )
  }, [courseData])
  let firstCard = data != undefined && {
    text: '<h1 class="coverTitle">' + data?.name + '</h1>',
    type: 1,
  }

  const courseCards = data != undefined && data?.cardDtos

  let cards = data != undefined && [firstCard, ...courseCards]

  const themeSettings =
    data != undefined &&
    JSON.parse(data?.settingDtos.find(x => x.key == 'theme').value)

  return (
    <div className={classes.page}>
      <Typography variant="h4" style={{ marginTop: 18, marginBottom: 16 }}>
        Download Carousel PDF
      </Typography>
      <Typography style={{ textAlign: 'center' }}>
        This PDF is optimized for sharing your mini-course as LinkedIn carousel
        post. You can increase the content engagement by adding images,
        questions, and customizing the appearance on your dashboard.
      </Typography>
      {!loading && data && (
        <PDFDownloadLink
          style={{
            margin: 16,
            display: 'block',
            textDecoration: 'none',
            color: 'white',
            padding: 8,
            paddingLeft: 24,
            paddingRight: 24,
            borderRadius: 8,
            fontWeight: 600,
            background: '#015FFB',
            width: 250,
            marginRight: 6,
            textAlign: 'center',
          }}
          onClick={() => {
            track('mobile_lc_download')
          }}
          document={
            <MyDoc cards={cards} themeSettings={themeSettings} logo={logo} />
          }
          fileName={data?.name + '.pdf'}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Creating document...' : 'Download'
          }
        </PDFDownloadLink>
      )}
    </div>
  )
}

export default withStyles(styles)(AiCarouselReadyPopupView)
