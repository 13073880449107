import {
  TEAMS_USER_LIST,
  TEAMS_USER_LIST_SUCCESS,
  TEAMS_USER_LIST_ERROR,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  TEAM_LIST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_ERROR,
  PATCH_TEAM,
  PATCH_TEAM_SUCCESS,
  PATCH_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  TEAMS_MEMBER_LIST,
  TEAMS_MEMBER_LIST_SUCCESS,
  TEAMS_MEMBER_LIST_ERROR,
  TEAMS_MEMBER_DELETE,
  TEAMS_MEMBER_DELETE_SUCCESS,
  TEAMS_MEMBER_DELETE_ERROR,
  TEAMS_MEMBER_PATCH,
  TEAMS_MEMBER_PATCH_SUCCESS,
  TEAMS_MEMBER_PATCH_ERROR,
  TEAMS_MEMBER_ADD,
  TEAMS_MEMBER_ADD_SUCCESS,
  TEAMS_MEMBER_ADD_ERROR,
  TEAMS_MEMBER_EXPORT,
  TEAMS_MEMBER_EXPORT_SUCCESS,
  TEAMS_MEMBER_EXPORT_ERROR,
  TEAMS_MEMBER_IMPORT,
  TEAMS_MEMBER_IMPORT_SUCCESS,
  TEAMS_MEMBER_IMPORT_ERROR,
  SEND_MAIL_TO_LEARNER,
  SEND_MAIL_TO_LEARNER_SUCCESS,
  SEND_MAIL_TO_LEARNER_ERROR,
} from './type'

/**
 *
 *@param {*} callback
 */
export const teamListAction = (emptyItem, callback) => {
  return {
    type: TEAM_LIST,
    payload: { callback },
  }
}

/**
 *
 * @param {*} response
 */
export const teamListSuccess = response => {
  return {
    type: TEAM_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamListError = () => {
  return {
    type: TEAM_LIST_ERROR,
  }
}

/**
 *
 * @param {*} query
 */
export const teamsUserListAction = query => {
  return {
    type: TEAMS_USER_LIST,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const teamsUserListSuccess = response => {
  return {
    type: TEAMS_USER_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamsUserListError = () => {
  return {
    type: TEAMS_USER_LIST_ERROR,
  }
}

/**
 *
 * @param {*} name
 */
export const addTeamAction = name => {
  return {
    type: ADD_TEAM,
    payload: { name },
  }
}

/**
 *
 * @param {*} response
 */
export const addTeamSuccess = response => {
  return {
    type: ADD_TEAM_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const addTeamError = () => {
  return {
    type: ADD_TEAM_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const patchTeamAction = (content, callback) => {
  return {
    type: PATCH_TEAM,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const patchTeamSuccess = content => {
  return {
    type: PATCH_TEAM_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const patchTeamError = () => {
  return {
    type: PATCH_TEAM_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} callback
 */
export const deleteTeamAction = (id, callback) => {
  return {
    type: DELETE_TEAM,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} id
 */
export const deleteTeamSuccess = id => {
  return {
    type: DELETE_TEAM_SUCCESS,
    payload: { id },
  }
}

/**
 *
 */
export const deleteTeamError = () => {
  return {
    type: DELETE_TEAM_ERROR,
  }
}

/**
 *
 * @param {*} query
 * @param {*} id
 * @param {*} callback
 */
export const teamsMemberListAction = (query, id, callback) => {
  return {
    type: TEAMS_MEMBER_LIST,
    payload: { query, id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const teamsMemberListSuccess = response => {
  return {
    type: TEAMS_MEMBER_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamsMemberListError = () => {
  return {
    type: TEAMS_MEMBER_LIST_ERROR,
  }
}

/**
 *
 * @param {*} teamsId
 * @param {*} memberId
 * @param {*} callback
 */
export const teamsMemberDeleteAction = (teamsId, memberId, callback) => {
  return {
    type: TEAMS_MEMBER_DELETE,
    payload: { teamsId, memberId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const teamsMemberDeleteSuccess = response => {
  return {
    type: TEAMS_MEMBER_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamsMemberDeleteError = () => {
  return {
    type: TEAMS_MEMBER_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} content
 * @param {*} teamsId
 * @param {*} memberId
 * @param {*} callback
 */
export const patchTeamsMemberAction = (
  teamsId,
  memberId,
  content,
  callback,
) => {
  return {
    type: TEAMS_MEMBER_PATCH,
    payload: { teamsId, memberId, content, callback },
  }
}

/**
 *
 *
 */
export const patchTeamsMemberSuccess = (teamsId, memberId, content) => {
  return {
    type: TEAMS_MEMBER_PATCH_SUCCESS,
    payload: { teamsId, memberId, content },
  }
}

/**
 *
 */
export const patchTeamsMemberError = () => {
  return {
    type: TEAMS_MEMBER_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} teamsId
 * @param {*} teamMembers
 * @param {*} callback
 */
export const addTeamsMemberAction = (teamsId, teamMembers, callback) => {
  return {
    type: TEAMS_MEMBER_ADD,
    payload: { teamsId, teamMembers, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const addTeamsMemberSuccess = response => {
  return {
    type: TEAMS_MEMBER_ADD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const addTeamsMemberError = () => {
  return {
    type: TEAMS_MEMBER_ADD_ERROR,
  }
}

/**
 *
 * @param {*} teamsId
 * @param {*} callback
 */
export const teamsMemberExportAction = (teamsId, callback) => {
  return {
    type: TEAMS_MEMBER_EXPORT,
    payload: { teamsId, callback },
  }
}

export const teamsMemberExportSuccess = response => {
  return {
    type: TEAMS_MEMBER_EXPORT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamsMemberExportError = () => {
  return {
    type: TEAMS_MEMBER_EXPORT_ERROR,
  }
}

/**
 *
 * @param {*} file
 * @param {*} teamsId
 * @param {*} callback
 */
export const teamsMemberImportAction = (file, teamsId, callback) => {
  return {
    type: TEAMS_MEMBER_IMPORT,
    payload: { file, teamsId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const teamsMemberImportSuccess = response => {
  return {
    type: TEAMS_MEMBER_IMPORT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const teamsMemberImportError = () => {
  return {
    type: TEAMS_MEMBER_IMPORT_ERROR,
  }
}

/**
 *
 * @param {*} collectionId
 * @param {*} callback
 */

export const sendMailToLearnerAction = (
  collectionId,
  sendLinkToNewMembersOnly,
  callback,
) => {
  return {
    type: SEND_MAIL_TO_LEARNER,
    payload: { collectionId, sendLinkToNewMembersOnly, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const sendMailToLearnerSuccess = response => {
  return {
    type: SEND_MAIL_TO_LEARNER_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const sendMailToLearnerError = () => {
  return {
    type: SEND_MAIL_TO_LEARNER_ERROR,
  }
}
