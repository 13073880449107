import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
  smallAvatar: {
    width: 44,
    height: 44,
    padding: theme.spacing(1),
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
  },
}))

function MyAvatar(props) {
  const { text } = props
  const classes = useStyles()

  return <Avatar className={classes.smallAvatar}>{text}</Avatar>
}

export default MyAvatar
