import React, {useEffect} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { get as getCookie } from 'es-cookie'
import { track } from '@amplitude/analytics-browser';

const PrivateRoute = ({ component: Component, ...rest }) => {

  

  useEffect(() => {
    
    track('Page View', {
      name: window.location.pathname,
    });
  });

  return (
    <Route
      {...rest}
      render={props =>
        getCookie('token') != null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
