import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { BoxView, FullView } from './thir-step-comp'
const ThirdStep = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <FullView />
    </div>
  )
}

export default withStyles(styles)(ThirdStep)
