import React, { useEffect, useState, useRef, useCallback } from 'react'
// @mui
import { Box } from '@material-ui/core'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'
import KanbanTaskCard from 'src/components/kanban/KanbanCourseCard'

// components
import Page from '../../components/Page'
// sections
import { KanbanColumn, KanbanColumnAdd } from 'src/components/kanban'
import { editCourseCollectionIndexAction } from 'src/reducers/course-collection/action'
import { LimitTypeEnum } from 'src/model/limit'
import { getUserLimits } from 'src/api/limit'
import { isMobile } from 'react-device-detect'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

import { domainListAction } from 'src/reducers/domain/action'
import { MobileView, DndItems } from './components'

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable'
import { get as getCookie } from 'es-cookie'
import { set as setCookie } from 'es-cookie'
// ----------------------------------------------------------------------

const DEFAULT_COLLECTION_GUID = '00000000-0000-0000-0000-000000000000'

const defaultCollection = {
  id: DEFAULT_COLLECTION_GUID,
  collectionName: 'Independent Mini-Courses',
  collectionIndex: 0,
  isDeleted: false,
  userId: '',
  description: '',
  coverImageUrl: '',
}

function Kanban(props) {
  const { history, classes } = props
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      getCookie('redirectUrl') != '' &&
      getCookie('redirectUrl') != null &&
      getCookie('redirectUrl') != undefined
    ) {
      const url = getCookie('redirectUrl')
      setCookie('redirectUrl', '')
      window.location.href = `/${url}`
    }
  }, [])

  useEffect(() => {
    const queryCollection = {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }

    const queryDomainList = {
      CurrentPage: 0,
      PageSize: 200,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }

    dispatch(
      courseCollectionListAction(queryCollection, () =>
        dispatch(domainListAction(queryDomainList)),
      ),
    )
  }, [])

  const [viewType, setViewType] = useState('gallery')
  const [isUserReachedCourseLimit, setIsUserReachedCourseLimit] = useState(
    'Initial',
  )
  const [
    hasCoursesWithOutCollection,
    setHasCoursesWithOutCollection,
  ] = useState(false)

  const value = useSelector(state => state.courseCollectionList.list)
  const { courseCollectionDtos: collections = [], courseDtos = [] } = value
  const data = {
    courseDtos: [...courseDtos],
    courseCollectionDtos: [...collections],
  }

  const groupCoursesByCollection = (courseDtos, collections) => {
    const collectionsMap = new Map()

    // Initialize collections map with empty arrays for each collection
    collections.forEach(collection => {
      collectionsMap.set(collection.id, { ...collection, courses: [] })
    })

    // Iterate over courseDtos and group them under their respective collection
    courseDtos.forEach(course => {
      if (collectionsMap.has(course.courseCollectionId)) {
        collectionsMap.get(course.courseCollectionId).courses.push(course)
      }
    })

    collectionsMap.values().length > 0 &&
      collectionsMap.values().map(x =>
        x.courses.sort((a, b) => {
          return a.courseCollectionOrder - b.courseCollectionOrder
        }),
      )

    //for independent courses
    const independentCourses = courseDtos.filter(
      course => course.courseCollectionId == null,
    )
    if (independentCourses.length > 0) {
      collectionsMap.set(DEFAULT_COLLECTION_GUID, {
        ...defaultCollection,
        courses: independentCourses,
      })
    }

    // Convert the map back to an array
    return Array.from(collectionsMap.values())
  }

  const groupedData = groupCoursesByCollection(
    data.courseDtos,
    data.courseCollectionDtos,
  )
  const [containers, setContainers] = useState(groupedData)
  const [activeId, setActiveId] = useState(null)
  const ref = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [changedCourses, setChangedCourses] = useState([])

  useEffect(() => {
    if (courseDtos && courseDtos.length > 0) {
      const filteredCourses = courseDtos.filter(
        x => x.courseCollectionId == null,
      )
      setHasCoursesWithOutCollection(filteredCourses.length > 0 ? true : false)
    }
  }, [courseDtos, collections])

  useEffect(() => {
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).limit.upperLimit

      count >= limit && limit != -1
        ? setIsUserReachedCourseLimit(true)
        : setIsUserReachedCourseLimit(false)
    })
  }, [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  function findValueOfItems(id, type) {
    if (type === 'container') {
      // Directly return the container if it's found
      return containers.find(container => container.id === id)
    } else if (type === 'item') {
      // Loop through each container to find the item
      for (const container of containers) {
        const item = container.courses.find(x => x.id == id)

        if (item) {
          // Return the found item, not the container

          return container
        }
      }
    }
    return null // Return null if nothing is found
  }

  const handleDragStart = useCallback(
    event => {
      setIsDragging(true)
      const { active } = event
      const { id } = active
      activeId == null && setActiveId(id)
    },
    [activeId],
  )

  const handleDragMove = useCallback(
    event => {
      const { active, over } = event
      // Handle Items Sorting
      if (activeId !== active.id) return

      if (
        over.id.toString().includes('empty-item-') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const emptyActiveContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
        )

        const emptyOverContainer = findValueOfItems(
          over.id.split('empty-item-')[1],
          'container',
        )

        if (!emptyActiveContainer || !emptyOverContainer) return
        const activeContainerIndex = containers.findIndex(
          container => container.id === emptyActiveContainer.id,
        )
        const emptyOverContainerIndex = containers.findIndex(
          container => container.id === emptyOverContainer.id,
        )
        const activeitemIndex = emptyActiveContainer.courses.findIndex(
          x => x.id == active.id.split('-item')[0],
        )

        let newItems = [...containers]
        const [removeditem] = newItems[activeContainerIndex].courses.splice(
          activeitemIndex,
          1,
        )
        newItems[emptyOverContainerIndex].courses.push(removeditem)

        let changedCoursesInit = []
        for (const collection of newItems) {
          for (const course of collection.courses) {
            changedCoursesInit.push({
              ...course,
              courseCollectionId: collection.id,
              courseCollectionOrder: collection.courses.indexOf(course),
              courseCollectionDto: collections.find(x => x.id == collection.id),
            })
          }
        }
        setChangedCourses(changedCoursesInit)
        newItems.map(x =>
          x.courses.sort((a, b) => {
            return a.courseCollectionOrder - b.courseCollectionOrder
          }),
        )
        setContainers(newItems)
        return
      }

      if (
        active.id.toString().includes('item') &&
        over?.id.toString().includes('item') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        // Find the active container and over container
        const activeContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
        )
        const overContainer = findValueOfItems(
          over.id.split('-item')[0],
          'item',
        )
        if (overContainer.id === DEFAULT_COLLECTION_GUID) return
        // If the active or over container is not found, return
        if (!activeContainer || !overContainer) return

        // Find the index of the active and over container

        const activeContainerIndex = containers.findIndex(
          container => container.id === activeContainer.id,
        )
        const overContainerIndex = containers.findIndex(
          container => container.id === overContainer.id,
        )

        // Find the index of the active and over item
        const activeitemIndex = activeContainer.courses.findIndex(
          item => item.id.toString() === active.id.split('-item')[0],
        )
        const overitemIndex = overContainer.courses.findIndex(
          item => item.id.toString() === over.id.split('-item')[0],
        )

        // In the same container
        if (activeContainerIndex === overContainerIndex) {
          let newItems = [...containers]

          newItems[activeContainerIndex].courses = arrayMove(
            newItems[activeContainerIndex].courses,
            activeitemIndex,
            overitemIndex,
          )
          let changedCoursesInit = []

          for (const course of activeContainer.courses) {
            changedCoursesInit.push({
              ...course,
              courseCollectionId: activeContainer.id,
              courseCollectionOrder: activeContainer.courses.indexOf(course),
              courseCollectionDto: collections.find(
                x => x.id == activeContainer.id,
              ),
            })
          }

          setChangedCourses(changedCoursesInit)
          // Change courses courseCollectionOrder based on changes with index
          newItems.map(x =>
            x.courses.sort((a, b) => {
              return a.courseCollectionOrder - b.courseCollectionOrder
            }),
          )
          setContainers(newItems)
        } else {
          // In different containers

          let newItems = [...containers]
          const [removeditem] = newItems[activeContainerIndex].courses.splice(
            activeitemIndex,
            1,
          )
          newItems[overContainerIndex].courses.splice(
            overitemIndex,
            0,
            removeditem,
          )

          let changedCoursesInit = []
          for (const collection of newItems) {
            for (const course of collection.courses) {
              changedCoursesInit.push({
                ...course,
                courseCollectionId: collection.id,
                courseCollectionOrder: collection.courses.indexOf(course),
                courseCollectionDto: collections.find(
                  x => x.id == collection.id,
                ),
              })
            }
          }
          setChangedCourses(changedCoursesInit)
          newItems.map(x =>
            x.courses.sort((a, b) => {
              return a.courseCollectionOrder - b.courseCollectionOrder
            }),
          )
          setContainers(newItems)
        }
        return
      }
    },
    [activeId],
  )

  // This is the function that handles the sorting of the containers and items when the user is done dragging.
  function handleDragEnd() {
    if (changedCourses.length !== 0) {
      dispatch(
        editCourseCollectionIndexAction({
          courseDtos: changedCourses,
        }),
      )
      setActiveId(null)
      setIsDragging(false)
      setChangedCourses([])
    }
  }
  // Find the value of the items

  const findCourseById = courseId => {
    for (const collection of containers) {
      const foundCourse = collection.courses.find(
        course => course.id.toString() === courseId,
      )
      if (foundCourse) {
        return foundCourse
      }
    }
    return null // Return null if no course is found
  }
  useEffect(() => {
    setContainers(groupedData)
  }, [value])

  return (
    <Page title="Mini Course Generator">
      {isMobile && <MobileView />}

      <div>
        <div className={classes.header_bar}>
          <div className={classes.addColumnHead}>
            <KanbanColumnAdd />
          </div>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={event => {
            handleDragStart(event)
          }}
          onDragEnd={event => {
            handleDragEnd(event)
          }}
          onDragMove={event => {
            handleDragMove(event)
          }}
        >
          <SortableContext items={containers.map(i => `${i.id}-container`)}>
            {containers.map((collection, index) => {
              collection.courses.sort((a, b) => {
                return a.courseCollectionOrder - b.courseCollectionOrder
              })
              return (
                <KanbanColumn
                  courses={collection.courses}
                  key={collection.id}
                  column={{
                    collection: collection,
                    name: collection.collectionName,
                    cardIds: [],
                    id: collection.id,
                    collectionIndex: index, //collection.collectionIndex,
                  }}
                  history={history}
                  isUserReachedCourseLimit={isUserReachedCourseLimit}
                  setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
                  viewType={viewType}
                />
              )
            })}
          </SortableContext>
        </DndContext>
        {/* <DragDropContext onDragEnd={onDrag}>
          {hasCoursesWithOutCollection && (
            <NonCollectionCourses
              collections={collections}
              courseDtos={courseDtos}
              history={history}
              isUserReachedCourseLimit={isUserReachedCourseLimit}
              setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
              viewType={viewType}
            />
          )}
          </DragDropContext>*/}
        <div className={classes.addColumnBottom}>
          <KanbanColumnAdd />
        </div>
        <DragOverlay adjustScale={false}>
          {/* Drag Overlay For item Item */}
          {activeId && activeId.toString().includes('-item') && (
            <DndItems id={activeId}>
              <KanbanTaskCard
                course={findCourseById(activeId.split('-item')[0])}
                history={history}
                setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
                isUserReachedCourseLimit={isUserReachedCourseLimit}
                viewType={viewType}
              />
            </DndItems>
          )}
        </DragOverlay>
        <Box m={2}></Box>
      </div>
    </Page>
  )
}
export default withStyles(styles)(Kanban)
export { DEFAULT_COLLECTION_GUID }
