import React from 'react'
import { Alert, Box, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles'
const MobileView = props => {
  const { classes } = props
  return (
    <>
      <Alert severity="warning" className={classes.mobile_view_alert}>
        We recommend creating & editing mini-courses on desktop browsers.
      </Alert>
      <Box className={classes.mobile_view_box}></Box>
    </>
  )
}

export default withStyles(styles)(MobileView)
