const styles = theme => ({
  iconButton: {
    width: '32px',
    minWidth: '32px',
    height: '32px'
  },
  icon: {
      color: 'black'
  }
})

export default styles
