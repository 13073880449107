import React from 'react'
import styles from './style'

import { Grid, Typography, withStyles } from '@material-ui/core'

import { questionAddAction } from 'src/reducers/question/action'
import {
  getQuestionTypeParam,
  QuestionFieldNames,
  QuestionSchema,
} from 'src/model/question'
import { useCourse } from 'src/hooks/useCourseSettings'
import { track } from '@amplitude/analytics-browser'
import CardTypes from 'src/model/card-types'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import BasicTypes from './options/basics'
import MultipleChoice from './options/multiple-choice'
import Feedback from './options/feedback'
import Completions from './options/completions'
import QuizSelection from './options/quiz'
import QuizSurvey from './options/quiz-survey'
const CardSelectionPopup = ({
  classes,
  dispatch,
  onAddCard,
  type,
  section,
}) => {
  const baseEntity = useCourse()

  const saveType = async selectedType => {
    let entity = QuestionSchema()
    let list = []
    entity = getQuestionTypeParam(selectedType, entity)
    if (section && section.id !== 0) {
      entity[QuestionFieldNames.SECTION] = section
    }

    await dispatch(
      questionAddAction(entity, baseEntity.id, response => {
        const newTaskObject = { ...response, isNewAdded: true }
        list.push(newTaskObject)
        onAddCard(list)
      }),
    )

    track('create_card', { cardType: CardTypes[selectedType] })
    dispatch(componentBoxHideAction())
  }

  return (
    <Grid container className={classes.root}>
      <Typography variant="subtitle1" className={classes.header}>
        Select Card Type
      </Typography>
      <div className={classes.cardContainer}>
        {[BasicTypes, QuizSurvey, Feedback, Completions].map(
          (Component, idx) => (
            <Grid key={idx} item className={classes.cardItem}>
              <Component saveType={saveType} entity={baseEntity} />
            </Grid>
          ),
        )}
      </div>
    </Grid>
  )
}

export default withStyles(styles)(CardSelectionPopup)
