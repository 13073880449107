const styles = theme => ({
  container: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    borderRadius: 8,
    gap: 16,
  },
  inputRow: {
    display: 'flex',
    gap: 16,
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  charCount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  input: {},
  heading: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
  },
  badgeStyleHeading: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#637381',
  },
  tipMessage: {
    fontWeight: 400,
    fontSize: '12px !important',
    color: '#637381',
    textAlign: 'center',
  },
  settingDrawerOpenSpan: {
    fontWeight: 400,
    fontSize: '12px !important',
    color: '#015FFB',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textField: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    '& label': {},
    '& label.Mui-focused': {},
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#015FFB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#015FFB',
      },
    },
  },
  charCountText: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#637381',
  },
  modal: {
    display: 'flex !important',
  },
  modalContent: {
    height: '437px',
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
    borderRadius: '16px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    '&:focus': {
      outline: 'none!important',
    },
  },
  premiumBadges: {
    display: 'flex',
    height: '20px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
