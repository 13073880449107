import React from 'react'

import { withStyles, Button, Tooltip } from '@material-ui/core'
import { Icon } from '@iconify/react';
import styles from './style'

import propTypes from './prop'

import translator from 'src/translate'

import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { HELPER_URL } from 'src/model/payment'

import ModalContent from 'src/components/card-item/bottom-bar/button-actions/sertifier'
import { guid } from 'src/utils/guid'

import { QuestionFieldNames } from 'src/model/question'

function SertifierForm(props) {
  const { classes, entity, dispatch, location } = props

  const settingsModalClickHandler = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(<ModalContent classes={classes} cardId={entity[QuestionFieldNames.ID]} key={guid.uid} />, {
          hideCloseButton: false,
          header: translator._('taskItem.sertifier.header'),
          callback: e => { },
        }),
      ),
    )
  }

  return (
    <>
      <Tooltip
        title={translator._('taskItem.sertifier.settings')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => settingsModalClickHandler()}
        >
          <Icon icon="ion:settings-outline" width="18" height="19px" className={classes.icon} />
        </Button>
      </Tooltip>

    </>
  )
}



export default withStyles(styles)(SertifierForm)
