import React, { useState, useRef } from 'react'
import styles from './styles'
import { Typography, withStyles, Button } from '@material-ui/core'
import content from './content'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller, Pagination, Navigation, A11y } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const OnboardingInformation = props => {
  const { pageView, setPageView, handlePageView, name, classes } = props
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const swiperRef = useRef(null)

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }
  return (
    <div className={classes.onBoardingRoot}>
      {pageView == 1 ? (
        <div
          className={classes.onBoardingRoot_leftSide_first}
          style={{
            backgroundImage: `url(/assets/onboarding-background-1.png)`,
          }}
        >
          <img
            src={`/assets/onboarding-1.png`}
            alt="pic-1"
            className={classes.onBoardingRoot_leftSide_middleImage}
          ></img>
        </div>
      ) : (
        <Swiper
          // Install modules
          ref={swiperRef}
          pagination={{ clickable: true }}
          modules={[Pagination, Controller]}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={swiper => setPageView(swiper.activeIndex + 2)}
          scrollbar={{ draggable: true }}
          onSwiper={setControlledSwiper}
          controller={{ control: controlledSwiper }}
          style={{
            width: '50%',
          }}
        >
          <SwiperSlide>
            <div
              className={classes.onBoardingRoot_leftSide}
              style={{
                backgroundImage: `url(/assets/onboarding-background-2.png)`,
              }}
            >
              <img
                src={`/assets/onboarding-2.png`}
                alt="pic-1"
                className={classes.onBoardingRoot_leftSide_middleImage}
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className={classes.onBoardingRoot_leftSide}
              style={{
                backgroundImage: `url(/assets/onboarding-background-3.png)`,
              }}
            >
              <img
                src={`/assets/onboarding-3.png`}
                alt="pic-1"
                className={classes.onBoardingRoot_leftSide_middleImage}
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className={classes.onBoardingRoot_leftSide}
              style={{
                backgroundImage: `url(/assets/onboarding-background-4.png)`,
              }}
            >
              <img
                src={`/assets/onboarding-4.png`}
                alt="pic-1"
                className={classes.onBoardingRoot_leftSide_middleImage}
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className={classes.onBoardingRoot_leftSide}
              style={{
                backgroundImage: `url(/assets/onboarding-background-5.png)`,
                alignItems: 'center',
              }}
            >
              <img
                src={`/assets/onboarding-5.png`}
                alt="pic-1"
                className={classes.onBoardingRoot_leftSide_middleImage}
              ></img>
            </div>
          </SwiperSlide>

          {/* ... */}
        </Swiper>
      )}

      <div className={classes.onBoardingRoot_rightSide}>
        <div className={classes.onBoardingRoot_rightSide_text}>
          {pageView == 1 ? (
            <Typography variant="h2">Welcome {name},</Typography>
          ) : (
            <Typography fontSize="15px" fontWeight={500}>
              {pageView - 1} / 4{' '}
            </Typography>
          )}
          {pageView == 1 ? (
            <Typography
              fontSize="16px"
              fontWeight={500}
              className={classes.onBoardingRoot_padding_bottom}
            >
              some quick tips for you💡
            </Typography>
          ) : (
            <Typography variant="h2">{content[pageView - 1].header}</Typography>
          )}

          <Typography fontSize="15px" fontWeight={500}>
            {content[pageView - 1].explanation}
          </Typography>
          <div className={classes.onBoarding_FX}></div>
          <div className={classes.onBoarding_buttonGroup}>
            {content[pageView - 1].skip && (
              <Button
                className={classes.onBoardingRoot_skipButton}
                variant="text"
                onClick={() => setPageView(6)}
              >
                Skip
              </Button>
            )}

            <Button
              variant="contained"
              className={classes.onBoardingRoot_nextButton}
              onClick={() => {
                handlePageView()
                goNext()
              }}
            >
              {content[pageView - 1].buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(OnboardingInformation)
