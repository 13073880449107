import React, { useEffect, useState } from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from 'react-redux'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import URLs from 'src/api/url'
import ComponentBoxContent from 'src/model/component-box'
import CertificateList from '../certificates-list'
import { patchCertificateAction } from 'src/reducers/certificate/action'
import { PatchContent, PatchData } from 'src/model/patch'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import SimpleHtmlTextEditor from 'src/components/simple-text-editor'
const CertificateEdit = props => {
  const { classes, logoUrl } = props
  const dispatch = useDispatch()

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const collection = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )
  const certificateParent = useSelector(state => state.certificate)
  const certificate = certificateParent.certificate

  const [html, setHtml] = useState(certificate ? certificate.html : '')

  const editHtmlValue = html => {
    setHtml(html)
    const content = new PatchData(
      new PatchContent(html, 'html', PatchData.OPERATION_REPLACE),
      certificate.id,
    )
    dispatch(patchCertificateAction(content))
    dispatch(componentBoxHideAction())
  }

  useEffect(() => {
    const collectionLogo =
      collection[0].logoUrl === null || collection[0].logoUrl === ''
        ? 'assets/gateway/empty-logo.png'
        : collection[0].logoUrl

    const logo = URLs.CDN + collectionLogo

    const certificateLogo = document.getElementById('certificate-logo')
    if (certificateLogo) {
      certificateLogo.style.backgroundImage = 'url(' + logo + ')'
    }
  }, [collection])

  const handleOpenCertificationList = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <CertificateList editHtmlValue={editHtmlValue} />,
          {
            hideCloseButton: true,
          },
        ),
      ),
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.customizeText}>
          Customize Certificate
        </Typography>
        <div className={classes.button} onClick={handleOpenCertificationList}>
          <Icon icon="tabler:refresh" fontSize={'16px'} />
          <Typography className={classes.buttonText}>
            Change Template
          </Typography>
        </div>
      </div>

      <SimpleHtmlTextEditor
        className={classes.templateArea}
        html={html}
        onBlur={value => {
          editHtmlValue(value)
        }}
      ></SimpleHtmlTextEditor>
    </div>
  )
}

export default withStyles(styles)(CertificateEdit)
