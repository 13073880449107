import React from 'react'
import { Typography } from '@material-ui/core'
const Header = () => {
  return (
    <div>
      <Typography fontSize={'16px'} fontWeight={500}>
        Analytics
      </Typography>
    </div>
  )
}

export default Header
