const styles = theme => ({
  modal: {
    display: 'flex !important',
    height: 'max-content',
    width: 'max-content',
    margin: 'auto',
    border: 'none',
    outline: 'none',
    borderRadius: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
    overflow: 'auto',
    maxHeight: '90vh',
    //padding: theme.spacing(3),
  },
  backdrop: {
    backdropFilter: 'blur(2px)',
  },
  modalContent: {
    borderRadius: theme.shape.borderRadiusMd,
    height: 'fit-content',
    width: 'max-content',
    minWidth: '30vw',
    zIndex: 1300,
    border: 'none',
    outline: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  modalTop: {
    display: 'flex',
    flexDirection: 'row',
  },
  modalLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px !important',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingTop: '0',
    gap: '.8rem',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    marginRight: '.7rem',
    marginTop: '.7rem',
    zIndex: 1999,
  },
  headerGroup: {
    margin: theme.spacing(3),
  },
  group: {
    height: 112,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      height: 'auto',
    },
  },
  groupTitleCard: {
    alignItems: 'center',
    backgroundColor: '#F4F6F7',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    height: 112,
    justifyContent: 'space-evenly',
    width: 197,
  },
})

export default styles
