import 'lazysizes'
import 'src/utils/highlight'
import 'simplebar/src/simplebar.css'
import 'slick-carousel/slick/slick.css'
import 'react-image-lightbox/style.css'
import 'react-quill/dist/quill.snow.css'
import 'slick-carousel/slick/slick-theme.css'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import App from './app'
import React from 'react'
import ReactDOM from 'react-dom'

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('root'))
