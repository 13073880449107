import React from 'react'
import { Typography, Tooltip, withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import styles from './styles'
import clsx from 'clsx'
const AnalyticsCard = props => {
  const { item, type, classes } = props
  return (
    <div key={item.cardName} className={classes.card}>
      <div className={classes.header}>
        <Typography
          className={clsx({
            [classes.cardName]: type == 'collection',
            [classes.cardNameCourse]: type == 'course',
          })}
        >
          {item.cardName}
        </Typography>
        <Tooltip title={item.tooltipMessage}>
          <Icon
            icon="gg:info"
            className={clsx({
              [classes.icon]: type == 'collection',
              [classes.iconCourse]: type == 'course',
            })}
          />
        </Tooltip>
      </div>
      <div className={classes.content}>
        <Typography
          className={clsx({
            [classes.value]: type == 'collection',
            [classes.valueCourse]: type == 'course',
          })}
        >
          {item.value}
        </Typography>
        <img
          src={item.image}
          className={clsx({
            [classes.image]: type == 'collection',
            [classes.imageCourse]: type == 'course',
          })}
          alt="badge"
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(AnalyticsCard)
