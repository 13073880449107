import { put, call, take, fork } from 'redux-saga/effects'
import { patchCertificateService } from 'src/api/certificate'
import { patchCertificateSuccess } from 'src/reducers/certificate/action'
import { PATCH_CERTIFICATE } from 'src/reducers/certificate/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* certificatePatchFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(patchCertificateService, content)
    yield put(patchCertificateSuccess(content))
    yield put(operationSuccess())
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* patchCertificateWatcher() {
  while (true) {
    const action = yield take(PATCH_CERTIFICATE)
    const { payload } = action

    yield fork(generalSaga, certificatePatchFlow, payload)
  }
}
