import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import SubHeader from './sub-header'
import { withStyles, styled } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
import { getItemStyle } from 'src/utils/dnd/getCardTitleItemStyle'
import { Typography, TextField, Box, Button, Divider } from '@material-ui/core'
import styles from './styles'
import Iconify from 'src/components/Iconify'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'

import {
  questionAddAction,
  questionPatchAction,
} from '../../../reducers/question/action'

import { PatchContent, PatchData } from '../../../model/patch'
import {
  AnswerItemType,
  QuestionFieldNames,
  QuestionSchema,
  getQuestionTypeParam,
} from 'src/model/question'
import {
  createSectionWithCardIdsService,
  sectionPatchService,
} from 'src/api/assignment'

import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import CardSelectionPopup from 'src/components/card-list/card-selection-popup'
import { AssignmentFieldNames } from 'src/model/assignment'
import {
  assignmentGetAction,
  generateQuizzesByCourseIdAction,
} from 'src/reducers/assignment/action'
import translator from 'src/translate'

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: '0px !important',
  },

  '& .MuiInput-underline:after': {
    borderBottom: '0px',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: 600,
  },
})

class Outline extends Component {
  constructor(props) {
    super(props)

    this.state = this.props
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleSubHeaderItemSave = this.handleSubHeaderItemSave.bind(this)
    this.handleHeaderItemChange = this.handleHeaderItemChange.bind(this)
  }

  onDragEnd(result) {
    const { outline, onDrag, entity } = this.props

    let oldIndexArray = []
    let newIndexArray = []
    let destinationSectionIdArray = []

    if (result.type == 'HEADERS') {
      const firstId = outline[result.destination.index].subHeaders[0].id
      const firstIndex = entity[AssignmentFieldNames.QUESTION_DTOS].findIndex(
        x => x.id == firstId,
      )

      outline[result.source.index].subHeaders.forEach(async (item, index) => {
        const oldIndex = entity[AssignmentFieldNames.QUESTION_DTOS].findIndex(
          x => x.id == item.id,
        )

        const newIndex = firstIndex + index

        oldIndexArray.push(oldIndex)
        newIndexArray.push(newIndex)
        destinationSectionIdArray.push(null)
      })
    } else {
      const sourceSectionId = result.source.droppableId.split('droppable')[1]
      const sourceCardIndex = result.source.index

      const id = outline.find(x => x.id == sourceSectionId).subHeaders[
        sourceCardIndex
      ].id
      const oldIndex = entity[AssignmentFieldNames.QUESTION_DTOS].findIndex(
        x => x.id == id,
      )

      const destinationSectionId = result.destination.droppableId.split(
        'droppable',
      )[1]
      const destinationCardIndex = result.destination.index

      const sourceSectionFirstItemId = outline.find(
        x => x.id == destinationSectionId,
      ).subHeaders[0].id
      const sourceSectionFirstItemIndex = entity[
        AssignmentFieldNames.QUESTION_DTOS
      ].findIndex(x => x.id == sourceSectionFirstItemId)

      let newIndexOfItem = sourceSectionFirstItemIndex + destinationCardIndex
      /*  if (oldIndex < newIndexOfItem) {
        
        newIndexOfItem = newIndexOfItem - 1
      } */

      oldIndexArray.push(oldIndex)
      newIndexArray.push(newIndexOfItem)
      destinationSectionIdArray.push(destinationSectionId)
    }

    onDrag(oldIndexArray, newIndexArray, destinationSectionIdArray, true)
  }

  inputFocus = id => {
    document.getElementById(id).focus()
  }

  handleHeaderItemChange = (e, index) => {
    const { entity, outline, dispatch, saveOutline } = this.props
    const name = e.target.value
    let newHeaders = [...outline]
    const ids = newHeaders[index].subHeaders.map(value => value.id)

    if (newHeaders[index].id == 0) {
      createSectionWithCardIdsService(entity.id, name, ids).then(response => {
        let newHeaders = [...outline]
        newHeaders[index].header = name
        newHeaders[index].id = response.data.id

        newHeaders[index].subHeaders.forEach(element => {
          element.sectionName = response.data.name
          element.sectionId = response.data.id
        })

        this.setState({
          outline: newHeaders,
        })

        saveOutline(newHeaders)

        dispatch(assignmentGetAction(entity.id, () => {}))
      })
    } else {
      let id = outline[index].id

      sectionPatchService(
        new PatchData(
          new PatchContent(
            e.target.value,
            'Name',
            PatchContent.OPERATION_REPLACE,
          ),
          id,
        ),
      ).then(response => {
        const { saveOutline } = this.props

        newHeaders[index].header = name

        this.setState({
          outline: newHeaders,
        })

        saveOutline(newHeaders)
      })
    }
  }

  handleSubHeaderItemSave = async (e, outlineNum, index, id, oldName) => {
    const { dispatch, outline, entity } = this.props
    const name = e.target.value

    const cardObject = entity[AssignmentFieldNames.QUESTION_DTOS].find(
      x => x[QuestionFieldNames.ID] == id,
    )

    if (name == cardObject?.title) {
      return
    }
    await dispatch(
      questionPatchAction(
        new PatchData(
          new PatchContent(
            e.target.value,
            QuestionFieldNames.TITLE,
            PatchContent.OPERATION_REPLACE,
          ),
          id,
        ),
        () => {
          let newHeaders = [...outline]
          newHeaders[outlineNum].subHeaders[index].name = name
          this.setState({
            outline: newHeaders,
          })
          saveOutline(newHeaders)
        },
      ),
    )

    const { saveOutline } = this.props
  }

  handleSubHeaderItemOnChange = async (e, outlineNum, index, id, oldName) => {
    const { dispatch, saveOutline } = this.props

    if (oldName == e.target.value) {
      return true
    }

    const name = e.target.value

    let newHeaders = [...this.props.outline]
    newHeaders[outlineNum].subHeaders[index].name = name
    this.setState({
      outline: newHeaders,
    })
    saveOutline(newHeaders)
  }

  handleSubHeaderClick = id => {
    const { setPage } = this.props

    setPage(id)
  }

  addOwnHeader = async () => {
    const { saveOutline, dispatch, entity, onAddCard } = this.props

    let questionEntity = QuestionSchema()
    let list = []
    questionEntity = getQuestionTypeParam(AnswerItemType.INFORM, questionEntity)
    questionEntity[QuestionFieldNames.SECTION] = {
      name: '',
      courseId: entity.id,
    }

    await dispatch(
      questionAddAction(questionEntity, entity.id, response => {
        let newTaskObject = { ...response, isNewAdded: true }
        list.push(newTaskObject)

        onAddCard(list, () => {})
      }),
    )
  }

  addOwnSubheader = (headerIndex, subHeaderIndex, section) => {
    const { dispatch, onAddCard, sortCard } = this.props

    const component = new ComponentBoxContent(
      (
        <CardSelectionPopup
          dispatch={dispatch}
          onAddCard={list => {
            onAddCard(list, sortCard, subHeaderIndex + 1)
          }}
          type="end"
          section={section}
        />
      ),
      {
        hideCloseButton: false,
      },
    )

    dispatch(componentBoxShowAction(component))
  }

  deleteSubheader = id => {
    const { onDelete } = this.props

    onDelete(id)
  }

  generateAIQuiz = () => {
    const { dispatch, entity, setPage } = this.props
    let allContent = ''
    const mergeTexts = entity[AssignmentFieldNames.QUESTION_DTOS].map(x => {
      return (allContent += x[QuestionFieldNames.QTEXT])
    })
    let CharacterLimitReached = false

    if (allContent.length >= 500) {
      CharacterLimitReached = true
    }

    if (CharacterLimitReached == false) {
      dispatch(
        messageBoxShowAction(
          new MessageBoxContent(
            MessageBoxType.WARNING,
            'Quiz could not be added',
            [
              new ButtonData(
                ButtonType.NORMAL,
                translator._('action.ok'),
                ButtonActionType.NO,
                () => {
                  dispatch(messageBoxHideAction())
                },
              ),
            ],
            'For us to add an AI-Quiz to your course, the minimum character limit must be 500 inside of all cards. Please try after reaching the character limit.',
          ),
        ),
      )
    } else {
      dispatch(
        generateQuizzesByCourseIdAction(entity.id.toString(), response => {
          setPage(response[0].id)
        }),
      )
    }
  }

  render() {
    const { handleNext, classes, outline, page, height, entity } = this.props
    const documentHeight = document.getElementById(`${entity.id}-coverImage`)
      ? document.getElementById(`${entity.id}-coverImage`).clientHeight
      : 0
    return (
      <div style={{ overflow: 'auto', height: height - documentHeight - 100 }}>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragUpdate={this.onDragUpdate}
        >
          <Droppable droppableId="droppable" type="HEADERS">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                //style={getQuestionListStyle(snapshot.isDraggingOver)}
              >
                {outline.map((header, index) => (
                  <Draggable
                    key={header.id}
                    draggableId={header.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        {' '}
                        <Box className={classes.header}>
                          <span
                            {...provided.dragHandleProps}
                            className="dragIcon"
                          >
                            <Iconify icon="icon-park-outline:drag"></Iconify>
                          </span>
                          <CssTextField
                            id={header.id}
                            key={header.id}
                            defaultValue={header.header}
                            variant="standard"
                            multiline
                            className={classes.headerTextField}
                            onBlur={e => this.handleHeaderItemChange(e, index)}
                            placeholder="Section"
                          />
                          <div style={{ flexGrow: 1 }}></div>
                          <span className="buttons">
                            <EditIcon
                              onClick={() => {
                                this.inputFocus(header.id)
                              }}
                              fontSize="small"
                              className={classes.button}
                            />
                          </span>
                        </Box>
                        <Box m={1}></Box>
                        <SubHeader
                          outlineNum={index}
                          header={header}
                          handleSubHeaderItemSave={this.handleSubHeaderItemSave}
                          handleSubHeaderItemOnChange={
                            this.handleSubHeaderItemOnChange
                          }
                          deleteSubheader={this.deleteSubheader}
                          addOwnSubheader={this.addOwnSubheader}
                          handleSubHeaderClick={this.handleSubHeaderClick}
                          page={page}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Divider></Divider>
          <Button
            variant="text"
            size="small"
            className={classes.addAIQuizButton}
            onClick={() => {
              this.generateAIQuiz()
            }}
          >
            <img
              src="/assets/add-ai-quiz.png"
              className={classes.addAIQuizIcon}
            />
            <Typography className={classes.addAIQuizText}>
              Add AI-Quiz
            </Typography>
          </Button>

          <div className={classes.addSectionDiv}>
            <Button
              variant="contained"
              className={classes.addSectionButton}
              onClick={() => this.addOwnHeader()}
            >
              <Typography fontSize={12} fontStyle="normal">
                Add Section
              </Typography>
            </Button>
          </div>
        </DragDropContext>
        <Box m={2}></Box>
      </div>
    )
  }
}

export default withStyles(styles)(Outline)
