import React, { Component, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import translator from '../../translate'
import { withSnackbar } from 'notistack'
import { get as getCookie } from 'es-cookie'
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Card,
  Box,
  CircularProgress,
} from '@material-ui/core'
import Header from '../form/renderer/header'
import { remove as removeCookie } from 'es-cookie'

import Requirements from './requirements'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { userRenewPasswordService } from '../../api/user-password'
import { getUserLimits, customerPortal } from '../../api/limit'
import { authenticationDeleteAction } from '../../reducers/authentication/action'
import { LimitTypeEnum } from 'src/model/limit'
import UserType from 'src/model/user-types'
import { ButtonData, ButtonType, ButtonActionType } from '../../model/button'
import MessageBoxContent, { MessageBoxType } from '../../model/message-box'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../reducers/message-box/action'
import {
  userUpdateEntityAction,
  userGetSelfAction,
} from '../../reducers/user/action'
import { track } from '@amplitude/analytics-browser'

class SetPasswordContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideFormArea: false,
      name: '',
      long: false,
      number: false,
      lowercase: false,
      uppercase: false,
      special: false,
      password: '',
      showPassword: false,
      disabled: true,
      openAlert: false,
      type: window.CONSTANTS.USER_ROLE_ID,
      loading: 0,
    }
  }
  handleLogoutOpenPopup = e => {
    removeCookie('token')
    window.location.href = '/'
  }

  onHandleSetPassword = async () => {
    const { password } = this.state
    const repassword = password
    if (password && repassword) {
      const data = {
        password,
        repassword,
      }
      let response = null
      try {
        response = await userRenewPasswordService(data)
      } catch (error) {
        this.setState({ openAlert: true })
      }

      if (response && response.statusCode == 200) {
        this.setState({ hideFormArea: true }, () => {
          this.handleLogoutOpenPopup()
        })
      }
    }
  }

  handleChange = prop => event => {
    let regexForLong = new RegExp('(?=.{8,})')

    let long = regexForLong.test(event.target.value)

    let regexForNumber = new RegExp('(?=.*[0-9])')

    let number = regexForNumber.test(event.target.value)

    let regexForLowercase = new RegExp('(?=.*[a-z])')

    let lowercase = regexForLowercase.test(event.target.value)

    let regexForUppercase = new RegExp('(?=.*[A-Z])')

    let uppercase = regexForUppercase.test(event.target.value)

    let regexForSpecial = new RegExp('(?=.*[!@#$%^&*])')

    let special = regexForSpecial.test(event.target.value)

    this.setState({
      password: event.target.value,
      long,
      number,
      lowercase,
      uppercase,
      special,
      disabled: !(long && number && lowercase && uppercase && special),
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClose = () => {
    this.setState({ openAlert: false })
  }

  /**
   *
   */
  render() {
    const { classes, hasHeader, dispatch } = this.props
    const {
      hideFormArea,
      long,
      number,
      lowercase,
      uppercase,
      special,
      password,
      showPassword,
      disabled,
      openAlert,
      loading,
    } = this.state

    const directUrl = () => {
      this.setState({ loading: 1 })
      customerPortal().then(response => {
        let sessionUrl = response.data.sessionUrl
        window.open(`${sessionUrl}`, '_self')
      })
      this.setState({ loading: 0 })
    }
    const handlePaymentPopup = () => {
      dispatch(
        messageBoxShowAction(
          new MessageBoxContent(
            MessageBoxType.WARNING,
            'You have an ongoing subscription. Please cancel your subscription first to delete your account.',
            [
              new ButtonData(
                ButtonType.NORMAL,
                'Payment Details',
                ButtonActionType.NO,
                e => {
                  dispatch(messageBoxHideAction())
                  dispatch(directUrl())
                },
              ),
            ],
          ),
        ),
      )
    }

    const deleteButtonAction = e => {
      dispatch(
        messageBoxShowAction(
          new MessageBoxContent(
            MessageBoxType.WARNING,
            translator._('message.delete'),
            [
              new ButtonData(
                ButtonType.NORMAL,
                translator._('action.no'),
                ButtonActionType.NO,
                e => {
                  dispatch(messageBoxHideAction())
                },
              ),
              new ButtonData(
                ButtonType.ACTION,
                translator._('action.yes'),
                ButtonActionType.DELETE,
                e => {
                  dispatch(messageBoxHideAction())
                  track('account_deleted', {
                    user_email: window.CONSTANTS.EMAIL,
                  })
                  dispatch(authenticationDeleteAction())
                },
              ),
            ],
          ),
        ),
      )
    }
    translator.changeLanguage(getCookie('language'))
    return (
      <div className={classes.div}>
        <Dialog
          open={openAlert}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translator._('setPassword.passwordSameWithTheLastOne')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              {translator._('setPassword.ok')}
            </Button>
          </DialogActions>
        </Dialog>

        {hasHeader && (
          <Typography variant={'h3'} paddingBottom={'30px'}>
            {translator._('setPassword.renewPassword')}
          </Typography>
        )}
        <Card className={classes.card}>
          {!hideFormArea && (
            <div className={classes.form}>
              <FormControl
                margin="normal"
                required
                fullWidth
                className={classes.input}
              >
                <Input
                  id="password"
                  placeholder="New Password"
                  inputProps={{
                    'aria-label': 'description',
                  }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={this.handleChange('password')}
                  label={translator._('setPassword.password')}
                  variant="outlined"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.handleClickShowPassword()
                        }}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Requirements
                long={long}
                number={number}
                uppercase={uppercase}
                lowercase={lowercase}
                special={special}
                classes={classes}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
                onClick={() => this.onHandleSetPassword()}
              >
                {this.RenderButtonText()}
              </Button>

              {this.state.loading == 1 ? (
                <CircularProgress />
              ) : (
                <Button
                  variant={'outlined'}
                  color={'error'}
                  fullWidth
                  className={classes.submit}
                  onClick={
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Premium' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Premium Plus' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Super Admin' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] ==
                      'Premium Admin Account (Quarterly)' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] ==
                      'Premium Admin Account (Starter)' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] ==
                      'Starter - Legacy' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Starter' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Starter - A' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Premium - A' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Premium v2' ||
                    UserType[window.CONSTANTS.USER_ROLE_ID] == 'Premium Plus v2'
                      ? handlePaymentPopup
                      : deleteButtonAction
                  }
                >
                  Delete Account
                </Button>
              )}
            </div>
          )}
        </Card>
        <Box m={3}></Box>
        {hideFormArea && (
          <video autoPlay width="320" height="240">
            <source src="/assets/video/done-complete.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    )
  }
  /**
   *
   */
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(userGetSelfAction(() => {}))
  }
  RenderButtonText = () => {
    return translator._('setPassword.renewPassword')
  }
}

export default withStyles(styles)(withSnackbar(SetPasswordContainer))
