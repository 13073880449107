const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '950px',
    margin: '0 auto',
  },
  header: {
    marginTop: 16,
    marginBottom: 16,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  listItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
    padding: '2px',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(234, 234, 234)',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    width: '100%',
    gap: '16px',
  },
  listImage: { width: '32px', height: '32px', marginRight: '8px' },
  listTitle: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: 'rgb(138, 138, 138)',
    marginBottom: '8px',
  },
  listText: {
    display: 'block',
    color: 'rgb(38, 38, 39)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    fontWeight: 500,
  },
  listTextSpan: {
    display: 'block',
    color: 'rgb(38, 38, 39)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    fontWeight: 300,
    cursor: 'pointer',
  },
  listTextLocked: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    fontWeight: 500,
  },
  listItemsLocked: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
    padding: '2px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '35px',
    marginBottom: '16px',
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    marginTop: 24,
    fontWeight: 300,
  },
})

export default styles
