import React from 'react'
import styles from './styles'
import { Card, Typography, withStyles, IconButton } from '@material-ui/core'
import Quixote from './pdf-view'
import CloseIcon from '@material-ui/icons/Close'
import { initialThemeSettings } from 'src/model/theme'

const CarouselView = props => {
  const {
    dispatch,
    classes,
    entity,
    page,
    setShowCarousel,
    lastClickTime,
    setThemeDrawerOpen,
    editor,
  } = props

  let cards = entity ? entity.cardDtos : []
  let themeSettings = initialThemeSettings
  let logo = null

  if (entity && entity.settingDtos.find(x => x.key == 'theme') != undefined) {
    themeSettings = JSON.parse(
      entity.settingDtos.find(x => x.key == 'theme').value,
    )
  }
  if (entity && entity.settingDtos.find(x => x.key == 'general') != undefined) {
    logo = JSON.parse(
      entity.settingDtos.find(x => x.key == 'general').value,
    ).logo
  }

  let firstCard = {
    text: '<h1 class="coverTitle">' + entity.name + '</h1>',
    type: 1,
  }

  cards = [firstCard, ...cards]

  return (
    <Card className={classes.settingsContainer}>
      <div className={classes.headerLine}>
        <div className={classes.header}>
          <Typography className={classes.title}>
            Linkedin Carousel Preview
          </Typography>
        </div>

       {/* <IconButton
          variant="text"
          color="inherit"
          className={classes.closeButton}
          onClick={() => setShowCarousel(false)}
        >
          <CloseIcon></CloseIcon>
        </IconButton>  */}
      </div>

      <div className={classes.pdfViewer}>
        {cards && themeSettings && (
          <Quixote
            cards={cards}
            themeSettings={themeSettings}
            logo={logo}
            page={page}
            lastClickTime={lastClickTime}
            setThemeDrawerOpen={setThemeDrawerOpen}
            editor={editor}
            name={entity.name}
            dispatch={dispatch}
            setShowCarousel={setShowCarousel}
          ></Quixote>
        )}
      </div>
    </Card>
  )
}

export default withStyles(styles)(CarouselView)
