import React, { useEffect, useState } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import CustomizedSwitch from 'src/components/customized-switch'
import { Typography, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'

const OverView = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const collection = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )
  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )

  const entity = collection[0]
  const [isSequential, setIsSequential] = React.useState(
    collectionSettings.isSequential == null
      ? false
      : collectionSettings.isSequential,
  )
  const [collectionCoverPageText, setCollectionCoverPageText] = React.useState(
    entity && entity.description,
  )
  const [customizeBadge, setCustomizeBadge] = React.useState(
    entity && entity.badgeText != null && entity.badgeText != '',
  )
  const [badgeText, setBadgeText] = React.useState(entity && entity.badgeText)

  useEffect(() => {
    setIsSequential(
      collectionSettings.isSequential == null
        ? false
        : collectionSettings.isSequential,
    )
    setCollectionCoverPageText(entity && entity.description)
    setCustomizeBadge(
      entity && entity.badgeText != null && entity.badgeText != '',
    )
    setBadgeText(entity && entity.badgeText)
  }, [collectionSettings])

  const handleTextFieldChangeCoverText = e => {
    setCollectionCoverPageText(e)
  }
  const onClickSwitch = () => {
    if (!customizeBadge == false) {
      setBadgeText('')
      const patchSource = new PatchData(null, entity.id)

      patchSource.addContent(
        new PatchContent('', 'badgeText', PatchData.OPERATION_REPLACE),
      )

      dispatch(patchCourseCollectionAction(patchSource, () => {}))
    }
    setCustomizeBadge(!customizeBadge)
  }
  const handleBlur = () => {
    const patchSource = new PatchData(null, entity.id)

    patchSource.addContent(
      new PatchContent(badgeText, 'badgeText', PatchData.OPERATION_REPLACE),
    )

    dispatch(patchCourseCollectionAction(patchSource, () => {}))
  }
  const handleSaveCoverText = e => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(e, 'description', PatchData.OPERATION_REPLACE),
    )
    dispatch(patchCourseCollectionAction(patchSource, () => {}))
  }
  const handleSequentialSwitch = () => {
    setIsSequential(!isSequential)

    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        !isSequential,
        'isSequential',
        PatchData.OPERATION_REPLACE,
      ),
    )
    dispatch(patchCollectionSettingsAction(patchSource, () => {}))
  }

  return (
    <div className={classes.container}>
      <div className={classes.sequentialContainer}>
        <div className={classes.row}>
          <CustomizedSwitch
            onClick={() => handleSequentialSwitch()}
            checked={isSequential}
          ></CustomizedSwitch>
          <Typography fontSize="14px" fontWeight={500}>
            Learners can proceed only sequentially
          </Typography>
        </div>
        <div className={classes.subRow}>
          <Typography fontSize="12px" fontWeight={400} color="#637381">
            Learners can't proceed unless previous course were viewed.
          </Typography>
        </div>
      </div>
      <div className={classes.badgeContainer}>
        <div className={classes.row}>
          <CustomizedSwitch
            onClick={() => onClickSwitch()}
            checked={customizeBadge}
          ></CustomizedSwitch>
          <Typography fontSize="14px" fontWeight={500}>
            Enable Badge
          </Typography>
        </div>
        <div className={classes.badgeRow}>
          {customizeBadge ? (
            <TextField
              className={classes.textField}
              placeholder="💰 $29 Paid Content etc."
              onChange={e => setBadgeText(e.target.value)}
              onBlur={() => handleBlur()}
              value={badgeText}
            ></TextField>
          ) : (
            <Typography fontSize="12px" fontWeight={400} color="#637381">
              You can add information or price as a label on the cover page.
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.textContainer}>
        <Typography fontSize="15px">Cover Text</Typography>
        <SunEditor
          className={classes.textArea}
          setDefaultStyle="text-align: left;"
          onChange={handleTextFieldChangeCoverText}
          onBlur={(event, editorContents) =>
            handleSaveCoverText(editorContents)
          }
          value={collectionCoverPageText}
          defaultValue={collectionCoverPageText}
          placeholder="Cover Text"
          onImageUploadBefore={(files, info, uploadHandler) => {
            dispatch(
              mediaImageUploadAction(files[0], '', result => {
                const response = {
                  result: [
                    {
                      url: `${URL.MEDIA}${result.url}`,
                      name: result.name,
                      size: 100,
                    },
                  ],
                }

                uploadHandler(response)
              }),
            )
          }}
          setOptions={{
            mode: 'inline',
            formats: ['h1', 'h2', 'p'],
            height: 'unset',
            marginTop: '12px',
            minHeight: '300px',

            addTagsWhitelist: 'file-embed|google-maps|div',
            resizingBar: false,
            buttonList: [
              [
                ':t-Format Text-default.more_text',
                'formatBlock',
                'bold',
                'italic',
                'underline',
                'fontColor',
                'hiliteColor',
              ],
              ['align'],
              ['list'],
              ['image'],
              ['link'],
              ['removeFormat'],
              ['codeView'],
            ],
            pasteTagsWhitelist: 'p|h1|h2|h3',
            popupDisplay: 'local',
          }}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(OverView)
