const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  root_content: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    gap: 16,
    borderRadius: 8,
    padding: 12,
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1px solid #015FFB !important',
      cursor: 'pointer',
    },
  },
  root_content_text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  root_content_text_title: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#002E47',
  },
  root_content_text_description: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#637381',
  },
})

export default styles
