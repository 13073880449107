const styles = theme => ({
  customDomain__root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
  },
  displayF: {
    display: 'flex',
  },
  customDomain__headerWithIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  customDomain__icon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
    marginTop: '3px',
  },
  customDomain__domainView: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    height: '48px',
    padding: '16px',
  },
  customDomain__cname: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 8,
  },
  customDomain__addDomainButton: {
    backgroundColor: '#015FFB',
    color: '#fff',
    borderRadius: '12px',
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    height: '40px',
    '&:hover': {
      backgroundColor: '#3a92e9',
    },
  },
  customDomain__buttonDiv: {
    padding: '16px 0px',
  },
  customDomain__cnameList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    marginTop: '32px',
    padding: '12px 16px',
  },
  customDomain__headerList: {
    padding: '0px 16px',
  },
  customDomain__list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  customDomain__listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  },
  prevButton: {
    width: 64,
    height: 36,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  advancedGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  switchControl: {
    marginLeft: 0,
  },
  textFields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  settings: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  taskPerDayInput: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
  },
  input: {
    borderColor: 'transparent',
  },
  gamificationDesc: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  previewGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
  },
  visibilityIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  iconWrapper: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    //backgroundColor: '#D6F1E4',
    borderRadius: theme.spacing(3),
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    marginRight: theme.spacing(1),
  },
  timingHolder: {
    maxWidth: 320,
  },
  timingHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  aboutTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  timingHeaderGroup: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  infoSameLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  copy: {
    color: '#015FFB',
  },

  inputField: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  listGroup: {
    marginTop: 0,
  },
  listLabel: {
    padding: 24,
  },
  select: {
    marginTop: '0!important',
  },
  embedView_content: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    borderRadius: '8px',
  },
  embedView_content__header: {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: '#F4F6F8',
    justifyContent: 'center',
  },
  header__content: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    height: '48px',
    gap: 10,
    padding: '0 16px',
    borderRadius: '8px',
  },
  header__content__numberView: {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    justifyContent: 'center',
  },
})

export default styles
