import { put, call, take, fork, takeMaybe } from 'redux-saga/effects'
import { listCourseCollectionService } from '../../api/course-collection'
import { courseCollectionListSuccess } from '../../reducers/course-collection/action'
import { LIST_COURSE_COLLECTION } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* courseCollectionListFlow(payload) {
  try {
    const { query, callback } = payload
    const response = yield call(listCourseCollectionService, query)

    yield put(courseCollectionListSuccess(response.data))
    yield put(operationSuccess())
    
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* courseCollectionListWatcher() {
  while (true) {
    const action = yield takeMaybe(LIST_COURSE_COLLECTION)
    const { payload } = action

    yield fork(generalSaga, courseCollectionListFlow, payload)
  }
}
