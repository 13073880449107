import React, { useState } from 'react'
import { Typography, withStyles, TextField, Button } from '@material-ui/core'
import styles from './styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import URLs from 'src/api/url'

const EmbedView = props => {
  const { entity, classes } = props
  const [copiedEmbedCode, setCopiedEmbedCode] = useState(false)
  const [copiedEmbedCode2, setCopiedEmbedCode2] = useState(false)

  const onClickEmbedCode = () => {
    setCopiedEmbedCode(true)
    setTimeout(() => {
      setCopiedEmbedCode(false)
    }, 1000)
  }
  const onClickEmbedCode2 = () => {
    setCopiedEmbedCode2(true)
    setTimeout(() => {
      setCopiedEmbedCode2(false)
    }, 1000)
  }

  return (
    <div className={classes.customDomain__root}>
      <div className={classes.embedView_content}>
        <div className={classes.embedView_content__header}>
          <div style={{ marginBottom: 3 }}> 1</div>
        </div>
        <Typography color={'#002E47'} fontSize={'14px'} fontWeight={500}>
          Paste code number 1 into the {`<head>`} section of your site.
        </Typography>
      </div>
      <TextField
        fullWidth
        disabled={true}
        multiline
        sx={{ input: { color: '#637381' } }}
        value={`<meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
<script src="{1}viewer.js"></script>
<script>
const app = new MiniCourse();
document.addEventListener("DOMContentLoaded", () => {
  app.init({
    categoryId: "{2}"
  })
})
</script>`
          .replace('{0}', URLs.APP_URL)
          .replace('{1}', URLs.APP_URL)
          .replace('{2}', entity.id)}
        className={classes.textField}
      />

      <CopyToClipboard
        text={`<meta http-equiv="Access-Control-Allow-Origin" content="{0}" />
                    <script src="{1}viewer.js"></script>
                    <script>
                      const app = new MiniCourse();

                      document.addEventListener("DOMContentLoaded", () => {
                        app.init({
                          categoryId: "{2}"
                        })
                      })
                    </script>`
          .replace('{0}', URLs.APP_URL)
          .replace('{1}', URLs.APP_URL)
          .replace('{2}', entity.id)}
        className={classes.copy}
      >
        <Button
          onClick={onClickEmbedCode}
          variant="outlined"
          style={{
            textTransform: 'none',
          }}
        >
          {copiedEmbedCode ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>
      <div className={classes.embedView_content}>
        <div className={classes.embedView_content__header}>
          <div style={{ marginBottom: 3 }}>2</div>
        </div>
        <Typography color={'#002E47'} fontSize={'14px'} fontWeight={500}>
          Paste code number 2 into the {`<body>`} section of your site.
        </Typography>
      </div>
      <TextField
        fullWidth
        multiline
        value={'<div id="mcg-category-' + entity.id + '"></div>'}
        className={classes.textField}
        sx={{ input: { color: '#637381' } }}
        disabled={true}
      />

      <CopyToClipboard
        text={'<div id="mcg-category-' + entity.id + '"></div>'}
        className={classes.copy}
      >
        <Button
          onClick={onClickEmbedCode2}
          variant="outlined"
          textTransform="none"
          style={{
            textTransform: 'none',
          }}
        >
          {copiedEmbedCode2 ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>
    </div>
  )
}

export default withStyles(styles)(EmbedView)
