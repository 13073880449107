import React from 'react'
import style from './style'
import propTypes from './prop'
import { withStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Logo = ({ classes, ...props }) => {
  const theme = useTheme()
  {
    /*const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  })*/
  }

  return (
    <Link to="/">
      <img
        src={'/assets/logotype.png'}
        className={classes.logo}
        alt="logo"
        {...props}
      />
    </Link>
  )
}

Logo.propTypes = propTypes

export default withStyles(style)(Logo)
