import React from 'react'
import styles from '../styles'
import { Typography, withStyles } from '@material-ui/core'
import CustomizedSwitch from 'src/components/customized-switch'
import { Badge, UpgradePopover } from '..'

const AccordionSummaryContent = props => {
  const {
    accessRule,
    handleClickSwitch,
    contentHeader,
    contentDescription,
    id,
    badgeType,
    open,
    anchorEl,
    handleClose,
    popupHeader,
    popupContent,
    accessRuleEqual,
    classes,
  } = props

  return (
    <div className={classes.optIn__root}>
      <div className={classes.root}>
        <div
          className={classes.switch_div}
          style={{
            transform:
              accessRule == accessRuleEqual ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <CustomizedSwitch
            aria-describedby={id}
            onClick={event => handleClickSwitch(event)}
            checked={accessRule == accessRuleEqual}
          />
        </div>
        <div className={classes.root__summary__auth}>
          <Typography fontSize={'14px'} fontWeight={500}>
            {contentHeader}
          </Typography>
          {badgeType && <Badge type={badgeType} />}
        </div>
      </div>
      <div className={classes.optIn__root__description}>
        <Typography fontSize={'12px'} fontWeight={400} color={'#637381'}>
          {contentDescription}
        </Typography>
      </div>

      <UpgradePopover
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        header={popupHeader}
        content={popupContent}
        accessRuleEqual={accessRuleEqual}
      />
    </div>
  )
}

export default withStyles(styles)(AccordionSummaryContent)
