import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Controller,
  Pagination,
  Navigation,
  EffectCoverflow,
} from 'swiper/modules'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { withStyles } from '@material-ui/core'
import styles from './styles'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

const SwiperContent = props => {
  const { classes } = props
  const [controlledSwiper, setControlledSwiper] = useState(null)

  const swiperRef = useRef(null)

  const [pageView, setPageView] = useState(1)

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  return (
    <div className={classes.root}>
      <Swiper
        effect={'coverflow'}
        ref={swiperRef}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        scrollbar={{ draggable: true }}
        loop={true}
        modules={[EffectCoverflow, Pagination, Controller, Navigation]}
        className={classes.swiper}
        onSwiper={setControlledSwiper}
        bserver={true} // Observe changes
        observeParents={true}
        controller={{ control: controlledSwiper }}
        onSlideChange={swiper => setPageView(swiper.activeIndex)}
        navigation={true}
      >
        <SwiperSlide boxShadow="none" className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-1.png" alt="" />
        </SwiperSlide>
        <SwiperSlide className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-2.png" alt="" />
        </SwiperSlide>
        <SwiperSlide className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-3.png" alt="" />
        </SwiperSlide>
        <SwiperSlide className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-4.png" alt="" />
        </SwiperSlide>
        <SwiperSlide className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-5.png" alt="" />
        </SwiperSlide>
        <SwiperSlide className={classes.swiper_slide}>
          <img src="/assets/automation/swiper-6.png" alt="" />
        </SwiperSlide>
        <div className={classes.swiper_buttons}>
          <IconButton
            onClick={() => {
              goPrev()
            }}
            aria-label="prev"
            className={classes.swiper_buttons_padding}
          >
            <ChevronLeftIcon fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => {
              goNext()
            }}
            aria-label="next"
            className={classes.swiper_buttons_padding}
          >
            <ChevronRightIcon fontSize="medium" />
          </IconButton>
        </div>
      </Swiper>
    </div>
  )
}

export default withStyles(styles)(SwiperContent)
