import { Component } from 'react'
import propTypes from './prop'
import { set as setCookie } from 'es-cookie'
import { integrationValidateShortLinkService } from '../../api/integration'

class EnoctaReroute extends Component {
  checkToken = async shortLink => {
    let response = await integrationValidateShortLinkService(shortLink)
    if (response.data != null) {
      setCookie('token', response.data.accessToken, {sameSite: 'none',secure: true})
      setCookie('refreshToken', response.data.refreshToken, { sameSite: 'none',secure: true })
      setCookie('userId', response.data.userId, { sameSite: 'none',secure: true })
      //setCookie('token', token)

      window.location.href = '/micro-learning-content'
    } else {
      window.location.href = '/login'
    }
  }

  /**
   *
   */
  render() {
    let url = window.location.href
    let shortLink = url.split('shortLink=')[1]
    if (shortLink) {
      this.checkToken(shortLink)
    }

    return ''
  }
}

EnoctaReroute.propTypes = propTypes

export default EnoctaReroute
