import PropTypes from 'prop-types'
import React, { useState } from 'react'
// @mui
import { Stack } from '@material-ui/core'
//model
import { PatchContent, PatchData } from 'src/model/patch'
// redux
import { useDispatch } from 'react-redux'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'

import KanbanTaskCard from './KanbanCourseCard'
import KanbanColumnToolBar from './KanbanColumnToolBar'
import CreateButtonView from 'src/components/create-button-view'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
import { DndContainer, DndItems } from 'src/pages/list-courses/components'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable'
// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
}

export default function KanbanColumn({
  column,

  courses,
  refresh,
  history,
  isUserReachedCourseLimit,
  setIsUserReachedCourseLimit,
  classes,
  entity,
  viewType,
}) {
  const dispatch = useDispatch()
  const { name, id, collection } = column

  const isDefaultCollection = id === DEFAULT_COLLECTION_GUID

  const handlePatchCourseName = newName => {
    dispatch(
      patchCourseCollectionAction(
        new PatchData(
          new PatchContent(
            newName,
            'collectionName',
            PatchData.OPERATION_REPLACE,
          ),
          id,
        ),
      ),
    )
  }
  return (
    <DndContainer
      key={collection.id}
      id={collection.id}
      style={{
        minWidth: '100px',
        minHeight: '100px',
      }}
    >
      <SortableContext
        items={collection.courses.map(course => `${course.id}-item`)}
        style={{
          minWidth: '100px',
          minHeight: '100px',
        }}
      >
        <div
          style={{
            padding: 16,
            marginBottom: '16px',
            width: '100%',
            background: 'rgba(244, 246, 248, 1)',
            borderRadius: 16,
          }}
        >
          <Stack spacing={2}>
            <KanbanColumnToolBar
              classes={classes}
              collection={collection}
              id={id}
              columnName={name}
              onUpdate={handlePatchCourseName}
            />
          </Stack>

          <>
            <div
              spacing={2}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {!isDefaultCollection && (
                <CreateButtonView
                  dispatch={dispatch}
                  id={id}
                  entity={entity}
                  history={history}
                  collectionName={name}
                  viewType={viewType}
                  isUserReachedCourseLimit={isUserReachedCourseLimit}
                />
              )}

              {courses.length > 0 ? (
                courses &&
                courses.map((course, index) => (
                  <DndItems key={course.id} id={`${course.id}-item`}>
                    <KanbanTaskCard
                      courses={courses}
                      key={course.id}
                      course={course}
                      index={index}
                      history={history}
                      refresh={refresh}
                      setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
                      isUserReachedCourseLimit={isUserReachedCourseLimit}
                      viewType={viewType}
                    />
                  </DndItems>
                ))
              ) : (
                <DndItems
                  key={collection.id}
                  id={`empty-item-${collection.id}`}
                >
                  <div
                    style={{
                      minHeight: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  ></div>
                </DndItems>
              )}
            </div>
          </>
          {/*  {isUserReachedCourseLimit == true && (
            <Stack spacing={2} sx={{ pb: 3, mt: 2 }}>
              <UserReachedCourseLimit
                classes={classes}
                dispatch={dispatch}
                id={id}
                entity={entity}
                history={history}
                collectionName={name}
                selectedAssignmentType={AssignmentTypes.ONETIME}
              />
            </Stack>
          )}
          {isUserReachedCourseLimit == false && (
            <Stack spacing={2} sx={{ pb: 3, mt: 2 }}>
              <CustomizedMenus
                courses={courses}
                classes={classes}
                dispatch={dispatch}
                id={id}
                entity={entity}
                history={history}
                collectionName={name}
                selectedAssignmentType={AssignmentTypes.ONETIME}
              />
            </Stack>
          )}
          {isUserReachedCourseLimit == 'Initial' && (
            <Stack spacing={2} sx={{ pb: 3, mt: 2, alignItems: 'center' }}>
              <CircularProgress style={{ width: '16px', height: '16px' }} />
            </Stack>
          )} */}
        </div>
      </SortableContext>
    </DndContainer>
  )
}
