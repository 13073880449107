const styles = theme => ({
  rootOpen: {
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '8px',
    flexDirection: 'row',
    width: '260px',
    height: '48px',
    paddingBottom: 12,
    paddingTop: 12,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rootClose: {
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '72px',
    padding: '12px',
    height: '48px',
    flexDirection: 'row',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
