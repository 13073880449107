import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const TemplateCertificatesList = props => {
  const { certificateList, editHtmlValue, classes } = props
  return (
    <div className={classes.container}>
      {certificateList.templates.length > 0 &&
        certificateList.templates.map((certificate, index) => (
          <div
            key={index}
            className={classes.certificateItem}
            onClick={() => editHtmlValue(certificate.html)}
          >
            <div className={classes.certificateFrame}>
              <img
                src={certificate.thumbnail}
                alt="certificate"
                className={classes.certificateImage}
              />
            </div>
            <Typography className={classes.certificateText}>
              {certificate.name}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default withStyles(styles)(TemplateCertificatesList)
