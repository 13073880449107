import {
  MEDIA_IMAGE_GET_BY_FILTER,
  MEDIA_IMAGE_GET_BY_FILTER_SUCCESS,
  MEDIA_IMAGE_GET_BY_FILTER_ERROR,
  MEDIA_IMAGE_GET_BY_ID,
  MEDIA_IMAGE_GET_BY_ID_SUCCESS,
  MEDIA_IMAGE_GET_BY_ID_ERROR,
  MEDIA_IMAGE_UPLOAD_MANY,
  MEDIA_IMAGE_UPLOAD_MANY_SUCCESS,
  MEDIA_IMAGE_UPLOAD_MANY_ERROR,
  MEDIA_IMAGE_UPLOAD,
  MEDIA_IMAGE_UPLOAD_SUCCESS,
  MEDIA_IMAGE_UPLOAD_ERROR,
  MEDIA_IMAGE_PATCH,
  MEDIA_IMAGE_PATCH_SUCCESS,
  MEDIA_IMAGE_PATCH_ERROR,
  MEDIA_IMAGE_DELETE,
  MEDIA_IMAGE_DELETE_SUCCESS,
  MEDIA_IMAGE_DELETE_ERROR,
  MEDIA_IMAGE_DELETE_LIST,
  MEDIA_IMAGE_DELETE_LIST_SUCCESS,
  MEDIA_IMAGE_DELETE_LIST_ERROR,
  OPENAI_GENERATE_IMAGE,
  OPENAI_GENERATE_IMAGE_SUCCESS,
  OPENAI_GENERATE_IMAGE_ERROR,
  MEDIA_CARD_IMAGE_CREATION,
  MEDIA_CARD_IMAGE_CREATION_SUCCESS,
  MEDIA_CARD_IMAGE_CREATION_ERROR,
} from './type'

/**
 *
 * @param {*} query
 */
export const mediaImageGetByFilterAction = query => {
  return {
    type: MEDIA_IMAGE_GET_BY_FILTER,
    payload: { query },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageGetByFilterSuccess = response => {
  return {
    type: MEDIA_IMAGE_GET_BY_FILTER_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageGetByFilterError = () => {
  return {
    type: MEDIA_IMAGE_GET_BY_FILTER_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const mediaImageGetByIdAction = id => {
  return {
    type: MEDIA_IMAGE_GET_BY_ID,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageGetByIdSuccess = response => {
  return {
    type: MEDIA_IMAGE_GET_BY_ID_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageGetByIdError = () => {
  return {
    type: MEDIA_IMAGE_GET_BY_ID_ERROR,
  }
}

/**
 *
 * @param {*} files
 */
export const mediaImageUploadManyAction = files => {
  return {
    type: MEDIA_IMAGE_UPLOAD_MANY,
    payload: { files },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageUploadManySuccess = response => {
  return {
    type: MEDIA_IMAGE_UPLOAD_MANY_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageUploadManyError = () => {
  return {
    type: MEDIA_IMAGE_UPLOAD_MANY_ERROR,
  }
}

/**
 *
 * @param {*} file
 * @param {*} parentFolderId
 * @param {*} callback
 */
export const mediaImageUploadAction = (file, parentFolderId, callback) => {
  return {
    type: MEDIA_IMAGE_UPLOAD,
    payload: { file, parentFolderId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageUploadSuccess = response => {
  return {
    type: MEDIA_IMAGE_UPLOAD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageUploadError = () => {
  return {
    type: MEDIA_IMAGE_UPLOAD_ERROR,
  }
}

/**
 *
 * @param {*} content
 */
export const mediaImagePatchAction = content => {
  return {
    type: MEDIA_IMAGE_PATCH,
    payload: { content },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImagePatchSuccess = response => {
  return {
    type: MEDIA_IMAGE_PATCH_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImagePatchError = () => {
  return {
    type: MEDIA_IMAGE_PATCH_ERROR,
  }
}

/**
 *
 * @param {*} file
 * @param {*} parentFolderId
 */
export const mediaImageDeleteAction = (id, callback) => {
  return {
    type: MEDIA_IMAGE_DELETE,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageDeleteSuccess = response => {
  return {
    type: MEDIA_IMAGE_DELETE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageDeleteError = () => {
  return {
    type: MEDIA_IMAGE_DELETE_ERROR,
  }
}

/**
 *
 * @param {*} ids
 */
export const mediaImageDeleteListAction = ids => {
  return {
    type: MEDIA_IMAGE_DELETE_LIST,
    payload: { ids },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaImageDeleteListSuccess = response => {
  return {
    type: MEDIA_IMAGE_DELETE_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaImageDeleteListError = () => {
  return {
    type: MEDIA_IMAGE_DELETE_LIST_ERROR,
  }
}

/**
 *
 * @param {*} description
 * @param {*} callback
 */
export const openaiImageGenerationAction = (description, callback) => {
  return {
    type: OPENAI_GENERATE_IMAGE,
    payload: { description, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const openaiImageGenerationSuccess = response => {
  return {
    type: OPENAI_GENERATE_IMAGE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const openaiImageGenerationError = () => {
  return {
    type: OPENAI_GENERATE_IMAGE_ERROR,
  }
}
/**
 *
 * @param {*} prompt
 * @param {*} cardId
 * @param {*} callback
 */
export const mediaCardImageCreationAction = (prompt, cardId, callback) => {
  return {
    type: MEDIA_CARD_IMAGE_CREATION,
    payload: { prompt, cardId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const mediaCardImageCreationSuccess = response => {
  return {
    type: MEDIA_CARD_IMAGE_CREATION_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const mediaCardImageCreationError = () => {
  return {
    type: MEDIA_CARD_IMAGE_CREATION_ERROR,
  }
}
