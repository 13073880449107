import { Typography, Grid, TextField, Button } from '@material-ui/core'
import React, { useState } from 'react'
import styles from './style'

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key

const EmbedImage = props => {
  const { onPhotoClick, classes } = props
  const [urlText, setUrlText] = useState()
  return (
    <Grid className={classes.linkPanel}>
      <Grid item className={classes.mainGrid}>
        <Typography className={classes.header}>
          Add images quickly with a link.
        </Typography>
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          style={{ width: '100%', marginBottom: 16 }}
          label="Paste the image link"
          onChange={e => setUrlText(e.target.value)}
        ></TextField>
        <Button
          variant="contained"
          onClick={() => {
            onPhotoClick(urlText)
          }}
        >
          Add Image
        </Button>
      </Grid>
      <div className={classes.infoDiv}></div>
      <Typography>
        Want to embed media from other platforms like Vimeo, Podcasts?
        <a
          href="https://minicoursegenerator.com/knowledge-base/embedding-media-from-youtube-vimeo-spotify-etc/"
          target="_blank"
        >
          {' '}
          Learn more.
        </a>
      </Typography>
    </Grid>
  )
}

export default EmbedImage
