import React from 'react'
import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react'
import translator from 'src/translate'
//redux
import { useDispatch } from 'react-redux'
// @mui
import {
  Stack,
  OutlinedInput,
  MenuItem,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core'
// components
import Iconify from 'src/components/Iconify'
import MenuPopover from 'src/components/MenuPopover'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import ShareCollection from './share-collection/index'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
// ----------------------------------------------------------------------
import URL from 'src/api/url'
import CollectionEdit from '../collection-edit'
import CollectionDelete from 'src/components/collection-delete'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import CollectionSettingsDrawer from 'src/components/collection-settings-drawer'
import { getCollectionSettingsAction } from 'src/reducers/course-collection/action'

import { teamListAction } from 'src/reducers/teams/action'
import { domainListAction } from 'src/reducers/domain/action'
import { track } from '@amplitude/analytics-browser'

KanbanColumnToolBar.propTypes = {
  columnName: PropTypes.string,
  onUpdate: PropTypes.func,
}

function KanbanColumnToolBar({
  collection,
  columnName,
  onUpdate,
  id,
  classes,
}) {
  const renameRef = useRef(null)
  const dispatch = useDispatch()
  const [value, setValue] = useState(columnName)
  useEffect(() => {
    setValue(columnName)
  }, [columnName])
  const [open, setOpen] = useState(null)

  const [isCollectionEditOpen, setIsCollectionEditOpen] = useState(false)
  const [isCollectionDeleteOpen, setIsCollectionDeleteOpen] = useState(false)
  const isDefaultCollection = id === DEFAULT_COLLECTION_GUID
  const [
    collectionSettingsDrawerOpen,
    setCollectionSettingsDrawerOpen,
  ] = useState(false)
  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleClickRename = () => {
    track('collection_edit_clicked')
    setIsCollectionEditOpen(true)
  }

  const handleChangeColumnName = event => {
    setValue(event.target.value)
  }

  const handleUpdateColumn = event => {
    if (
      value != columnName &&
      (event.type === 'blur' || event.key === 'Enter') &&
      renameRef.current
    ) {
      renameRef.current.blur()
      value != columnName && onUpdate(value)
    }
  }

  const handleClickShare = () => {
    track('collection_share_clicked')

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <ShareCollection dispatch={dispatch} collection={collection} />,
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }
  const handleClickPreview = () => {
    track('collection_preview_clicked')
    window.open(
      URL.APP_URL +
        'category/' +
        columnName
          .replaceAll(' ', '-')
          .replaceAll('?', '')
          .replaceAll('&', '') +
        '-1/' +
        id,
    )
  }

  const onDelete = () => {
    setIsCollectionDeleteOpen(true)
  }

  useEffect(() => {}, [])

  const handleOpenSettingsDrawer = entity => {
    track('learners_access_clicked')

    dispatch(
      getCollectionSettingsAction(entity.id, response => {
        dispatch(
          teamListAction({}, response => {
            setCollectionSettingsDrawerOpen(true)
          }),
        )
      }),
    )
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <OutlinedInput
          size="small"
          placeholder="Name the collection"
          value={value}
          onChange={handleChangeColumnName}
          onBlur={handleUpdateColumn}
          onKeyUp={handleUpdateColumn}
          inputRef={renameRef}
          disabled={isDefaultCollection}
          sx={
            isDefaultCollection
              ? {
                  fontSize: '18px',
                  fontWeight: 600,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                  minWidth: 260,
                }
              : {
                  fontSize: '18px',
                  fontWeight: 600,
                  width: '100%',
                  '& fieldset': {
                    border: 'none',
                  },
                }
          }
          inputProps={{
            style: isDefaultCollection
              ? {
                  color: 'black',
                  WebkitTextFillColor: 'black',
                }
              : {},
          }}
        />
        {!isDefaultCollection && (
          <>
            <Button
              className={classes.buttons}
              variant="text"
              onClick={handleClickPreview}
            >
              <Typography className={classes.buttonText}>View</Typography>
            </Button>
            <Button
              className={classes.buttonEdit}
              variant="text"
              onClick={handleClickRename}
            >
              <Typography className={classes.buttonText}>
                {' '}
                Edit Landing Page
              </Typography>
            </Button>
            <Button
              className={classes.learnersAccessButton}
              variant="text"
              onClick={() => handleOpenSettingsDrawer(collection)}
            >
              <Typography className={classes.buttonText}>
                Learners' Access
              </Typography>
            </Button>
            {/*<Button
              className={classes.buttons}
              variant="text"
              onClick={handleClickShare}
            >
              <Typography className={classes.buttonText}> Share</Typography>
        </Button>*/}
            <IconButton
              size="small"
              onClick={handleOpen}
              color={open ? 'inherit' : 'default'}
              className={classes.iconButton}
            >
              <Iconify
                icon={'eva:more-horizontal-fill'}
                width={20}
                height={20}
              />
            </IconButton>
          </>
        )}
      </Stack>
      {isCollectionEditOpen && (
        <CollectionEdit
          dispatch={dispatch}
          collectionEntity={collection}
          handleCloseModal={() => setIsCollectionEditOpen(false)}
          isModalOpen={isCollectionEditOpen}
        />
      )}
      {isCollectionDeleteOpen && (
        <CollectionDelete
          classes={classes}
          dispatch={dispatch}
          id={id}
          collectionEntity={collection}
          handleCloseModal={() => setIsCollectionDeleteOpen(false)}
          isModalOpen={isCollectionDeleteOpen}
          collectionName={columnName}
        />
      )}
      {!isDefaultCollection && (
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: '8px',
            },
          }}
        >
          <MenuItem onClick={onDelete}>
            <Iconify
              icon={'eva:trash-2-outline'}
              sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
            />
            {translator._('courseCollection.collection.delete')}
          </MenuItem>
        </MenuPopover>
      )}
      {collection && (
        <CollectionSettingsDrawer
          setCollectionSettingDrawerOpen={setCollectionSettingsDrawerOpen}
          collectionSettingsDrawerOpen={collectionSettingsDrawerOpen}
          dispatch={dispatch}
          entity={collection}
        ></CollectionSettingsDrawer>
      )}
    </>
  )
}

export default withStyles(styles)(KanbanColumnToolBar)
