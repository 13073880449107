import { put, call, take, fork } from 'redux-saga/effects'
import { domainDeleteService } from '../../api/domain'
import { domainDeleteSuccess } from '../../reducers/domain/action'
import { DOMAIN_DELETE } from '../../reducers/domain/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* domainDeleteFlow(payload) {
  try {
    const { id, callback } = payload

    yield call(domainDeleteService, id)
    yield put(domainDeleteSuccess(id))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* domainDeleteWatcher() {
  while (true) {
    const action = yield take(DOMAIN_DELETE)
    const { payload } = action

    yield fork(generalSaga, domainDeleteFlow, payload)
  }
}
