const styles = theme => ({

  certificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#637381',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  bannerContainer: {
    width: '100%',
    height: '260px',
    borderRadius: '16px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    flexDirection: 'row',
    backgroundImage: `url('/assets/certificate-upgrade-bg.jpg')`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: theme.spacing(2)
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    width: '400px',
    height:'260px',
    gap: '16px',
    textAlign: 'center',
    marginLeft: '50px',
  },
  bannerText: {
    color: '#002E47',
    fontWeight: 500,
    fontSize: '18px',
    textAlign: 'left'
  },
  bannerSubText: {
    color: '#637381',
    fontSize: '14px',
    textAlign: 'left'
  },
  bannerButton: {
   
    
  },
})

export default styles
