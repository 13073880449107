import React from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { sendConfirmationEmailAction } from '../../../reducers/sign-up/action.js'

// material
import { Grid, Alert, TextField, Box } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// routes
//import { PATH_AUTH } from '../../../routes/paths'
import useIsMountedRef from '../../../hooks/useIsMountedRef'
import translator from '../../../translate'
import { Link } from '@material-ui/core'
// ----------------------------------------------------------------------

const RegisterForm = props => {
  //const { login } = useAuth()
  const method = 'firebase'
  const login = ''
  const isMountedRef = useIsMountedRef()

  const PATH_AUTH = {}
  /*  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  }) */

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    //validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { dispatch } = props
        const email = values.email
        if (email !== '' && email !== null) {
          const data = {
            email: email,
            application: 'becompe',
          }
          dispatch(sendConfirmationEmailAction(data))

          if (isMountedRef.current) {
            setSubmitting(false)
          }
        }
      } catch (error) {
        console.error(error)
        resetForm()
        if (isMountedRef.current) {
          setSubmitting(false)
          setErrors({ afterSubmit: error.message })
        }
      }
    },
  })

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid spacing={3}>
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>
        <Box m={2}></Box>
        <LoadingButton
          sx={{
            backgroundColor: '#015ffb',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#015ffb',
              boxShadow: 'none',
            },
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Get Started
        </LoadingButton>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '36px',
          }}
        >
          {translator._('signUp.alreayHaveAnAccount')} &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            href="login"
            sx={{
              color: '#015ffb',
            }}
          >
            {translator._('loginPage.signIn')}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default RegisterForm
