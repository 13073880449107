const styles = theme => ({
  root: {
    width: '33%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    gap: '20px',
    backgroundColor: '#fff',
    borderRadius: '16px',
  },
})

export default styles
