const styles = theme => ({
  container: {},
  certificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#637381',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '184px',
    gap: '16px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    color: '#002E47',
    borderColor: 'rgba(145, 158, 171, 0.32)',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: 'rgba(145, 158, 171, 0.32)',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '4px',
    color: '#002E47',
  },
})

export default styles
