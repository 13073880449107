import { put, call, take, fork } from 'redux-saga/effects'
import { getCertificateService } from 'src/api/certificate'
import { getCertificateSuccess } from 'src/reducers/certificate/action.js'
import { GET_CERTIFICATE } from 'src/reducers/certificate/type.js'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getCertificateFlow(payload) {
  try {
    const { collectionId, callback } = payload
    const response = yield call(getCertificateService, collectionId)
    yield put(getCertificateSuccess(response.data))
    callback(response.data)
    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getCertificateSagaWatcher() {
  while (true) {
    const action = yield take(GET_CERTIFICATE)
    const { payload } = action

    yield fork(generalSaga, getCertificateFlow, payload)
  }
}
