import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import styles from './styles'

import { useDispatch } from 'react-redux'
import { CustomDomainHeader, CnameView, CnameList } from './components'
import CustomDomainNonPremiumView from 'src/components/custom-domain-non-premium-view'
import UserType from 'src/model/user-types'
import { useSelector } from 'react-redux'
const CustomDomain = props => {
  const { classes } = props

  const dispatch = useDispatch()

  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const collection = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )

  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )
  const entity = collection[0]

  const components = [
    {
      component: <CustomDomainHeader />,
      onView: true,
      mainPage: true,
      userControl:
        userControl != 'Regular Admin Account' &&
        userControl != 'Starter - Legacy' &&
        userControl != 'Starter' &&
        userControl != 'Starter A',
    },
    {
      component: <CnameView entity={entity} dispatch={dispatch} />,
      onView: true,
      mainPage: true,
      userControl:
        userControl != 'Regular Admin Account' &&
        userControl != 'Starter - Legacy' &&
        userControl != 'Starter' &&
        userControl != 'Starter A',
    },
    {
      component: <CnameList entity={entity} dispatch={dispatch} />,
      onView: true,
      mainPage: true,
      userControl:
        userControl != 'Regular Admin Account' &&
        userControl != 'Starter - Legacy' &&
        userControl != 'Starter' &&
        userControl != 'Starter A',
    },

    {
      component: <CustomDomainNonPremiumView />,
      onView: true,
      mainPage: true,
      userControl:
        userControl == 'Regular Admin Account' ||
        userControl == 'Starter - Legacy' ||
        userControl == 'Starter' ||
        userControl == 'Starter A',
    },
  ]

  return (
    <div className={classes.content}>
      <div className={classes.content__components}>
        {components.map(
          (component, index) =>
            component.onView &&
            component.userControl && (
              <div key={index}>{component.component}</div>
            ),
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(CustomDomain)
