import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import URLs from 'src/api/url'
import {
  withStyles,
  Typography,
  Button,
  OutlinedInput,
  Popover,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import { Icon } from '@iconify/react'
import styles from './styles'
import { PatchContent, PatchData } from 'src/model/patch'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'

import ContainerMediaGalery from 'src/container/media-galery'
import {
  componentBoxHideAction,
  componentBoxShowAction,
} from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'
import DeleteIcon from '@material-ui/icons/Delete'
import { track } from '@amplitude/analytics-browser'
import ShareCollectionPopup from 'src/components/collection-share'
import CollectionDelete from 'src/components/collection-delete'
import MenuPopover from 'src/components/MenuPopover'
import { MenuItem } from '@material-ui/core'
const DEFAULT_COLLECTION_GUID = '00000000-0000-0000-0000-000000000000'
const CollectionDetails = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const collection = useSelector(state =>
    state.courseCollectionList.list.courseCollectionDtos.filter(
      collection => collection.id === selectedCollection,
    ),
  )
  const collections = useSelector(
    state => state.courseCollectionList.list.courseCollectionDtos,
  )
  const isDefaultCollection =
    collection.length > 0 && collection[0].id === DEFAULT_COLLECTION_GUID

  const [logoUrl, setLogoUrl] = useState(collection[0]?.logoUrl)

  const [coverImageUrl, setCoverImageUrl] = useState(
    collection[0]?.coverImageUrl != null
      ? URLs.CDN + collection[0]?.coverImageUrl
      : 'https://cdn.minicoursegenerator.com/assets/gateway/collection-cover.png',
  )

  const [isCollectionDeleteOpen, setIsCollectionDeleteOpen] = useState(false)
  const onDelete = () => {
    setIsCollectionDeleteOpen(true)
    handleClosePopup()
  }
  const [openPopup, setOpenPopup] = useState(null)

  const handleOpenPopup = event => {
    setOpenPopup(event.currentTarget)
  }
  const handleClosePopup = () => {
    setOpenPopup(null)
  }
  const renameRef = useRef(null)

  useEffect(() => {
    setLogoUrl(collection[0]?.logoUrl)

    let coverURL =
      collection[0]?.coverImageUrl != null
        ? collection[0]?.coverImageUrl.includes('http://') ||
          collection[0]?.coverImageUrl.includes('https://')
          ? collection[0]?.coverImageUrl
          : `${URLs.CDN}` + collection[0]?.coverImageUrl
        : 'https://cdn.minicoursegenerator.com/assets/gateway/collection-cover.png'

    setCoverImageUrl(coverURL)
  }, [collection[0]])

  const onLogoUpload = event => {
    if (logoUrl) return
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setLogoUrl(`${result.url}`)
        const patchSource = new PatchData(null, collection[0].id)
        patchSource.addContent(
          new PatchContent(result.url, 'logoUrl', PatchData.OPERATION_REPLACE),
        )

        dispatch(patchCourseCollectionAction(patchSource))
      }),
    )
  }

  const handleAddImage = () => {
    /**
     *
     * @param {} items
     */
    const onOk = item => {
      const itemUrl = item

      const patchSource = new PatchData(null, collection[0].id)
      patchSource.addContent(
        new PatchContent(itemUrl, 'coverImageUrl', PatchData.OPERATION_REPLACE),
      )
      setCoverImageUrl(itemUrl)
      dispatch(patchCourseCollectionAction(patchSource))
      dispatch(componentBoxHideAction())
    }
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <ContainerMediaGalery onOk={onOk} popupType={'collection-edit'} />,
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }
  const [value, setValue] = useState(
    collection.length > 0 ? collection[0]?.collectionName : '',
  )

  useEffect(() => {
    setValue(collection.length > 0 ? collection[0]?.collectionName : '')
  }, [collection[0]])

  const handleChangeColumnName = event => {
    setValue(event.target.value)
  }
  const handlePatchCourseName = newName => {
    dispatch(
      patchCourseCollectionAction(
        new PatchData(
          new PatchContent(
            newName,
            'collectionName',
            PatchData.OPERATION_REPLACE,
          ),
          collection[0].id,
        ),
      ),
    )
  }
  const handleUpdateColumn = event => {
    if (
      value != collection[0]?.collectionName &&
      (event.type === 'blur' || event.key === 'Enter') &&
      renameRef.current
    ) {
      renameRef.current.blur()
      value != collection[0]?.collectionName && handlePatchCourseName(value)
    }
  }

  const handleClickPreview = () => {
    track('collection_preview_clicked')
    window.open(
      URLs.APP_URL +
        'category/' +
        collection[0].collectionName
          .replaceAll(' ', '-')
          .replaceAll('?', '')
          .replaceAll('&', '') +
        '-1/' +
        collection[0].id,
    )
  }

  const handleClickShare = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleDeleteLogo = () => {
    setLogoUrl()
    const patchSource = new PatchData(null, collection[0].id)
    patchSource.addContent(
      new PatchContent('', 'logoUrl', PatchData.OPERATION_REPLACE),
    )
    setLogoUrl(null)
    dispatch(patchCourseCollectionAction(patchSource))
  }

  if (selectedCollection == DEFAULT_COLLECTION_GUID) {
    return ''
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.image__content}
        style={{
          backgroundImage: `url(${coverImageUrl})`,
        }}
      >
        <div
          className={classes.deleteIcon}
          onClick={() => {
            handleAddImage()
          }}
        >
          <Icon icon="fluent:edit-48-filled" color="#fff" fontSize={'20px'} />
          <Typography
            style={{
              color: '#fff',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            Update Cover
          </Typography>
        </div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.logoContainer}>
          {!logoUrl && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <Icon
                icon="ic:round-add-a-photo"
                color="#919EAB"
                fontSize={'27px'}
              />
              <Typography
                fontSize="14px"
                fontWeight={500}
                className={classes.logoText}
              >
                Add Logo
              </Typography>
            </div>
          )}
          {logoUrl && (
            <div
              className={classes.image_logo_content}
              style={{ backgroundImage: 'url(' + URLs.CDN + logoUrl + ')' }}
            ></div>
          )}
          {logoUrl && (
            <div className={classes.image__remove} onClick={handleDeleteLogo}>
              <DeleteIcon fontSize="small" />
            </div>
          )}

          {!logoUrl && (
            <input
              type="file"
              className={classes.file__upload}
              onChange={onLogoUpload}
            />
          )}
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.titleContainer}>
            <OutlinedInput
              size="small"
              value={value}
              onChange={handleChangeColumnName}
              onBlur={handleUpdateColumn}
              onKeyUp={handleUpdateColumn}
              inputRef={renameRef}
              disabled={isDefaultCollection}
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '& fieldset': {
                  border: 'none',
                },
                '&:hover .end-adornment-icon': {
                  visibility: 'visible',
                },
                width: 350,
                '& input': {
                  width: '100%',
                  boxSizing: 'border-box',
                },
              }}
              multiline
              maxRows={2}
              inputProps={{
                style: isDefaultCollection
                  ? {
                      color: 'black',
                      WebkitTextFillColor: 'black',
                    }
                  : {},
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  className="end-adornment-icon"
                  sx={{ visibility: 'hidden' }}
                >
                  <Icon icon="fluent:edit-48-filled" color="#000" />
                </InputAdornment>
              }
            />

            <div className={classes.buttonGroup}>
              <Button
                variant="text"
                className={classes.viewButton}
                onClick={() => {
                  handleClickPreview()
                }}
              >
                View
              </Button>
              <Button
                variant="contained"
                className={classes.shareButton}
                onClick={handleClickShare}
              >
                Share
              </Button>
              <IconButton
                size="small"
                onClick={handleOpenPopup}
                color={openPopup ? 'inherit' : 'default'}
                className={classes.iconButton}
              >
                <Icon icon={'eva:more-vertical-fill'} width={20} height={20} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            padding: '16px',
            borderRadius: '16px',
            width: '400px',
            minHeight: '460px',
          },
        }}
      >
        <ShareCollectionPopup entity={collection[0]} />
      </Popover>
      {isCollectionDeleteOpen && (
        <CollectionDelete
          classes={classes}
          dispatch={dispatch}
          collectionEntity={collection[0]}
          handleCloseModal={() => setIsCollectionDeleteOpen(false)}
          isModalOpen={isCollectionDeleteOpen}
          collections={collections}
        />
      )}
      {!isDefaultCollection && (
        <MenuPopover
          open={Boolean(openPopup)}
          anchorEl={openPopup}
          onClose={handleClosePopup}
          sx={{
            width: 'auto',
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
          }}
          disabledArrow
        >
          <MenuItem onClick={onDelete}>
            <Icon
              icon={'eva:trash-2-outline'}
              sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
            />
            Delete Collection
          </MenuItem>
        </MenuPopover>
      )}
    </div>
  )
}

export default withStyles(styles)(CollectionDetails)
