const data = [
  {
    icon: '/assets/automation/feature-icons.png',
    headerText: 'Seamless, password-less flows for learners',
    description:
      'Users can pass to the Mini Course Generator’s side without creating accounts, remembering passwords, or facing login barriers.',
  },
  {
    icon: '/assets/automation/feature-icons-1.png',
    headerText: 'Great versatility for many use cases',
    description:
      'From enriching training programs and workshops to selling courses, onboarding, and generating leads, flows can be created to meet a variety of needs.',
  },
  {
    icon: '/assets/automation/feature-icons-2.png',
    headerText: 'Flexibility for triggers and actions',
    description:
      'Create flows based on various triggers, such as completing a collection, surpassing a score, adding a new learner to a group, or processing a payment.',
  },
]

export default data
