import { FormItemActive, FormItemSubject, FormItemSendType } from './form-item'

const QuestionFiltersForm = {
  addTitle: null,
  editTitle: null,
  groups: [],
  formItemsSuggestion: [
    { ...FormItemSubject },
    { ...FormItemActive },
    { ...FormItemSendType },
  ],
  hasInitialFilter: true,
}

export default QuestionFiltersForm
