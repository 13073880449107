import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core'
import {
  CreateCertificate,
  Selections,
  AccordionPart,
  SuccessRate,
  CertificateEdit,
  Highlights,
} from './components'
import styles from './styles'
import { getCertificateAction } from 'src/reducers/certificate/action'
import IsActive from './components/is-active'
import UserType from 'src/model/user-types'
import UpgradeCertificate from 'src/components/upgrade-certificate'

const Certification = props => {
  const { classes, logoUrl, setLogoUrl } = props

  const dispatch = useDispatch()

  const certificateParent = useSelector(state => state.certificate)
  const certificate = certificateParent.certificate
  const collectionId = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const [certificateExist, setCertificateExist] = useState(false)
  const [certificateType, setCertificateType] = useState(0)

  //const [certificate, setCertificate] = useState(0)

  useEffect(() => {
    dispatch(
      getCertificateAction(collectionId, response => {
        //setCertificate(response)
        setCertificateType(response.type)
        setCertificateExist(response != null ? true : false)
      }),
    )
  }, [])




  if (window?.CONSTANTS.USER_ROLE_ID == 1) {
    return (
      <div className={classes.container}>
        <UpgradeCertificate></UpgradeCertificate>{' '}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {certificateExist ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <IsActive />
          <Selections
            certificate={certificate}
            certificateType={certificateType}
            setCertificateType={setCertificateType}
          />
          {certificateType === 1 && <SuccessRate />}
          <AccordionPart certificateType={certificateType} />
          <CertificateEdit logoUrl={logoUrl} />
          <Highlights
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
            certificate={certificate}
          />
        </div>
      ) : (
        <CreateCertificate setCertificateExist={setCertificateExist} />
      )}
    </div>
  )
}

export default withStyles(styles)(Certification)
