import React from 'react'
import { Icon } from '@iconify/react'
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Input,
  withStyles,
  TextField,
} from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useSelector } from 'react-redux'
import styles from '../../styles'
import { BackButton } from '..'
const StepOne = props => {
  const { entity, videoUrl, handleNext, handleBack, classes } = props

  const domain = entity.customDomainDtos
    ? entity.customDomainDtos.length == 0 ||
      entity.customDomainDtos[0].verificationStatus != 1
      ? 'https://share.minicoursegenerator.com'
      : entity.customDomainDtos[0].domain
    : 'https://share.minicoursegenerator.com'

  const [domainName, setDomainName] = React.useState(domain)

  /*const collectionName = entity.collectionName
    .replaceAll(/\?.*$/g, '')
    .replaceAll(' ', '-')*/

  const customDomain = useSelector(state => state.domain.list)

  const customDomainList = customDomain && customDomain.results
  const collectionDomain =
    customDomainList &&
    customDomainList.filter(
      item =>
        item.defaultCollectionDto != null &&
        item.defaultCollectionDto.id == entity.id,
    )
  const [domains, setDomains] = React.useState(collectionDomain)

  const hasVerifiedDomain =
    domains && domains.find(x => x.verificationStatus == 1)

  const [copiedShareCode, setCopiedShareCode] = React.useState(false)

  const onClickShareCode = () => {
    setCopiedShareCode(true)
    setTimeout(() => {
      setCopiedShareCode(false)
    }, 500)
  }

  const handleDomain = e => {
    setDomainName(e.target.value)
  }
  return (
    <div className={classes.stepOne_main}>
      <div className={classes.stepOne_main_title}>
        <Icon
          icon="octicon:play-24"
          width="20px"
          height="20px"
          style={{ color: '#FD2222', marginRight: '5px' }}
        ></Icon>
        <Typography fontSize={'14px'} fontWeight={400}>
          Watch the video guide
        </Typography>
      </div>
      <iframe
        width="440"
        height="235"
        src={videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className={classes.iframeStyle}
      ></iframe>
      {hasVerifiedDomain && (
        <div>
          <FormControl variant="outlined" className={classes.listGroup}>
            <Select
              variant="outlined"
              className={classes.select}
              inputProps={{
                className: classes.inputField,
              }}
              fullWidth
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Choose your domain"
              value={domainName}
              disabled={false}
              input={<Input id="select-input" name="select-input" />}
              onChange={handleDomain}
            >
              <MenuItem value={'share.minicoursegenerator.com'}>
                share.minicoursegenerator.com
              </MenuItem>
              {domains &&
                domains
                  .filter(x => x.verificationStatus == 1)
                  .map(value => {
                    return (
                      <MenuItem value={value.domain} key={value.domain}>
                        {value.domain}
                      </MenuItem>
                    )
                  })}
            </Select>
          </FormControl>
        </div>
      )}
      <TextField
        variant="outlined"
        value={
          hasVerifiedDomain
            ? 'https://' + domainName + '/payment-success'
            : domain + '/payment-success'
        }
        style={{
          width: '440px',
        }}
      />
      <CopyToClipboard
        text={
          hasVerifiedDomain
            ? 'https://' + domainName + '/payment-success'
            : domain + '/payment-success'
        }
        className={classes.shareViewCopyButton}
      >
        <Button
          style={{ textTransform: 'none', background:"none"  }}
          onClick={onClickShareCode}
          variant="outlined"
        >
          {copiedShareCode ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
      </CopyToClipboard>
      <div className={classes.stepOne_main_buttons}>
        <BackButton handleBack={handleBack} />
        <Button
          variant="contained"
          className={classes.continueButton}
          onClick={handleNext}
        >
          <Typography fontSize="14px" fontWeight={400}>
            Continue
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(StepOne)
