import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useDispatch } from 'react-redux'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import styles from './styles'

const CertificateListHeader = props => {
  const { classes, text, showIcon } = props
  const dispatch = useDispatch()
  const hide = () => {
    dispatch(componentBoxHideAction())
  }

  return (
    <div className={classes.header}>
      <Typography className={classes.typography}>{text}</Typography>
      {showIcon && (
        <Icon icon="uiw:close" onClick={hide} className={classes.icon} />
      )}
    </div>
  )
}

export default withStyles(styles)(CertificateListHeader)
