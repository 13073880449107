import React, { useState } from 'react'
import { Typography, TextField, withStyles } from '@material-ui/core'
import styles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { patchCertificateAction } from 'src/reducers/certificate/action'
import { PatchContent, PatchData } from 'src/model/patch'
const SuccessRate = props => {
  const dispatch = useDispatch()
  const { classes } = props
  const certificate = useSelector(state => state.certificate.certificate)

  const [percentage, setPercentage] = useState(
    certificate?.successRate != null ? certificate?.successRate : 0,
  )

  const handlePercentage = e => {
    //do not let the user enter more than 100
    let percentage = e
    if (e > 100) {
      percentage = 100
    }
    setPercentage(percentage)
  }

  const patchPercentage = e => {
    let percentage = e
    if (e > 100) {
      percentage = 100
    }
    const content = new PatchData(
      new PatchContent(percentage, 'successRate', PatchData.OPERATION_REPLACE),
      certificate.id,
    )
    dispatch(patchCertificateAction(content))
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.heading}>
        Award the badge if the success rate equals or exceeds
      </Typography>
      <TextField
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment: <Typography>%</Typography>,
        }}
        inputProps={{
          style: {
            height: 38,
            padding: '0 8px',
            '&::focus': {
              outline: '1px solid #015FFB',
            },
          },
        }}
        value={percentage}
        onChange={event => handlePercentage(event.target.value)}
        onBlur={event => patchPercentage(event.target.value)}
        className={classes.textField}
        //accepts only number
        type="number"
      />
    </div>
  )
}

export default withStyles(styles)(SuccessRate)
