import React, { useState } from 'react'
import styles from '../style'

import {
  Grid,
  Typography,
  withStyles,
  Badge,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import translator from 'src/translate'
import { AnswerItemType } from 'src/model/question'
import {
  QuestionFieldNames,
  isAnswerItemTypeResultCard,
} from 'src/model/question'

import { AssignmentFieldNames } from 'src/model/assignment'
const Completions = props => {
  const { classes, saveType, entity, type } = props
  const [lockedMessageOpen, setLockedMessageOpen] = useState(
    entity
      ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
          isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
        ) != null
      : false,
  )
  const BasicOptions = [
    {
      name: 'default',
      icon: '/assets/icons/task/finish_card.png',
      title: 'Standard',
      description: translator._('tasks.score.description'),
      description2: translator._('tasks.score.description2'),
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.RESULTDEFAULT,
      disableLockedMessage: false,
      lockedMessage: 'You added a completion card',
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
            isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
          ) != null
        : false,
    },

    {
      name: 'result',
      icon: '/assets/icons/task/completion-icon.png',
      title: 'Conditional',
      description: translator._('tasks.result.description'),
      description2: translator._('tasks.result.description2'),
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.RESULT,
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
            isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
          ) != null
        : false,
      disableLockedMessage: true,
    },
    /*  {
      name: 'sertifier',
      icon: '/assets/icons/task/sertifier.png',
      title: 'Certification with Sertifier',
      description: translator._('tasks.sertifier.description'),
      description2: translator._('tasks.sertifier.description2'),
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.SERTIFIERCARD,
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
            isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
          ) != null
        : false,
      disableLockedMessage: true,
    }, */
    {
      name: 'score',
      icon: '/assets/icons/task/score_card.png',
      title: 'Score',
      description: translator._('tasks.score.description'),
      description2: translator._('tasks.score.description2'),
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.SCORECARD,
      disableLockedMessage: false,
      lockedMessage: 'You added a completion card',
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
            isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
          ) != null
        : false,
    },
    {
      name: 'badge',
      icon: '/assets/icons/task/badge.png',
      title: 'Badge',
      description: 'Badge',
      description2: 'Badge',
      exampleImage: '/assets/icons/task/star-rating-example.png',
      dataType: AnswerItemType.BADGE,
      disableLockedMessage: false,
      lockedMessage: 'You added a completion card',
      locked: entity
        ? entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
            isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
          ) != null
        : false,
    },
  ]

  return (
    <div container className={classes.list}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          className={classes.listTitle}
          style={{
            opacity:
              entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
                ? 0.3
                : 1,
          }}
        >
          COMPLETIONS
        </Typography>
        {type != 'end' && !lockedMessageOpen && (
          <Tooltip title="Can be used as last card">
            <InfoIcon
              style={{ marginTop: '2px', marginLeft: '5px' }}
              fontSize="small"
              color="primary"
            />
          </Tooltip>
        )}
        {type != 'end' && lockedMessageOpen && (
          <Tooltip title="You already used completion card!">
            <InfoIcon
              style={{ marginTop: '2px', marginLeft: '5px' }}
              fontSize="small"
              color="primary"
            />
          </Tooltip>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        {BasicOptions.map((option, index) => {
          return (
            <div
              className={classes.listItems}
              onClick={() => !option.locked && saveType(option.dataType)}
            >
              <img
                alt="cardIcon"
                src={option.icon}
                className={classes.listImage}
                style={{ opacity: !option.locked ? 1 : 0.3 }}
              />

              <Typography
                className={classes.listTextLocked}
                style={{
                  color: !option.locked
                    ? 'rgb(38, 38, 39)'
                    : 'rgb(138, 138, 138)',
                }}
              >
                {option.title}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(Completions)
