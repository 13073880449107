import { put, call, take, fork } from 'redux-saga/effects'
import { deleteCourseCollectionService } from '../../api/course-collection'

import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import { deleteCourseCollectionSuccess } from 'src/reducers/course-collection/action'
import { DELETE_COURSE_COLLECTION } from 'src/reducers/course-collection/type'

/**
 *
 * @param {*} payload
 */
function* deleteCourseCollectionSagaFlow(payload) {
  try {
    const { id, callback } = payload
    const response = yield call(deleteCourseCollectionService, id)
    window.location.href = '/'

   //yield put(deleteCourseCollectionSuccess(id))
    yield put(operationSuccess())

    callback(id)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* deleteCourseCollectionWatcher() {
  while (true) {
    const action = yield take(DELETE_COURSE_COLLECTION)
    const { payload } = action

    yield fork(generalSaga, deleteCourseCollectionSagaFlow, payload)
  }
}
