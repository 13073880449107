import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from './store'
import { SnackbarProvider } from 'notistack'
import { get as getCookie } from 'es-cookie'
import { ThemeConfig } from './theme'
import { createBrowserHistory } from 'history'
import AppOld from './app_old'

let lang

if (getCookie('language') !== undefined) {
  lang = getCookie('language')
} else {
  lang = 'tr'
}

const store = configureStore()
const history = createBrowserHistory()

function App() {
  useEffect(() => {
    window.CONSTANTS = {
      TIME_ZONE: 'Europe/Istanbul',
      DATE_FORMAT: 'DD-M-YYYY',
      NAME: ' ',
      SUR_NAME: ' ',
      EMAIL: ' ',
      COMPANY_LOGO: '',
      CUSTOM_DOMAIN: null,
      LOCALIZATION_STRINGS: {},
    }
  }, [])

  return (
    <Provider store={store}>
      <ThemeConfig>
        <SnackbarProvider>
          <AppOld />
        </SnackbarProvider>
      </ThemeConfig>
    </Provider>
  )
}

export default App
