import React, { useState } from 'react'
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Input,
  Divider,
  Typography,
  InputLabel,
  FormControlLabel, Switch
} from '@material-ui/core'
import styles from './styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useSelector } from 'react-redux'
import { Icon } from '@iconify/react'
import { track } from '@amplitude/analytics-browser'

import translator from 'src/translate'
import CustomizedSwitch from 'src/components/customized-switch'
const ShareView = props => {
  const { entity, handleView, classes } = props

  const [copiedShareCode, setCopiedShareCode] = useState(false)
  const [showShareButtons, setShowShareButtons] = useState(0)

  const onClickShareCode = () => {
    setCopiedShareCode(true)
    setTimeout(() => {
      setCopiedShareCode(false)
    }, 1000)
  }

  const openNewWindow = () => {
    track('collection_preview_clicked')
    window.open(
      'https://' +
      domainName +
      '/category/' +
      collectionName
        .replaceAll(' ', '-')
        .replaceAll('?', '')
        .replaceAll('&', '') +
      '-1/' +
      entity.id +
      shareCode
    )
  }

  const domain = entity.customDomainDtos
    ? entity.customDomainDtos.length == 0 ||
      entity.customDomainDtos[0].verificationStatus != 1
      ? 'share.minicoursegenerator.com'
      : 'https://' + entity.customDomainDtos[0].domain + '/'
    : 'share.minicoursegenerator.com'

  const [domainName, setDomainName] = useState(domain)

  const collectionName = entity.collectionName
    .replaceAll(/\?.*$/g, '')
    .replaceAll(' ', '-')

  const customDomain = useSelector(state => state.domain.list)

  const customDomainList = customDomain && customDomain.results
  const collectionDomain =
    customDomainList &&
    customDomainList.filter(
      item =>
        item.defaultCollectionDto != null &&
        item.defaultCollectionDto.id == entity.id,
    )
  const [domains, setDomains] = useState(collectionDomain)

  const handleDomain = e => {
    setDomainName(e.target.value)
  }
  let shareCode = showShareButtons ? '?shr=1' : ''
  return (
    <div className={classes.shareViewRoot}>
       <div className={classes.settingsShare}>
        <FormControlLabel
          control={
            <CustomizedSwitch
              checked={showShareButtons}
              onChange={(e, checked) => {
                setShowShareButtons(checked)
              }}
              name="checkedB"
            />
          }
          label={translator._('assignment.showShareButtonsLabel')}
        />
      </div>
      
      <Typography fontSize={14} fontWeight={500}>
        Choose your custom domain linked to your collection
      </Typography>
      <div>
        <FormControl
          variant="outlined"
          className={classes.shareViewFormControl}
        >
          <InputLabel>Choose your domain</InputLabel>
          <Select
            labelId="domain-selection-label"
            id="domain-selection-select"
            fullWidth
            label="Choose your domain"
            value={domainName}
            disabled={false}
            onChange={handleDomain}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    marginLeft: '8px',
                    marginRight: '8px',
                    borderRadius: '8px',
                    marginTop: '4px',
                    marginBottom: '4px',
                  },
                },
              },
            }}
          >
            <MenuItem value={'share.minicoursegenerator.com'}>
              (Default) share.minicoursegenerator.com
            </MenuItem>

            {domains &&
              domains
                .filter(x => x.verificationStatus == 1)
                .map(value => {
                  return (
                    <MenuItem value={value.domain} key={value.domain}>
                      {value.domain}
                    </MenuItem>
                  )
                })}
          </Select>
        </FormControl>
      </div>

      <TextField
        fullWidth
        value={
          'https://' +
          domainName +
          '/category/' +
          collectionName
            .replaceAll(' ', '-')
            .replaceAll('?', '')
            .replaceAll('&', '') +
          '-1/' +
          entity.id +
          shareCode
        }
        className={classes.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={()=> openNewWindow()} className={classes.adornment}>
              <Icon icon="fluent:open-24-regular" color="#212B36" fontSize={20} />
            </InputAdornment>
          ),
          classes: {
            input: classes.shareViewTextFieldAdornment.input,
          },
        }}
      />

      <CopyToClipboard
        text={
          'https://' +
          domainName +
          '/category/' +
          collectionName
            .replaceAll(' ', '-')
            .replaceAll('?', '')
            .replaceAll('&', '') +
          '-1/' +
          entity.id +
          shareCode
        }
        className={classes.shareViewCopyButton}
      >
        <Button
          className={classes.shareViewCopyButton}
          onClick={onClickShareCode}
          variant="contained"
        >
          {copiedShareCode ? 'Copied!' : 'Copy Link'}
        </Button>
      </CopyToClipboard>

      <Divider className={classes.shareViewDivider} />

      <div
        className={classes.shareViewOptions}
        onClick={() => handleView('embed')}
      >
        <div className={classes.shareViewEmbedOption}>
          <Icon icon="iconoir:code" color="#002E47" fontSize={24} />
          <Typography
            style={{
              marginBottom: '4px',
            }}
          >
            Embed on a Website
          </Typography>
        </div>
        <Icon icon="ic:round-chevron-right" color="#002E47" fontSize={24} />
      </div>
    </div>
  )
}

export default withStyles(styles)(ShareView)
