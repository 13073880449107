import clsx from 'clsx'
import React, { useEffect } from 'react'
import Account from './Account'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
//import menu2Fill from '@iconify-icons/eva/menu-2-fill'
//import heartOutline from '@iconify-icons/eva/heart-outline'
//import robotLoveOutline from '@iconify/icons-mdi/robot-love-outline'
//import robotLoveOutline from '@iconify/icons-file-icons/robots'
//import robotLoveOutline from '@iconify/icons-eos-icons/machine-learning-outlined'
//import robotLoveOutline from '@iconify/icons-simple-icons/probot'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'

import {
  Box,
  AppBar,
  //Hidden,
  Toolbar,
  //IconButton,
  Button,
  Typography,
  withStyles,
} from '@material-ui/core'
import Logo from 'src/components/logo/index'
import { Link as RouterLink } from 'react-router-dom'
import translator from 'src/translate'
import styles from '../styles'
import { track } from '@amplitude/analytics-browser'
// ----------------------------------------------------------------------

//const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 70

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    //borderBottom: '1px solid rgb(239 239 239)',
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
      //paddingLeft: DRAWER_WIDTH,
    },
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
    },
  },
  upgrade_button: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
      padding: '4px 12px',
    },
  },
  button: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
      padding: '0px 6px',
    },
  },
  buttonText: {
    fontSize: '12px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
    },
  },
}))

// ----------------------------------------------------------------------

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string,
}

function TopBar(props) {
  const classes = useStyles()
  const { /*onOpenNav,*/ className, dispatch } = props
  const pageUrl = window.location.pathname.split('/')[1]

  const [pageController, setPageController] = React.useState({
    mainPage: pageUrl == '',
    teamsPage: pageUrl.includes('learners'),
    customDomainPage: pageUrl.includes('custom-domain'),
    automations: pageUrl.includes('automations'),
  })

  useEffect(() => {
    setPageController({
      mainPage: pageUrl == '',
      teamsPage: pageUrl.includes('learners'),
      customDomainPage: pageUrl.includes('custom-domain'),
      automations: pageUrl.includes('automations'),
    })
  }, [pageUrl])

  /*const openFreshDesk = () => {
    window.FreshworksWidget(
      'identify',
      'ticketForm',
      {
        name: window.CONSTANTS.NAME + ' ' + window.CONSTANTS.SUR_NAME,
        email: window.CONSTANTS.EMAIL,
      },
      {},
    )

    window.FreshworksWidget('open', 'ticketForm', {})
  }*/

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {/*  <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <Logo />

          <Button
            className={classes.button}
            to="/"
            sx={{
              color: '#002E47',
              '&:hover': {
                color: '#002E47',
                border: 'none',
              },
            }}
            variant="text"
            component={RouterLink}
          >
            <Typography
              className={classes.buttonText}
              fontWeight={pageController.mainPage ? 'bold' : 'normal'}
            >
              My Collections
            </Typography>
          </Button>

          {!isMobile && (
            <>
              <Button
                className={classes.button}
                to="/learners"
                sx={{
                  color: '#002E47',
                  '&:hover': {
                    color: '#002E47',
                    border: 'none',
                  },
                }}
                variant="text"
                component={RouterLink}
              >
                <Typography
                  className={classes.buttonText}
                  fontWeight={pageController.teamsPage ? 'bold' : 'normal'}
                >
                  Learners
                </Typography>
              </Button>
              <Button
                className={classes.button}
                to="/custom-domain"
                sx={{
                  color: '#002E47',
                  '&:hover': {
                    color: '#002E47',
                    border: 'none',
                  },
                }}
                variant="text"
                component={RouterLink}
              >
                <Typography
                  className={classes.buttonText}
                  fontWeight={
                    pageController.customDomainPage ? 'bold' : 'normal'
                  }
                >
                  Custom Domain
                </Typography>
              </Button>
              <Button
                className={classes.button}
                to="/automations"
                sx={{
                  color: '#002E47',
                  '&:hover': {
                    color: '#002E47',
                    border: 'none',
                  },
                }}
                variant="text"
                component={RouterLink}
              >
                <Typography
                  className={classes.buttonText}
                  fontWeight={pageController.automations ? 'bold' : 'normal'}
                >
                  Automations
                </Typography>
              </Button>
            </>
          )}
        </div>

        <Box sx={{ flexGrow: 1 }}>
          {/*  <Button
            to="/pricing"
            sx={{
              mr: 1,
              color: 'text.primary',
            }}
            variant="outlined"
            startIcon={<Icon icon={heartOutline} />}
            component={RouterLink}
          >
            Upgrade
          </Button> */}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 1,
              },
            },
          }}
        >
          {pageUrl != 'mini-course' && window.CONSTANTS.USER_ROLE_ID == 1 && (
            <Button
              className={classes.upgrade_button}
              to="/pricing"
              sx={{
                mr: 1,
                color: 'text.primary',
              }}
              variant="outlined"
              startIcon={
                <Icon
                  size="small"
                  icon="fa6-solid:crown"
                  color="#f7b155"
                  width={15}
                ></Icon>
              }
              component={RouterLink}
              onClick={() => {
                track('limit_warning_upgrade', {
                  from: 'dashboard_upgrade_button',
                })
              }}
            >
              {translator._('general.upgrade')}
            </Button>
          )}

          {/*pageUrl != 'mini-course' && (
            <Button
              className={classes.button}
              to="/create-course-with-ai"
              sx={{
                mr: 1,
                color: 'text.primary',
              }}
              variant="outlined"
              startIcon={<Icon icon={robotLoveOutline} />}
              component={RouterLink}
              color="secondary"
            >
              {translator._('general.aiassistant')}
            </Button>
          )*/}
          {/* <Button
            className={classes.button}
            sx={{
              mr: 1,
              color: 'text.primary',
            }}
            variant="outlined"
            onClick={() => {
              openFreshDesk()
            }}
          >
            {translator._('general.getInTouch')}
          </Button> */}
          {/*pageUrl != 'mini-course' && (
            <Button
              className={classes.button}
              to="/help-center"
              target="_blank"
              sx={{
                mr: 1,
                color: 'text.primary',
              }}
              variant="outlined"
              component={RouterLink}
              onClick={() => {
                track('knowledge_base_clicked')
              }}
            >
              Knowledge Base
            </Button>
            )*/}
          <Account dispatch={dispatch} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(TopBar)
