const styles = theme => ({
  customDomain__root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
  },
  displayF: {
    display: 'flex',
  },
  customDomain__headerWithIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  customDomain__icon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
    marginTop: '3px',
  },
  customDomain__domainView: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    height: '53px',
    padding: '16px',
  },
  customDomain__cname: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 8,
  },
  customDomain__addDomainButton: {
    backgroundColor: '#37D768',
    color: '#fff',
    borderRadius: '12px',
    width: '116px',
    border: 'none',
    boxShadow: 'none',
    height: '40px',
    '&:hover': {
      backgroundColor: '#37D768',
    },
  },
  customDomain__buttonDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customDomain__cnameList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    marginTop: '32px',
    padding: '12px 16px',
  },
  customDomain__headerList: {
    padding: '0px 16px',
  },
  customDomain__list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  customDomain__listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    maxWidth: 700,
    maxHeigh: 600,
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: '16px',
    backgroundColor: '#F4F6F8',
    padding: '24px 16px',
  },
  textField: {
    width: 300,
  },
  button: {
    backgroundColor: 'rgba(0, 171, 85, 0.08)',
    color: '#00AB55',
    marginLeft: '40%',
    margin: '25px 0 15px 0',
  },
  next: {
    marginTop: theme.spacing(2),
  },
  input: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  inputWrapper: {
    borderColor: '#d6d6d6',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
  },
  limitModalImage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '20px',
  },
})

export default styles
