const data = [
  {
    info:
      'Personalized variables can be used through dynamic attributes that are unique for each learner.',
    buttonText: 'Dynamic Attributes',
  },
  {
    info:
      'You can set the gateway for how learners access your collection. If there is no gateway, there will be no login, and therefore, their name and surname cannot be collected.',
    buttonText: 'Learners’ Access',
  },
  {
    info: 'You can add your logo or change it.',
    buttonText: 'Change Logo',
  },
]

export default data
