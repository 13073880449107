import React, { useEffect, useState } from 'react'
// material
import { Container, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import style from './style'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'
import { addCustomDomainAction } from 'src/reducers/domain/action'
import { CaseZero, CaseOne, ContainerContent } from './components'
import CustomDomainNonPremiumView from 'src/components/custom-domain-non-premium-view'

import { track } from '@amplitude/analytics-browser'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function getSteps() {
  return ['Add following CNAME to your DNS Records', 'Add Domain']
}

function CustomDomain(props) {
  const { dispatch, classes, selectedItem } = props

  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [domain, setDomain] = useState(null)
  const [verified, setVerified] = useState(true)
  const [error, setError] = useState(false)
  const [courses, setCourses] = useState()
  const [collections, setCollections] = useState()
  const [courseName, setCourseName] = useState()
  const [courseId, setCourseId] = useState()
  const [collectionName, setCollectionName] = useState()
  const [collectionId, setCollectionId] = useState()
  const [type, setType] = useState('')
  const [visible, setVisible] = useState('hidden')
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }
  /**
   *
   * @param {*} e
   */
  const handleCourse = e => {
    setCourseName(e.target.value)
    setCourseId(e.target.value)
  }
  const handleCollection = e => {
    setCollectionName(e.target.value)
    setCollectionId(e.target.value)
  }
  const handleType = e => {
    setType(e.target.value)
  }
  const handleAddDomain = async () => {
    setError(false)
    setLoading(true)
    const data = {
      domain: domain,
      defaultCollectionId: type == 'Course' ? null : collectionId,
      defaultCourseId: type == 'Course' ? courseId : null,
      type: type,
    }
    dispatch(addCustomDomainAction(data, response => {}))

    setLoading(false)
    setDomain('')
    setActiveStep(0)
  }
  useEffect(() => {
    if (type != '') setVisible('visible')
    else if (type == '') setVisible('hidden')
  }, [type])

  useEffect(() => {
    if (!courses && !collections) {
      const query = {
        CurrentPage: 0,
        PageSize: 200,
        SortingColumn: 'LastModifiedAt',
        Asc: false,
        FilterText: '',
        Filters: [],
      }
      dispatch(
        courseCollectionListAction(
          { ...query, SortingColumn: 'LastModifiedAt' },
          data => {
            setCourses(data.courseDtos)
            setCollections(data.courseCollectionDtos)
          },
        ),
      )
    }
  }, [])
  const onClickUpgrade = () => {
    track('limit_warning_upgrade', {
      from: 'cname_limit_upgrade',
    })
    window.location.href = '/pricing'
  }
  const getStepContent = step => {
    switch (step) {
      case 0:
        return <CaseZero />

      case 1:
        return (
          <CaseOne
            courses={courses}
            collections={collections}
            loading={loading}
            error={error}
            verified={verified}
            domain={domain}
            setDomain={setDomain}
            type={type}
            courseId={courseId}
            collectionId={collectionId}
            courseName={courseName}
            collectionName={collectionName}
            handleType={handleType}
            handleCourse={handleCourse}
            handleCollection={handleCollection}
            handleAddDomain={handleAddDomain}
          />
        )

      default:
        return 'Unknown step'
    }
  }

  return (
    <Container style={{}}>
      {window.CONSTANTS.USER_ROLE_ID !== 1 && (
        <ContainerContent
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          getStepContent={getStepContent}
          handleNext={handleNext}
        />
      )}

      {window.CONSTANTS.USER_ROLE_ID === 1 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomDomainNonPremiumView onClickUpgrade={onClickUpgrade} />
        </div>
      )}
    </Container>
  )
}

export default connect()(withStyles(style)(CustomDomain))
