import React from 'react'
import styles from './styles'

import { withStyles, styled } from '@material-ui/core/styles'
import {
  Typography,
  List,
  ListItem,
  Button,
  Radio,
  TextField,
  Alert,
  Box,
} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: '0px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '0px',
  },
})
const StepTitle = props => {
  const {
    errorMessage,
    titleOptions,
    handleChange,
    selectedTitle,
    hasOwnTitle,
    handleTitleItemChange,
    inputFocus,
    addOwnTitle,
    sendOutlineRequest,
    selectedTitleIndex,
    classes,
  } = props
  return (
    <>
      <List className={classes.list}>
        {titleOptions &&
          titleOptions.map((titleOption, index) => (
            <ListItem
              key={'title_' + index}
              /* secondaryAction={
                    <IconButton edge="end" aria-label="edit">
                      <EditIcon />
                    </IconButton>
                  } */ onClick={() => {
                handleChange(titleOption, index)
              }}
              className={classes.listItem}
            >
              <Radio
                checked={index === selectedTitleIndex}
                onChange={() => {
                  handleChange(titleOption, index)
                }}
                value={titleOption}
                name="radio-buttons"
                inputProps={{ 'aria-label': index }}
              />
              {/* <ListItemText id={titleOption} primary={titleOption} /> */}
              <CssTextField
                id={'title_' + index}
                key={'title_input_' + index}
                defaultValue={titleOption}
                variant="standard"
                style={{ borderBottom: 0, width: '100%' }}
                autoFocus={hasOwnTitle}
                multiline
                onBlur={e => {
                  handleTitleItemChange(e, index)
                }}
                onChange={e => {
                  handleChange(e.target.value, index)
                }}
                size="small"
              />

              <EditIcon
                onClick={() => {
                  inputFocus('title_' + index)
                }}
                color="primary"
                fontSize="small"
              />
            </ListItem>
          ))}

        <Button
          variant="text"
          size="small"
          onClick={() => addOwnTitle()}
          style={{
            display: hasOwnTitle == true ? 'none' : 'block',
            marginTop: 6,
          }}
        >
          <Typography fontSize={12} fontStyle="normal">
            Write your own title instead
          </Typography>
        </Button>
      </List>
      {errorMessage == true && (
        <>
          <Alert severity="warning">
            A problem occurred due to GPT-3. Please try again.
          </Alert>
          <Box style={{ marginBottom: '16px' }}></Box>
        </>
      )}
      <Button
        variant="contained"
        onClick={() => sendOutlineRequest()}
        disabled={selectedTitle == undefined ? true : false}
      >
        Generate Outline
      </Button>
    </>
  )
}

export default withStyles(styles)(StepTitle)
