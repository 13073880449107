import React, { useEffect, useState, useRef } from 'react'
import styles from './style'
import propTypes from './prop'
import translator from 'src/translate'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { openaiSendCardRequestAction } from 'src/reducers/assignment/action'
import AICreationLimitModal from 'src/components/ai-creation-limit-modal/index'
import {
  Grid,
  withStyles,
  Button,
  Typography,
  TextField,
  Alert,
  Card,
  Box,
  Paper,
  CircularProgress,
  Divider,
  Accordion,
} from '@material-ui/core'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import moment from 'moment-timezone'
import { Icon } from '@iconify/react'
import { Link as RouterLink } from 'react-router-dom'

import { getUserLimits } from 'src/api/limit'
import { LimitTypeEnum } from 'src/model/limit'
import { track } from '@amplitude/analytics-browser'
import { isMobile } from 'react-device-detect'
const AccordionContents = [
  {
    icon: 'ph:text-align-left',
    header: 'Let AI follow-up your introduction',
    description:
      'Add 2-3 descriptive sentences in your topic, click "generate", and let AI bring the following sentences.',
    details: [],
    panelName: 'panel1',
    display: 'initial',
  },
  {
    icon: 'system-uicons:create',
    header: 'Create an introduction / outline',
    description: `Samples:`,
    details: [
      `I want you to act as a well-being author focusing on meditation in professional life. How would be an introduction to my course?`,
      `Meditation can be a natural, free, and effective medicine during an economic crisis when we might feel unconfident about our future. Let's dive into its details with this outline:`,
      `Bring me an outline about meditation during a hectic work schedule`,
    ],
    panelName: 'panel2',
    display: 'initial',
  },
  {
    icon: 'material-symbols:format-list-numbered-rounded',
    header: 'Lists with X number of ways, steps, etc.',
    description: `Samples:`,
    details: [
      `Although we are exposed to high daily stress, we can not find or spare time to relax and meditate. 5 common excuses can be listed as follows:`,
      `10 ways to mediate during hectic business life to be more productive`,
      `Create a list of 7 questions for my course about time management`,
    ],
    panelName: 'panel3',
    display: 'initial',
  },
  {
    icon: 'material-symbols:chat-bubble-outline-rounded',
    header: 'Bring explanations to questions',
    description: `Samples:`,
    details: [
      `How to use meditation in business life to feel more productive during the day?`,
      `How to use Discord for business?`,
    ],
    panelName: 'panel4',
    display: 'initial',
  },
  {
    icon: 'fluent:autocorrect-24-regular',
    header: 'Correct to standard English',
    description: `Sample:`,
    details: [
      `Correct this to standard English: Let's dive off meditation tchniques during the hard times above the economy when we feel not confident about ourselves future.`,
    ],
    panelName: 'panel5',
    display: 'none',
  },
  {
    icon: 'icon-park-outline:change',
    header: 'Rewrite / Repurpose / Paraphrase',
    description: `Sample:`,
    details: [
      'Paraphrase this: meditation is a free, harmless, and great medicine that can be done to cheer up the day. Besides, allocating daily 3-5 minutes is more than enough.',
    ],
    panelName: 'panel6',
    display: 'none',
  },
  {
    icon: 'mdi:comedy',
    header: 'Create analogies, anecdotes, ads, jokes, etc.',
    description: `Samples:`,
    details: [
      `Create an analogy including meditation in business life and football`,
      `Create a anecdote about a politician who ignores meditation and finds it unnecessary`,
      `Create an ad about my meditation course, which targets stressful professionals in their work life`,
    ],
    panelName: 'panel7',
    display: 'none',
  },
  {
    icon: 'material-symbols:question-mark-rounded',
    header: 'Create questions with their answers',
    description: `Sample:`,
    details: [
      `Create a question about meditation in work life with an answer.`,
    ],
    panelName: 'panel8',
    display: 'none',
  },
  {
    icon: 'material-symbols:short-text-rounded',
    header: 'Summarize a long text',
    description: `Sample:`,
    details: [`Summarize this:(add your long text)`],
    panelName: 'panel9',
    display: 'none',
  },
]

const OpenAiPopup = props => {
  const { classes, dispatch, value, addTextToTextArea, entity, editor } = props

  const myRef = useRef(null)

  const [starterText, setStarterText] = useState(
    value
      .replaceAll('&nbsp;', '')
      .replace(/(<([^>]+)>)/gi, '')
      .split('+++')[0],
  )
  const executeScroll = () => myRef.current.scrollIntoView()
  const [options, setOptions] = useState()
  const [optionCount, setOptionCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isAiCard, setIsAiCard] = useState(false)
  const [roleType, setRoleType] = useState()

  const [isCardEmpty, setIsCardEmpty] = useState(
    entity.type == 17 || (starterText == '' && entity.type == 1) ? true : false,
  )
  const [seeMore, setSeeMore] = useState(false)
  const [count, setCount] = useState('')
  const [limit, setLimit] = useState('')
  const [expanded, setExpanded] = useState('panel1')
  const [refreshesAt, setRefreshesAt] = useState('')
  const [limitLoading, setLimitLoading] = useState(false)
  const [emptyChoices, setEmptyChoices] = useState(false)
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState(true)
  const courseId = window.location.href.split('mini-course/')[1]

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const AccordionDetails = withStyles(theme => ({
    root: {
      padding: '8px 16px 16px',
    },
  }))(MuiAccordionDetails)

  const AccordionSummary = withStyles({
    root: {
      //backgroundColor: 'rgb(255 255 255 / 40%)',
      marginBottom: -1,
      minHeight: 54,
      '&$expanded': {
        minHeight: 54,
        borderBottom: '1px solid #f4f4f4',
      },
    },
    content: {
      '&$expanded': {
        margin: 0,
      },
    },
    expanded: {},
  })(MuiAccordionSummary)
  /**
   *
   */
  const sendRequest = e => {
    if (isMobile && e == 'generate new options') {
      executeScroll()
    }

    setLoading(true)
    dispatch(
      openaiSendCardRequestAction(
        starterText,
        courseId,
        entity.id,
        response => {
          track('AI Request', {
            courseId: courseId,
            cardId: entity.id,
          })

          if (
            response.choices[0].text == '' &&
            response.choices[1].text == '' &&
            response.choices[2].text == ''
          ) {
            setEmptyChoices(true)
            setLoading(false)
            if (e == 'generate new options') {
              setOptions(response.choices.filter(x => x.text != ''))
            } else {
              !options && setOptions(response.choices.filter(x => x.text != ''))
              options &&
                setOptions(
                  options.concat(response.choices.filter(x => x.text != '')),
                )
            }
          } else {
            if (e == 'generate new options') {
              setOptions(response.choices.filter(x => x.text != ''))
            } else {
              !options && setOptions(response.choices.filter(x => x.text != ''))
              options &&
                setOptions(
                  options.concat(response.choices.filter(x => x.text != '')),
                )
            }
            setLoading(false)
            setCount(count + 1)
          }
        },
      ),
    )
  }

  useEffect(() => {
    setLimitLoading(true)
    value.includes('+++') && editor.setContents(value.replace('+++', ''))
    starterText == '' && setIsAiCard(true)
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).limit.upperLimit

      let refreshedAt = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).refreshedAt
      setRoleType(response.data[0].limit.roleType)
      setRefreshesAt(refreshedAt)
      setLimit(limit)
      setCount(count)
      setLimitLoading(false)

      refreshedAt.includes('2022') && setRefreshDateAvaliable(false)
    })
  }, [entity])

  useEffect(() => {
    count < limit && !isAiCard && sendRequest()
  }, [limitLoading])

  const setAnswer = e => {
    const toAddedText = e
    var newCardText = isAiCard
      ? `${starterText + ' ' + toAddedText}`
      : value.replace('+++', ' ' + toAddedText)
    track('AI Option Selected', {
      courseId: courseId,
      cardId: entity.id,
    })

    addTextToTextArea(newCardText)
    handleClickRedirect()
  }
  const handleClickRedirect = () => {
    dispatch(componentBoxHideAction())
  }

  const onBlur = event => {
    setStarterText(event.target.value)
    addTextToTextArea(event.target.value)
  }

  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '70px',
            marginRight: '-24px',
            marginLeft: '-24px',
            borderRadius: '8px',
            alignItems: 'center',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <img
            src="/assets/AI_icon.png"
            style={{ marginLeft: '25px', height: '25px', marginRight: '15px' }}
          />
          <Typography variant="h5" color="primary">
            AI Assistant
          </Typography>
        </Card>
        {limitLoading && (
          <Box className={classes.circularBox} mt={5}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
      {!limitLoading && (
        <Grid container className={classes.distantGrid}>
          <Grid item xs={5} p={2} className={classes.grid}>
            {!isAiCard ? (
              <>
                <Typography variant="h6" marginBottom={1}>
                  Input
                </Typography>
                <Typography>{starterText.replaceAll('&nbsp;', '')}</Typography>
                <img src="/assets/skeleton.png" style={{ marginTop: '15px' }} />
              </>
            ) : (
              <>
                <Typography variant="h6" marginBottom={2}>
                  Input
                </Typography>
                <TextField
                  label="Your prompt text"
                  multiline
                  rows={8}
                  onBlur={event => {
                    onBlur(event)
                  }}
                  style={{ width: '100%', marginBottom: 16 }}
                ></TextField>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {!loading ? (
                    <Button
                      variant="contained"
                      fullWidth={false}
                      style={{ maxWidth: '200px' }}
                      startIcon={<img src="/assets/ai-white.png" height="14" />}
                      onClick={() => sendRequest('generate new options')}
                    >
                      Call AI-Assistant
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </>
            )}
            <div style={{ flexGrow: 1 }}></div>
            <Divider
              style={{
                borderStyle: 'outset',
                width: '100%',
              }}
            />

            {refreshDateAvaliable == true ? (
              limit > count || limit != count ? (
                <>
                  <p className={classes.limitText}>
                    {limit - count} credits left. Renewal date:
                    {moment(refreshesAt).add('days', 30).format('L')}.
                  </p>
                </>
              ) : (
                <p className={classes.limitText}>You have no credits left!</p>
              )
            ) : (
              (limit > count || limit != count) && (
                <>
                  <p className={classes.limitText}>
                    Your AI credits will be renewed every 30 days after your
                    first usage.
                  </p>
                </>
              )
            )}
          </Grid>

          <Grid item xs={7} p={2} className={classes.secondaryGrid} ref={myRef}>
            {!emptyChoices && options && (
              <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
                <Typography variant="h6" color="primary">
                  Output
                </Typography>
              </div>
            )}
            {!emptyChoices &&
              options &&
              options.map((option, index) =>
                option.text == '' ? (
                  ''
                ) : (
                  <Paper
                    key={option.index}
                    className={classes.paper}
                    sx={{
                      p: 2,
                      width: 1,
                      bgcolor: '#fffff',
                    }}
                    onClick={() => {
                      setAnswer(option.text)
                    }}
                  >
                    <div className={classes.closeButton}>
                      <Typography
                        style={{
                          color: '#0d53b7',
                          fontSize: 14,
                          marginBottom: '2px',
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </div>
                    <Typography variant="body2">{option.text}</Typography>
                  </Paper>
                ),
              )}
            {emptyChoices && options && (
              <Alert severity="warning" style={{ alignItems: 'center' }}>
                <p style={{ textAlign: 'center' }}>
                  Sorry, there is no result for your input. Your credits remain
                  the same, <br />
                  so give it another try with a different text.
                </p>
              </Alert>
            )}
            {(limit == count || limit <= count) && (
              <AICreationLimitModal
                refreshesAt={refreshesAt}
                handleClickRedirect={handleClickRedirect}
                type={'Card_Complete'}
                roleType={roleType}
              />
            )}

            {loading && !options && (
              <>
                <Box width="200px">
                  <img src="/assets/ai_mascot.gif"></img>
                </Box>
                <Typography variant="h4" color="primary">
                  AI Assistant is in action!
                </Typography>
                <Typography variant="subtitle">
                  Please wait, AI can take up to 30 seconds.
                </Typography>
              </>
            )}

            {isAiCard && isCardEmpty && !limitLoading && !options && !loading && (
              <>
                <Typography variant="h6" textAlign="center">
                  Ways to benefit from AI-Assistant
                </Typography>

                <Box m={1}></Box>
                {AccordionContents.map(accordionContent => (
                  <>
                    <Accordion
                      className={classes.accordion}
                      style={{
                        display: seeMore ? 'initial' : accordionContent.display,
                        backgroundColor:
                          expanded === accordionContent.panelName && '#ffffff',
                      }}
                      expanded={expanded === accordionContent.panelName}
                      onChange={handleChange(accordionContent.panelName)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.summary}>
                          <Icon
                            style={{
                              marginTop: '1px',
                              color:
                                expanded === accordionContent.panelName &&
                                '#0d53b7',
                            }}
                            icon={accordionContent.icon}
                          ></Icon>
                          <Box m={0.5}></Box>
                          <Typography
                            variant="subtitle2"
                            color={
                              expanded === accordionContent.panelName &&
                              'primary'
                            }
                          >
                            {accordionContent.header}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {accordionContent.description != '' && (
                          <Typography>
                            {accordionContent.description}
                          </Typography>
                        )}

                        {accordionContent.details.map(detail => (
                          <li>{detail}</li>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </>
                ))}
                <Button
                  variant="outlined"
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    setSeeMore(!seeMore)
                  }}
                >
                  {seeMore ? 'See Less Ideas' : 'See More Ideas'}
                </Button>
              </>
            )}

            {count < limit &&
              !emptyChoices &&
              options &&
              (!loading ? (
                <Button
                  variant="outlined"
                  fullWidth={false}
                  style={{ maxWidth: '200px', backgroundColor: '#ffff' }}
                  startIcon={<img src="/assets/AI_icon.png" height="14" />}
                  onClick={() => sendRequest('generate and add more')}
                >
                  Generate more
                </Button>
              ) : (
                <CircularProgress />
              ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}

OpenAiPopup.propTypes = propTypes

export default withStyles(styles)(OpenAiPopup)
