import React, { useEffect, useState } from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import {
  Selections,
  AccordionPart,
  BadgeImage,
  SuccessRate,
} from './components'
import { getBadgesAction } from 'src/reducers/question/action'
import { useDispatch } from 'react-redux'
import { useCardSettings, patchSetting } from 'src/hooks/useCardSettings'

/**
 *
 * @param {*} props
 */

const BadgeCard = props => {
  const { entity, classes } = props
  const dispatch = useDispatch()
  const settings = useCardSettings(entity.id, 'badge')
  const badgeSettings = JSON.parse(settings.value)
  const [badgeUsageType, setBadgeUsageType] = useState(
    badgeSettings.badgeUsageType,
  )
  const handleBadgeUsageType = badgeType => {
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({
        ...badgeSettings,
        badgeUsageType: badgeType,
      }),
    )
    setBadgeUsageType(badgeType)
  }
  const [badges, setBadges] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      getBadgesAction(response => {
        setLoading(false)
        setBadges(response.data)
      }),
    )
  }, [])
  return (
    <div className={classes.root}>
      <Selections
        handleBadgeUsageType={handleBadgeUsageType}
        badgeUsageType={badgeUsageType}
      />
      {badgeUsageType === 1 && (
        <SuccessRate badgeSettings={badgeSettings} settings={settings} />
      )}
      <AccordionPart
        settings={settings}
        badgeSettings={badgeSettings}
        badgeUsageType={badgeUsageType}
      />
      <BadgeImage
        badges={badges}
        settings={settings}
        badgeSettings={badgeSettings}
      />
    </div>
  )
}

BadgeCard.propTypes = propTypes

export default withStyles(styles)(BadgeCard)
