const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: 24,
    marginTop: 16,
    paddingBottom: 16,
  },
})

export default styles
