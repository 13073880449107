import { put, call, take, fork } from 'redux-saga/effects'
import { assignmentAddBulkQuestionService } from '../../api/assignment'
import { assignmentAddBulkQuestionSuccess } from '../../reducers/assignment/action'
import { ASSIGNMENT_ADD_BULK_QUESTION } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* assignmentAddBulkQuestionFlow(payload) {
  try {
    const { entity, history, callback } = payload

    const response = yield call(assignmentAddBulkQuestionService, entity)

    yield put(assignmentAddBulkQuestionSuccess(response.data))
    yield put(operationSuccess())
    history.push('/mini-course/' + response.data.id)

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* assignmentAddBulkQuestionWatcher() {
  while (true) {
    const action = yield take(ASSIGNMENT_ADD_BULK_QUESTION)
    const { payload } = action

    yield fork(generalSaga, assignmentAddBulkQuestionFlow, payload)
  }
}
