const WebhookUrl = paymentProvider => {
  switch (paymentProvider) {
    case 'stripe':
      return 'stripe/webhook'
    case 'lemon_squeeze':
      return 'lemon-squeezy/webhook'
    case 'pabbly':
      return 'pabbly/webhook'
    case 'pay_stack':
      return 'paystack/webhook'
    default:
      return false
  }
}

export default WebhookUrl
