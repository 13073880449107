import React from 'react'

import PropTypes from 'prop-types'
// @mui
import { styled, alpha } from '@material-ui/core/styles'
import { Grid, RadioGroup, CardActionArea, Box, Stack } from '@material-ui/core'

//

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
  height : 50
}))

// ----------------------------------------------------------------------

export default function SettingArrow(props) {
  const { classes, themeArrow, onChangeArrow } = props

  return (
    <RadioGroup name="themeLayout" value={themeArrow}>
      <Grid dir="ltr" container spacing={2}>
        {[1, 2, 3, 4].map(arrow => {
          const isSelected = themeArrow === arrow
          const isVertical = arrow === 1

          return (
            <Grid key={arrow} item xs={3} onClick={() => onChangeArrow(arrow)}>
              <BoxStyle
                className={isSelected ? classes.selectedGrid : ''}
                sx={{
                  ...(isSelected && {
                    color: 'primary.main',
                  }),
                }}
              >
                <img src={`/assets/arrows/${arrow}.svg`} alt="" height= "100%" />
              </BoxStyle>
            </Grid>
          )
        })}
      </Grid>
    </RadioGroup>
  )
}

// ----------------------------------------------------------------------

const style = {
  width: 1,
  height: 32,
  borderRadius: 0.5,
}

VerticalBox.propTypes = {
  isSelected: PropTypes.bool,
}

function VerticalBox({ isSelected }) {
  return (
    <>
      <Box
        sx={{
          ...style,
          mb: 0.75,
          height: 12,
          bgcolor: theme => alpha(theme.palette.text.disabled, 0.72),
          ...(isSelected && {
            bgcolor: theme => alpha(theme.palette.primary.main, 0.72),
          }),
        }}
      />
      <Box
        sx={{
          ...style,
          border: theme => `dashed 1px ${theme.palette.divider}`,
          bgcolor: theme => alpha(theme.palette.text.disabled, 0.08),
          ...(isSelected && {
            border: theme => `dashed 1px ${theme.palette.primary.main}`,
            bgcolor: theme => alpha(theme.palette.primary.main, 0.16),
          }),
        }}
      />
    </>
  )
}

HorizontalBox.propTypes = {
  isSelected: PropTypes.bool,
}

function HorizontalBox({ isSelected }) {
  return (
    <>
      <Box
        sx={{
          ...style,
          mb: 0.75,
          height: 12,
          bgcolor: theme => alpha(theme.palette.text.disabled, 0.72),
          ...(isSelected && {
            bgcolor: theme => alpha(theme.palette.primary.main, 0.72),
          }),
        }}
      />
      <Stack width={1} direction="row" justifyContent="space-between">
        <Box
          sx={{
            ...style,
            width: 20,
            bgcolor: theme => alpha(theme.palette.text.disabled, 0.32),
            ...(isSelected && {
              bgcolor: theme => alpha(theme.palette.primary.main, 0.32),
            }),
          }}
        />
        <Box
          sx={{
            ...style,
            width: `calc(100% - 26px)`,
            border: theme => `dashed 1px ${theme.palette.divider}`,
            bgcolor: theme => alpha(theme.palette.text.disabled, 0.08),
            ...(isSelected && {
              border: theme => `dashed 1px ${theme.palette.primary.main}`,
              bgcolor: theme => alpha(theme.palette.primary.main, 0.16),
            }),
          }}
        />
      </Stack>
    </>
  )
}
