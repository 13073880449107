import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
  Box,
  Tooltip,
  Grid,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Switch,
  Alert,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import styles from './styles'
import { Presets } from 'src/model/ai-image-selection-presests/index'
import { track } from '@amplitude/analytics-browser'

const StepAiSelection = props => {
  const {
    handleCreateCourse,
    setPreset,
    selectedPreset,
    outlineOptionsLength,
    count,
    limit,
    roleType,
    setCount,
    imageWillBeGenerated,
    setImageWillBeGenerated,
    classes,
  } = props

  const [userType, setUserType] = useState(roleType)
  const [neededCredits, setNeededCredits] = useState(
    userType == 1 ? count + 2 : count + outlineOptionsLength,
  )

  useEffect(() => {
    imageWillBeGenerated == true &&
      setImageWillBeGenerated(neededCredits <= limit)
    setNeededCredits(userType == 1 ? count + 2 : count + outlineOptionsLength)
  }, [outlineOptionsLength])

  const handleChange = () => {
    if (imageWillBeGenerated == true) {
      setPreset()
      track('ai_image_stepper', { value: 'ai_image_off' })
      //setCount(count)
    } else {
      setPreset('sketch_art')
      track('ai_image_stepper', { value: 'ai_image_on' })
    }

    if (neededCredits <= limit) {
      //!imageWillBeGenerated == false && setCount(count + outlineOptionsLength)
      setImageWillBeGenerated(!imageWillBeGenerated)
    }
  }

  return (
    <>
      <Box m={2}></Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          //alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            //justifyContent: 'center',
          }}
        >
          <Switch
            checked={imageWillBeGenerated}
            onChange={handleChange}
            disabled={limit < neededCredits}
          />
          <Typography>Add AI-generated images.</Typography>
        </div>
        <div>
          {neededCredits <= limit ? (
            userType == 1 ? (
              <div style={{ marginLeft: 16 }}>
                <Typography>
                  (Only 2 images will be added.<a href="/pricing">Upgrade</a> to
                  get images for all cards.)
                </Typography>
              </div>
            ) : (
              <div style={{ marginLeft: 16 }}>
                <Typography>
                  ({outlineOptionsLength} credits will be used for images.)
                </Typography>
              </div>
            )
          ) : (
            <Alert
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              severity="error"
            >
              You do not have enough credits for AI generated images.{' '}
              <a href="/pricing" target="_blank">
                Upgrade
              </a>{' '}
              to a higher plan for more AI credits.
            </Alert>
          )}
        </div>
      </div>
      <Box m={1}></Box>

      <Box m={2}></Box>

      <Button
        variant="contained"
        fullWidth={false}
        style={{ marginTop: 16, maxWidth: 210, marginBottom: 16 }}
        onClick={() => {
          handleCreateCourse()
        }}
      >
        Generate Mini-Course
      </Button>
    </>
  )
}

export default withStyles(styles)(StepAiSelection)
