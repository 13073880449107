/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, Modal, Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import { deleteTeamAction } from 'src/reducers/teams/action'

const TeamDelete = props => {
  const {
    isModalOpen,
    handleCloseModal,
    dispatch,
    teamInfo,
    handleClose,
    classes,
  } = props

  const handleDeleteColumn = () => {
    dispatch(deleteTeamAction(teamInfo.teamId, response => {}))
    handleClose()
    handleCloseModal()
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.modalContent}>
        <div className={classes.modalContent__header}>
          <Typography className={classes.modalContent__header__title}>
            Delete Group
          </Typography>
        </div>

        <Typography className={classes.modalContent__header__text}>
          Are you sure you want to delete the group?
        </Typography>
        <Typography className={classes.modalContent__header__warning}>
          Note: This will also delete all learners in the group.{' '}
        </Typography>
        <div className={classes.modalContent__buttons}>
          <Button
            variant="outlined"
            className={classes.modalContent__cancelButton}
            onClick={() => handleCloseModal()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.modalContent__deleteButton}
            onClick={() => handleDeleteColumn()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Delete
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(TeamDelete)
