const style = theme => ({
  paginationHolder: {
    display: 'block',
    width: '100%',
  },
  pagination: {
    display: 'inline-block',
    justifyContent: 'center',
    background: '#fdfdfd',
    border: '1px solid #ededed',
    padding: '.4rem',
    borderRadius: '15px',
  },
  cardListContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1,
  },
  cardListView: {
    position: 'relative',
    width: '100%',

    textAlign: 'center',
    zIndex: 1,
  },
  cardListHolder: {
    position: 'relative',
    width: '100%',
    //height: '100%',
    textAlign: 'center',
    overflowX: 'clip',
    marginTop: '24px',
    /*justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',*/
    zIndex: 1,
  },
  popover: {
    '&.MuiPopover-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
    '& .MuiPaper-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
  },
  emptySlot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  rightHelper: {
    width: '100%',
  },
  front: { width: '100%' },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    //marginTop: '.8rem',
    textDecoration: 'none',
    color: '#ffffff',
    opacity: '1',
    marginBottom: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBackground: {
    background: '#015FFB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    borderRadius: '50%',
    zIndex: 9999999,
  },
  buttonText: {
    padding: '.8rem',
    lineHeight: '17px',
    fontSize: '13px',
    fontWeight: '500',
    borderRadius: '25px',
    background: '#ffffff',
    whiteSpace: 'nowrap',
    color: '#637381',
    width: '130px',
    textAlign: 'end',
    marginLeft: '-38px',
    height: '45px',
    '&:hover': {
      color: '#015FFB',
    },
  },
  buttonTextQuiz: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '32px',
    padding: '.8rem',
    lineHeight: '17px',
    fontSize: '13px',
    fontWeight: '500',
    borderRadius: '25px',
    background: '#ffffff',
    whiteSpace: 'nowrap',
    color: '#637381',
    width: '130px',
    textAlign: 'end',
    marginLeft: '-38px',
    height: '45px',
    '&:hover': {
      color: '#015FFB',
    },
  },
  addCard: {
    zIndex: 999,
    margin: '1rem 0',
    //marginTop: '-30px',
    color: '#0C53B7',
  },
  iconButtonAdd: {
    color: '#015FFB',
    background: '#ffffff',
    '&:hover': {
      color: '#ffffff',
      background: '#015FFB',
    },
    width: 32,
    height: 32,
  },
})

export default style
