/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Button,
  Modal,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import styles from './styles'
import { patchTeamsMemberAction } from 'src/reducers/teams/action'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { PatchContent, PatchData } from 'src/model/patch'

const EditMember = props => {
  const { entity, dispatch, classes } = props
  const teamsId = window.location.pathname.split('learners-member-list/')[1]
  const [memberName, setMemberName] = React.useState(entity.name)
  const [memberEmail, setMemberEmail] = React.useState(entity.email)

  const handlePatchMember = () => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(memberName, 'name', PatchData.OPERATION_REPLACE),
    )

    patchSource.addContent(
      new PatchContent(memberEmail, 'email', PatchData.OPERATION_REPLACE),
    )

    dispatch(
      patchTeamsMemberAction(teamsId, entity.id, patchSource, () => {
        dispatch(componentBoxHideAction())
      }),
    )
  }

  const handleCloseModal = () => {
    dispatch(componentBoxHideAction())
  }
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <div className={classes.modalContent__header}>
          <Typography className={classes.modalContent__header__title}>
            Create Learner
          </Typography>
        </div>

        <div className={classes.modalContent__header}>
          <TextField
            value={memberName}
            onChange={e => setMemberName(e.target.value)}
            className={classes.modalContent__input}
            label="Name"
            placeholder="Name"
          />
          <TextField
            value={memberEmail}
            onChange={e => setMemberEmail(e.target.value)}
            className={classes.modalContent__input}
            label="Email"
            placeholder="Email"
          />
        </div>

        <div className={classes.modalContent__buttons}>
          <Button
            variant="outlined"
            className={classes.modalContent__cancelButton}
            onClick={() => handleCloseModal()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.modalContent__SaveButton}
            onClick={() => handlePatchMember()}
          >
            <Typography className={classes.modalContent__buttonText}>
              Save Changes
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  )
}
export default withStyles(styles)(EditMember)
