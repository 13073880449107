import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import styles from './styles'
const EndlessPossibilities = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <Typography
        fontSize={'20px'}
        fontWeight={600}
        className={classes.root_text}
      >
        Create endless possibilities
      </Typography>
      <Typography
        fontSize={'15px'}
        fontWeight={500}
        className={classes.root_text}
      >
        Connect the Mini Course Generator to thousands of products seamlessly
        using Zapier and Make integrations.
      </Typography>
      <img
        src="/assets/automation/automations-img.png"
        className={classes.root_img}
      ></img>
    </div>
  )
}

export default withStyles(styles)(EndlessPossibilities)
