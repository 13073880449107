import React from 'react'
import QuizSelection from './quiz'
import MultipleChoice from './multiple-choice'

const QuizSurvey = props => {
  const { saveType, baseEntity } = props
  return (
    <div
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <QuizSelection saveType={saveType} entity={baseEntity} />
      <MultipleChoice saveType={saveType} entity={baseEntity} />
    </div>
  )
}

export default QuizSurvey
