const styles = theme => ({
  coverImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 100%',
    width: '100%',
    height: 146,
    borderRadius: 16,
    overflow: 'hidden',
    marginBottom: 16,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  courseGalleryView: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8FAFC',
    height: 280,
    width: 300,
    borderRadius: 16,
    cursor: 'pointer',
  },

  createIcon: {
    background: 'white',
    width: 36,
    height: 36,
    borderRadius: 100,
    color: 'rgb(1,95,251)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default styles
