import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import styles from './styles'
const LogoView = props => {
  const {
    logo,
    productName,
    buttonClass,
    buttonUrl,
    logoHeight,
    classes,
  } = props
  const buttonsClass = classes[buttonClass]
  const onClick = () => {
    window.open(buttonUrl, '_blank')
  }
  return (
    <div className={classes.root}>
      <div className={classes.root__img}>
        <img src={logo} height={logoHeight} alt=""></img>
      </div>
      <Typography textAlign={'center'}>
        Sign-in to your <br />
        {productName} account
      </Typography>
      <Button
        onClick={() => onClick()}
        variant="contained"
        className={buttonsClass}
      >
        <Typography
          fontSize={'15px'}
          fontWeight={600}
          style={{
            marginBottom: 4,
          }}
        >
          Sign in
        </Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(LogoView)
