import React, { useRef, useEffect } from 'react'

const SimpleHtmlTextEditor = props => {
  const { html, onBlur, className } = props

  const editorRef = useRef(null)

  const setElements = async value => {
    onBlur(value)
  }

  const onPaste = async event => {
    event.preventDefault()

    let paste = event.clipboardData.getData('Text')
    const selection = window.getSelection()
    if (!selection.rangeCount) return
    selection.deleteFromDocument()
    selection.getRangeAt(0).insertNode(document.createTextNode(paste))
    selection.collapseToEnd()
  }

  return (
    <div
      ref={editorRef}
      className={className}
      contentEditable
      onBlurCapture={() => setElements(editorRef.current.innerHTML)}
      dangerouslySetInnerHTML={{ __html: html }}
      onPaste={e => onPaste(e)}
    />
  )
}

export default SimpleHtmlTextEditor
