const styles = theme => ({
  container: {
    width: '100%',
    margin: '0 auto',
  },
  grid: {
    width: '100%',
    flexBasis: 'unset',
  },
  childGrid: {
    boxShadow: 'none',
    overflow: 'visible',
  },
  informGrid: {
    minWidth: 130,
  },
  textField: {
    width: '100%',
    borderRadius: '10px !important',
  },
  media: {
    height: 60,
    width: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    marginBottom: 0,
  },
  cardContent: {
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    textAlign: 'left',
    //overflow: 'hidden',
    borderRadius: '20px',
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      //overflow: 'auto',
      //overflow: 'hidden',
    },

    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
    },
    padding: theme.spacing(2),
    '& p': {
      fontSize: 14,
    },
    '& h2': {
      fontSize: 16,
      fontWeight: 700,
    },
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  saving: {
    position: 'fixed',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
    gap: '.4rem',
    background: '#EAEAEA',
    border: '1px solid #CBCBCB',
    borderRadius: '25px',
    padding: '.3rem .9rem',
    color: '#494949',
    userSelect: 'none',
    fontSize: '.9rem',
    zIndex: 9999999999,
  },
  addCard: {
    zIndex: 999,
    margin: '1rem 0',
    //marginTop: '-30px',
    color: '#0C53B7',
  },
  textArea: {
    textAlign: 'left',
    border: '1px solid rgba(145, 158, 171, 0.32) !important',
    borderRadius: '8px',
  },
  iconButtonAdd: {
    color: '#ffffff',
    background: '#015FFB',
    '&:hover': {
      color: '#015FFB',
      background: '#ffffff',
    },
    width: 32,
    height: 32,
  },
  popover: {
    '&.MuiPopover-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
    '& .MuiPaper-root': {
      overflowX: 'unset!important',
      overflowY: 'unset!important',
    },
  },
  contentDiv: {
    width: '640px',
    marginTop: 16,
  },

  textfieldInput: {
    color: '#002E47',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
  },
  hoverCursor: {
    width: '20px',
    height: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
