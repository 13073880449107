const QUESTION_UPDATE_ENTITY = 'QUESTION_UPDATE_ENTITY'

const QUESTION_LIST = 'QUESTION_LIST'
const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS'
const QUESTION_LIST_ERROR = 'QUESTION_LIST_ERROR'

const QUESTION_ID_LIST = 'QUESTION_ID_LIST'
const QUESTION_ID_LIST_SUCCESS = 'QUESTION_ID_LIST_SUCCESS'
const QUESTION_ID_LIST_ERROR = 'QUESTION_ID_LIST_ERROR'

const QUESTION_GET = 'QUESTION_GET'
const QUESTION_GET_SUCCESS = 'QUESTION_GET_SUCCESS'
const QUESTION_GET_ERROR = 'QUESTION_GET_ERROR'

const QUESTION_GET_LIST = 'QUESTION_GET_LIST'
const QUESTION_GET_LIST_SUCCESS = 'QUESTION_GET_LIST_SUCCESS'
const QUESTION_GET_LIST_ERROR = 'QUESTION_GET_LIST_ERROR'

const QUESTION_DELETE_LIST = 'QUESTION_DELETE_LIST'
const QUESTION_DELETE_ALL_LIST = 'QUESTION_DELETE_ALL_LIST'

const QUESTION_ADD = 'QUESTION_ADD'
const QUESTION_ADD_SUCCESS = 'QUESTION_ADD_SUCCESS'
const QUESTION_ADD_ERROR = 'QUESTION_ADD_ERROR'

const QUESTION_EDIT = 'QUESTION_EDIT'
const QUESTION_EDIT_SUCCESS = 'QUESTION_EDIT_SUCCESS'
const QUESTION_EDIT_ERROR = 'QUESTION_EDIT_ERROR'

const QUESTION_PATCH = 'QUESTION_PATCH'
const QUESTION_PATCH_SUCCESS = 'QUESTION_PATCH_SUCCESS'
const QUESTION_PATCH_ERROR = 'QUESTION_PATCH_ERROR'

const QUESTION_CLONE = 'QUESTION_CLONE'
const QUESTION_CLONE_SUCCESS = 'QUESTION_CLONE_SUCCESS'
const QUESTION_CLONE_ERROR = 'QUESTION_CLONE_ERROR'

const QUESTION_DELETE = 'QUESTION_DELETE'
const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS'
const QUESTION_DELETE_ERROR = 'QUESTION_DELETE_ERROR'

const QUESTION_LIST_DELETE = 'QUESTION_LIST_DELETE'
const QUESTION_LIST_DELETE_SUCCESS = 'QUESTION_LIST_DELETE_SUCCESS'
const QUESTION_LIST_DELETE_ERROR = 'QUESTION_LIST_DELETE_ERROR'

const QUESTION_LIST_MAKE_ACTIVE = 'QUESTION_LIST_MAKE_ACTIVE'
const QUESTION_LIST_MAKE_ACTIVE_SUCCESS = 'QUESTION_LIST_MAKE_ACTIVE_SUCCESS'
const QUESTION_LIST_MAKE_ACTIVE_ERROR = 'QUESTION_LIST_MAKE_ACTIVE_ERROR'

const QUESTION_LIST_MAKE_PASSIVE = 'QUESTION_LIST_MAKE_PASSIVE'
const QUESTION_LIST_MAKE_PASSIVE_SUCCESS = 'QUESTION_LIST_MAKE_PASSIVE_SUCCESS'
const QUESTION_LIST_MAKE_PASSIVE_ERROR = 'QUESTION_LIST_MAKE_PASSIVE_ERROR'

const QUESTION_TAG_ADD = 'QUESTION_TAG_ADD'
const QUESTION_TAG_ADD_SUCCESS = 'QUESTION_TAG_ADD_SUCCESS'
const QUESTION_TAG_ADD_ERROR = 'QUESTION_TAG_ADD_ERROR'

const QUESTION_TAG_DELETE = 'QUESTION_TAG_DELETE'
const QUESTION_TAG_DELETE_SUCCESS = 'QUESTION_TAG_DELETE_SUCCESS'
const QUESTION_TAG_DELETE_ERROR = 'QUESTION_TAG_DELETE_ERROR'

const QUESTION_GAMEINFO_ADD = 'QUESTION_GAMEINFO_ADD'
const QUESTION_GAMEINFO_ADD_SUCCESS = 'QUESTION_GAMEINFO_ADD_SUCCESS'
const QUESTION_GAMEINFO_ADD_ERROR = 'QUESTION_GAMEINFO_ADD_ERROR'

const QUESTION_GAMEINFO_DELETE = 'QUESTION_GAMEINFO_DELETE'
const QUESTION_GAMEINFO_DELETE_SUCCESS = 'QUESTION_GAMEINFO_DELETE_SUCCESS'
const QUESTION_GAMEINFO_DELETE_ERROR = 'QUESTION_GAMEINFO_DELETE_ERROR'

const QUESTION_MEDIA_ADD = 'QUESTION_MEDIA_ADD'
const QUESTION_MEDIA_ADD_SUCCESS = 'QUESTION_MEDIA_ADD_SUCCESS'
const QUESTION_MEDIA_ADD_ERROR = 'QUESTION_MEDIA_ADD_ERROR'

const QUESTION_MEDIA_DELETE = 'QUESTION_MEDIA_DELETE'
const QUESTION_MEDIA_DELETE_SUCCESS = 'QUESTION_MEDIA_DELETE_SUCCESS'
const QUESTION_MEDIA_DELETE_ERROR = 'QUESTION_MEDIA_DELETE_ERROR'

const QUESTION_LINK_ADD = 'QUESTION_LINK_ADD'
const QUESTION_LINK_ADD_SUCCESS = 'QUESTION_LINK_ADD_SUCCESS'
const QUESTION_LINK_ADD_ERROR = 'QUESTION_LINK_ADD_ERROR'

const QUESTION_LINK_DELETE = 'QUESTION_LINK_DELETE'
const QUESTION_LINK_DELETE_SUCCESS = 'QUESTION_LINK_DELETE_SUCCESS'
const QUESTION_LINK_DELETE_ERROR = 'QUESTION_LINK_DELETE_ERROR'

const QUESTION_SELECTION_PATCH = 'QUESTION_SELECTION_PATCH'
const QUESTION_SELECTION_PATCH_SUCCESS = 'QUESTION_SELECTION_PATCH_SUCCESS'
const QUESTION_SELECTION_PATCH_ERROR = 'QUESTION_SELECTION_PATCH_ERROR'

const QUESTION_SELECTION_ADD = 'QUESTION_SELECTION_ADD'
const QUESTION_SELECTION_ADD_SUCCESS = 'QUESTION_SELECTION_ADD_SUCCESS'
const QUESTION_SELECTION_ADD_ERROR = 'QUESTION_SELECTION_ADD_ERROR'

const QUESTION_SELECTION_DELETE = 'QUESTION_SELECTION_DELETE'
const QUESTION_SELECTION_DELETE_SUCCESS = 'QUESTION_SELECTION_DELETE_SUCCESS'
const QUESTION_SELECTION_DELETE_ERROR = 'QUESTION_SELECTION_DELETE_ERROR'

const QUESTION_REPORT_COMPARE_TEAMS = 'QUESTION_REPORT_COMPARE_TEAMS'
const QUESTION_REPORT_COMPARE_TEAMS_SUCCESS =
  'QUESTION_REPORT_COMPARE_TEAMS_SUCCESS'
const QUESTION_REPORT_COMPARE_TEAMS_ERROR =
  'QUESTION_REPORT_COMPARE_TEAMS_ERROR'

const QUESTION_REPORT_RELATED_TEAMS = 'QUESTION_REPORT_RELATED_TEAMS'
const QUESTION_REPORT_RELATED_TEAMS_SUCCESS =
  'QUESTION_REPORT_RELATED_TEAMS_SUCCESS'
const QUESTION_REPORT_RELATED_TEAMS_ERROR =
  'QUESTION_REPORT_RELATED_TEAMS_ERROR'

const QUESTION_REPORT_SHOW_USER_ANSWERS = 'QUESTION_REPORT_SHOW_USER_ANSWERS'
const QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS =
  'QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS'
const QUESTION_REPORT_SHOW_USER_ANSWERS_ERROR =
  'QUESTION_REPORT_SHOW_USER_ANSWERS_ERROR'

const QUESTION_REPORT_CHOICE_TENDENCY = 'QUESTION_REPORT_CHOICE_TENDENCY'
const QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS =
  'QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS'
const QUESTION_REPORT_CHOICE_TENDENCY_ERROR =
  'QUESTION_REPORT_CHOICE_TENDENCY_ERROR'

const QUESTION_REPORT_IMPROVEMENT = 'QUESTION_REPORT_IMPROVEMENT'
const QUESTION_REPORT_IMPROVEMENT_SUCCESS =
  'QUESTION_REPORT_IMPROVEMENT_SUCCESS'
const QUESTION_REPORT_IMPROVEMENT_ERROR = 'QUESTION_REPORT_IMPROVEMENT_ERROR'

const QUESTION_RE_ORDER_LIST = 'QUESTION_RE_ORDER_LIST'

const GET_BADGES = 'GET_BADGES'
const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS'
const GET_BADGES_ERROR = 'GET_BADGES_ERROR'
export {
  QUESTION_UPDATE_ENTITY,
  QUESTION_LIST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_ERROR,
  QUESTION_ID_LIST,
  QUESTION_ID_LIST_SUCCESS,
  QUESTION_ID_LIST_ERROR,
  QUESTION_GET,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_ERROR,
  QUESTION_GET_LIST,
  QUESTION_GET_LIST_SUCCESS,
  QUESTION_GET_LIST_ERROR,
  QUESTION_DELETE_LIST,
  QUESTION_DELETE_ALL_LIST,
  QUESTION_ADD,
  QUESTION_ADD_SUCCESS,
  QUESTION_ADD_ERROR,
  QUESTION_EDIT,
  QUESTION_EDIT_SUCCESS,
  QUESTION_EDIT_ERROR,
  QUESTION_PATCH,
  QUESTION_PATCH_SUCCESS,
  QUESTION_PATCH_ERROR,
  QUESTION_CLONE,
  QUESTION_CLONE_SUCCESS,
  QUESTION_CLONE_ERROR,
  QUESTION_DELETE,
  QUESTION_DELETE_SUCCESS,
  QUESTION_DELETE_ERROR,
  QUESTION_LIST_DELETE,
  QUESTION_LIST_DELETE_SUCCESS,
  QUESTION_LIST_DELETE_ERROR,
  QUESTION_LIST_MAKE_ACTIVE,
  QUESTION_LIST_MAKE_ACTIVE_SUCCESS,
  QUESTION_LIST_MAKE_ACTIVE_ERROR,
  QUESTION_LIST_MAKE_PASSIVE,
  QUESTION_LIST_MAKE_PASSIVE_SUCCESS,
  QUESTION_LIST_MAKE_PASSIVE_ERROR,
  QUESTION_TAG_ADD,
  QUESTION_TAG_ADD_SUCCESS,
  QUESTION_TAG_ADD_ERROR,
  QUESTION_TAG_DELETE,
  QUESTION_TAG_DELETE_SUCCESS,
  QUESTION_TAG_DELETE_ERROR,
  QUESTION_GAMEINFO_ADD,
  QUESTION_GAMEINFO_ADD_SUCCESS,
  QUESTION_GAMEINFO_ADD_ERROR,
  QUESTION_GAMEINFO_DELETE,
  QUESTION_GAMEINFO_DELETE_SUCCESS,
  QUESTION_GAMEINFO_DELETE_ERROR,
  QUESTION_MEDIA_ADD,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_ADD_ERROR,
  QUESTION_MEDIA_DELETE,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_MEDIA_DELETE_ERROR,
  QUESTION_SELECTION_PATCH,
  QUESTION_SELECTION_PATCH_SUCCESS,
  QUESTION_SELECTION_PATCH_ERROR,
  QUESTION_SELECTION_ADD,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_ERROR,
  QUESTION_SELECTION_DELETE,
  QUESTION_SELECTION_DELETE_SUCCESS,
  QUESTION_SELECTION_DELETE_ERROR,
  QUESTION_LINK_ADD,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_LINK_ADD_ERROR,
  QUESTION_LINK_DELETE,
  QUESTION_LINK_DELETE_SUCCESS,
  QUESTION_LINK_DELETE_ERROR,
  QUESTION_REPORT_COMPARE_TEAMS,
  QUESTION_REPORT_RELATED_TEAMS,
  QUESTION_REPORT_SHOW_USER_ANSWERS,
  QUESTION_REPORT_CHOICE_TENDENCY,
  QUESTION_REPORT_COMPARE_TEAMS_SUCCESS,
  QUESTION_REPORT_RELATED_TEAMS_SUCCESS,
  QUESTION_REPORT_SHOW_USER_ANSWERS_SUCCESS,
  QUESTION_REPORT_CHOICE_TENDENCY_SUCCESS,
  QUESTION_REPORT_COMPARE_TEAMS_ERROR,
  QUESTION_REPORT_RELATED_TEAMS_ERROR,
  QUESTION_REPORT_SHOW_USER_ANSWERS_ERROR,
  QUESTION_REPORT_CHOICE_TENDENCY_ERROR,
  QUESTION_REPORT_IMPROVEMENT,
  QUESTION_REPORT_IMPROVEMENT_ERROR,
  QUESTION_REPORT_IMPROVEMENT_SUCCESS,
  QUESTION_RE_ORDER_LIST,
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_ERROR,
}
