const styles = theme => ({
  iconButton: {
    width: '36px',
    height: '36px',
    borderRadius: '1rem',
    zIndex: 1000,
    position: 'absolute',
    right: 20,
    top: 20,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  root__teamName: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root__teamName__number: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  root__teamName_divider: {
    marginRight: '10px',
    paddingRight: '10px',
    marginTop: '2px',
  },
  root__teamName_enrolled: {
    marginRight: '10px',
    marginTop: '2px',
  },
  mr: {
    marginRight: '5px',
  },
  team_list_root: {
    width: '416px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px',
    borderRadius: '16px',
    backgroundColor: '#fff',
    height: '94px',
    alignItems: 'center',
    gap: '16px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#f7fafc',
      cursor: 'pointer',
    },
  },
  team_list_root_avatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    borderRadius: '50%',
    minWidth: '44px',
    height: '44px',
  },
  team_list_root_column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  page__header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  page__header__title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  page__header__button: {
    borderRadius: '12px',
    backgroundColor: '#37D768',
    color: '#fff',
    height: '40px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#63f28f',
    },
  },

  breadCrumbsText: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  namingModal: {
    display: 'flex !important',
  },
  namingModal__Content: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadiusMd,
    height: 244,
    width: 598,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
    padding: 24,
    gap: 16,
  },
  namingModal__Title: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: 24,
  },
  namingModal__ButtonGroup: {
    height: '68px',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '12px',
  },
  namingModal__SaveButton: {
    boxShadow: 'none',
    color: '#fff',
    backgroundColor: '#37D768',
    padding: '6px 16px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#63f28f',
    },
  },
  menu_popover: {
    width: 'auto',
    '& .MuiMenuItem-root': {
      px: 1,
      typography: 'body2',
      backgroundColor: '#fff',
      color: '#000',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#F4F6F8',
        color: '#000',
        borderRadius: '4px',
      },
    },
  },
  noTeamsView_root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  noTeamsView_root_content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    gap: '16px',
    width: '452px',
    backgroundColor: '#fff',
    borderRadius: '16px',
  },
})

export default styles
