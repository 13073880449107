import React from 'react'
import Inform from './inform'
import { AnswerItemType } from 'src/model/question'
import Question from './question'
import Freeform from './free-form'
import Input from './input'
import Numeric from './numeric'
import Stars from './stars'
import NotSupported from './not-supported'

/* 
import Signup from './signup'
import Stripe from './stripe-payment'
import ResultCard from './result-card'
import ScoreCard from './score'
import SertifierCard from './sertifier'
import ProtectionCard from './protection'
import ResultStandart from './result-standart'
import QuestionAi from './ai' */

/**
 *
 * @param {*} props
 */
const CardTypeFactory = props => {
  const { type, id } = props
  props = { ...props, key: id }

  switch (type) {
    case AnswerItemType.INFORM:
      return <Inform {...props} />
    case AnswerItemType.TESTINGSINGLECHOICE:
      return <Question {...props} />
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYSINGLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      return <Question {...props} />
    case AnswerItemType.SURVEYFREEFORM:
      return <Freeform {...props} />
    case AnswerItemType.SURVEYINPUT:
      return <Input {...props} />
    case AnswerItemType.SURVEYNUMERICENTRY:
      return <Numeric {...props} />
    case AnswerItemType.SURVEYSTARS:
      return <Stars {...props} />
     
    case AnswerItemType.SIGNUP:
      return <NotSupported {...props} />
    case AnswerItemType.STRIPEPAYMENT:
      return <NotSupported {...props} />
    case AnswerItemType.RESULT:
      return <NotSupported {...props} />
    case AnswerItemType.SCORECARD:
      return <NotSupported {...props} />
    case AnswerItemType.SERTIFIERCARD:
      return <NotSupported {...props} />
    case AnswerItemType.PROTECTIONCARD:
      return <NotSupported {...props} />
    case AnswerItemType.RESULTDEFAULT:
      return <NotSupported {...props} />
    case AnswerItemType.AI:
      return <Inform {...props} /> 
    default:
      return <Inform {...props} />
  }
}

export default CardTypeFactory
