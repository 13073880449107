import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  assignmentGetAction,
  //assignmentReportAction,
} from 'src/reducers/assignment/action'
// @mui
import {
  Paper,
  Typography,
  Box,
  Tooltip,
  withStyles,
  Button,
} from '@material-ui/core'
// components
import KanbanCourseToolBar from './KanbanCourseToolBar'
import URL from 'src/api/url/api'
import styles from './styles'
import { IconButton } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PatchContent, PatchData } from 'src/model/patch'
import { assignmentPatchAction } from 'src/reducers/assignment/action'

// ----------------------------------------------------------------------

KanbanTaskCard.propTypes = {
  course: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
}

function KanbanTaskCard(props) {
  const {
    course,
    index,
    courses,
    setIsUserReachedCourseLimit,
    isUserReachedCourseLimit,
    hoveredCollectionId,
    draggedCourseId,
    viewType,
    refresh,
    containers,
    setContainers,
    classes,
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { name, coverImageUrl } = course

  const [isMoved, setIsMoved] = useState(false)
  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  useEffect(() => {
    console.log('hoveredCollectionId', hoveredCollectionId)
    console.log('isMoved', isMoved)
    if (isMoved == true && hoveredCollectionId != null) {
      const hoveredCollection = containers.find(
        collection => collection.id === hoveredCollectionId,
      )
      console.log('hoveredCollection', hoveredCollection)
      const currentCollection = containers.find(
        collection => collection.id === selectedCollection,
      )
      let newCollections = [...containers]

      console.log('currentCollection', currentCollection)

      const indexOfCourse = currentCollection.courses.findIndex(
        course => course.id === draggedCourseId,
      )

      console.log('indexOfCourse', indexOfCourse)

      const course = currentCollection.courses[indexOfCourse]

      if (indexOfCourse !== -1) {
        currentCollection.courses.splice(indexOfCourse, 1)
        hoveredCollection.courses.push(course)

        newCollections = newCollections.map(collection => {
          if (collection.id === selectedCollection) {
            return currentCollection
          } else if (collection.id === hoveredCollectionId) {
            return hoveredCollection
          } else {
            return collection
          }
        })
        console.log('newCollections', newCollections)

        // set new courses for request to update
        for (const collection of newCollections) {
          if (
            collection.id === selectedCollection ||
            collection.id === hoveredCollectionId
          ) {
            collection.courses = collection.courses.map((course, index) => ({
              ...course,
              courseCollectionId: collection.id,
              courseCollectionOrder: index,
            }))
          }
        }
      }

      console.log('collection.courses', newCollections)
      const patchSource = new PatchData(null, course.id)
      patchSource.addContent(
        new PatchContent(
          hoveredCollection.id,
          'courseCollectionId',
          PatchData.OPERATION_REPLACE,
        ),
      )
      patchSource.addContent(
        new PatchContent(
          hoveredCollection.courses.length - 1,
          'courseCollectionOrder',
          PatchData.OPERATION_REPLACE,
        ),
      )

      dispatch(assignmentPatchAction(patchSource))
      setContainers(newCollections)

      //TODO Burada kurs collection içine taşınacak  Akif

      console.log('hoveredCollectionId', hoveredCollectionId)
      console.log('Draggaed Course Id', draggedCourseId)
      //console.log('containers', containers)
      //console.log('courses', courses)

      setIsMoved(false)
    }
  }, [hoveredCollectionId])

  const handleClick = course => {
    dispatch(
      assignmentGetAction(course.id, () => {
        window.scrollTo(0, 0)
        history.push('/mini-course/' + course.id)
      }),
    )
  }

  const onMouseUp = () => {
    setIsMoved(true)
  }

  const [onHover, setOnHover] = React.useState(false)
  /*const handleGetAnalytics = course => {
    dispatch(assignmentReportAction(course.id))
  }*/

  const coverImage =
    coverImageUrl === null
      ? 'https://cdn.minicoursegenerator.com/assets/gateway/course-cover.png'
      : coverImageUrl.includes('http')
      ? coverImageUrl
      : URL.CDN + coverImageUrl

  // DnD Kit
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
  } = useSortable({ id: `${course.id}-item` })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: 1100,
  }

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes}>
        <Paper
          onMouseEnter={() => {
            setOnHover(true)
          }}
          onMouseLeave={() => {
            setOnHover(false)
          }}
          className={classes.courseGalleryView}
          style={{
            outline: isDragging ? '1px solid #015FFB' : 'none',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${coverImage})`,
            }}
            className={classes.coverImage}
            onClick={() => {
              handleClick(course)
            }}
          >
            {onHover && (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    flexGrow: 1,
                  }}
                ></div>

                <Icon
                  {...listeners}
                  draggable
                  width={24}
                  height={24}
                  icon="eva:move-fill"
                  style={{
                    padding: '4px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    margin: '16px',
                    borderRadius: '50%',
                  }}
                  onMouseUp={() => onMouseUp()}
                />
              </div>
            )}
          </div>

          <Box
            sx={{
              cursor: 'pointer',
              padding: 1,
              paddingRight: 2,
              paddingLeft: 2,
            }}
            onClick={() => {
              handleClick(course)
            }}
          >
            <Tooltip
              title={course.name.length > 31 ? course.name : ''}
              placement="bottom"
            >
              <Typography
                style={{
                  whiteSpace: 'normal',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                noWrap
                variant="subtitle2"
                sx={{
                  transition: theme =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Box>
          <Box style={{ flexGrow: 1 }}></Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 16,
            }}
          >
            <KanbanCourseToolBar
              courses={courses}
              course={course}
              refresh={refresh}
              setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
              isUserReachedCourseLimit={isUserReachedCourseLimit}
            />
          </div>
        </Paper>
      </div>
    </>
  )
}

export default withStyles(styles)(KanbanTaskCard)
