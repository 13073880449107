import { put, call, take, fork } from 'redux-saga/effects'
import { patchCourseCollectionService } from '../../api/course-collection'
import { patchCourseCollectionSuccess } from '../../reducers/course-collection/action'
import { PATCH_COURSE_COLLECTION } from '../../reducers/course-collection/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* patchCourseCollectionFlow(payload) {
  try {
    const { content, callback } = payload
    const response = yield call(patchCourseCollectionService, content)
    yield put(patchCourseCollectionSuccess(content))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* patchCourseCollectionWatcher() {
  while (true) {
    const action = yield take(PATCH_COURSE_COLLECTION)
    const { payload } = action

    yield fork(generalSaga, patchCourseCollectionFlow, payload)
  }
}
