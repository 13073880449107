import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles } from '@material-ui/core'
import { Popover, Button, Alert, AlertTitle } from '@material-ui/core'
import Link from '../../link'

import InfoIcon from '@material-ui/icons/Info'

/**
 *
 * @param {*} props
 */
const ResultStandart = props => {
  const { dispatch, entity, classes } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info-score-popover' : undefined

  return <Link dispatch={dispatch} entity={entity} location={1} />
}

ResultStandart.propTypes = propTypes

export default withStyles(styles)(ResultStandart)
