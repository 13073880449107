import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Typography, TextField, Box, Button, Alert } from '@material-ui/core'
import styles from './styles'
import PurposeSelection from './select-purpose'
import SelectLanguage from './select-language'
const StepDescription = props => {
  const {
    profession,
    setProfession,
    audience,
    setAudience,
    description,
    setDescription,
    sendTitleRequest,
    errorMessage,
    language,
    setLanguage,
    purpose,
    setPurpose,
    classes,
  } = props

  return (
    <>
      <Box m={2}></Box>
      <SelectLanguage language={language} setLanguage={setLanguage} />
      <TextField
        value={profession}
        label={
          language != 'English'
            ? 'Tell us your profession ' + '(in ' + language + ')'
            : 'Tell us your profession'
        }
        placeholder="Business Coach, Personal Trainer..."
        onChange={event => setProfession(event.currentTarget.value)}
        fullWidth
      />
      <Box m={2}></Box>
      <TextField
        value={audience}
        variant="outlined"
        multiline
        label={
          language != 'English'
            ? 'What is your target audience? ' + '(in ' + language + ')'
            : 'What is your target audience?'
        }
        placeholder="Entrepreneurs, Beginner-Level Yoga learners..."
        onChange={event => setAudience(event.currentTarget.value)}
        fullWidth
      />
      <Typography className={classes.descriptionText}>
        The key to successful AI guidance is a precise and detailed description.
        Your description should be at least 5 words.
      </Typography>
      <Box m={2}></Box>

      <TextField
        value={description}
        multiline
        rows={2}
        placeholder="A course about how to create ..."
        fullWidth
        label={
          language != 'English'
            ? 'Description ' + '(in ' + language + ')'
            : 'Description'
        }
        onChange={event => setDescription(event.currentTarget.value)}
      ></TextField>
      <Box m={2}></Box>
      {errorMessage == true && (
        <>
          <Alert severity="warning">
            A problem occurred due to GPT-3. Please try again.
          </Alert>
          <Box m={2}></Box>
        </>
      )}
      {/*<PurposeSelection purpose={purpose} setPurpose={setPurpose} />*/}
      <Box m={2}></Box>
      <Button
        variant="contained"
        onClick={() => sendTitleRequest()}
        disabled={
          profession == undefined ||
          audience == undefined ||
          description == undefined ||
          description?.length < 20
            ? true
            : false
        }
      >
        Generate Title
      </Button>
      <Box m={2}></Box>
    </>
  )
}

export default withStyles(styles)(StepDescription)
