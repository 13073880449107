import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import Logo from 'src/components/logo/index'
import URL from 'src/api/url'
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '100%',
  },
  listItems: {
    paddingBottom: '10px',
    '&:hover': {
      color: '#0d53b7',
    },
  },
  mainGrid: {
    width: '100%',
  },
}))

function Policies(props) {
  const classes = useStyles()
  const [selectedField, setSelectedField] = useState(
    URL.APP_URL + 'GY8hJvUO4UGp3QIYQL1hVQ/1',
  )
  const [fieldName, setFieldName] = useState('')

  const changeFieldOnClick = e => {
    setFieldName(e.target.innerText)
    switch (e.target.innerText) {
      case 'Legal Notice and Terms of Use':
        setSelectedField(URL.APP_URL + 'GY8hJvUO4UGp3QIYQL1hVQ/1')
        break
      case 'Service Terms and Conditions':
        setSelectedField(URL.APP_URL + 'bZo3CYPYL0uQXYS9GBM96A/1')
        break
      case 'Privacy Policy':
        setSelectedField(URL.APP_URL + 'aODrzz-7AU6b61n2ocbF4Q/1')
        break

      case 'Data Processing Agreement':
        setSelectedField(URL.APP_URL + '87FZmqQTKUSwvrkjA32vgQ/1')
        break
      case 'Cookies Policy':
        setSelectedField(URL.APP_URL + '65wQiJLNuU-eX2Ki_wNkhg/1')
        break
      case 'Payment Terms and Conditions':
        setSelectedField(URL.APP_URL + 'moW-8ExnpU6OpijWBwFIww/1')
        break
      case 'Report Abuse':
        setSelectedField(URL.APP_URL + 'RelkYyixpUqhPJohCtdLvw/1')
        break

      default:
        return null
    }
  }
  return (
    <Grid container>
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div style={{ paddingTop: '20px' }}>
            <Logo sx={{ height: 64 }} />
          </div>

          <div className={classes.drawerContainer}>
            <List>
              {[
                'Legal Notice and Terms of Use',
                'Service Terms and Conditions',
                'Privacy Policy',
                'Data Processing Agreement',
                'Cookies Policy',
                'Payment Terms and Conditions',
                'Report Abuse',
              ].map((text, index) => (
                <ListItem
                  className={classes.listItems}
                  button
                  key={text}
                  onClick={e => {
                    changeFieldOnClick(e)
                  }}
                >
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
        <Grid className={classes.mainGrid}>
          <Toolbar />

          <iframe
            width={1100}
            height={900}
            style={{ border: '0' }}
            title="Legal Notice and Terms of Use"
            src={selectedField}
          ></iframe>
        </Grid>
      </div>
    </Grid>
  )
}

export default Policies
