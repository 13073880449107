import { put, call, take, fork } from 'redux-saga/effects'
import { setUserRoleService } from '../../api/user'
import { setUserRoleSuccess } from '../../reducers/user-role-detailed-list/action'
import { SET_USER_ROLE } from '../../reducers/user-role-detailed-list/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* setUserRoleFlow(payload) {
  try {
    const response = yield call(setUserRoleService, payload.data)
    yield put(setUserRoleSuccess(payload.data))

    yield put(operationSuccess())
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* setUserRoleWatcher() {
  while (true) {
    const action = yield take(SET_USER_ROLE)
    const { payload } = action

    yield fork(generalSaga, setUserRoleFlow, payload)
  }
}
