import { put, call, take, fork } from 'redux-saga/effects'
import { createCertificateService } from 'src/api/certificate'
import { createCertificateSuccess } from 'src/reducers/certificate/action.js'
import { CREATE_CERTIFICATE } from 'src/reducers/certificate/type.js'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* createCertificateFlow(payload) {
  try {
    const { collectionId, callback } = payload
    const response = yield call(createCertificateService, collectionId)

    yield put(createCertificateSuccess(response.data))
    yield put(operationSuccess())
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* createCertificateSagaWatcher() {
  while (true) {
    const action = yield take(CREATE_CERTIFICATE)
    const { payload } = action

    yield fork(generalSaga, createCertificateFlow, payload)
  }
}
