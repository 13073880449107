import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  withStyles,
  Typography,
  Grid,
  Card,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import { Link as RouterLink } from 'react-router-dom'
import styles from './style'
import translator from '../../translate'
import { getUserLimits, customerPortal } from '../../api/limit'
import { LimitTypeEnum } from 'src/model/limit'
import UserType from 'src/model/user-types'
import { track } from '@amplitude/analytics-browser'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'

const ProfileInformation = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(false)
  const [type, setType] = useState('')
  const [courseLimitation, setcourseLimitation] = useState('')
  const [aiCourseCount, setaiCourseCount] = useState('')
  const [courseCount, setcourseCount] = useState('')
  const [loading, dispatchLoading] = useState(0) // 0: nothing, 1: loading

  const directUrl = () => {
    dispatchLoading(1)
    customerPortal().then(response => {
      let sessionUrl = response.data.sessionUrl
      window.open(`${sessionUrl}`)
      dispatchLoading(0)
    })
  }

  const openStripeAlertModal = () => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          'We are having an issue with the subscription service provider.',
          [
            new ButtonData(ButtonType.NORMAL, 'Ok', ButtonActionType.NO, () => {
              dispatch(messageBoxHideAction())
            }),
          ],
          'If you want to cancel your subscription, upgrade, or downgrade, please reach us through the widget right-below or send an email to support@minicoursegenerator.com. We’ll handle your request immediately.',
        ),
      ),
    )
  }

  useEffect(() => {
    getUserLimits().then(response => {
      let aiCount = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).count
      setaiCourseCount(aiCount)

      let ccount = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).count
      setcourseCount(ccount)

      let userType = response.data[0].limit.roleType
      setType(userType)

      let courseLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.CourseCreation,
      ).limit.upperLimit
      setcourseLimitation(courseLimit)

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).limit.upperLimit
      setLimit(limit)
    })
  }, [])
  const tooltipText = ''
  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item>
          <Typography variant={'h3'}>
            {translator._('profile.profile')}
          </Typography>
        </Grid>
        <Grid item className={classes.items}>
          <TableContainer component={Card} className={classes.card}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableTitle}
                  >
                    {translator._('profile.accountInfo')}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.type')}
                  </TableCell>
                  <TableCell align="center">{UserType[type]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {translator._('profile.miniCourseCount')}
                  </TableCell>
                  <TableCell align="center">
                    {courseLimitation === -1 ? (
                      <Typography>
                        {translator._('profile.unlimited')}
                      </Typography>
                    ) : (
                      <Typography>
                        {courseCount}/{courseLimitation}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {translator._('profile.aiCourseCount')}{' '}
                    <Tooltip
                      style={{
                        maxWidth: 500,
                      }} // Custom width as 500px, applied to Tooltip directly
                      classes={{ tooltip: classes.customWidth }}
                      PopperProps={{
                        style: { maxWidth: 500, margin: 20, width: 500 },
                      }} // Custom width as 500px, applied to Popper directly
                      title={
                        <div
                          style={{
                            width: '100%',
                            maxwidth: '500px',
                            padding: '10px',
                          }}
                        >
                          <Typography color="inherit">
                            Here is how AI-credits work:
                          </Typography>
                          We have transactions based on activity and character
                          count on the backlog. Hence, our AI credits are
                          defined according to the volume of an action as
                          follows:
                          <br />
                          <br />
                          <ul
                            style={{
                              marginLeft: 15,
                            }}
                          >
                            <li>
                              Generating a mini-course title on AI Course
                              Creator will require 1 credit.
                            </li>
                            <li>
                              Generating a mini-course outline on AI Course
                              Creator will require 1 credit.
                            </li>
                            <li>
                              Bringing the mini-course based on an outline will
                              require 2 credits for every card formed. Each
                              subheader under a header on the outline will
                              correspond to a card in the mini-course. PS: If
                              you've turned off AI-images for that mini-course,
                              it will take 1 credit to get each card.
                            </li>
                            <li>
                              Using AI-Assistant inside content cards with +++
                              will require 1 AI credit.
                            </li>
                            <li>
                              Using in-card AI image generator with your own
                              prompts will require 5 AI credits.
                            </li>
                          </ul>
                          <br />
                          <br />
                          Number of AI credits used for each mini-course
                          generation totally changes on the length of outline
                          and other factors, like using AI-image generation
                          frequently.
                          <br />
                          <br />
                          AI credits renew every 30 days, and the day of renewal
                          is determined based on the first date on which AI
                          credits started to be used.
                        </div>
                      }
                    >
                      <Icon
                        icon="eva:info-outline"
                        style={{
                          fontSize: '20px',
                          color: '#015FFB',
                          marginLeft: '5px',
                          marginTop: '3px',
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {aiCourseCount}/{limit}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className={classes.buttonGroup}>
              {(UserType[type] == 'Premium v2' ||
                UserType[type] == 'Premium Plus v2') && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={directUrl}
                >
                  {translator._('profile.paymentDetails')}
                </Button>
              )}

              {(UserType[type] == 'Premium' ||
                UserType[type] == 'Super Admin' ||
                UserType[type] == 'Premium Admin Account (Quarterly)' ||
                UserType[type] == 'Premium Admin Account (Starter)' ||
                UserType[type] == 'Starter - Legacy' ||
                UserType[type] == 'Starter' ||
                UserType[type] == 'Starter - A' ||
                UserType[type] == 'Premium - A' ||
                UserType[type] == 'Premium Plus') && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={openStripeAlertModal}
                >
                  {translator._('profile.paymentDetails')}
                </Button>
              )}
              {UserType[type] == 'Regular Admin Account' && (
                <Button
                  className={classes.button}
                  to="/pricing"
                  sx={{
                    mr: 1,
                  }}
                  variant="contained"
                  startIcon={
                    <Icon
                      size="small"
                      icon="fa6-solid:crown"
                      color="#f7b155"
                      width={15}
                    ></Icon>
                  }
                  component={RouterLink}
                  onClick={() => {
                    track('limit_warning_upgrade', {
                      from: 'account_page_upgrade',
                    })
                  }}
                >
                  {translator._('general.upgrade')}
                </Button>
              )}

              <div className={classes.midDiv}></div>
            </div>
            {loading == 1 ? <CircularProgress /> : ''}
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ProfileInformation)
