import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const Badge = props => {
  const { classes } = props

  return (
    <div className={classes.badge} style={{}}>
      <Typography fontSize={'11px'} fontWeight={500} color="#9747FF">
        Pro
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Badge)
