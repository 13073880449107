import React, { useEffect } from 'react'
import translator from 'src/translate'
import styles from './styles'
import {
  withStyles,
  Typography,
  Button,
  Popover,
  Modal,
  Box,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import { AssignmentFieldNames } from 'src/model/assignment'
import URL from 'src/api/url'
import LinkIcon from '@material-ui/icons/LinkOutlined'
import CodeIcon from '@material-ui/icons/Code'
import { track } from '@amplitude/analytics-browser'

import SettingsDrawer from 'src/components/settings-drawer'
import ThemeSettingsDrawer from 'src/components/theme-settings'
import AnalyticsDrawer from 'src/components/analytics-drawer'
import ShareLinkStep from '../share-link'
import EmbedStep from '../embed'

import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import MyDoc from './pdf-export/full-pdf-download'
import { initialThemeSettings } from 'src/model/theme'
import { PdfStyleSheet } from './pdf-export/pdf-style'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { assignmentReportAction } from 'src/reducers/assignment/action'
import UserType from 'src/model/user-types'
import ScormDownloadModal from 'src/components/scorm-download-modal'
import UpgradePopup from 'src/components/new-upgrade-popup'

import {
  processStartAction,
  processStartEnd,
} from 'src/reducers/process/action'
import { useSelector } from 'react-redux'
import { settingsDrawerOpenSuccess } from 'src/reducers/assignment/action'
const ButtonBar = props => {
  const {
    entity,

    setThemeDrawerOpen,
    themeDrawerOpen,
    analyticsDrawerOpen,
    shareLinkModalOpen,
    shareEmbedModalOpen,
    setSettingDrawerOpen,
    setAnalyticsDrawerOpen,
    setShareLinkModalOpen,
    setShareEmbedModalOpen,
    dispatch,
    classes,
    history,
  } = props
  const settingsDrawerOpen = useSelector(
    state => state.assignment.settingsDrawerOpen,
  )
  const handleDrawerClose = () => {
    dispatch(settingsDrawerOpenSuccess())
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [scormPopupOpen, setScormPopupOpen] = React.useState(false)
  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const premiumControl = () => {
    if (
      userControl == 'Premium Plus' ||
      userControl == 'Premium Plus v2' ||
      userControl == 'Premium - A' ||
      userControl == 'Appsumo (Tier 3)' ||
      userControl == 'Appsumo (Tier 3A)' ||
      userControl == 'Appsumo (Tier 4)' ||
      userControl == 'Appsumo (Tier 5)'
    ) {
      return false
    } else return true
  }

  const [scormExportUserEligable, setScormExportEligable] = React.useState(
    premiumControl(),
  )
  const [pdfUpgradePopupOpen, setPdfUpgradePopupOpen] = React.useState(false)

  useEffect(() => {
    setScormExportEligable(premiumControl())
  }, [userControl])

  const handleClickShare = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseShare = () => {
    setAnchorEl(null)
  }

  const handleOpenLink = () => {
    track('Course_Share', {
      type: 'Share Link',
    })
    setShareLinkModalOpen(true)
  }
  const handleScormClick = () => {
    setScormPopupOpen(true)
    track('Course_Share', {
      type: 'Export to SCORM ',
    })
  }

  const handlePdfClick = async () => {
    track('Course_Share', {
      type: 'Export to PDF ',
    })

    if (UserType[window.CONSTANTS.USER_ROLE_ID] == 'Regular Admin Account') {
      setPdfUpgradePopupOpen(true)

      return false
    }

    dispatch(processStartAction())

    let cards = entity ? entity.cardDtos : []

    let themeSettings = initialThemeSettings
    let logo = null
    let coverText = null

    if (entity && entity.settingDtos.find(x => x.key == 'theme') != undefined) {
      themeSettings = JSON.parse(
        entity.settingDtos.find(x => x.key == 'theme').value,
      )
    }
    if (
      entity &&
      entity.settingDtos.find(x => x.key == 'general') != undefined
    ) {
      logo = JSON.parse(entity.settingDtos.find(x => x.key == 'general').value)
        .logo

      coverText = JSON.parse(
        entity.settingDtos.find(x => x.key == 'general').value,
      ).coverPageText
    }

    let processedEntity = entity

    /*  if (processedEntity.coverImageUrl.includes('.jpg')) {
      let coverImageUrl = entity.coverImageUrl.includes('https://')
        ? entity.coverImageUrl
        : URL.CDN + entity.coverImageUrl + '?not-from-cache-please2'

      processedEntity.coverImageUrl = await convertJpgToPng(coverImageUrl)
    }
 */
    const processedCards = await processCards(cards)

    const pdfStyles = PdfStyleSheet(themeSettings)
    dispatch(processStartEnd())

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <div
              style={{
                maxWidth: 400,
                textAlign: 'center',
                alignSelf: 'center',
              }}
            >
              <PDFDownloadLink
                style={pdfStyles.downloadButton}
                document={
                  <MyDoc
                    cards={processedCards}
                    themeSettings={themeSettings}
                    logo={logo}
                    entity={processedEntity}
                    coverText={coverText}
                  />
                }
                fileName={entity?.name + '.pdf'}
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Creating document...' : 'Download Pdf'
                }
              </PDFDownloadLink>
              Your file is being created. The process may take a long time if
              the number of pages is large.
            </div>
          ),
          {
            hideCloseButton: false,
            header: 'Download Pdf',
            callback: e => {},
          },
        ),
      ),
    )
  }

  async function processCards(cards) {
    // Use Promise.all to wait for all promises to resolve
    const processedCards = await Promise.all(
      cards.map(async card => {
        if (
          card.mediaDtos &&
          card.mediaDtos.length > 0 &&
          card.mediaDtos[0].mediaType === 1 &&
          card.mediaDtos[0].mediaUrl.includes('jpg') &&
          !card.mediaDtos[0].mediaUrl.includes('data:image')
        ) {
          try {
            let srcUrl = card.mediaDtos[0].mediaUrl.includes('https')
              ? card.mediaDtos[0].mediaUrl
              : URL.CDN + card.mediaDtos[0].mediaUrl

            const jpgUrl = srcUrl // Your JPG image URL
            const pngDataUrl = await convertJpgToPng(jpgUrl)
            card.mediaDtos[0].mediaUrl = pngDataUrl
            // The PNG data URL is now set on the card, and can be used as needed
            return card
          } catch (error) {
            console.error('Error converting image:', error)
            // Optionally, return null or the unmodified card if conversion fails
            // so you can filter them out or handle them later
            return card
          }
        } else {
          // If the card doesn't meet the criteria, return it unmodified
          return card
        }
      }),
    )

    // Filter out any null values if you returned null in case of errors
    return processedCards.filter(card => card !== null)
  }

  function convertJpgToPng(jpgUrl) {
    return new Promise((resolve, reject) => {
      var img = new Image()

      img.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        // Convert the image to PNG format
        var pngDataUrl = canvas.toDataURL('image/png')

        // Resolve the promise with the converted PNG URL
        resolve(pngDataUrl)
      }

      // Handle image loading errors
      img.onerror = function () {
        reject(new Error('Could not load the image at the provided URL.'))
      }

      img.crossOrigin = 'Anonymous' // This is needed if you're loading an image from a different domain and want to avoid CORS issues
      img.src = jpgUrl
    })
  }

  const handleOpenShare = () => {
    track('Course_Share', {
      type: 'Embed',
    })
    setShareEmbedModalOpen(true)
    setAnchorEl(null)
  }

  const handleGetAnalytics = () => {
    dispatch(assignmentReportAction(entity.id))
  }

  const handleCloseShareLink = () => setShareLinkModalOpen(false)
  const handleCloseShareEmbed = () => setShareEmbedModalOpen(false)
  const handleScormClose = () => setScormPopupOpen(false)
  const handlePdfClose = () => setPdfUpgradePopupOpen(false)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const shareLinkCourse = course => {
    track('Course_Share', {
      type: 'Share Link',
    })
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <ShareLinkStep dispatch={dispatch} entity={course} />,
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }

  return (
    <div className={classes.buttons}>
      <Button
        size="small"
        variant="outlined"
        href={entity ? URL.APP_URL + entity[AssignmentFieldNames.CODE] : ''}
        target="_blank"
        onClick={() => {
          track('view_clicked')
        }}
        className={classes.previewBtn}
      >
        <Icon
          icon="ic:baseline-remove-red-eye"
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        View
      </Button>
      <Button
        size="small"
        variant="outlined"
        className={classes.previewBtn}
        onClick={() => {
          setThemeDrawerOpen(true)
          track('theme_clicked')
        }}
      >
        <Icon
          icon="ic:baseline-palette"
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        Theme
      </Button>

      <Button
        size="small"
        variant="outlined"
        className={classes.previewBtn}
        onClick={() => {
          handleDrawerClose()
          track('settings_clicked')
        }}
      >
        <Icon
          icon="material-symbols:settings-rounded"
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        Settings
      </Button>

      <Button
        size="small"
        variant="outlined"
        className={classes.previewBtn}
        onClick={() => {
          setAnalyticsDrawerOpen(true)
          track('report_clicked')
        }}
      >
        <Icon
          icon="material-symbols:equalizer"
          style={{ marginTop: '3px', marginRight: '5px' }}
          width="16"
          height="16"
        />
        Report
      </Button>

      <Button
        aria-describedby={id}
        size="small"
        variant="outlined"
        disableElevation={true}
        className={classes.shareBtn}
        onClick={event => {
          handleClickShare(event)
        }}
      >
        {translator._('assignment.share')}
        <Icon
          icon="material-symbols:keyboard-arrow-down-rounded"
          style={{ marginTop: '3px' }}
          width="16"
          height="16"
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseShare}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          marginTop: '8px',
          marginRight: '55px',
        }}
      >
        <div className={classes.shareButtonGroup}>
          <div
            onClick={() => shareLinkCourse(entity)}
            className={classes.shareButton}
          >
            <LinkIcon
              style={{
                fontSize: '16px',
                marginTop: '3px',
                marginRight: '5px',
                color: '#FFD243',
              }}
            />
            <Typography
              textAlign="center"
              style={{ width: 'max-content', fontSize: '0.775rem' }}
            >
              Share with <br /> a Link
            </Typography>
          </div>

          <div onClick={handleOpenShare} className={classes.shareButton}>
            <CodeIcon
              style={{
                fontSize: '16px',
                marginTop: '3px',
                marginRight: '5px',
                color: '#FF8E4F',
              }}
            />
            <Typography
              textAlign="center"
              style={{ width: 'max-content', fontSize: '0.775rem' }}
            >
              Embed on <br /> a website
            </Typography>
          </div>

          <div onClick={handlePdfClick} className={classes.shareButtonScorm}>
            <Icon size="small" icon="octicon:file-16" color="#497fc9"></Icon>
            <Typography
              textAlign="center"
              style={{
                width: 'max-content',
                fontSize: '0.775rem',
                marginTop: '2px',
              }}
            >
              Export to <br /> PDF{' '}
              <Icon size="small" icon="fa6-solid:crown" color="#f7b155"></Icon>
            </Typography>
          </div>

          <div onClick={handleScormClick} className={classes.shareButtonScorm}>
            <Icon size="small" icon="octicon:package-16" color="#497fc9"></Icon>
            <Typography
              textAlign="center"
              style={{ width: 'max-content', fontSize: '0.775rem' }}
            >
              Export to <br /> SCORM{' '}
              <Icon size="small" icon="fa6-solid:crown" color="#f7b155"></Icon>
            </Typography>
          </div>
        </div>
      </Popover>

      {entity && <SettingsDrawer dispatch={dispatch} entity={entity} />}
      {entity && (
        <ThemeSettingsDrawer
          dispatch={dispatch}
          entity={entity}
          setThemeDrawerOpen={setThemeDrawerOpen}
          themeDrawerOpen={themeDrawerOpen}
        />
      )}
      {entity && (
        <AnalyticsDrawer
          dispatch={dispatch}
          entity={entity}
          setAnalyticsDrawerOpen={setAnalyticsDrawerOpen}
          analyticsDrawerOpen={analyticsDrawerOpen}
          handleGetAnalytics={handleGetAnalytics}
        />
      )}

      <Modal
        open={shareEmbedModalOpen}
        onClose={() => {
          handleCloseShareEmbed()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <EmbedStep dispatch={dispatch} entity={entity}></EmbedStep>
        </Box>
      </Modal>
      <Modal
        open={scormPopupOpen}
        onClose={() => {
          handleScormClose()
        }}
        aria-labelledby="scorm-modal"
        aria-describedby="scorm-modal"
      >
        <Box className={classes.modalStyleScorm}>
          {scormExportUserEligable ? (
            <UpgradePopup
              upgradeButtonText={'Upgrade to the Pro Plan'}
              upgradeDescription={
                <Typography fontSize={'14px'} fontWeight={400}>
                  Upgrade to the{' '}
                  <span className={classes.text_span}>Pro Plan</span> to export
                  your courses to{' '}
                  <span className={classes.text_span}>SCORM</span> and access
                  exclusive features.
                </Typography>
              }
              planName={'Pro'}
              onClose={() => handleScormClose()}
            />
          ) : (
            <ScormDownloadModal
              dispatch={dispatch}
              entity={entity}
              onClose={() => handleScormClose()}
            />
          )}
        </Box>
      </Modal>
      <Modal
        open={pdfUpgradePopupOpen}
        onClose={() => {
          handlePdfClose()
        }}
        aria-labelledby="scorm-modal"
        aria-describedby="scorm-modal"
      >
        <Box className={classes.modalStyleScorm}>
          <UpgradePopup
            upgradeButtonText={'Upgrade to the Premium'}
            upgradeDescription={
              <Typography fontSize={'14px'} fontWeight={400}>
                Upgrade to the{' '}
                <span className={classes.text_span}>Premium Plan</span> to
                export your courses to{' '}
                <span className={classes.text_span}>PDF</span> and access
                exclusive features.
              </Typography>
            }
            planName={'Pro'}
            onClose={() => handlePdfClose()}
          />
        </Box>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(ButtonBar)
