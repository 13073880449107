import React from 'react'
import styles from './style'
import propTypes from './prop'
import ClearIcon from '@material-ui/icons/Clear'
import { componentBoxHideAction } from '../../reducers/component-box/action'
import { withStyles, IconButton, Modal, Typography } from '@material-ui/core'

/**
 *
 * @param {*} props
 */
const ComponentBox = props => {
  const { classes, content, dispatch } = props


  let drawerPaper = document.getElementsByClassName('MuiDrawer-paper')[0]
  if (drawerPaper != undefined) {
    drawerPaper.removeAttribute('tabindex')
    drawerPaper.removeAttribute('role')
  }

  /**
   *
   */
  const closeHandler = e => {

    const bodyElement = document.getElementsByTagName('body')[0]
    bodyElement.style.overflow = 'auto'

    if (content.options.hideCloseButton) {
      return true
    }

    dispatch(componentBoxHideAction())
    if (content.options.callback) {
      content.options.callback()
    }


  }

  return (
    <React.Fragment>
      {content && (
        <Modal
          open={true}
          onClose={closeHandler}
          className={classes.modal}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
          }}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalTop}>
              <div
                className={classes.modalLeft}
                style={{
                  backgroundImage: content.options.backgroundColor
                    ? `url(${content.options.backgroundColor})`
                    : 'inherit',
                  backgroundColor: content.options.backgroundColor || '#ffffff',
                  color: content.options.fontColor || '#000000',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  borderRadius: 5,
                }}
              >
                {content.options.header && (
                  <div className={classes.headerGroup}>
                    <Typography variant="h6" id="modal-modal-title">
                      {content.options.header}
                    </Typography>
                  </div>
                )}
                {!content.options?.hideCloseButton && (
                  <div className={classes.closeButton}>
                    <IconButton onClick={closeHandler}>
                      <ClearIcon />
                    </IconButton>
                  </div>
                )}
                <div className={classes.modalBody}>{content.element}</div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

ComponentBox.propTypes = propTypes

export default withStyles(styles)(ComponentBox)
