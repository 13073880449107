import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
const ZapierEmbed = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <zapier-full-experience
        sign-up-email="email_of_your_user@example.com"
        sign-up-first-name="first_name_of_your_user"
        sign-up-last-name="last_name_of_your_user"
        client-id="BBNMgMIiTPRXdNLYTAyQQz5rDmoYPOy6YomMQvdc"
        theme="light"
        app-search-bar-display="show"
      />
    </div>
  )
}

export default withStyles(styles)(ZapierEmbed)
