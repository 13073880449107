import { authenticationCurrentUserInfoAction } from 'src/reducers/authentication/action'
import { meService } from '../api/authentication'
import { get } from 'es-cookie'

const systemMiddleware = api => next => async action => {
  if (
    api.getState().authentication.me == null &&
    !api.getState().authentication.sendMeOperation
  ) {
    if (get('token')) {
      api.getState().authentication.sendMeOperation = true

      api.dispatch(authenticationCurrentUserInfoAction())
    }

    return next(action)
  } else {
    return next(action)
  }
}

export default systemMiddleware
