import React, { useState } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'
import { FormControlLabel, Switch } from '@material-ui/core'
import translator from 'src/translate'

const ShareViewHeader = props => {
  const { classes } = props
  const [showShareButtons, setShowShareButtons] = useState(0)

  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__headerWithIcon}>
        <Typography fontSize={'15px'} fontWeight={500}>
          Share Link
        </Typography>
      </div>

     
    </div>
  )
}

export default withStyles(styles)(ShareViewHeader)
