import {
  ASSIGNMENT_UPDATE_ENTITY,
  ASSIGNMENT_ADD,
  ASSIGNMENT_ADD_SUCCESS,
  ASSIGNMENT_ADD_ERROR,
  ASSIGNMENT_LIST,
  ASSIGNMENT_LIST_SUCCESS,
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_GET,
  ASSIGNMENT_GET_SUCCESS,
  ASSIGNMENT_GET_ERROR,
  ASSIGNMENT_EDIT,
  ASSIGNMENT_EDIT_SUCCESS,
  ASSIGNMENT_EDIT_ERROR,
  ASSIGNMENT_UPDATE_ATTRIBUTE,
  ASSIGNMENT_REMOVE_USER,
  ASSIGNMENT_PATCH,
  ASSIGNMENT_PATCH_SUCCESS,
  ASSIGNMENT_PATCH_ERROR,
  ASSIGNMENT_REPORT,
  ASSIGNMENT_REPORT_SUCCESS,
  ASSIGNMENT_REPORT_ERROR,
  QUESTION_PATCH_SUCCESS,
  QUESTION_MEDIA_ADD_SUCCESS,
  QUESTION_MEDIA_DELETE_SUCCESS,
  QUESTION_LINK_ADD_SUCCESS,
  QUESTION_SELECTION_ADD_SUCCESS,
  QUESTION_SELECTION_DELETE_SUCCESS,
  QUESTION_LINK_DELETE_SUCCESS,
  QUESTION_SELECTION_PATCH_SUCCESS,
  ASSIGNMENT_REMOVE_QUESTION_SUCCESS,
  ASSIGNMENT_REMOVE_QUESTION,
  ASSIGNMENT_ADD_BULK_QUESTION,
  ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS,
  ASSIGNMENT_ADD_BULK_QUESTION_ERROR,
  OPENAI_SEND_REQUEST,
  OPENAI_SEND_REQUEST_SUCCESS,
  OPENAI_SEND_REQUEST_ERROR,
  QUESTION_PAYMENT_DATA_UPDATE,
  QUESTION_PAYMENT_DISCONNECT,
  QUESTION_SETTING_PATCH,
  QUESTION_SETTING_PATCH_SUCCESS,
  QUESTION_SETTING_PATCH_ERROR,
  QUESTION_SETTING_CREATE,
  QUESTION_SETTING_CREATE_SUCCESS,
  QUESTION_SETTING_CREATE_ERROR,
  QUESTION_SETTING_REMOVE,
  ASSIGNMENT_CARD_LINK_PATCH,
  ASSIGNMENT_CARD_LINK_PATCH_SUCCESS,
  ASSIGNMENT_CARD_LINK_PATCH_ERROR,
  COURSE_SETTING_PATCH,
  COURSE_SETTING_PATCH_SUCCESS,
  COURSE_SETTING_PATCH_ERROR,
  COURSE_SETTING_CREATE,
  COURSE_SETTING_CREATE_SUCCESS,
  COURSE_SETTING_CREATE_ERROR,
  COURSE_SETTING_REMOVE,
  QUESTION_SELECTIONS_ADD,
  QUESTION_SELECTIONS_ADD_SUCCESS,
  QUESTION_SELECTIONS_ADD_ERROR,
  OPENAI_SEND_CARD_REQUEST_ERROR,
  OPENAI_SEND_CARD_REQUEST_SUCCESS,
  OPENAI_SEND_CARD_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_ERROR,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_ERROR,
  OPENAI_SEND_COURSE_CREATION_REQUEST,
  OPENAI_SEND_COURSE_CREATION_REQUEST_SUCCESS,
  OPENAI_SEND_COURSE_CREATION_REQUEST_ERROR,
  CLONE_TEMPLATE_COURSE,
  CLONE_TEMPLATE_COURSE_SUCCESS,
  CLONE_TEMPLATE_COURSE_ERROR,
  CREATE_PROMPT_FROM_CARD,
  CREATE_PROMPT_FROM_CARD_SUCCESS,
  CREATE_PROMPT_FROM_CARD_ERROR,
  QUESTION_CHANGE_IMAGE,
  GET_COURSE_ANALYTICS,
  GET_COURSE_ANALYTICS_SUCCESS,
  GET_COURSE_ANALYTICS_ERROR,
  ADD_COURSE_DOCUMENT,
  ADD_COURSE_DOCUMENT_SUCCESS,
  ADD_COURSE_DOCUMENT_ERROR,
  GET_COURSE_DOCUMENT_BY_ID,
  GET_COURSE_DOCUMENT_BY_ID_SUCCESS,
  GET_COURSE_DOCUMENT_BY_ID_ERROR,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_QUIZ_WITH_COURSE_DOCUMENT_ERROR,
  DELETE_COURSE_DOCUMENT,
  DELETE_COURSE_DOCUMENT_SUCCESS,
  DELETE_COURSE_DOCUMENT_ERROR,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_ERROR,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS,
  GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_ERROR,
  GENERATE_QUIZZES_BY_COURSE_ID,
  GENERATE_QUIZZES_BY_COURSE_ID_SUCCESS,
  GENERATE_QUIZZES_BY_COURSE_ID_ERROR,
  EXPORT_COURSE_TO_SCORM,
  EXPORT_COURSE_TO_SCORM_SUCCESS,
  EXPORT_COURSE_TO_SCORM_ERROR,
  SETTINGS_DRAWER_OPEN,
} from './type'

/**
 *
 * @param {*} entity
 */
export const assignmentUpdateEntityAction = entity => {
  return {
    type: ASSIGNMENT_UPDATE_ENTITY,
    payload: { entity },
  }
}

/**
 *
 * @param {*} query
 */
export const assignmentListAction = (query, callback) => {
  return {
    type: ASSIGNMENT_LIST,
    payload: { query, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const assignmentListSuccess = response => {
  return {
    type: ASSIGNMENT_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const assignmentListError = () => {
  return {
    type: ASSIGNMENT_LIST_ERROR,
  }
}

/**
 *
 * @param {*} id
 */
export const assignmentGetAction = (id, callback) => {
  return {
    type: ASSIGNMENT_GET,
    payload: { id, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentGetSuccess = entity => {
  return {
    type: ASSIGNMENT_GET_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const assignmentGetError = () => {
  return {
    type: ASSIGNMENT_GET_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const assignmentAddAction = (entity, history, callback) => {
  return {
    type: ASSIGNMENT_ADD,
    payload: { entity, history, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentAddSuccess = entity => {
  return {
    type: ASSIGNMENT_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const assignmentAddError = () => {
  return {
    type: ASSIGNMENT_ADD_ERROR,
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentEditAction = (entity, callback) => {
  return {
    type: ASSIGNMENT_EDIT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentEditSuccess = entity => {
  return {
    type: ASSIGNMENT_EDIT_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const assignmentEditError = () => {
  return {
    type: ASSIGNMENT_EDIT_ERROR,
  }
}

export const assignmentUpdateAttribute = (content, callback) => {
  return {
    type: ASSIGNMENT_UPDATE_ATTRIBUTE,
    payload: { content, callback },
  }
}

export const assignmentRemoveUser = (userId, callback) => {
  return {
    type: ASSIGNMENT_REMOVE_USER,
    payload: { userId, callback },
  }
}

export const assignmentRemoveQuestion = (questionId, courseId, callback) => {
  return {
    type: ASSIGNMENT_REMOVE_QUESTION,
    payload: { questionId, courseId, callback },
  }
}

export const assignmentRemoveQuestionSuccess = (questionId, callback) => {
  return {
    type: ASSIGNMENT_REMOVE_QUESTION_SUCCESS,
    payload: { questionId, callback },
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */
export const assignmentPatchAction = (content, callback) => {
  return {
    type: ASSIGNMENT_PATCH,
    payload: { content, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const assignmentPatchSuccess = content => {
  return {
    type: ASSIGNMENT_PATCH_SUCCESS,
    payload: { content },
  }
}

/**
 *
 */
export const ASSIGNMENTPatchError = () => {
  return {
    type: ASSIGNMENT_PATCH_ERROR,
  }
}

export const assignmentReportAction = id => {
  return {
    type: ASSIGNMENT_REPORT,
    payload: { id },
  }
}

/**
 *
 * @param {*} response
 */
export const assignmentReportSuccess = () => {
  return {
    type: ASSIGNMENT_REPORT_SUCCESS,
    payload: {},
  }
}

/**
 *
 */
export const assignmentReportError = () => {
  return {
    type: ASSIGNMENT_REPORT_ERROR,
  }
}

/**
 *
 * @param {*} content
 */
export const questionPatchSuccess = content => {
  return {
    type: QUESTION_PATCH_SUCCESS,
    payload: { content },
  }
}

/**
 *
 * @param {*} entity
 */
export const questionMediaAddSuccess = entity => {
  return {
    type: QUESTION_MEDIA_ADD_SUCCESS,
    payload: { entity },
  }
}

/*
 *
 *
 */

export const questionMediaDeleteSuccess = (id, questionId) => {
  return {
    type: QUESTION_MEDIA_DELETE_SUCCESS,
    payload: { id, questionId },
  }
}

/**
 *
 */
export const questionLinkAddSuccess = entity => {
  return {
    type: QUESTION_LINK_ADD_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 * @param {*} response
 */
export const questionSelectionAddSuccess = response => {
  return {
    type: QUESTION_SELECTION_ADD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 * @param {*} id
 * @param {*}  questionId
 */
export const questionSelectionDeleteSuccess = (id, questionId) => {
  return {
    type: QUESTION_SELECTION_DELETE_SUCCESS,
    payload: { id, questionId },
  }
}

/**
 *
 * @param {*} id
 * @param {*}  questionId
 */
export const questionLinkDeleteSuccess = (id, questionId) => {
  return {
    type: QUESTION_LINK_DELETE_SUCCESS,
    payload: { id, questionId },
  }
}

/**
 *
 * @param {*} content
 */
export const questionSelectionPatchSuccess = (content, questionId) => {
  return {
    type: QUESTION_SELECTION_PATCH_SUCCESS,
    payload: { content, questionId },
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const assignmentAddBulkQuestionAction = (entity, history, callback) => {
  return {
    type: ASSIGNMENT_ADD_BULK_QUESTION,
    payload: { entity, history, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const assignmentAddBulkQuestionSuccess = entity => {
  return {
    type: ASSIGNMENT_ADD_BULK_QUESTION_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const assignmentAddBulkQuestionError = () => {
  return {
    type: ASSIGNMENT_ADD_BULK_QUESTION_ERROR,
  }
}

/**
 *
 * @param {*} topic
 * @param {*} callback
 */
export const openaiSendRequestAction = (topic, callback) => {
  return {
    type: OPENAI_SEND_REQUEST,
    payload: { topic, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const openaiSendRequestSuccess = entity => {
  return {
    type: OPENAI_SEND_REQUEST_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const openaiSendRequestError = () => {
  return {
    type: OPENAI_SEND_REQUEST_ERROR,
  }
}

export const questionPaymentDataChangeAction = content => {
  return {
    type: QUESTION_PAYMENT_DATA_UPDATE,
    payload: { content },
  }
}

export const questionPaymentDisconnectAction = cardId => {
  return {
    type: QUESTION_PAYMENT_DISCONNECT,
    payload: { cardId },
  }
}

export const questionSettingPatch = (
  content,
  settingId,
  questionId,
  key,
  callback,
) => {
  return {
    type: QUESTION_SETTING_PATCH,
    payload: { content, settingId, questionId, key, callback },
  }
}

export const questionSettingPatchSuccess = (
  content,
  settingId,
  key,
  questionId,
) => {
  return {
    type: QUESTION_SETTING_PATCH_SUCCESS,
    payload: { content, settingId, key, questionId },
  }
}

export const questionSettingPatchError = () => {
  return {
    type: QUESTION_SETTING_PATCH_ERROR,
  }
}

export const questionSettingCreate = (content, questionId, callback) => {
  return {
    type: QUESTION_SETTING_CREATE,
    payload: { content, questionId, callback },
  }
}

export const questionSettingCreateSuccess = (content, questionId) => {
  return {
    type: QUESTION_SETTING_CREATE_SUCCESS,
    payload: { content, questionId },
  }
}

export const questionSettingCreateError = () => {
  return {
    type: QUESTION_SETTING_CREATE_ERROR,
  }
}

export const questionSettingRemove = (questionId, id) => {
  return {
    type: QUESTION_SETTING_REMOVE,
    payload: { questionId, id },
  }
}

export const assignmentCardLinkPatchAction = (content, cardId, callback) => {
  return {
    type: ASSIGNMENT_CARD_LINK_PATCH,
    payload: { content, cardId, callback },
  }
}

/**
 *
 * @param {*} content
 */
export const assignmentCardLinkPatchSuccess = (content, cardId) => {
  return {
    type: ASSIGNMENT_CARD_LINK_PATCH_SUCCESS,
    payload: { content, cardId },
  }
}

/**
 *
 */
export const assignmentCardLinkPatchError = () => {
  return {
    type: ASSIGNMENT_CARD_LINK_PATCH_ERROR,
  }
}

export const courseSettingPatch = (
  content,
  settingId,
  questionId,
  key,
  callback,
) => {
  return {
    type: COURSE_SETTING_PATCH,
    payload: { content, settingId, questionId, key, callback },
  }
}

export const courseSettingPatchSuccess = (
  content,
  settingId,
  key,
  questionId,
) => {
  return {
    type: COURSE_SETTING_PATCH_SUCCESS,
    payload: { content, settingId, key, questionId },
  }
}

export const courseSettingPatchError = () => {
  return {
    type: COURSE_SETTING_PATCH_ERROR,
  }
}

export const courseSettingCreate = (content, questionId, callback) => {
  return {
    type: COURSE_SETTING_CREATE,
    payload: { content, questionId, callback },
  }
}

export const courseSettingCreateSuccess = (content, questionId) => {
  return {
    type: COURSE_SETTING_CREATE_SUCCESS,
    payload: { content, questionId },
  }
}

export const courseSettingCreateError = () => {
  return {
    type: COURSE_SETTING_CREATE_ERROR,
  }
}

export const courseSettingRemove = (questionId, id) => {
  return {
    type: COURSE_SETTING_REMOVE,
    payload: { questionId, id },
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const questionSelectionsAddAction = (entity, callback) => {
  return {
    type: QUESTION_SELECTIONS_ADD,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const questionSelectionsAddSuccess = response => {
  return {
    type: QUESTION_SELECTIONS_ADD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const questionSelectionsAddError = () => {
  return {
    type: QUESTION_SELECTIONS_ADD_ERROR,
  }
}

/**
 * @param {*} starterText
 * @param {*} courseId
 * @param {*} cardId
 * @param {*} callback
 */
export const openaiSendCardRequestAction = (
  starterText,
  courseId,
  cardId,
  callback,
) => {
  return {
    type: OPENAI_SEND_CARD_REQUEST,
    payload: { starterText, courseId, cardId, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const openaiSendCardRequestSuccess = entity => {
  return {
    type: OPENAI_SEND_CARD_REQUEST_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const openaiSendCardRequestError = () => {
  return {
    type: OPENAI_SEND_CARD_REQUEST_ERROR,
  }
}

/**
 * @param {*} profession
 * @param {*} audience
 * @param {*} title
 * @param {*} description
 * @param {*} purpose
 * @param {*} callback
 */
export const openaiSendCourseCreationGetTitleRequestAction = (
  profession,
  audience,
  description,
  language,
  purpose,
  callback,
) => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST,
    payload: { profession, audience, description, language, purpose, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const openaiSendCourseCreationGetTitleRequestSuccess = entity => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const openaiSendCourseCreationGetTitleRequestError = () => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_TITLE_REQUEST_ERROR,
  }
}

/**
 * @param {*} profession,
 * @param {*} audience,
 * @param {*} title,
 * @param {*} language,
 * @param {*} callback
 */
export const openaiSendCourseCreationGetOutlineRequestAction = (
  profession,
  audience,
  title,
  language,
  purpose,
  callback,
) => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST,
    payload: { profession, audience, title, language, purpose, callback },
  }
}

/**
 *
 * @param {*} entity
 */
export const openaiSendCourseCreationGetOutlineRequestSuccess = entity => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_SUCCESS,
    payload: { entity },
  }
}

/**
 *
 */
export const openaiSendCourseCreationGetOutlineRequestError = () => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_GET_OUTLINE_REQUEST_ERROR,
  }
}
/**
 * @param {*} firstCardText
 * @param {*} title
 * @param {*} outlines
 * @param {*} description
 * @param {*} profession
 * @param {*} courseCollectionId
 * @param {*} themeSettings
 * @param {*} purpose
 * @param {*} selectedPreset
 * @param {*} imageGenerator
 * @param {*} callback
 */
export const openaiSendCourseCreationRequestAction = (
  title,
  outlines,
  profession,
  audience,
  description,
  themeSettings,
  courseCollectionId,
  variables,
  gatewaySelection,
  language,
  purpose,
  imageGenerator,
  generateImage,
  callback,
) => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_REQUEST,
    payload: {
      title,
      outlines,
      profession,
      audience,
      description,
      themeSettings,
      courseCollectionId,
      variables,
      gatewaySelection,
      language,
      purpose,
      imageGenerator,
      generateImage,
      callback,
    },
  }
}

/**
 *
 * @param {*} code
 * @param {*} title
 * @param {*} collectionId
 * @param {*} callback
 */
export const cloneTemplateCourseAction = (
  code,
  title,
  collectionId,
  callback,
) => {
  return {
    type: CLONE_TEMPLATE_COURSE,
    payload: { code, title, collectionId, callback },
  }
}

/**
 *
 * @param {*} entity
 */

export const openaiSendCourseCreationRequestSuccess = entity => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_REQUEST_SUCCESS,
    payload: { entity },
  }
}

export const questionCloneSuccess = response => {
  return {
    type: CLONE_TEMPLATE_COURSE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const openaiSendCourseCreationRequestError = () => {
  return {
    type: OPENAI_SEND_COURSE_CREATION_REQUEST_ERROR,
  }
}

export const questionCloneError = () => {
  return {
    type: CLONE_TEMPLATE_COURSE_ERROR,
  }
}

/**
 *
 * @param {*} starterText
 */
export const createPromptFromCardAction = (starterText, callback) => {
  return {
    type: CREATE_PROMPT_FROM_CARD,
    payload: { starterText, callback },
  }
}

export const createPromptFromCardSuccess = response => {
  return {
    type: CREATE_PROMPT_FROM_CARD_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const createPromptFromCardError = () => {
  return {
    type: CREATE_PROMPT_FROM_CARD_ERROR,
  }
}

/**
 *
 * @param {*} cardId
 * @param {*} imageUrl
 * @param {*} callback
 */
export const questionChangeImageAction = (cardId, imageUrl, callback) => {
  return {
    type: QUESTION_CHANGE_IMAGE,
    payload: { cardId, imageUrl, callback },
  }
}

/**
 *
 * @param {*} courseId
 * @param {*} callback
 */
export const getCourseAnalyticsByIdAction = (courseId, callback) => {
  return {
    type: GET_COURSE_ANALYTICS,
    payload: { courseId, callback },
  }
}

export const getCourseAnalyticsByIdSuccess = response => {
  return {
    type: GET_COURSE_ANALYTICS_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const getCourseAnalyticsByIdError = () => {
  return {
    type: GET_COURSE_ANALYTICS_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const addCourseDocumentAction = (entity, callback) => {
  return {
    type: ADD_COURSE_DOCUMENT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const addCourseDocumentSuccess = response => {
  return {
    type: ADD_COURSE_DOCUMENT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const addCourseDocumentError = () => {
  return {
    type: ADD_COURSE_DOCUMENT_ERROR,
  }
}

/**
 *
 * @param {*} courseId
 * @param {*} callback
 */
export const getCourseDocumentByIdAction = (courseId, callback) => {
  return {
    type: GET_COURSE_DOCUMENT_BY_ID,
    payload: { courseId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const getCourseDocumentByIdSuccess = response => {
  return {
    type: GET_COURSE_DOCUMENT_BY_ID_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const getCourseDocumentByIdError = () => {
  return {
    type: GET_COURSE_DOCUMENT_BY_ID_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const generateQuizWithCourseDocumentAction = (entity, callback) => {
  return {
    type: GENERATE_QUIZ_WITH_COURSE_DOCUMENT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const generateQuizWithCourseDocumentSuccess = response => {
  return {
    type: GENERATE_QUIZ_WITH_COURSE_DOCUMENT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const generateQuizWithCourseDocumentError = () => {
  return {
    type: GENERATE_QUIZ_WITH_COURSE_DOCUMENT_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const deleteCourseDocumentAction = (entity, callback) => {
  return {
    type: DELETE_COURSE_DOCUMENT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const deleteCourseDocumentSuccess = response => {
  return {
    type: DELETE_COURSE_DOCUMENT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const deleteCourseDocumentError = () => {
  return {
    type: DELETE_COURSE_DOCUMENT_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const generateSuggestionWithCourseDocumentAction = (
  entity,
  callback,
) => {
  return {
    type: GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const generateSuggestionWithCourseDocumentSuccess = response => {
  return {
    type: GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const generateSuggestionWithCourseDocumentError = () => {
  return {
    type: GENERATE_SUGGESTION_WITH_COURSE_DOCUMENT_ERROR,
  }
}

/**
 *
 * @param {*} entity
 * @param {*} callback
 */
export const generateInformationWithCourseDocumentAction = (
  entity,
  callback,
) => {
  return {
    type: GENERATE_INFORMATION_WITH_COURSE_DOCUMENT,
    payload: { entity, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const generateInformationWithCourseDocumentSuccess = response => {
  return {
    type: GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const generateInformationWithCourseDocumentError = () => {
  return {
    type: GENERATE_INFORMATION_WITH_COURSE_DOCUMENT_ERROR,
  }
}

/**
 *
 * @param {*} courseId
 * @param {*} callback
 */
export const generateQuizzesByCourseIdAction = (courseId, callback) => {
  return {
    type: GENERATE_QUIZZES_BY_COURSE_ID,
    payload: { courseId, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const generateQuizzesByCourseIdSuccess = response => {
  return {
    type: GENERATE_QUIZZES_BY_COURSE_ID_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const generateQuizzesByCourseIdError = () => {
  return {
    type: GENERATE_QUIZZES_BY_COURSE_ID_ERROR,
  }
}

/**
 *
 * @param {*} id
 * @param {*} scormVersion
 */

export const exportCourseToScormAction = (
  courseName,
  id,
  scormVersion,
  callback,
) => {
  return {
    type: EXPORT_COURSE_TO_SCORM,
    payload: { courseName, id, scormVersion, callback },
  }
}

/**
 *
 * @param {*} response
 */
export const exportCourseToScormSuccess = response => {
  return {
    type: EXPORT_COURSE_TO_SCORM_SUCCESS,
    payload: { response },
  }
}

/**
 *
 */
export const exportCourseToScormError = () => {
  return {
    type: EXPORT_COURSE_TO_SCORM_ERROR,
  }
}

/**
 *
 */
export const settingsDrawerOpenSuccess = () => {
  return {
    type: SETTINGS_DRAWER_OPEN,
  }
}
