import URL from '../url'
import Request from '../request'

export const createCertificateService = collectionId =>
  new Request(
    URL.API,
    URL.CREATE_CERTIFICATE.replace('{collectionId}', collectionId),
  ).post()

export const getCertificateService = collectionId =>
  new Request(
    URL.API,
    URL.GET_CERTIFICATE.replace('{collectionId}', collectionId),
  ).get()

export const patchCertificateService = content =>
  new Request(
    URL.API,
    URL.PATCH_CERTIFICATE.replace('{certificateId}', content.id),
  ).patch(content)

export const getCertificateListService = selectedCollectionId =>
  new Request(
    URL.API,
    URL.GET_CERTIFICATE_LIST.replace('{collectionId}', selectedCollectionId),
  ).get()
