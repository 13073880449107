const styles = theme => ({
  backButtonRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '36px',
    '&:hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid rgba(145, 158, 171, 0.32)',
  },
  magic_link_icon_button: {
    width: '24px',
    height: '24px',
  },
  bottomButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    width: 460,
    height: '40px',
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
})

export default styles
