import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import AnalyticsCard from 'src/components/analytics-card'
const DataTables = props => {
  const { data, classes } = props
  return (
    <div className={classes.container}>
      {data.map((item, index) => (
        <AnalyticsCard item={item} key={index} type="collection" />
      ))}
    </div>
  )
}

export default withStyles(styles)(DataTables)
