import React, { useState } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './style'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Icon } from '@iconify/react'
import { track } from '@amplitude/analytics-browser'
function OptionsList(props) {
  const { item, addTextToTextArea, options, classes } = props
  const [selectedOption, setSelectedOption] = useState()

  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = index => {
    setSelectedOption(index)
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
    setSelectedOption()
  }

  const addText = text => {
    addTextToTextArea(item.image, text)
  }
  return options.map((option, index) =>
    option.text == '' ? (
      ''
    ) : (
      <>
        <Paper
          key={option.index}
          className={classes.paper}
          sx={{
            p: 2,
            width: 1,
          }}
          onMouseEnter={() => handleMouseOver(index)}
          onMouseLeave={() => handleMouseOut()}
        >
          <div style={{ marginBottom: '20px' }}>
            <div className={classes.closeButton}>
              <Typography
                style={{
                  color: '#0d53b7',
                  fontSize: 14,
                  marginBottom: '2px',
                }}
              >
                {index + 1}
              </Typography>
            </div>
            {/*<Typography variant="body2">
              {option.text.replace(/(<([^>]+)>)/gi, '')}
              </Typography>*/}

            <Typography
              className={classes.cardText}
              dangerouslySetInnerHTML={{
                __html: `${option.text.replace(
                  '<ul>',
                  "<ul style='margin-left: 20px; margin-top: 8px;'>",
                )}`,
              }}
            ></Typography>
          </div>
          {selectedOption == index && isHovering && (
            <div className={classes.summary}>
              <CopyToClipboard text={option.text.replace(/(<([^>]+)>)/gi, '')}>
                <Button
                  variant="outlined"
                  className={classes.copyText}
                  onClick={() =>
                    track('ai_in_card_used', {
                      type: 'text_copied',
                    })
                  }
                >
                  <Icon
                    icon="fluent:copy-16-regular"
                    width="18"
                    height="19px"
                    style={{ marginTop: '4px', marginRight: '2px' }}
                    //className={classes.icon}
                  />
                  <Typography style={{ fontWeight: 400 }}>Copy Text</Typography>
                </Button>
              </CopyToClipboard>

              <div style={{ flexGrow: 1 }}></div>
              <Button
                variant="contained"
                style={{ backgroundColor: '#015FFB', borderRadius: '15px' }}
                onClick={() => {
                  addText(option.text)
                  track('ai_in_card_used', {
                    type: 'text_used',
                  })
                }}
              >
                <Typography style={{ fontWeight: 400 }}>Use Text</Typography>
              </Button>
            </div>
          )}
        </Paper>
      </>
    ),
  )
}

export default withStyles(styles)(OptionsList)
