import URL from '../url'
import Request from '../request'

/**
 *@param {*} collectionName
 *
 */
export const createCourseCollectionService = entity =>
  new Request(URL.API, URL.CREATE_COURSE_COLLECTION).post(entity)

/**
 *@param {*} id
 *
 */
export const deleteCourseCollectionService = id =>
  new Request(URL.API, URL.DELETE_COURSE_COLLECTION).delete({ id })

/**
 *
 *
 */
export const patchCourseCollectionService = content =>
  new Request(URL.API, URL.PATCH_COLLECTION + '?id=' + content.id).patch(
    content,
  )

/**
 * @param {*} query
 *
 */
export const listCourseCollectionService = query =>
  new Request(URL.API, URL.LIST_COURSE_COLLECTION).post(query)

/**
 * @param {*} entity
 *
 */
export const updateCourseCollectionService = entity =>
  new Request(URL.API, URL.UPDATE_COURSE_COLLECTION).put(entity)

/**
 *
 * @param {*} entity
 */
export const editCourseCollectionIndexService = entity =>
  new Request(URL.API, URL.EDIT_ASSIGNMENT_COURSE_COLLECTION_INDEX).put(entity)

/**
 *
 * @param {*} content
 */
export const collectionCoursePatchService = content =>
  new Request(URL.API, URL.PATCH_COLLECTION_COURSE + '?id=' + content.id).patch(
    content,
  )

/**
 *
 * @param {*} id
 */
export const getCollectionSettingsService = id =>
  new Request(
    URL.API,
    URL.GET_COLLECTION_TEAM_LIST + '/' + id + URL.GET_COLLECTION_SETTINGS,
  ).get()

/**
 *
 * @param {*} content
 */
export const patchCollectionSettingsService = content =>
  new Request(
    URL.API,
    URL.GET_COLLECTION_TEAM_LIST +
      '/' +
      content.id +
      URL.GET_COLLECTION_SETTINGS,
  ).patch(content)

/**
 * @param {*} collectionId
 * @param {*} courseId
 */

export const getCollectionAnalyticsService = (collectionId, courseId) => {
  return new Request(
    URL.API,
    URL.GET_COLLECTION_ANALYTICS.replace('{collectionId}', collectionId),
  ).get({ courseId })
}
