const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: 0,
      paddingRight: 0,
    },
    //marginBottom: theme.spacing(5),
  },
  title: {
    marginLeft: theme.spacing(0),
    alignSelf: 'center',
  },
  stepper: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '-2rem',
    },
  },
  breadcrumbs: {
    //marginTop: theme.spacing(1),
  },
  stepHolder: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: '100%',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  prevButton: {
    width: 64,
    height: 36,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  userList: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 120px)',
    display: 'block',
    //paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplate: 'auto / auto calc(100% - 72px)',
      gridTemplate: 'inherit',
      gridAutoFlow: 'dense',
      gap: '1rem',
      height: 'fit-content',
    },
    //paddingLeft: theme.spacing(4),
    //paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  widgetBackground: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      gridRow: 2,
      zIndex: '1',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    '&$activeIcon': {
      color: theme.palette.secondary.main,
    },
    '&$completedIcon': {
      color: theme.palette.secondary.main,
    },
  },
  activeIcon: {
    color: theme.palette.secondary.main,
  },
  completedIcon: {
    color: theme.palette.secondary.main,
  },
  link: { cursor: 'pointer' },

  previewBtn: {
    marginRight: theme.spacing(1),
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    '&:hover': {
      backgroundColor: '#015FFB',
      color: '#ffffff',
      border: 'none',
    },
  },

  shareBtn: {
    marginRight: theme.spacing(0),
    backgroundColor: '#035ffb',
    color: '#ffffff',
    border: 'none',
    '&:hover': {
      backgroundColor: '#015FFB',
      color: '#ffffff',
      border: 'none',
    },
  },
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  modalStyleScorm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    marginRight: 24,
    marginBottom: 28,
  },
  continueButton: {
    backgroundColor: '#00AB55',
    marginLeft: theme.spacing(2),
  },
  buttonText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  iframe: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    width: 350,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 10,
    padding: theme.spacing(2),
    justifyContent: 'end',
  },
  shareButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  shareBtnPopup: {
    color: '#637381',
    margin: '1px',
    justifyContent: 'flex-start',
    '&:hover': {
      color: '#015FFB',
      backgroundColor: '#ffffff',
    },
  },
  shareButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingLeft: '24px',
    paddingBottom: '8px',
    paddingRight: '24px',
    justifyContent: 'center',
    cursor: 'pointer',
    borderLeft: '1px solid #ececec',
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    '&:hover': {
      backgroundColor: '#E9F1FF',
    },
  },
  shareButtonScorm: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingLeft: '24px',
    paddingBottom: '8px',
    paddingRight: '24px',
    justifyContent: 'center',
    cursor: 'pointer',
    borderLeft: '1px solid #ececec',
    backgroundColor: '#ffffff',
    color: '#637381',
    border: 'none',
    '&:hover': {
      backgroundColor: '#E9F1FF',
    },
  },
  questionListRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonBarGrid: {
    width: '100%',
    height: '54px',
  },
  questionViewContainer: {
    background: '#f4f6f8',
    borderRadius: 20,
    marginTop: 16,
    paddingRight: 16,
    justifyContent: 'start',
    alignItems: 'start',
  },
  text_span: {
    fontSize: '15px',
    fontWeight: 500,
  },
})

export default styles
