import React from 'react'
import { useParams } from 'react-router-dom'
import { get as getCookie } from 'es-cookie'
import { set as setCookie } from 'es-cookie'

const Redirect = () => {
  let { url } = useParams()
  const urlObj = new URL(window.location.href)
  const params = new URLSearchParams(urlObj.search) // This extracts the query string and parses it
  const urlParam = params.get('url') // This gets the value of "url" parameter
  const token = getCookie('token')

  if (token == undefined) {
    setCookie('redirectUrl', urlParam)
    window.location.href = '/register'
  } else {
    window.location.href = urlParam
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <h1>Redirecting...</h1>
    </div>
  )
}

export default Redirect
