import React, { useState, useRef } from 'react'
import translator from 'src/translate'
// @mui
import { Paper, Button, Typography } from '@material-ui/core'
// redux
import { useDispatch } from 'react-redux'
// components
import Iconify from 'src/components/Iconify'
import CollectionEdit from '../collection-edit'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

// ----------------------------------------------------------------------

function KanbanColumnAdd(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const collectionNameRef = useRef(null)

  const [collectionName, setCollectionName] = useState('')
  const [isCollectionEditOpen, setIsCollectionEditOpen] = useState(false)

  const handleOpen = () => {
    setIsCollectionEditOpen(true)
  }

  const handleClose = () => {
    setIsCollectionEditOpen(false)
  }

  return (
    <Paper className={classes.kanbanColumnAdd__paper}>
      <Button
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        startIcon={
          <Iconify
            icon={'eva:plus-fill'}
            width={24}
            height={24}
            color={'#002E47'}
            style={{
              outline: '1px solid #919eab3d',
              borderRadius: '6px',
            }}
          />
        }
        onClick={handleOpen}
        className={classes.kanbanColumnAdd__button}
      >
        <Typography className={classes.kanbanColumnAdd__text}>
          {translator._('courseCollection.collection.add')}
        </Typography>
      </Button>

      {isCollectionEditOpen && (
        <CollectionEdit
          dispatch={dispatch}
          handleCloseModal={() => setIsCollectionEditOpen(false)}
          isModalOpen={isCollectionEditOpen}
        />
      )}
    </Paper>
  )
}

export default withStyles(styles)(KanbanColumnAdd)
