const styles = theme => ({
  root: {
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#f4f6f8',
    alignItems: 'center',
    width: '100%',
    padding: 16,
    display: 'flex',
  },
  root_purpose_mobile: {
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#f4f6f8',
    width: '100%',
    padding: 16,
    display: 'flex',
  },
  root_purpose: {
    height: '100%',
    justifyContent: 'center',
    backgroundColor: '#f4f6f8',
    width: '100%',
    display: 'flex',
  },
  main: {
    width: 'auto',
    display: 'block',

    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  video: {
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },

  image: {
    backgroundImage: 'url(/assets/signup_screen_newbg4.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    position: 'relative',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 32px',
    paddingLeft: '44px',
    width: '100%',
    height: '100%',
  },
  paper_purposeSelectionField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 16px',
    width: '100%',
    height: '100%',
    paddingLeft: '42px',
  },
  paper_mobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    height: '84vh',
  },
  paperText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  paperText_mobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form_mobile: { width: '100%', maxWidth: 480, backgroundColor: '#fff' },
  form: { width: '100%', backgroundColor: '#fff' },
  submit: {
    marginTop: theme.spacing(3),
    height: '50px',
    borderRadius: '16px',
    backgroundColor: '#015FFB',
    width: '100%',
  },
  submit_bottom: {
    height: '50px',
    borderRadius: '16px',
    backgroundColor: '#015FFB',
    width: '100%',
    textTransform: 'none',
  },
  submit_mobile: {
    marginTop: theme.spacing(3),
    height: '50px',
    maxWidth: 500,
    borderRadius: '16px',
    backgroundColor: '#015FFB',
    width: '100%',
    textTransform: 'none',
  },
  submit_mobile_purposeSelection: {
    height: '50px',
    borderRadius: '16px',
    backgroundColor: '#015FFB',
    width: '100%',
  },
  combo: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    //borderWidth: 1,
    //borderStyle: 'solid',
    boxSizing: 'border-box',
    padding: theme.spacing(2),

    '&:hover': {
      borderColor: '#80bdff',
    },
  },
  overlayText: {
    maxWidth: '90%',
    padding: 20,
    margin: '5%',
    background: '#f0ffff14',
    bottom: 0,
    position: 'absolute',
    color: 'whitesmoke',
  },
  interestButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonIndex: {
    color: '#808080',
    borderRadius: '4px',
    border: '1px solid #808080',
  },
  formGroup: {
    padding: '8px',
    //marginLeft: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formGroupCard: {
    borderRadius: '8px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    border: '1px solid #808080',
    background: '#ffffff',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  formGroupCard_mobile: {
    borderRadius: '8px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    maxWidth: 500,
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    border: '1px solid #808080',
    background: '#ffffff',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  onBoardingRoot: {
    width: '1000px',
    height: '550px',
    display: 'flex',
    flexDirection: 'row',
  },
  onBoardingRoot_leftSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRadius: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gap: '32px',
    justifyContent: 'center',
  },
  onBoardingRoot_leftSide_first: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
    borderRadius: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gap: '32px',
    justifyContent: 'center',
  },
  purpose_leftSide: {
    display: 'flex',
    flexDirection: 'column',
    //height: '100%',
    width: '100%',
    borderRadius: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gap: '32px',
  },
  onBoardingRoot_leftSide_middleImage: {
    width: '440px',
    height: '440px',
  },
  onBoardingRoot_leftSide_stepper: {
    margin: '0 auto',
    height: '10px',
  },
  onBoardingRoot_rightSide: {
    width: '50%',
    height: '100%',
    padding: '0px 48px',
  },
  onBoardingRoot_rightSide_text: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: '100%',
  },
  onBoardingRoot_padding_bottom: {
    paddingBottom: '12px',
  },
  onBoarding_FX: {
    flexGrow: 1,
  },
  onBoarding_buttonGroup: {
    width: '100%',
    height: '48px',
    display: 'flex',
    gap: 8,
  },
  onBoardingRoot_skipButton: {
    color: '#919EAB',
    boxShadow: 'none',
    height: '100%',
    borderRadius: '12px',
  },
  onBoardingRoot_nextButton: {
    boxShadow: 'none',
    height: '100%',
    backgroundColor: '#015FFB',
    borderRadius: '12px',
    width: '100%',
    textTransform: 'none',
  },
  rootMobile: {
    justifyContent: 'center',
    backgroundColor: '#f4f6f8',
    width: '100%',
    padding: 16,
    display: 'flex',
    height: '100%',
  },
  onBoardingRootMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '84vh',
  },
  onBoardingRoot_leftSideMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gap: '32px',
    justifyContent: 'center',
    height: '400px',
    marginBottom: 12,
  },
  onBoardingRoot_leftSide_middleImageMobile: {
    width: '300px',
    height: '300px',
  },
  onBoardingRoot_leftSide_stepperMobile: {
    margin: '0 auto',
    height: '10px',
  },
  onBoardingRoot_rightSideMobile: {
    width: '100%',
    padding: '24px 0px',
    marginBottom: 12,
  },
  onBoardingRoot_rightSide_textMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },

  onBoarding_buttonGroupMobile: {
    width: '100%',
    height: '48px',
    display: 'flex',
    gap: 8,
  },
  onBoardingRoot_skipButtonMobile: {
    color: '#919EAB',
    boxShadow: 'none',
    height: '100%',
    borderRadius: '12px',
  },
  onBoardingRoot_nextButtonMobile: {
    boxShadow: 'none',
    height: '100%',
    backgroundColor: '#015FFB!important',
    borderRadius: '12px',
    width: '100%',
    textTransform: 'none',
  },
})

export default styles
