import React, { useState, useEffect } from 'react'
import styles from './style'
import propTypes from './prop'
import {
  withStyles,
  FormControl,
  FormLabel,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  FormGroup,
  Switch,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import translator from 'src/translate'
import { showModal } from 'src/components/upgrade-popup'

import { AssignmentFieldNames } from 'src/model/assignment'
import {
  QuestionFieldNames,
  CardSettingsTypes,
  CardSettingBuilder,
} from 'src/model/question'

import { useDispatch, useSelector } from 'react-redux'

import {
  useCardSettings,
  patchSetting,
  createSetting,
} from 'src/hooks/useCardSettings'

import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'

const CardSettings = props => {
  const { classes, cardId, closeThisModal } = props
  const baseEntity = useSelector(
    state => state.assignment.entity[AssignmentFieldNames.QUESTION_DTOS],
  )
  const dispatch = useDispatch()
  const entity = baseEntity.find(x => x[QuestionFieldNames.ID] == cardId)
  const [maskedInputs, setMaskedInputs] = useState({})

  const settingsSchema = CardSettingBuilder(
    entity[QuestionFieldNames.QUESTION_TYPE],
  )
  const baseSettings =
    CardSettingsTypes[entity[QuestionFieldNames.QUESTION_TYPE]]

  const cardBaseSettings = useCardSettings(cardId, 'cardSettings')

  const isUserPremium = Number(window.CONSTANTS.USER_ROLE_ID) !== 1
  useEffect(() => {
    if (!cardBaseSettings) {
      createSetting(
        dispatch,
        cardId,
        'cardSettings',
        JSON.stringify(settingsSchema),
        () => {},
      )
    }
  }, [cardBaseSettings])

  const cardSettings = cardBaseSettings
    ? JSON.parse(cardBaseSettings.value)
    : settingsSchema

  const handleOpenPremiumDetails = () => {
    closeThisModal()
    showModal(dispatch) // premium details
  }

  return (
    <FormControl className={classes.form}>
      {baseSettings &&
        baseSettings.settings.map((value, index) => {
          let domContent = <></>

          const saveAction = newValue => {
            //if (!isUserPremium) return false
            patchSetting(
              dispatch,
              cardId,
              cardBaseSettings.id,
              'cardSettings',
              JSON.stringify({
                ...cardSettings,
                [value.key]: newValue,
              }),
              () => {},
            )
          }

          switch (value.type) {
            case 'string':
              domContent = (
                <TextField
                  key={index}
                  label={value.label}
                  onBlur={e => saveAction(e.target.value)}
                  defaultValue={cardSettings[value.key]}
                  disabled={!cardSettings /*|| !isUserPremium*/}
                  fullWidth
                />
              )
              break
            case 'password':
              domContent = (
                <TextField
                  key={index}
                  type={maskedInputs[index] ? 'text' : 'password'}
                  label={value.label}
                  onBlur={e => saveAction(e.target.value)}
                  defaultValue={cardSettings[value.key]}
                  disabled={!cardSettings /*|| !isUserPremium*/}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setMaskedInputs({
                              ...maskedInputs,
                              [index]: !maskedInputs[index],
                            })
                          }
                          edge="end"
                        >
                          {maskedInputs[index] ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )
              break
            case 'boolean':
              domContent = (
                <div className={classes.dom}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={cardSettings[value.key]}
                          onChange={e => saveAction(e.target.checked)}
                        />
                      }
                      disabled={!cardSettings /*|| !isUserPremium}*/}
                      label={value.label}
                    />
                  </FormGroup>
                </div>
              )
              break
            case 'number':
              domContent = (
                <TextField
                  type="number"
                  key={index}
                  label={value.label}
                  onBlur={e => saveAction(e.target.value)}
                  defaultValue={cardSettings[value.key]}
                  disabled={!cardSettings /*|| !isUserPremium*/}
                  fullWidth
                />
              )
              break
            default:
              break
          }

          return (
            <div className={classes.action} key={index}>
              {domContent}
              {/*  {!isUserPremium && (
                <span
                  className={classes.badge}
                  onClick={handleOpenPremiumDetails}
                >
                  Premium
                </span>
              )} */}
            </div>
          )
        })}
    </FormControl>
  )
}

CardSettings.propTypes = propTypes

export default withStyles(styles)(CardSettings)
