const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    padding: '16px',
    gap: '16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  customizeText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    color: '#002E47',
    height: '36px',
    gap: 8,
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid rgba(145, 158, 171, 0.32)',
      cursor: 'pointer',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '4px',
  },
  templateArea: {
    backgroundColor: '#000',
    height: '663px',
    width: '940px',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    outline:0
  },
})

export default styles
