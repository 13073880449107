import React from 'react'
import { IconButton, Typography, withStyles, MenuItem } from '@material-ui/core'
import MenuPopover from 'src/components/MenuPopover'

import styles from '../styles'
import Iconify from 'src/components/Iconify'

const TeamListItem = props => {
  const { name, number1, teamId, removeTeam, type, classes } = props
  const [open, setOpen] = React.useState(null)

  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }
  return (
    <div
      className={classes.team_list_root}
      style={{
        backgroundColor: type == 'green' ? '#fff' : '#F4F6F8',
        marginLeft: type == 'green' && '12px',
        marginRight: type == 'green' && '12px',
      }}
    >
      <div
        className={classes.team_list_root_avatar}
        style={{
          backgroundColor: type == 'green' ? '#F4F6F8' : '#FFFFFF',
        }}
      >
        {type == 'black' && (
          <img width={20} height={15} src="/assets/vector-black.svg " />
        )}
        {type == 'blue' && (
          <img width={20} height={15} src="/assets/vector.png " />
        )}
        {type == 'yellow' && (
          <img width={20} height={15} src="/assets/yellow-users.png " />
        )}
        {type == 'green' && (
          <img width={20} height={15} src="/assets/green-vector.png " />
        )}
      </div>
      <div className={classes.team_list_root_column}>
        <div className={classes.root__teamName}>
          <Typography
            fontSize={'15px'}
            fontWeight={500}
            color={type == 'yellow' && '#FFAB00'}
          >
            {name}
          </Typography>
          {type == 'blue' && (
            <IconButton
              size="small"
              onClick={handleOpen}
              color={open ? 'inherit' : 'default'}
              className={classes.iconButton}
            >
              <Iconify icon={'ri:more-2-fill'} width={20} height={20} />
            </IconButton>
          )}
        </div>
        <div className={classes.root__teamName__number}>
          {(type == 'blue' || type == 'black' || type == 'green') && (
            <>
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                className={classes.mr}
              >
                {number1}
              </Typography>
              <Typography
                fontSize={'12px'}
                fontWeight={400}
                color="#637381"
                className={classes.root__teamName_divider}
              >
                Learners
              </Typography>
            </>
          )}
          {type == 'yellow' && (
            <>
              <Typography
                fontSize={'12px'}
                fontWeight={400}
                color="#637381"
                className={classes.root__teamName_divider}
              >
                {number1}
              </Typography>
            </>
          )}
        </div>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          className={classes.menuPopover}
        >
          <MenuItem
            onClick={() => {
              removeTeam(teamId)
              handleClose()
            }}
          >
            Remove Group
          </MenuItem>
        </MenuPopover>
      </div>
    </div>
  )
}

export default withStyles(styles)(TeamListItem)
