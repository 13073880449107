const badgeUsageTypeEnum = {
  COMPLETION: 0,
  SUCCESS: 1,
}
const data = [
  {
    id: badgeUsageTypeEnum.COMPLETION,
    title: 'Completion Badge',
    description: 'Earned upon completion of the mini-course.',
  },
  {
    id: badgeUsageTypeEnum.SUCCESS,
    title: 'Success Badge',
    description: 'Earned upon surpassing the success rate threshold you set.',
  },
]
export default data
