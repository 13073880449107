const data = [
  {
    logo: '/assets/automation/zapier.png',
    productName: 'Zapier',
    buttonClass: 'zapierButton',
    buttonUrl: 'https://zapier.com/app/login',
    logoHeight: 30,
  },
  {
    logo: '/assets/automation/make.png',
    productName: 'Make',
    buttonClass: 'makeButton',
    buttonUrl: 'https://www.make.com/en/login',
    logoHeight: 28,
  },
]

export default data
