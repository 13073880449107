import React, { useState } from 'react'
import { Typography, TextField, withStyles } from '@material-ui/core'
import styles from './styles'
import { patchSetting } from 'src/hooks/useCardSettings'
import { useDispatch } from 'react-redux'
const SuccessRate = props => {
  const dispatch = useDispatch()
  const { classes, badgeSettings, settings } = props

  const [percentage, setPercentage] = useState(badgeSettings.successRate)

  const handlePercentage = e => {
    //do not let the user enter more than 100
    let percentage = e
    if (e > 100) {
      percentage = 100
    }
    setPercentage(percentage)
  }

  const patchPercentage = e => {
    let percentage = e
    if (e > 100) {
      percentage = 100
    }
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({
        ...badgeSettings,
        successRate: percentage,
      }),
    )
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.heading}>
        Award the badge if the success rate equals or exceeds
      </Typography>
      <TextField
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment: <Typography>%</Typography>,
        }}
        inputProps={{
          style: {
            height: 38,
            padding: '0 8px',
            '&::focus': {
              outline: '1px solid #015FFB',
            },
          },
        }}
        value={percentage}
        onChange={event => handlePercentage(event.target.value)}
        onBlur={event => patchPercentage(event.target.value)}
        className={classes.textField}
        //accepts only number
        type="number"
      />
    </div>
  )
}

export default withStyles(styles)(SuccessRate)
