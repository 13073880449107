const styles = theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    height: 'inherit',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  secondaryGrid: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    alignItems: 'center',
    overflow: 'auto',
    borderRadius: '8px',
    backgroundColor: '#E5EAEF',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  childGrid: {
    margin: theme.spacing(1.5),
  },
  distantGrid: {
    height: '500px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: '980px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'block',
    },
  },

  textField: {
    padding: '10px',
  },
  cards: {
    margin: '10px',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    padding: 32,
    '&:hover': {
      outline: '1px solid #0d53b7',
      cursor: 'pointer',
    },
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    background: '#f2f2f2',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem',
    marginTop: '0.5rem',
    '&:hover': {
      cursor: 'default',
    },
  },
  button: {
    minWidth: '10px',
    marginRight: '5px',
    paddingLeft: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  limitText: {
    fontSize: 'smaller',
    marginTop: 25,
    textAlign: 'center',
  },

  circularBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '500px',
    width: '980px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  accordion: {
    backgroundColor: 'rgb(255 255 255 / 40%)',
    borderRadius: '8px',
    width: '100%',
    marginBottom: '4px',
    '&:before': {
      display: 'none',
    },

    borderRadius: '8px',
    width: '100%',
    marginBottom: '4px',
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default styles
