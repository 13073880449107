import React, { useEffect } from 'react'
import { withStyles, Button, Typography } from '@material-ui/core'
import styles from './styles'
import GeneralSettings from 'src/components/user-access'
const LearnerAccess = props => {
  return (
    <div>
      <GeneralSettings />
    </div>
  )
}

export default withStyles(styles)(LearnerAccess)
