import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '../../../components/Page'
import style from './style'
// material
import { styled } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Typography,
  withStyles,
  TextField,
  Button,
} from '@material-ui/core'
import translator from 'src/translate'
import WarningIcon from '@material-ui/icons/Warning'
// components
import SuccessCode from './success'
import { componentBoxShowAction } from '../../../reducers/component-box/action'
import ComponentBoxContent from '../../../model/component-box'
import { appsumoCouponRefundAction } from '../../../reducers/common/action'

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}))

// ----------------------------------------------------------------------

function RefundCoupon(props) {
  const dispatch = useDispatch()
  const { classes, history } = props

  const [promoCodes, setPromoCodes] = useState('')

  const onHandleAppsumoCodeRefund = event => {
    let newValue = []
    newValue = promoCodes.split(',').map(x => (x = x.trim()))

    if (newValue.length != 0) {
      dispatch(
        appsumoCouponRefundAction(newValue, () => {
          successCode()
        }),
      )
    }
  }
  const successCode = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <SuccessCode history={history} dispatch={dispatch} />,
          { hideCloseButton: true },
        ),
      ),
    )
  }

  return (
    <RootStyle title="Use Coupon">
      <Box m={5}></Box>
      <Grid className={classes.innerGrid}>
        <WarningIcon color="primary" size="small" className={classes.icon} />
        <Box m={1}></Box>
        <Typography variant="h3" paragraph>
          {translator._('message.refundCode')}
        </Typography>
        <Box m={1}></Box>
        <WarningIcon color="primary" size="small" className={classes.icon} />
      </Grid>
      <Box m={2}></Box>
      <Grid container className={classes.innerGrid}>
        <Grid item xs={6} md={2}>
          <TextField
            fullWidth
            label={'Code'}
            id="code"
            name="code"
            onChange={e => setPromoCodes(e.target.value)}
          />
        </Grid>
        <Box m={2}></Box>

        <Button
          variant="contained"
          color="primary"
          onClick={onHandleAppsumoCodeRefund}
        >
          <Typography>{translator._('message.refundCode')}</Typography>
        </Button>
      </Grid>
    </RootStyle>
  )
}

export default withStyles(style)(RefundCoupon)
