import React, { useState, useEffect } from 'react'
import styles from './styles'
import { withStyles, Typography } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { AssignmentFieldNames, AssignmentTypes } from 'src/model/assignment'
import { QuestionFieldNames } from 'src/model/question'
import Outline from './outline'
import { useHistory } from 'react-router-dom'
import NamingModal from 'src/components/naming-modal'
import useWindowDimensions from 'src/utils/windowDimension'
import URL from 'src/api/url'
import { startConnection } from 'src/utils/signalR-connection/signalR-connection'
import { addCourseCreationMessageReceivedListener } from 'src/utils/signalR-connection/signalR-connection'

const CourseLeftSide = props => {
  const {
    dispatch,
    classes,
    onAdd,
    onDrag,
    page,
    setPage,
    onDelete,
    sortCard,
    entity,
  } = props
  const [outlineOptions, setOutlineOptions] = useState([])
  const [showNamingModal, setShowNamingModal] = useState(false)
  let history = useHistory()
  const { height } = useWindowDimensions()
  console.log(entity[AssignmentFieldNames.COVERIMAGEURL])
  const [coverUrl, setCoverUrl] = useState(
    entity[AssignmentFieldNames.COVERIMAGEURL] != null
      ? entity[AssignmentFieldNames.COVERIMAGEURL].includes('https')
        ? entity[AssignmentFieldNames.COVERIMAGEURL]
        : `${URL.CDN}${entity[AssignmentFieldNames.COVERIMAGEURL]}`
      : 'https://cdn.minicoursegenerator.com/assets/gateway/course-cover.png',
  )

  useEffect(() => {
    setCoverUrl(
      entity[AssignmentFieldNames.COVERIMAGEURL] != null
        ? entity[AssignmentFieldNames.COVERIMAGEURL].includes('https')
          ? entity[AssignmentFieldNames.COVERIMAGEURL]
          : `${URL.CDN}${entity[AssignmentFieldNames.COVERIMAGEURL]}`
        : 'https://cdn.minicoursegenerator.com/assets/gateway/course-cover.png',
    )
  }, [])

  useEffect(() => {
    createOutlineObject()
    startConnection()
    setTimeout(() => {
      handleSocketMessage()
    }, 2000)
  }, [entity])

  const handleSocketMessage = () => {
    addCourseCreationMessageReceivedListener(async message => {
      const courseId = entity.id
      if (courseId.toString() == message[0]) {
        setCoverUrl(message[1])
      }
    })
  }

  const createOutlineObject = () => {
    if (entity == null) {
      return ''
    }

    const cardDTOS = entity[AssignmentFieldNames.QUESTION_DTOS]
    let sections = []
    cardDTOS.map((card, index) => {
      let sectionId =
        card[QuestionFieldNames.SECTION] != null
          ? card[QuestionFieldNames.SECTION].id
          : 0
      if (sections.find(x => x.id == sectionId) == undefined) {
        let section = {
          id: sectionId,
          header: card[QuestionFieldNames.SECTION]
            ? card[QuestionFieldNames.SECTION].name
            : '',
          subHeaders: [
            {
              id: card[QuestionFieldNames.ID],
              index: card[QuestionFieldNames.SORT_ORDER],
              name: card[QuestionFieldNames.TITLE],
              sectionName: card[QuestionFieldNames.SECTION]
                ? card[QuestionFieldNames.SECTION].name
                : '',
              sectionId: card[QuestionFieldNames.SECTION]
                ? card[QuestionFieldNames.SECTION].id
                : 0,
            },
          ],
        }

        sections.push(section)
      } else if (sections.find(x => x.id == sectionId) !== undefined) {
        let section = sections.find(x => x.id == sectionId)

        let subHeader = {
          id: card[QuestionFieldNames.ID],
          index: card[QuestionFieldNames.SORT_ORDER],
          name: card[QuestionFieldNames.TITLE],
          sectionName: card[QuestionFieldNames.SECTION]
            ? card[QuestionFieldNames.SECTION].name
            : '',
          sectionId: sectionId,
        }
        section.subHeaders.push(subHeader)
      }
    })

    setOutlineOptions(sections)

    return sections
  }

  const saveOutline = outline => {
    setOutlineOptions(outline)
  }

  return entity ? (
    <div>
      <div className={classes.barWidth}></div>
      <div className={classes.leftSide}>
        <div
          id={`${entity.id}-coverImage`}
          style={{
            backgroundImage: `url(${coverUrl})`,
          }}
          className={classes.coverImage}
        >
          <div className={classes.drawerContent}>
            {entity && (
              <Typography
                className={classes.courseName}
                onClick={() => {
                  setShowNamingModal(true)
                }}
              >
                {entity.name}
              </Typography>
            )}
          </div>
          <div
            className={classes.editIconHolder}
            onClick={() => {
              setShowNamingModal(true)
            }}
          >
            <span className={classes.editCoverText}>Edit Cover</span>
            <Icon
              icon="material-symbols:edit"
              color="white"
              className={classes.editIcon}
              onClick={() => {
                setShowNamingModal(true)
              }}
            />
          </div>
        </div>

        <Outline
          onAddCard={onAdd}
          entity={entity}
          outline={outlineOptions}
          saveOutline={saveOutline}
          dispatch={dispatch}
          onDrag={onDrag}
          page={page}
          setPage={setPage}
          onDelete={onDelete}
          height={height}
          sortCard={sortCard}
        />
        {showNamingModal && (
          <NamingModal
            classes={classes}
            dispatch={dispatch}
            courseEntity={entity}
            history={history}
            handleCloseModal={() => setShowNamingModal(false)}
            isModalOpen={showNamingModal}
            selectedAssignmentType={AssignmentTypes.ONETIME}
            coverImageUrlParent={coverUrl}
            setCoverUrl={setCoverUrl}
          />
        )}
        <div></div>
      </div>
    </div>
  ) : null
}

export default withStyles(styles)(CourseLeftSide)
