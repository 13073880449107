const styles = theme => ({
  container: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
    justifyContent: 'center',
  },
  certificateItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
    width: '210px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  certificateImage: {
    '&:hover': {
      outline: '1px solid #015ffb',
      borderRadius: '8px',
    },
  },
  certificateText: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    minHeight: '42px',
  },
})

export default styles
