import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { CollectionListDrawer, CollectionView, PageFactory } from './components'
import {
  courseCollectionListAction,
  editCourseCollectionIndexAction,
  setSelectedCollectionAction,
  setSelectedPageViewAction,
} from 'src/reducers/course-collection/action'
import { teamListAction } from 'src/reducers/teams/action'
import { domainListAction } from 'src/reducers/domain/action'
import { isMobile } from 'react-device-detect'
import clsx from 'clsx'
import { getCollectionSettingsAction } from 'src/reducers/course-collection/action'
import { courseCollectionUpdateAction } from 'src/reducers/course-collection/action'
import { get as getCookie, set as setCookie } from 'es-cookie'
import { PatchContent, PatchData } from 'src/model/patch'

const DEFAULT_COLLECTION_GUID = '00000000-0000-0000-0000-000000000000'

const defaultCollection = {
  id: DEFAULT_COLLECTION_GUID,
  collectionName: 'Independent Mini-Courses',
  collectionIndex: 0,
  isDeleted: false,
  userId: '',
  description: '',
}

const MainPage = props => {
  const { classes } = props
  const collectionIdFromUrl = window.location.pathname.split('/')[1]

  const dispatch = useDispatch()
  const history = useHistory()

  function findValueOfItems(id, type) {
    if (type === 'container') {
      // Directly return the container if it's found
      return containers.find(container => container.id === id)
    } else if (type === 'item') {
      // Loop through each container to find the item
      for (const container of containers) {
        const item = container.courses.find(x => x.id == id)

        if (item) {
          // Return the found item, not the container

          return container
        }
      }
    }
    return null // Return null if nothing is found
  }

  function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
    return JSON.stringify(arr)
  }

  useEffect(() => {
    const queryCollection = {
      CurrentPage: 0,
      PageSize: 20,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }

    const queryDomainList = {
      CurrentPage: 0,
      PageSize: 200,
      SortingColumn: 'LastModifiedAt',
      Asc: false,
      FilterText: '',
      Filters: [],
    }

    dispatch(
      courseCollectionListAction(queryCollection, () =>
        dispatch(domainListAction(queryDomainList)),
      ),
    )
  }, [dispatch])

  const openCookieValue =
    getCookie('collectionDrawerOpen') != undefined
      ? getCookie('collectionDrawerOpen') === 'true'
      : true

  const value = useSelector(state => state.courseCollectionList.list)
  const [open, setOpen] = useState(openCookieValue)

  useEffect(() => {
    setCookie('collectionDrawerOpen', open)
  }, [open])

  const [activeId, setActiveId] = useState(null)
  const [draggedCourseId, setDraggedCourseId] = useState(null)
  const [hoveredCollectionId, setHoveredCollectionId] = useState(null)
  const [changedCourses, setChangedCourses] = useState([])
  const [changedCollections, setChangedCollections] = useState([])

  const { courseCollectionDtos: collections = [], courseDtos = [] } = value
  const data = {
    courseDtos: [...courseDtos],
    courseCollectionDtos: [...collections],
  }

  const groupCoursesByCollection = (courseDtos, collections) => {
    const collectionsMap = new Map()

    // Initialize collections map with empty arrays for each collection
    collections.forEach(collection => {
      collectionsMap.set(collection.id, { ...collection, courses: [] })
    })

    // Iterate over courseDtos and group them under their respective collection
    courseDtos.forEach(course => {
      if (collectionsMap.has(course.courseCollectionId)) {
        collectionsMap.get(course.courseCollectionId).courses.push(course)
      }
    })

    collectionsMap.values().length > 0 &&
      collectionsMap.values().map(x =>
        x.courses.sort((a, b) => {
          return a.courseCollectionOrder - b.courseCollectionOrder
        }),
      )

    //for independent courses
    const independentCourses = courseDtos.filter(
      course => course.courseCollectionId == null,
    )
    if (independentCourses.length > 0) {
      collectionsMap.set(DEFAULT_COLLECTION_GUID, {
        ...defaultCollection,
        courses: independentCourses,
      })
    }

    // Convert the map back to an array
    return Array.from(collectionsMap.values())
  }

  const groupedData = groupCoursesByCollection(
    data.courseDtos,
    data.courseCollectionDtos,
  )
  const [containers, setContainers] = useState(groupedData)
  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  useEffect(() => {
    dispatch(teamListAction({}, response => {}))
    if (collectionIdFromUrl) {
      dispatch(setSelectedPageViewAction('Mini Courses'))

      dispatch(setSelectedCollectionAction(collectionIdFromUrl))
      if (collectionIdFromUrl != DEFAULT_COLLECTION_GUID) {
        dispatch(getCollectionSettingsAction(collectionIdFromUrl))
      }
    }
  }, [collectionIdFromUrl])

  useEffect(() => {
    setContainers(groupedData)

    if (collections.length > 0 && selectedCollection == null) {
      dispatch(setSelectedPageViewAction('Mini Courses'))
      dispatch(getCollectionSettingsAction(collections[0].id))
      dispatch(setSelectedCollectionAction(collections[0].id))

      window.history.replaceState(null, '', `/${collections[0].id}`)
    } else if (collections.length == 0 && courseDtos.length > 0) {
      window.history.replaceState(null, '', `/${DEFAULT_COLLECTION_GUID}`)
    }
  }, [value])

  const handleDragMove = useCallback(
    event => {
      const { active, over } = event

      // Handle Items Sorting
      if (activeId !== active.id) return

      if (
        active.id.toString().includes('container') &&
        over?.id.toString().includes('container') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const activeContainer = findValueOfItems(
          active.id.split('-container')[0],
          'container',
        )
        const overContainer = findValueOfItems(
          over.id.split('-container')[0],
          'container',
        )
        if (overContainer.id === DEFAULT_COLLECTION_GUID) return
        if (!activeContainer || !overContainer) return
        const activeContainerIndex = containers.findIndex(
          container => container.id === activeContainer.id,
        )
        const overContainerIndex = containers.findIndex(
          container => container.id === overContainer.id,
        )
        let newItems = JSON.parse(
          arrayMove(containers, activeContainerIndex, overContainerIndex),
        )
        let changedCollections = []
        for (const container of newItems) {
          changedCollections.push({
            ...container,
            collectionIndex: newItems.indexOf(container),
          })
        }
        setChangedCollections(changedCollections)
        setContainers(changedCollections)
        return
      }

      if (
        over.id.toString().includes('empty-item-') &&
        active &&
        over &&
        active.id !== over.id
      ) {
        const emptyActiveContainer = findValueOfItems(
          active.id.split('-item')[0],
          'item',
        )

        const emptyOverContainer = findValueOfItems(
          over.id.split('empty-item-')[1],
          'container',
        )

        if (!emptyActiveContainer || !emptyOverContainer) return
        const activeContainerIndex = containers.findIndex(
          container => container.id === emptyActiveContainer.id,
        )
        const emptyOverContainerIndex = containers.findIndex(
          container => container.id === emptyOverContainer.id,
        )
        const activeitemIndex = emptyActiveContainer.courses.findIndex(
          x => x.id == active.id.split('-item')[0],
        )

        let newItems = [...containers]
        const [removeditem] = newItems[activeContainerIndex].courses.splice(
          activeitemIndex,
          1,
        )
        newItems[emptyOverContainerIndex].courses.push(removeditem)

        let changedCoursesInit = []
        for (const collection of newItems) {
          for (const course of collection.courses) {
            changedCoursesInit.push({
              ...course,
              courseCollectionId: collection.id,
              courseCollectionOrder: collection.courses.indexOf(course),
              courseCollectionDto: collections.find(x => x.id == collection.id),
            })
          }
        }
        setChangedCourses(changedCoursesInit)
        newItems.map(x =>
          x.courses.sort((a, b) => {
            return a.courseCollectionOrder - b.courseCollectionOrder
          }),
        )
        setContainers(newItems)
        return
      }
    },
    [activeId],
  )
  const handleDragStart = useCallback(
    event => {
      const { active } = event
      const { id } = active
      activeId == null && setActiveId(id)
    },
    [activeId],
  )

  const handleDragEnd = () => {
    if (changedCourses.length !== 0) {
      dispatch(
        editCourseCollectionIndexAction({
          courseDtos: changedCourses,
        }),
      )
      setActiveId(null)
      setChangedCourses([])
    }
    if (changedCollections.length !== 0) {
      dispatch(
        courseCollectionUpdateAction({
          courseCollectionDtos: changedCollections,
        }),
      )
      setActiveId(null)
      setChangedCollections([])
    }
  }
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const courseTransportClicked = useSelector(
    state => state.courseCollectionList.courseTransportClicked,
  )

  return (
    <div
      className={clsx({
        [classes.page]: !isMobile,
        [classes.page_mobile]: isMobile,
      })}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          onDragMove={handleDragMove}
          onDragStart={handleDragStart}
          strategy={verticalListSortingStrategy}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={containers.map(i => `${i.id}-container`)}
            strategy={verticalListSortingStrategy}
          >
            <CollectionListDrawer
              containers={containers}
              open={open}
              setOpen={setOpen}
              draggedCourseId={draggedCourseId}
              setHoveredCollectionId={setHoveredCollectionId}
              hoveredCollectionId={hoveredCollectionId}
              setContainers={setContainers}
            />
          </SortableContext>
        </DndContext>
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div
            className={clsx({
              [classes.blurredPage]: courseTransportClicked, // Blur only this section
            })}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '964px',
              gap: 24,
              marginLeft: open ? 280 : 92,
              transition: 'margin-left 0.3s',
              paddingBottom: '32px',
            }}
          >
            <CollectionView />
            <PageFactory
              containers={containers}
              setContainers={setContainers}
              draggedCourseId={draggedCourseId}
              setDraggedCourseId={setDraggedCourseId}
              setHoveredCollectionId={setHoveredCollectionId}
              hoveredCollectionId={hoveredCollectionId}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(MainPage)
