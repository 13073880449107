import React, { useState, useEffect } from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Paper,
  Box,
  Divider,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import styles from './style'
import { userGetSelfAction } from 'src/reducers/user/action'
import { useDispatch } from 'react-redux'
import Lottie from 'react-lottie'
import animationData from 'src/lotties/AI-ready-v2-mobile_small.json'

import { track } from '@amplitude/analytics-browser'

const Options = [
  {
    cardName: 'Create with AI-Assistant',
    icon: '/assets/use-ai-assistant.png',
    text: 'Make AI create a complete mini-course based on your guidance.',
    height: '380px',
    opacity: 1,
  },
  {
    cardName: 'From scratch',
    icon: '/assets/from-scratch.png',
    text: 'Start to build mini-course immediately with your content.',
    badge: 'Optimized for desktop',
    height: '300px',
    opacity: 0.6,
  },
  {
    cardName: 'Select Template',
    icon: '/assets/select-template.png',
    text: 'See use-case ideas and content suggestions from common practices.',
    badge: 'Optimized for desktop',
    height: '300px',
    opacity: 0.6,
  },
]
const MobileUi = props => {
  const { classes } = props
  let history = useHistory()
  const [collection, setCollection] = useState(
    window.location.pathname.split('create-course/')[1] || '',
  )
  const dispatch = useDispatch()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    dispatch(userGetSelfAction())
  }, [])

  const handleClickCard = index => {
    if (index == 0) {
      history.push('/create-course-with-ai/' + collection)
      track('mobile_ai_selected', {})
      return
    }
    return true
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Typography variant="h3">Hi, {window.CONSTANTS.NAME}👋</Typography>
        <Paper
          className={classes.paper}
          sx={{
            p: 2,
            width: 1,
          }}
        >
          <Typography className={classes.desktopText} textAlign="center">
            Creating, editing, and managing mini-courses are optimized for
            desktop browsers. Please visit your account{' '}
            <span style={{ color: '#015FFB' }}>on desktop</span> for the full
            capability.
          </Typography>

          <Lottie options={defaultOptions} height={182} width={300} />
        </Paper>

        <Paper
          key={Options[0].index}
          className={classes.paper}
          sx={{
            p: 2,
            width: 1,
            bgcolor: '#fffff',
            opacity: Options[0].opacity,
          }}
          onClick={() => handleClickCard(0)}
        >
          <Typography className={classes.desktopText} textAlign="center">
            {Options[0].text}
          </Typography>

          <Button
            onClick={() => handleClickCard(0)}
            size="large"
            variant="contained"
            className={classes.createButton}
          >
            {Options[0].cardName}
          </Button>

          <Box m={1}></Box>
        </Paper>
        <Divider className={classes.dividerMargin} />
        <Box m={1}></Box>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          {Options.filter(x => x.badge != undefined).map((option, index) => (
            <Grid item xs={6} style={{ zoom: 0.7 }}>
              <Paper
                key={option.index}
                className={classes.paper}
                sx={{
                  p: 2,
                  width: 1,
                  bgcolor: '#fffff',
                  opacity: option.opacity,
                  height: option.height,
                  marginBottom: 2.5,
                }}
              >
                <div className={classes.card}>
                  <div className={classes.ribbon}>
                    <span className={classes.ribbonContent}>
                      {option.badge}
                    </span>
                  </div>
                </div>

                <img style={{ width: 350 }} src={`${option.icon}`}></img>
                <Box m={1}></Box>
                <Typography variant="h4">{option.cardName} </Typography>
                <Box m={1}></Box>
                <Typography textAlign="center">{option.text}</Typography>
                <Box m={2}></Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box m={2}></Box>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(MobileUi)
