import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import styles from './styles'

import { useDispatch } from 'react-redux'
import {
  ShareViewHeader,
  ShareView,
  EmbedViewHeader,
  EmbedView,
} from './components'

const ShareCollectionPopup = props => {
  const { classes, entity } = props
  const [view, setView] = React.useState('share')
  const dispatch = useDispatch()
  const handleView = view => {
    setView(view)
  }
  const components = [
    {
      component: <ShareViewHeader />,
      onView: view == 'share',
      mainPage: true,
    },
    {
      component: (
        <ShareView
          entity={entity}
          dispatch={dispatch}
          handleView={handleView}
        />
      ),
      onView: view == 'share',
      mainPage: true,
    },
    {
      component: <EmbedViewHeader entity={entity} handleView={handleView} />,
      onView: view == 'embed',
      mainPage: true,
    },
  ]

  return (
    <div className={classes.content}>
      <div className={classes.content__components}>
        {components.map(
          (component, index) =>
            component.onView && <div key={index}>{component.component}</div>,
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(ShareCollectionPopup)
