import CardTypeFactory from './cardItem/factory'
import { QuestionFieldNames } from 'src/model/question'
import { AnswerItemType } from 'src/model/question'
import {
  Page,
  Document,
  View,
  Image,
  Svg,
  Path,
  G,
  StyleSheet,
  Font,
  Text
} from '@react-pdf/renderer'
import { PdfStyleSheet, BackgroundColorRgb, ButtonColorRgb } from './pdf-style'
import URL from 'src/api/url'
import { Entity } from 'draft-js'

const MyDoc = props => {
  const { cards, themeSettings, entity, coverText } = props

  const backgroundImageUrl = themeSettings.bodyBackgroundImage
    ? themeSettings.bodyBackgroundImage.replace('svg', 'jpg')
    : ''

  const backgroundColorRgb = BackgroundColorRgb(themeSettings)
  const buttonColorRgb = ButtonColorRgb(themeSettings)

  const styles = PdfStyleSheet(themeSettings)

  let coverImageUrl = ''
  if (
    entity.coverImageUrl 
  ) {
    coverImageUrl =  entity.coverImageUrl.includes('https://') || entity.coverImageUrl.includes('data:image')
      ?  entity.coverImageUrl 
      : URL.CDN +  entity.coverImageUrl  + '?not-from-cache-please2'
  } else {
    coverImageUrl = ''
  }

  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.page,
        ]}
        wrap={true}
      >
        <Text style={styles.courseTitle}>{entity.name}</Text>

        {entity.coverImageUrl && (
          <Image style={styles.coverImage} src={coverImageUrl} cache={false} />
        )}

        <Text style={styles.coverText}>{coverText}</Text>

      

        {cards
          .filter(
            x =>
              x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.INFORM ||
              x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.AI ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.TESTINGSINGLECHOICE ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.TESTINGMULTIPLECHOICE ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYSINGLECHOICE ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYMULTIPLECHOICE ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYSTARS ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYNUMERICENTRY ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYINPUT ||
              x[QuestionFieldNames.QUESTION_TYPE] ==
                AnswerItemType.SURVEYFREEFORM,
          )
          .map(
            (card, index) =>
              card !== undefined && (
                <View style={styles.view}>

                  {CardTypeFactory({
                    type: card[QuestionFieldNames.QUESTION_TYPE],
                    card: card,
                    page: index,
                    stylesheet: styles,
                    style: styles.text,
                    buttonColorRgb: buttonColorRgb,
                    backgroundColorRgb: backgroundColorRgb,
                    editor: undefined,
                  })}
                </View>
              ),
          )}
      </Page>
    </Document>
  )
}
export default MyDoc
