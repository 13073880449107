import translator from 'src/translate'

const QuestionFieldNames = {
  ID: 'id',
  SHOP_ID: 'shopId',
  GAMEINFOID: 'gameInfoId',
  QTEXT: 'text',
  QUESTION_STATUS: 'questionStatus',
  QUESTION_LEVEL: 'questionLevel',
  HAS_PRE_INFO: 'hasPreInfo',
  PRE_INFO_TEXT: 'preInfoText',
  AFTER_INFO_TEXT: 'afterInfoText',
  HAS_AFTER_INFO: 'hasAfterInfo',
  ANSWER_TYPE: 'answerType',
  ANSWER_INPUT_VIEW_TYPE: 'answerInputViewType',
  MAX_VALUE: 'maxValue',
  IS_POLL: 'isPoll',
  ANONYMOUS: 'anonymous',
  IS_ACTIVE: 'isActive',
  SUBJECT_IDS: 'subjectIds',
  QUESTION_MEDIAS: 'mediaDtos',
  QUESTION_LINKS: 'linkDtos',
  SELECTIONS: 'selectionDtos',
  CAN_TAKE_PHOTO: 'canTakePhoto',
  QUESTION_TYPE: 'type',
  QUESTION_TAG_RELATION: 'subjectTree',
  IS_ANSWERED: 'isAnswered',
  PAYMENT: 'paymentInfoDtos',
  SETTING_DTOS: 'settingDtos',

  // result condition
  RESULT_CONDITIONS: 'resultConditions',
  IS_NEW_ADDED: 'isNewAdded',
  TITLE: 'title',
  SECTION: 'section',
  SORT_ORDER: 'orderOfCard',
  BADGE: 'badge',
}

const QuestionPaymentFieldNames = {
  CURRENCY: 'currency',
  MODE: 'mode',
  AMOUNT: 'amount',
  ACCOUNT_ID: 'paymentAccountId',
  ERRORTEXT: 'errorText',
  PAYMENT_CODE_REQUEST_TEXT: 'paymentCodeRequestText',
  PROCESSING_TEXT: 'processingText',
  REQUIRES_PAYMENT_METHOD_TEXT: 'requiresPaymentMethodText',
  SUCCEEDED_TEXT: 'succeededText',
  SUCCESSEMAIL: 'successEmail',
}

const QuestionLinkFieldNames = {
  ID: 'id',
  QUESTION_ID: 'cardId',
  TITLE: 'title',
  URL: 'Url',
  TYPE: 'type',
  ICON: 'icon',
  TARGET: 'target',
  THUMBNAIL: 'thumbnailUrl',
  ATTENTIONTEXT: 'attentionText',
}

const QuestionMediaFieldNames = {
  ID: 'id',
  QUESTION_ID: 'cardId',
  MEDIA_TYPE_ID: 'mediaType',
  MEDIA_URL: 'mediaUrl',
}

const SelectionFieldNames = {
  ID: 'id',
  QUESTION_ID: 'cardId',
  STEXT: 'text',
  ISCORRECT: 'isCorrect',
}

const QuestionSchema = () => {
  return {
    [QuestionFieldNames.ID]: null,
    [QuestionFieldNames.SHOP_ID]: '',
    [QuestionFieldNames.GAMEINFOID]: null,
    [QuestionFieldNames.QTEXT]: '',
    [QuestionFieldNames.QUESTION_STATUS]: 3,
    [QuestionFieldNames.QUESTION_LEVEL]: 1,
    [QuestionFieldNames.HAS_PRE_INFO]: false,
    [QuestionFieldNames.PRE_INFO_TEXT]: '',
    [QuestionFieldNames.AFTER_INFO_TEXT]: '',
    [QuestionFieldNames.HAS_AFTER_INFO]: false,
    [QuestionFieldNames.ANSWER_TYPE]: 0,
    [QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE]: 0,
    [QuestionFieldNames.MAX_VALUE]: null,
    [QuestionFieldNames.IS_POLL]: false,
    [QuestionFieldNames.ANONYMOUS]: false,
    [QuestionFieldNames.IS_ACTIVE]: true,
    [QuestionFieldNames.SUBJECT_IDS]: [],
    [QuestionFieldNames.QUESTION_MEDIAS]: [],
    [QuestionFieldNames.QUESTION_LINKS]: [],
    [QuestionFieldNames.SELECTIONS]: [],
    [QuestionFieldNames.QUESTION_TAG_RELATION]: [],
    [QuestionFieldNames.CAN_TAKE_PHOTO]: false,
    [QuestionFieldNames.QUESTION_TYPE]: null,
    [QuestionFieldNames.IS_ANSWERED]: false,
    [QuestionFieldNames.IS_NEW_ADDED]: true,
    [QuestionFieldNames.TITLE]: '',
    [QuestionFieldNames.SECTION]: null,
  }
}

const QuestionLinkSchema = () => {
  return {
    [QuestionLinkFieldNames.ID]: null,
    [QuestionLinkFieldNames.QUESTION_ID]: null,
    [QuestionLinkFieldNames.TITLE]: '[]',
    [QuestionLinkFieldNames.URL]: '[]',
    [QuestionLinkFieldNames.TYPE]: 0,
    [QuestionLinkFieldNames.ICON]: '',
    [QuestionLinkFieldNames.TARGET]: '',
  }
}

const QuestionMediaSchema = () => {
  return {
    [QuestionMediaFieldNames.ID]: null,
    [QuestionMediaFieldNames.QUESTION_ID]: null,
    [QuestionMediaFieldNames.MEDIA_TYPE_ID]: null,
    [QuestionMediaFieldNames.MEDIA_URL]: null,
  }
}

const SelectionSchema = () => {
  return {
    [SelectionFieldNames.ID]: null,
    [SelectionFieldNames.QUESTION_ID]: null,
    [SelectionFieldNames.STEXT]: '',
    [SelectionFieldNames.ISCORRECT]: '',
  }
}
class AnswerItemType {
  static INFORM = 1
  static TESTINGSINGLECHOICE = 2
  static TESTINGMULTIPLECHOICE = 3
  static SURVEYSINGLECHOICE = 4
  static SURVEYMULTIPLECHOICE = 5
  static SURVEYFREEFORM = 6
  static SURVEYINPUT = 7
  static SURVEYSTARS = 8
  static SURVEYNUMERICENTRY = 9
  static SIGNUP = 10
  static STRIPEPAYMENT = 11
  static RESULT = 12 // question results
  static SERTIFIERCARD = 13 // sertifier
  static SCORECARD = 14 // info
  static PROTECTIONCARD = 15 // password protection card
  static RESULTDEFAULT = 16
  static AI = 17 // ai card
  static BADGE = 18 // badge card
}

const isAnswerItemTypeResultCard = id => {
  return (
    id === AnswerItemType.RESULT ||
    id === AnswerItemType.SERTIFIERCARD ||
    id === AnswerItemType.SCORECARD ||
    id === AnswerItemType.RESULTDEFAULT ||
    id === AnswerItemType.BADGE
  )
}

const QuestionPlaceholders = {
  [AnswerItemType.INFORM]: translator._(
    'taskItem.addQuestion.main.infoCardPlaceholder',
  ),
  [AnswerItemType.STRIPEPAYMENT]: translator._(
    'taskItem.addQuestion.main.infoPlaceholder',
  ),
  [AnswerItemType.SIGNUP]: translator._(
    'taskItem.addQuestion.main.textFieldEgHolder',
  ),
  [AnswerItemType.PROTECTIONCARD]: translator._(
    'taskItem.addQuestion.main.infoPlaceholder',
  ),

  //[AnswerItemType.PROTECTIONCARD]:
  //  'Users will write the password you set\nbelow, you can give them header here',
}

const TranslateCardTypes = {
  [AnswerItemType.STRIPEPAYMENT]: true,
  [AnswerItemType.SERTIFIERCARD]: true,
}

/**
 *
 * @param {*} type
 * @param {*} entity
 */
const getQuestionTypeParam = (type, entity) => {
  switch (Number(type)) {
    case AnswerItemType.INFORM:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 5
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.TESTINGSINGLECHOICE:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 1
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.TESTINGMULTIPLECHOICE:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 2
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SURVEYSINGLECHOICE:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 1
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SURVEYMULTIPLECHOICE:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 2
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SURVEYFREEFORM:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 3
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SURVEYINPUT:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 3
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = true
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SURVEYSTARS:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 4
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 3
      break
    case AnswerItemType.SURVEYNUMERICENTRY:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 4
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 1
      break
    case AnswerItemType.SURVEYDOCUMENT:
      entity[QuestionFieldNames.IS_POLL] = true
      // TODO: Caner Add support for document uploads
      break
    case AnswerItemType.SIGNUP:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 6
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.STRIPEPAYMENT:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 7
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.RESULT:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 8
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SERTIFIERCARD:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 9
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.SCORECARD:
      entity[QuestionFieldNames.QTEXT] = defaultQTexts[AnswerItemType.SCORECARD]
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 10
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.PROTECTIONCARD:
      entity[QuestionFieldNames.IS_POLL] = false
      break
    case AnswerItemType.AI:
      entity[QuestionFieldNames.IS_POLL] = true
      entity[QuestionFieldNames.ANSWER_TYPE] = 5
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    case AnswerItemType.BADGE:
      entity[QuestionFieldNames.IS_POLL] = false
      entity[QuestionFieldNames.ANSWER_TYPE] = 8
      entity[QuestionFieldNames.CAN_TAKE_PHOTO] = false
      entity[QuestionFieldNames.ANSWER_INPUT_VIEW_TYPE] = 0
      break
    default:
      break
  }

  entity[QuestionFieldNames.QUESTION_TYPE] = parseInt(type)
  return entity
}

const CreateMethods = {
  SINGLE: 0,
  BULK: 1,
}

const defaultQTexts = {
  [AnswerItemType.SCORECARD]: `<h1 style="text-align: center;"><strong>Your result is:</strong></h1><h2 style="text-align: center;"><strong>{{Correct_Answers}}/{{Quiz_Questions}}​</strong><br></h2>`,
}

const QuestionEditorHeights = {
  [AnswerItemType.INFORM]: {
    mobile: 200,
    desktop: 200,
  },
  [AnswerItemType.SCORECARD]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.SERTIFIERCARD]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.PROTECTIONCARD]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.RESULTDEFAULT]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.SCORECARD]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.SIGNUP]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.STRIPEPAYMENT]: {
    mobile: 150,
    desktop: 200,
  },
  [AnswerItemType.AI]: {
    mobile: 250,
    desktop: 200,
  },
}

const CardSettings = {
  required: {
    key: 'isRequired',
    label: "The user can't pass without answering this question.",
    type: 'boolean',
    default: true,
  },
  showAnswerAfterSelect: {
    key: 'showAnswerAfterSelect',
    label:
      'When the user answers the question, the correct answer is displayed on the screen.',
    type: 'boolean',
    default: true,
  },
  minValue: {
    key: 'minValue',
    label: 'Minimum Value',
    type: 'number',
    default: 0,
  },
  maxValue: {
    key: 'maxValue',
    label: 'Maximum Value',
    type: 'number',
    default: 100,
  },
  maxCharacter: {
    key: 'maxCharacter',
    label: 'Maximum Character',
    type: 'number',
    default: 200,
  },
  placeholder: {
    key: 'placeholder',
    label: 'Placeholder',
    type: 'string',
    default: '',
  },
  label: {
    key: 'label',
    label: 'Input Label',
    type: 'string',
    default: '',
  },
  helperText: {
    key: 'helperText',
    label: 'Helper Text',
    type: 'string',
    default: '',
  },
  repeat: {
    key: 'repeat',
    label: 'Show Repeat Button',
    type: 'boolean',
    default: false,
  },
  emailVeritifaction: {
    key: 'emailVeritifaction',
    label: 'Email Verification',
    type: 'boolean',
    default: false,
  },
  password: {
    key: 'password',
    label: 'Password',
    type: 'password',
    default: '',
  },
}

const CardSettingsTypes = {
  [AnswerItemType.INFORM]: {},
  [AnswerItemType.TESTINGSINGLECHOICE]: {
    settings: [CardSettings.required, CardSettings.showAnswerAfterSelect],
  },
  [AnswerItemType.TESTINGMULTIPLECHOICE]: {
    settings: [CardSettings.required, CardSettings.showAnswerAfterSelect],
  },
  [AnswerItemType.SURVEYSINGLECHOICE]: {
    settings: [CardSettings.required],
  },
  [AnswerItemType.SURVEYMULTIPLECHOICE]: {
    settings: [CardSettings.required],
  },
  [AnswerItemType.SURVEYFREEFORM]: {
    settings: [
      CardSettings.required,
      CardSettings.label,
      CardSettings.placeholder,
      CardSettings.maxCharacter,
    ],
  },
  [AnswerItemType.SURVEYINPUT]: {
    settings: [
      CardSettings.required,
      CardSettings.label,
      CardSettings.placeholder,
    ],
  },
  [AnswerItemType.SURVEYSTARS]: {
    settings: [CardSettings.required],
  },
  [AnswerItemType.SURVEYNUMERICENTRY]: {
    settings: [CardSettings.required],
  },
  [AnswerItemType.SIGNUP]: {
    settings: [CardSettings.placeholder],
  },
  [AnswerItemType.STRIPEPAYMENT]: {
    settings: [
      {
        key: 'codeRequestText',
        label: 'Code Request',
        type: 'string',
        default:
          'Enter the code sent to your email after you have made the payment.',
      },
      {
        key: 'processingText',
        label: 'Processing',
        type: 'string',
        default:
          'Your payment is processing. After the process, you can proceed with the code that will be sent to your e-mail address.',
      },
      {
        key: 'notSuccesfulText',
        label: 'Not Successful',
        type: 'string',
        default: 'Your payment was not successful, please try again.',
      },
      {
        key: 'succeededText',
        label: 'Succeeded',
        type: 'string',
        default: 'Payment succeeded!',
      },
      {
        key: 'errorText',
        label: 'Error',
        type: 'string',
        default: 'Something went wrong.',
      },
    ],
  },
  [AnswerItemType.RESULT]: {
    settings: [CardSettings.repeat],
  },
  [AnswerItemType.SERTIFIERCARD]: {
    settings: [
      {
        key: 'name',
        label: 'Name placeholder',
        type: 'string',
        default: 'Name Surname',
      },
      {
        key: 'email',
        label: 'Email placeholder',
        type: 'string',
        default: 'Email',
      },
      {
        key: 'success',
        label: 'Success message',
        type: 'string',
        default:
          'Your certificate is being prepared. It will be sent to your e-mail address shortly.',
      },
    ],
  },
  [AnswerItemType.SCORECARD]: {
    settings: [CardSettings.repeat],
  },
  [AnswerItemType.PROTECTIONCARD]: {
    settings: [CardSettings.password, CardSettings.label],
  },
  [AnswerItemType.AI]: {},
}

const CardSettingBuilder = type => {
  let settings = {}

  for (let i = 0; i < CardSettingsTypes[type].settings.length; i++) {
    settings[CardSettingsTypes[type].settings[i].key] =
      CardSettingsTypes[type].settings[i].default
  }

  return settings
}

export {
  QuestionSchema,
  QuestionPaymentFieldNames,
  QuestionLinkSchema,
  QuestionMediaSchema,
  SelectionSchema,
  QuestionFieldNames,
  QuestionLinkFieldNames,
  QuestionMediaFieldNames,
  SelectionFieldNames,
  AnswerItemType,
  getQuestionTypeParam,
  CreateMethods,
  defaultQTexts,
  QuestionEditorHeights,
  CardSettings,
  CardSettingsTypes,
  CardSettingBuilder,
  TranslateCardTypes,
  QuestionPlaceholders,
  isAnswerItemTypeResultCard,
}
