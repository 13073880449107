import React from 'react'

import { ReactComponent as GoogleIcon } from './google_g_logo.svg'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
// material
import { Grid, Button, Divider, Typography, SvgIcon } from '@material-ui/core'

// ----------------------------------------------------------------------

export default function AuthFirebaseSocials() {
  return (
    <>
      <Grid direction="column" spacing={2} textAlign="center">
        <Button
          size="large"
          color="inherit"
          variant="outlined"
          href={`${process.env.REACT_APP_URL}externalauth/externalLogin?provider=Google`}
          startIcon={
            <SvgIcon>
              <GoogleIcon></GoogleIcon>
            </SvgIcon>
          }
          style={{ textTransform: 'inherit' }}
        >
          Sign in with Google
        </Button>

        <Button
          size="large"
          color="inherit"
          variant="outlined"
          href={`${process.env.REACT_APP_URL}externalauth/externalLogin?provider=LinkedIn`}
          style={{ marginLeft: 8 }}
        >
          <LinkedInIcon style={{ color: '#1C9CEA' }} />
        </Button>
      </Grid>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  )
}
