import React, { useState } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { useDispatch } from 'react-redux'
import { patchSetting } from 'src/hooks/useCardSettings'
const Accordion = withStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: '#F4F6F8',

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      boxShadow: 'none',
      backgroundColor: '#F4F6F8',
    },
  },
  expanded: {
    backgroundColor: '#F4F6F8',
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#F4F6F8',

    minHeight: 56,
    borderRadius: '8px',
    gap: 8,
    '&$expanded': {
      minHeight: 56,
      gap: 8,
      backgroundColor: '#F4F6F8',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      backgroundColor: '#F4F6F8',
    },
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: '8px',
  },
}))(MuiAccordionDetails)

const AccordionPart = props => {
  const { classes, settings, badgeSettings, badgeUsageType } = props
  const dispatch = useDispatch()

  const [completionMessage, setCompletionMessage] = useState(
    badgeSettings.completionMessage,
  )
  const [successMessage, setSuccessMessage] = useState(
    badgeSettings.additionalMessageOnSuccess,
  )

  const [failMessage, setFailMessage] = useState(
    badgeSettings.additionalMessageOnFail,
  )
  const handleSuccessMessage = e => {
    setSuccessMessage(e)
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({
        ...badgeSettings,
        additionalMessageOnSuccess: e,
      }),
    )
  }

  const handleCompletionMessage = e => {
    setCompletionMessage(e)
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({
        ...badgeSettings,
        completionMessage: e,
      }),
    )
  }

  const handleFailMessage = e => {
    patchSetting(
      dispatch,
      settings.cardId,
      settings.id,
      'badge',
      JSON.stringify({
        ...badgeSettings,
        additionalMessageOnFail: e,
      }),
    )
  }

  const [expandedBool, setExpandedBool] = React.useState('')

  const handleChange = () => {
    expandedBool == 'panel1' ? setExpandedBool('') : setExpandedBool('panel1')
  }

  return (
    <Accordion expanded={expandedBool == 'panel1'}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => handleChange()}
      >
        <Typography className={classes.heading}>
          {badgeUsageType == 0 ? 'Additional Message' : 'Customize Message'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.description}>
          {badgeUsageType == 0
            ? 'Message displayed on completion'
            : 'Message displayed on success'}
        </Typography>
        <div style={{ display: badgeUsageType == 0 ? 'flex' : 'none' }}>
          <SunEditor
            key={'completionMessage-editor'}
            value={completionMessage}
            defaultValue={completionMessage}
            onBlur={(event, editorContents) =>
              handleCompletionMessage(editorContents)
            }
            className={classes.textArea}
            setDefaultStyle="text-align: center;"
            onImageUploadBefore={(files, info, uploadHandler) => {
              dispatch(
                mediaImageUploadAction(files[0], '', result => {
                  const response = {
                    result: [
                      {
                        url: `${URL.MEDIA}${result.url}`,
                        name: result.name,
                        size: 100,
                      },
                    ],
                  }

                  uploadHandler(response)
                }),
              )
            }}
            setOptions={{
              mode: 'inline',
              formats: ['h1', 'h2', 'p'],
              placeholder:
                'This content will appear under the condition you define.',
              height: 212,
              buttonList: [
                [],
                ['formatBlock'],
                [],
                ['bold'],
                [],
                ['image'],
                [],
                ['codeView'],
                [],
                [
                  ':i-More Misc-default.more_vertical',
                  'fontColor',
                  'hiliteColor',
                  'align',
                  'link',
                  'italic',
                  'underline',
                  'removeFormat',
                ],
              ],
              pasteTagsWhitelist: 'p|h1|h2|h3',
              imageMultipleFile: false,
              imageUrlInput: false,
              imageAlignShow: false,
              imageHeightShow: false,
              imageResizing: false,
              popupDisplay: 'local',
              attributesWhitelist: {
                all:
                  'style|dir|class|width|height|title|allow|allowfullscreen|aspectratio|id|frameborder|iframe|className|data-.+',
              },
            }}
          />
        </div>
        <div
          style={{
            display: badgeUsageType == 1 ? 'flex' : 'none',
          }}
        >
          <SunEditor
            key={'successMessage-editor'}
            value={successMessage}
            defaultValue={successMessage}
            onBlur={(event, editorContents) =>
              handleSuccessMessage(editorContents)
            }
            onChange={(event, editorContents) =>
              setSuccessMessage(editorContents)
            }
            className={classes.textArea}
            setDefaultStyle="text-align: center;"
            onImageUploadBefore={(files, info, uploadHandler) => {
              dispatch(
                mediaImageUploadAction(files[0], '', result => {
                  const response = {
                    result: [
                      {
                        url: `${URL.MEDIA}${result.url}`,
                        name: result.name,
                        size: 100,
                      },
                    ],
                  }

                  uploadHandler(response)
                }),
              )
            }}
            setOptions={{
              mode: 'inline',
              formats: ['h1', 'h2', 'p'],
              placeholder:
                'This content will appear under the condition you define.',
              height: 212,
              buttonList: [
                [],
                ['formatBlock'],
                [],
                ['bold'],
                [],
                ['image'],
                [],
                ['codeView'],
                [],
                [
                  ':i-More Misc-default.more_vertical',
                  'fontColor',
                  'hiliteColor',
                  'align',
                  'link',
                  'italic',
                  'underline',
                  'removeFormat',
                ],
              ],
              pasteTagsWhitelist: 'p|h1|h2|h3',
              imageMultipleFile: false,
              imageUrlInput: false,
              imageAlignShow: false,
              imageHeightShow: false,
              imageResizing: false,
              popupDisplay: 'local',
              attributesWhitelist: {
                all:
                  'style|dir|class|width|height|title|allow|allowfullscreen|aspectratio|id|frameborder|iframe|className|data-.+',
              },
            }}
          />
        </div>

        <div
          style={{
            display: badgeUsageType == 0 ? 'none' : 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <Typography className={classes.description}>
            Message displayed on lack of success
          </Typography>
          <SunEditor
            key={'editor'}
            defaultValue={failMessage}
            value={failMessage}
            onBlur={(event, editorContents) =>
              handleFailMessage(editorContents)
            }
            onChange={(event, editorContents) => setFailMessage(editorContents)}
            className={classes.textArea}
            setDefaultStyle="text-align: center;"
            onImageUploadBefore={(files, info, uploadHandler) => {
              dispatch(
                mediaImageUploadAction(files[0], '', result => {
                  const response = {
                    result: [
                      {
                        url: `${URL.MEDIA}${result.url}`,
                        name: result.name,
                        size: 100,
                      },
                    ],
                  }

                  uploadHandler(response)
                }),
              )
            }}
            setOptions={{
              mode: 'inline',
              formats: ['h1', 'h2', 'p'],
              placeholder:
                'This content will appear under the condition you define.',
              height: 414,
              buttonList: [
                [],
                ['formatBlock'],
                [],
                ['bold'],
                [],
                ['image'],
                [],
                ['codeView'],
                [],
                [
                  ':i-More Misc-default.more_vertical',
                  'fontColor',
                  'hiliteColor',
                  'align',
                  'link',
                  'italic',
                  'underline',
                  'removeFormat',
                ],
              ],
              attributesWhitelist: {
                all:
                  'style|dir|class|width|height|title|allow|allowfullscreen|aspectratio|id|frameborder|iframe|className|data-.+',
              },
              pasteTagsWhitelist: 'p|h1|h2|h3',
              imageMultipleFile: false,
              imageUrlInput: false,
              imageAlignShow: false,
              imageHeightShow: false,
              imageResizing: false,
              popupDisplay: 'local',
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(styles)(AccordionPart)
