const badgeUsageTypeEnum = {
  COMPLETION: 0,
  SUCCESS: 1,
}
const data = [
  {
    id: badgeUsageTypeEnum.COMPLETION,
    title: 'Completion Certificate',
    description:
      'A digital certificate is delivered upon completion of the collection.',
  },
  {
    id: badgeUsageTypeEnum.SUCCESS,
    title: 'Success Certificate',
    description:
      'The digital certificate is delivered when the success score you set is reached.',
  },
]
export default data
