import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import styles from './styles'
import data from './data'
import { patchCertificateAction } from 'src/reducers/certificate/action'
import { PatchContent, PatchData } from 'src/model/patch'

import { useDispatch } from 'react-redux'
const StyledRadio = withStyles({
  root: {
    '&$checked': {
      color: '#015FFB',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const Selections = props => {
  const { classes, certificateType, setCertificateType, certificate } = props
  const dispatch = useDispatch()
  const handleClickOnBadge = badgeId => {
    const content = new PatchData(
      new PatchContent(badgeId, 'type', PatchData.OPERATION_REPLACE),
      certificate.id,
    )

    dispatch(patchCertificateAction(content))

    setCertificateType(badgeId)
  }


  return (

      <div className={classes.root}>
        {data.map(item => (
          <div
            className={classes.root_content}
            key={item.id}
            onClick={() => handleClickOnBadge(item.id)}
            style={{
              border:
                certificateType == item.id
                  ? '1px solid #015FFB'
                  : '1px solid #E0E0E0',
            }}
          >
            <div>
              <StyledRadio
                checked={certificateType == item.id}
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
              />
            </div>

            <div className={classes.root_content_text}>
              <Typography className={classes.root_content_text_title}>
                {item.title}
              </Typography>
              <Typography className={classes.root_content_text_description}>
                {item.description}
              </Typography>
            </div>
          </div>
        ))}
      </div>
  )
}

export default withStyles(styles)(Selections)
