import React from 'react'
import styles from './styles'
import { Card, Typography, withStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ScormDownloadField from './scorm-download-field'
const ScormDownloadModal = props => {
  const { dispatch, entity, onClose, classes } = props
  const data = [
    { title: 'SCORM 1.2', type: '1_2' },
    { title: 'SCORM 2004 3TH', type: '2004' },
  ]
  return (
    <Card className={classes.settingsContainer}>
      <div className={classes.headerLine}>
        <Typography fontSize={'15px'} fontWeight={600}>
          Export to SCORM
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon
            style={{
              height: '20px',
            }}
          />
        </IconButton>
      </div>
      <div className={classes.scormDownloadFieldContainer}>
        {data.map((item, index) => (
          <ScormDownloadField
            key={index}
            title={item.title}
            dispatch={dispatch}
            entity={entity}
            type={item.type}
          />
        ))}
      </div>
    </Card>
  )
}

export default withStyles(styles)(ScormDownloadModal)
