import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import styles from '../styles'

import { Button, CircularProgress, TextField } from '@material-ui/core'

import { useCourseSettings } from 'src/hooks/useCourseSettings'
import { initialLanguageSettings } from 'src/model/course-settings'
import CountrySelect from 'src/components/naming-modal/country-select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { getLocalizationStringOfTypeService } from 'src/api/common'
import {
  useCourse,
  createSetting,
  patchSetting,
} from 'src/hooks/useCourseSettings'
import { useDispatch } from 'react-redux'
import { settingsDrawerOpenSuccess } from 'src/reducers/assignment/action'
const SETTING_KEY = 'language'

const LanguageSettings = props => {
  const { classes } = props
  const course = useCourse()
  const dispatch = useDispatch()
  const emailSetting = course.settingDtos.find(x => x.key === 'email')

  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const languageOptions = window.CONSTANTS.LANGUAGES
  const [activeIndex, setActiveIndex] = useState()
  const [loading, setLoading] = useState(false)
  const [emailSettings, setEmailSettings] = useState()
  // delete from value the items with key invite_member and invite_member_inner
  const [selectedEmailSettings, setSelectedEmailSettings] = useState(
    emailSetting
      ? JSON.parse(emailSetting.value).filter(
          x => x.key != 'invite_member' && x.key != 'invite_member_inner',
        )
      : [],
  )

  const [error, setError] = useState([])
  const [generalSettings, setGeneralSettings] = useState({})
  const [loginSettings, setLoginSettings] = useState({})
  const [endScreenSettings, setEndScreenSettings] = useState({})
  const [languageSelection, setLanguageSelection] = React.useState(
    languageOptions.find(
      exists
        ? x => x.countryCode === value.selected
        : x => x.countryCode === 'en',
    ),
  )

  const result = {}

  window.CONSTANTS.LOCALIZATION_STRINGS[languageSelection.countryCode].forEach(
    item => {
      result[item.key] = item.text
    },
  )

  const [localizationStringValues, setLocalizationStringValues] = useState(
    exists ? value.strings : result,
  )

  useEffect(() => {
    function groupStrings(strings) {
      const groupedStrings = {
        general: {},
        login: {},
        endScreen: {},
      }

      for (const [key, value] of Object.entries(strings)) {
        if (key.startsWith('login_')) {
          groupedStrings.login[key] = value
        } else if (key.startsWith('end_screen')) {
          groupedStrings.endScreen[key] = value
        } else {
          groupedStrings.general[key] = value
        }
      }

      return groupedStrings
    }

    const groupedLocalizationStrings = groupStrings(localizationStringValues)
    setGeneralSettings(groupedLocalizationStrings.general)

    if (groupedLocalizationStrings.login.login_email == undefined) {
      const localizationDefaultStrings = groupStrings(result)

      let loginStrings = localizationDefaultStrings.login
      setLoginSettings(loginStrings)
    } else setLoginSettings(groupedLocalizationStrings.login)

    if (
      groupedLocalizationStrings.endScreen.end_screen_collection == undefined
    ) {
      const localizationDefaultStrings = groupStrings(result)
      let endScreenStrings = localizationDefaultStrings.endScreen
      setEndScreenSettings(endScreenStrings)
    } else setEndScreenSettings(groupedLocalizationStrings.endScreen)
  }, [languageSelection])

  const handleChangeLanguage = e => {
    if (e === null) return
    setLanguageSelection(e)
    const result = {}
    window.CONSTANTS.LOCALIZATION_STRINGS[e.countryCode].forEach(item => {
      result[item.key] = item.text
    })
    setLocalizationStringValues(result)

    emailSettings &&
      setSelectedEmailSettings(
        emailSettings[e.countryCode].filter(x => x.display == true) || [],
      )
  }

  const handleChangeValue = (e, type) => {
    const { name, value } = e.target
    let newSettings
    if (type === 'general') {
      newSettings = { ...generalSettings, [name]: value }
      setGeneralSettings(newSettings)
    } else if (type === 'login') {
      newSettings = { ...loginSettings, [name]: value }
      setLoginSettings(newSettings)
    } else {
      newSettings = { ...endScreenSettings, [name]: value }
      setEndScreenSettings(newSettings)
    }

    setLocalizationStringValues({
      ...localizationStringValues,
      [name]: value,
    })
  }

  const handleChangeValueOnBlur = e => {
    if (e.target.value.trim().length === 0) {
      setError([...error, { error: true, name: e.target.name }])
      return
    }
    error.find(x => x.name === e.target.name) &&
      setError(error.filter(x => x.name !== e.target.name))
  }

  const handleSaveCourse = () => {
    const variables = {
      selected: languageSelection.countryCode,
      strings: localizationStringValues,
    }
    setLoading(true)

    if (!exists) {
      // create a setting entity with execute function
      execute(variables, () => {
        setLoading(false)
        handleDrawerClose()
      })
    } else {
      update(variables, () => {
        setLoading(false)
        handleDrawerClose()
      })
    }

    if (selectedEmailSettings.length !== 0) {
      if (!emailSetting) {
        createSetting(
          dispatch,
          course.id,
          'email',
          JSON.stringify(selectedEmailSettings),
          () => {},
        )
      } else {
        patchSetting(
          dispatch,
          course.id,
          emailSetting.id,
          'email',
          JSON.stringify(selectedEmailSettings),
          () => {},
        )
      }
    }
  }
  const handleEmailSettingChange = (e, index) => {
    const { name, value } = e.target
    const updatedEmailSettings = [...selectedEmailSettings]
    updatedEmailSettings[index] = {
      ...updatedEmailSettings[index],
      text: value,
    }

    setSelectedEmailSettings(updatedEmailSettings)
  }

  const handleEmailSettingBlur = e => {
    if (e.target.value.trim().length === 0) {
      setError([...error, { error: true, name: e.target.name }])
      return
    }
    error.find(x => x.name === e.target.name) &&
      setError(error.filter(x => x.name !== e.target.name))
  }
  const mailService = () => {
    setLoading(true)
    getLocalizationStringOfTypeService('course-mail').then(res => {
      var groupBy = function (xs, keyFunc) {
        return xs.reduce(function (rv, x) {
          var key = keyFunc(x)
          ;(rv[key] = rv[key] || []).push(x)
          return rv
        }, {})
      }

      // Usage
      var localizationStringsEmailsGroupedByKeys = groupBy(
        res.data.localizationStrings,
        function (item) {
          return item.countryCode
        },
      )

      setSelectedEmailSettings(
        emailSetting
          ? JSON.parse(emailSetting.value).filter(
              x => x.key != 'invite_member' && x.key != 'invite_member_inner',
            )
          : localizationStringsEmailsGroupedByKeys[
              languageSelection.countryCode
            ].filter(
              x => x.key != 'invite_member' && x.key != 'invite_member_inner',
            ),
      )
      setEmailSettings(localizationStringsEmailsGroupedByKeys)
      setLoading(false)
    })
  }

  const toggleAccordion = index => {
    index == 2 && emailSettings == undefined && mailService()
    setActiveIndex(activeIndex === index ? null : index)
  }

  const handleDrawerClose = () => {
    dispatch(settingsDrawerOpenSuccess())
  }
  return (
    <div className={classes.content}>
      <div className={classes.root__bg}>
        <CountrySelect
          languageSelection={languageSelection}
          setLanguageSelection={handleChangeLanguage}
          languageOptions={languageOptions}
        />

        <div className={classes.accordionSection}>
          <div
            className={classes.accordionTitle}
            onClick={() => toggleAccordion(0)}
          >
            Mini-Course buttons
            {activeIndex === 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>

          <div
            className={`${classes.accordionContent} ${
              activeIndex === 0 ? 'show' : ''
            }`}
          >
            {Object.keys(generalSettings).map((key, index) => (
              <TextField
                key={index}
                name={key}
                value={generalSettings[key]}
                label={initialLanguageSettings.labels[key]}
                onChange={e => handleChangeValue(e, 'general')}
                onBlur={handleChangeValueOnBlur}
                error={error.find(x => x.name === key)?.error}
                helperText={
                  error.find(x => x.name === key)?.error &&
                  'This field is required'
                }
              />
            ))}
          </div>
        </div>

        <div className={classes.accordionSection}>
          <div
            className={classes.accordionTitle}
            onClick={() => toggleAccordion(1)}
          >
            Login screens
            {activeIndex === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          <div
            className={`${classes.accordionContent} ${
              activeIndex === 1 ? 'show' : ''
            }`}
          >
            {Object.keys(loginSettings).map((key, index) => (
              <TextField
                key={index}
                name={key}
                value={loginSettings[key]}
                label={initialLanguageSettings.labels[key]}
                multiline
                onChange={e => handleChangeValue(e, 'login')}
                onBlur={handleChangeValueOnBlur}
                error={error.find(x => x.name === key)?.error}
                helperText={
                  error.find(x => x.name === key)?.error &&
                  'This field is required'
                }
              />
            ))}
          </div>
        </div>
        <div className={classes.accordionSection}>
          <div
            className={classes.accordionTitle}
            onClick={() => toggleAccordion(2)}
          >
            Confirmation emails
            {activeIndex === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          <div
            className={`${classes.accordionContent} ${
              activeIndex === 2 ? 'show' : ''
            }`}
          >
            {selectedEmailSettings &&
              selectedEmailSettings.map((item, index) => {
                return (
                  <TextField
                    style={{
                      display: item.display ? 'inlineFlex' : 'none',
                    }}
                    key={index}
                    name={item.key}
                    value={item.text.replaceAll(/(\\n)/g, '\n')}
                    label={initialLanguageSettings.labels[item.key]}
                    multiline
                    maxRows={10}
                    onChange={e => handleEmailSettingChange(e, index)}
                    onBlur={handleEmailSettingBlur}
                    error={error.find(x => x.name === item.key)?.error}
                    helperText={
                      error.find(x => x.name === item.key)?.error &&
                      'This field is required'
                    }
                  />
                )
              })}
          </div>
        </div>
        <div className={classes.accordionSection}>
          <div
            className={classes.accordionTitle}
            onClick={() => toggleAccordion(3)}
          >
            Course Last Screen
            {activeIndex === 3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          <div
            className={`${classes.accordionContent} ${
              activeIndex === 3 ? 'show' : ''
            }`}
          >
            {Object.keys(endScreenSettings).map((key, index) => (
              <TextField
                key={index}
                name={key}
                value={endScreenSettings[key]}
                label={initialLanguageSettings.labels[key]}
                multiline
                onChange={e => handleChangeValue(e, 'endScreen')}
                onBlur={handleChangeValueOnBlur}
                error={error.find(x => x.name === key)?.error}
                helperText={
                  error.find(x => x.name === key)?.error &&
                  'This field is required'
                }
              />
            ))}
          </div>
        </div>
      </div>
      {loading ? (
        <div className={classes.bottomButton}>
          <CircularProgress></CircularProgress>
        </div>
      ) : (
        <div className={classes.bottomButton}>
          <Button
            disabled={error.length > 0}
            fullWidth={true}
            color="secondary"
            size="small"
            variant="contained"
            onClick={handleSaveCourse}
          >
            {translator._('assignment.courseSettings.save')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(LanguageSettings)
