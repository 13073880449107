import translator from 'src/translate'
import React, { useState } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import ThemeSettings from './tabs/theme-settings'
import { Icon } from '@iconify/react'

const ThemeSettingsDrawer = props => {
  const { setThemeDrawerOpen, themeDrawerOpen, dispatch, entity, classes } = props
  const [currentTab, setCurrentTab] = useState('0')
  const isUserPremium = Number(window.CONSTANTS.USER_ROLE_ID) !== 1
  const handleTabChange = (event, newValue) => setCurrentTab(newValue)

  React.useEffect(() => {
    window.toggleFreshworksAction(themeDrawerOpen ? 'hide' : 'show')
  }, [themeDrawerOpen])
  const SettingsTabs = [
    {
      label: translator._('assignment.courseSettings.theme'),
      component: entity => <ThemeSettings entity={entity} />,
      enabled: true,
    },
  ]
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={themeDrawerOpen}
        onClose={() => setThemeDrawerOpen(false)}
        onOpen={() => setThemeDrawerOpen(true)}
        transitionDuration={{ enter: 700, exit: 700 }}
      >
        <div className={classes.list}>
          <div>
            <TabContext value={currentTab}>
              <Typography variant="h5" className={classes.tabList}>
                {' '}
                Theme Settings
              </Typography>

              {SettingsTabs.map((value, index) => {
                return (
                  <TabPanel
                    className={classes.tabContainer}
                    key={index}
                    value={index.toString()}
                    hidden={Number(currentTab) !== Number(index)}
                  >
                    {value.component(entity, setThemeDrawerOpen)}
                  </TabPanel>
                )
              })}
            </TabContext>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
export default withStyles(styles)(ThemeSettingsDrawer)
