import React from 'react'
import { Icon } from '@iconify/react'
import { Typography, IconButton, withStyles } from '@material-ui/core'
import styles from '../styles'
const InputField = props => {
  const {
    uploadedFile,
    onAddBulkMember,
    memberCount,
    setUploadedFile,
    classes,
  } = props
  return (
    <div className={classes.modalContent__inputField}>
      {!uploadedFile ? (
        <>
          <input
            type="file"
            className={classes.file__upload}
            onChange={onAddBulkMember}
          />
          <Icon
            icon={'eva:cloud-upload-fill'}
            color={'#919EAB'}
            height={40}
            width={40}
          ></Icon>
          <Typography color={'#919EAB'} fontSize="14px" fontWeight={500}>
            Drag and Drop file here or Choose file
          </Typography>
          <Typography color={'#919EAB'} fontSize="12px" fontWeight={400}>
            Supported formats: CSV File
          </Typography>
        </>
      ) : (
        <>
          <Icon
            icon={'fa-solid:file-csv'}
            height={40}
            width={40}
            color="#015FFB"
          ></Icon>
          <Typography fontSize={'14px'} fontWeight={500} color={'#015FFB'}>
            {uploadedFile.name}
          </Typography>
          <Typography color={'#637381'} fontSize="14px" fontWeight={400}>
            Total {memberCount} learners
          </Typography>
          <IconButton
            style={{
              padding: '5px',
              position: 'absolute',
              top: 20,
              right: 20,
            }}
            onClick={() => setUploadedFile()}
          >
            <Icon
              icon={'lucide:trash'}
              color="#C3CBD1"
              style={{ width: '20px', height: '20px' }}
            ></Icon>
          </IconButton>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(InputField)
