import React from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useDispatch } from 'react-redux'
import styles from './styles'
import data from './constants'
import { setSelectedPageViewAction } from 'src/reducers/course-collection/action'
import { DynamicAttributes } from './components'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'
import { PatchContent, PatchData } from 'src/model/patch'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'

const Highlights = props => {
  const { classes } = props
  const dispatch = useDispatch()

  const handleButtonClick = index => {
    if (index === data.length - 1) {
      // Handle the last button click to open file explorer
      document.getElementById('fileInput').click()
    } else if (index === 0) {
      dispatch(
        componentBoxShowAction(
          new ComponentBoxContent(<DynamicAttributes />, {
            hideCloseButton: true,
            callback: e => {},
          }),
        ),
      )
    } else if (index === 1) {
      dispatch(setSelectedPageViewAction("Learners' Access"))
    } 
  }

  const selectedCollection = window.location.pathname.split('/')[1]

  const onLogoUpload = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        const patchSource = new PatchData(null, selectedCollection)
        patchSource.addContent(
          new PatchContent(result.url, 'logoUrl', PatchData.OPERATION_REPLACE),
        )
        dispatch(patchCourseCollectionAction(patchSource))
      }),
    )
  }

  return (
    <div className={classes.root}>
      <Typography>Highlights</Typography>
      {data.map((item, index) => (
        <div key={index} className={classes.highlightItem}>
          <div>
            <Icon icon="ic:round-info" className={classes.infoIcon} />
          </div>
          <div className={classes.infoText}>
            <Typography>{item.info}</Typography>
          </div>
          <div className={classes.flexGrow}></div>
          <div>
            <Button
              variant="text"
              className={classes.button}
              onClick={() => handleButtonClick(index)}
            >
              <Typography>{item.buttonText}</Typography>
            </Button>
          </div>
        </div>
      ))}
      {/* Hidden file input to open file explorer */}
      <input
        type="file"
        id="fileInput"
        className={classes.fileInput}
        onChange={e => {
          onLogoUpload(e)
        }}
      />
    </div>
  )
}

export default withStyles(styles)(Highlights)
