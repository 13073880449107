import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import styles from './styles'
import { cloneTemplateCourseAction } from 'src/reducers/assignment/action'
import { Options } from 'src/model/template-courses/index'
import { track } from '@amplitude/analytics-browser'
import TemplateSelection from './template-selection'
import CourseSelection from './course-selection'
import EmbedView from './embed-view'
import CourseNaming from './course-naming'
import BackButton from './back-button'

const SelectTemplate = props => {
  const dispatch = useDispatch()
  const { classes, history } = props
  const [itemSelected, setItemSelected] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [courseSelected, setCourseSelected] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState()
  const [templateSelected, setTemplateSelected] = useState(false)
  const [courseTitle, setCourseTitle] = useState()
  const [collection, setCollection] = useState(
    window.location.pathname.split('/select-template/')[1] ||
      '00000000-0000-0000-0000-000000000000',
  )

  const handleClickUpdate = optionItems => {
    track('Selected_Course', {
      course: optionItems.name,
    })
    setSelectedCourse(optionItems)
    setCourseSelected(!courseSelected)
  }

  const handleBack = () => {
    if (itemSelected && courseSelected && templateSelected) {
      setTemplateSelected()
    } else if (itemSelected && courseSelected && !templateSelected) {
      setCourseSelected()
    } else if (itemSelected && !courseSelected && !templateSelected) {
      setItemSelected()
    } else if (!itemSelected && !courseSelected && !templateSelected) {
      history.push('/create-course')
    }
  }
  const handleSelectedItem = index => {
    track('Template_selection', {
      template: Options[index].cardName,
    })
    setItemSelected(true)
    setSelectedItem(index)
    if (Options[index].items.length == 1) {
      setSelectedCourse(Options[index].items[0])
      setCourseSelected(true)
    }
  }
  const handleCopyCourse = () => {
    track('Copied_Course', {
      course: selectedCourse.name,
    })
    const code = selectedCourse.code
    const collectionId =
      collection && collection != '00000000-0000-0000-0000-000000000000'
        ? collection
        : ''
    dispatch(
      cloneTemplateCourseAction(code, courseTitle, collectionId, response => {
        history.push('/mini-course/' + response.data.id)
      }),
    )
  }
  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <BackButton
          itemSelected={itemSelected}
          courseSelected={courseSelected}
          templateSelected={templateSelected}
          Options={Options}
          selectedItem={selectedItem}
          handleBack={handleBack}
        />

        {!itemSelected && !courseSelected && !templateSelected && (
          <TemplateSelection
            handleSelectedItem={handleSelectedItem}
            Options={Options}
          />
        )}
        {itemSelected && !courseSelected && !templateSelected && (
          <CourseSelection
            selectedItem={selectedItem}
            handleClickUpdate={handleClickUpdate}
            Options={Options}
          />
        )}
        {itemSelected && courseSelected && !templateSelected && (
          <EmbedView
            selectedCourse={selectedCourse}
            setTemplateSelected={setTemplateSelected}
          />
        )}
      </div>

      {itemSelected && courseSelected && templateSelected && (
        <CourseNaming
          setCourseTitle={setCourseTitle}
          handleCopyCourse={handleCopyCourse}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(SelectTemplate)
