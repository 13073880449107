import React, { Component } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { getItemStyle, getSubHeaderItemStyle } from 'src/utils/dnd/getItemStyle'
import { getAnswerListStyle } from 'src/utils/dnd/getSubHeaderListStyle'
import { withStyles, styled } from '@material-ui/core/styles'

import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DragIcon from '@material-ui/icons/DragIndicatorOutlined'
import { Typography, TextField, Box, Button } from '@material-ui/core'
import styles from './styles'

const SubHeaders = props => {
  const {
    header,
    outlineNum,
    handleSubHeaderItemChange,
    deleteSubheader,
    addOwnSubheader,
    classes,
  } = props

  const CssTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },

    '& .MuiInput-underline:after': {
      borderBottom: '0px',
    },
  })

  const inputFocus = id => {
    document.getElementById(id).focus()
  }

  return (
    <Droppable droppableId={`droppable${header.id}`} type={`${outlineNum}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getAnswerListStyle(snapshot.isDraggingOver)}
        >
          {header.subHeaders.map((subHeader, index) => {
            return (
              <Draggable
                key={`${outlineNum}${index}`}
                draggableId={`${outlineNum}${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getSubHeaderItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <Box className={classes.header}>
                      <span {...provided.dragHandleProps}>
                        <DragIcon
                          color="inherit"
                          fontSize="small"
                          style={{ marginTop: 6 }}
                        />
                      </span>

                      <CssTextField
                        id={header.id + '_' + index}
                        key={header.id + '_' + index}
                        defaultValue={subHeader}
                        variant="standard"
                        style={{ borderBottom: 0, minWidth: '65%' }}
                        onBlur={e =>
                          handleSubHeaderItemChange(e, outlineNum, index)
                        }
                        placeholder="Edit Your Outline Subheader"
                        multiline
                      />
                      <span className="buttons">
                        <EditIcon
                          onClick={() => {
                            inputFocus(header.id + '_' + index)
                          }}
                          color="inherit"
                          fontSize="small"
                          className={classes.button}
                        />
                        <DeleteIcon
                          onClick={() => {
                            deleteSubheader(outlineNum, subHeader)
                          }}
                          color="inherit"
                          fontSize="small"
                          className={classes.button}
                        />
                      </span>
                    </Box>
                  </div>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
          <Button
            variant="text"
            size="small"
            onClick={() => addOwnSubheader(outlineNum)}
            style={{ marginLeft: 10 }}
          >
            <Typography fontSize={12} fontStyle="normal">
              Add a Subheader
            </Typography>
          </Button>
        </div>
      )}
    </Droppable>
  )
}

export default withStyles(styles)(SubHeaders)
