import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import Page from '../../components/Page'
import style from './style'

// material
import { styled } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  withStyles,
  TextField,
  Button,
  Paper,
  Stack,
  Switch,
} from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
// components
import { PricingPlanCard } from '../../components/pricing'
import SuccessCode from './success'
import Subscription from './subscription'
//
import {
  PlanFreeIcon,
  PlanPremiumIcon,
  PlanPremiumFridayIcon,
  PlanPremiumEnterpriseIcon,
} from '../../assets'
import { meService } from '../../api/authentication'
import { getUserLimits } from '../../api/limit'
import UserType from '../../model/user-types'
import { createStripeSessionService } from '../../api/payment'
import {
  componentBoxShowAction,
  componentBoxHideAction,
} from '../../reducers/component-box/action'
import ComponentBoxContent from '../../model/component-box'
import { codeVerifierAction } from '../../reducers/common/action'
import translator from 'src/translate'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Icon } from '@iconify/react'
import check from '@iconify-icons/eva/checkmark-circle-2-fill'
import { customerUpgradePortal } from '../../api/limit'
import { track } from '@amplitude/analytics-browser'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
}))

export const handleClick = async card => {
  const {
    priceId,
    priceTrialDays,
    successReturnUrl,
    labelAction,
    subscription,
    starterPackage,
    premiumPackage,
  } = card
  track('upgrade_clicked', {
    subscription: subscription,
  })

  if (
    subscription == '  Pro ' &&
    labelAction == 'Upgrade' &&
    (starterPackage || premiumPackage)
  ) {
    customerUpgradePortal().then(response => {
      let sessionUrl = response.data.sessionUrl
      window.open(`${sessionUrl}`)
    })
  } else {
    const resMe = await meService()

    const resStripeSession = await createStripeSessionService(
      priceId,
      priceTrialDays,
      successReturnUrl,
    )

    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      sessionId: resStripeSession.data.sessionId,
    })

    /* if (error) {
      setLoading(false)
      setStripeError(error)
    } */
  }
}

export const PricingPlans = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [timeInterval, setTimeInterval] = useState(true)
  const [starterPackage, setStarterPackage] = useState('')
  const [premiumPackage, setPremiumPackage] = useState('')
  const [premiumPlusPackage, setPremiumPlusPackage] = useState('')
  const handleChange = () => {
    setTimeInterval(!timeInterval)
  }
  const [type, setType] = useState('')

  useEffect(() => {
    dispatch(
      componentBoxHideAction(),
      getUserLimits().then(response => {
        let userType = response.data[0].limit.roleType
        setType(UserType[userType])

        setStarterPackage(
          UserType[userType] == 'Starter' || UserType[userType] == 'Starter - A'
            ? true
            : false,
        )
        setPremiumPackage(
          UserType[userType] == 'Premium - A' ||
            UserType[userType] == 'Premium' ||
            UserType[userType] == 'Premium v2'
            ? true
            : false,
        )

        setPremiumPlusPackage(
          UserType[userType] == 'Premium Plus' ||
            UserType[userType] == 'Premium Plus v2'
            ? true
            : false,
        )
      }),
    )
  }, [])

  const PLANS = [
    {
      subscription: 'Basic',
      icon: <PlanFreeIcon />,
      price: 0,
      caption: ' Free Forever ',
      lists: [
        { text: 'Unlimited user engagements', isAvailable: true },
        { text: '3 mini-courses', isAvailable: true },
        {
          text: 'Analytics for limited users',
          isAvailable: true,
        },
        { text: 'Link sharing & embed code', isAvailable: true },
        { text: 'Customization of layout & theme', isAvailable: true },

        { text: 'Landing page for collections', isAvailable: true },
      ],
      labelAction: type == 'Free Account' ? 'current plan' : 'free plan',
    },

    /*  {
      subscription: 'Starter',
      icon: <PlanPremiumIcon />,
      price: timeInterval ? 97 : 9,
      priceId: timeInterval
        ? 'price_premium_starter_annual'
        : 'price_premium_starter',
      priceTrialDays: null,
      starterPackage: starterPackage,
      successReturnUrl: timeInterval
        ? 'annual-starter-subscription-successful'
        : 'starter-subscription-successful',
      caption: 'Save 10% with annual plan',
      lists: [
        { text: '10 mini-courses', isAvailable: true },
        {
          text: 'Analytics for 1.000 users per m.c.',
          isAvailable: true,
        },
        {
          text: '1 GB limit for video uploads',
          isAvailable: true,
        },
        { text: '75 AI-Assistant credits (per month)', isAvailable: true },

        { text: 'Custom Domains & Webhooks', isAvailable: true },
        { text: 'Premium themes', isAvailable: true },
      ],
      labelAction: starterPackage ? 'Current Plan' : 'Upgrade',
    }, */
    {
      subscription: '  Premium  ',
      icon: <PlanPremiumIcon />,
      price: timeInterval ? 15 : 29,
      priceId: timeInterval
        ? 'price_1NixlMI6xu65zAZTZ9bQSsxr'
        : 'price_1NifyjI6xu65zAZTwvnLB5dP',
      priceTrialDays: null,
      starterPackage: starterPackage,
      premiumPackage: premiumPackage,
      successReturnUrl: timeInterval
        ? 'annual-premium-subscription-successful'
        : 'premium-subscription-successful',
      caption: 'Save 50% when billed annually',
      lists: [
        { text: 'Unlimited mini-courses', isAvailable: true },
        {
          text: 'Analytics for all users',
          isAvailable: true,
        },
        {
          text: '500 AI-Assistant credits (per month)',
          isAvailable: true,
        },
        { text: 'Custom domains', isAvailable: true },

        {
          text: 'Gated access with pay-wall & sign-up wall',
          isAvailable: true,
        },
        { text: 'Removal of MCG badge', isAvailable: true },
      ],
      labelAction: starterPackage
        ? 'Upgrade'
        : premiumPackage
        ? 'Current Plan'
        : 'Upgrade',
    },
    {
      subscription: '  Pro ',
      icon: <PlanPremiumFridayIcon />,
      price: timeInterval ? 25 : 49,
      priceId: timeInterval
        ? 'price_1NixmEI6xu65zAZTCIuRIwDe'
        : 'price_1Nig3NI6xu65zAZTFLqIxyFb',
      priceTrialDays: null,
      starterPackage: starterPackage,
      premiumPackage: premiumPackage,
      successReturnUrl: timeInterval
        ? 'annual-premium-plus-subscription-successful'
        : 'premium-plus-subscription-successful',
      caption: 'Save 50% when billed annually',
      lists: [
        { text: '1000 AI-Assistant credits (per month)', isAvailable: true },
        {
          text: 'Export to SCORM',
          isAvailable: true,
        },
        {
          text: 'Enabling access to specific users',
          isAvailable: true,
        },
        { text: 'In-app authentication (100 users)', isAvailable: true },

        { text: 'More storage for video uploads', isAvailable: true },
        { text: 'Online session when support needed', isAvailable: true },
      ],
      labelAction: premiumPackage
        ? 'Upgrade'
        : premiumPlusPackage
        ? 'Current Plan'
        : 'Upgrade',
    },
    /*   {
      subscription: '  Enterprise ',
      icon: <PlanPremiumEnterpriseIcon />,
      price: timeInterval ? 469 : 49,
      priceId: timeInterval
        ? 'price_premium_plus_annual'
        : 'price_premium_plus',
      priceTrialDays: null,
      starterPackage: starterPackage,
      premiumPackage: premiumPackage,
      successReturnUrl: timeInterval
        ? 'annual-premium-plus-subscription-successful'
        : 'premium-plus-subscription-successful',
      caption:
        'Tailored for the needs of vocational training and corporate learning',
      lists: [
        { text: 'User management', isAvailable: true },
        {
          text: 'User analytics',
          isAvailable: true,
        },
        {
          text: 'xAPI export for LMS',
          isAvailable: true,
        },
        { text: 'Share on Slack', isAvailable: true },

        { text: 'Share on Teams', isAvailable: true },
        { text: 'Team collaboration', isAvailable: true },
      ],
      labelAction: 'Schedule a call',
    }, */
  ]
  return (
    <>
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="overline" sx={{ mr: 1.5 }}>
            MONTHLY
          </Typography>
          <Switch checked={timeInterval} onChange={handleChange} />
          <Typography variant="overline" sx={{ ml: 1.5 }}>
            YEARLY
          </Typography>
          <Box m={5}></Box>
        </Stack>
      </Box>
      <Grid
        container
        display="flex"
        //flexDirection="column"
        justifyContent="center"
      >
        {PLANS.map((card, index) => (
          <Grid md={4} key={card.subscription}>
            <PricingPlanCard
              userType={type}
              card={card}
              index={index}
              timeInterval={timeInterval}
              onClickHandler={() => {
                handleClick(card)
              }}
              handleChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

// ----------------------------------------------------------------------

function Pricing(props) {
  const dispatch = useDispatch()
  const { classes, history } = props

  //const [stripeError, setStripeError] = useState()
  //const [loading, setLoading] = useState()

  const [code, setCode] = useState('')

  const routeChange = () => {
    let path = `/`
    history.push(path)
  }
  const onHandleAppsumoCode = event => {
    if (code != '') {
      dispatch(
        codeVerifierAction(code, () => {
          successCode()
        }),
      )
    }
  }

  const onHandleSubscription = event => {
    if (code != '') {
      subscription()
    }
  }

  const successCode = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <SuccessCode history={history} dispatch={dispatch} />,
          { hideCloseButton: true },
        ),
      ),
    )
  }
  const subscription = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <Subscription history={history} dispatch={dispatch} />,
          { hideCloseButton: false, header: 'Warning!' },
        ),
      ),
    )
  }
  return (
    <RootStyle title="Pricing">
      <Container maxWidth="fit-content">
        <Typography variant="h3" align="center" paragraph>
          Break the limits with paid plans.
        </Typography>
        <Typography align="center" sx={{ color: 'text.secondary' }}>
          Start your subscription and get the most out of Mini Course Generator.{' '}
          <br />
          If you change your mind in 7 days after the payment, we will refund
          100% of your money. No questions asked.
        </Typography>
        <Box m={5}></Box>

        <PricingPlans handleClick={handleClick} />

        <Box m={5}></Box>
      </Container>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                {translator._('pricing.compare')}
              </TableCell>
              <TableCell align="center">
                {translator._('pricing.free')}
              </TableCell>
              <TableCell align="center" width={150}>
                {translator._('pricing.premium')}
              </TableCell>
              <TableCell align="center" width={150}>
                {translator._('pricing.premiumPlus')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableTitle}
              >
                {translator._('pricing.tableTitle.creating')}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                {translator._('pricing.tableCell.storageLimitImg')}
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                HTML editor (to embed videos, podcasts, etc.)
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Layout and theme customization
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Landing page for collections
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                AI Course Creator inc. images & questions
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                In-card AI-Assistant
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Number of mini-courses
              </TableCell>
              <TableCell align="center">3</TableCell>
              <TableCell align="center">
                {translator._('pricing.tableCell.unlimited')}
              </TableCell>
              <TableCell align="center">
                {translator._('pricing.tableCell.unlimited')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                # of AI credits per month
              </TableCell>
              <TableCell align="center" scope="row">
                Limited Trial
              </TableCell>
              <TableCell align="center" scope="row">
                500
              </TableCell>
              <TableCell align="center" scope="row">
                1000
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Storage for video uploads
              </TableCell>
              <TableCell align="center">100 MB</TableCell>
              <TableCell align="center">30 GB</TableCell>
              <TableCell align="center">75 GB</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableTitle}
              >
                {translator._('pricing.tableTitle.presenting')}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Unlimited views & user engagements
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Sharing mini-courses & collections with links
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Embedding mini-courses & collections
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Removal of MCG branding
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Custom domain (CNAME)
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Export to PDF
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Export to SCORM
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableTitle}
              >
                Enabling Access
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access to everyone
              </TableCell>
              <TableCell align="center">
                {' '}
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                {' '}
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                {' '}
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access after password-wall
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access after double opt-in
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access after payment
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access to specific users
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Enable access with in-app authentication
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">Up to 100 users</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableTitle}
              >
                {translator._('pricing.tableTitle.management')}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {translator._('pricing.tableCell.report')}
              </TableCell>
              <TableCell align="center">First 25 users</TableCell>
              <TableCell align="center">Unlimited</TableCell>
              <TableCell align="center">Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {translator._('pricing.tableCell.seo')}
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Adding .js codes to head & body
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Automations with Zapier Integration
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Automations with Make Integration
              </TableCell>
              <TableCell align="center">- </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Automations with Web-hooks
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
          </TableBody>
          {/*   <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableTitle}
              >
                AI Quiz Generator
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating quiz based on entered text
              </TableCell>
              <TableCell align="center">Limited</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating quiz based on uploaded file
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Creating quiz based on URL
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Using multiple resources to create a quiz
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center">
                <Icon icon={check} className={classes.checkIcon} />
              </TableCell>
            </TableRow>
          </TableBody> */}
        </Table>
      </TableContainer>

      <Divider></Divider>

      <Box m={8}></Box>
      {/*<Grid className={classes.innerGrid}>
        <LocalOfferIcon color="primary" className={classes.icon}>
          {' '}
        </LocalOfferIcon>
        <Box m={1}></Box>
        <Typography variant="h4">
          {translator._('pricing.useCoupon')}
        </Typography>
      </Grid>
      <Box m={3}></Box>

      <Grid container className={classes.innerGrid}>
        <Grid item xs={6} md={2}>
          <TextField
            size="small"
            fullWidth
            label={'Code'}
            id="code"
            name="code"
            onChange={event => {
              setCode(event.target.value)
            }}
          />
        </Grid>
        <Box m={2}></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            switch (UserType[userType]) {
              case UserType[1]:
                onHandleAppsumoCode()
                break
              case UserType[4]:
                onHandleAppsumoCode()
                break
              case UserType[5]:
                onHandleAppsumoCode()
                break
              case UserType[7]:
                onHandleAppsumoCode()
                break
              case UserType[8]:
                onHandleAppsumoCode()
                break
              case UserType[9]:
                onHandleAppsumoCode()
                break
              case UserType[10]:
                onHandleAppsumoCode()
                break
              default:
                onHandleSubscription()
                break
            }
          }}
        >
          {translator._('pricing.redeem')}
        </Button>
      </Grid>*/}
      <Box m={3}></Box>
      <Grid item align="center">
        <Typography>
          {translator._('pricing.contact')}:
          <a href="mailto:support@minicoursegenerator.com">
            support@minicoursegenerator.com
          </a>
        </Typography>
      </Grid>
    </RootStyle>
  )
}
export default withStyles(style)(Pricing)
