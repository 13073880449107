/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import translator from 'src/translate'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Modal,
  Typography,
  withStyles,
  OutlinedInput,
  TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './styles'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
import { createCourseCollectionAction, getCollectionSettingsAction, setSelectedCollectionAction } from 'src/reducers/course-collection/action'
import { patchCourseCollectionAction } from 'src/reducers/course-collection/action'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import CustomizedSwitch from 'src/components/customized-switch'
const CollectionEdit = props => {
  const {
    dispatch,
    handleCloseModal,
    classes,
    isModalOpen,
    collectionEntity,
  } = props

  const [coverImageUrl, setCoverImageUrl] = React.useState(
    collectionEntity && collectionEntity.coverImageUrl != null
      ? `${URL.CDN}${collectionEntity.coverImageUrl}`
      : 'https://cdn.minicoursegenerator.com/assets/gateway/collection-cover.png',
  )

  const [customizeBadge, setCustomizeBadge] = React.useState(
    collectionEntity &&
      collectionEntity.badgeText != null &&
      collectionEntity.badgeText != '',
  )
  const [badgeText, setBadgeText] = React.useState(
    collectionEntity && collectionEntity.badgeText,
  )
  const [logoUrl, setLogoUrl] = React.useState(
    collectionEntity && collectionEntity.logoUrl != null
      ? collectionEntity.logoUrl
      : '',
  )
  const [collectionTitle, setCollectionTitle] = React.useState(
    collectionEntity ? collectionEntity.collectionName : '',
  )
  const [collectionCoverPageText, setCollectionCoverPageText] = React.useState(
    collectionEntity ? collectionEntity.description : '',
  )

  const handleTextFieldChange = e => {
    setCollectionTitle(e.target.value)
  }
  const handleTextFieldChangeCoverText = e => {
    setCollectionCoverPageText(e)
  }
  const onCoverUpload = event => {
    if (coverImageUrl) return
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setCoverImageUrl(`${result.url}`)
      }),
    )
  }
  const onLogoUpload = event => {
    if (logoUrl) return
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setLogoUrl(`${result.url}`)
      }),
    )
  }
  const isTextFieldValid = () => {
    if (collectionEntity || collectionTitle.length >= 1) {
      return true
    }
    return false
  }

  const handleSaveCollection = () => {
    const patchSource = new PatchData(null, collectionEntity.id)
    patchSource.addContent(
      new PatchContent(
        collectionTitle,
        'collectionName',
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        coverImageUrl,
        'coverImageUrl',
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        collectionCoverPageText,
        'description',
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(
        logoUrl ? logoUrl : '',
        'logoUrl',
        PatchData.OPERATION_REPLACE,
      ),
    )
    patchSource.addContent(
      new PatchContent(badgeText, 'badgeText', PatchData.OPERATION_REPLACE),
    )

    dispatch(
      patchCourseCollectionAction(patchSource, () => {
        handleCloseModal(true)
        setCoverImageUrl(coverImageUrl)
      }),
    )
  }

  const handleCreateColumn = async () => {
    const entity = {
      collectionName: collectionTitle,
      description: collectionCoverPageText,
      coverImageUrl: coverImageUrl,
      logoUrl: logoUrl,
    }
    dispatch(
      createCourseCollectionAction(entity, response => {
 
        dispatch(getCollectionSettingsAction(response.data.id))
        dispatch(setSelectedCollectionAction(response.data.id))
        window.history.pushState(null, '', `/${response.data.id}`)
        handleCloseModal()
      }),
    )
  }
  const onClickSwitch = () => {
    if (!customizeBadge == false) {
      setBadgeText('')
    }
    setCustomizeBadge(!customizeBadge)
  }
  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.namingModalContent}>
        <div className={classes.headerGroup}>
          <Typography className={classes.headerText}>
            {collectionEntity ? `Edit Landing Page` : 'New Collection'}
          </Typography>
        </div>

        <div className={classes.root__bg}>
          <div className={classes.root__section}>
            <div className={classes.root__content}>
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                Collection Name
              </Typography>
              <OutlinedInput
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  borderColor: 'black',
                }}
                defaultValue={collectionTitle}
                placeholder={translator._('assignment.assignmentName')}
                onChange={handleTextFieldChange}
              />
            </div>
          </div>
        </div>
        <div className={classes.buttonGroupNaming}>
          <Button
            variant="outlined"
            color="inherit"
            style={{
              height: '36px',
              width: '85px',
            }}
            onClick={handleCloseModal}
          >
            {translator._('assignment.back')}
          </Button>
          <Button
            variant="contained"
            disabled={isTextFieldValid() ? false : true}
            className={classes.namingContinueButton}
            onClick={
              collectionEntity ? handleSaveCollection : handleCreateColumn
            }
          >
            {translator._(
              collectionEntity ? 'assignment.save' : 'assignment.create',
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(CollectionEdit)
