import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { Header, SelectionPart, DataTables } from './components'
import { getCollectionAnalyticsAction } from 'src/reducers/course-collection/action'
const Analytics = props => {
  const { containers, classes } = props
  const dispatch = useDispatch()
  const [analyticsData, setAnalyticsData] = React.useState()
  const [selectedCourse, setSelectedCourse] = React.useState('')

  const selectedCollectionId = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const [selectedCollection, setSelectedCollection] = useState(
    containers.find(container => container.id === selectedCollectionId),
  )

  const [courses, setCourses] = useState(
    selectedCollection &&
      containers.length > 0 &&
      containers.filter(x => x.id == selectedCollection.id)[0].courses,
  )

  const handleChange = event => {
    setSelectedCourse(event.target.value)
    dispatch(
      getCollectionAnalyticsAction(
        selectedCollectionId,
        event.target.value,
        data => {
          setAnalyticsData(data)
        },
      ),
    )
  }
  useEffect(() => {
    if (selectedCollectionId) {
      dispatch(
        getCollectionAnalyticsAction(
          selectedCollectionId,
          selectedCourse,
          data => {
            setAnalyticsData(data)
          },
        ),
      )
    }
  }, [selectedCollectionId])

  const data = [
    {
      cardName: 'Eligible learners',
      tooltipMessage:
        'Those eligible to access the content. If no gateway is used, everyone is eligible. If an email gateway is selected, only learners with confirmed emails are eligible. If a payment gateway is used, only learners who have paid are eligible. If access is restricted to specific users that you invite, then only they are eligible. For automation or integration, eligibility is determined by the permissions you set.',
      image: '/assets/analytics-badges/eligible_learners.png',
      value: analyticsData?.assigned == null ? 'All' : analyticsData?.assigned,
    },
    {
      cardName: 'Engaged learners',
      tooltipMessage:
        'Those who have interacted with the content by completing at least one card. This subset of eligible learners has actively participated in the learning process, either partially or fully. Engagement can include proceeding to the next card or responding to a question.',
      image: '/assets/analytics-badges/engaged_learners.png',
      value: analyticsData?.engaged,
    },
    {
      cardName: 'Learners who completed',
      tooltipMessage:
        'Those who have fully engaged with and completed the entire content, whether it’s the whole collection or a filtered mini-course.',
      image: '/assets/analytics-badges/learners_who_completed.png',
      value: analyticsData?.completed,
    },
    {
      cardName: 'Learners with badges',
      tooltipMessage:
        'Those who have earned a badge as recognition based on your chosen criteria, such as completing a mini-course or achieving a success threshold that you set within a mini-course.',
      image: '/assets/analytics-badges/learners_with_badges.png',
      value: analyticsData?.badgeCount,
    },
    {
      cardName: 'Learners with certificates',
      tooltipMessage:
        'Those who have been awarded a certificate upon successfully completing the collection or achieving a success threshold that you set within the collection.',
      image: '/assets/analytics-badges/learners_with_certificates.png',
      value: analyticsData?.certificateCount,
    },
    {
      cardName: 'Average success score',
      tooltipMessage:
        'This metric represents the mean average of the success scores of all mini-courses within a collection. It provides an overall success rate for the collection by calculating the average of individual mini-course scores. For example, if a collection contains three mini-courses with success scores of 80%, 70%, and 50%, the Average Success Score would be 66.6%.',
      image: '/assets/analytics-badges/average_success_score.png',
      value:
        analyticsData?.averageScore == 'NaN'
          ? '-'
          : analyticsData?.averageScore + ' %',
    },
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Header />
      <SelectionPart
        courses={courses}
        selectedCourse={selectedCourse}
        handleChange={handleChange}
        selectedCollectionId={selectedCollectionId}
      />
      <DataTables data={data} />
    </div>
  )
}

export default withStyles(styles)(Analytics)
