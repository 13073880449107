import { isMobile } from 'react-device-detect'

const styles = theme => ({
  outlineContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxWidth: 'fit-content',
    width: 280,
  },
  header: {
    '& .buttons': {
      display: 'none',
    },
    '&:hover .buttons': {
      display: 'inline-block',
      float: 'right',
    },
    '& .dragIcon': {
      opacity: 0,
    },
    '&:hover .dragIcon': {
      opacity: 1,
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextField: { borderBottom: 0, maxWidth: '70%' },
  addSectionDiv: {
    width: '100%',
    padding: 16,
  },
  addSectionButton: {
    width: '100%',
    height: '30px',
    background: '#fff',
    boxShadow: 'none',
    color: '#015FFB',
    '&:hover': {
      color: '#015FFB',
      background: '#fff',
      boxShadow: 'none',
      outline: '1px solid #015FFB',
    },
  },
  addItem: {
    fontSize: '13px',
    fontWeight: 400,
  },
  addCardButton: {
    marginLeft: 10,
    color: '#637381',
  },
  button: {
    color: '#919EAB',
  },
  barWidth: {
    width: 300,
  },
  leftSide: {
    position: 'fixed',
    background: '#f4f6f8',
    borderRadius: 20,
  },
  coverImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    width: 280,
    height: 'auto',
    borderRadius: 20,
    overflow: 'hidden',
    marginBottom: 16,
  },
  drawerContent: {
    width: '100%',
    height: '100%',
    display: 'grid',
    minHeight: 200,
    alignContent: 'center',
  },
  courseName: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    width: '85%',
    padding: 20,
    height: 'fit-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    width: 20,
    height: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIconHolder: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
  },
  editCoverText: {
    position: 'relative',
    right: '-3px',
    top: '-8px',

    fontSize: 11,
    color: '#ffffff',
  },
  addAIQuizIcon: {
    height: 16,
    marginRight: 3,
  },
  addAIQuizButton: {
    marginLeft: 10,
    marginTop: 8,
  },
  addAIQuizText: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#22A822',
  },
})

export default styles
