import React from 'react'
import { useSelector } from 'react-redux'
import URLs from 'src/api/url'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import { CollectionDetails, PageSelectionButtons } from './components'
const CollectionView = props => {
  const { classes } = props

  return (
    <>
      <CollectionDetails />
      <PageSelectionButtons />
    </>
  )
}

export default withStyles(styles)(CollectionView)
