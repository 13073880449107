const styles = theme => ({
  coverImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    width: '100%',
    height: 146,
    borderRadius: 16,
    overflow: 'hidden',
    marginBottom: 16,
    cursor: 'pointer',
    position: 'relative',
  },

  courseGalleryView: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8FAFC',
    height: 280,
    width: 300,
    borderRadius: 16,
    cursor: 'pointer',
    zIndex: 1,
  },
  courseSortView: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    height: 130,
    width: 130,
    cursor: 'grab',
    borderRadius: 16,
    margin: 8,
  },
  buttons: {
    display: 'flex',
    backgroundColor: '#ffffff',
    height: '42px',
    color: '#002E47',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  learnersAccessButton: {
    display: 'flex',
    backgroundColor: '#015FFB',
    height: '42px',
    maxWidth: '140px',
    width: '100%',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#015FFB',
      color: '#fff',
    },
  },
  buttonEdit: {
    display: 'flex',
    backgroundColor: '#ffffff',
    height: '42px',
    maxWidth: '140px',
    width: '100%',
    color: '#002E47',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  buttonCollection: {
    display: 'flex',
    backgroundColor: '#ffffff',
    height: '42px',
    maxWidth: '150px',
    width: '100%',
    color: '#002E47',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  iconButton: {
    width: '42px',
    height: '42px',
    borderRadius: '1rem',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#015FFB',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  kanbanColumnAdd__paper: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    borderRadius: '16px',
    height: '40px',
  },
  kanbanColumnAdd__button: {
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    justifyContent: 'center',
    border: 'none',
  },
  kanbanColumnAdd__text: {
    color: '#1D1D1F',
    fontSize: '14px',
    fontWeight: 500,
  },
})

export default styles
