import React from 'react'
import {
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  withStyles,
} from '@material-ui/core'
import translator from 'src/translate'
import ListDomain from 'src/container/list-domain'
import styles from '../style'
const ContainerContent = props => {
  const {
    steps,
    activeStep,
    handleStep,
    getStepContent,
    handleNext,
    classes,
  } = props
  return (
    <React.Fragment>
      <Typography variant="h3" align="center">
        {translator._('customDomain.customDomain')}
      </Typography>
      <Typography align="center" gutterBottom>
        {translator._('customDomain.addCustomDomain')}
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel onClick={handleStep(index)}>{label}</StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  {activeStep == 0 && (
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.next}
                        >
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                      </div>
                    </div>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      <Box m={6}></Box>
      <div className={classes.card}>
        <ListDomain></ListDomain>
      </div>{' '}
    </React.Fragment>
  )
}

export default withStyles(styles)(ContainerContent)
