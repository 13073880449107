const Options = [
  {
    text: 'Not sure / Just checking things out',
  },
  {
    text: 'Enriching workshops and live training programs',
  },
  {
    text: 'Onboarding new staff, customers, community members, etc.',
  },
  {
    text: 'Selling courses to individuals or organizations',
  },
  {
    text: 'Offering courses only to members through in-app authentication',
  },
  {
    text: 'Offering free courses to collect emails for lead generation',
  },
]

export { Options }
