import React, { useRef, useState, useEffect } from 'react'

import { mediaCardImageCreationAction } from 'src/reducers/media-image/action'
import { Icon } from '@iconify/react'

import { Link as RouterLink } from 'react-router-dom'
import URL from 'src/api/url'

import moment from 'moment-timezone'
import {
  TextField,
  withStyles,
  Button,
  Box,
  Grid,
  Typography,
  Card,
  CircularProgress,
} from '@material-ui/core'
import styles from './style'

import { createPromptFromCardAction } from 'src/reducers/assignment/action'

import { track } from '@amplitude/analytics-browser'

import { startConnection } from 'src/utils/signalR-connection/signalR-connection'
import { addCourseCreationMessageReceivedListener } from 'src/utils/signalR-connection/signalR-connection'

const AiImage = props => {
  const {
    cardEntity,
    assignment,
    onPhotoClick,
    dispatch,
    setAiCount,
    count,
    limit,
    loading,
    handleLoading,
    choosePhoto,
    refreshedAt,
    popupType,
    classes,
  
  } = props

  const [description, setDescription] = useState()
  const [imageUrlForSaveImage, setImageUrlForSaveImage] = useState()
  const [imageURL, setImageURL] = useState()

  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [genre, setGenre] = useState([])
  const [style, setStyle] = useState([])
  const [place, setPlace] = useState([])
  const [camera, setCamera] = useState([])
  const [selectedPreset, setPreset] = useState()
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState()

  const bottomRef = useRef(null)
  useEffect(() => {
    if (popupType == 'create prompt') {
      createPrompt()
    }
  }, [popupType])
  useEffect(() => {
    refreshedAt &&
      refreshedAt.includes('2022') &&
      setRefreshDateAvaliable(false)
  }, [refreshedAt])

  const handleSocketMessage = data => {
    addCourseCreationMessageReceivedListener(async message => {
    
      if (cardEntity.id == message[0]) {
        setImageUrlForSaveImage(message[1])
        setImageURL(`${URL.STORAGE}` + message[1])
        setAiCount(count + 5)

        handleLoading(false)
      }
    })
  }

  const sendAiRequest = () => {
    const prompt = description
    handleLoading(true)
    startConnection()
    track('generate_ai_image')
    dispatch(
      mediaCardImageCreationAction(prompt, cardEntity.id, response => {
        handleSocketMessage(response.data)
      }),
    )
  }
  const handleDescription = value => {
    setDescription(value)
  }

  const descriptionModifier = (oldValue, value, setter) => {
    let newDescription = ''

    if (description !== undefined) {
      newDescription = description
    }

    oldValue.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    value.forEach(element => {
      newDescription = newDescription + ', ' + element
    })

    // for delete first empty character
    if (newDescription[0] == ' ') {
      newDescription = newDescription.replace(' ', '')
    }
    // for delete first ,
    if (newDescription[0] == ',') {
      newDescription = newDescription.replace(',', '')
    }

    setter(value)
    setDescription(newDescription)
  }

  const handleStyles = async value => {
    descriptionModifier(style, value, setStyle)
  }

  const handleGenre = async value => {
    descriptionModifier(genre, value, setGenre)
  }
  const handlePlace = async value => {
    descriptionModifier(place, value, setPlace)
  }
  const handleCamera = async value => {
    descriptionModifier(camera, value, setCamera)
  }

  const clearAdvancedSettings = async () => {
    let newDescription = ''

    if (description !== undefined) {
      newDescription = description
    }

    style.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    genre.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    camera.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    place.forEach(element => {
      newDescription = newDescription.replace(', ' + element, '')
      newDescription = newDescription.replace(element, '')
    })

    // for delete first empty character
    if (newDescription[0] == ' ') {
      newDescription = newDescription.replace(' ', '')
    }
    // for delete first ,
    if (newDescription[0] == ',') {
      newDescription = newDescription.replace(',', '')
    }
    setStyle([])
    setGenre([])
    setCamera([])
    setPlace([])
    setDescription(newDescription)
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [openAdvanced])

  useEffect(() => {
    clearAdvancedSettings()
  }, [selectedPreset])

  const createPrompt = () => {
    var starterText = cardEntity.text.replace(/(<([^>]+)>)/gi, '')
    track('generate_ai_image_prompt')
    dispatch(
      createPromptFromCardAction(starterText, response => {
        setDescription(response.data)
        setAiCount(count + 1)
      }),
    )
  }

  return (
    <Grid container>
      <Grid item xs={6} spacing={10} className={classes.mainGrid}>
        <div className={classes.searchBar}>
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>
              Text Prompt
              <Button
                variant="outlined"
                className={classes.random}
                disabled={count >= limit}
                onClick={() => {
                  createPrompt()
                }}
              >
                Get Prompt from Card
              </Button>
            </Typography>
          </div>
          <Box m={0.5} />
          <TextField
            multiline
            rows={5}
            fullWidth={true}
            value={description}
            placeholder={'An astronaut riding a horse on mars, Van Gogh'}
            //label="Please describe your image"
            className={classes.textField}
            onChange={e => handleDescription(e.target.value)}
          />
          <Box m={0.5} />
          <div>
            <Typography className={classes.limitText}>
              <span>{limit - count}</span> credits left.{' '}
              {refreshDateAvaliable ? (
                <>
                  • Renewal date{' '}
                  {moment(refreshedAt).add('days', 30).format('L')}
                </>
              ) : (
                <>• Renewed every month</>
              )}
              <span> • This action requires 5 AI credits.</span>
            </Typography>
          </div>
        </div>
        <Box m={0.5} />

        <div style={{ flexGrow: 1 }}></div>
        <Button
          variant="contained"
          className={classes.requestButton}
          onClick={() => sendAiRequest()}
          disabled={count >= limit}
        >
          <img
            height={16}
            width={16}
            style={{ marginRight: 4 }}
            src="/assets/ai-white.png"
          />
          Generate Image
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.imageGrid}>
        <Card className={classes.imageCard}>
          {loading ? (
            <CircularProgress />
          ) : count >= limit ? (
            <>
              <img
                width={390}
                height={250}
                style={{ paddingTop: '10px' }}
                src="/assets/outout-limit.png"
              />

              <Box m={1} />
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                  textAlign: 'center',
                  color: '#015FFB',
                }}
              >
                Wow, you've reached the ceiling!
              </Typography>

              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                It looks like you’ve run out of your AI credits!
                <br />
                Upgrade to a higher plan to generate more images
                <br /> with our AI-Assistant now.
              </Typography>
              <Box m={2} />
              <Button
                variant="contained"
                to="/pricing"
                component={RouterLink}
                style={{
                  borderRadius: 16,
                  backgroundColor: '#015FFB',
                  height: '30px',
                  width: '120px',
                }}
              >
                Upgrade
              </Button>
            </>
          ) : imageURL ? (
            <>
              <img
                width={390}
                //height={390}
                className={classes.generatedImage}
                src={imageURL}
                onClick={() => {
                  onPhotoClick(imageUrlForSaveImage)
                  track('use_ai_image')
                }}
              />

              <Box m={1} />
              <Button
                variant="outlined"
                style={{
                  color: '#00AB55',
                  borderRadius: 8,
                  borderColor: '#00AB55',
                }}
                onClick={() => {
                  onPhotoClick(imageUrlForSaveImage)
                  track('use_ai_image')
                }}
              >
                Use This Image
              </Button>
            </>
          ) : (
            <>
              <Card
                style={{
                  width: '90%',
                  height: '100%',
                  margin: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  icon="ic:round-add-a-photo"
                  color="#919EAB"
                  width="48"
                  height="48"
                />
                <Typography
                  style={{
                    color: '#919EAB',
                    fontWeight: 450,
                    fontSize: '12px',
                  }}
                >
                  Enter your text prompt and generate image!
                </Typography>
              </Card>
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(AiImage)
