import React, { useState, useEffect } from 'react'
import translator from 'src/translate'
import { domainPatchAction } from 'src/reducers/domain/action'
import { courseCollectionListAction } from 'src/reducers/course-collection/action'
import styles from './styles'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { PatchContent, PatchData } from 'src/model/patch'
import { domainListAction } from 'src/reducers/domain/action'

import {
  Button,
  Divider,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Box,
  TextField,
} from '@material-ui/core'

const CustomDomainCourseEdit = props => {
  const {
    title,
    domainName,
    inputLabel,
    dispatch,
    entity,
    classes,
    refresh,
    dataType,
  } = props

  const [courseName, setcourseName] = useState(inputLabel)
  const [courses, setCourses] = useState()
  const [collections, setCollections] = useState()
  const [courseId, setCourseId] = useState(inputLabel)
  const [type, setType] = useState(dataType)
  const [collectionName, setCollectionName] = useState()
  const [collectionId, setCollectionId] = useState()
  useEffect(() => {
    if (!courses) {
      const query = {
        CurrentPage: 0,
        PageSize: 200,
        SortingColumn: 'LastModifiedAt',
        Asc: false,
        FilterText: '',
        Filters: [],
      }
      dispatch(
        courseCollectionListAction(
          { ...query, SortingColumn: 'LastModifiedAt' },
          data => {
            setCourses(data.courseDtos)
            setCollections(data.courseCollectionDtos)
          },
        ),
      )
    }
  }, [])

  /**
   *
   * @param {*} e
   */
  const handleCourse = e => {
    setCourseId(e.target.value)
    setcourseName(e.target.value)
  }
  const handleCollection = e => {
    setCollectionName(e.target.value)
    setCollectionId(e.target.value)
  }
  const handleType = e => {
    setType(e.target.value)
  }
  const handleSubmit = () => {
    if (type == 'Collection') {
      let path = 'defaultCourseId'
      let newPath = 'defaultCollectionId'
      dispatch(
        domainPatchAction(
          new PatchData(
            new PatchContent(null, path, PatchData.OPERATION_REPLACE),
            entity.id,
          ),
          () => {},
        ),
      )
      dispatch(
        domainPatchAction(
          new PatchData(
            new PatchContent(
              collectionId,
              newPath,
              PatchData.OPERATION_REPLACE,
            ),
            entity.id,
          ),
          () => {
            dispatch(
              domainListAction(
                {
                  CurrentPage: 0,
                  PageSize: 5,
                  SortingColumn: 'createdAt',
                  Asc: false,
                  FilterText: '',
                  Filters: [],
                },
                () => {
                  dispatch(componentBoxHideAction())
                },
              ),
            )
          },
        ),
      )
    } else if (type == 'Course') {
      let path = 'defaultCollectionId'
      let newPath = 'defaultCourseId'
      dispatch(
        domainPatchAction(
          new PatchData(
            new PatchContent(null, path, PatchData.OPERATION_REPLACE),
            entity.id,
          ),
          () => {},
        ),
      )
      dispatch(
        domainPatchAction(
          new PatchData(
            new PatchContent(courseId, newPath, PatchData.OPERATION_REPLACE),
            entity.id,
          ),
          () => {
            dispatch(
              domainListAction(
                {
                  CurrentPage: 0,
                  PageSize: 5,
                  SortingColumn: 'createdAt',
                  Asc: false,
                  FilterText: '',
                  Filters: [],
                },
                () => {
                  dispatch(componentBoxHideAction())
                },
              ),
            )
          },
        ),
      )
    }
  }

  const handleCloseModal = willRefresh => {
    dispatch(componentBoxHideAction())
    willRefresh && refresh()
  }

  return (
    <div className={classes.namingModalContent}>
      <div className={classes.headerGroup}>
        <Typography variant={'h5'}>{title}</Typography>
      </div>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.listGroup}
      >
        <TextField
          disabled
          variant="outlined"
          id="filled-basic"
          label="Domain Address"
          defaultValue={domainName}
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.listGroup}
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className={classes.listLabel}
        >
          Choose default data
        </InputLabel>
        <Select
          className={classes.select}
          inputProps={{
            className: classes.input,
          }}
          fullWidth
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Choose your course"
          value={type}
          disabled={false}
          input={<Input id="select-input" name="select-input" />}
          onChange={handleType}
        >
          <MenuItem value={'Course'}>Course</MenuItem>
          <MenuItem value={'Collection'}>Collection</MenuItem>
        </Select>
      </FormControl>
      {type == 'Course' ? (
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.listGroup}
        >
          <InputLabel
            id="demo-simple-select-helper-label"
            className={classes.listLabel}
          >
            {translator._('customDomain.chooseCourse')}
          </InputLabel>
          <Select
            className={classes.select}
            inputProps={{
              className: classes.input,
            }}
            fullWidth
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Choose your course"
            key={courseId}
            value={courseName}
            disabled={false}
            input={<Input id="select-input" name="select-input" />}
            onChange={handleCourse}
          >
            {courses &&
              courses.map(value => {
                return (
                  <MenuItem value={value.id} key={value.name}>
                    {value.name}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.listGroup}
        >
          <InputLabel
            id="demo-simple-select-helper-label"
            className={classes.listLabel}
          >
            Choose your default collection
          </InputLabel>
          <Select
            className={classes.select}
            inputProps={{
              className: classes.input,
            }}
            fullWidth
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Choose your course"
            key={collectionId}
            value={collectionName}
            disabled={false}
            input={<Input id="select-input" name="select-input" />}
            onChange={handleCollection}
          >
            {collections &&
              collections.map(value => {
                return (
                  <MenuItem value={value.id} key={value.collectionName}>
                    {value.collectionName}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      )}
      <Box m={1}></Box>
      <Divider />
      <div className={classes.buttonGroup}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleCloseModal(false)}
        >
          {translator._('assignment.back')}
        </Button>
        <Button
          variant="contained"
          disabled={courseName != '' ? false : true}
          className={classes.continueButton}
          onClick={() => handleSubmit()}
        >
          {translator._('domain.change')}
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(CustomDomainCourseEdit)
