import React from 'react'
import styles from './style'
import { withStyles } from '@material-ui/core'
import propTypes from './prop'

import { useCardSettings } from 'src/hooks/useCardSettings'
import { QuestionFieldNames } from 'src/model/question'
import Alert from '@material-ui/core/Alert'

import SettingsIcon from '@material-ui/icons/Settings'
/**
 *
 * @param {*} props
 */
const ProtectionCard = props => {
  const { dispatch, entity } = props

  const settings = useCardSettings(
    entity[QuestionFieldNames.ID],
    'cardSettings',
  )

  const configured = settings ? JSON.parse(settings.value) : null

  return (
    <>
      {!configured && (
        <Alert
          severity="warning"
          sx={{
            '& .MuiAlert-icon': {
              alignItems: 'center',
            },
          }}
        >
          Set the password using the <SettingsIcon fontSize="small" /> icon in
          the upper-right corner. You can change it at any time.
        </Alert>
      )}
      {configured && configured.password == '' ? (
        <Alert
          severity="warning"
          sx={{
            '& .MuiAlert-icon': {
              alignItems: 'center',
            },
          }}
        >
          Set the password using the <SettingsIcon fontSize="small" /> icon in
          the upper-right corner. You can change it at any time.
        </Alert>
      ) : (
        configured && (
          <Alert severity="success">
            Password is set. Users can reach the rest of the content with the
            password.
          </Alert>
        )
      )}
    </>
  )
}

ProtectionCard.propTypes = propTypes

export default withStyles(styles)(ProtectionCard)
