import React, { useState } from 'react'
import { clsx } from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import AddIcon from '@material-ui/icons/Add'
import { Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CollectionEdit from 'src/components/collection-edit'
const AddNewCollection = props => {
  const { classes, open, setChatId } = props
  const dispatch = useDispatch()

  const [isCollectionEditOpen, setIsCollectionEditOpen] = useState(false)

  const handleOpen = () => {
    setIsCollectionEditOpen(true)
  }

  return (
    <>
      {isCollectionEditOpen && (
        <CollectionEdit
          dispatch={dispatch}
          handleCloseModal={() => setIsCollectionEditOpen(false)}
          isModalOpen={isCollectionEditOpen}
        />
      )}

      <div
        className={clsx(classes.drawer, {
          [classes.rootOpen]: open,
          [classes.rootClose]: !open,
        })}
        onClick={() => handleOpen()}
      >
        <div
          style={{
            width: '72px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AddIcon
            style={{
              color: '#637381',
            }}
          />
        </div>
        <Typography
          style={{
            display: open ? 'block' : 'none',
          }}
        >
          New Collection
        </Typography>
      </div>
    </>
  )
}

export default withStyles(styles)(AddNewCollection)
