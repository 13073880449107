import { put, call, take, fork } from 'redux-saga/effects'
import { generateQuizzesByCourseIdService } from 'src/api/assignment'
//import { questionPatchSuccess } from '../../reducers/question/action'
import { generateQuizzesByCourseIdSuccess } from 'src/reducers/assignment/action'
import { GENERATE_QUIZZES_BY_COURSE_ID } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* generateQuizzesByCourseIdFlow(payload) {
  try {
    const { courseId, callback } = payload

    const response = yield call(generateQuizzesByCourseIdService, courseId)
    yield put(generateQuizzesByCourseIdSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* generateQuizzesByCourseIdWatcher() {
  while (true) {
    const action = yield take(GENERATE_QUIZZES_BY_COURSE_ID)
    const { payload } = action

    yield fork(generalSaga, generateQuizzesByCourseIdFlow, payload, true)
  }
}
