import React from 'react'
import { Icon } from '@iconify/react'
import { TextField, Typography, Button, withStyles } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from '../../styles'
import { BackButton } from '..'
import WebhookUrl from '../data/webhook-url'
const StepTwo = props => {
  const {
    handleBack,
    videoUrl,
    entity,
    handleNext,
    paymentProvider,
    classes,
  } = props

  const [copiedShareCode, setCopiedShareCode] = React.useState(false)

  const webHookUrlExtension = WebhookUrl(paymentProvider)
  const webHookUrl = `${process.env.REACT_APP_URL}${webHookUrlExtension}`
  const onClickShareCode = () => {
    setCopiedShareCode(true)
    setTimeout(() => {
      setCopiedShareCode(false)
    }, 500)
  }

  return (
    <>
      <div className={classes.stepTwo_main}>
        <div className={classes.stepTwo_main_title}>
          <Icon
            icon="octicon:play-24"
            width="20px"
            height="20px"
            style={{ color: '#FD2222', marginRight: '5px' }}
          ></Icon>
          <Typography fontSize={'14px'} fontWeight={400}>
            Watch the video guide
          </Typography>
        </div>
        <iframe
          width="440"
          height="235"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={classes.iframeStyle}
        ></iframe>
        <div className={classes.stepTwo_main_title}>
          <Icon
            icon="ci:info"
            width="20px"
            height="20px"
            style={{ color: '#002E47', marginRight: '5px' }}
          ></Icon>
          <Typography fontSize={'14px'} fontWeight={400}>
            Go to webhook page and add this url like video guide
          </Typography>
        </div>
        <TextField
          variant="outlined"
          value={webHookUrl}
          style={{
            width: '440px',
          }}
        />
        <CopyToClipboard
          text={webHookUrl}
          className={classes.shareViewCopyButton}
        >
          <Button
            style={{ textTransform: 'none', background:"none" }}
            onClick={onClickShareCode}
            variant="outlined"
          >
            {copiedShareCode ? 'Copied!' : 'Copy to Clipboard'}
          </Button>
        </CopyToClipboard>
        <div className={classes.stepTwo_main_buttons}>
          <BackButton handleBack={handleBack} />
          <Button
            variant="contained"
            onClick={handleNext}
            className={classes.continueButton}
            disabled={webHookUrl.length === 0}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(StepTwo)
