import React from 'react'
import styles from './styles'
import { Typography, withStyles, Button } from '@material-ui/core'
import Badge from './badge'

const UpgradePopup = props => {
  const {
    upgradeButtonText,
    upgradeDescription,
    planName,
    onClose,
    classes,
  } = props
  return (
    <div className={classes.scormExportUpgradePopupContainer}>
      <img src="/assets/scorm-upgrade-rocket.png" height={'100%'}></img>
      <div className={classes.scormExportUpgradePopupContainer__right}>
        <div
          className={classes.scormExportUpgradePopupContainer__right__header}
        >
          <Typography fontSize={'20px'} fontWeight={600}>
            Upgrade to {planName}
          </Typography>
          <Badge />
        </div>
        {upgradeDescription && upgradeDescription}
        <div className={classes.buttons}>
          <Button variant="text" onClick={() => onClose()}>
            <Typography fontSize={'14px'} fontWeight={500} color={'#637381'}>
              Not Now
            </Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.upgradeButton}
            onClick={() => window.open('/pricing')}
          >
            {upgradeButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(UpgradePopup)
