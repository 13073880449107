const styles = theme => ({
  container: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    justifyContent: 'center',
  },
  certificateItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
  },
  certificateFrame: {
    padding: '20px 40px',
    outline: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E3F3FF',
    },
  },
  certificateImage: {
    borderRadius: '8px',
  },
  certificateText: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },
})

export default styles
