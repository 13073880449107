import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
//redux
import { useDispatch, useSelector } from 'react-redux'
import {
  assignmentGetAction,
  //assignmentReportAction,
} from 'src/reducers/assignment/action'
// @mui
import {
  Paper,
  Typography,
  Box,
  Tooltip,
  withStyles,
  Button,
} from '@material-ui/core'
// components
import KanbanCourseToolBar from './KanbanCourseToolBar'
import URL from 'src/api/url/api'
import styles from './styles'
import { IconButton } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

// ----------------------------------------------------------------------

KanbanCourseCard.propTypes = {
  course: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
}

function KanbanCourseCard(props) {
  const {
    course,
    courses,
    setIsUserReachedCourseLimit,
    isUserReachedCourseLimit,
    refresh,
    open,
    setOpen,
    classes,
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { name, coverImageUrl } = course

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const handleClick = course => {
    dispatch(
      assignmentGetAction(course.id, () => {
        window.scrollTo(0, 0)
        history.push('/mini-course/' + course.id)
      }),
    )
  }

  const [onHover, setOnHover] = React.useState(false)

  const coverImage =
    coverImageUrl === null
      ? 'https://cdn.minicoursegenerator.com/assets/gateway/course-cover.png'
      : coverImageUrl.includes('http')
      ? coverImageUrl
      : URL.CDN + coverImageUrl

  // DnD Kit
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
  } = useSortable({ id: `${course.id}-item` })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: 1000,
  }

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes}>
        <Paper
          onMouseEnter={() => {
            setOnHover(true)
          }}
          onMouseLeave={() => {
            setOnHover(false)
          }}
          className={classes.courseGalleryView}
          style={{
            outline: isDragging ? '1px solid #015FFB' : 'none',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${coverImage})`,
            }}
            className={classes.coverImage}
            onClick={() => handleClick(course)}
          >
            {onHover && (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    flexGrow: 1,
                  }}
                ></div>

                <Icon
                  {...listeners}
                  draggable
                  width={24}
                  height={24}
                  icon="eva:move-fill"
                  style={{
                    padding: '4px',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    margin: '16px',
                    borderRadius: '50%',
                  }}
                  //onMouseUp={() => onMouseUp()}
                />
              </div>
            )}
          </div>

          <Box
            sx={{
              cursor: 'pointer',
              padding: 1,
              paddingRight: 2,
              paddingLeft: 2,
            }}
            onClick={() => {
              handleClick(course)
            }}
          >
            <Tooltip
              title={course.name.length > 81 ? course.name : ''}
              placement="bottom"
            >
              <Typography
                style={{
                  whiteSpace: 'normal',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                noWrap
                variant="subtitle2"
                sx={{
                  transition: theme =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Box>
          <Box style={{ flexGrow: 1 }}></Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 16,
            }}
          >
            <KanbanCourseToolBar
              courses={courses}
              course={course}
              refresh={refresh}
              setIsUserReachedCourseLimit={setIsUserReachedCourseLimit}
              isUserReachedCourseLimit={isUserReachedCourseLimit}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </Paper>
      </div>
    </>
  )
}

export default withStyles(styles)(KanbanCourseCard)
