const styles = theme => ({
  addColumn: {
    padding: '16px 0px 32px',
    width: '200px',
  },
  addColumnHead: {
    width: '200px',
  },
  addColumnBottom: {
    width: '200px',
    paddingBottom: '20px',
  },
  mobile_view_alert: {
    position: 'absolute',
    top: '68px',
    width: '350px',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobile_view_box: {
    height: '40px',
  },
  header_bar: {
    display: 'flex',
    marginBottom: '16px',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: '#F4F6F8',
    borderRadius: '12px',
  },
})

export default styles
