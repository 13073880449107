import React, { useEffect, useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  Button,
  Box,
} from '@material-ui/core'

import { track } from '@amplitude/analytics-browser'

const StepperSkeleton = props => {
  const {
    activeStep,
    steps,
    handleStep,
    getStepContent,
    titleOptions,
    outlineOptions,
    sendTitleRequest,
    sendOutlineRequest,
    loading,
    classes,
  } = props
  const [hasTitleOptions, setHasTitleOptions] = useState(
    titleOptions && titleOptions.length > 0 && true,
  )
  const [hasOutlineOptions, setHasOutlineOptions] = useState(
    outlineOptions && outlineOptions.length >= 0 && true,
  )

  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel className={classes.actionsContainer}>
              <div className={classes.actionsContainer}>
                <Typography
                  onClick={handleStep(index)}
                  className={classes.stepperLabel}
                >
                  {label}
                </Typography>
              </div>
            </StepLabel>
            <StepContent className={classes.stepContent}>
              {index == activeStep &&
                !loading &&
                hasTitleOptions != false &&
                activeStep == 1 && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        sendTitleRequest('Generate More')
                        track('generate_more_title')
                      }}
                    >
                      Generate Again
                    </Button>
                  </div>
                )}
              {index == activeStep &&
                !loading &&
                hasTitleOptions != false &&
                activeStep == 2 && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        sendOutlineRequest('Generate More')
                        track('generate_more_outline')
                      }}
                    >
                      Generate Again
                    </Button>
                  </div>
                )}
              <Box m={0.5}></Box>
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  )
}

export default withStyles(styles)(StepperSkeleton)
