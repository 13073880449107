import moment from 'moment-timezone'
import ConverterStringToArray from './string-to-array'
import { get as getCookie, set as setCookie } from 'es-cookie'

require('moment/min/locales.min')

/**
 * @param value
 */
const ConverterNumberToDayFormat = function (value) {
  if (getCookie('language') !== undefined) {
    moment.locale(getCookie('language'))
  }

  if (value === undefined || value === null || value.length === 0) {
    return ''
  } else {
    value = ConverterStringToArray(value)
    let dayNames = value.map(
      day =>
        ' ' +
        moment()
          .weekday(getCookie('language') == 'tr' ? day - 1 : day)
          .format('dddd'),
    )

    return dayNames.toString()
  }
}

export default ConverterNumberToDayFormat
