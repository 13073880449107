import React from 'react'
import style from './style'
import propTypes from './prop'
import QuestionItem from './item'
import { withStyles, Grid, Divider, IconButton } from '@material-ui/core'
import SortableList, { SortableItem } from 'react-easy-sort'
import AddIcon from '@material-ui/icons/Add'
import {
  QuestionFieldNames,
  isAnswerItemTypeResultCard,
} from '../../model/question'

import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import CardSelectionPopup from './card-selection-popup/index'

import { startConnection } from 'src/utils/signalR-connection/signalR-connection'

import CreateQuizWithAi from 'src/pages/create-quiz-with-ai'

const QuestionList = props => {
  const {
    dispatch,
    list,
    entity,
    onAdd,
    onMove,
    onRemove,
    content,
    showSortOrder,
    refresh,
    onDragEnd,
    classes,
    courseId,
    courseCode,
    baseEntity,
    onAddCard,
    page,
    setPage,
    setThemeDrawerOpen,
    listingType,
    sortCard,
    onDelete,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectWidgetOpen, setSelectWidgetOpen] = React.useState(null)
  const [lastClickTime, setLastClickTime] = React.useState(Date.now())
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ])
  const [items, setItems] = React.useState([1, 2, 3, 4, 5, 6])
  const onSortEnd = (oldIndex, newIndex) => {}

  const [editor, setEditor] = React.useState(null)
  const [cardStartYPoint, setCardStartYPoint] = React.useState([])
  const [scrollPosition, setScrollPosition] = React.useState(0)

  const cardStartYPointCreator = list => {
    const currentScrollPos = window.pageYOffset

    window.scrollTo(0, 0)
    let cardStartYPoint = []

    list.forEach((value, index) => {
      const dom = document.getElementById(`card-${value.id}`)

      cardStartYPoint.push(dom.getBoundingClientRect().top - 60)
    })

    setCardStartYPoint(cardStartYPoint)
    window.scrollTo(0, currentScrollPos)
  }

  React.useEffect(() => {
    if (list && list.length > 0) {
      cardStartYPointCreator(list)
    }
  }, [list.length])

  React.useEffect(() => {
    const handleScroll = () => {
      //let holderTop = document.getElementById(`card-holder`).getBoundingClientRect().top;
      let holderTop = window.scrollY
      setScrollPosition(holderTop)
    }

    window.addEventListener('scrollend', handleScroll)
    return _ => {
      window.removeEventListener('scrollend', handleScroll)
    }
  }, [])

  const setNavigationPage = (page, scroll) => {
    setPage(page, scroll)
  }

  React.useEffect(() => {
    if (list.length == 0) {
      return
    }
    let scrolledPage = page

    let listofStartYPoint = cardStartYPoint.map((value, index) => {
      if (value > scrollPosition) return value
    })

    let scrolledIndex =
      listofStartYPoint.length -
      listofStartYPoint.filter(x => x != undefined).length

    // Handle for bottom of page
    if (scrolledIndex != 0 && scrolledIndex == listofStartYPoint.length) {
      scrolledIndex = listofStartYPoint.length - 1
    }

    scrolledPage = list[scrolledIndex][QuestionFieldNames.ID]
    if (page != scrolledPage) {
      setNavigationPage(scrolledPage, false)
    }
  }, [scrollPosition])

  React.useEffect(() => {
    //Start socket connection for media update
    startConnection()
  }, [])

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  React.useEffect(() => {
    setEditor()
  }, [page])

  /**
   *
   * @param {*} list
   */
  const onAddItem = list => {
    onAdd(content, list)
  }

  const handleChange = (editor, value) => {
    //setPage(value)
    setLastClickTime(Date.now())
    setEditor(editor)
  }

  const addCardAction = event => {
    const component = new ComponentBoxContent(
      (
        <CardSelectionPopup
          dispatch={dispatch}
          onAddCard={list => {
            onAddCard(list, sortCard)
          }}
          type="end"
          section={entity[QuestionFieldNames.SECTION]}
        />
      ),
      {
        hideCloseButton: false,
      },
    )

    dispatch(componentBoxShowAction(component))
  }

  const openAiPopup = event => {
    const component = new ComponentBoxContent(
      <CreateQuizWithAi entity={entity} type="end" />,
      {
        hideCloseButton: false,
      },
    )

    dispatch(componentBoxShowAction(component))
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectWidgetOpen(false)
  }
  /* 
  const sortCard = (newEntity, hasFinishCard) => {
    handleClose()
    //reOrderToNewAdded(cardCount, index + 1, newEntity)
    //setPage(index + 2)
  } */

  const isLastCardFinishCard =
    list.length > 0 &&
    isAnswerItemTypeResultCard(
      list[list.length - 1][QuestionFieldNames.QUESTION_TYPE],
    )

  return (
    <div className={classes.cardListView}>
      <div className={classes.cardListContainer}>
        <div className={classes.cardListHolder}>
          <div>
            <SortableList
              className="list"
              draggedItemClassName="dragged"
              allowDrag={false}
            >
              <Grid container alignItems="center" key="list">
                {list.length > 0 ? (
                  <>
                    {list.map((item, index) => (
                      <SortableItem key={'Sortable' + item.id}>
                        <div
                          className={classes.front}
                          key={'QuestionItemHolder' + index}
                        >
                          <QuestionItem
                            dispatch={dispatch}
                            entity={item}
                            index={index}
                            id={index}
                            onMove={onMove}
                            onClick={editor => {
                              handleChange(editor, index + 1)
                            }}
                            cardCount={list?.length}
                            onDrag={(prev, next) => {
                              onDragEnd(prev, next)
                              handleChange(null, next + 1)
                            }}
                            onRemove={onRemove}
                            refresh={refresh}
                            showSortOrder={showSortOrder}
                            courseId={courseId}
                            baseEntity={baseEntity}
                            onAddCard={onAddCard}
                            sortCard={sortCard}
                            page={page}
                            setPage={setPage}
                            lastCardFinish={
                              list[list.length - 1][
                                QuestionFieldNames.QUESTION_TYPE
                              ] >= 12 &&
                              list[list.length - 1][
                                QuestionFieldNames.QUESTION_TYPE
                              ] < 17
                            }
                            onDelete={onDelete}
                          />
                        </div>
                      </SortableItem>
                    ))}
                  </>
                ) : (
                  <div className={classes.emptySlot}></div>
                )}
              </Grid>
            </SortableList>
          </div>
        </div>
      </div>
      {/*  {list.length > 0 && (
        <div className={classes.paginationHolder}>
          <Pagination
            count={list.length}
            className={classes.pagination}
            page={page}
            onChange={handleChange}
          />
        </div>
      )} */}
    </div>
  )
}

QuestionList.propTypes = propTypes

export default withStyles(style)(QuestionList)
