import React from 'react'
// @mui
import { styled } from '@material-ui/core/styles'
import { Grid, RadioGroup, CardActionArea } from '@material-ui/core'
// hooks
//
import Iconify from 'src/components/Iconify'

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}))

// ----------------------------------------------------------------------

export default function SettingDirection(props) {
  const { classes, cardAlignment, onChangeCardAlignment } = props

  return (
    <RadioGroup name="themeDirection" value={cardAlignment}>
      <Grid
        dir="top"
        container
        spacing={2.5}
      >
        {['top', 'center'].map((alignment, index) => {
          const isSelected = cardAlignment === alignment

          return (
            <Grid
              key={alignment}
              item
              xs={3}
              onClick={() => onChangeCardAlignment(alignment)}
            
            >
              <BoxStyle
                className={isSelected ? classes.selectedGrid : ''}
                sx={{
                  ...(isSelected && {
                    color: 'primary.main',
                  }),
                }}
              >
                <img
                  src={`/assets/alignment_${alignment}.png`}
                  alt=""
                
                />
              </BoxStyle>
            </Grid>
          )
        })}
      </Grid>
    </RadioGroup>
  )
}
