import React, { useState } from 'react'
import styles from '../styles'
import withStyles from '@material-ui/core/styles/withStyles'
import { alpha, styled } from '@material-ui/core/styles'
import {
  Grid,
  IconButton,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'

function Features(props) {
  const {
    produceType,
    setProduceType,
    questionType,
    setQuestionType,
    maxQuestions,
    setMaxQuestions,
    titleList,
    setTitleList,
    setTitle,
    title,
    roleType,
    classes,
  } = props
  const handleProduceType = (event, index) => {
    switch (index) {
      case 0:
        setQuestionType(event.target.value)
        break
      case 1:
        setMaxQuestions(event.target.value)
        break
      default:
    }
  }
  const SelectStyled = styled(Select)(({ theme }) => ({
    '& .MuiInputBase-input': {
      paddingBottom: '18px',
    },
  }))

  const selectionData =
    roleType != 1
      ? [
          {
            selectionName: 'Question Type',
            selectionOptions: [
              'Multiple Choice',
              'True/False',
              'Short Answer',
              'Fill in the Blank',
            ],
            value: questionType,
          },
          {
            selectionName: 'Max. Questions',
            selectionOptions: ['3', '5', '10', '15', '20', '25', '30'],
            value: maxQuestions,
          },
        ]
      : [
          {
            selectionName: 'Question Type',
            selectionOptions: [
              'Multiple Choice',
              'True/False',
              'Short Answer',
              'Fill in the Blank',
            ],
            value: questionType,
          },
          {
            selectionName: 'Max. Questions',
            selectionOptions: [
              '3',
              '5 (Paid)',
              '10 (Paid)',
              '15 (Paid)',
              '20 (Paid)',
              '25 (Paid)',
              '30 (Paid)',
            ],
            value: maxQuestions,
          },
        ]

  return (
    <div style={{ marginTop: 8, width: '100%' }}>
      {selectionData.map((item, index) => (
        <div
          style={{ display: 'flex', alignContent: 'center', marginBottom: 8 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: '14px',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              {item.selectionName}
            </Typography>
          </div>
          <FormControl variant="filled" style={{ width: '50%' }} size="small">
            <SelectStyled
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={item.value}
              onChange={event => handleProduceType(event, index)}
            >
              {item.selectionOptions.map((item, index) => (
                <MenuItem disabled={item.includes('Paid')} value={item}>
                  {item}
                </MenuItem>
              ))}
            </SelectStyled>
          </FormControl>
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(Features)
