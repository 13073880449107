import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_ERROR,
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_ERROR,
  PATCH_CERTIFICATE,
  PATCH_CERTIFICATE_SUCCESS,
  PATCH_CERTIFICATE_ERROR,
  GET_CERTIFICATE_LIST,
  GET_CERTIFICATE_LIST_SUCCESS,
  GET_CERTIFICATE_LIST_ERROR,
} from './type'

/**
 *
 * @param {*} collectionId
 */
export const createCertificateAction = (collectionId, callback) => {
  return {
    type: CREATE_CERTIFICATE,
    payload: { collectionId, callback },
  }
}

export const createCertificateSuccess = response => {
  return {
    type: CREATE_CERTIFICATE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 * @param {*} error
 */
export const createCertificateError = error => {
  return {
    type: CREATE_CERTIFICATE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} collectionId
 * @param {*} callback
 */

export const getCertificateAction = (collectionId, callback) => {
  return {
    type: GET_CERTIFICATE,
    payload: { collectionId, callback },
  }
}

export const getCertificateSuccess = response => {
  return {
    type: GET_CERTIFICATE_SUCCESS,
    payload: { response },
  }
}

/**
 *
 *
 * @param {*} error
 */
export const getCertificateError = error => {
  return {
    type: GET_CERTIFICATE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} content
 * @param {*} callback
 */

export const patchCertificateAction = (content, callback) => {
  return {
    type: PATCH_CERTIFICATE,
    payload: { content, callback },
  }
}

export const patchCertificateSuccess = content => {
  return {
    type: PATCH_CERTIFICATE_SUCCESS,
    payload: { content },
  }
}

/**
 *
 * @param {*} error
 */
export const patchCertificateError = error => {
  return {
    type: PATCH_CERTIFICATE_ERROR,
    payload: { error },
  }
}

/**
 *
 * @param {*} callback
 * @param {*} selectedCollectionId
 */
export const getCertificateListAction = (selectedCollectionId, callback) => {
  return {
    type: GET_CERTIFICATE_LIST,
    payload: { selectedCollectionId, callback },
  }
}

export const getCertificateListSuccess = response => {
  return {
    type: GET_CERTIFICATE_LIST_SUCCESS,
    payload: { response },
  }
}

/**
 *
 * @param {*} error
 */
export const getCertificateListError = error => {
  return {
    type: GET_CERTIFICATE_LIST_ERROR,
    payload: { error },
  }
}
