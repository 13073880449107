const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  image__content: {
    width: '100%',
    height: '200px',
    display: 'flex',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '16px 16px 0px 0px',
    position: 'relative',
  },
  image_logo_content:{
    width: '118px',
    height: '118px',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '16px',
    position: 'relative',
  },
  deleteIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    padding: '24px',
    backgroundColor: '#fff',
    borderRadius: '0px 0px 16px 16px',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    justifyContent: 'center',
  },
  logoContainer: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    height: '120px',
    width: '120px',
    border: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoText: {
    color: '#919EAB',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '137px',
    justifyContent: 'space-between',
  },
  collectionName: {
    color: '#002E47',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  viewButton: {
    height: '36px',
    color: '#637381',
  },
  shareButton: {
    height: '36px',
    backgroundColor: '#015FFB',
    boxShadow: 'none',
  },
  file__upload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '128px',
    cursor: 'pointer',
    opacity: '0',
  },
  image__remove: {
    position: 'absolute',
    right: 8,
    top: 8,
    width: 24,
    height: 24,
    borderRadius: '1rem',
    opacity: 0,
    backgroundColor: '#ffffff29',
    transition: '0.2s linear all',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0.3,
    zIndex: 999,
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
  image__img: {
    width: '100%',
    zIndex: '2',
    borderRadius: '16px',
  },
})

export default styles
