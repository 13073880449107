import { put, call, take, fork } from 'redux-saga/effects'
import { addDomainService } from '../../api/domain'
import { addCustomDomainSuccess } from '../../reducers/domain/action'
import { ADD_CUSTOM_DOMAIN } from '../../reducers/domain/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* domainAddFlow(payload) {
  try {
    const { entity, callback } = payload

    const response = yield call(addDomainService, entity)
    yield put(addCustomDomainSuccess(response))
    yield put(operationSuccess())

    callback()
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* domainAddWatcher() {
  while (true) {
    const action = yield take(ADD_CUSTOM_DOMAIN)
    const { payload } = action

    yield fork(generalSaga, domainAddFlow, payload)
  }
}
