import React from 'react'
import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react'
import translator from 'src/translate'
//redux
import { useDispatch } from 'react-redux'
import { assignmentGetAction } from 'src/reducers/assignment/action'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
// @mui
import { Stack, MenuItem, IconButton, Tooltip } from '@material-ui/core'
// components
import Iconify from 'src/components/Iconify'
import MenuPopover from 'src/components/MenuPopover'
import ComponentBoxContent from 'src/model/component-box'
import ShareLinkStep from 'src/pages/create-mini-course/share-link'
import EmbedStep from 'src/pages/create-mini-course/embed'
import CarouselView from 'src/pages/create-mini-course/carousel-view'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import NamingModal from '../naming-modal'
import { AssignmentTypes } from 'src/model/assignment'
import {
  assignmentCloneAction,
  assignmentDeleteAction,
} from 'src/reducers/course-collection/action'
import { assignmentReportAction } from 'src/reducers/assignment/action'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'
import { track } from '@amplitude/analytics-browser'
import LimitReachedModal from 'src/components/limit-reached-modal'
import BarChartIcon from '@material-ui/icons/BarChart'
import { useHistory } from 'react-router-dom'

// ----------------------------------------------------------------------

import URL from 'src/api/url'

KanbanCourseToolBar.propTypes = {
  course: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
}

export default function KanbanCourseToolBar({
  course,
  courses,
  refresh,
  columnName,
  classes,
  id,
  setIsUserReachedCourseLimit,
  isUserReachedCourseLimit,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const renameRef = useRef(null)
  const [isNamingModalOpen, setNamingModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const [open, setOpen] = useState(null)

  useEffect(() => {
    if (open) {
      if (renameRef.current) {
        renameRef.current.focus()
      }
    }
  }, [open])

  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const duplicateCourse = course => {
    if (isUserReachedCourseLimit) {
      setModalOpen(true)
    } else {
      dispatch(assignmentCloneAction(course.code))
    }
  }
  const courseView = course => {
    window.open(URL.APP_URL + course.code, course.name, 'height=800,width=600')
  }
  const editCourse = course => {
    dispatch(
      assignmentGetAction(course.id, () => {
        history.push('/mini-course/' + course.id)
      }),
    )
  }

  const shareLinkCourse = course => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <ShareLinkStep dispatch={dispatch} entity={course} />,
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }
  const shareEmbedCourse = course => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          <EmbedStep dispatch={dispatch} entity={course} />,
          {
            hideCloseButton: false,
            backgroundColor: 'transparent',
          },
        ),
      ),
    )
  }

  const deleteCourse = course => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.delete'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              () => {
                dispatch(
                  assignmentDeleteAction(course.id, () => {
                    setIsUserReachedCourseLimit(false)
                    dispatch(messageBoxHideAction())
                    refresh()
                  }),
                )
              },
            ),
          ],
        ),
      ),
    )
  }

  const handleGetAnalytics = course => {
    dispatch(assignmentReportAction(course.id))
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Tooltip title="Analytics">
          <IconButton
            size="small"
            onClick={() => {
              handleGetAnalytics(course)
            }}
          >
            <BarChartIcon style={{ color: 'rgb(0,46,71)' }} />
          </IconButton>
        </Tooltip>
        <IconButton size="small" onClick={handleOpen}>
          <Iconify
            icon={'eva:more-vertical-fill'}
            style={{ color: 'rgb(0,46,71)' }}
            width={20}
            height={20}
          />
        </IconButton>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            editCourse(course)
          }}
        >
          {translator._('courseCollection.course.edit')}
        </MenuItem>
        <MenuItem>
          <a
            href={URL.APP_URL + course.code}
            target="_blank"
            style={{
              color: '#000',
              textDecoration: 'none',
            }}
          >
            {translator._('courseCollection.course.preview')}
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            duplicateCourse(course)
          }}
        >
          {translator._('courseCollection.course.duplicate')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            shareLinkCourse(course)
          }}
        >
          Share Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            shareEmbedCourse(course)
            track('Course_Share', {
              type: 'Embed',
            })
          }}
        >
          Embed
        </MenuItem>
        {/*<MenuItem
          onClick={() => {
            shareCarousel(course)
          }}
        >
          Carousel
        </MenuItem>*/}
        <MenuItem
          onClick={() => {
            deleteCourse(course)
          }}
        >
          {translator._('courseCollection.course.delete')}
        </MenuItem>
      </MenuPopover>
      {isNamingModalOpen && (
        <NamingModal
          classes={classes}
          dispatch={dispatch}
          id={id}
          courseEntity={course}
          history={history}
          handleCloseModal={() => setNamingModalOpen(false)}
          isModalOpen={isNamingModalOpen}
          collectionName={columnName}
          selectedAssignmentType={AssignmentTypes.ONETIME}
        />
      )}
      {modalOpen && (
        <LimitReachedModal
          classes={classes}
          handleCloseModal={() => setModalOpen(false)}
          isModalOpen={modalOpen}
          history={history}
        />
      )}
    </>
  )
}
