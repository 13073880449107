import React, { useState } from 'react'
import { Typography, Button } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useDispatch } from 'react-redux'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { withStyles } from '@material-ui/core'
import data from './data'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './styles'

const DynamicAttributes = props => {
  const { classes } = props
  const dispatch = useDispatch()
  const [copiedIndex, setCopiedIndex] = useState(null)

  const hide = () => {
    dispatch(componentBoxHideAction())
  }

  const handleCopy = index => {
    setCopiedIndex(index)
    setTimeout(() => {
      setCopiedIndex(null)
    }, 1000)
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Dynamic Attributes</Typography>
        <Icon icon="uiw:close" onClick={hide} className={classes.icon} />
      </div>
      <div className={classes.contentContainer}>
        {data.map((item, index) => (
          <div key={index} className={classes.itemContainer}>
            <Typography className={classes.itemTitle}>{item.title}</Typography>
            <Typography className={classes.itemInfo}>{item.info}</Typography>
            <CopyToClipboard text={item.info} onCopy={() => handleCopy(index)}>
              <Button className={classes.button}>
                <Typography>
                  {copiedIndex === index ? 'Copied' : item.buttonText}
                </Typography>
              </Button>
            </CopyToClipboard>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(DynamicAttributes)
