const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    borderRadius: '8px',
    padding: '0px 16px',
    height: '56px',
  },
  input: {
    backgroundColor: '#fff',
  },
  textField: {
    width: '66px',
    height: '38px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    '& label': {},
    '& label.Mui-focused': {},

    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#015FFB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#015FFB',
      },
    },
  },
  heading: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
  },
})

export default styles
