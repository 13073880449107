import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from 'react-redux'
import { createCertificateAction } from 'src/reducers/certificate/action'
import styles from './styles'
const CreateCertificate = props => {
  const { classes, setCertificateExist } = props
  const dispatch = useDispatch()
  const selectedCollectionId = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )
  const handleCreateCertificate = () => {
    dispatch(
      createCertificateAction(selectedCollectionId, () => {
        setCertificateExist(true)
      }),
    )
  }
  return (
    <div className={classes.certificationContainer}>
      <Typography className={classes.title}>Certification</Typography>
      <Typography className={classes.description}>
        Award learners a digital certificate when they complete a collection or
        pass the success threshold.
      </Typography>

      <div className={classes.content}>
        <img
          src="assets/empty-certificate-icon.png"
          width="144px"
          height="96px"
          alt="empty-certificate-icon"
        />
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleCreateCertificate}
        >
          <Icon
            icon="material-symbols:add-rounded"
            fontSize={20}
            color="#002E47"
          />
          <Typography className={classes.buttonText}>
            Create Certificate
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(CreateCertificate)
