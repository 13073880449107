import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './badgeGridStyles'
import { useCourseSettings, useCourse } from 'src/hooks/useCourseSettings'
import URL from 'src/api/url/api'
import { useSelector } from 'react-redux'
const SETTING_KEY = 'general'
const BadgeGrid = ({
  badges,
  userControl,
  selectedBadge,
  handleSelectedBadge,
  handlePopupOpen,
  ribbonText,
  content,
  classes,
}) => {
  const [controlledLogo, setControlledLogo] = useState('')
  const placeholderLogoUrl =
    'https://cdn.minicoursegenerator.com/test/images/mehmetakif1636638891/a68920bd-189c-49d7-ae13-2a2027524aba.png'

  const mcgLogo =
    'https://cdn.minicoursegenerator.com/production/images/emir637831896232284552/df470150-76e1-454f-ae18-bec54b02a19a.png'
  const course = useSelector(state => state.assignment.entity)
  const courseGeneralSetting = course.settingDtos.find(
    setting => setting.key === SETTING_KEY,
  )
  const courseLogo =
    courseGeneralSetting && JSON.parse(courseGeneralSetting.value).logo
  useEffect(() => {
    if (userControl == 'Regular Admin Account') {
      setControlledLogo(mcgLogo)
    } else {
      if (courseLogo != '' && courseLogo != null) {
        setControlledLogo(`${URL.MEDIA}` + courseLogo)
      } else {
        setControlledLogo(placeholderLogoUrl)
      }
    }
  }, [course])

  return (
    <div className={classes.badgeGrid}>
      {badges.length > 0 &&
        badges.map((item, index) => {
          let viewItem = item
          if (
            userControl === 'Regular Admin Account' &&
            index !== 0 &&
            viewItem.key !== 'badgeFail' &&
            viewItem.key !== 'badge9'
          ) {
            viewItem = {
              ...badges.find(badge => badge.key === 'badge9'),
              key: 'template',
            }
          }
          return (
            <div
              key={viewItem.number}
              onClick={() =>
                userControl === 'Regular Admin Account'
                  ? handlePopupOpen()
                  : handleSelectedBadge(viewItem.id)
              }
              style={{
                display:
                  viewItem.key === 'badgeFail' || viewItem.key === 'badge9'
                    ? 'none'
                    : 'flex',
                outline:
                  viewItem.id === selectedBadge ? '1px solid #015FFB' : 'none',
                backgroundColor:
                  viewItem.id === selectedBadge ? '#fff' : 'transparent',
              }}
              className={classes.badgeItem}
            >
              <div
                className={classes.innerHtmlContainer}
                dangerouslySetInnerHTML={{
                  __html: viewItem.value
                    .replaceAll('{ribbonText}', ribbonText)
                    .replaceAll('{title}', content)
                    .replaceAll('{logo}', controlledLogo),
                }}
              />
            </div>
          )
        })}
    </div>
  )
}

export default withStyles(styles)(BadgeGrid)
