import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
import {
  TextField,
  Typography,
  Button,
  withStyles,
  InputAdornment,
} from '@material-ui/core'
import styles from '../../styles'
import { useDispatch } from 'react-redux'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
const StepZero = props => {
  const {
    entity,
    videoUrl,
    handleNext,
    paymentLink,
    setPaymentLink,
    classes,
  } = props
  const dispatch = useDispatch()
  const validatePaymentLink = text => {
    const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/
    if (urlPattern.test(text)) {
      return true
    } else {
      return false
    }
  }
  const [isValidUrl, setIsValidUrl] = React.useState(validatePaymentLink(paymentLink))

  const onClick = event => {
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(paymentLink, 'paymentLink', PatchData.OPERATION_REPLACE),
    )
    dispatch(
      patchCollectionSettingsAction(patchSource, response => {
        handleNext()
      }),
    )
  }

  const onChangeText = event => {
    const validUrl = validatePaymentLink(event.target.value)
    setPaymentLink(event.target.value)
    if (validUrl) {
      setIsValidUrl(true)
    } else {
      setIsValidUrl(false)
    }
  }
  return (
    <div className={classes.stepZero_main}>
      <div className={classes.stepZero_main_title}>
        <Icon
          icon="octicon:play-24"
          width="20px"
          height="20px"
          style={{ color: '#FD2222', marginRight: '5px' }}
        ></Icon>
        <Typography fontSize={'14px'} fontWeight={400}>
          Watch the video guide
        </Typography>
      </div>
      <iframe
        width="440"
        height="235"
        src={videoUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className={classes.iframeStyle}
      ></iframe>
      <TextField
        label="Paste payment link here"
        variant="outlined"
        value={paymentLink}
        error={!isValidUrl && paymentLink != null}
        helperText={!isValidUrl && paymentLink != null && 'Please enter valid url'}
        fullWidth
        onChange={onChangeText}
        className={classes.paymentLinkTextfield}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!isValidUrl ? (
                <Icon
                  icon="ic:round-info"
                  color="#ED4245"
                  width="20px"
                  height="20px"
                ></Icon>
              ) : (
                <Icon
                  icon="lets-icons:check-fill"
                  color="#35CE64"
                  width="20px"
                  height="20px"
                ></Icon>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        className={classes.continueButton}
        onClick={onClick}
        disabled={
          paymentLink === undefined ||
          paymentLink === null ||
          paymentLink.length == 0 ||
          !isValidUrl
        }
      >
        <Typography fontSize="14px" fontWeight={400}>
          Continue
        </Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(StepZero)
