import React from 'react'
import Html from 'react-pdf-html'
import { QuestionFieldNames } from 'src/model/question'
import URL from 'src/api/url'

import ReactPDF, {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Svg,
  Path,
  G,
  Circle,
} from '@react-pdf/renderer'
import { Card } from '@material-ui/core'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card, buttonColorRgb } = props
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'T',
    'U',
    'V',
    'Y',
    'Z',
  ]
  return (
    <View style={stylesheet.freeFormView}>
      {page !== 0 &&
        card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('gif') && (
          <Image
            style={stylesheet.freeFormContentImage}
            src={
              card.mediaDtos[0].mediaUrl.includes('https')
                ? card.mediaDtos[0].mediaUrl
                : URL.CDN + card.mediaDtos[0].mediaUrl +  '?not-from-cache-please2'
            }
          />
        )}
      <View style={stylesheet.freeFormBg}>
        <Svg viewBox="0 0 300 400">
          <G>
            <Path
              fill={buttonColorRgb}
              d="M0,0c0,52.3,0,231.3,0,248.5c31.4,24.2,73.3,41,120.3,46.5c15.9,40.6,79.4,55.3,79.4,55.3
		s-29.9-23.9-29.1-53.8c50-2.8,95.1-18.2,129.4-41.9V0H0z"
            />
          </G>
        </Svg>
      </View>
      <View style={stylesheet.freeFormText}>
        <Html stylesheet={stylesheet} style={style}>
          {editor && editor.getContents && page !== 0
            ? editor.getContents()
            : card.text}
        </Html>
      </View>
    </View>
  )
}

export default Inform
