import { isMobile } from 'react-device-detect'

const styles = theme => ({
  settingsContainer: {
    //minHeight: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(0),
    maxWidth: 500,
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:"center",
    height:50
  },
  pdfViewer:{
    paddingRight: theme.spacing(3),

  },
  header: {
    lineHeight:30

  },
  title:{
    fontSize: 16
  },
  closeButton:{

  }
})

export default styles
