import React, { useEffect, useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'
import { Typography, Box, Divider } from '@material-ui/core'

import moment from 'moment-timezone'

const CardHeader = props => {
  const { limit, count, refreshesAt, purpose, activeStep, classes } = props
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState(true)

  useEffect(() => {
    refreshesAt.includes('2022') && setRefreshDateAvaliable(false)
  }, [refreshesAt])

  return (
    <>
      <Box className={classes.cardHeader}>
        <span className={classes.cardTitle}>
          {' '}
          <img src="/assets/blue-ai-icon.png" className={classes.headerIcon} />
          <Typography
            variant="h5"
            color="primary"
            className={classes.headerText}
          >
            AI Assistant for Course Creation
          </Typography>
        </span>

        {purpose == 'Carousel' && (activeStep == 0 || activeStep == 5) && (
          <div className={classes.creditHolder}>
            <Typography className={classes.limitText}>
              <span className={classes.limitTextSpan}>{limit - count}</span>{' '}
              credits left.
            </Typography>
            {refreshDateAvaliable ? (
              <Typography className={classes.limitTextRenewal}>
                Renewal date {moment(refreshesAt).add('days', 30).format('L')}
              </Typography>
            ) : (
              <Typography className={classes.limitTextRenewal}>
                Renewed every month
              </Typography>
            )}
          </div>
        )}
        {(purpose == 'Link' || purpose == 'Embed' || purpose == undefined) &&
          (activeStep == 0 || activeStep == 6) && (
            <div className={classes.creditHolder}>
              <Typography className={classes.limitText}>
                <span className={classes.limitTextSpan}>{limit - count}</span>{' '}
                credits left.
              </Typography>
              {refreshDateAvaliable ? (
                <Typography className={classes.limitTextRenewal}>
                  Renewal date {moment(refreshesAt).add('days', 30).format('L')}
                </Typography>
              ) : (
                <Typography className={classes.limitTextRenewal}>
                  Renewed every month
                </Typography>
              )}
            </div>
          )}
      </Box>
      <Divider className={classes.dividerLine}></Divider>
    </>
  )
}

export default withStyles(styles)(CardHeader)
