/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import translator from 'src/translate'
import { assignmentAddAction } from 'src/reducers/assignment/action'
import {
  AssignmentFieldNames,
  AssignmentSchema,
  AssignmentTypes,
} from 'src/model/assignment'
import { assignmentPatchAction } from 'src/reducers/assignment/action'
import { PatchContent, PatchData } from 'src/model/patch'
import {
  Button,
  Modal,
  TextField,
  Typography,
  withStyles,
  OutlinedInput,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './styles'
import { mediaImageUploadAction } from 'src/reducers/media-image/action'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import URL from 'src/api/url/api'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
import { track } from '@amplitude/analytics-browser'
import { useCourseSettings } from 'src/hooks/useCourseSettings'
import CountrySelect from './country-select'
import { useCourse } from 'src/hooks/useCourseSettings'
import { Icon } from '@iconify/react'
const SETTING_KEY = 'general'

const NamingModal = props => {
  const {
    dispatch,
    handleCloseModal,
    classes,
    isModalOpen,
    selectedAssignmentType,
    id,
    collectionName,
    courseEntity,
    courses,
    coverImageUrlParent,
    setCoverUrl,
    history,
  } = props

  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const [assignmentTitle, setAssignmentTitle] = React.useState(
    courseEntity ? courseEntity.name : '',
  )
  const [assignmentCoverPageText, setAssignmentCoverPageText] = React.useState(
    courseEntity ? value.coverPageText : null,
  )

  const [coverPage, setCoverPage] = React.useState(
    courseEntity ? value?.coverPage : null,
  )

  const [logo, setLogo] = React.useState(courseEntity ? value.logo : null)
  const [coverImageUrl, setCoverImageUrl] = React.useState(
    coverImageUrlParent != null
      ? coverImageUrlParent.includes('https')
        ? coverImageUrlParent
        : URL.CDN + coverImageUrlParent
      : null,
  )

  const languageOptions = window.CONSTANTS.LANGUAGES

  const [languageSelection, setLanguageSelection] = React.useState(
    languageOptions.find(x => x.countryCode === 'en'),
  )
  const onCoverUpload = event => {
    dispatch(
      mediaImageUploadAction(event.target.files[0], '', result => {
        setCoverImageUrl(`${URL.CDN}${result.url}`)
      }),
    )
  }
  const fileInputRef = useRef(null)

  const handleSaveCourse = () => {
    handleSaveCourseSettings()
    const patchSource = new PatchData(
      null,
      courseEntity[AssignmentFieldNames.ID],
    )
    patchSource.addContent(
      new PatchContent(
        assignmentTitle,
        AssignmentFieldNames.NAME,
        PatchData.OPERATION_REPLACE,
      ),
    )

    coverImageUrl == undefined
      ? setCoverUrl(coverImageUrlParent)
      : patchSource.addContent(
          new PatchContent(
            coverImageUrl ? coverImageUrl : null,
            AssignmentFieldNames.COVERIMAGEURL,
            PatchData.OPERATION_REPLACE,
          ),
        )

    dispatch(
      assignmentPatchAction(patchSource, () => {
        handleCloseModal(true)
        coverImageUrl != undefined && setCoverUrl(coverImageUrl)
      }),
    )
  }

  const handleSaveCourseSettings = () => {
    const variables = {
      ...value,
      logo: logo,
      coverPage: coverPage,
      coverPageText: assignmentCoverPageText,
    }

    if (!exists) {
      // create a setting entity with execute function
      execute(variables, {})
    } else {
      update(variables, {})
    }
  }
  const handleCreateButton = () => {
    const courseCollectionOrder = courses ? courses.length : 0
    dispatch(
      assignmentAddAction(
        {
          ...AssignmentSchema(),
          [AssignmentFieldNames.NAME]: assignmentTitle,
          [AssignmentFieldNames.IS_PERIODIC]: AssignmentTypes.ONETIME,
          coverPageText: assignmentCoverPageText,
          [AssignmentFieldNames.COVERIMAGEURL]: coverImageUrl,
          CourseCollectionId: id === DEFAULT_COLLECTION_GUID ? null : id,
          CourseCollectionOrder: courseCollectionOrder,
          CountryCode: languageSelection.countryCode,
        },
        window.history,
        entity => {
          track('Course Created', {
            courseId: entity.id,
          })
          history.push('/mini-course/' + entity.id)

          //window.location.href = '/mini-course/' + entity.id
        },
      ),
    )
  }
  const handleTextFieldChange = e => {
    setAssignmentTitle(e.target.value)
  }
  const handleTextFieldChangeCoverText = e => {
    setAssignmentCoverPageText(e.target.value)
  }

  const isTextFieldValid = () => {
    if (courseEntity || assignmentTitle.length >= 1) {
      return true
    }
    return false
  }
  const handleEditIconClick = () => {
    fileInputRef.current.click() // Programmatically click the hidden file input
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => handleCloseModal()}
      className={classes.modal}
    >
      <div className={classes.namingModalContent}>
        <div className={classes.headerGroup}>
          <Typography className={classes.headerText}>
            {courseEntity
              ? `Edit Cover`
              : `${translator._('assignment.createOneTime')}`}
          </Typography>
        </div>

        <div className={classes.root__bg}>
          <div className={classes.root__section}>
            <div className={classes.image__bg}>
              {!coverImageUrl && (
                <div className={classes.image__icon}>
                  <AddPhotoAlternateIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                  <span className={classes.image__span}>
                    Change Cover Image
                  </span>
                </div>
              )}
              <div
                style={{
                  backgroundImage: coverImageUrl && `url(${coverImageUrl})`,
                }}
                className={classes.image__content}
              ></div>
              {coverImageUrl && (
                <div
                  className={classes.image__remove}
                  onClick={handleEditIconClick}
                >
                  <Icon
                    icon="material-symbols:edit"
                    color="white"
                    style={{ color: '#fff', fontSize: '24px' }}
                  />
                </div>
              )}

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onCoverUpload}
              />
            </div>
          </div>
          <div className={classes.root__section}>
            <div className={classes.root__content}>
              <OutlinedInput
                sx={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  borderColor: 'black',
                }}
                defaultValue={assignmentTitle}
                placeholder={translator._('assignment.assignmentName')}
                onChange={handleTextFieldChange}
              />
              <TextField
                className={classes.input__description}
                multiline
                maxRows={6}
                minRows={6}
                defaultValue={assignmentCoverPageText}
                placeholder="Cover Text"
                onChange={handleTextFieldChangeCoverText}
              />
              <span className={classes.description__span}></span>
            </div>
          </div>
          {!courseEntity && (
            <CountrySelect
              languageSelection={languageSelection}
              setLanguageSelection={setLanguageSelection}
              languageOptions={languageOptions}
            />
          )}
        </div>
        <div className={classes.buttonGroupNaming}>
          <Button
            variant="outlined"
            color="inherit"
            style={{
              height: '30px',
              width: '85px',
            }}
            onClick={handleCloseModal}
          >
            {translator._('assignment.back')}
          </Button>
          <Button
            variant="contained"
            disabled={isTextFieldValid() ? false : true}
            className={classes.namingContinueButton}
            onClick={courseEntity ? handleSaveCourse : handleCreateButton}
          >
            {translator._(
              courseEntity ? 'assignment.save' : 'assignment.create',
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withStyles(styles)(NamingModal)
