import { AnswerItemType } from 'src/model/question'
import translator from 'src/translate'
import { AssignmentFieldNames } from 'src/model/assignment'
import {
  QuestionFieldNames,
  isAnswerItemTypeResultCard,
} from 'src/model/question'

const options = props => {
  return [
    {
      name: 'viewing-content',
      icon: '/assets/icons/task/new-ai-icon-card.png',
      title: 'AI',
      contents: [
        {
          name: 'bite-sized-information',
          icon: '/assets/icons/task/new-ai-icon-card.png',
          title: translator._('tasks.biteSized.title'),
          exampleImage: '/assets/icons/task/bite-size-example.png',
          dataType: AnswerItemType.AI,
        },
      ],
    },
    {
      name: 'viewing-content',
      icon: '/assets/icons/task/viewing-content.png',
      title: translator._('tasks.viewingContent'),
      contents: [
        {
          name: 'bite-sized-information',
          icon: '/assets/icons/task/viewing-content.png',
          title: translator._('tasks.biteSized.title'),
          description: translator._('tasks.biteSized.description'),
          description2: translator._('tasks.biteSized.description2'),
          exampleImage: '/assets/icons/task/bite-size-example.png',
          dataType: AnswerItemType.INFORM,
        },
      ],
    },
    {
      name: 'assessing-knowledge',
      icon: '/assets/icons/task/assessing-content.png',
      title: translator._('tasks.assesssingKnowledge'),
      contents: [
        {
          name: 'single-select',
          icon: '/assets/icons/task/single-select.png',
          title: translator._('tasks.singleSelect.title'),
          description: translator._('tasks.singleSelect.description'),
          description2: translator._('tasks.singleSelect.description2'),
          exampleImage: '/assets/icons/task/single-select-example.png',
          dataType: AnswerItemType.TESTINGSINGLECHOICE,
        },
        {
          name: 'multiple-select',
          icon: '/assets/icons/task/multiple-select.png',
          title: translator._('tasks.multipleSelect.title'),
          description: translator._('tasks.multipleSelect.description'),
          description2: translator._('tasks.multipleSelect.description2'),
          exampleImage: '/assets/icons/task/multiple-select-example.png',
          dataType: AnswerItemType.TESTINGMULTIPLECHOICE,
        },
      ],
    },
    {
      name: 'feedback-collection',
      icon: '/assets/icons/task/feedback.png',
      title: translator._('tasks.feedbackCollection'),
      contents: [
        {
          name: 'free-form',
          icon: '/assets/icons/task/free-form.png',
          title: translator._('tasks.freeForm.title'),
          description: translator._('tasks.freeForm.description'),
          description2: translator._('tasks.freeForm.description2'),
          exampleImage: '/assets/icons/task/free-form-example.png',
          dataType: AnswerItemType.SURVEYFREEFORM,
        },
        {
          name: 'input',
          icon: '/assets/icons/task/input.png',
          title: translator._('tasks.input.title'),
          description: translator._('tasks.input.description'),
          description2: translator._('tasks.input.description2'),
          exampleImage: '/assets/icons/task/free-form-example.png',
          dataType: AnswerItemType.SURVEYINPUT,
        },
        {
          name: 'star-rating',
          icon: '/assets/icons/task/star-rating.png',
          title: translator._('tasks.starRating.title'),
          description: translator._('tasks.starRating.description'),
          description2: translator._('tasks.starRating.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.SURVEYSTARS,
        },
        {
          name: 'single-select',
          icon: '/assets/icons/task/survey-single-select.png',
          title: translator._('tasks.singleSelectFeedback.title'),
          description: translator._('tasks.singleSelectFeedback.description'),
          description2: translator._('tasks.singleSelectFeedback.description2'),
          exampleImage: '/assets/icons/task/survey-single-select-example.png',
          dataType: AnswerItemType.SURVEYSINGLECHOICE,
        },
        {
          name: 'multiple-select',
          icon: '/assets/icons/task/survey-multiple-select.png',
          title: translator._('tasks.multipleSelectFeedback.title'),
          description: translator._('tasks.multipleSelectFeedback.description'),
          description2: translator._(
            'tasks.multipleSelectFeedback.description2',
          ),
          exampleImage: '/assets/icons/task/survey-multiple-select-example.png',
          dataType: AnswerItemType.SURVEYMULTIPLECHOICE,
        },
        {
          name: 'numeric-input',
          icon: '/assets/icons/task/numeric-input.png',
          title: translator._('tasks.numericInput.title'),
          description: translator._('tasks.numericInput.description'),
          description2: translator._('tasks.numericInput.description2'),
          exampleImage: '/assets/icons/task/numeric-input-example.png',
          dataType: AnswerItemType.SURVEYNUMERICENTRY,
        },
      ],
    },
    {
      name: 'gateway',
      icon: '/assets/icons/task/gateway.png',
      title: translator._('tasks.gateway'),
      contents: [
        {
          name: 'signup',
          icon: '/assets/icons/task/signup.png',
          title: translator._('tasks.signup.title'),
          description: translator._('tasks.signup.description'),
          description2: translator._('tasks.signup.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.SIGNUP,
          //userRoleControl: window.CONSTANTS.USER_ROLE_ID === 1 ? true : false,
        },

        {
          name: 'password',
          icon: '/assets/icons/task/password.png',
          title: translator._('tasks.password.title'),
          description: translator._('tasks.password.description'),
          description2: translator._('tasks.password.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.PROTECTIONCARD,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(
                x =>
                  x[QuestionFieldNames.QUESTION_TYPE] ==
                  AnswerItemType.PROTECTIONCARD,
              ) != null
            : false,
          lockedMessage: 'Password card was used.',
          //userRoleControl: window.CONSTANTS.USER_ROLE_ID === 1 ? true : false,
        },
        {
          name: 'stripe',
          icon: '/assets/icons/task/stripepayment.png',
          title: translator._('tasks.stripe.title'),
          description: translator._('tasks.stripe.description'),
          description2: translator._('tasks.stripe.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.STRIPEPAYMENT,
          userRoleControl:
            window.CONSTANTS.USER_ROLE_ID === 4 ||
            window.CONSTANTS.USER_ROLE_ID === 8 ||
            window.CONSTANTS.USER_ROLE_ID === 1 ||
            window.CONSTANTS.USER_ROLE_ID === 12
              ? true
              : false,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(
                x =>
                  x[QuestionFieldNames.QUESTION_TYPE] ==
                  AnswerItemType.STRIPEPAYMENT,
              ) != null
            : false,
          lockedMessage: 'Stripe was used.',
        },
      ],
    },
    {
      name: 'finish',
      icon: '/assets/icons/task/finish_card.png',
      title: translator._('tasks.finishCard'),
      contents: [
        {
          name: 'default',
          icon: '/assets/icons/task/finish_card.png',
          title: translator._('tasks.finishDefault'),
          description: translator._('tasks.score.description'),
          description2: translator._('tasks.score.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.RESULTDEFAULT,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
            : false,
          disableLockedMessage: false,
          lockedMessage: 'You added a completion card',
        },

        {
          name: 'score',
          icon: '/assets/icons/task/score_card.png',
          title: translator._('tasks.score'),
          description: translator._('tasks.score.description'),
          description2: translator._('tasks.score.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.SCORECARD,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
            : false,
          disableLockedMessage: false,
          lockedMessage: 'You added a completion card',
        },
        {
          name: 'result',
          icon: '/assets/icons/task/result_card.png',
          title: translator._('tasks.result'),
          description: translator._('tasks.result.description'),
          description2: translator._('tasks.result.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.RESULT,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
            : false,
          disableLockedMessage: true,
        },
        {
          name: 'sertifier',
          icon: '/assets/icons/task/sertifier.png',
          title: translator._('tasks.sertifier'),
          description: translator._('tasks.sertifier.description'),
          description2: translator._('tasks.sertifier.description2'),
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.SERTIFIERCARD,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
            : false,
          disableLockedMessage: true,
        },
        {
          name: 'badge',
          icon: '/assets/icons/task/finish_card.png',
          title: 'Badge',
          description: 'Badge',
          description2: 'Badge',
          exampleImage: '/assets/icons/task/star-rating-example.png',
          dataType: AnswerItemType.BADGE,
          locked: props.entity
            ? props.entity[AssignmentFieldNames.QUESTION_DTOS].find(x =>
                isAnswerItemTypeResultCard(x[QuestionFieldNames.QUESTION_TYPE]),
              ) != null
            : false,
          disableLockedMessage: false,
        },
      ],
    },
  ]
}

export default options
