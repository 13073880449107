const styles = theme => ({
  root: {
    display: 'flex',
    padding: 16,
    paddingTop: 0,
    position: 'fixed',
    left: 0,
    top: 86,
    height: '90vh',
  },
  root_mobile: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 64,
    height: 'calc(100vh - 64px)',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    backdropFilter: 'blur(10px)',
    zIndex: 99999999999999, // Ensure the overlay appears above other elements
  },
  hide: {
    display: 'none',
  },

  listOpen: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    maxWidth: '280px',
    overflowX: 'hidden',
  },
  drawer: {
    width: '280px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  drawerOpen: {
    width: '280px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    padding: 10,
  },
  courseTransportClicked: {
    outline: '1px solid #015FFB',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '92px',
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    padding: 10,
  },
  drawerOpenPaper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    overflowX: 'hidden',
  },
  drawerClosePaper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    width: '72px',
    height: '72px',
    margin: 0,
  },
  fG: {
    flexGrow: 1,
  },
  chatListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px',
    borderRadius: '8px',
    height: '52px',
    padding: '6px',
    maxWidth: '260px',
    gap: '8px',
    '&:hover': {
      backgroundColor: '#E3F3FF !important',
    },
  },
  chatListItemSelected: {
    backgroundColor: '#E3F3FF !important',
  },
  collectionNameOpen: {
    alignItems: 'center', // Center the text vertically
    maxWidth: '154px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal', // Allow the text to wrap to the next line
    lineHeight: '20px', // Assuming the line height is 20px for a single line
    maxHeight: '40px', // Maximum height for two lines
    height: 'auto', // Set the height to ensure centering within this space
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },

  collectionNameClose: {
    display: 'none',
  },
  chatListIcon: {
    width: '15px',
    height: '15px',
    marginTop: 2,
  },
  chatListText: {
    lineBreak: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  drawerOpenMobile: {
    width: '280px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
  },
  drawerCloseMobile: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerOpenPaperMobile: {
    width: '280px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    border: 'none',
  },
  drawerClosePaperMobile: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    position: 'relative',
    backgroundColor: '#F4F6F8',
    borderRadius: '16px',
    border: 'none',
  },
  dragIcon: {
    display: 'none',
    position: 'absolute',
    right: 0,
    fontSize: '20px',
  },

  chatListItemHover: {
    '&:hover $dragIcon': {
      display: 'inline-block',
      marginRight: '6px',
    },
  },
})

export default styles
