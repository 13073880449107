import React, { useState } from 'react'

import styles from './style'
import propTypes from './prop'
import translator from 'src/translate'
import { Icon } from '@iconify/react'

import QuestionCreateLink from '../../../create-link'
import { componentBoxShowAction } from '../../../../../reducers/component-box/action'
import ComponentBoxContent from '../../../../../model/component-box'
import { QuestionFieldNames } from 'src/model/question'

import { track } from '@amplitude/analytics-browser'

import ContainerMediaGalery from 'src/container/media-galery'
import OpenAiPopup from 'src/components/card-item/main/openai-card-complete'
import OpenAiPopupWithCardText from 'src/components/ai-popup/index'

import {
  withStyles,
  Button,
  Tooltip,
  MenuItem,
  Menu,
  Box,
} from '@material-ui/core'

import Fade from '@material-ui/core/Fade'

//import MenuPopover from 'src/components/MenuPopover'

const IconsInform = props => {
  const {
    classes,
    entity,
    dispatch,
    location,
    editor,
    onBlur,
    onChange,
    imageURL,
    setImageURL,
    onOk,
    onRemove,
    addTextToTextArea,
    anchorEl,
    setAnchorEl,
    open,
    spanRef,
    updatedMarkdownText,
    editorType,
    onChangeMarkDown,
  } = props
  const PopoverItems = [
    {
      image: 'complete',
      title: 'Complete the rest',
      displayCondition: true,
    },
    {
      image: 'correct',
      title: 'Correct grammar',
      displayCondition: true,
    },
    {
      image: 'summerize',
      title: 'Summarize & shorten',
      displayCondition: true,
    },
    {
      image: 'rewrite',
      title: 'Rewrite & paraphrase',
      displayCondition: true,
    },
    {
      image: 'image',
      title: 'Create AI-powered image',
      displayCondition:
        entity[QuestionFieldNames.QUESTION_MEDIAS].length == 0 ? true : false,
    },
  ]

  const [openTooltip, setOpenTooltip] = useState(false)
  const element = document.getElementById(
    'main_' + `${entity[QuestionFieldNames.ID]}`,
  )
  const scrollToBottom = () => {
    element.scrollTo(0, element.scrollHeight)
  }

  const addButtonClickHandler = type => {
    const { dispatch } = props
    const component = (
      <QuestionCreateLink
        cardId={entity[QuestionFieldNames.ID]}
        location={location}
        selectedItem={type}
      />
    )

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(component, {
          hideCloseButton: false,
          callback: e => {},
        }),
      ),
    )
    scrollToBottom()
  }

  const handleClick = event => {
    track('ai_in_card_clicked')
    entity.text.replace(/(<([^>]+)>)/gi, '') != ''
      ? setAnchorEl(event.currentTarget)
      : dispatch(
          componentBoxShowAction(
            new ComponentBoxContent(
              (
                <OpenAiPopup
                  entity={entity}
                  dispatch={dispatch}
                  value={''}
                  addTextToTextArea={addTextToTextArea}
                  editor={editor}
                  updatedMarkdownText={updatedMarkdownText}
                  editorType={editorType}
                  onChangeMarkDown={onChangeMarkDown}
                />
              ),
              {
                hideCloseButton: false,
                callback: e => {},
              },
            ),
          ),
        )
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onAiClick = item => {
    track('ai_in_card_selected', {
      type: item.title,
    })
    if (item.title == 'Create AI-powered image') {
      openMediaPopup()
    } else {
      openTextPopup(item)
    }
    handleClose()
  }

  const openTextPopup = item => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <OpenAiPopupWithCardText
              item={item}
              dispatch={dispatch}
              editorValue={editor.getText()}
              editor={editor}
              entity={entity}
              onBlur={onBlur}
              onChange={onChange}
              updatedMarkdownText={updatedMarkdownText}
              editorType={editorType}
              onChangeMarkDown={onChangeMarkDown}
            />
          ),
          {
            hideCloseButton: false,
            callback: e => {},
          },
        ),
      ),
    )
  }
  const openMediaPopup = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <ContainerMediaGalery
              setImageURL={setImageURL}
              onOk={onOk}
              onRemove={onRemove}
              cardEntity={entity}
              popupType="create prompt"
            />
          ),
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }
  return (
    <>
      <Tooltip title="Call AI Assistant" placement="bottom">
        <Button
          className={classes.iconButton}
          size="small"
          aria-controls="fade-menu"
          aria-haspopup="true"
          ref={spanRef}
          onClick={handleClick}
        >
          <img src="/assets/ai-text-icon.svg" style={{ width: '16px' }} />
        </Button>
      </Tooltip>
      <Tooltip
        title={translator._('taskItem.addQuestion.link.addLink')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => addButtonClickHandler('url')}
        >
          <Icon
            icon="ph:link-bold"
            width="256"
            height="256"
            className={classes.icon}
          />
        </Button>
      </Tooltip>
      <Tooltip
        title={translator._('taskItem.addQuestion.link.addPDF')}
        placement="bottom"
      >
        <Button
          className={classes.iconButton}
          size="small"
          onClick={() => addButtonClickHandler('pdf')}
        >
          <Icon
            icon="material-symbols:picture-as-pdf-outline"
            width="256"
            height="256"
            className={classes.icon}
          />
        </Button>
      </Tooltip>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ mt: -5 }}
      >
        {PopoverItems.map(item => (
          <MenuItem
            onClick={() => {
              onAiClick(item)
            }}
            disabled={!item.displayCondition}
            key={'button-' + item.title}
          >
            <img
              style={{ width: '20px' }}
              src={`/assets/ai-${item.image}.png`}
            />
            <Box m={0.5}></Box>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

IconsInform.propTypes = propTypes

export default withStyles(styles)(IconsInform)
