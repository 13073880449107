const initialLanguageSettings = {
  selected: 'en',
  strings: {
    continue: 'Continue',
    feedback: 'Feedback',
    finishText: 'Thank you for participating!',
    home: 'Home',
    next: 'Next',
    quiz: 'Quiz',
    resumeCourse: 'Resume Course',
    startCourse: 'Start Course',
    login_title: 'Unlock This Exclusive Course',
    login_description:
      'To continue, please enter your email for authentication.',
    login_name: 'Name Surname',
    login_email: 'Email Address',
    login_check: 'I agree to receive this content from the content owner.',
    login_link_text: 'Get Access Link',
    login_verification_error_title: 'Verification Required for Access',
    login_verification_error_description:
      'It seems that your email address is not currently on the authorized access list. Please ensure you are using the email address that was invited, or contact the content owner for assistance.',
    login_verification_error_link: 'Try Again',
    login_access_link_title: 'Access Link Sent',
    login_access_link_description:
      'The access link has been sent to #MAIL_ADDRESS# Please check your inbox or spam folder and click the link to access the',
    login_resend_access_link_text: 'Resend Access Link',
    login_access_link_sent:
      'A fresh access link has been sent your email address',
    login_payment_title: 'Welcome back {userName}',
    login_payment_description:
      'Great, your email has been verified! You can start accessing this amazing content after the payment.',
    login_payment_link: 'Pay and Access',
    login_payment_success_title: 'Yes, you’ve successfully ordered! 🎉',
    login_payment_success_description:
      'Thank you for the payment. You can now access {collectionName} by simply clicking the button below.',
    login_payment_success_link: 'Resume Course',
    login_payment_redirect_text:
      'Please check your email to proceed to your course.',
    end_screen_retake: 'Retake',
    end_screen_next_steps: 'Your Next Steps',
    end_screen_see_all: 'See All',
    end_screen_ending_text:
      "<h1>Congratulations! 🎉 </h1> <p style='color:#637381;'>Great work! Course is complete!</p>",
    end_screen_collection: 'Collection',
    end_screen_next_course: 'Next Course',
  },
  labels: {
    continue: 'Continue',
    feedback: 'Feedback',
    finishText: 'Thank you for participating!',
    home: 'Home',
    next: 'Next',
    quiz: 'Quiz',
    resumeCourse: 'Resume Course',
    startCourse: 'Start Course',
    login_title: 'Unlock This Exclusive Course',
    login_description: 'Login description',
    login_name: 'Name Surname',
    login_email: 'Email Address',
    login_check: 'I agree to receive this content from the content owner.',
    login_link_text: 'Get Access Link',
    login_verification_error_title: 'Verification Required for Access',
    login_verification_error_description: 'Verification Error Description',
    login_verification_error_link: 'Try Again',
    login_access_link_title: 'Access Link Sent',
    login_access_link_description: 'Access Link Description',
    login_resend_access_link_text: 'Resend Access Link',
    login_mail_subject: 'Email Subject',
    double_optin: 'Email confirmation (if double opt-in selected)',
    paid_login: 'Pre-payment email (if paywall is activated)',
    paid_order: 'After-payment email (if paywall is activated)',
    invite_member: 'Invite Member',
    invite_member_inner: 'Invite Member Inner',
    login_access_link_sent: 'Login Access Link Sent',
    login_payment_title: 'Login Payment Title',
    login_payment_description: 'Login Payment Description',
    login_payment_link: 'Login Payment Link',
    login_payment_success_title: 'Login Payment Success Title',
    login_payment_success_description: 'Login Payment Success Description',
    login_payment_success_link: 'Login Payment Success Link',
    login_payment_redirect_text: 'Login Payment Redirect Text',
    end_screen_retake: 'Retake',
    end_screen_next_steps: 'Your Next Steps',
    end_screen_see_all: 'See All',
    end_screen_ending_text: 'Ending Text',
    end_screen_collection: 'Collection',
    end_screen_next_course: 'Next Course',
  },
}

export { initialLanguageSettings }
