import React, { useEffect, useState } from 'react'
import styles from '../styles'
import { withStyles, Typography, TextField, Button } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { MailPreparation, MailSent, MailCName } from '../index'
import {
  BottomButton,
  BackButton,
} from '../../../collection-settings-drawer/tabs/components'
import { sendMailToLearnerAction } from 'src/reducers/teams/action'
import { Icon } from '@iconify/react'
import { track } from '@amplitude/analytics-browser'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
import CountrySelect from 'src/components/naming-modal/country-select'
import { getLocalizationStringOfTypeService } from 'src/api/common'

const MagicViewContent = props => {
  const { entity, handleBack, closeDrawer, collectionSettings, classes } = props

  const dispatch = useDispatch()

  const [mailSent, setMailSent] = React.useState(false)
  const sendMail = () => {
    dispatch(
      sendMailToLearnerAction(entity.id, sendLinkToNewMembersOnly, response =>
        setMailSent(true),
      ),
    )
    track('magiclink_email_sent')
  }
  const [
    sendLinkToNewMembersOnly,
    setSendLinkToNewMembersOnly,
  ] = React.useState(collectionSettings.sendLinkToNewMembersOnly)

  const handleSendLinkToNewMembersOnly = value => {
    setSendLinkToNewMembersOnly(value)
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        value,
        'sendLinkToNewMembersOnly',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }
  const [emailText, setEmailText] = React.useState(
    collectionSettings.inviteEmailBody,
  )
  const [emailSettings, setEmailSettings] = useState()
  const languageOptions = window.CONSTANTS.LANGUAGES

  const [languageSelection, setLanguageSelection] = React.useState(
    languageOptions.find(
      collectionSettings.countryCode
        ? x => x.countryCode === collectionSettings.countryCode
        : x => x.countryCode === 'en',
    ),
  )

  const [emailTitle, setEmailTitle] = useState(
    collectionSettings.inviteEmailSubject != null
      ? collectionSettings.inviteEmailSubject
      : emailSettings &&
          emailSettings[languageSelection.countryCode].find(
            x => x.key == 'invite_member_subject',
          ).text,
  )

  const handleChangeLanguage = e => {
    if (e === null) return
    setLanguageSelection(e)
    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        e.countryCode,
        'countryCode',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))

    const newPatchSource = new PatchData(null, entity.id)

    newPatchSource.addContent(
      new PatchContent(
        emailSettings[e.countryCode].find(
          x => x.key == 'invite_member_inner',
        ).text,
        'inviteEmailBody',
        PatchData.OPERATION_REPLACE,
      ),
    )
    newPatchSource.addContent(
      new PatchContent(
        emailSettings[e.countryCode].find(
          x => x.key == 'invite_member_subject',
        ).text,
        'inviteEmailSubject',
        PatchData.OPERATION_REPLACE,
      ),
    )

    setEmailText(
      emailSettings[e.countryCode].find(x => x.key == 'invite_member_inner')
        .text,
    )
    setEmailTitle(
      emailSettings[e.countryCode].find(x => x.key == 'invite_member_subject')
        .text,
    )
    dispatch(patchCollectionSettingsAction(newPatchSource, response => {}))
  }

  useEffect(() => {
    if (emailSettings) return
    const mailService = () => {
      getLocalizationStringOfTypeService('course-mail').then(res => {
        var groupBy = function (xs, keyFunc) {
          return xs.reduce(function (rv, x) {
            var key = keyFunc(x)
            ;(rv[key] = rv[key] || []).push(x)
            return rv
          }, {})
        }

        // Usage
        var localizationStringsEmailsGroupedByKeys = groupBy(
          res.data.localizationStrings,
          function (item) {
            return item.countryCode
          },
        )

        setEmailSettings(localizationStringsEmailsGroupedByKeys)
      })
    }
    mailService()
  }, [languageSelection])

  useEffect(() => {
    emailSettings &&
      emailTitle == null &&
      setEmailTitle(
        emailSettings[languageSelection.countryCode].find(
          x => x.key == 'invite_member_subject',
        ).text,
      )
  }, [emailSettings])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
      }}
    >
      {mailSent ? (
        <>
          <MailSent closeDrawer={closeDrawer} handleBack={handleBack} />
        </>
      ) : (
        <>
          <div
            className={classes.displayF}
            style={{ marginBottom: 24 }}
            onClick={() => handleBack()}
          >
            <Icon
              icon={'material-symbols:arrow-back-ios-rounded'}
              className={classes.magic_link_icon}
              style={{ cursor: 'pointer' }}
            />
            <Typography
              fontSize={'15px'}
              fontWeight={600}
              style={{ color: '#002E47' }}
            >
              Invite learners by magic link
            </Typography>
          </div>

          <Typography
            fontSize={'15px'}
            fontWeight={600}
            style={{ color: '#002E47' }}
          >
            Email Settings
          </Typography>

          <div
            style={{
              background: '#F4F6F8',
              padding: 16,
              gap: 16,
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CountrySelect
              languageSelection={languageSelection}
              setLanguageSelection={handleChangeLanguage}
              languageOptions={languageOptions}
            />
            <MailPreparation
              handleSendLinkToNewMembersOnly={handleSendLinkToNewMembersOnly}
              sendLinkToNewMembersOnly={sendLinkToNewMembersOnly}
              entity={entity}
              collectionSettings={collectionSettings}
              dispatch={dispatch}
              emailText={emailText}
              setEmailText={setEmailText}
              emailTitle={emailTitle}
              setEmailTitle={setEmailTitle}
            />
          </div>
          <Typography
            fontSize={'15px'}
            fontWeight={600}
            style={{ color: '#002E47' }}
          >
            Custom Domain Settings
          </Typography>
          <div
            style={{
              background: '#F4F6F8',
              padding: 16,
              gap: 16,
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MailCName
              handleSendLinkToNewMembersOnly={handleSendLinkToNewMembersOnly}
              sendLinkToNewMembersOnly={sendLinkToNewMembersOnly}
              entity={entity}
              collectionSettings={collectionSettings}
              dispatch={dispatch}
              emailText={emailText}
              setEmailText={setEmailText}
              emailTitle={emailTitle}
              setEmailTitle={setEmailTitle}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 16,
            }}
          >
            <Button
              fullWidth={true}
              size="small"
              variant={'contained'}
              onClick={() => handleBack()}
              className={classes.mailPreparationBackButton}
            >
              Back
            </Button>
            <Button
              fullWidth={true}
              size="small"
              variant={'contained'}
              onClick={() => collectionSettings.teams.length != 0 && sendMail()}
              className={
                collectionSettings.teams.length == 0
                  ? classes.mailPreparationBottomDimButton
                  : classes.mailPreparationBottomButton
              }
            >
              Send Mail
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(MagicViewContent)
