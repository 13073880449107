import React from 'react'
import styles from './styles'
import { withStyles, Typography } from '@material-ui/core'
import { BoxView, data } from './box-view'
const FullView = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <Typography fontSize="22px" fontWeight={600}>
        What makes it special for automations in education?
      </Typography>
      <div className={classes.root__boxView}>
        {data.map((item, index) => (
          <BoxView
            key={index}
            icon={item.icon}
            headerText={item.headerText}
            description={item.description}
          />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(FullView)
