import React, { useState, useEffect } from 'react'
import styles from './styles'
import {
  withStyles,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
} from '@material-ui/core'
import UserType from 'src/model/user-types'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { setUserRoleAction } from 'src/reducers/user-role-detailed-list/action'
import { getUserReportByIdService } from 'src/api/authentication'

const UserRoleEdit = props => {
  const { dispatch, entity, classes, refresh } = props
  const [userRole, setUserRole] = useState(entity.roleType)
  const [loading, setLoading] = useState(false)
  const [totalActionsCount, setTotalActionsCount] = useState('')
  const [trialStartDate, setTrialStartDate] = useState('')
  const [uniqueEngagedUsers, setUniqueEngagedUsers] = useState('')
  /**
   *
   * @param {*} e
   */
  const handleCourse = e => {
    setUserRole(e.target.value)
  }

  useEffect(() => {
    setLoading(true)
    getUserReportByIdService(entity.id).then(response => {
      setTotalActionsCount(response.data.totalActionsCount)
      setTrialStartDate(response.data.trialStartDate)
      setUniqueEngagedUsers(response.data.uniqueEngagedUsers)
      setLoading(false)
    })
  }, [entity])
  const saveRole = () => {
    const data = {
      userName: entity.userName,
      email: entity.email,
      id: entity.id,
      roleType: userRole,
      shopId: entity.shopId,
    }
    dispatch(setUserRoleAction(data))
    dispatch(componentBoxHideAction())
  }
  return (
    <div className={classes.namingModalContent}>
      <div className={classes.modalItems}>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.fields}
        >
          <InputLabel id="demo-simple-select-helper-label">
            User Role
          </InputLabel>
          <Select
            fullWidth
            variant="outlined"
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            className={classes.select}
            inputProps={{
              className: classes.input,
            }}
            key={userRole}
            value={userRole}
            input={<Input id="select-input" name="select-input" />}
            onChange={handleCourse}
          >
            <MenuItem value={userRole} disabled>
              {UserType[userRole]}
            </MenuItem>
            {Object.keys(UserType).map(key => {
              return (
                <MenuItem value={key} key={key}>
                  {UserType[key]}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <TextField
          className={classes.fields}
          disabled={true}
          defaultValue={entity.email}
          label="Email"
        ></TextField>
        <TextField
          className={classes.fields}
          disabled={true}
          defaultValue={entity.name}
          label="Name"
        ></TextField>
        <TextField
          className={classes.fields}
          disabled={true}
          defaultValue={entity.surname}
          label="SurName"
        ></TextField>
        <TextField
          className={classes.fields}
          disabled={true}
          defaultValue={entity.id}
          label="ID"
        ></TextField>
        <TextField
          disabled={true}
          defaultValue={entity.userName}
          label="User Name"
          className={classes.fields}
        ></TextField>
        {!loading && (
          <>
            <TextField
              disabled={true}
              value={totalActionsCount}
              label="Total Action Count"
              className={classes.fields}
            >
              {totalActionsCount}
            </TextField>
            <TextField
              disabled={true}
              value={trialStartDate}
              label="Trial Start Date"
              className={classes.fields}
            >
              {trialStartDate}
            </TextField>
            <TextField
              disabled={true}
              value={uniqueEngagedUsers}
              label="Unique Engaged Users"
              className={classes.fields}
            >
              {uniqueEngagedUsers}
            </TextField>
          </>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => saveRole()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(UserRoleEdit)
