import React from 'react'
import { Typography, withStyles, Popover, Button } from '@material-ui/core'
import styles from '../user-access/components/styles'
import { track } from '@amplitude/analytics-browser'

const UpgradePopover = props => {
  const {
    id,
    anchorEl,
    handleClose,
    open,
    header,
    content,
    accessRuleEqual,
  } = props

  const onClick = () => {
    if (header != 'Get quote') {
      track('limit_warning_upgrade', {
        from: `${accessRuleEqual}_limit_upgrade`,
      })
      window.location.href = '/pricing'
      return
    } else {
      if (window.Intercom) {
        handleClose()
        window.Intercom('showMessages')
      }
    }
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 16,
          padding: '24px 24px',
          width: '370px',
          boxShadow: 'none',
          backgroundImage:
            'linear-gradient(128deg, #6FB6FE 5.78%, #015FFB 112.71%)',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <Typography fontSize={'22px'} fontWeight={500} color={'#fff'}>
            {header}
          </Typography>
          <Typography fontSize={'15px'} fontWeight={400} color={'#fff'}>
            {content}
          </Typography>
        </div>
        <img
          src={'/assets/mcg-rocket2x.png'}
          alt="rocket!"
          width={100}
          height={100}
        />
      </div>
      <Button
        variant={'contained'}
        onClick={onClick}
        style={{
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        <Typography color={'#015FFB'} fontSize={'14px'} fontWeight={500}>
          {header == 'Get quote' ? 'Contact sales' : 'See Plans & Upgrade'}
        </Typography>
      </Button>
    </Popover>
  )
}

export default withStyles(styles)(UpgradePopover)
