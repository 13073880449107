import React from 'react'
import {
  Button,
  FormControl,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Icon } from '@iconify/react'
import styles from './styles'
import { useDispatch } from 'react-redux'
import { exportCollectionAnalyticsAction } from 'src/reducers/course-collection/action'
const SelectionPart = props => {
  const {
    classes,
    courses,
    selectedCourse,
    handleChange,
    selectedCollectionId,
  } = props
  const dispatch = useDispatch()
  const handleDownload = () => {
    dispatch(exportCollectionAnalyticsAction(selectedCollectionId))
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          value={selectedCourse}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          <option value={''}>All Mini-Courses</option>
          {courses.map(course => (
            <option value={course.id}>{course.name}</option>
          ))}
        </Select>
      </FormControl>
      <Button
        style={{
          height: '36px',
          color: '002E47',
          backgroundColor: '#E3F3FF',
        }}
        onClick={() => handleDownload()}
      >
        <Icon
          icon="tabler:download"
          style={{
            marginRight: '4px',
          }}
        />
        <Typography
          fontSize={'14px'}
          fontWeight={400}
          style={{ color: '#002E47' }}
        >
          Download Full Report
        </Typography>
      </Button>
    </div>
  )
}

export default withStyles(styles)(SelectionPart)
