import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import {
  Typography,
  TextField,
  Box,
  Button,
  Alert,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  NativeSelect,
} from '@material-ui/core'
import styles from './styles'

const SelectLanguage = props => {
  const { language, setLanguage, classes } = props
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }))
  const handleChange = event => {
    setLanguage(event.target.value)
  }
  return (
    <>
      <Box m={2}></Box>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-helper">Language</InputLabel>
        <NativeSelect
          value={language}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'age-native-helper',
          }}
        >
          <option value={'Arabic'}>Arabic</option>
          <option value={'Simplified Chinese'}>Chinese (Simplified)</option>
          <option value={'Traditional Chinese'}>Chinese (Traditional)</option>
          <option value={'Danish'}>Danish</option>
          <option value={'Dutch'}>Dutch</option>
          <option value={'English'}>English</option>
          <option value={'Finnish'}>Finnish</option>
          <option value={'French'}>French</option>
          <option value={'German'}>German</option>
          <option value={'Italian'}>Italian</option>
          <option value={'Norwegian'}>Norwegian</option>
          <option value={'Polish'}>Polish</option>
          <option value={'Portuguese'}>Portuguese</option>
          <option value={'Russian'}>Russian</option>
          <option value={'Spanish'}>Spanish</option>
          <option value={'Swedish'}>Swedish</option>
          <option value={'Turkish'}>Turkish</option>
        </NativeSelect>
      </FormControl>
      <Box m={2}></Box>
    </>
  )
}

export default withStyles(styles)(SelectLanguage)
