
const grid = 6;


export const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid*2,
      margin: `0 0 ${grid}px 0`,
      textAlign: "left",
  
      // change background colour if dragging
      background: isDragging ? "#f4f4f4" : "white",
      border: "1px solid #e5e5e5",
      borderRadius:10,
      maxWidth:500,
  
      // styles we need to apply on draggables
      ...draggableStyle,
      
    };
  };
  
  export const getSubHeaderItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: 0,
      paddingLeft:12,
      paddingBottom:6,
      margin: `0 0 ${grid}px 0`,
      textAlign: "left",
  
      // change background colour if dragging
      background: isDragging ? "#f4f4f4" : "white",
      borderRadius:10,
  
      // styles we need to apply on draggables
      ...draggableStyle
    };
  };
  