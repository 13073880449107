import React from 'react'
import Html from 'react-pdf-html'
import { QuestionFieldNames } from 'src/model/question'
import URL from 'src/api/url'

import ReactPDF, {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Svg,
  Path,
  Line,
  G,
  Circle,
} from '@react-pdf/renderer'
import { Card } from '@material-ui/core'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card, buttonColorRgb } = props

  return (
    <View style={stylesheet.starView}>
      {page !== 0 &&
        card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('gif') && (
          <Image
            style={stylesheet.starContentImage}
            src={
              card.mediaDtos[0].mediaUrl.includes('https')
                ? card.mediaDtos[0].mediaUrl
                : URL.CDN + card.mediaDtos[0].mediaUrl +  '?not-from-cache-please2'
            }
          />
        )}

      <View style={stylesheet.starText}>
        <Html stylesheet={stylesheet}>
          {editor && editor.getContents && page !== 0
            ? editor.getContents()
            : card.text}
        </Html>

        <View style={stylesheet.starLine}>
          <Svg width="200" height="1" viewBox="0 0 280 1" fill="none">
            <Line x1="0" y1="0.5" x2="280" y2="0.5" stroke="#676767" />
          </Svg>
        </View>
        <View style={stylesheet.starVector}>
          <Svg width="143" height="27" viewBox="0 0 143 27" fill="none">
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0338 6.01239L10.8101 9.77854C10.391 11.0684 9.18902 11.9416 7.83283 11.9416H3.87288L7.07655 14.2692C8.17373 15.0664 8.63283 16.4794 8.21375 17.7692L6.99005 21.5353L10.1937 19.2077C11.2909 18.4106 12.7766 18.4106 13.8738 19.2077L17.0775 21.5353L15.8538 17.7692C15.4347 16.4794 15.8938 15.0664 16.991 14.2692L20.1946 11.9416H16.2347C14.8785 11.9416 13.6765 11.0684 13.2575 9.77854L12.0338 6.01239ZM13.0971 2.77253C12.7624 1.74249 11.3051 1.74249 10.9705 2.77253L8.89613 9.15666C8.74646 9.61731 8.31719 9.92919 7.83283 9.92919H1.12016C0.0371095 9.92919 -0.413198 11.3151 0.463006 11.9517L5.89367 15.8973C6.28552 16.182 6.44949 16.6866 6.29982 17.1473L4.22549 23.5314C3.89081 24.5615 5.06973 25.418 5.94594 24.7814L11.3766 20.8358C11.7685 20.5511 12.2991 20.5511 12.6909 20.8358L18.1216 24.7814C18.9978 25.418 20.1767 24.5615 19.842 23.5314L17.7677 17.1473C17.618 16.6866 17.782 16.182 18.1738 15.8973L23.6045 11.9517C24.4807 11.3151 24.0304 9.92919 22.9474 9.92919H16.2347C15.7503 9.92919 15.3211 9.61731 15.1714 9.15666L13.0971 2.77253Z"
              fill="#F5CF57"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M41.7662 6.01239L40.5425 9.77854C40.1234 11.0684 38.9214 11.9416 37.5653 11.9416H33.6053L36.809 14.2692C37.9062 15.0664 38.3653 16.4794 37.9462 17.7692L36.7225 21.5353L39.9261 19.2077C41.0233 18.4106 42.509 18.4106 43.6062 19.2077L46.8099 21.5353L45.5862 17.7692C45.1671 16.4794 45.6262 15.0664 46.7234 14.2692L49.9271 11.9416H45.9671C44.6109 11.9416 43.409 11.0684 42.9899 9.77854L41.7662 6.01239ZM42.8295 2.77253C42.4948 1.74249 41.0376 1.74249 40.7029 2.77253L38.6286 9.15666C38.4789 9.61731 38.0496 9.92919 37.5653 9.92919H30.8526C29.7695 9.92919 29.3192 11.3151 30.1954 11.9517L35.6261 15.8973C36.0179 16.182 36.1819 16.6866 36.0322 17.1473L33.9579 23.5314C33.6232 24.5615 34.8022 25.418 35.6784 24.7814L41.109 20.8358C41.5009 20.5511 42.0315 20.5511 42.4233 20.8358L47.854 24.7814C48.7302 25.418 49.9091 24.5615 49.5745 23.5314L47.5001 17.1473C47.3504 16.6866 47.5144 16.182 47.9063 15.8973L53.3369 11.9517C54.2131 11.3151 53.7628 9.92919 52.6798 9.92919H45.9671C45.4827 9.92919 45.0535 9.61731 44.9038 9.15666L42.8295 2.77253Z"
              fill="#F5CF57"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M71.5006 6.01239L70.2769 9.77854C69.8578 11.0684 68.6558 11.9416 67.2996 11.9416H63.3397L66.5433 14.2692C67.6405 15.0664 68.0996 16.4794 67.6805 17.7692L66.4569 21.5353L69.6605 19.2077C70.7577 18.4106 72.2434 18.4106 73.3406 19.2077L76.5443 21.5353L75.3206 17.7692C74.9015 16.4794 75.3606 15.0664 76.4578 14.2692L79.6614 11.9416H75.7015C74.3453 11.9416 73.1433 11.0684 72.7242 9.77854L71.5006 6.01239ZM72.5638 2.77253C72.2292 1.74249 70.7719 1.74249 70.4373 2.77253L68.3629 9.15666C68.2133 9.61731 67.784 9.92919 67.2996 9.92919H60.587C59.5039 9.92919 59.0536 11.3151 59.9298 11.9517L65.3605 15.8973C65.7523 16.182 65.9163 16.6866 65.7666 17.1473L63.6923 23.5314C63.3576 24.5615 64.5365 25.418 65.4127 24.7814L70.8434 20.8358C71.2353 20.5511 71.7659 20.5511 72.1577 20.8358L77.5884 24.7814C78.4646 25.418 79.6435 24.5615 79.3088 23.5314L77.2345 17.1473C77.0848 16.6866 77.2488 16.182 77.6406 15.8973L83.0713 11.9517C83.9475 11.3151 83.4972 9.92919 82.4142 9.92919H75.7015C75.2171 9.92919 74.7879 9.61731 74.6382 9.15666L72.5638 2.77253Z"
              fill="#F5CF57"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.233 6.01239L100.009 9.77854C99.5902 11.0684 98.3882 11.9416 97.0321 11.9416H93.0721L96.2758 14.2692C97.3729 15.0664 97.8321 16.4794 97.413 17.7692L96.1893 21.5353L99.3929 19.2077C100.49 18.4106 101.976 18.4106 103.073 19.2077L106.277 21.5353L105.053 17.7692C104.634 16.4794 105.093 15.0664 106.19 14.2692L109.394 11.9416H105.434C104.078 11.9416 102.876 11.0684 102.457 9.77854L101.233 6.01239ZM102.296 2.77253C101.962 1.74249 100.504 1.74249 100.17 2.77253L98.0953 9.15666C97.9457 9.61731 97.5164 9.92919 97.0321 9.92919H90.3194C89.2363 9.92919 88.786 11.3151 89.6622 11.9517L95.0929 15.8973C95.4847 16.182 95.6487 16.6866 95.499 17.1473L93.4247 23.5314C93.09 24.5615 94.269 25.418 95.1452 24.7814L100.576 20.8358C100.968 20.5511 101.498 20.5511 101.89 20.8358L107.321 24.7814C108.197 25.418 109.376 24.5615 109.041 23.5314L106.967 17.1473C106.817 16.6866 106.981 16.182 107.373 15.8973L112.804 11.9517C113.68 11.3151 113.23 9.92919 112.147 9.92919H105.434C104.95 9.92919 104.52 9.61731 104.371 9.15666L102.296 2.77253Z"
              fill="#F5CF57"
            />
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M130.965 6.01239L129.742 9.77854C129.323 11.0684 128.121 11.9416 126.764 11.9416H122.805L126.008 14.2692C127.105 15.0664 127.564 16.4794 127.145 17.7692L125.922 21.5353L129.125 19.2077C130.223 18.4106 131.708 18.4106 132.805 19.2077L136.009 21.5353L134.785 17.7692C134.366 16.4794 134.825 15.0664 135.923 14.2692L139.126 11.9416H135.166C133.81 11.9416 132.608 11.0684 132.189 9.77854L130.965 6.01239ZM132.029 2.77253C131.694 1.74249 130.237 1.74249 129.902 2.77253L127.828 9.15666C127.678 9.61731 127.249 9.92919 126.764 9.92919H120.052C118.969 9.92919 118.518 11.3151 119.395 11.9517L124.825 15.8973C125.217 16.182 125.381 16.6866 125.231 17.1473L123.157 23.5314C122.822 24.5615 124.001 25.418 124.878 24.7814L130.308 20.8358C130.7 20.5511 131.231 20.5511 131.623 20.8358L137.053 24.7814C137.929 25.418 139.108 24.5615 138.774 23.5314L136.699 17.1473C136.55 16.6866 136.714 16.182 137.105 15.8973L142.536 11.9517C143.412 11.3151 142.962 9.92919 141.879 9.92919H135.166C134.682 9.92919 134.253 9.61731 134.103 9.15666L132.029 2.77253Z"
              fill="#F5CF57"
            />
          </Svg>
        </View>
      </View>
    </View>
  )
}

export default Inform
