import React,{useEffect} from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'
import { patchCertificateAction } from 'src/reducers/certificate/action'
import { PatchContent, PatchData } from 'src/model/patch'

import { useDispatch, useSelector } from 'react-redux'
import CustomizedSwitch from 'src/components/customized-switch'


const IsActiveCertificate = props => {
  const { classes } = props
  const dispatch = useDispatch()
 
  const certificate = useSelector(state => state.certificate.certificate)

  const handleChangeSwitch = () => {
    const content = new PatchData(
      new PatchContent(
        !certificate.isActive,
        'isActive',
        PatchData.OPERATION_REPLACE,
      ),
      certificate.id,
    )

    dispatch(patchCertificateAction(content))
  }

  

  return (
    <>

      <div className={classes.switchContainer}>
        <div className={classes.switch}>
          <CustomizedSwitch
            onClick={() => handleChangeSwitch()}
            checked={certificate.isActive}
          ></CustomizedSwitch>
          <Typography fontSize="15px" fontWeight={500}>
            Certification
          </Typography>
        </div>
        <div className={classes.subRow}>
          <Typography fontSize="13px" fontWeight={400} color="#637381">
            Enable this to award a digital certificate upon course completion.
          </Typography>
        </div>
      </div>
     {!certificate.isActive && <div className={classes.certicationLock}></div>}

   
    </>
  )
}

export default withStyles(styles)(IsActiveCertificate)
