export default class UserType {
  static 1 = 'Regular Admin Account'
  static 2 = 'Premium'
  static 3 = 'Super Admin'
  static 4 = 'Appsumo (Tier 1)'
  static 5 = 'Appsumo (Tier 2)'
  static 6 = 'Premium Admin Account (Quarterly)'
  static 7 = 'Appsumo (Tier 3)'
  static 8 = 'Appsumo (Tier 1A)'
  static 9 = 'Appsumo (Tier 2A)'
  static 10 = 'Appsumo (Tier 3A)'
  static 11 = 'Starter - Legacy'
  static 12 = 'Starter'
  static 13 = 'Starter - A'
  static 14 = 'Premium - A'
  static 15 = 'Premium Plus'
  static 16 = 'Premium v2'
  static 17 = 'Premium Plus v2'
  //static 18 = learner
  static 19 = 'Appsumo (Tier 4)'
  static 20 = 'Appsumo (Tier 5)'
}
