const ProviderContent = paymentProvider => {
  switch (paymentProvider) {
    case 'stripe':
      return {
        0: 'https://www.youtube.com/embed/Ig9I_SZ29zA?si=q6h6sVr_IG1XG5xw',
        1: 'https://www.youtube.com/embed/YbDS3pHavKE?si=a2OEE4rE3ywEILg8',
        2: 'https://www.youtube.com/embed/yJcODR31eok?si=SCCuCOPgx_m-u0Uu',
      }
    case 'lemon_squeeze':
      return {
        0: 'https://www.youtube.com/embed/ilas3wyvKpI?si=Occ6LNh0ihV3If07',
        1: 'https://www.youtube.com/embed/rimv499lal8?si=H1W8eksGfz3LQ0a-',
        2: 'https://www.youtube.com/embed/4SZ2TmhEvMw?si=ecjZcWSV7yTvtChJ',
      }

    case 'pabbly':
      return {
        0: 'https://www.youtube.com/embed/Fx5HH7d4D7M?si=3cyjeT05SEqELdIC',
        1: 'https://www.youtube.com/embed/jN5ArTGFTxE?si=8aXIaJuBMIU1BYe4',
        2: 'https://www.youtube.com/embed/2fAL83KLBSI?si=VIOGM-zQj64GqQW7',
      }

    case 'pay_stack':
      return {
        0: 'https://www.youtube.com/embed/ipc9X9ccgQQ?si=Y2mHD_4W-qFrWpGc',
        1: 'https://www.youtube.com/embed/061vy51cIMs?si=4kYceUnHi_QSFpGY',
        2: 'https://www.youtube.com/embed/bI9sS5QEuNo?si=5YQPwroH6fOk5983',
      }
    default:
      return false
  }
}

export default ProviderContent
