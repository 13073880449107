const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  swiper_slide: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '880px',
    height: '255px',
    margin: '20px 40px',
  },
  swiper: {
    width: '100%',
    paddingBottom: '50px',
    height: '100%',
  },
  swiper_buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  swiper_buttons_padding: {
    padding: 5,
  },
})

export default styles
