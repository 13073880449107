import {
  CREATE_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_SUCCESS,
  PATCH_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_LIST_SUCCESS,
} from './type'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = {
    certificateList: null,
    certificate: null,
  },
  action,
) {
  const { payload, type } = action
  switch (type) {
    case CREATE_CERTIFICATE_SUCCESS:
      return { ...state, certificate: payload.response }
    case GET_CERTIFICATE_SUCCESS:
      return { ...state, certificate: payload.response }
    case PATCH_CERTIFICATE_SUCCESS:
      var newCertificate = state.certificate
      payload.content._contents.map(value => {
        newCertificate[value._path] = value._value
      })

      return {
        ...state,
        certificate: newCertificate,
      }
    case GET_CERTIFICATE_LIST_SUCCESS:
      return { ...state, certificateList: payload.response }

    default:
      return state
  }
}
