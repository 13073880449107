import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const AutomationBanner = ({ classes }) => {
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.bannerContent}>
        <Typography className={classes.bannerText}>
          Start building automations in your Zapier and Make accounts.
        </Typography>
        <Button
          className={classes.bannerButton}
          onClick={() => {
            window.location.href = '/automations'
          }}
        >
          Automations
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(AutomationBanner)
