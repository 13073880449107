import URL from '../url'
import Request from '../request'
import { id } from 'date-fns/locale'

/**
 *
 */
export const teamsListService = () => new Request(URL.API, URL.TEAMS).get()

/**
 * @param name
 *
 */
export const teamsAddService = name =>
  new Request(URL.API, URL.TEAMS).post(name)

/**
 *
 * @param {*} content
 */

export const teamsPatchService = content =>
  new Request(URL.API, URL.TEAMS + '/' + content.id).patch(content)

/**
 *
 * @param {*} id
 */

export const teamsDeleteService = id =>
  new Request(URL.API, URL.TEAMS + '/' + id).delete()

/**
 *
 * @param {*} id
 * @param {*} query
 */

export const teamsMemberListService = (query, id) => {
  return new Request(URL.API, URL.TEAMS + '/' + id + URL.MEMBER_LIST).post(
    query,
  )
}

/**
 *
 * @param {*} teamsId
 * @param {*} memberId
 */

export const teamsMemberDeleteService = (teamsId, memberId) => {
  return new Request(
    URL.API,
    URL.TEAMS + '/' + teamsId + URL.MEMBER + '/' + memberId,
  ).delete()
}

/**
 *
 * @param {*} content
 * @param {*} teamsId
 * @param {*} memberId
 */

export const teamsMemberPatchService = (teamsId, memberId, content) => {
  return new Request(
    URL.API,
    URL.TEAMS + '/' + teamsId + URL.MEMBER + '/' + memberId,
  ).patch(content)
}

/**
 *
 * @param {*} teamsId
 * @param {*} teamMembers
 *
 */

export const teamsMemberAddService = (teamsId, teamMembers) => {
  return new Request(
    URL.API,
    URL.TEAMS + '/' + teamsId + URL.MEMBER_BATCH,
  ).post({ teamMembers: teamMembers })
}

/**
 * @param {*} teamsId
 */
export const teamsExportService = teamsId =>
  new Request(URL.API, URL.TEAMS + '/' + teamsId + URL.MEMBERS_EXPORT).get()

/**
 * @param {*} formData
 * @param {*} teamsId
 */
export const teamsImportService = (formData, teamsId) =>
  new Request(URL.API, URL.TEAMS + '/' + teamsId + URL.MEMBERS_IMPORT).post(
    formData,
  )

/**
 *
 * @param {*} collectionId
 * @param {*} sendLinkToNewMembersOnly
 */

export const teamsCollectionService = (
  collectionId,
  sendLinkToNewMembersOnly,
) =>
  new Request(
    URL.API,
    URL.LEARNER_AUTH + '/' + collectionId + URL.SENDMAIL_TO_MEMBERS,
  ).post({
    sendLinkToNewMembersOnly: sendLinkToNewMembersOnly,
  })
