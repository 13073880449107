const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'row',
  },
  page_mobile: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflowX: 'hidden',
  },
  chatField: { width: '100%', display: 'flex', flexDirection: 'column' },
  blurredPage: {
    filter: 'blur(5px)',
    pointerEvents: 'none', // To prevent interactions with the blurred content
    opacity: 0.6, // Optional: To reduce the opacity of the blurred content
  },
  upperPageView: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
})

export default styles
