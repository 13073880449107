import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { StyleSheet, Font } from '@react-pdf/renderer'
import { parseFontSize } from 'src/utils/parseFontSize'

import { useCourseSettings } from 'src/hooks/useCourseSettings'
import { GoogleFontList } from 'src/utils/googleFontList'

export const BackgroundColorRgb = value => {
  return `rgb(${value.backgroundColor.r},${value.backgroundColor.g},${value.backgroundColor.b})`
}
export const CardBackgroundColorRgb = value => {
  return `rgba(${value.cardBackgroundColor.r},${value.cardBackgroundColor.g},${value.cardBackgroundColor.b},${value.cardBackgroundColor.a})`
}
export const FontcolorRgb = value => {
  return `rgb(${value.fontColor.r},${value.fontColor.g},${value.fontColor.b})`
}
export const ButtonColorRgb = value => {
  return `rgb(${value.buttonColor.r},${value.buttonColor.g},${value.buttonColor.b})`
}

export const BackgroundImageUrl = () => {
  const { value } = useCourseSettings('theme')
  const backgroundImageUrl = value.bodyBackgroundImage
    ? value.bodyBackgroundImage.replace('svg', 'jpg')
    : ''
  return backgroundImageUrl
}

export const PdfStyleSheet = value => {
  const backgroundColorRgb = BackgroundColorRgb(value)
  const cardBackgroundColorRgb = CardBackgroundColorRgb(value)
  const fontcolorRgb = FontcolorRgb(value)
  const buttonColorRgb = ButtonColorRgb(value)

  Font.register({
    family: value.font,
    fonts: [
      {
        src: GoogleFontList.items.find(x => x.family == value.font).files
          .regular,
        fontWeight: 'normal',
      },
      {
        src: GoogleFontList.items.find(x => x.family == value.font).files[700]
          ? GoogleFontList.items.find(x => x.family == value.font).files[700]
          : GoogleFontList.items.find(x => x.family == value.font).files
              .regular,
        fontWeight: 'bold',
      },
      {
        src: GoogleFontList.items.find(x => x.family == value.font).files.italic
          ? GoogleFontList.items.find(x => x.family == value.font).files.italic
          : GoogleFontList.items.find(x => x.family == value.font).files
              .regular,
        fontStyle: 'italic',
      },
    ],
  })
  Font.register({
    family: value.font + 'Bold',
    src: GoogleFontList.items.find(x => x.family == value.font).files[700],
    fontWeight: 'bold',
  })
  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size)
    }

    return chunks
  }
  Font.register({
    family: 'Work Sans',
    src: GoogleFontList.items.find(x => x.family == 'Work Sans').files.regular,
    fontStyle: 'normal',
    fontWeight: 'normal',
  })
  Font.registerHyphenationCallback(word => {
    if (word.length > 16) {
      return chunkSubstr(word, 14)
    } else {
      return [word]
    }
  })
  return StyleSheet.create({
    h1: {
      fontSize: 24 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    h2: {
      fontSize: 22 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    h3: {
      fontSize: 20 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    h4: {
      fontSize: 18 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    h5: {
      fontSize: 16 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    h6: {
      fontSize: 14 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    p: {
      fontFamily: value.font,
      fontSize: 13 + parseFontSize(value.fontSize),
      fontColor: fontcolorRgb,
    },
    li: {
      fontFamily: value.font,
      fontSize: 13 + parseFontSize(value.fontSize),
      fontColor: fontcolorRgb,
      fontWeight:400
    },

    span: { fontFamily: value.font },

    ['.coverTitle']: {
      fontSize: 28,
      fontFamily: value.font,
      color: fontcolorRgb,
      backgroundColor: cardBackgroundColorRgb,
      borderRadius: 10,
      padding: 10,
    },

    page: {
      backgroundColor: backgroundColorRgb,
      minHeight: 400,
      color: fontcolorRgb,
      padding: 30,
    },

    view: { padding: 0, borderRadius: 10, width: '100%' },

    text: {
      margin: 12,
      marginBottom: 0,
      fontSize: 15 + parseFontSize(value.fontSize),
      fontFamily: value.font,
      fontColor: fontcolorRgb,
      zIndex: 20,
    },
    courseTitle: {
      margin: 12,
      paddingTop: 12,
      fontSize: 20 + parseFontSize(value.fontSize),
      fontFamily: value.font,
      fontWeight: 'bold',
      textAlign: 'center',
    },

    coverText: {
      margin: 12,
      fontSize: 13 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },

    coverImage: {
      width: 505,
      borderRadius: 10,
      margin: 12,
      marginBottom: 0,
    },

    informTitle: {
      margin: 12,
      marginLeft: 0,
      paddingTop: 24,
      fontSize: 16 + parseFontSize(value.fontSize),
      fontFamily: value.font,
      fontWeight: 'bold',
    },
    arrow: {
      position: 'absolute',
      right: 10,
      bottom: 10,
      zIndex: 10,
    },
    badge: {
      position: 'absolute',
      left: 10,
      bottom: 10,
      zIndex: 10,
      borderRadius: 10,
      overflow: 'hidden',
    },
    lastCardSocialIcons: {
      position: 'absolute',
      left: 75,
      bottom: 10,
      zIndex: 10,
    },

    backgroundImage: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      //height: '100%',
      zIndex: 10,
      width: 800,
      minHeight: 400,
    },
    logo: {
      width: 100,
      marginLeft: 25,
    },
    contentImage: {
      width: 505,
      borderRadius: 10,
      marginTop: 15,
      marginBottom: 15,
    },

    embed: {
      width: 300,
      height: 400,
      border: 0,
      backgroundColor: 'white',
    },
    pdfHolder: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    downloadButton: {
      margin: 16,
      display: 'block',
      background: '#0e53b7',
      textDecoration: 'none',
      color: 'white',
      padding: 8,
      paddingLeft: 24,
      paddingRight: 24,
      borderRadius: 10,
      fontWeight: 600,
    },
    bottomButtonHolder: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    scrollDownAnimation: {
      position: 'absolute',
      right: 2,
      bottom: '50%',
    },
    alert: { maxWidth: 300, marginBottom: 10 },
    //Inform
    informView: {
      padding: 15,
      paddingBottom: 0,
      paddingTop: 0,
    },
    //Question
    questionHolder: {
      padding: 15,
    },
    questionView: {
      backgroundColor: '#4e4e4e',
      //backgroundColor: buttonColorRgb,
      color: 'white',
      //color: backgroundColorRgb,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      padding: 6,
      width: '100%',
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 13 + parseFontSize(value.fontSize),
    },
    optionHolder: {
      backgroundColor: 'white',
      color: 'black',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      paddingBottom: 6,
      paddingTop: 12,
      paddingRight: 12,
      paddingLeft: 12,
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
    },
    optionView: {
      border: '1px solid #4e4e4e',
      borderRadius: 10,
      padding: 3,
      width: '100%',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 6,
    },
    optionAlphabet: {
      //borderRadius: 100,
      paddingTop: 2,
      width: 20,
      height: 18,
      fontSize: 12,
      color: '#4e4e4e',
      marginLeft: 3,
      marginRight: 3,
      textAlign: 'center',
      fontFamily: 'Work Sans',
      //fontWeight: 600,
    },
    optionText: {
      textAlign: 'left',
      paddingLeft: 10,
      borderLeft: '1px solid #4e4e4e',
      width: '100%',
      fontSize: 12 + parseFontSize(value.fontSize),
      fontFamily: value.font,
    },
    giveAnswerWithComment: {
      color: buttonColorRgb,

      padding: 6,
      width: '100%',
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 14 + parseFontSize(value.fontSize),
    },
    questionSocialIcons: {
      padding: 6,
      width: '100%',
      textAlign: 'center',
      marginLeft: 75,
      marginTop: 10,
    },
    // FREE FORM
    freeFormView: {
      width: '100%',
      height: 360,
      color: backgroundColorRgb,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    freeFormBg: {
      position: 'absolute',
      left: 0,
      top: 0,

      zIndex: 10,
    },
    freeFormText: {
      color: backgroundColorRgb,
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 26 + parseFontSize(value.fontSize),
      padding: 15,
      marginTop: -30,
    },
    freeFormContentImage: {
      width: 100,
      marginBottom: 10,
      borderRadius: 10,
      overflow: 'hidden',
    },

    // Input
    inputView: {
      width: '100%',
      height: 360,
      color: backgroundColorRgb,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputBg: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10,
    },
    inputText: {
      color: backgroundColorRgb,
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 26 + parseFontSize(value.fontSize),
      padding: 25,
      marginTop: -30,
    },
    inputContentImage: {
      width: 100,
      marginBottom: 10,
      borderRadius: 10,
      overflow: 'hidden',
    },
    inputSocialIcons: {
      position: 'absolute',
      left: '50%',
      marginLeft: -62,
      bottom: 0,
      textAlign: 'center',
    },

    // Numeric
    numericView: {
      width: '100%',
      height: 360,
      color: backgroundColorRgb,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    numericBg: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10,
    },
    numericText: {
      color: backgroundColorRgb,
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 26 + parseFontSize(value.fontSize),
      padding: 25,
      marginTop: -10,
    },
    numericContentImage: {
      width: 100,
      marginBottom: 10,
      borderRadius: 10,
      overflow: 'hidden',
    },
    numericSocialIcons: {
      position: 'absolute',
      left: '50%',
      marginLeft: -62,
      bottom: 0,
      textAlign: 'center',
    },

    // Star
    starView: {
      width: '100%',
      height: 360,
      color: backgroundColorRgb,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    starText: {
      color: fontcolorRgb,
      textAlign: 'center',
      fontFamily: value.font,
      fontSize: 24 + parseFontSize(value.fontSize),
      padding: 15,
      paddingTop: 10,
      alignItems: 'center',
    },
    starContentImage: {
      maxWidth: 200,
      marginTop: 5,
      borderRadius: 10,
      overflow: 'hidden',
    },
    starVector: {
      marginTop: 10,
    },
    // NotSupported
    notSupportedView: {
      width: '100%',
      height: 360,
      color: fontcolorRgb,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 30,
      fontSize: 14,
    },
  })
}
