import React from 'react'
import Html from 'react-pdf-html'
import { QuestionFieldNames } from 'src/model/question'
import URL from 'src/api/url'

import ReactPDF, {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Svg,
  Path,
  G,
  Rect,
} from '@react-pdf/renderer'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card, buttonColorRgb } = props
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'T',
    'U',
    'V',
    'Y',
    'Z',
  ]

  let srcUrl = ''

  if (
    card.mediaDtos &&
    card.mediaDtos.length > 0 &&
    card.mediaDtos[0].mediaType == 1 &&
    !card.mediaDtos[0].mediaUrl.includes('.gif')
  ) {
    if (
      card.mediaDtos[0].mediaUrl.includes('https') ||
      card.mediaDtos[0].mediaUrl.includes('data:image/png')
    ) {
      srcUrl = card.mediaDtos[0].mediaUrl
    } else {
      srcUrl = URL.CDN + card.mediaDtos[0].mediaUrl + '?not-from-cache-please2'
    }
  } else {
    srcUrl = ''
  }

  return (
    <View style={stylesheet.questionHolder}>
      {card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('.gif') && (
          <Image style={stylesheet.contentImage} src={srcUrl} />
        )}
      <View style={stylesheet.questionView}>
        <Html stylesheet={stylesheet} style={style}>
          {editor && editor.getContents && page !== 0
            ? editor.getContents()
            : card.text}
        </Html>
      </View>

      <View style={stylesheet.optionHolder}>
        {card[QuestionFieldNames.SELECTIONS].map((option, index) => (
          <View style={stylesheet.optionView}>
            <Text style={stylesheet.optionAlphabet}>{alphabet[index]}</Text>
            <Text style={stylesheet.optionText}>{option.text}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Inform
