import React, { useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import translator from '../../translate'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { registerMarketingInfo } from '../../api/sign-up'
import Preloader from '../../components/preloader'
import moment from 'moment-timezone'
import { Options } from 'src/model/user-marketing-info'
import {
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  TextField,
  Radio,
  Card,
  CircularProgress,
} from '@material-ui/core'
import { track, init, Identify, identify } from '@amplitude/analytics-browser'

import { get as getCookie } from 'es-cookie'
import { set as setCookie } from 'es-cookie'
import { userGetSelfAction } from 'src/reducers/user/action'

const RadioButton = withStyles({
  root: {
    '&$checked': {
      color: '#015FFB',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

function PurposeSelectionField(props) {
  const { classes } = props
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [purposeFilled, setPurposeFilled] = React.useState(false)
  const [purpose, setPurpose] = React.useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userGetSelfAction())

    if (getCookie('email')) {
      init(process.env.REACT_APP_AMPLITUDE_KEY, getCookie('email'))
      track('signed_up')

      if (window.Reflio) {
        window.Reflio.signup(getCookie('email'))
      }
    }
  }, [])

  const handleChange = (text, index) => {
    setValue(text)
    setSelectedIndex(index + 1)
  }

  const handleNext = () => {
    setPurposeFilled(true)
  }

  const handleContinue = async event => {
    setLoading(true)

    //const selectedText = this.state.value
    const selectedIndexofText = selectedIndex
    let timeZone = 'None'

    if (moment.tz.guess() != undefined) {
      timeZone = moment.tz.guess()
    }
    const data = {
      selectedIndexofText,
      purpose,
      timeZone,
    }

    track('onboarding_complete_event')
    let response = await registerMarketingInfo(data)

    if (response.statusCode == 200) {
      setLoading(false)
      window.Intercom('update', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'a1mmx6vo',
        purpose_text: purpose,
        purpose_selection: selectedIndexofText,
      })
      if (
        getCookie('redirectUrl') != '' &&
        getCookie('redirectUrl') != null &&
        getCookie('redirectUrl') != undefined
      ) {
        setCookie('redirectUrl', '')
        window.location.href = getCookie('redirectUrl')
      } else {
        window.location.href = '/create-course-with-ai'
      }
    }
    setLoading(false)
  }

  return (
    <Grid container component="main" className={classes.onBoardingRoot}>
      {loading && <Preloader process={loading} />}
      <CssBaseline />
      <Grid
        item
        xs={5}
        sm={5}
        md={5}
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        <div
          className={classes.purpose_leftSide}
          style={{
            backgroundImage: `url(/assets/purpose-left.png)`,
          }}
        >
          {purposeFilled ? (
            <Typography
              fontSize="28px"
              textAlign={'center'}
              style={{ marginTop: '54px' }}
            >
              What would set us <br />
              apart for you?{' '}
            </Typography>
          ) : (
            <Typography
              fontSize="28px"
              textAlign={'center'}
              style={{ marginTop: '54px' }}
            >
              What is your <br /> main use case?
            </Typography>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={7}
        sm={7}
        md={7}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div className={classes.paper_purposeSelectionField}>
          {!purposeFilled && (
            <>
              <div className={classes.paperText}>
                <Typography fontSize="20px">
                  We’ll share best practices accordingly.{' '}
                </Typography>

                <Box m={2}></Box>
              </div>
              {Options.map((option, index) => (
                <Card
                  className={classes.formGroupCard}
                  style={{
                    backgroundColor:
                      value == option.text
                        ? 'rgba(1, 95, 251,0.02) '
                        : 'rgba(255, 255, 255)',
                  }}
                  onClick={() => handleChange(option.text, index)}
                  key={'option_' + index}
                >
                  <div className={classes.formGroup}>
                    <RadioButton
                      checked={value === option.text}
                      value={option.text}
                    />
                    <Typography fontSize="15px" style={{ marginBottom: 4 }}>
                      {option.text}
                    </Typography>
                  </div>
                </Card>
              ))}
              <div className={classes.onBoarding_FX}></div>

              <Button
                onClick={() => {
                  handleNext()
                }}
                variant="contained"
                className={classes.submit}
              >
                {translator._('signUp.continue')}
              </Button>
            </>
          )}
          {purposeFilled && (
            <>
              <div className={classes.paperText}>
                {' '}
                <Typography
                  fontSize="20px"
                  //style={{ fontWeight: 500, fontSize: '1.1rem' }}
                >
                  Compared to similar products you've tried before, what do you
                  expect to see differently with our solution?
                </Typography>
                <Box m={2}></Box>
              </div>
              <TextField
                className={classes.form}
                multiline
                inputProps={{ style: { fontSize: '15px' } }} // font size of input text
                InputLabelProps={{ style: { fontSize: '15px' } }}
                placeholder="We’ll share personalized suggestions accordingly."
                rows={10}
                onChange={e => {
                  setPurpose(e.target.value)
                }}
              ></TextField>
              {loading && (
                <>
                  <CircularProgress style={{ marginTop: 24 }} />
                </>
              )}
              <div className={classes.onBoarding_FX}></div>

              {!loading && (
                <>
                  <Button
                    onClick={() => {
                      handleContinue()
                    }}
                    variant="contained"
                    className={classes.submit_bottom}
                  >
                    Let's start creating courses!{' '}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(PurposeSelectionField)
