import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

import { getUserLimits } from 'src/api/limit'
import { LimitTypeEnum } from 'src/model/limit'

import {
  Paper,
  Grid,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import {
  openaiSendCourseCreationGetTitleRequestAction,
  openaiSendCourseCreationRequestAction,
  openaiSendCourseCreationGetOutlineRequestAction,
} from 'src/reducers/assignment/action'

import Outline from './outline'
import StepDescription from './step-description'
import StepTitle from './step-title'
import CardHeader from './card-header'
import StepperSkeleton from './stepper'
import AiLoading from './ai-loading'
import AiCourseReady from './ai-course-ready'
import AICreationLimitModal from 'src/components/ai-creation-limit-modal/index'

import { track } from '@amplitude/analytics-browser'

import { startConnection } from 'src/utils/signalR-connection/signalR-connection'
import { addCourseCreationMessageReceivedListener } from 'src/utils/signalR-connection/signalR-connection'
import themePresets from 'src/model/theme/presets'
import { courseCreationProgressService } from 'src/api/assignment'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
const CreateCourseWithAi = props => {
  const history = useHistory()
  const { classes } = props
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [description, setDescription] = useState()
  const [titleOptions, setTitleOptions] = useState()
  const [count, setAiCount] = useState()
  const [roleType, setRoleType] = useState()
  const [limit, setLimit] = useState()
  const [currentCredit, setCurrentCredit] = useState()
  const [refreshesAt, setRefreshesAt] = useState()
  const [title, setTitle] = useState('')
  const [selectedTitle, setSelectedTitle] = useState()
  const [selectedTitleIndex, setSelectedTitleIndex] = useState()
  const [loading, setLoading] = useState(false)
  const [limitLoading, setLimitLoading] = useState(true)
  const [themeSettings, setThemeSettings] = useState({
    ...themePresets[0].theme,
    bodyBackgroundImage: '',
    ...themePresets[0].colors[themePresets[0].defaultColor],
  })
  const [outlineOptions, setOutlineOptions] = useState([])
  const [outlineState, setOutlineState] = useState()
  const [outlineOptionsLength, setOutlineOptionsLength] = useState()
  const [hasOwnTitle, setHasOwnTitle] = useState(false)
  const [profession, setProfession] = useState()
  const [audience, setAudience] = useState()
  const [openFinishScreen, setOpenFinishScreen] = useState(false)
  const [courseData, setCourseData] = useState()
  const [errorMessage, setErrorMessage] = useState(false)
  const [gateway, setGateway] = useState('No gateway. Anyone can access.')
  const [courseCollectionId, setourseCollectionId] = useState(
    window.location.href.split('create-course-with-ai/')[1] != '' &&
      window.location.href.split('create-course-with-ai/')[1] != undefined
      ? window.location.href.split('create-course-with-ai/')[1]
        ? window.location.href.split('create-course-with-ai/')[1].split('/')[0]
        : '00000000-0000-0000-0000-000000000000'
      : null,
  )
  const [language, setLanguage] = useState('English')
  const [purpose, setPurpose] = useState('Link')
  const [password, setPassword] = useState()
  const [gatewaySelection, setGatewaySelection] = useState({
    gateway: gateway,
    password: password,
  })
  const [selectedPreset, setPreset] = useState('sketch_art')
  const [imageWillBeGenerated, setImageWillBeGenerated] = useState(true)
  const [progressedCardCount, setProgressedCardCount] = useState(0)
  const [usedCreditsCount, setUsedCreditsCount] = useState(0)
  const [progressOutline, setProgressOutline] = useState(0)
  useEffect(() => {
    //This useEffect hook is used for getting user's limit count for course creation.
    setLimitLoading(true)
    getUserLimits().then(response => {
      let count = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).count

      let limit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).limit.upperLimit
      if (limit == -1) {
        limit = 100
      }
      let refreshedAt = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).refreshedAt

      setRoleType(response.data[0].limit.roleType)
      setRefreshesAt(refreshedAt)
      setLimit(limit)
      setAiCount(count)
      setCurrentCredit(limit - count)
      setLimitLoading(false)
    })
  }, [])

  useEffect(() => {
    //This useEffect hook is used for declaring the users credit "AFTER" outline options return.
    //We are creating course card prompts with outlines and subTitles in this outlines.
    //If one outline has more then one subTitle, this means we will create cards with count of "subTitles length".
    let subHeaderLength = 0
    if (outlineOptions) {
      outlineOptions.forEach(element => {
        subHeaderLength =
          element.subHeaders.length != 0
            ? subHeaderLength + element.subHeaders.length
            : subHeaderLength + 1
      })
      if (subHeaderLength != outlineOptionsLength) {
        setAiCount(count + subHeaderLength - outlineOptionsLength)
        setOutlineOptionsLength(subHeaderLength)
      }
    }
  }, [outlineOptions, outlineState])

  useEffect(() => {
    //Start socket connection for media update
    startConnection()
  }, [])

  const handleSocketMessage = data => {
    let processedCards = []
    const outlineLength = outlineOptionsLength
    const courseId = data.id

    addCourseCreationMessageReceivedListener(async message => {
      if (
        processedCards.find(x => x == message[0]) == undefined &&
        message.length == 2 &&
        message[1] == courseId
      ) {
        processedCards = [...processedCards, message[0]]

        setProgressedCardCount(processedCards.length)

        if (outlineLength == processedCards.length) {
          setLoading(false)
          /*  dispatch(
            generateQuizzesByCourseIdAction(courseId.toString(), response => {
              
            }),
          ) */
        }
      }
    })
  }

  const handleChange = (titleOption, index) => {
    setTitle(titleOption)
    setSelectedTitle(titleOption)
    setSelectedTitleIndex(index)
  }
  const handleGatewaySelection = e => {
    setGatewaySelection(e)
  }
  const sendTitleRequest = e => {
    setLoading(true)
    if (e != 'Generate More') {
      track('ai_idea_described', {
        course_type: 'Link',
        language: language,
      })
    }
    dispatch(
      openaiSendCourseCreationGetTitleRequestAction(
        profession,
        audience,
        description,
        language,
        purpose,
        response => {
          if (response.error.message == null) {
            let choices = response.choices[0].text || ''
            choices = choices
              .replace(`\n\n`, '')
              .split('\n')
              /* .split('.')[1]
               .replaceAll(`"`, '')
              .replace(' ', '') */
              .filter(choice => choice != '')
            choices = choices.map(title =>
              title.split('.')[1].replaceAll(`"`, '').replace(' ', ''),
            )
            //Gets title from request return and splits the options.
            //Title response is usually comes up with "." and we are using this as our split point.
            setTitleOptions(choices)
            setAiCount(count + 1) //Ai credit - 1
            e != 'Generate More' && handleNext()
          } else {
            setErrorMessage(true)
          }
          setUsedCreditsCount(usedCreditsCount + 1)
          setLoading(false)
        },
      ),
    )
  }

  const sendOutlineRequest = e => {
    setLoading(true)

    setProgressOutline(progressOutline + 1)

    setAiCount(count + 1)
    if (e != 'Generate More') {
      track('ai_title_selected')
    }
    dispatch(
      openaiSendCourseCreationGetOutlineRequestAction(
        profession,
        audience,
        title,
        language,
        purpose,
        response => {
          if (response.error.message == null) {
            let outlineChoices
            outlineChoices = response.choices[0].text
              .replace('\n\n\n', '\n\n') //sometimes outlines could come with starter \n\n\n statement. We replace it to getting prepared for next split step.
              .split('\n\n') //after this step we should have a list like this outlineTitle \n outlineSubHeader1 \n outlineSubHeader2 ..
              .filter(x => x != ' ')
              .filter(x => x != '')
              .filter(
                x => x.toLowerCase().includes(title.toLowerCase()) != true,
              ) //sometimes outlines could come with title which user declares on first steps. We do not want this to be shown on ui.

            let outline = outlineObjectListCreator(outlineChoices)
            setOutlineOptions(outline)
            e != 'Generate More' && handleNext()
          } else {
            setErrorMessage(true)
          }
          setUsedCreditsCount(usedCreditsCount + 1)
          setLoading(false)
          setProgressOutline(0)
        },
      ),
    )
  }
  useEffect(() => {
    let newProgressOutline = progressOutline < 95 ? progressOutline : 85
    if (newProgressOutline > 0) {
      const timer = setTimeout(() => {
        setProgressOutline(newProgressOutline + 1)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [progressOutline])

  const outlineObjectListCreator = outline => {
    const x = outline.map(function (option, i) {
      // outline =  outlineTitle \n outlineSubHeader1 \n outlineSubHeader2
      let header = ''

      let subHeaders = []

      let outlineList = option.split('\n')

      if (outlineList.length > 0) {
        for (let index = 0; index < outlineList.length; index++) {
          if (outlineList[index] != ' ') {
            if (index == 0) {
              //if index equals to 0 this means it is he outlineTitle. Response is usually comes up with "." and we are using this as our split point.
              header = outlineList[index].split('. ')[1]
                ? outlineList[index].split('. ')[1]
                : outlineList[index]
            } else {
              //this part is for outlineSubHeaders. Response is usually comes up with "." and we are using this as our split point.
              let outlineSubheader = outlineList[index].split('. ')[
                outlineList[index].split('. ').length - 1
              ]
              subHeaders = [...subHeaders, outlineSubheader]
            }
          }
        }
      }

      return {
        id: 'header_' + i,
        header: header,
        subHeaders: subHeaders,
      }
    })
    let subHeaderLength = 0
    //We are declaring the estimated credits that user going to need for completing the course creation.
    x.forEach(element => {
      subHeaderLength =
        element.subHeaders.length != 0
          ? subHeaderLength + element.subHeaders.length
          : subHeaderLength + 1
    })
    setAiCount(count + subHeaderLength - outlineOptionsLength) // this is needed for the case if user creates more than one request for outline.
    setOutlineOptionsLength(subHeaderLength)

    return x
  }

  const handleCreateCourse = () => {
    let outlines = outlineOptions
    let generateImage
    setLoading(true)
    setOpenFinishScreen(true)
    const imageGenerator = 'leonardo-ai'

    const variables = JSON.stringify({
      title: title,
      description: description,
      audience: audience,
      profession: profession,
    })
    generateImage = imageWillBeGenerated

    dispatch(
      openaiSendCourseCreationRequestAction(
        title,
        outlines,
        profession,
        audience,
        description,
        themeSettings,
        courseCollectionId,
        variables,
        gatewaySelection,
        language,
        purpose,
        imageGenerator,
        generateImage,
        response => {
          setCourseData(response.data)

          track('AI_course_created', {
            courseId: response.data.id,
            creditUsed: usedCreditsCount + outlineOptionsLength,
          })

          handleSocketMessage(response.data)

          let checkProgress = setInterval(() => {
            courseCreationProgressService(response.data.id).then(res => {
              if (res.data.cardCreationProgress == 100) {
                clearInterval(checkProgress)
                setLoading(false)
              }
            })
          }, 10000)
        },
      ),
    )
  }
  function getSteps() {
    return [
      'Describe your mini-course idea.',
      'Select the title.',
      'Conclude the outline.',
    ]
  }

  const inputFocus = id => {
    document.getElementById(id).focus()
  }

  const addOwnTitle = () => {
    setTitleOptions([...titleOptions, ' '])
    setHasOwnTitle(true)
    handleChange(' ', titleOptions.length)
  }

  const saveOutline = outline => {
    setOutlineState(Date.now())
    setOutlineOptions(outline)
  }

  const handleTitleItemChange = (e, index) => {
    let newTitleOptions = [...titleOptions]
    newTitleOptions[index] = e.target.value

    setTitleOptions(newTitleOptions)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            {loading == false && (
              <StepDescription
                errorMessage={errorMessage}
                profession={profession}
                setProfession={setProfession}
                audience={audience}
                setAudience={setAudience}
                description={description}
                setDescription={setDescription}
                sendTitleRequest={sendTitleRequest}
                sendOutlineRequest={sendOutlineRequest}
                language={language}
                setLanguage={setLanguage}
                purpose={purpose}
                setPurpose={setPurpose}
              />
            )}
            {loading == true && <CircularProgress size={25} />}
          </>
        )
      case 1:
        return (
          <div>
            {loading == false && (
              <>
                <StepTitle
                  errorMessage={errorMessage}
                  titleOptions={titleOptions}
                  handleChange={handleChange}
                  selectedTitle={selectedTitle}
                  hasOwnTitle={hasOwnTitle}
                  handleTitleItemChange={handleTitleItemChange}
                  inputFocus={inputFocus}
                  addOwnTitle={addOwnTitle}
                  sendOutlineRequest={sendOutlineRequest}
                  selectedTitleIndex={selectedTitleIndex}
                />
              </>
            )}
            {loading == true && (
              <>
                <Typography>{progressOutline}%</Typography>
                <LinearProgress
                  variant="buffer"
                  value={progressOutline}
                  valueBuffer={progressOutline + Math.random() * 10}
                />
              </>
            )}
          </div>
        )
      case 2:
        return loading == false ? (
          <>
            <Outline
              outline={outlineOptions}
              saveOutline={saveOutline}
              handleNext={handleNext}
              language={language}
              handleCreateCourse={handleCreateCourse}
              selectedPreset={selectedPreset}
              setPreset={setPreset}
              outlineOptionsLength={outlineOptionsLength}
              count={count}
              limit={limit}
              roleType={roleType}
              imageWillBeGenerated={imageWillBeGenerated}
              setImageWillBeGenerated={setImageWillBeGenerated}
            />
          </>
        ) : (
          <>
            <Typography>{progressOutline}%</Typography>
            <LinearProgress
              variant="buffer"
              value={progressOutline}
              valueBuffer={progressOutline + Math.random() * 10}
            />
          </>
        )

      default:
        return ''
    }
  }
  const handleStep = step => () => {
    if (step == 1) {
      if (titleOptions && titleOptions.length != 0) {
        setActiveStep(step)
      }
    } else if (step == 2) {
      if (outlineOptions && outlineOptions.length != 0) {
        setActiveStep(step)
      }
    } else {
      setActiveStep(step)
    }
  }

  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleClickRedirect = type => {
    if ((type = 'Home Page')) {
      history.push('/')
    } else {
      history.push('/pricing')
    }
  }

 /*  return (
    <Grid container className={classes.page}>
      <Grid  item md={6} className={classes.stepperGrid}>
     
        <Paper style={{marginTop:200}}>
          <Typography align='center' variant='h1' ><SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon></Typography>
        <Typography align='center'>Due to workload, AI assistant cannot provide service for a short time. </Typography>
        <Typography align='center'>Please try again later.</Typography>
        </Paper>
      </Grid>
    </Grid>
  ) */
  return (
    <Grid container className={classes.page}>
      <Grid item md={6} className={classes.stepperGrid}>
        <Paper className={classes.stepperHolder}>
          {limitLoading ? (
            <div className={classes.page}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <CardHeader
                limit={limit}
                count={count}
                refreshesAt={refreshesAt}
                purpose={purpose}
                activeStep={activeStep}
              />

              {currentCredit <= 5 ? (
                <div className={classes.page}>
                  <AICreationLimitModal
                    handleClickRedirect={handleClickRedirect}
                    refreshesAt={refreshesAt}
                    roleType={roleType}
                    type={'stepper'}
                  />
                </div>
              ) : (
                <>
                  {openFinishScreen == false && (
                    <>
                      <StepperSkeleton
                        activeStep={activeStep}
                        steps={steps}
                        handleStep={handleStep}
                        getStepContent={getStepContent}
                        titleOptions={titleOptions}
                        outline={outlineOptions}
                        sendTitleRequest={sendTitleRequest}
                        sendOutlineRequest={sendOutlineRequest}
                        loading={loading}
                      />
                    </>
                  )}
                  {openFinishScreen == true && loading == true && (
                    <AiLoading
                      progressedCardCount={progressedCardCount}
                      outlineOptionsLength={outlineOptionsLength}
                    />
                  )}

                  {openFinishScreen == true && loading == false && (
                    <AiCourseReady
                      history={history}
                      courseData={courseData}
                      purpose={purpose}
                      dispatch={dispatch}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CreateCourseWithAi)
