import React from 'react'
import { Box, Typography } from '@material-ui/core'
import URL from 'src/api/url'
import translator from 'src/translate'

const CaseZero = () => {
  return (
    <div>
      <Box m={2}></Box>
      <p>
        {translator._('customDomain.detailedInformation')}
        {'  '}
        <a href={`${URL.APP_URL}7tX3umAUDUeH4lM9S2Maew/1`} target="_blank">
          {translator._('customDomain.thisLink')}
        </a>
        {'.'}
      </p>
      <Box m={2}></Box>

      <Typography variant="subtitle2">
        {translator._('customDomain.cName')}
      </Typography>
    </div>
  )
}

export default CaseZero
