const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textfield: {
    '& fieldset': { border: 'none' },
    width: '100%',
    marginBottom: '12px',
    padding: 0,
  },
  textfieldInput: {
    color: '#002E47',
    fontWeight: 700,
    fontSize: '23px',
    lineHeight: '42px',
    textAlign: 'center',
    padding: 0,
  },
  divider: {
    backgroundColor: '#015FFB',
    width: '280px',
    marginBottom: '12px',
    height: '3px',
  },
})

export default styles
