import React from 'react'
import styles from '../style'
import { Typography, withStyles } from '@material-ui/core'
import translator from 'src/translate'
import { AnswerItemType } from 'src/model/question'
const BasicOptions = [
  {
    name: 'single-select',
    icon: '/assets/icons/task/survey-single-select.png',
    title: 'Single choice',
    description: translator._('tasks.singleSelectFeedback.description'),
    description2: translator._('tasks.singleSelectFeedback.description2'),
    exampleImage: '/assets/icons/task/survey-single-select-example.png',
    dataType: AnswerItemType.SURVEYSINGLECHOICE,
  },
  {
    name: 'multiple-select',
    icon: '/assets/icons/task/survey-multiple-select.png',
    title: 'Multiple choice',
    description: translator._('tasks.multipleSelectFeedback.description'),
    description2: translator._('tasks.multipleSelectFeedback.description2'),
    exampleImage: '/assets/icons/task/survey-multiple-select-example.png',
    dataType: AnswerItemType.SURVEYMULTIPLECHOICE,
  },
]
const MultipleChoice = props => {
  const { classes, saveType, entity } = props

  return (
    <div container className={classes.list}>
      <Typography className={classes.listTitle}>SURVEY</Typography>

      {BasicOptions.map((option, index) => {
        return (
          <div
            className={classes.listItems}
            onClick={() => saveType(option.dataType)}
          >
            <img
              alt="cardIcon"
              src={option.icon}
              className={classes.listImage}
            />
            <Typography className={classes.listText}>{option.title}</Typography>
          </div>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(MultipleChoice)
