import React, { useState } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import styles from './styles'
import data from './data'
import { patchSetting } from 'src/hooks/useCardSettings'
import { useDispatch } from 'react-redux'
const StyledRadio = withStyles({
  root: {
    //color: green[400],
    '&$checked': {
      color: '#015FFB',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const Selections = props => {
  const { classes, handleBadgeUsageType, badgeUsageType } = props
  const handleClickOnBadge = badgeId => {
    handleBadgeUsageType(badgeId)
  }
  return (
    <div className={classes.root}>
      {data.map(item => (
        <div
          className={classes.root_content}
          onClick={() => handleClickOnBadge(item.id)}
          style={{
            border:
              badgeUsageType == item.id
                ? '1px solid #015FFB'
                : '1px solid #E0E0E0',
          }}
        >
          <div>
            <StyledRadio
              checked={badgeUsageType == item.id}
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
            />
          </div>

          <div className={classes.root_content_text}>
            <Typography className={classes.root_content_text_title}>
              {item.title}
            </Typography>
            <Typography className={classes.root_content_text_description}>
              {item.description}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(Selections)
