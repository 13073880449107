import React, { useState } from 'react'
import styles from './styles'

import { withStyles } from '@material-ui/core/styles'
import { Typography, Box, Button, Divider, Card } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Lottie from 'react-lottie'

import animationData from 'src/lotties/AI-ready-v2_small.json'
import URL from 'src/api/url/'
import { track } from '@amplitude/analytics-browser'
import MyDoc from 'src/pages/create-mini-course/carousel-view/full-pdf-download'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import AiCarouselReadyPopupView from './ai-carousel-ready-popup'
import animationDataMobile from 'src/lotties/AI-ready-v2-mobile_small.json'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const AiCourseReady = props => {
  const { history, courseData, purpose, dispatch, classes } = props
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const mobileLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataMobile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const openFreshDesk = () => {
    window.FreshworksWidget(
      'identify',
      'ticketForm',
      {
        name: window.CONSTANTS.NAME + ' ' + window.CONSTANTS.SUR_NAME,
        email: window.CONSTANTS.EMAIL,
      },
      {},
    )

    window.FreshworksWidget('open', 'ticketForm', {})
  }
  const onClickButton = () => {
    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <AiCarouselReadyPopupView
              courseData={courseData}
              dispatch={dispatch}
            />
          ),
          {
            hideCloseButton: false,
            callback: e => {},
          },
        ),
      ),
    )

    track('linkedin_carousel_download')
  }
  return (
    <>
      <div
        className={classes.nonMobileViewForFinishScreen}
        style={{ textAlign: 'center' }}
      >
        <Typography variant="h4">
          Your engaging mini-course is ready!
        </Typography>
        <Box m={3}></Box>
        <Typography className={classes.gatewayTooltipStyle}>
          You can enrich it with questions, images, videos, revisions,
          <br />
          customized endings, etc., to make the content uniquely yours.
        </Typography>

        <Typography className={classes.gatewayTooltipStylePc}>
          You can make revisions on your dashboard, which is optimized for
          desktop browsers. Visit your dashboard on a desktop browser to see the
          full functionality.
        </Typography>
        <Box m={2}></Box>

        <Button
          onClick={() => {
            //history.push('/mini-course/' + courseData.id)
            history.push('/')
            track('ai_course_created_edited', {
              courseId: courseData.id,
            })
          }}
          variant="contained"
          className={classes.editButton}
        >
          <span style={{ fontSize: '14px' }}>Edit Mini-Course</span>
        </Button>
        <Box m={2}></Box>
        <div
          className={classes.nonMobileViewForFinishScreen}
          onClick={() => {
            history.push('/mini-course/' + courseData.id)
            track('ai_course_created_edited', {
              courseId: courseData.id,
            })
          }}
        >
          <Lottie options={defaultOptions} />
        </div>

        <Box m={2}></Box>
      </div>
      <div className={classes.mobileViewForFinishScreen}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '25px',
          }}
        >
          <img src="/assets/tooltip_2x.png" />
          <Box m={1}></Box>
          <Lottie options={mobileLottieOptions} width={300} height={182} />
        </div>
      </div>

      <div className={classes.mobileViewForFinishScreen}>
        <Box m={2}></Box>
        <Divider />
        <Box m={2}></Box>
        <Typography
          variant="h4"
          style={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 600,
            color: '#002E47',
          }}
        >
          Your engaging mini-course <br /> is ready!🎉
        </Typography>
        <Box m={2}></Box>
        {courseData && purpose == 'Carousel' && (
          <div className={classes.carouselViewButton}>
            <Button
              variant="contained"
              onClick={() => onClickButton()}
              className={classes.carouselEditButton}
            >
              <span style={{ fontSize: '14px' }}>Ok, I got it 👍</span>
            </Button>
          </div>
        )}
        {courseData && (purpose == 'Link' || purpose == 'Embed') && (
          <div className={classes.carouselViewButton}>
            <Button
              href={courseData ? URL.APP_URL + courseData.code : ''}
              target="_blank"
              variant="contained"
              className={classes.viewButton}
              onClick={() => {
                track('ai_mobile_check_content', {
                  courseId: courseData.id,
                })
              }}
            >
              Check Content
            </Button>
          </div>
        )}

        <Box m={6}></Box>
        <Typography
          variant="h4"
          style={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 600,
            color: '#002E47',
          }}
        >
          Additional Resources
        </Typography>
        <Box m={2}></Box>
        <Card
          className={classes.redirectCards}
          onClick={() => {
            window.open('https://minicoursegenerator.com/show-cases/', '_blank')
          }}
        >
          <img src="/assets/use-cases.svg" />
          <Box m={1}></Box>
          <div>
            <Typography
              style={{ fontWeight: 600, fontSize: '16px', color: '#002E47' }}
            >
              Show-Cases
            </Typography>
            <Typography
              style={{ fontWeight: 500, fontSize: '12px', color: '#637381' }}
            >
              to check mobile experience <br /> for end-users(audience)
            </Typography>
          </div>
          <Box m={1}></Box>
          <div style={{ flexGrow: 1 }}></div>
          <ArrowForwardIosIcon />
        </Card>

        <Box m={2}></Box>
        <Card
          className={classes.redirectCards}
          onClick={() => {
            window.open('https://minicoursegenerator.com/use-cases/', '_blank')
          }}
        >
          <img src="/assets/show-cases.svg" />
          <Box m={1}></Box>
          <div>
            <Typography
              style={{ fontWeight: 600, fontSize: '16px', color: '#002E47' }}
            >
              Use-Cases
            </Typography>
            <Typography
              style={{ fontWeight: 500, fontSize: '12px', color: '#637381' }}
            >
              to learn best practices of <br /> mini-courses
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <Box m={1}></Box>
          <ArrowForwardIosIcon />
        </Card>
      </div>
    </>
  )
}

export default withStyles(styles)(AiCourseReady)
