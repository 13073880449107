import React, { useEffect, useState, useRef } from 'react'
import styles from './style'
import propTypes from './prop'
import { componentBoxHideAction } from 'src/reducers/component-box/action'
import { openaiSendCardRequestAction } from 'src/reducers/assignment/action'
import AICreationLimitModal from 'src/components/ai-creation-limit-modal/index'
import {
  Grid,
  withStyles,
  Button,
  Typography,
  Alert,
  Card,
  Box,
  Paper,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

import { getUserLimits } from 'src/api/limit'
import { LimitTypeEnum } from 'src/model/limit'
import OptionsList from './options-list'
import { useCourse, useCourseSettings } from 'src/hooks/useCourseSettings'
const SETTING_KEY = 'language'
const OpenAiPopupWithCardText = props => {
  const {
    classes,
    item,
    dispatch,
    editorValue,
    editor,
    entity,
    onBlur,
    onChange,
    updatedMarkdownText,
    editorType,
    onChangeMarkDown,
  } = props
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)
  const myRef = useRef(null)

  const languages = window.CONSTANTS.LANGUAGES
  const language = exists
    ? languages.filter(x => x.countryCode == value.selected)[0].name
    : 'English'

  //const executeScroll = () => myRef.current.scrollIntoView()
  const [options, setOptions] = useState()
  //const [optionCount, setOptionCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [roleType, setRoleType] = useState()

  const [count, setCount] = useState('')
  const [limit, setLimit] = useState('')

  const [refreshesAt, setRefreshesAt] = useState('')
  const [limitLoading, setLimitLoading] = useState(true)
  const [emptyChoices, setEmptyChoices] = useState(false)
  const [refreshDateAvaliable, setRefreshDateAvaliable] = useState(true)

  const courseId = window.location.href.split('mini-course/')[1]

  useEffect(() => {
    let aiCount
    let aiLimit
    let aiRefreshesAt
    getUserLimits().then(response => {
      aiCount = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).count

      aiLimit = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).limit.upperLimit

      aiRefreshesAt = response.data.find(
        x => x.limit.type == LimitTypeEnum.AICourseCreation,
      ).refreshedAt

      if (aiLimit == -1) aiLimit = 1000

      setRoleType(response.data[0].limit.roleType)
      setRefreshesAt(aiRefreshesAt)
      setLimit(aiLimit)
      setCount(aiCount)
      setLimitLoading(false)
      aiRefreshesAt.includes('2022') && setRefreshDateAvaliable(false)
      if (aiCount < aiLimit) {
        setLoading(true)
        sendCardRequest()
      }
    })
  }, [entity])

  const handleClickRedirect = () => {
    dispatch(componentBoxHideAction())
  }

  const getStarterText = () => {
    if (editorType == 'markdownEditor') {
      switch (item.image) {
        case 'summerize':
          return `Summarize and shorten the following text. Always provide the content in markdown format.  Provide the output without any additional comments and with the ${language} language. Add emojis when possible. Here is the text: ${updatedMarkdownText}`

        case 'complete':
          return `Always provide the content in markdown format. Do not change the prompt text and do not add the prompt text into your response. Provide the output without any additional comment and with the ${language} language.. Add emojis when possible. Complete the rest of this text: ${updatedMarkdownText}`

        case 'correct':
          return `Grammarly, please correct the following text. Always provide the content in markdown format.  Provide the output without any additional comments and with the ${language} language. Add emojis when possible. Here is the text: ${updatedMarkdownText}`
        case 'rewrite':
          return `Paraphrase the following text. Always provide the content in markdown format.  Provide the output without any additional comments  and with the ${language} language. Add emojis when possible. Here is the text:  ${updatedMarkdownText}`
        default:
          return ''
      }
    } else {
      switch (item.image) {
        case 'summerize':
          //return `Summarize and shorten the following text with html tags and do not change the header tags: ${editorValue}`
          return `Summarize and shorten the following text with html tags and provide content with html tags and with the ${language} language. Please do not change the headers and provide the output without any additional comments : ${editor.getContents()}`

        case 'complete':
          return `Do not change the following text and do not add the text into your response. Provide the output without any additional comments and with the ${language} language. Complete the rest of this text and provide content with html tags: ${editor.getContents()}`

        case 'correct':
          return `Grammarly, please correct the following text with html tags and provide content with html tags. Please do not change the headers and provide the output without any additional comments and with the ${language} language: ${editor.getContents()}`
        //return `Grammarly, please correct the following text with html tagsand do not change the header tags: ${editorValue}`
        case 'rewrite':
          return `Paraphrase the following text with html tags and provide content with html tags. Please do not change the headers and provide the output without any additional comments and with the ${language} language: ${editor.getContents()}`
        //return `Paraphrase the following text with html tags and do not change the header tags: ${editorValue}`
        default:
          return ''
      }
    }
  }

  const setOptionsForTreeOptioned = (response, e) => {
    if (
      response.choices[0].text == '' &&
      response.choices[1].text == '' &&
      response.choices[2].text == ''
    ) {
      setEmptyChoices(true)
      setLoading(false)
      if (e == 'generate new options') {
        setOptions(response.choices.filter(x => x.text != ''))
      } else {
        !options && setOptions(response.choices.filter(x => x.text != ''))
        options &&
          setOptions(options.concat(response.choices.filter(x => x.text != '')))
      }
    } else {
      if (e == 'generate new options') {
        setOptions(response.choices.filter(x => x.text != ''))
      } else {
        !options && setOptions(response.choices.filter(x => x.text != ''))
        options &&
          setOptions(options.concat(response.choices.filter(x => x.text != '')))
      }
      setLoading(false)
      setCount(count + 1)
    }
  }

  const setOptionsForOneOptioned = (response, e) => {
    if (
      response.choices[0].text == '' &&
      response.choices[1].text == '' &&
      response.choices[2].text == ''
    ) {
      setEmptyChoices(true)
      setLoading(false)

      !options && setOptions(response.choices.filter(x => x.text != ''))
      options &&
        setOptions(options.concat(response.choices.filter(x => x.text != '')))
    } else {
      !options && setOptions([response.choices.filter(x => x.text != '')[0]])
      setLoading(false)
      setCount(count + 1)
    }
  }

  const sendCardRequest = e => {
    const requestText = getStarterText()

    dispatch(
      openaiSendCardRequestAction(
        requestText,
        courseId,
        entity.id,
        response => {
          if (item.image == 'correct') {
            setOptionsForOneOptioned(response, e)
          } else {
            setOptionsForTreeOptioned(response, e)
          }
        },
      ),
    )
  }

  const addTextToTextArea = (title, text) => {
    if (editorType == 'markdownEditor') {
      if (title == 'summerize' || title == 'rewrite' || title == 'correct') {
        onChangeMarkDown(text)
        onBlur(text)
      } else if (title == 'complete') {
        onChangeMarkDown(updatedMarkdownText + ' ' + text)
        onBlur(updatedMarkdownText + text)
      }
    } else {
      const htmlValue = editor.getContents()

      if (title == 'summerize' || title == 'rewrite' || title == 'correct') {
        onChange(text)
        onBlur(text)
        editor.setContents(text)
      } else if (title == 'complete') {
        onChangeMarkDown(htmlValue + ' ' + text)
        onBlur(htmlValue + ' ' + text)
        editor.setContents(htmlValue + ' ' + text)
      }
    }

    dispatch(componentBoxHideAction())
  }
  return (
    <>
      <Grid container className={classes.headerGrid}>
        <Card className={classes.headerCard}>
          <Box m={0.5}></Box>
          <img style={{ width: '30px' }} src={`/assets/ai-${item.image}.png`} />
          <Box m={0.5}></Box>
          <Typography variant="h5" color="#015FFB">
            {item.title}
          </Typography>
        </Card>
        {limitLoading && (
          <Box className={classes.circularBox} mt={5}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
      {!limitLoading && (
        <Grid container className={classes.distantGrid}>
          <Grid item xs={5} p={2} className={classes.grid}>
            <>
              <Typography
                variant="h6"
                marginBottom={1}
                className={classes.inputText}
              >
                Input
              </Typography>
              {/*<Typography className={classes.cardText}>
                {starterText.replaceAll('&nbsp;', '')}
              </Typography>*/}
              <Typography
                className={classes.cardText}
                dangerouslySetInnerHTML={{
                  __html: `${entity.text.replace(
                    '<ul>',
                    "<ul style='margin-left: 20px; margin-top: 8px;'>",
                  )}`,
                }}
              ></Typography>
            </>
            <div style={{ flexGrow: 1 }}></div>
            <>
              <Divider className={classes.divider} />

              {refreshDateAvaliable == true ? (
                limit > count || limit != count ? (
                  <>
                    <p className={classes.limitText}>
                      {limit - count} credits left. Renewal date:
                      {moment(refreshesAt).add('days', 30).format('L')}.
                    </p>
                  </>
                ) : (
                  <p className={classes.limitText}>You have no credits left!</p>
                )
              ) : (
                <>
                  <p className={classes.limitText}>
                    Your AI credits will be renewed every 30 days after your
                    first usage.
                  </p>
                </>
              )}
            </>
          </Grid>

          <Grid item xs={7} p={2} className={classes.secondaryGrid} ref={myRef}>
            <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
              <Typography variant="h6" className={classes.outputText}>
                Output
              </Typography>
            </div>

            {!emptyChoices && options && (
              <OptionsList
                item={item}
                addTextToTextArea={addTextToTextArea}
                options={options}
              />
            )}
            {emptyChoices && options && (
              <Alert severity="warning" style={{ alignItems: 'center' }}>
                <p style={{ textAlign: 'center' }}>
                  Sorry, there is no result for your input. Your credits remain
                  the same, <br />
                  so give it another try with a different text.
                </p>
              </Alert>
            )}
            {(limit == count || limit <= count) && (
              <AICreationLimitModal
                refreshesAt={refreshesAt}
                handleClickRedirect={handleClickRedirect}
                type={'Card_Complete'}
                roleType={roleType}
              />
            )}

            {loading && !options && (
              <>
                <Box width="200px">
                  <img src="/assets/ai_mascot.gif"></img>
                </Box>
                <Typography variant="h4" color="primary">
                  AI Assistant is in action!
                </Typography>
                <Typography variant="subtitle">
                  Please wait, AI can take up to 30 seconds.
                </Typography>
              </>
            )}
            {item.image != 'correct' &&
              count < limit &&
              !emptyChoices &&
              options &&
              (!loading ? (
                <Button
                  variant="outlined"
                  fullWidth={false}
                  style={{ maxWidth: '200px', backgroundColor: '#ffff' }}
                  startIcon={<img src="/assets/AI_icon.png" height="14" />}
                  onClick={() => sendCardRequest('generate and add more')}
                >
                  Generate more
                </Button>
              ) : (
                <CircularProgress />
              ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}

OpenAiPopupWithCardText.propTypes = propTypes

export default withStyles(styles)(OpenAiPopupWithCardText)
