/*
    Theme model
        Font
        Font Color
        Font Size
        Body Background (image or color)
        Card Background (color)
        Button Color

        Card Alignment
            Top
            Center
        
        Layout

        Card Stretch
            Full
            Wide
*/

const initialThemeSettings = {
  font: 'Open Sans',
  fontColor: { r: 0, g: 0, b: 0, a: 1 },
  fontSize: 'M',
  lineHeight: 'M',
  backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
  bodyBackgroundImage: '',
  cardBackgroundColor: { r: 255, g: 255, b: 255, a: 0 },
  buttonColor: { r: 1, g: 95, b: 251, a: 1 },
  cardAlignment: 'top',
  stretch: 2,
  layout: 0,
  arrow: 1,
}

export { initialThemeSettings }
