import { Icon } from '@iconify/react'
import { Typography, withStyles, Button } from '@material-ui/core'
import React from 'react'
import styles from '../styles'
import clsx from 'clsx'

const MagicLink = props => {
  const { handleMagicLink, selectedTeamList, classes } = props
  return (
    <div className={classes.magic_link_root}>
      <div
        className={clsx(classes.magic_link_root__header, {
          [classes.magic_link_root_header_border]:
            selectedTeamList && selectedTeamList.length == 0 ? false : true,
        })}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <div className={classes.displayF}>
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              style={{ color: '#002E47' }}
            >
              Invite learners by magic link
            </Typography>
          </div>
          <Typography color="#637381" fontWeight={400} fontSize={'13px'}>
            Customize and send the invitation email
          </Typography>
        </div>
        <div className={classes.magic_link_icon_button_div}>
          <Button
            className={clsx({
              [classes.mailPreparationBottomButton]:
                selectedTeamList && selectedTeamList.length == 0 ? false : true,
              [classes.mailPreparationBottomDimButton]:
                selectedTeamList && selectedTeamList.length == 0 ? true : false,
            })}
            onClick={
              selectedTeamList && selectedTeamList.length == 0
                ? () => {}
                : handleMagicLink
            }
            color="secondary"
          >
            Send mail
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(MagicLink)
