const styles = theme => ({
  heading: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#002E47',
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#637381',
  },
  textField: {
    width: '66px',
    height: '38px',
    backgroundColor: '#fff',
  },
})

export default styles
