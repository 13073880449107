import URL from '../url'
import Request from '../request'

/**
 *
 * @param {*} user
 */
export const loginUserService = user =>
  new Request(URL.API, URL.LOGIN).post(user)

/**
 *
 */
export const validateSuperLoginService = id =>
  new Request(URL.API, URL.VALIDATE_SUPER_LOGIN).get({ id })

/**
 *
 */
export const meService = () => new Request(URL.API, URL.ME).post()

/**
 *
 *
 */
export const logoutService = () => new Request(URL.API, URL.LOGOUT).post()
/**
 *
 *
 */
export const deleteService = () => new Request(URL.API, URL.DELETE).post()

/**
 *
 * @param {*} email
 */
export const sendForgotEmailService = email =>
  new Request(URL.API, URL.FORGOT_PASSWORD_EMAIL).post({ email })
/**
 *
 * @param {*} query
 */
export const userChangePasswordService = query =>
  new Request(URL.API, URL.AUTH_CHANGE_PASSWORD_USER).post(query)

/**
 *
 * @param {*} linkCode
 */
export const userVerifyChangePasswordCodeService = linkCode =>
  new Request(URL.API, URL.VERIFY_CHANGE_PASSWORD_CODE).post({ linkCode })

/**
 *
 */
export const getUserReportByIdService = userId =>
  new Request(URL.API, URL.GET_USER_REPORT_BY_FILTER).post({ userId })


/**
 *
 * @param {*} user
 */
export const refreshTokenService = refreshToken =>
  new Request(URL.API, URL.REFRESH_TOKEN).post(refreshToken)