import React from 'react'
import { Typography, withStyles, Divider } from '@material-ui/core'
import styles from './styles'
import { Icon } from '@iconify/react'

const EmbedViewHeader = props => {
  const { classes } = props
  return (
    <div className={classes.customDomain__root}>
      <div className={classes.customDomain__headerWithIcon}>
        <Icon
          icon={'fluent-mdl2:embed'}
          className={classes.customDomain__icon}
        />
        <Typography fontSize={'15px'} fontWeight={500}>
          Embed on a website
        </Typography>
      </div>
      <Typography color={'#637381'} fontSize={'14px'} fontWeight={400}>
        You can embed your site with JavaScript code.
      </Typography>
      <div className={classes.header__content}>
        <div className={classes.header__content__numberView}>
          <div style={{ marginBottom: 3 }}>1</div>
        </div>
        <Typography color={'#002E47'} fontSize={'14px'} fontWeight={400}>
          Paste code number 1 into the {`<head>`} section of your site.
        </Typography>
      </div>
      <div className={classes.header__content}>
        <div className={classes.header__content__numberView}>
          <div style={{ marginBottom: 3 }}>2</div>
        </div>
        <Typography color={'#002E47'} fontSize={'14px'} fontWeight={400}>
          Paste code number 2 into the {`<body>`} section of your site.
        </Typography>
      </div>
      <Divider style={{ color: '#002E47' }}></Divider>
    </div>
  )
}

export default withStyles(styles)(EmbedViewHeader)
