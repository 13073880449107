import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { cloneTemplateCourseAction } from 'src/reducers/assignment/action'

const UseTemplate = () => {
  const dispatch = useDispatch()
  const urlObj = new URL(window.location.href)
  const params = new URLSearchParams(urlObj.search) // This extracts the query string and parses it
  const courseCode = params.get('courseCode') // This gets the value of "url" parameter
  const courseName = params.get('courseName') // This gets the value of "url" parameter

  React.useEffect(() => {
    dispatch(
      cloneTemplateCourseAction(courseCode, courseName, null, response => {
        window.location.href = '/'
      }),
    )
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {courseName} is being cloned...
    </div>
  )
}

export default UseTemplate
