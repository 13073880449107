import React from 'react'
import Html from 'react-pdf-html'
import { QuestionFieldNames } from 'src/model/question'
import URL from 'src/api/url'

import ReactPDF, {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Svg,
  Path,
  G,
  Rect,
} from '@react-pdf/renderer'
import { Card } from '@material-ui/core'
/**
 *
 * @param {*} props
 */
const Inform = props => {
  const { stylesheet, style, page, editor, card, buttonColorRgb } = props
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'T',
    'U',
    'V',
    'Y',
    'Z',
  ]
  return (
    <View style={stylesheet.inputView}>
      {page !== 0 &&
        card.mediaDtos &&
        card.mediaDtos.length > 0 &&
        card.mediaDtos[0].mediaType == 1 &&
        !card.mediaDtos[0].mediaUrl.includes('gif') && (
          <Image
            style={stylesheet.inputContentImage}
            src={
              card.mediaDtos[0].mediaUrl.includes('https')
                ? card.mediaDtos[0].mediaUrl
                : URL.CDN + card.mediaDtos[0].mediaUrl +  '?not-from-cache-please2'
            }
          />
        )}
      <View style={stylesheet.inputBg}>
        <Svg viewBox="30 60 240 400">
          <G>
            <Path
              fill={buttonColorRgb}
              d="M267.8,95.1c0-0.5-0.1-0.9-0.1-1.4c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.5-0.1-0.9-0.2-1.4c-0.1-0.5-0.2-0.9-0.3-1.4
	c-0.1-0.4-0.2-0.9-0.4-1.3c-0.1-0.4-0.3-0.9-0.5-1.3c-0.2-0.4-0.3-0.9-0.5-1.3c-0.2-0.4-0.4-0.8-0.6-1.3c-0.2-0.4-0.4-0.8-0.7-1.2
	c-0.2-0.4-0.5-0.8-0.7-1.2c-0.3-0.4-0.5-0.8-0.8-1.1c-0.3-0.4-0.5-0.7-0.8-1.1c-0.3-0.4-0.6-0.7-0.9-1.1c-0.3-0.3-0.6-0.7-0.9-1
	c-0.3-0.3-0.7-0.6-1-1c-0.3-0.3-0.7-0.6-1.1-0.9c-0.4-0.3-0.7-0.6-1.1-0.8c-0.4-0.3-0.8-0.5-1.1-0.8c-0.4-0.3-0.8-0.5-1.2-0.7
	c-0.4-0.2-0.8-0.5-1.2-0.7c-0.4-0.2-0.8-0.4-1.3-0.6c-0.4-0.2-0.9-0.4-1.3-0.5c-0.4-0.2-0.9-0.3-1.3-0.5c-0.4-0.1-0.9-0.3-1.3-0.4
	c-0.4-0.1-0.9-0.2-1.3-0.3c-0.5-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-0.9-0.1-1.4-0.2c-0.5,0-0.9-0.1-1.4-0.1c-0.5,0-0.9,0-1.4,0
	c-0.5,0-0.9,0-1.4,0L57,80.1c-0.8,0-1.7,0.1-2.5,0.3c-0.8,0.1-1.6,0.3-2.5,0.5c-0.8,0.2-1.6,0.4-2.4,0.7c-0.8,0.3-1.6,0.6-2.3,1
	c-0.8,0.4-1.5,0.7-2.2,1.2c-0.7,0.4-1.4,0.9-2.1,1.4c-0.7,0.5-1.3,1-2,1.6c-0.6,0.6-1.2,1.1-1.8,1.7c-0.6,0.6-1.1,1.3-1.6,1.9
	c-0.5,0.7-1,1.4-1.4,2.1c-0.4,0.7-0.9,1.4-1.2,2.2s-0.7,1.5-1,2.3c-0.3,0.8-0.6,1.6-0.8,2.4c-0.2,0.8-0.4,1.6-0.6,2.4
	s-0.3,1.7-0.3,2.5c-0.1,0.8-0.1,1.7-0.1,2.5l2.5,188.6c0,0.8,0.1,1.6,0.1,2.5c0.1,0.8,0.2,1.6,0.4,2.4c0.2,0.8,0.4,1.6,0.6,2.4
	c0.2,0.8,0.5,1.6,0.8,2.3c0.3,0.8,0.7,1.5,1,2.2c0.4,0.7,0.8,1.4,1.2,2.1c0.5,0.7,0.9,1.4,1.4,2c0.5,0.6,1.1,1.3,1.6,1.9
	c0.6,0.6,1.2,1.2,1.8,1.7c0.6,0.5,1.3,1,1.9,1.5s1.4,0.9,2.1,1.3c0.7,0.4,1.4,0.8,2.2,1.1c0.8,0.3,1.5,0.6,2.3,0.9
	c0.8,0.3,1.6,0.5,2.4,0.7c0.8,0.2,1.6,0.4,2.4,0.5c0.8,0.1,1.6,0.2,2.5,0.2L227,330c0.9,0,1.7,0,2.6,0c0.9,0,1.7-0.1,2.5-0.2
	c0.8-0.1,1.7-0.3,2.5-0.5c0.8-0.2,1.6-0.4,2.5-0.7c0.8-0.3,1.6-0.6,2.4-1c0.8-0.4,1.5-0.8,2.3-1.2c0.7-0.4,1.5-0.9,2.1-1.4
	c0.7-0.5,1.4-1,2-1.6c0.6-0.6,1.2-1.2,1.8-1.8c0.6-0.6,1.1-1.3,1.6-2c0.5-0.7,1-1.4,1.4-2.1c0.4-0.7,0.9-1.5,1.2-2.2
	c0.4-0.8,0.7-1.6,1-2.4c0.3-0.8,0.6-1.6,0.8-2.4c0.2-0.8,0.4-1.7,0.5-2.5c0.1-0.8,0.2-1.7,0.3-2.5l13.2-207.5c0-0.5,0-0.9,0.1-1.4
	C267.8,96.1,267.8,95.6,267.8,95.1z M59.7,96.3c-1.5,0.5-2.9,1.1-4,1.8c-1.1,0.8-2,1.6-2.6,2.5c-0.6,0.9-0.9,1.8-0.9,2.6
	c0,0.6,0.2,1.1,0.7,1.5c0.5,0.4,1.2,0.9,2.1,1.4c1.9,0.9,2.8,2.4,2.8,4.5c0,1.5-0.5,2.7-1.6,3.6c-1.1,0.9-2.5,1.3-4.3,1.3
	c-2.1,0-3.8-0.7-5.1-2c-1.4-1.3-2.1-3-2.1-5.2c0-3.8,1.3-7.1,3.8-9.7c2.5-2.6,6.3-4.6,11.1-5.9V96.3z M79.8,96.9
	c-1.5,0.5-2.9,1.1-4,1.8c-1.1,0.8-2,1.6-2.6,2.5c-0.6,0.9-0.9,1.8-0.9,2.6c0,0.6,0.2,1.1,0.7,1.5c0.5,0.4,1.2,0.9,2.1,1.4
	c1.9,0.9,2.8,2.4,2.8,4.5c0,1.5-0.5,2.7-1.6,3.6c-1.1,0.9-2.5,1.3-4.3,1.3c-2.1,0-3.8-0.7-5.1-2c-1.4-1.3-2.1-3-2.1-5.2
	c0-3.8,1.3-7.1,3.8-9.7c2.5-2.6,6.3-4.6,11.1-5.9V96.9z M218.7,304.1c-2.6,2.7-6.5,4.7-11.6,6.1v-3.5c1.6-0.5,3-1.1,4.1-1.9
	c1.2-0.8,2-1.7,2.7-2.6c0.6-1,0.9-1.9,0.9-2.7c0-0.6-0.2-1.1-0.7-1.6c-0.5-0.5-1.2-0.9-2.2-1.4c-1.9-1-2.9-2.5-2.9-4.7
	c0-1.6,0.6-2.8,1.7-3.7c1.1-0.9,2.6-1.4,4.4-1.4c2.1,0,3.9,0.7,5.3,2.1c1.4,1.4,2.2,3.2,2.2,5.4C222.7,298,221.3,301.4,218.7,304.1z
	 M239.5,304.7c-2.6,2.7-6.5,4.7-11.6,6.1v-3.5c1.6-0.5,3-1.1,4.1-1.9c1.2-0.8,2-1.7,2.7-2.6c0.6-1,0.9-1.9,0.9-2.7
	c0-0.6-0.2-1.1-0.7-1.6c-0.5-0.5-1.2-0.9-2.2-1.4c-1.9-1-2.9-2.5-2.9-4.7c0-1.6,0.6-2.8,1.7-3.7c1.1-0.9,2.6-1.4,4.4-1.4
	c2.1,0,3.9,0.7,5.3,2.1c1.4,1.4,2.2,3.2,2.2,5.4C243.5,298.7,242.2,302,239.5,304.7z"
            />
          </G>
        </Svg>
      </View>
      <View style={stylesheet.inputText}>
        <Html stylesheet={stylesheet}>
          {editor && editor.getContents && page !== 0
            ? editor.getContents()
            : card.text}
        </Html>
      </View>

      <View style={stylesheet.inputSocialIcons}>
        <Svg width="101" height="22" viewBox="0 0 101.2 22" fill={buttonColorRgb}>
        <G>
            <Path
              fill={buttonColorRgb}
              d="M67.9,7L59,10.6c-0.2,0.1-0.2,0.3,0,0.4l3.3,1.4c0.2,0.1,0.3,0.2,0.4,0.4l1.4,3.2c0.1,0.2,0.3,0.2,0.4,0l3.7-8.6
		C68.2,7.1,68.1,7,67.9,7z"
            />
            <Path
              fill={buttonColorRgb}
              d="M45.1,7.3c-0.3,0-0.8,0-1.4,0h-4.8c-0.6,0-1.1,0-1.4,0s-1.4,0.3-1.4,1.4c0,0.3,0,0.8,0,1.4v1.8c0,0.6,0,1.1,0,1.4
		c0,1,0.6,1.4,1.4,1.4c0.3,0,0.8,0,1.4,0H44c0.4,0,0.8,0.2,1.1,0.4l1.5,1.5v-1.2v-3.7v-1.6c0-0.6,0-1.1,0-1.4
		C46.6,7.8,45.4,7.3,45.1,7.3z M38.8,10.3c-0.4,0-0.6-0.3-0.6-0.6S38.4,9,38.7,9h5.1c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.2,0.7-0.5,0.7
		H38.8z M43.9,12.9h-3.2c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h3.2c0.4,0,0.6,0.3,0.6,0.6S44.2,12.9,43.9,12.9z"
            />
            <Path
              fill={buttonColorRgb}
              d="M87.7,5.7h-7.6c-0.4,0-0.6,0.3-0.6,0.6v10.3l4.1-2.6c0.2-0.1,0.5-0.1,0.7,0l4.1,2.6V6.3C88.4,5.9,88.1,5.7,87.7,5.7z"
            />
            <Path
              fill={buttonColorRgb}
              d="M25.1,9.5h-1.3c-0.8,0-1.6-0.5-2-1.2l-1.7-3.5c-0.1-0.2-0.3-0.3-0.5-0.3c-0.4,0-0.9,0.4-0.7,0.9l0.9,3.1
		c0.1,0.2-0.1,0.4-0.2,0.4h-0.1h-5.2c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s1.2,0,1.5,0c0.2,0,0.3,0.2,0.3,0.3
		c0,0.2-0.2,0.3-0.3,0.3l0,0H15c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.8c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-1.1
		c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.9c0,0,0,0,0.1,0c0.2,0,0.3,0.1,0.3,0.3S17.9,15,17.7,15c0,0,0,0-0.1,0h-1.3
		c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h3.1l0,0l0,0l0,0l0,0h2.4c0.4,0,0.8-0.2,1.1-0.5c0.5-0.6,1.3-0.9,2-0.9
		c0.2,0,0.3-0.1,0.3-0.3C25.2,14.7,25.2,9.5,25.1,9.5z"
            />
            <Path
              fill={buttonColorRgb}
              d="M90.2,0H11C4.9,0,0,4.9,0,11s4.9,11,11,11h79.2c6.1,0,11-4.9,11-11S96.3,0,90.2,0z M25,16c-0.4,0-0.9,0.2-1.2,0.5
		c-0.5,0.5-1.2,0.8-1.9,0.8h-2.4h-0.1h-0.1l0,0h-3.1c-1,0-1.9-0.8-1.9-1.9c0-0.2,0-0.3,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5
		c0-0.2,0-0.3,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5c0-0.2,0-0.4,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5c0-1,0.8-1.9,1.9-1.9h4l-0.6-1.9
		c-0.2-0.6-0.1-1.2,0.3-1.7s1-0.8,1.6-0.8l0,0c0.7,0,1.3,0.4,1.6,1l0,0l0,0L23,7.6c0.2,0.3,0.5,0.6,0.9,0.6h1.3
		c0.8,0,1.5,0.7,1.5,1.5v4.8C26.7,14.5,26.7,16,25,16z M48,10.1v1.6v3.7v2.2c0,1-1.2,1.4-1.8,0.8L44,16.2h-5.1c-0.6,0-1.1,0-1.5,0
		s-0.8-0.1-1.2-0.3c-0.6-0.3-1-0.7-1.3-1.3c-0.2-0.4-0.3-0.7-0.3-1.2c0-0.4,0-0.9,0-1.5v-1.8c0-0.6,0-1.1,0-1.5s0.1-0.8,0.3-1.2
		c0.3-0.6,0.7-1,1.3-1.3c0.4-0.2,0.7-0.3,1.2-0.3c0.4,0,0.9,0,1.5,0h4.8c0.6,0,1.1,0,1.5,0s0.8,0.1,1.2,0.3c0.6,0.3,1,0.7,1.3,1.3
		C47.9,7.8,48,8.1,48,8.6C48,9,48,9.5,48,10.1z M70.2,5.5l-5.3,12.9c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0-0.4-0.1-0.5-0.3l-2.1-5
		c0-0.1-0.1-0.1-0.1-0.1l-5-2.1c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4,0.4-0.5L69.7,5c0.2-0.1,0.5,0,0.6,0.1C70.2,5,70.3,5.2,70.2,5.5z
		 M89.5,18c-0.2,0.3-0.6,0.4-0.9,0.2l-4.8-3l-4.8,3c-0.2,0.1-0.4,0.1-0.6,0s-0.3-0.3-0.3-0.6V6.3c0-1.1,0.9-1.9,1.9-1.9h7.6
		c1.1,0,1.9,0.9,1.9,1.9l0.1,11.4C89.6,17.8,89.6,17.9,89.5,18z"
            />
          </G>
        </Svg>
      </View>
    </View>
  )
}

export default Inform
