import translator from 'src/translate'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styles from '../styles'
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useCourseSettings } from 'src/hooks/useCourseSettings'
import { Icon } from '@iconify/react'
import { showModal } from 'src/components/upgrade-popup'
import CustomizedSwitch from 'src/components/customized-switch'
import { Badge } from 'src/components/user-access/components'
import UpgradePopover from 'src/components/upgrade-popover/upgrade-popover'
import { getCollectionSettingsAction } from 'src/reducers/course-collection/action'
import { settingsDrawerOpenSuccess } from 'src/reducers/assignment/action'
const SETTING_KEY = 'general'

const AdvancedSettings = props => {
  const { classes, entity } = props
  const [collectionSettings, setCollectionSettings] = useState()
  const dispatch = useDispatch()
  // select collection
  const { execute, update, exists, value } = useCourseSettings(SETTING_KEY)

  useEffect(() => {
    dispatch(
      getCollectionSettingsAction(entity.courseCollectionId, response => {
        setCollectionSettings(response)
        setLoading(false)
      }),
    )
  }, [])
  const [loading, setLoading] = useState(true)

  const isUserPremium = Number(window.CONSTANTS.USER_ROLE_ID) !== 1

  const [thumbnailUrl, setThumbnailUrl] = useState(exists ? value.logo : '')

  const [headerScript, setHeaderScript] = useState(
    exists ? value.headerScript : '',
  )

  const [bodyScript, setBodyScript] = useState(exists ? value.bodyScript : '')
  const [sendEmailOnFinish, setSendEmailOnFinish] = useState(
    exists && value.sendEmailOnFinish != undefined
      ? value.sendEmailOnFinish
      : false,
  )
  const [isSequential, setIsSequential] = useState(
    exists && (value.isSequential != undefined ? value.isSequential : false),
  )

  const [headerScriptIsExist, setHeaderScriptIsExist] = useState(
    (headerScript != '' && headerScript != null && headerScript != undefined) ||
      (bodyScript != '' && bodyScript != null && bodyScript != undefined),
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const popupHeader = 'Upgrade to Premium'
  const popupContent =
    'Upgrade to Premium account to access privileged features.'
  const saveSettings = () => {
    const variables = {
      ...value,
      isSequential: isSequential,
      logo: thumbnailUrl,
      headerScript: headerScript,
      bodyScript: bodyScript,
      sendEmailOnFinish: sendEmailOnFinish,
    }
    if (!exists) {
      // create a setting entity with execute function
      execute(variables, () => {
        setLoading(false)
        handleDrawerClose()
      })
    } else {
      update(variables, () => {
        setLoading(false)
        handleDrawerClose()
      })
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleIsSequential = () => {
    if (!isUserPremium) {
      setAnchorEl(document.getElementById('isSequential'))
    } else {
      if ((collectionSettings && collectionSettings.accessRule != 0) == true) {
        setIsSequential(!isSequential)
      }
    }
  }

  const handleSetSendEmailOnFinish = () => {
    if (!isUserPremium) {
      setAnchorEl(document.getElementById('sendEmailOnFinish'))
    } else {
      setSendEmailOnFinish(!sendEmailOnFinish)
    }
  }
  const handleHeaderScriptIsExist = () => {
    if (!isUserPremium) {
      setAnchorEl(document.getElementById('headerScriptIsExist'))
    } else {
      setHeaderScriptIsExist(!headerScriptIsExist)
    }
  }

  const id = open ? 'simple-popover' : undefined

  const handleDrawerClose = () => {
    dispatch(settingsDrawerOpenSuccess())
  }
  return (
    <>
      <div className={classes.content}>
        <Box m={1}></Box>

        <div
          style={{
            opacity:
              collectionSettings && collectionSettings.accessRule == 0
                ? 0.5
                : 1,
          }}
          className={classes.advancedSettings}
          onClick={() => handleIsSequential()}
        >
          <div className={classes.advancedSettings_content}>
            <Typography>Learners can proceed only sequentially</Typography>
            <Badge type={4} />
          </div>
          <Tooltip
            title={
              collectionSettings && collectionSettings.accessRule == 0
                ? `This feature is unavailable under the “everyone can access” setting. To activate it, please modify the access rules for learners.`
                : ''
            }
          >
            <CustomizedSwitch id="isSequential" checked={isSequential} />
          </Tooltip>
        </div>

        <Box m={0.5}></Box>
        <div
          style={{
            width: '100%',
            height: isSequential ? '100%' : '0px',
            opacity: isSequential ? 1 : 0,
            transition: 'all 0.5s',
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              color: '#637381',
            }}
            fontSize={'14px'}
            fontWeight={400}
          >
            Learners can't proceed unless previous cards were viewed.
          </Typography>
        </div>
        <Box m={1}></Box>
        <div
          className={classes.advancedSettings}
          onClick={() => handleSetSendEmailOnFinish()}
        >
          <div className={classes.advancedSettings_content}>
            <Typography>Notify by email when someone completes</Typography>
            <Badge type={4} />
          </div>

          <CustomizedSwitch
            id="sendEmailOnFinish"
            checked={sendEmailOnFinish}
          />
        </div>
        <Box m={0.5}></Box>
        <div
          style={{
            width: '100%',
            height: sendEmailOnFinish ? '100%' : '0px',
            opacity: sendEmailOnFinish ? 1 : 0,
            transition: 'all 0.5s',
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              color: '#637381',
            }}
            fontSize={'14px'}
            fontWeight={400}
          >
            Recommended if you need to contact the lead promptly after
            mini-course is completed.
          </Typography>
        </div>
        <Box m={1}></Box>
        <div
          className={classes.advancedSettings}
          onClick={() => handleHeaderScriptIsExist()}
        >
          <div className={classes.advancedSettings_content}>
            <Typography>Add header and body scripts</Typography>
            <Badge type={4} />
          </div>

          <CustomizedSwitch
            id="headerScriptIsExist"
            checked={headerScriptIsExist}
          />
        </div>

        <Box m={1}></Box>
        <div
          style={{
            width: '100%',
            height: headerScriptIsExist ? '100%' : '0px',
            opacity: headerScriptIsExist ? 1 : 0,
            transition: 'all 0.5s',
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              color: '#637381',
            }}
            fontSize={'14px'}
            fontWeight={400}
          >
            Add custom CSS & JS codes, like Google Analytics tracking code,
            Facebook Pixel, etc.
          </Typography>
          <Box m={1}></Box>
          <Typography
            style={{
              color: '#00B8D9',
            }}
            fontSize={'14px'}
            fontWeight={400}
          >
            Header Script
          </Typography>
          <Box m={1}></Box>
          <TextField
            multiline
            rows="5"
            fullWidth
            placeholder={translator._('assignment.courseSettings.headerText')}
            id="headerScript"
            label={translator._('assignment.courseSettings.headerScript')}
            variant="outlined"
            defaultValue={headerScript}
            onBlur={event => setHeaderScript(event.target.value)}
            disabled={isUserPremium ? false : true}
            style={{
              marginTop: 4,
            }}
          />
          <Box m={1}></Box>
          <Typography
            style={{
              color: '#FFAB00',
            }}
            fontSize={'14px'}
            fontWeight={400}
          >
            Body Script
          </Typography>
          <Box m={1}></Box>
          <TextField
            multiline
            rows="5"
            fullWidth
            placeholder={translator._('assignment.courseSettings.bodyText')}
            id="headerScript"
            label={translator._('assignment.courseSettings.bodyScript')}
            variant="outlined"
            defaultValue={bodyScript}
            onBlur={event => setBodyScript(event.target.value)}
            disabled={isUserPremium ? false : true}
            style={{
              marginTop: 4,
            }}
          />
        </div>

        <Box m={1}></Box>
        {loading ? (
          <div className={classes.bottomButton}>
            <CircularProgress></CircularProgress>
          </div>
        ) : isUserPremium ? (
          <div className={classes.bottomButton}>
            <Button
              fullWidth={true}
              color="secondary"
              size="small"
              variant="contained"
              onClick={saveSettings}
              className={classes.previewBtn}
            >
              {translator._('assignment.courseSettings.save')}
            </Button>
          </div>
        ) : (
          <div className={classes.bottomButton}>
            <Button
              fullWidth={true}
              to="/pricing"
              color="primary"
              variant="contained"
              startIcon={
                <Icon
                  size="small"
                  icon="fa6-solid:crown"
                  color="#f7b155"
                ></Icon>
              }
              component={RouterLink}
            >
              Upgrade to Edit
            </Button>
          </div>
        )}
      </div>

      <UpgradePopover
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        header={popupHeader}
        content={popupContent}
      />
      {/*{!isUserPremium && (
        <>
          <div className={classes.premiumContainer}>
            <img src="/assets/premium.gif" alt="premium" width="128" />
            <Alert severity="warning">
              You must be a premium to use this feature.
            </Alert>
            <Button
              variant="contained"
              style={{ marginTop: '1rem' }}
              onClick={handleOpenPremiumDetails}
            >
              Upgrade your Plan
            </Button>
          </div>
        </>
      )}
      */}
    </>
  )
}

export default withStyles(styles)(AdvancedSettings)
