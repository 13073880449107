import React, { useState } from 'react'
import styles from './styles'
import { useDispatch } from 'react-redux'
import { authenticationForgotPasswordEmailAction } from 'src/reducers/authentication/action'
// material
import {
  Grid,
  withStyles,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from '@material-ui/core'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function ForgotPassword(props) {
  const dispatch = useDispatch()
  const { classes } = props
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)
  const [userExists, setUserExists] = useState(false)

  const sendForgotPasswordEmail = () => {
    setLoading(true)
    dispatch(
      authenticationForgotPasswordEmailAction(email, response => {
        response != '' && setUserExists(true)
        setLoading(false)
        setSentEmail(true)
      }),
    )
  }
  return (
    <Grid container className={classes.mainDiv}>
      <Grid Item>
        <Typography variant="h2" style={{ fontWeight: 700, lineHeight: 1.5 }}>
          Forgot your password?
        </Typography>
        <Box m={2}></Box>
        {sentEmail == false && userExists == false && (
          <div>
            <Typography variant="body1" style={{ color: '#637381' }}>
              Please enter the e-mail address associated with your MCG account.
              <br />
              We will send you a link to reset your password.
            </Typography>
            <Box m={4}></Box>
            <TextField
              label="Email address"
              onBlur={e => setEmail(e.target.value)}
              fullWidth
            />
            <Box m={4}></Box>
            {loading == false ? (
              <div>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={sendForgotPasswordEmail}
                  sx={{
                    backgroundColor: '#015ffb',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#015ffb',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Send Request
                </Button>
                <Box m={2}></Box>
                <Button
                  fullWidth
                  sx={{
                    color: '#015ffb',
                    boxShadow: 'none',
                    '&:hover': {
                      color: '#015ffb',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => {
                    window.location.href = '/'
                  }}
                >
                  Back
                </Button>
              </div>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
        {sentEmail == true && userExists == false && (
          <div>
            <Alert>
              We have sent you a link to reset your password. Please check your
              inbox!
              <br /> It may take a few minutes for the e-mail to arrive.
            </Alert>
          </div>
        )}
        {userExists == true && (
          <div>
            <Alert severity="error">
              User does not exist! Please enter the e-mail address associated
              with your MCG account!
            </Alert>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ForgotPassword)
