import React, { useEffect, useState } from 'react'
import styles from './style'
import propTypes from './prop'
import translator from '../../../translate'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import ContainerMediaGalery from '../../../container/media-galery'
import URL from 'src/api/url/api'
import ComponentBoxContent from '../../../model/component-box'
import { questionChangeImageAction } from '../../../reducers/assignment/action'
import { ButtonData, ButtonType, ButtonActionType } from '../../../model/button'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import {
  QuestionFieldNames,
  QuestionMediaFieldNames,
} from '../../../model/question'
import { componentBoxShowAction } from '../../../reducers/component-box/action'
import { questionMediaDeleteAction } from '../../../reducers/question/action'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import {
  Grid,
  withStyles,
  Button,
  Box,
  IconButton,
  Popover,
  Typography,
} from '@material-ui/core'
import SvgIconStyle from 'src/components/SvgIconStyle'
import { Icon } from '@iconify/react'
import { track } from '@amplitude/analytics-browser'

import { addMessageReceivedListener } from 'src/utils/signalR-connection/signalR-connection'

const QuestionIntro = props => {
  const {
    classes,
    entity,
    dispatch,
    filterType,
    imageURL,
    setImageURL,
    onOk,
    onRemove,
  } = props

  const [connectionEnabled, setConnectionEnabled] = useState(
    imageURL &&
      (imageURL.includes('/assets/gateway/ai-image-loading.gif') ||
        imageURL.includes(
          '/production/images/mini1631212909/5a14e263-32c7-4d3e-b0f6-258dba8bce73.png',
        ))
      ? true
      : false,
  )

  useEffect(() => {
    // Create a new SignalR connection

    if (!connectionEnabled) {
      return
    }

    addMessageReceivedListener(entity[QuestionFieldNames.ID], message => {
      if (entity[QuestionFieldNames.ID] == message[0]) {
        setImageURL(`${URL.STORAGE}${message[1]}`)

        dispatch(
          questionChangeImageAction(entity[QuestionFieldNames.ID], message[1]),
        )
      }
      // Handle the received message here
    })
  }, [])

  /**
   *
   */
  const imageButtonClickHandler = () => {
    handlePopoverClose()
    const { dispatch } = props
    track('add_image_video_clicked')

    dispatch(
      componentBoxShowAction(
        new ComponentBoxContent(
          (
            <ContainerMediaGalery
              setImageURL={setImageURL}
              onOk={onOk}
              onRemove={onRemove}
              filterType={filterType}
              cardEntity={entity}
            />
          ),
          {
            hideCloseButton: false,
          },
        ),
      ),
    )
  }

  /**
   *
   * @param {*} e
   */
  const deleteButtonClickHandler = e => {
    const id =
      entity[QuestionFieldNames.QUESTION_MEDIAS][0][QuestionMediaFieldNames.ID]

    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          translator._('message.delete'),
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              e => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.yes'),
              ButtonActionType.DELETE,
              e => {
                dispatch(messageBoxHideAction())

                dispatch(
                  questionMediaDeleteAction(id, entity[QuestionFieldNames.ID]),
                )
              },
            ),
          ],
        ),
      ),
    )
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  /*const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }*/

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  /**
   *
   */
  const addArea = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item className={classes.grid} onClick={imageButtonClickHandler}>
          <Box className={classes.box}>
            <Icon
              icon="ic:round-add-a-photo"
              color="#919eab"
              width="36"
              height="36"
            />

            <Typography color="#919EAB" fontSize={'10px'}>
              Add image or video
            </Typography>
            {/*<Button
              variant="outlined"
              className={classes.button}
              size="small"
              //onClick={handlePopoverOpen}
              color="primary"
              aria-describedby={id}
            >
              {translator._('taskItem.addQuestion.image.addMedia')}{' '}
            </Button> */}

            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorEl={anchorEl}
              id={id}
              open={open}
              onClose={handlePopoverClose}
            >
              <Button
                variant="outlined"
                className={classes.addImage}
                size="small"
                onClick={imageButtonClickHandler}
                color="primary"
                aria-describedby={id}
                startIcon={
                  <SvgIconStyle src="/static/icons/ic_add_photo_alternate.svg" />
                }
              >
                {translator._('taskItem.addQuestion.image.image')}{' '}
              </Button>
              <Button
                variant="outlined"
                className={classes.addImage}
                size="small"
                onClick={imageButtonClickHandler}
                color="primary"
                aria-describedby={id}
                startIcon={
                  <SvgIconStyle src="/static/icons/ic_add_video.svg" />
                }
              >
                {translator._('taskItem.addQuestion.image.video')}{' '}
              </Button>
            </Popover>
          </Box>
        </Grid>
      </Grid>
    )
  }

  /**
   *
   */
  const viewImageArea = () => {
    let srcURL = imageURL

    srcURL =
      srcURL && (srcURL.includes('https://') || srcURL.includes('http://'))
        ? srcURL
        : URL.STORAGE_QUESTION_IMAGE + srcURL

    return (
      <div className={classes.gridViewImage}>
        <div
          item
          style={{
            zIndex: 1,
            position: 'relative',
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: 16,
            width: 'fit-content',
          }}
        >
          <IconButton
            size=""
            className={classes.iconButton}
            onClick={deleteButtonClickHandler}
          >
            <DeleteIcon />
          </IconButton>
          <img src={srcURL} alt={'img'} className={classes.img}></img>
        </div>
      </div>
    )
  }

  const parseYouTubeURL = url => {
    const baseURL = 'https://youtube.com/embed/'
    /*eslint-disabled */
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    /*eslint-enabled */
    var match = url.match(regExp)
    if (match && match[2].length == 11) return baseURL + match[2]

    return url
  }

  const viewVideoArea = () => {
    let srcURL =
      entity[QuestionFieldNames.QUESTION_MEDIAS][0][
        QuestionMediaFieldNames.MEDIA_URL
      ]
    let nativeVideo = (
      <video width="100%" height="100%" controls>
        <source src={srcURL} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    )

    let youtubeVideo
    try {
      youtubeVideo = (
        <iframe
          width="100%"
          height="100%"
          src={parseYouTubeURL(srcURL)}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
          allowFullScreen
        ></iframe>
      )
    } catch (error) {}

    let videoView =
      srcURL && srcURL.includes('youtu') ? youtubeVideo : nativeVideo

    return (
      <Grid container justifyContent="center">
        <Grid item className={classes.grid}>
          <Grid
            className={classes.childGrid}
            style={{
              background: 'black',
              height: '100%',
              position: 'relative',
            }}
          >
            <IconButton
              size=""
              className={classes.iconButton}
              onClick={deleteButtonClickHandler}
            >
              <DeleteIcon />
            </IconButton>
            {videoView}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container justifyContent="center">
      {entity[QuestionFieldNames.QUESTION_MEDIAS].filter(
        item => item[QuestionMediaFieldNames.MEDIA_TYPE_ID] == 1,
      ).length > 0 && viewImageArea()}

      {entity[QuestionFieldNames.QUESTION_MEDIAS].filter(
        item => item[QuestionMediaFieldNames.MEDIA_TYPE_ID] == 2,
      ).length > 0 && viewVideoArea()}
      {entity[QuestionFieldNames.QUESTION_MEDIAS].length === 0 && addArea()}
    </Grid>
  )
}

QuestionIntro.propTypes = propTypes

export default withStyles(styles)(QuestionIntro)
