import { put, call, take, fork } from 'redux-saga/effects'
import {
  openaiSendRequestService,
  assignmentGetService,
} from '../../api/assignment'
import { assignmentAddSuccess } from '../../reducers/assignment/action'
import { OPENAI_SEND_REQUEST } from '../../reducers/assignment/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* openAiRequestFlow(payload) {
  try {
    const { topic, callback } = payload

    const response = yield call(openaiSendRequestService, topic)

    yield put(operationSuccess())

    callback(response.data.choices[0].text)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* openAiRequestWatcher() {
  while (true) {
    const action = yield take(OPENAI_SEND_REQUEST)
    const { payload } = action

    yield fork(generalSaga, openAiRequestFlow, payload)
  }
}
