const styles = theme => ({
  headerGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerCard: {
    display: 'flex',
    flexDirection: 'row',
    height: '70px',
    marginRight: '-24px',
    marginLeft: '-24px',
    borderRadius: '8px',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    height: 'inherit',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  secondaryGrid: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    alignItems: 'center',
    overflow: 'auto',
    borderRadius: '8px',
    backgroundColor: '#E5EAEF',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  childGrid: {
    margin: theme.spacing(1.5),
  },
  distantGrid: {
    height: '500px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',

    [theme.breakpoints.up('sm')]: {
      width: '980px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'block',
    },
  },

  textField: {
    padding: '10px',
  },
  cards: {
    margin: '10px',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    padding: 32,
    backgroundColor: '#ffffff',
    outline: '#ffffff',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E9F1FF',
      outline: '#E9F1FF',
    },
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem',
    marginTop: '0.5rem',
    backgroundColor: '#E9F1FF',
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#ffffff',
    },
  },
  button: {
    minWidth: '10px',
    marginRight: '5px',
    paddingLeft: '20px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  circularBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '500px',
    width: '980px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  summary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  inputText: {
    fontSize: '14px',
    color: '#212B36',
  },
  cardText: {
    fontSize: '12px',
    color: '#222222',
  },
  divider: {
    borderStyle: 'outset',
    width: '100%',
    color: '#919EAB',
  },
  limitText: {
    fontSize: '10px',
    marginTop: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: '#919EAB',
  },
  outputText: {
    color: '#212B36',
    fontSize: '14px',
    fontWeight: 600,
  },
  copyText: {
    border: 'none',
    color: '#015ffb',

    '&:hover': {
      outline: '1px solid #015ffb',
      border: 'none',
    },
  },
})

export default styles
