import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Tab,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import UploadText from './upload-text'
import UploadUrl from './upload-url'
import UploadPdf from './upload-pdf'
import { Icon } from '@iconify/react'

const SettingsTabs = [
  /* {
       label: translator._('assignment.courseSettings.theme'),
       component: entity => <ThemeSettings entity={entity} />,
       enabled: true,
     },*/
  {
    label: <Typography style={{ color: '#015FFB' }}>Text</Typography>,
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
      cardText,
    ) => (
      <UploadText
        documentText={documentText}
        setDocumentText={setDocumentText}
        roleType={roleType}
        handleAddSource={handleAddSource}
        cardText={cardText}
      />
    ),
    enabled: true,
  },
  {
    label: <Typography style={{ color: '#015FFB' }}>Url</Typography>,
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
    ) => (
      <UploadUrl
        documentUrl={documentUrl}
        setDocumentUrl={setDocumentUrl}
        handleAddSource={handleAddSource}
      />
    ),
    disabled: false,
  },

  {
    label: <Typography style={{ color: '#015FFB' }}>Upload</Typography>,
    component: (
      documentUrl,
      setDocumentUrl,
      file,
      setFile,
      documentPdf,
      setDocumentPdf,
      documentText,
      setDocumentText,
      roleType,
      handleAddSource,
    ) => (
      <UploadPdf
        file={file}
        setFile={setFile}
        documentPdf={documentPdf}
        setDocumentPdf={setDocumentPdf}
        handleAddSource={handleAddSource}
      />
    ),
    enabled: true,
  },
]

const DescriptionText = () => {
  return (
    <div>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 500,
          color: '#637381',
          marginBottom: 16,
        }}
      >
        💡 Add your resources to make AI Assistant create questions based on
        them.
      </Typography>
    </div>
  )
}

const AddSourceButton = props => {
  const { canAddSource, handleUploadDocuments } = props
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        style={{
          width: 'max-content',
          height: '40px',
          fontSize: '16px',
          backgroundColor: canAddSource ? '#015FFB' : '#342f2f1f',
        }}
        onClick={() => {
          handleUploadDocuments()
        }}
        disabled={!canAddSource}
      >
        Add Source
      </Button>
    </div>
  )
}
function AddDocument(props) {
  const {
    documentUrl,
    setDocumentUrl,
    file,
    setFile,
    documentPdf,
    setDocumentPdf,
    selectedMenu,
    setSelectedMenu,
    documentText,
    setDocumentText,
    roleType,
    handleUploadDocuments,
    cardText,
    classes,
  } = props
  const [currentTab, setCurrentTab] = useState('0')

  const handleTabChange = (event, newValue) => {
    if (roleType == 1 && newValue != 0) {
      window.open('https://admin.minicoursegenerator.com/pricing', '_blank')
    } else {
      setSelectedMenu(event.target.textContent)
      setCurrentTab(newValue)
    }
  }
  const [canAddSource, setCanAddSource] = useState(false)

  const handleAddSource = e => {
    setCanAddSource(e)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        height: '455px',
      }}
    >
      <DescriptionText />
      <TabContext value={currentTab}>
        <TabList
          centered
          className={classes.tabList}
          variant="scrollable"
          TabIndicatorProps={{
            style: { background: '#015FFB' },
          }}
          onChange={handleTabChange}
        >
          {SettingsTabs.map((value, index) => {
            return (
              <Tab
                key={index}
                label={value.label}
                value={index.toString()}
                className={classes.tab}
                disabled={value.disabled}
                icon={
                  roleType == 1 &&
                  index != 0 && (
                    <Icon
                      size="small"
                      icon="fa6-solid:crown"
                      color="#f7b155"
                      width={15}
                    ></Icon>
                  )
                }
              />
            )
          })}
        </TabList>

        {SettingsTabs.map((value, index) => {
          return (
            <TabPanel
              className={classes.tabContainer}
              key={index}
              value={index.toString()}
              hidden={Number(currentTab) !== Number(index)}
            >
              {value.component(
                documentUrl,
                setDocumentUrl,
                file,
                setFile,
                documentPdf,
                setDocumentPdf,
                documentText,
                setDocumentText,
                roleType,
                handleAddSource,
                cardText,
              )}
            </TabPanel>
          )
        })}
      </TabContext>
      <div style={{ flexGrow: 1 }}></div>
      <AddSourceButton
        canAddSource={canAddSource}
        handleUploadDocuments={handleUploadDocuments}
      />
    </div>
  )
}

export default withStyles(styles)(AddDocument)
