import React from 'react'
import translator from '../../../translate'
import MessageBoxContent, { MessageBoxType } from '../../../model/message-box'
import { ListColumnType } from '../../../model/list'
import { AssignmentFieldNames } from '../../../model/assignment'
import BarChartIcon from '@material-ui/icons/BarChart'
import RenameModal from 'src/components/rename-modal'
import { componentBoxShowAction } from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { TagColorType } from '../../../model/tag/index'
import robotLoveOutline from '@iconify/icons-simple-icons/probot'
import { Icon } from '@iconify/react'

import SettingsStep from '../../../pages/create-mini-course/embed'
import {
  messageBoxShowAction,
  messageBoxHideAction,
} from '../../../reducers/message-box/action'
import { ButtonType, ButtonData, ButtonActionType } from '../../../model/button'
import {
  assignmentGetAction,
  assignmentReportAction,
  //assignmentCloneAction,
} from '../../../reducers/assignment/action'
import { assignmentDeleteAction } from 'src/reducers/course-collection/action'

import URL from 'src/api/url'
/**
 *
 */
const columns = [
  {
    attribute: 'name',
    label: translator._('general.name'),
    type: ListColumnType.STRING,
    sortable: false,
    width: '200px',
    converter: null,
    suffix: null,
    renderer: null,
    actions: null,
  },

  {
    attribute: '',
    label: null,
    type: ListColumnType.ACTION,
    sortable: false,
    width: '50px',
    converter: null,
    suffix: null,
    renderer: null,
    actions: [
      {
        label: translator._('action.view'),
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh, history) => {
          window.open(
            URL.APP_URL + entity.code,
            entity.name,
            'height=800,width=600',
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('action.edit'),
        type: ButtonType.NORMAL,
        link: '/mini-course',
        exec: (entity, dispatch, refresh, history) => {
          dispatch(
            assignmentGetAction(entity.id, () => {
              history.push('/mini-course/' + entity.id)
            }),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('general.analytics'),
        icon: BarChartIcon,
        type: ButtonType.LINK,
        link: null,
        exec: async (entity, dispatch, refresh) => {
          dispatch(assignmentReportAction(entity.id))

          //const response = excelExportAutoSenderReportService(entity.id)

          //FileDownload(response, 'report.xlsx')
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('assignment.rename'),
        type: ButtonType.LINK,
        link: (entity, dispatch, refresh) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                (
                  <RenameModal
                    title={translator._('assignment.renameAsssignment')}
                    inputLabel={translator._('assignment.assignmentName')}
                    dispatch={dispatch}
                    entity={entity}
                    refresh={refresh}
                  />
                ),
                {
                  hideCloseButton: false,
                },
              ),
            ),
          )
        },
        exec: null,
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      /*{
        label: translator._('general.duplicate'),
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh, history) => {
          dispatch(assignmentCloneAction(entity.id))
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },*/
      {
        label: translator._('assignment.share'),
        type: ButtonType.LINK,
        link: (entity, dispatch) => {
          dispatch(
            componentBoxShowAction(
              new ComponentBoxContent(
                <SettingsStep dispatch={dispatch} entity={entity} />,
                {
                  hideCloseButton: false,
                  backgroundColor: 'transparent',
                },
              ),
            ),
          )
        },
        exec: null,
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
      {
        label: translator._('action.delete'),
        type: ButtonType.NORMAL,
        link: null,
        exec: (entity, dispatch, refresh) => {
          dispatch(
            messageBoxShowAction(
              new MessageBoxContent(
                MessageBoxType.WARNING,
                translator._('message.delete'),
                [
                  new ButtonData(
                    ButtonType.NORMAL,
                    translator._('action.no'),
                    ButtonActionType.NO,
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                  new ButtonData(
                    ButtonType.ACTION,
                    translator._('action.yes'),
                    ButtonActionType.DELETE,
                    () => {
                      dispatch(
                        assignmentDeleteAction(entity.id, () => {
                          dispatch(messageBoxHideAction())
                          refresh()
                        }),
                      )
                    },
                  ),
                ],
              ),
            ),
          )
        },
        disabled: entity => {
          return false
        },
        hidden: entity => {
          return false
        },
      },
    ],
  },
  {
    attribute: 'tags',
    label: null,
    type: ListColumnType.TAG,
    converter: null,
    suffix: null,
    sortable: false,
    renderer: null,
    hidden: entity => {
      return false
    },
    tags: [
      {
        label: entity => {
          return translator._('general.aigenerated')
        },
        icon: entity => {
          return <Icon icon={robotLoveOutline} style={{ marginRight: 5 }} />
        },
        color: entity => {
          return TagColorType.INFO
        },
        hidden: entity => {
          return !entity[AssignmentFieldNames.IS_AI]
        },
      },
    ],
  },
]

export default columns
