import React from 'react'
import { Typography, Button, withStyles } from '@material-ui/core'
import { Icon } from '@iconify/react'
import modalContentStyles from './modalContentStyles'

const ModalContent = props => {
  const { classes, setModalOpen } = props
  const premiumBadgeData = [
    {
      text: 'Pro',
      textColor: '#9747FF',
      backgroundColor: '#9747ff14',
      width: '30px',
    },
    {
      text: 'Premium',
      textColor: '#015FFB',
      backgroundColor: '#015ffb14',
      width: '60px',
    },
  ]

  return (
    <div className={classes.modalContent}>
      <img src="/assets/badge-upgrade-popup.png" style={{ width: '260px' }} />
      <div className={classes.contentWrapper}>
        <div className={classes.sectionWrapper}>
          <Typography className={classes.typographyMain}>
            Upgrade for different badge styles.
          </Typography>
          <div className={classes.badgeRow}>
            {premiumBadgeData.map(viewContents => (
              <div
                className={classes.premiumBadges}
                style={{
                  backgroundColor: viewContents.backgroundColor,
                  width: viewContents.width,
                }}
              >
                <Typography
                  fontSize={'11px'}
                  fontWeight={500}
                  style={{ color: viewContents.textColor }}
                >
                  {viewContents.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        <Typography className={classes.typographySub}>
          You can select premium badges with paid plans and match with your
          branding.
        </Typography>
        <div className={classes.flexRow}>
          <Icon icon="simple-line-icons:badge" className={classes.iconSmall} />
          <Typography className={classes.typographyNote}>
            Select the badge you prefer.
          </Typography>
        </div>
        <div className={classes.flexRow}>
          <Icon icon="streamline:crown" className={classes.iconSmall} />
          <Typography className={classes.typographyNote}>
            Enjoy all premium features together.
          </Typography>
        </div>
        <div className={classes.flexRow}>
          <div className={classes.iconWrapper}>
            <Icon
              icon="clarity:certificate-line"
              className={classes.iconSmall}
            />
          </div>
          <Typography className={classes.typographyNote}>
            You can also provide certificates upon course completions and
            success rate.
          </Typography>
        </div>
        <div className={classes.flexGrow}></div>
        <div className={classes.flexRow}>
          <Button
            variant="text"
            className={classes.notNowButton}
            onClick={() => setModalOpen(false)}
          >
            Not Now
          </Button>
          <Button
            className={classes.upgradeButton}
            onClick={() => (window.location.href = '/pricing')}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(modalContentStyles)(ModalContent)
