import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller, Pagination, Navigation, A11y } from 'swiper/modules'
import { withStyles, Typography } from '@material-ui/core'
import styles from './styles'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const BoxView = props => {
  const { icon, headerText, description, classes } = props

  return (
    <div className={classes.root}>
      <img src={icon} width={36} height={36} alt="icon"></img>
      <Typography fontWeight={600} fontSize="18px">
        {headerText}
      </Typography>
      <Typography fontWeight={400} fontSize={'15px'}>
        {description}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(BoxView)
