const styles = theme => ({
  cardFooter: {
    width: '100%',
    height: '40px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  disabled: {
    opacity: '.6',
  },
  iconButton: {
    width: '32px',
    minWidth: '32px',
    height: '32px',
  },
  icon: {
    color: '#637381',
  },
  itemWithMenu: {
    position: 'relative',
    display: 'flex',
  },
  menuItem: {
    display: 'flex',
    gap: '.7rem',
    fontSize: '12px!important',
  },
  widgetIcon: {
    color: '#919EAB',
    fontSize: '14px',
    fontWeight: 400,
  },
})

export default styles
