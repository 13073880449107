const styles = theme => ({
  form: {
    maxWidth: '50vw',
  },
  marginBottom: {
    marginBottom: '.7rem',
  },
  marginTop: {
    marginTop: '.7rem',
  },
  button: {
    marginTop: '.7rem',
    border: 'none',
    width: 'fit-content',
    alignSelf: 'flex-end',
    '&:hover': {
      border: 'none',
    },
  },
  action: {
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    border: '1px solid #f4f4f48c',
    borderRadius: '10px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  dom: {
    width: '80%'
  },
  badge: {
    position: 'absolute',
    right: 0,
    marginRight: 10,
    background: '#0C53B7',
    padding: '0',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderRadius: '10px',
    color: '#fff',
    fontSize: '10px',
    textAlign: 'center',
    height: 'min-content',
    transition: '.2s background linear',
    '&:hover': {
      cursor: 'pointer',
      background: '#0C53B7'
    }
  }
})

export default styles
