import { put, call, take, fork } from 'redux-saga/effects'
import { sendForgotEmailService } from '../../api/authentication'
import { authenticationForgotPasswordEmailSuccess } from '../../reducers/authentication/action'
import { AUTHENTICATION_FORGOT_PASSWORD_EMAIL } from '../../reducers/authentication/type'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'
import { remove as removeCookie } from 'es-cookie'

/**
 *
 * @param {*} payload
 */
function* sendForgotEmailSagaFlow(payload) {
  const { callback, email } = payload
  try {
    const response = yield call(sendForgotEmailService, email)
    yield put(operationSuccess())
    yield put(authenticationForgotPasswordEmailSuccess(response))
    callback(response)
  } catch (error) {
    yield put(operationFail(error))
    callback(error)
  }
}

/**
 *
 */
export default function* sendForgotEmailSagaWatcher() {
  while (true) {
    const action = yield take(AUTHENTICATION_FORGOT_PASSWORD_EMAIL)
    const { payload } = action

    yield fork(generalSaga, sendForgotEmailSagaFlow, payload)
  }
}
