const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 16,
    paddingBottom: '100px',
  },
  sequentialContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  subRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  badgeRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    marginLeft: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginLeft: '10px',
  },
  textArea: {
    textAlign: 'left',
  },
  textField: {
    width: '400px',
  },
})

export default styles
