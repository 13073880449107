import React from 'react'

import { withStyles, Button, Tooltip, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TranslateIcon from '@material-ui/icons/Translate'
import HelpIcon from '@material-ui/icons/HelpOutline'

import { Icon } from '@iconify/react'

import {
  messageBoxShowAction,
  messageBoxHideAction,
} from 'src/reducers/message-box/action'
import { ButtonType, ButtonData, ButtonActionType } from 'src/model/button'
import MessageBoxContent, { MessageBoxType } from 'src/model/message-box'

import { CardSettingsTypes, TranslateCardTypes } from 'src/model/question'

import translator from 'src/translate'
import {
  assignmentRemoveQuestion,
  assignmentEditAction,
} from 'src/reducers/assignment/action'

import BottomBarFactory from './menu-items/factory'

import styles from './style'
import URLs from 'src/api/url'

import {
  componentBoxShowAction,
  componentBoxHideAction,
} from 'src/reducers/component-box/action'
import ComponentBoxContent from 'src/model/component-box'
import { guid } from 'src/utils/guid'
import ModalContent from 'src/components/card-item/card-settings'

import { QuestionFieldNames, AnswerItemType } from 'src/model/question'

const HelperLinks = {
  [AnswerItemType.SCORECARD]: URLs.APP_URL + 'arA7LyKOxkSE6609h-j9_w/1',
  [AnswerItemType.RESULT]: URLs.APP_URL + 'fTK74G6xvk2S7C1V-GNs9A/1',
  [AnswerItemType.SERTIFIERCARD]: URLs.APP_URL + 'EwYCwYsArU-r5LVq95Sf8g/1',
}

const BottomBar = props => {
  const {
    editor,
    classes,
    entity,
    dispatch,
    courseId,
    onDrag,
    baseEntity,
    cardCount,
    setPage,
    index,
    lastCardFinish,
    addTextToTextArea,
    page,
    onBlur,
    onChange,
    imageURL,
    setImageURL,
    onOk,
    onRemove,
    anchorEl,
    setAnchorEl,
    open,
    spanRef,
    widgetParent,
    onDelete,
    updatedMarkdownText,
    editorType,
    onChangeMarkDown,
  } = props

  const isMenuEnabled = Boolean(anchorEl)

  const [cachedModal, setCachedModal] = React.useState(null)

  const onDeleteClick = e => {
    dispatch(
      messageBoxShowAction(
        new MessageBoxContent(
          MessageBoxType.WARNING,
          'Confirmation',
          [
            new ButtonData(
              ButtonType.NORMAL,
              translator._('action.no'),
              ButtonActionType.NO,
              () => {
                dispatch(messageBoxHideAction())
              },
            ),
            new ButtonData(
              ButtonType.ACTION,
              translator._('action.remove'),
              ButtonActionType.DELETE,
              () => {
                const order = index
                dispatch(
                  assignmentRemoveQuestion(
                    entity.id,
                    window.location.pathname.split('/')[2],
                    () => {
                      dispatch(messageBoxHideAction())
                    },
                  ),
                )
              },
              'error',
            ),
          ],
          translator._('assignment.areYouSureQuestion'),
        ),
      ),
    )
  }

  const settingsCloseHandler = () => {
    dispatch(componentBoxHideAction(cachedModal))
  }

  const settingsClickHandler = () => {
    let modal = new ComponentBoxContent(
      (
        <ModalContent
          cardId={entity.id}
          {...props}
          key={guid.uid}
          closeThisModal={settingsCloseHandler}
        />
      ),
      {
        hideCloseButton: false,
        header: TranslateCardTypes[entity[QuestionFieldNames.QUESTION_TYPE]]
          ? 'Translate'
          : translator._('action.settings'),
        callback: e => {},
      },
    )
    setCachedModal(modal)
    dispatch(componentBoxShowAction(modal))
  }

  let canSeeBack = (index || 0) - 1 >= 0
  let canSeeNext = (index || 0) + 1 < cardCount

  if (lastCardFinish) {
    if (index + 1 == cardCount - 1) {
      canSeeNext = false
    } else if (index + 1 == cardCount) {
      canSeeBack = false
      canSeeNext = false
    }
  }

  const handleNextCard = () => onDrag(index, index + 1)
  const handleBackCard = () => onDrag(index, index - 1)

  return (
    <div className={classes.cardFooter}>
      <div>
        {widgetParent && (
          <Typography className={classes.widgetIcon}>
            {widgetParent.title} Card
          </Typography>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          {CardSettingsTypes[entity.type] &&
            CardSettingsTypes[entity.type].settings && (
              <>
                {HelperLinks[entity[QuestionFieldNames.QUESTION_TYPE]] && (
                  <Tooltip title={'Help'} placement="bottom">
                    <Button
                      className={classes.iconButton}
                      size="small"
                      onClick={() => {
                        window.open(
                          HelperLinks[entity[QuestionFieldNames.QUESTION_TYPE]],
                        )
                      }}
                    >
                      <HelpIcon className={classes.icon} fontSize="small" />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    TranslateCardTypes[entity[QuestionFieldNames.QUESTION_TYPE]]
                      ? 'Translate'
                      : translator._('action.settings')
                  }
                  placement="bottom"
                >
                  <Button
                    className={classes.iconButton}
                    size="small"
                    onClick={() => settingsClickHandler()}
                  >
                    {TranslateCardTypes[
                      entity[QuestionFieldNames.QUESTION_TYPE]
                    ] ? (
                      <TranslateIcon
                        className={classes.icon}
                        fontSize="small"
                      />
                    ) : (
                      <Icon
                        icon="ion:settings-outline"
                        width="18"
                        height="19px"
                        className={classes.icon}
                      />
                    )}
                  </Button>
                </Tooltip>
              </>
            )}
          <BottomBarFactory
            editor={editor}
            type={entity.type}
            key={'bottom-bar' + entity.id}
            //{...props}
            courseId={courseId}
            entity={entity}
            dispatch={dispatch}
            addTextToTextArea={addTextToTextArea}
            page={page}
            onBlur={onBlur}
            onChange={onChange}
            imageURL={imageURL}
            setImageURL={setImageURL}
            onOk={onOk}
            onRemove={onRemove}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            open={open}
            spanRef={spanRef}
            updatedMarkdownText={updatedMarkdownText}
            editorType={editorType}
            onChangeMarkDown={onChangeMarkDown}
          />
        </div>
        <div>
          {cardCount > 1 && (
            <Tooltip title={translator._('action.delete')} placement="bottom">
              <Button
                className={classes.iconButton}
                size="small"
                onClick={() => onDelete(entity[QuestionFieldNames.ID])}
              >
                <Icon
                  icon="bx:trash-alt"
                  width="18"
                  height="19px"
                  className={classes.icon}
                />
              </Button>
            </Tooltip>
          )}

          {/*<Tooltip title={translator._('action.changeOrder')}>
            <span>
              <Button
                className={
                  classes.iconButton + ' ' + (!canSeeBack && classes.disabled)
                }
                size="small"
                onClick={handleBackCard}
                disabled={!canSeeBack}
              >
                <ChevronLeftIcon className={classes.icon} fontSize="small" />
              </Button>
            </span>
          </Tooltip>

          <Tooltip title={translator._('action.changeOrder')}>
            <span>
              <Button
                className={
                  classes.iconButton + ' ' + (!canSeeNext && classes.disabled)
                }
                size="small"
                onClick={handleNextCard}
                disabled={!canSeeNext}
              >
                <ChevronRightIcon className={classes.icon} fontSize="small" />
              </Button>
            </span>
              </Tooltip>*/}
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(BottomBar)
