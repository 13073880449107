import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core'
import styles from '../../styles'
import PaymentOptions from '../data/payment-options'
const Provider = props => {
  const { paymentProvider, handleChange, classes } = props
  return (
    <FormControl variant="outlined" className={classes.formControlPayment}>
      <InputLabel id="payment-selection">Select Payment Processor</InputLabel>
      <Select
        labelId="payment-selection-label"
        id="payment-selection-select"
        value={paymentProvider}
        onChange={handleChange}
        label="Select Payment Processor"
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                marginLeft: '8px',
                marginRight: '8px',
                borderRadius: '8px',
                marginTop: '8px',
              },
            },
          },
        }}
      >
        {PaymentOptions.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img
                src={option.icon}
                alt={option.type}
                style={{ width: 24, marginRight: 4 }}
              />
              <Typography fontSize={'15px'} fontWeight={400}>
                {option.type}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(Provider)
