import React, { useState, useEffect } from 'react'
import styles from './styles'
import { withStyles, Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  CertificateListHeader,
  UserOwnEmptyList,
  UserCertificatesList,
  TemplateCertificatesList,
} from './components'
import { getCertificateListAction } from 'src/reducers/certificate/action'

const CertificateList = props => {
  const { classes, editHtmlValue } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const selectedCollectionId = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )

  const [certificateList, setCertificateList] = React.useState()

  useEffect(() => {
    const getFetchData = async () => {
      dispatch(
        getCertificateListAction(selectedCollectionId, response => {
          setCertificateList(response)
          setLoading(false)
        }),
      )
    }

    getFetchData()
  }, [])

  return (
    <div
      style={{
        width: '940px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '16px',
          padding: '8px',
        }}
      >
       {!loading && <>
          <CertificateListHeader text="My Designs" showIcon={true} />
          {certificateList.collectionCertificateDtos.length > 0 ? (
            <UserCertificatesList
              certificateList={certificateList}
              editHtmlValue={editHtmlValue}
            />
          ) : (
            <UserOwnEmptyList />
          )}
          <CertificateListHeader
            text="Certificate Templates"
            showIcon={false}
          />
          <TemplateCertificatesList
            certificateList={certificateList}
            editHtmlValue={editHtmlValue}
          />
        </>}
      </div>
    </div>
  )
}

export default withStyles(styles)(CertificateList)
