import { put, call, take, fork } from 'redux-saga/effects'
import { getCertificateListService } from 'src/api/certificate'
import { getCertificateListSuccess } from 'src/reducers/certificate/action.js'
import { GET_CERTIFICATE_LIST } from 'src/reducers/certificate/type.js'
import generalSaga from '../general'
import {
  operationSuccess,
  operationFail,
} from '../../reducers/operation/action'

/**
 *
 * @param {*} payload
 */
function* getCertificateListFlow(payload) {
  try {
    const { selectedCollectionId, callback } = payload

    const response = yield call(getCertificateListService, selectedCollectionId)
    yield put(getCertificateListSuccess(response.data))
    yield put(operationSuccess())
    callback(response.data)
  } catch (error) {
    yield put(operationFail(error))
  }
}

/**
 *
 */
export default function* getCertificateListSagaWatcher() {
  while (true) {
    const action = yield take(GET_CERTIFICATE_LIST)
    const { payload } = action

    yield fork(generalSaga, getCertificateListFlow, payload)
  }
}
