import React, { useState, useEffect } from 'react'
import styles from './style'
import { withStyles, TextField, Divider } from '@material-ui/core'
import { QuestionFieldNames } from '../../../model/question'
import { questionPatchAction } from 'src/reducers/question/action'
import { PatchContent, PatchData } from 'src/model/patch'

const CardTitle = props => {
  const { entity, dispatch, classes } = props
  const [title, setTitle] = useState(entity[QuestionFieldNames.TITLE])
  useEffect(() => {
    setTitle(entity[QuestionFieldNames.TITLE])
  }, [entity[QuestionFieldNames.TITLE]])
  const onBlur = async value => {
    if (entity[QuestionFieldNames.TITLE] !== value) {
      await dispatch(
        questionPatchAction(
          new PatchData(
            new PatchContent(
              value,
              QuestionFieldNames.TITLE,
              PatchContent.OPERATION_REPLACE,
            ),
            entity[QuestionFieldNames.ID],
          ),
          () => {},
        ),
      )
    }
  }
  return (
    <div className={classes.root}>
      <TextField
        placeholder="Card Title"
        className={classes.textfield}
        InputProps={{
          className: classes.textfieldInput,
        }}
        inputProps={{ style: { textAlign: 'center', padding: 0 } }}
        defaultValue={entity[QuestionFieldNames.TITLE]}
        onBlur={e => onBlur(e.target.value)}
        onChange={e => setTitle(e.target.value)}
        value={title}
        multiline
      ></TextField>
      <Divider className={classes.divider} variant="middle" />
    </div>
  )
}

export default withStyles(styles)(CardTitle)
