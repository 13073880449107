import { Component } from 'react'
import propTypes from './prop'
import { set as setCookie } from 'es-cookie'
import { validateSuperLoginService } from '../../api/authentication'

class SuperLoginReroute extends Component {
  checkId = async id => {
    let response = await validateSuperLoginService(id)

    if (response.data != null) {
      setCookie('token', response.data.accessToken, {sameSite: 'none',secure: true})
      setCookie('refreshToken', response.data.refreshToken, { sameSite: 'none',secure: true })
      setCookie('userId', response.data.userId, { sameSite: 'none',secure: true })
      //setCookie('token', token)

      window.location.href = '/'
    } else {
      window.location.href = '/login'
    }
  }

  /**
   *
   */
  render() {
    let url = window.location.href
    //let token = url.split('token=')[1]
    let id = url.split('id=')[1]

    if (id) {
      this.checkId(id)
    }
    // if (token) {
    //     this.checkToken(token)
    // }

    return ''
  }
}

SuperLoginReroute.propTypes = propTypes

export default SuperLoginReroute
