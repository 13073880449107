import CardTypeFactory from './cardItem/factory'
import { QuestionFieldNames } from 'src/model/question'
import { AnswerItemType } from 'src/model/question'
import {
  Page,
  Document,
  View,
  Image,
  Svg,
  Path,
  G,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import { parseFontSize } from 'src/utils/parseFontSize'
import { GoogleFontList } from 'src/utils/googleFontList'
import { PdfStyleSheet, BackgroundColorRgb, ButtonColorRgb } from './pdf-style'
import URL from 'src/api/url'

const MyDoc = props => {
  const { cards, themeSettings, logo } = props

  const backgroundImageUrl = themeSettings.bodyBackgroundImage
    ? themeSettings.bodyBackgroundImage.replace('svg', 'jpg')
    : ''

  const backgroundColorRgb = BackgroundColorRgb(themeSettings)
  const buttonColorRgb = ButtonColorRgb(themeSettings)

  const styles = PdfStyleSheet(themeSettings)

  return (
    <Document>
      {cards
        .filter(
          x =>
            x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.INFORM ||
            x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.AI ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.TESTINGSINGLECHOICE ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.TESTINGMULTIPLECHOICE ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.SURVEYSINGLECHOICE ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.SURVEYMULTIPLECHOICE ||
            x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.SURVEYSTARS ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.SURVEYNUMERICENTRY ||
            x[QuestionFieldNames.QUESTION_TYPE] == AnswerItemType.SURVEYINPUT ||
            x[QuestionFieldNames.QUESTION_TYPE] ==
              AnswerItemType.SURVEYFREEFORM,
        )
        .map(
          (card, index) =>
            card !== undefined && (
              <Page
                size={[300, 400]}
                style={[
                  styles.page,
                  {
                    paddingTop:
                      card[QuestionFieldNames.QUESTION_TYPE] !==
                      AnswerItemType.INFORM
                        ? 0
                        : 20,
                  },
                ]}
                wrap={
                  index == 0 ||
                  index == cards.length - 1 ||
                  card[QuestionFieldNames.QUESTION_TYPE] !==
                    AnswerItemType.INFORM
                    ? false
                    : true
                }
              >
                {(index == 0 ||
                  index == cards.length - 1 ||
                  card[QuestionFieldNames.QUESTION_TYPE] !==
                    AnswerItemType.INFORM) &&
                  backgroundImageUrl != '' && (
                    <Image
                      style={styles.backgroundImage}
                      src={backgroundImageUrl}
                      cache={false}
                    />
                  )}

                <View style={styles.view}>
                  {(index == 0 || index == cards.length - 1) &&
                    card[QuestionFieldNames.QUESTION_TYPE] ==
                      AnswerItemType.INFORM &&
                    logo != undefined && (
                      <Image style={styles.logo} src={URL.CDN + logo} />
                    )}

                  {CardTypeFactory({
                    type: card[QuestionFieldNames.QUESTION_TYPE],
                    card: card,
                    page: index,
                    stylesheet: styles,
                    style: styles.text,
                    buttonColorRgb: buttonColorRgb,
                    backgroundColorRgb: backgroundColorRgb,
                    editor: undefined,
                  })}
                </View>

                {themeSettings.arrow == undefined ||
                  (themeSettings.arrow == 1 && index !== cards.length - 1 && (
                    <Svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      fixed
                      style={styles.arrow}
                    >
                      <Path
                        fill={buttonColorRgb}
                        d="M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z M15,11.8L10,16.9c-0.4,0.4-1.1,0.4-1.5,0
c-0.4-0.4-0.4-1.1,0-1.5l4.3-4.3L8.5,6.7c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.4,1.1-0.4,1.5,0l5.1,5.1C15.5,10.7,15.5,11.4,15,11.8z"
                      />
                    </Svg>
                  ))}
                {themeSettings.arrow == 2 && index !== cards.length - 1 && (
                  <Svg
                    width="50"
                    height="22"
                    viewBox="0 0 50 22"
                    fill="none"
                    fixed
                    style={styles.arrow}
                  >
                    <G>
                      <Path
                        fill={buttonColorRgb}
                        d="M0.4,2.6c-1-1-0.3-2.6,1.1-2.6H6c0.4,0,0.8,0.2,1.1,0.5l9.3,9.5c0.6,0.6,0.6,1.5,0,2.1l-9.3,9.5
C6.8,21.8,6.4,22,6,22H1.6c-1.4,0-2.1-1.6-1.1-2.6l7.3-7.3c0.6-0.6,0.6-1.5,0-2.1L0.4,2.6z"
                      />
                      <Path
                        fill={buttonColorRgb}
                        d="M17,2.6c-1-1-0.3-2.6,1.1-2.6h4.5c0.4,0,0.8,0.2,1.1,0.5L33,9.9c0.6,0.6,0.6,1.5,0,2.1l-9.3,9.5
C23.4,21.8,23,22,22.6,22h-4.5c-1.4,0-2.1-1.6-1.1-2.6l7.3-7.3c0.6-0.6,0.6-1.5,0-2.1L17,2.6z"
                      />
                      <Path
                        fill={buttonColorRgb}
                        d="M33.5,2.6c-1-1-0.3-2.6,1.1-2.6h4.5c0.4,0,0.8,0.2,1.1,0.5l9.3,9.5c0.6,0.6,0.6,1.5,0,2.1l-9.3,9.5
c-0.3,0.3-0.7,0.5-1.1,0.5h-4.5c-1.4,0-2.1-1.6-1.1-2.6l7.3-7.3c0.6-0.6,0.6-1.5,0-2.1L33.5,2.6z"
                      />
                    </G>
                  </Svg>
                )}
                {themeSettings.arrow == 3 && index !== cards.length - 1 && (
                  <Svg
                    width="34"
                    height="22"
                    viewBox="0 0 34 22"
                    fill="none"
                    fixed
                    style={styles.arrow}
                  >
                    <G>
                      <Path
                        fill={buttonColorRgb}
                        d="M33.6,10.1l-9.9-9.7c-0.6-0.5-1.5-0.5-2,0c-0.5,0.5-0.5,1.4,0,1.9l7.5,7.3H1.5c-0.8,0-1.5,0.5-1.5,1.3
                c0,0.8,0.5,1.4,1.3,1.5c0.1,0,0.1,0,0.2,0h27.7l-7.6,7.3c-0.6,0.5-0.6,1.4-0.1,1.9c0.5,0.6,1.4,0.6,2,0.1c0,0,0.1-0.1,0.1-0.1
                l9.9-9.5C34.1,11.5,34.1,10.6,33.6,10.1C33.6,10.1,33.6,10.1,33.6,10.1L33.6,10.1z"
                      />
                    </G>
                  </Svg>
                )}

                {themeSettings.arrow == 4 && index !== cards.length - 1 && (
                  <Svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    fixed
                    style={styles.arrow}
                  >
                    <G>
                      <Path
                        fill={buttonColorRgb}
                        d="M20.1,9.5c-0.2-0.6-0.8-0.9-1.4-0.9c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.3-0.6-0.9-1-1.6-1
                  c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.7,0.4-0.9,0.7c-0.3-0.4-0.8-0.6-1.3-0.6c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.4-1,0.8L10.1,5
                  C9.8,4.2,9.2,3.7,8.5,3.7c-0.2,0-0.4,0-0.6,0.1C7.1,4.2,6.7,5.1,7,6.1l3.6,9.7l-1.8-0.9c-0.3-0.1-0.6-0.2-0.9-0.2
                  c-0.8,0-1.5,0.5-1.8,1.2c-0.2,0.5-0.3,1-0.1,1.5c0.2,0.5,0.6,0.9,1,1.1c0,0,1.7,0.9,4,2l0.4,0.2C13,21.3,14.4,22,16,22h0
                  c0.8,0,1.6-0.1,2.4-0.4c2.9-1.1,3.3-2.9,3.6-4.6C22.2,15.3,20.2,9.8,20.1,9.5z M21.3,16.9c-0.2,1.7-0.6,3.1-3.1,4
                  c-0.7,0.3-1.4,0.4-2.1,0.4h0c-1.5,0-2.8-0.6-4.2-1.3l-0.4-0.2c-2.3-1.1-4-2-4-2c-0.3-0.1-0.5-0.4-0.7-0.7c-0.1-0.3-0.1-0.7,0-1
                  c0.2-0.4,0.6-0.7,1.1-0.7c0.2,0,0.4,0,0.5,0.1l2.7,1.3c0.1,0.1,0.3,0,0.4-0.1c0.1-0.1,0.2-0.3,0.1-0.4L7.7,5.9
                  C7.6,5.4,7.6,4.7,8.2,4.5c0.1,0,0.2-0.1,0.3-0.1c0.4,0,0.7,0.3,0.9,0.8l2.3,6.4c0.1,0.2,0.3,0.3,0.5,0.2c0.2-0.1,0.3-0.3,0.2-0.5
                  l-0.3-0.9c-0.3-0.8,0.3-1.3,0.8-1.4C13,9,13.1,9,13.2,9c0.4,0,0.8,0.3,0.9,0.6l0.5,1.2l0,0c0.1,0.2,0.3,0.3,0.5,0.2
                  c0.2-0.1,0.3-0.3,0.2-0.5l-0.2-0.6c-0.2-0.5,0.1-1.1,0.6-1.2c0.1,0,0.2-0.1,0.3-0.1c0.4,0,0.8,0.3,0.9,0.6l0.5,1.5l0,0
                  c0.1,0.2,0.3,0.3,0.5,0.2c0.2-0.1,0.3-0.3,0.2-0.5l-0.3-0.9c0.1-0.1,0.4-0.3,0.5-0.4c0.1,0,0.2,0,0.3,0c0.4,0,0.7,0.2,0.8,0.4
                  C20,11.3,21.4,15.6,21.3,16.9z"
                      />
                      <Path
                        fill={buttonColorRgb}
                        d="M2.4,2.3c4.3-1,8.8-0.8,13,0.4c0.3,0.1,0.6-0.1,0.7-0.3c0.1-0.2-0.1-0.5-0.4-0.6C11.3,0.5,6.6,0.3,2.1,1.4
                  C2.1,1,2,0.6,1.9,0.3C1.9,0.1,1.8,0,1.6,0C1.5,0,1.4,0,1.3,0.2C0.8,0.8,0.4,1.4,0.1,2c-0.1,0.2,0,0.4,0.2,0.5
                  c0.7,0.3,1.3,0.6,1.9,1c0.1,0.1,0.2,0.1,0.4,0c0.1-0.1,0.1-0.2,0.1-0.4C2.5,2.9,2.4,2.6,2.4,2.3z"
                      />
                    </G>
                  </Svg>
                )}

                {/* {index == cards.length - 1 && (
                  <View style={styles.lastCardSocialIcons}>
                    <Svg
                      width="151"
                      height="33"
                      viewBox="0 0 101.2 22"
                      fill={buttonColorRgb}
                    >
                      <G>
                        <Path
                          fill={buttonColorRgb}
                          d="M67.9,7L59,10.6c-0.2,0.1-0.2,0.3,0,0.4l3.3,1.4c0.2,0.1,0.3,0.2,0.4,0.4l1.4,3.2c0.1,0.2,0.3,0.2,0.4,0l3.7-8.6
		C68.2,7.1,68.1,7,67.9,7z"
                        />
                        <Path
                          fill={buttonColorRgb}
                          d="M45.1,7.3c-0.3,0-0.8,0-1.4,0h-4.8c-0.6,0-1.1,0-1.4,0s-1.4,0.3-1.4,1.4c0,0.3,0,0.8,0,1.4v1.8c0,0.6,0,1.1,0,1.4
		c0,1,0.6,1.4,1.4,1.4c0.3,0,0.8,0,1.4,0H44c0.4,0,0.8,0.2,1.1,0.4l1.5,1.5v-1.2v-3.7v-1.6c0-0.6,0-1.1,0-1.4
		C46.6,7.8,45.4,7.3,45.1,7.3z M38.8,10.3c-0.4,0-0.6-0.3-0.6-0.6S38.4,9,38.7,9h5.1c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.2,0.7-0.5,0.7
		H38.8z M43.9,12.9h-3.2c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h3.2c0.4,0,0.6,0.3,0.6,0.6S44.2,12.9,43.9,12.9z"
                        />
                        <Path
                          fill={buttonColorRgb}
                          d="M87.7,5.7h-7.6c-0.4,0-0.6,0.3-0.6,0.6v10.3l4.1-2.6c0.2-0.1,0.5-0.1,0.7,0l4.1,2.6V6.3C88.4,5.9,88.1,5.7,87.7,5.7z"
                        />
                        <Path
                          fill={buttonColorRgb}
                          d="M25.1,9.5h-1.3c-0.8,0-1.6-0.5-2-1.2l-1.7-3.5c-0.1-0.2-0.3-0.3-0.5-0.3c-0.4,0-0.9,0.4-0.7,0.9l0.9,3.1
		c0.1,0.2-0.1,0.4-0.2,0.4h-0.1h-5.2c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s1.2,0,1.5,0c0.2,0,0.3,0.2,0.3,0.3
		c0,0.2-0.2,0.3-0.3,0.3l0,0H15c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.8c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-1.1
		c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.9c0,0,0,0,0.1,0c0.2,0,0.3,0.1,0.3,0.3S17.9,15,17.7,15c0,0,0,0-0.1,0h-1.3
		c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h3.1l0,0l0,0l0,0l0,0h2.4c0.4,0,0.8-0.2,1.1-0.5c0.5-0.6,1.3-0.9,2-0.9
		c0.2,0,0.3-0.1,0.3-0.3C25.2,14.7,25.2,9.5,25.1,9.5z"
                        />
                        <Path
                          fill={buttonColorRgb}
                          d="M90.2,0H11C4.9,0,0,4.9,0,11s4.9,11,11,11h79.2c6.1,0,11-4.9,11-11S96.3,0,90.2,0z M25,16c-0.4,0-0.9,0.2-1.2,0.5
		c-0.5,0.5-1.2,0.8-1.9,0.8h-2.4h-0.1h-0.1l0,0h-3.1c-1,0-1.9-0.8-1.9-1.9c0-0.2,0-0.3,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5
		c0-0.2,0-0.3,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5c0-0.2,0-0.4,0.1-0.5c-0.4-0.3-0.7-0.9-0.7-1.5c0-1,0.8-1.9,1.9-1.9h4l-0.6-1.9
		c-0.2-0.6-0.1-1.2,0.3-1.7s1-0.8,1.6-0.8l0,0c0.7,0,1.3,0.4,1.6,1l0,0l0,0L23,7.6c0.2,0.3,0.5,0.6,0.9,0.6h1.3
		c0.8,0,1.5,0.7,1.5,1.5v4.8C26.7,14.5,26.7,16,25,16z M48,10.1v1.6v3.7v2.2c0,1-1.2,1.4-1.8,0.8L44,16.2h-5.1c-0.6,0-1.1,0-1.5,0
		s-0.8-0.1-1.2-0.3c-0.6-0.3-1-0.7-1.3-1.3c-0.2-0.4-0.3-0.7-0.3-1.2c0-0.4,0-0.9,0-1.5v-1.8c0-0.6,0-1.1,0-1.5s0.1-0.8,0.3-1.2
		c0.3-0.6,0.7-1,1.3-1.3c0.4-0.2,0.7-0.3,1.2-0.3c0.4,0,0.9,0,1.5,0h4.8c0.6,0,1.1,0,1.5,0s0.8,0.1,1.2,0.3c0.6,0.3,1,0.7,1.3,1.3
		C47.9,7.8,48,8.1,48,8.6C48,9,48,9.5,48,10.1z M70.2,5.5l-5.3,12.9c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0-0.4-0.1-0.5-0.3l-2.1-5
		c0-0.1-0.1-0.1-0.1-0.1l-5-2.1c-0.2-0.1-0.3-0.3-0.3-0.5s0.1-0.4,0.4-0.5L69.7,5c0.2-0.1,0.5,0,0.6,0.1C70.2,5,70.3,5.2,70.2,5.5z
		 M89.5,18c-0.2,0.3-0.6,0.4-0.9,0.2l-4.8-3l-4.8,3c-0.2,0.1-0.4,0.1-0.6,0s-0.3-0.3-0.3-0.6V6.3c0-1.1,0.9-1.9,1.9-1.9h7.6
		c1.1,0,1.9,0.9,1.9,1.9l0.1,11.4C89.6,17.8,89.6,17.9,89.5,18z"
                        />
                      </G>
                    </Svg>
                  </View>
                )} */}
              </Page>
            ),
        )}
    </Document>
  )
}
export default MyDoc
