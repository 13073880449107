import { Typography } from '@material-ui/core'
import React from 'react'

const AutomationsHeader = () => {
  return (
    <div>
      <Typography fontSize={24} fontWeight={700}>
        Automations
      </Typography>
    </div>
  )
}

export default AutomationsHeader
