import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  main: {
    //paddingBottom: '1rem',
    //width: 300,
    margin: '0 auto',
  },
  childGrid: {
    marginBottom: theme.spacing(1.5),
    background: 'url(/assets/dummy-image-background.jpg)',
    height: 200,
    flexBasis: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
  },

  button: {
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 37,
  },
  linkBox: {
    margin: '0 auto',
    padding: theme.spacing(1),
    paddingLeft: 120,
    textAlign: 'center',
  },
  addBox: {
    width: 160,
    margin: '0 auto',
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  checkboxIcon: {
    color: grey[400],
    width: '20px',
  },
  checkBoxCheckedIcon: {
    width: '20px',
    color: '#015FFB',
  },
  checkbox: {
    float: 'left',
    width: '30px',
    height: '30px',
  },
  deleteButton: {
    color: grey[400],
    display: 'none',
    float: 'left',
  },
  container: {
    border: '1px solid',
    borderRadius: 10,
    marginBottom: 8,
    display: 'grid',
    gridTemplate: 'auto / 32px calc(100% - 64px) 32px',
    '&:hover button': {
      display: 'block',
    },
  },
  containerNoGrid: {
    border: '1px solid',
    borderRadius: 10,
    marginBottom: 8,
    gridTemplate: 'auto / 0px calc(100% - 32px) 32px',

    display: 'grid',
    '&:hover button': {
      display: 'block',
    },
  },
  noBorder: {
    border: 'none',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid: {
    width: '100%',
  },
  input: {
    fontSize: 14,
  },
  alert: {
    marginBottom: '8px',
  },
  selectionsGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
  },
  addIcon: {
    marginLeft: 8,
    width: 20,
    height: 20,
    color: '#015FFB',
  },
  addText: {
    fontWeight: 400,
    fontSize: 12,
    color: '#015FFB',
  },
})

export default styles
