import { guid } from '../../../../utils/guid'

import { FormItemInvited, FormItemActive, FormItemLoggedin } from './form-item'

const Filters = {
  addTitle: guid().uid(),
  editTitle: null,
  groups: [],
  formItemsSuggestion: [
    { ...FormItemInvited },
    { ...FormItemActive },
    { ...FormItemLoggedin },
  ],
}

export default Filters
