import React from 'react'

import { withStyles, Typography } from '@material-ui/core'
import styles from '../styles'

const AuthenticatedLinksViewContent = props => {
  const { secretKey, entity, classes } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
      }}
    >
      <div className={classes.borderBottom}></div>
      <Typography fontSize={'15px'} fontWeight={500}>
        Prerequisites
      </Typography>
      <div>
        <ul className={classes.liPadding}>
          <li>
            <Typography fontSize={'14px'} fontWeight={400}>
              You’ve chosen the right seamless base link depending on the
              content type. For more details, refer to the Requirements for
              Seamless Links topic in the Related Links section.
            </Typography>
          </li>
          <li>
            <Typography fontSize={'14px'} fontWeight={400}>
              You have a signing secret in eduMe. For more details, refer to the
              Signing Secrets topic in the Related Links section.
            </Typography>
          </li>
          <li>
            <Typography fontSize={'14px'} fontWeight={400}>
              You have a list of unique user identifiers.
            </Typography>
          </li>
        </ul>
      </div>
      <div
        style={{
          padding: '12px 16px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          backgroundColor: '#E3F3FF',
        }}
      >
        <Typography
          fontSize={'14px'}
          fontWeight={400}
          dangerouslySetInnerHTML={{
            //__html: `import crypto from 'crypto'; <br/> const generateSignature = (url, secret) => {<br/>&nbsp;&nbsp;return crypto<br/>&nbsp;&nbsp;&nbsp;&nbsp;.createHmac('sha256', secret)<br/> &nbsp;&nbsp;&nbsp;&nbsp;.update(url)<br/>&nbsp;&nbsp;&nbsp;&nbsp;.digest('hex');<br/>}<br/>const SHARED_SECRET = "${secretKey}";<br/>const signature = generateSignature(url, SHARED_SECRET);<br/>// signature = SIGNATURE_SAMPLE_RESULT`,
            __html: `var CryptoJS = require("crypto-js");
            <br/>var collectionId = "${entity.id}";
            <br/>var secretKey = CryptoJS.enc.Utf8.parse("${secretKey}");
            <br/>var courseCode = "YOUR_COURSE_CODE"; // Optional
            <br/>var host = "YOUR_CUSTOM_DOMAIN"; // Optional
            
            <br/><br/>function encryptData(data) {
              <br/>&ensp; let encryptedBytes = CryptoJS.AES.encrypt(
                <br/>&emsp;CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
                <br/>&emsp;secretKey,
                <br/>&emsp;{ mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 },
                <br/>&ensp; );
                <br/>&emsp;return encodeURIComponent(encryptedBytes.toString());
                <br/>}
                <br/><br/>let data = {
                  <br/>&emsp;courseCollectionId: collectionId,
                  <br/>&emsp;//courseCode: courseCode, //Optional
                  <br/>&emsp;//host: host, // Optional
                  <br/>&emsp;//name: "You can set name surname", // Optional
                  <br/>&emsp;username: "You have to set username",
                  <br/>};
            
                  <br/><br/>var token = encryptData(data);
                  <br/>console.log(
              "https://test-gateway.minicoursegenerator.com/api/learner-auth/${entity.id}/generate-magic-link?token=&#36;{token}",
            );
            <br/>// You can redirect or open inside iframe to this url
            `,
          }}
        ></Typography>
      </div>
      {/*<div>
        <Typography fontSize={'15px'} fontWeight={500}>
          Related Links
        </Typography>
        <ul className={classes.liPadding}>
          {linkContent.map((item, index) => (
            <li key={index}>
              <a
                href={item.link}
                style={{ fontSize: '14px', fontWeight: 400 }}
                target="blank"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
          </div>*/}
    </div>
  )
}

export default withStyles(styles)(AuthenticatedLinksViewContent)
