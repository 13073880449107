import React, { useEffect } from 'react'
import styles from '../styles'
import {
  withStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Icon } from '@iconify/react' // Import Sun Editor's CSS File
import { TeamListItem } from '..'
import { useSelector } from 'react-redux'
import CustomizedSwitch from 'src/components/customized-switch'
import { PatchContent, PatchData } from 'src/model/patch'
import { patchCollectionSettingsAction } from 'src/reducers/course-collection/action'
const MailCName = props => {
  const {
    entity,
    collectionSettings,
    dispatch,
    handleSendLinkToNewMembersOnly,
    sendLinkToNewMembersOnly,
    emailText,
    setEmailText,
    emailTitle,
    setEmailTitle,
    classes,
  } = props

  const [hasTeamsToView, setHasTeamsToView] = React.useState(false)

  const [teamListLength, setTeamListLength] = React.useState(0)
  const [memberListLength, setMemberListLength] = React.useState(0)
  const [selectedDomain, setSelectedDomain] = React.useState({
    id: '',
    domain: '',
  })
  const [domainList, setDomainList] = React.useState([])
  

  const domainRawList = useSelector(state => state.domain.list.results)

  const domainListCreator = () => {
    const list = []

    domainRawList.map(domain => {
      if (
        domain.defaultCollectionDto &&
        domain.defaultCollectionDto.id === entity.id &&
        domain.verificationStatus === 1
      ) {
        list.push(domain)
      }
    })
    return list
  }
  useEffect(() => {
    const result = domainRawList && domainListCreator()
    setDomainList(result)
  }, [])

  const handleSelect = event => {
    setSelectedDomain({
      id: event.target.value,
      domain: event.target.name,
    })

    const patchSource = new PatchData(null, entity.id)
    patchSource.addContent(
      new PatchContent(
        event.target.value,
        'customDomainId',
        PatchData.OPERATION_REPLACE,
      ),
    )

    dispatch(patchCollectionSettingsAction(patchSource, response => {}))
  }
  useEffect(() => {
    if (collectionSettings.teams.length !== 0) {
      setHasTeamsToView(true)
      setTeamListLength(collectionSettings.teams.length)
      collectionSettings.teams.map(team => {
        setMemberListLength(prev => prev + team.memberCount)
      })
    }
  }, [collectionSettings.teams])

  const onBlurEmailTitle = value => {
    if (collectionSettings.invite_member_subject !== value) {
      const patchSource = new PatchData(null, entity.id)
      patchSource.addContent(
        new PatchContent(
          value,
          'inviteEmailSubject',
          PatchData.OPERATION_REPLACE,
        ),
      )

      dispatch(patchCollectionSettingsAction(patchSource, response => {}))
    }
  }
  return (
    <div className={classes.customDomain_select}>
      <div className={classes.displayF}>
      
        <Typography  color="#637381" fontWeight={500} fontSize={'14px'}>
          Choose your custom domain linked to your collection
        </Typography>
      </div>

      {domainList && domainList.length !== 0 ? (
        <FormControl variant="outlined" className={classes.formControlHalf}>
          <InputLabel id="collection-setting-custom-domain">
            Custom Domain
          </InputLabel>
          <Select
            labelId="collection-setting-custom-domain"
            id="demo-simple-select-outlined"
            value={selectedDomain.id}
            label="Custom Domain"
            onChange={event => {
              handleSelect(event)
            }}
            sx={{backgroundColor:"#ffffff", borderRadius:"6px"}}
          >
            {domainList.map((domain, index) => {
              return (
                <MenuItem key={index} value={domain.id} name={domain.domain}>
                  {domain.domain}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl variant="outlined" className={classes.formControlHalf}>
          <InputLabel id="collection-setting-custom-domain">
            Custom Domain
          </InputLabel>
          <Select disabled={true} value={1}  sx={{backgroundColor:"#ffffff", borderRadius:"6px"}}>
            <MenuItem value={1}>
              (Default) share.minicoursegenerator.com
            </MenuItem>
          </Select>
        </FormControl>
        /*   <div className={classes.noTeamsDiv}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="/assets/no-collection.png"
              style={{
                width: '80px',
                height: '80px',
              }}
            ></img>
          </div>

          <Typography textAlign={'center'} fontSize={'15px'} fontWeight={500}>
            No custom domain selected{' '}
          </Typography>
          <Typography
            textAlign={'center'}
            fontSize={'14px'}
            color="#637381"
            fontWeight={400}
          >
            By Default your collection URL will look like this
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div className={classes.customDomainView}>
              <Icon icon={'iconoir:lock'} style={{ marginTop: '3px' }}></Icon>
              <Typography fontSize={'14px'} fontWeight={500}>
                share.minicourse.com/category/coll-name/...
              </Typography>
            </div>
          </div>

          <Typography
            textAlign={'center'}
            fontSize={'14px'}
            fontWeight={400}
            color={'#637381'}
          >
            To connect a domain, go to the Custom Domain tab.
          </Typography>
        </div> */
      )}
    </div>
  )
}

export default withStyles(styles)(MailCName)
