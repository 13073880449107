import React from 'react'
import styles from './style'
import { withStyles, Button, Typography, Box } from '@material-ui/core'
import moment from 'moment-timezone'
import { Link as RouterLink } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'

const AICreationLimitModal = props => {
  const { refreshesAt, handleClickRedirect, type, roleType } = props

  return (
    <>
      <Box width="200px">
        {type == 'Course Creation' ? (
          <img src="/assets/ai-robo-happy-whitebg.gif"></img>
        ) : (
          <img src="/assets/ai_mascot_limit.gif"></img>
        )}
      </Box>
      <Typography variant="h4" color="#015FFB" marginBottom="10px">
        {roleType == 1 || roleType == 11 || roleType == 12
          ? "Wow, you've reached the ceiling!"
          : 'Wow, you’re fast!'}
      </Typography>
      <Typography variant="subtitle" marginBottom="10px">
        Your credits in the current plan will be renewed on{' '}
        {moment(refreshesAt).add('days', 30).format('L')}
      </Typography>
      <Typography
        variant="subtitle"
        marginBottom="10px"
        style={{ textAlign: 'center' }}
      >
        {roleType == 1 &&
          'Upgrade now to get more credits, and to continue call AI-Assistant! 👑'}
        {(roleType == 11 || roleType == 12) &&
          'Upgrade your account to have more credits for AI Assistant.'}

        {(roleType == 2 ||
          roleType == 4 ||
          roleType == 5 ||
          roleType == 7 ||
          roleType == 8 ||
          roleType == 9 ||
          roleType == 10 ||
          roleType == 13 ||
          roleType == 14) &&
          'It looks like you have used all your monthly credits for AI Assistant.'}
      </Typography>

      {(roleType == 11 || roleType == 12) && (
        <Button
          style={{ backgroundColor: '#015FFB' }}
          variant="contained"
          to="/pricing"
          component={RouterLink}
          onClick={() => {
            handleClickRedirect('Pricing')
            track('limit_warning_upgrade', {
              from: `ai_limit_upgrade${type}`,
            })
          }}
        >
          See Plans and Upgrade
        </Button>
      )}
      {roleType == 1 && (
        <Button
          style={{ backgroundColor: '#015FFB' }}
          variant="contained"
          to="/pricing"
          component={RouterLink}
          onClick={() => {
            handleClickRedirect('Pricing')
            track('limit_warning_upgrade', {
              from: 'ai_limit_upgrade',
            })
          }}
        >
          Start now!
        </Button>
      )}
      {(roleType == 2 ||
        roleType == 4 ||
        roleType == 5 ||
        roleType == 7 ||
        roleType == 8 ||
        roleType == 9 ||
        roleType == 10 ||
        roleType == 13 ||
        roleType == 14) && (
        <Button
          style={{ backgroundColor: '#015FFB' }}
          variant="contained"
          onClick={() => handleClickRedirect('Home Page')}
        >
          Got it!
        </Button>
      )}
    </>
  )
}

export default withStyles(styles)(AICreationLimitModal)
