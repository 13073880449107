import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import styles from './styles'

const UserCertificatesList = props => {
  const { certificateList, editHtmlValue, classes } = props
  return (
    <div className={classes.container}>
      {certificateList &&
        certificateList.collectionCertificateDtos &&
        certificateList.collectionCertificateDtos.length > 0 &&
        certificateList.collectionCertificateDtos.map((certificate, index) => (
          <div
            key={index}
            className={classes.certificateItem}
            onClick={() => editHtmlValue(certificate.html)}
          >
            <img
              src="/assets/Select_Template_Certificate.png"
              alt="certificate"
              className={classes.certificateImage}
            />
            <Typography className={classes.certificateText}>
              {certificate.courseCollectionName}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default withStyles(styles)(UserCertificatesList)
