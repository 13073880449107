import URL from '../url'
import Request from '../request'
import { get as getCookie } from 'es-cookie'

/**
 *
 * @param {*} entity
 */
export const getUserLimits = () =>
  new Request(URL.API, URL.GET_USER_LIMITS).get({userId: getCookie("userId")})

/**
 *
 * @param {*} entity
 */
export const customerPortal = () =>
  new Request(URL.API, URL.CUSTOMER_PORTAL).post()

/**
 *
 *
 */
export const customerUpgradePortal = () =>
  new Request(URL.API, URL.CUSTOMER_PORTAL_UPGRADE).post()
