import React from 'react'
import styles from './style'
import propTypes from './prop'
import { withStyles, Typography, Card, Box } from '@material-ui/core'
import { Icon } from '@iconify/react'

/**
 *
 * @param {*} props
 */
const MediaHeader = props => {
  const { popupType } = props

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '70px',
        borderRadius: '8px',
        alignItems: 'center',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '100%',
      }}
    >
      {popupType == 'create prompt' ? (
        <>
          <img
            style={{ width: '30px', marginLeft: '24px' }}
            src={`/assets/ai-image.png`}
            alt="ai"
          />
          <Box m={0.5}></Box>
          <Typography variant="h5" color="#015FFB">
            AI-Image Generator
          </Typography>
        </>
      ) : (
        <>
          <div
            style={{
              marginLeft: '24px',
            }}
          ></div>
          <Icon
            icon="ic:round-add-a-photo"
            color="#00AB55"
            width="24"
            height="24"
          />
          <Typography
            style={{
              marginLeft: 8,
              color: '#002E47',
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Media Gallery
          </Typography>
        </>
      )}
    </Card>
  )
}

MediaHeader.propTypes = propTypes

export default withStyles(styles)(MediaHeader)
