const styles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  characterCount: {
    textAlign: 'left',
    fontSize: 11,
  },
  paper: {
    borderRadius: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '320px',
    width: '280px',
    //padding: 32,
    alignItems: 'center',
    outline: '1px solid #cacaca',
    '&:hover': {
      outline: '1px solid #0d53b7',
      cursor: 'pointer',
    },
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'row',
    //minWidth: '760px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      zoom: 0.7,
    },
  },
  backButton: {
    position: 'absolute',
    top: '78px',
    left: '32px',
    display: 'flex',
    '&:hover': { cursor: 'pointer' },
  },
  button: {
    color: '#637381',
    '&:hover': { backgroundColor: 'transparent' },
  },
  createCourseModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  createCourseModalContent: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadiusMd,
    minHeight: 220,
    width: 600,
    backgroundColor: '#fff',
    margin: 'auto',
    zIndex: 1300,
  },
})

export default styles
