import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './pages/authentication/Login'
//import Login from './pages/maintenance'
import Register from './pages/authentication/Register'
import SignUp from './pages/signup'
import CheckEmail from './pages/check-verify-email-code'
import GetStarted from './pages/get-started'
import SetPassword from './pages/set-password'
import SetPw from './pages/set-pw'
import RenewPassword from './pages/renew-password'
import SetPasswordSuccess from './pages/set-password/success'
import Welcome from './pages/welcome'
//import MicroLearningContent from './pages/micro-learning-content'
//import MicroLearningContentDetail from './pages/micro-learning-content/detail'
import Main from './pages/main'
import PrivateRoute from './components/private-route'
import LiteIntegration from './pages/lite-integration-reroute'
import Integration from './pages/integration-reroute'
import SuperLoginReroute from './pages/super-login-reroute'
import IntegrationDesktop from './pages/integration-desktop'
import CheckDevice from './pages/check-device'
import RedirectByPlatform from './pages/redirect-platform'
import RedirectPlatform from './pages/redirect-platform-v2'
import UnsubscribedDone from './pages/unsubscribed-done'
import Purpose from './pages/purpose'
import ListSample from './pages/list-sample'
import AppSumoSignUp from './pages/appsumo-signup/index'
import ForgotPassword from './pages/authentication/ForgotPassword'
import ChangePassword from './pages/authentication/ChangePassword'
import Policies from './pages/policies'
import ProjectOnLife from './pages/projectonlife-signup'
import Redirect from './pages/redirect'
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/SuperLogin/:id?" component={SuperLoginReroute} />
          <Route path="/integration/:token?" component={Integration} />
          <Route path="/lite-integration/:token?" component={LiteIntegration} />
          <Route path="/integration-desktop" component={IntegrationDesktop} />
          <Route path="/get-started" component={GetStarted} />
          <Route path="/set-password/:userId?" component={SetPassword} />
          <Route path="/set-pw/:sIntegLink?" component={SetPw} />
          <Route path="/set-password-success" component={SetPasswordSuccess} />
          {/*<Route path="/change-password/:userId?" component={SetPassword} />*/}
          <Route path="/change-pw/:sIntegLink?" component={SetPw} />
          <Route path="/renew-password/" component={RenewPassword} />
          <Route exact path="/signup/:id?" component={SignUp} />
          <Route
            exact
            path="/hello-sumo-lings/:id?"
            component={AppSumoSignUp}
          />
          <Route exact path="/projectonlife" component={ProjectOnLife} />
          <Route path="/checkEmail" component={CheckEmail} />
          <Route path="/welcome" component={Welcome} />

          <Route
            path="/checkDevice/:webUrl/:link/:branchId/:sso?"
            component={CheckDevice}
          />
          <Route
            path="/redirectByPlatform/:branchId/:shortLink?"
            component={RedirectByPlatform}
          />
          <Route
            path="/redirectPlatform/:shortLink?"
            component={RedirectPlatform}
          />
          <Route path="/unsubscribed-done" component={UnsubscribedDone}></Route>
          <Route path="/list-sample" component={ListSample} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/change-password/:linkCode?"
            component={ChangePassword}
          />
          <Route path="/policies" component={Policies} />
          <Route path="/purpose" component={Purpose} />
          <Route path="/redirect/:url*" component={Redirect} />
          <PrivateRoute path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    )
  }

  componentWillMount() {
    window.CONSTANTS = {
      TIME_ZONE: 'Europe/Istanbul',
      DATE_FORMAT: 'DD-M-YYYY',
      NAME: ' ',
      SUR_NAME: ' ',
      EMAIL: ' ',
      CUSTOM_DOMAIN: null,
      LOCALIZATION_STRINGS: {},
    }
  }
}

export default App
