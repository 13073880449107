import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { pageView } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedPageViewAction } from 'src/reducers/course-collection/action'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import clsx from 'clsx'
import { DEFAULT_COLLECTION_GUID } from 'src/pages/list-courses'
const PageSelectionButtons = props => {
  const { classes } = props
  const dispatch = useDispatch()

  const selectedPage = useSelector(
    state => state.courseCollectionList.selectedPageView,
  )

  const selectedCollection = useSelector(
    state => state.courseCollectionList.selectedCollection,
  )


  const onClick = pageView => {
    dispatch(setSelectedPageViewAction(pageView))
  }
  //todo selectedPage should be coming from selector.

  if(selectedCollection == DEFAULT_COLLECTION_GUID){
    return ""
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        gap: 8,
      }}
    >
      {Object.keys(pageView).map((key, index) => (
        <Button
          key={index}
          variant="text"
          className={clsx({
            [classes.buttonOnSelectedPage]: pageView[key] === selectedPage,
            [classes.buttonOnNonSelectedPage]: pageView[key] !== selectedPage,
          })}
          onClick={() => onClick(pageView[key])}
          style={{
            color: pageView[key] === selectedPage ? '#002E47' : '#637381',
            backgroundColor:
              pageView[key] === selectedPage ? '#F4F6F8' : 'transparent',
          }}
        >
          <Typography fontWeight={600} fontSize="14px">
            {pageView[key]}
          </Typography>
        </Button>
      ))}
    </div>
  )
}

export default withStyles(styles)(PageSelectionButtons)
