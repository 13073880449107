const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginLeft: '10px',
  },

  content__components: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 16,
  },
  bottomButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    bottom: '20px',
    width: 460,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
  saveBottomButton: {
    backgroundColor: '#015FFB',
    color: '#fff',
    borderRadius: '12px',
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3a92e9',
    },
  },
})

export default styles
