import React, { useState } from 'react'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, Tab, Typography } from '@material-ui/core'
import { TabContext, TabPanel, TabList } from '@material-ui/lab'
import GeneralSettings from '../user-access'
import CustomDomain from '../custom-domain'
import ShareCollectionPopup from '../collection-share'
import EmbedTab from '../embed'

const CollectionSettingsDrawer = props => {
  const {
    setCollectionSettingDrawerOpen,
    collectionSettingsDrawerOpen,
    entity,
    classes,
  } = props
  const [currentTab, setCurrentTab] = useState('0')

  const [magicLink, setMagicLink] = useState(false)
  const [assignmentDescription, setAssignmentDescription] = useState(
    entity?.description || '',
  )
  const [assignmentTitle, setAssignmentTitle] = useState(
    entity ? entity.name : '',
  )
  const handleTabChange = (event, newValue) => setCurrentTab(newValue)

  React.useEffect(() => {
    window.toggleFreshworksAction(
      collectionSettingsDrawerOpen ? 'hide' : 'show',
    )
  }, [collectionSettingsDrawerOpen])
  const SettingsTabs = [
    {
      label: <Typography>Settings</Typography>,

      component: (entity, setCollectionSettingDrawerOpen) => (
        <GeneralSettings
          entity={entity}
          setCollectionSettingDrawerOpen={setCollectionSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
          magicLink={magicLink}
          setMagicLink={setMagicLink}
        />
      ),
      enabled: true,
    },
    {
      label: <Typography>Custom Domain</Typography>,

      component: (entity, setCollectionSettingDrawerOpen) => (
        <CustomDomain
          entity={entity}
          setCollectionSettingDrawerOpen={setCollectionSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
          magicLink={magicLink}
          setMagicLink={setMagicLink}
        />
      ),
      enabled: true,
    },
    {
      label: <Typography>Share Link</Typography>,

      component: (entity, setCollectionSettingDrawerOpen) => (
        <ShareCollectionPopup
          entity={entity}
          setCollectionSettingDrawerOpen={setCollectionSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
          magicLink={magicLink}
          setMagicLink={setMagicLink}
        />
      ),
      enabled: true,
    },
    {
      label: <Typography>Embed</Typography>,

      component: (entity, setCollectionSettingDrawerOpen) => (
        <EmbedTab
          entity={entity}
          setCollectionSettingDrawerOpen={setCollectionSettingDrawerOpen}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
          magicLink={magicLink}
          setMagicLink={setMagicLink}
        />
      ),
      enabled: true,
    },
  ]
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={collectionSettingsDrawerOpen}
        onClose={() => setCollectionSettingDrawerOpen(false)}
        //onOpen={() => setCollectionSettingDrawerOpen(true)}
        transitionDuration={{ enter: 700, exit: 700 }}
      >
        <div className={classes.list}>
          <div>
            <TabContext value={currentTab}>
              <Typography
                variant="h5"
                style={{ textAlign: 'center', marginBottom: '16px' }}
              >
                Learners' Access
              </Typography>
              {!magicLink && (
                <TabList
                  onChange={handleTabChange}
                  className={classes.tabList}
                  variant="scrollable"
                  color="#0066FF"
                  TabIndicatorProps={{
                    style: { background: '#0066FF' },
                  }}
                >
                  {SettingsTabs.map((value, index) => {
                    return (
                      <Tab
                        key={index}
                        label={value.label}
                        icon={value.icon}
                        value={index.toString()}
                        className={classes.tab}
                        disabled={value.disabled}
                      />
                    )
                  })}
                </TabList>
              )}

              {SettingsTabs.map((value, index) => {
                return (
                  <TabPanel
                    className={classes.tabContainer}
                    key={index}
                    value={index.toString()}
                    hidden={Number(currentTab) !== Number(index)}
                  >
                    {value.component(entity, setCollectionSettingDrawerOpen)}
                  </TabPanel>
                )
              })}
            </TabContext>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default withStyles(styles)(CollectionSettingsDrawer)
