import React from 'react'
import { Box, TextField, Autocomplete } from '@material-ui/core'

export default function CountrySelect(props) {
  const { languageOptions, languageSelection, setLanguageSelection } = props

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300, background:"#ffffff", borderRadius:"6px", width:"50%" }}
      options={languageOptions}
      autoHighlight
      getOptionLabel={option => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.name}
        </Box>
      )}
      renderInput={params => <TextField {...params} label="Choose language" />}
      value={languageSelection}
      onChange={(event, newValue) => {
        setLanguageSelection(newValue)
      }}
    />
  )
}
