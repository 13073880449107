import React from 'react'
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { styled } from '@material-ui/core'
import styles from '../styles'
import {
  AuthenticatedLinksViewContent,
  UpgradePopover,
  AccordionSummaryContent,
} from '..'
import { useSelector } from 'react-redux'

import UserType from 'src/model/user-types'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  '&.Mui-expanded': {
    boxShadow: 'none',
  },
}))

const AuthenticatedLinks = props => {
  const { accessRule, handleAccessRule, classes, entity } = props
  const collectionSettings = useSelector(
    state => state.courseCollectionList.collectionSettings,
  )
  const secretKey = collectionSettings.secretKey
  const userControl = UserType[window.CONSTANTS.USER_ROLE_ID]
  const accessSpecificUsersControl = () => {
    if (
      /* userControl == 'Premium Plus' ||
      userControl == 'Premium Plus v2' ||
      userControl == 'Premium - A' ||*/
      userControl == 'Appsumo (Tier 4)' ||
      userControl == 'Appsumo (Tier 5)'
    ) {
      return false
    }

    return true
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClickSwitch = event => {
    accessSpecificUsersControl() ? handleClick(event) : handleAccessRule(3)
  }
  return (
    <div className={classes.root}>
      <StyledAccordion expanded={accessRule == 3}>
        <AccordionSummary
          className={classes.accordion__summary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryContent
            accessRule={accessRule}
            handleClickSwitch={handleClickSwitch}
            contentHeader={'Enable access with in-app authentication'}
            contentDescription={
              ' Provide a seamless, one-click, passwordless access directly within your web-app or mobile-app.'
            }
            id={id}
            badgeType={3}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            popupHeader={'Upgrade to Pro'}
            popupContent={
              'Unlock exclusive features by upgrading to the Pro plan.'
            }
            accessRuleEqual={3}
          />
        </AccordionSummary>

        <AccordionDetails className={classes.accordion__details}>
          <AuthenticatedLinksViewContent
            secretKey={secretKey}
            entity={entity}
          />
        </AccordionDetails>
      </StyledAccordion>
      <UpgradePopover
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        header={'Get quote'}
        content={
          'Unlock native app experience within your own platform with dedicated support.'
        }
        accessRuleEqual={3}
      />
    </div>
  )
}

export default withStyles(styles)(AuthenticatedLinks)
